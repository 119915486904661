import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { t } from 'i18next';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { isProdEnv } from 'src/api';
import lnld from 'src/assets/images/lnld.webp';
import cup from 'src/assets/sidebar-icons/cup.png';
import gift from 'src/assets/sidebar-icons/gift.png';
import joker from 'src/assets/sidebar-icons/joker.png';
import lemon from 'src/assets/sidebar-icons/lemon.png';
import medal from 'src/assets/sidebar-icons/medal.png';
import { GameCardBannerType } from 'src/components/GameCardBanner/GameCardBanner';
import { AttentionModal } from 'src/components/Modal/AttentionBonus';
import { ChangeCurrencyModal } from 'src/components/Modal/ChangeCurrencyModal';
import { DepositCryptoModal } from 'src/components/Modal/DepositCryptoModal';
import { DepositModal } from 'src/components/Modal/DepositModal';
import { DepositPaymentInfo } from 'src/components/Modal/DepositPaymentInfo';
import { FormFieldsModal } from 'src/components/Modal/FormFieldsModal';
import { GameNotificationModal } from 'src/components/Modal/GameNotificationModal';
import { HellocleverResultModal } from 'src/components/Modal/HellocleverResult';
import { IosSetupModal } from 'src/components/Modal/IosSetupModal';
import { NewTournament } from 'src/components/Modal/NewTournament';
import { NodapayMethodsModal } from 'src/components/Modal/NodapayMethodsModal.tsx';
import { PopUpFreeSpins } from 'src/components/Modal/PopUpFreeSpins';
import { PopUpPromoCode } from 'src/components/Modal/PopUpPromoCode';
import { SupportModal } from 'src/components/Modal/SupportModal';
import { UpdateUserInformation } from 'src/components/Modal/UpdateUserInformation';
import { VipRulesModal } from 'src/components/Modal/VipRulesModal';
import { PopUpGetLevelReward } from 'src/components/PopUp/PopUpGetLevelReward';
import {
  SidebarBJSVG,
  SidebarBonusSVG,
  SidebarBooksSVG,
  SidebarDiamondSVG,
  SidebarDropwinsSVG,
  SidebarFireSVG,
  SidebarFruitsSVG,
  SidebarLiveSVG,
  SidebarMegawaysSVG,
  SidebarSlotsSVG,
} from 'src/components/SidebarSvg/SidebarSvg';
import { Tooltips } from 'src/components/SidebarTooltip/SidebarTooltip';
import {
  AbnArmo,
  AsnBank,
  Astropay,
  BankTransfer,
  Bitcoin,
  BitcoinCash,
  Cashtocode,
  DogeCoin,
  EcoPayz,
  Ethereum,
  Ezeewallet,
  Flexepin,
  ING,
  Interac,
  Jeton,
  Litecoin,
  Mastercard,
  MB,
  Neosurf,
  Neteller,
  Payop,
  Paysafe,
  Rabobank,
  Ripple,
  Skrill,
  SNS,
  Tether,
  Visa,
  YellowDiamond,
  YellowRhomb,
  YellowSpades,
} from 'src/components/svg';

import bronzeJackpotBackground from '../assets/images/bronzeJackpot.webp';
import logoCasinosAnalyzer from '../assets/images/casinos_analyzer.webp';
import goldJackpotBackground from '../assets/images/goldJackpot.webp';
import iconJacktopBronze from '../assets/images/iconJacktopBronze.webp';
import iconJacktopGold from '../assets/images/iconJacktopGold.webp';
import iconJacktopSilver from '../assets/images/iconJacktopSilver.webp';
import sidebarBaner1 from '../assets/images/sidebarBaner1.webp';
import sidebarBaner2 from '../assets/images/sidebarBaner2.webp';
import sidebarBaner3 from '../assets/images/sidebarBaner3.webp';
import silverJackpotBackground from '../assets/images/silverJackpot.webp';
import top10casinos from '../assets/images/top_10_casinos.webp';
import { LoginModal } from '../components/Modal/LoginModal';
import { ResetPasswordModal } from '../components/Modal/ResetPasswordModal';
import { SignUpModal } from '../components/Modal/SignUpModal';
import { UpdateMailAndPassword } from '../components/Modal/UpdateEmail';
import { PopUpDemo } from '../components/PopUp/PopUpDemoGame';
import { PopUpGame } from '../components/PopUp/PopUpGame';
import { License } from '../components/svg/License';
import { BetSquare, ClashOfSlots, CorrectCasino } from '../components/svg/Partners';
import { MODAL_NAME } from '../hooks/useModal';
import { BONUS_TRANSACTION_TYPE, Country, GameCategoriesMap } from '../types';
import allCountries from './countries.v1.json';

export const SIGN_UP_BONUS_ID = isProdEnv ? '5' : '3';

export const GameCategories: GameCategoriesMap = {
  'andar-bahar': {
    name: 'type_id',
    title: 'gameCategories.andarBahar',
    value: 10,
  },
  baccarat: {
    name: 'type_id',
    title: 'gameCategories.baccarat',
    value: 4,
  },
  bingo: {
    name: 'type_id',
    title: 'gameCategories.bingo',
    value: 8,
  },
  'bj-league': {
    name: 'category_id',
    title: 'gameCategories.bjLeague',
    value: 613,
  },
  blackjack: {
    name: 'type_id',
    title: 'gameCategories.blackjack',
    value: 3,
  },
  'bonus-games': {
    name: 'is_wagering',
    title: 'gameCategories.bonusGames',
    value: 1,
  },
  books: {
    name: 'category_id',
    title: 'gameCategories.booksOfSlots',
    value: 581,
  },
  casino: {
    name: 'source_id',
    title: 'gameCategories.casino',
    value: 0,
  },
  'casino-live': {
    name: 'source_id',
    title: 'gameCategories.live',
    value: 1,
  },
  'casual-games': {
    name: 'category_id',
    title: 'gameCategories.casual',
    value: 585,
  },
  craps: {
    name: 'type_id',
    title: 'gameCategories.craps',
    value: 11,
  },
  'dragon-tiger': {
    name: 'type_id',
    title: 'gameCategories.dragonTiger',
    value: 5,
  },
  'dream-catcher': {
    name: 'type_id',
    title: 'gameCategories.dreamCatcher',
    value: 9,
  },
  dropwins: {
    name: 'category_id',
    title: 'gameCategories.dropWins',
    value: 587,
  },
  'free-spins': {
    name: 'free_spins',
    title: 'gameCategories.freeSpins',
    value: true,
  },
  'fruits-games': {
    name: 'category_id',
    title: 'gameCategories.fruits',
    value: 586,
  },
  'jackpot-daily': {
    name: 'category_id',
    title: 'gameCategories.dailyJackpot',
    value: 595,
  },
  'jackpot-monthly': {
    name: 'category_id',
    title: 'gameCategories.monthlyJackpot',
    value: 597,
  },
  'jackpot-weekly': {
    name: 'category_id',
    title: 'gameCategories.weeklyJackpot',
    value: 596,
  },
  jackpots: {
    name: 'jackpot',
    title: 'gameCategories.jackpot',
    value: true,
  },
  keno: {
    name: 'type_id',
    title: 'gameCategories.keno',
    value: 6,
  },
  'live-casino-lobby': {
    name: 'type_id',
    title: 'gameCategories.liveCasinoLobby',
    value: 16,
  },
  lottery: {
    name: 'type_id',
    title: 'gameCategories.lottery',
    value: 12,
  },
  megaways: {
    name: 'category_id',
    title: 'gameCategories.megaways',
    value: 580,
  },
  'new-games': {
    name: 'category_id',
    title: 'gameCategories.newGames',
    value: 1,
  },
  other: {
    name: 'type_id',
    title: 'gameCategories.other',
    value: 17,
  },
  poker: {
    name: 'type_id',
    title: 'gameCategories.poker',
    value: 1,
  },
  race: {
    name: 'type_id',
    title: 'gameCategories.race',
    value: 14,
  },
  'ready-to-drop': {
    name: 'category_id',
    title: 'gameCategories.readyToDrop',
    value: 582,
  },
  roulette: {
    name: 'type_id',
    title: 'gameCategories.roulette',
    value: 2,
  },
  'scratch-cards': {
    name: 'type_id',
    title: 'gameCategories.scratchCards',
    value: 13,
  },
  'sic-bo': {
    name: 'type_id',
    title: 'gameCategories.sicBo',
    value: 7,
  },
  slots: {
    name: 'type_id',
    title: 'gameCategories.slots',
    value: 0,
  },
  'speedy-7': {
    name: 'type_id',
    title: 'gameCategories.speedy',
    value: 15,
  },
  sport: {
    name: 'source_id',
    title: 'gameCategories.sport',
    value: 2,
  },
  'sport-games': {
    name: 'category_id',
    title: 'gameCategories.sport',
    value: 584,
  },
  'top-games': {
    name: 'type_id',
    title: 'gameCategories.top',
    value: 0,
  },
  videoslot: {
    name: 'category_id',
    title: 'gameCategories.videoslot',
    value: 583,
  },
  'virtual-sport': {
    name: 'source_id',
    title: 'gameCategories.virtualSport',
    value: 3,
  },
};

export const bonusHeader = ['ID', 'Type', 'Amount', 'Balance/Currency', 'Date/Status'];
export const transactionHistoryHeader = [
  'transactionHistoryTable.date',
  'transactionHistoryTable.type',
  'transactionHistoryTable.amount',
  'transactionHistoryTable.fee',
  'transactionHistoryTable.curerncy',
  'transactionHistoryTable.status',
];
export const menuItem = [
  {
    image: (
      <img
        alt="lemon"
        src={lemon}
        style={{ maxWidth: '20px' }}
      />
    ),
    name: 'menuItem.games',
    path: '/games/top-games',
    tooltip: Tooltips.GAMES,
    tooltipId: 'sidebar-top-games',
  },
  {
    image: (
      <img
        alt="joker"
        src={joker}
        style={{ maxWidth: '20px' }}
      />
    ),
    name: 'menuItem.live',
    path: '/games/casino-live',
    tooltip: Tooltips.LIVE,
    tooltipId: 'sidebar-live',
  },
  {
    image: (
      <img
        alt="gift"
        src={gift}
        style={{ maxWidth: '20px' }}
      />
    ),
    name: 'menuItem.promo',
    path: '/bonuses',
    tooltip: Tooltips.PROMO,
    tooltipId: 'sidebar-promo',
  },
  {
    image: (
      <img
        alt="cup"
        src={cup}
        style={{ maxWidth: '20px' }}
      />
    ),
    name: 'menuItem.tournaments',
    path: '/tournaments',
    tooltip: Tooltips.TOURNAMENTS,
    tooltipId: 'sidebar-tournaments',
  },
  {
    image: (
      <img
        alt="medal"
        src={medal}
        style={{ maxWidth: '20px' }}
      />
    ),
    name: 'menuItem.vip',
    path: '/vip',
    tooltip: Tooltips.VIP,
    tooltipId: 'sidebar-vip',
  },
];

export const categoriesItem = [
  {
    image: <SidebarFireSVG />,
    name: 'categoriesItem.top',
    path: '/games/top-games',
  },
  {
    image: <SidebarDiamondSVG />,
    name: 'categoriesItem.new',
    path: '/games/new-games',
  },
  {
    image: <SidebarMegawaysSVG />,
    name: 'categoriesItem.megaways',
    path: '/games/megaways',
  },
  {
    image: <SidebarSlotsSVG />,
    name: 'categoriesItem.slots',
    path: '/games/slots',
  },
  {
    image: <SidebarLiveSVG />,
    name: 'categoriesItem.live',
    path: '/games/casino-live',
  },
  {
    image: <SidebarFruitsSVG />,
    name: 'categoriesItem.fruits',
    path: '/games/fruits-games',
  },
  {
    image: <SidebarBonusSVG />,
    name: 'categoriesItem.bonus',
    path: '/games/bonus-games',
  },
  {
    image: <SidebarBooksSVG />,
    name: 'categoriesItem.bookSlots',
    path: '/games/books',
  },
  {
    image: <SidebarDropwinsSVG />,
    name: 'categoriesItem.dropWins',
    path: '/games/dropwins',
  },
  {
    image: <SidebarBJSVG />,
    name: 'categoriesItem.bjLeague',
    path: '/games/bj-league',
  },
];

export const gamesCategoriesSwitcher = [
  {
    image: <SidebarSlotsSVG />,
    name: 'categoriesItem.slots',
    path: '/games/slots',
  },
  {
    image: <SidebarFireSVG />,
    name: 'categoriesItem.top',
    path: '/games/top-games',
  },
  {
    image: <SidebarDiamondSVG />,
    name: 'categoriesItem.new',
    path: '/games/new-games',
  },
  {
    image: <SidebarMegawaysSVG />,
    name: 'categoriesItem.megaways',
    path: '/games/megaways',
  },
  {
    image: <SidebarLiveSVG />,
    name: 'categoriesItem.live',
    path: '/games/casino-live',
  },
  {
    image: <SidebarBJSVG />,
    name: 'categoriesItem.bjLeague',
    path: '/games/bj-league',
  },
  {
    image: <SidebarFruitsSVG />,
    name: 'categoriesItem.fruits',
    path: '/games/fruits-games',
  },
  {
    image: <SidebarBonusSVG />,
    name: 'categoriesItem.bonus',
    path: '/games/bonus-games',
  },
  {
    image: <SidebarBooksSVG />,
    name: 'categoriesItem.bookSlots',
    path: '/games/books',
  },
  {
    image: <SidebarDropwinsSVG />,
    name: 'categoriesItem.dropWins',
    path: '/games/dropwins',
  },
];

export const jackpotsItems = [
  {
    image: (
      <YellowSpades
        height="20"
        width="20"
      />
    ),
    name: 'categoriesItem.dailyJackpot',
    path: '/games/jackpot-daily',
  },
  {
    image: (
      <YellowDiamond
        height="20"
        width="20"
      />
    ),
    name: 'categoriesItem.weeklyJackpot',
    path: '/games/jackpot-weekly',
  },
  {
    image: (
      <YellowRhomb
        height="20"
        width="20"
      />
    ),
    name: 'categoriesItem.monthlyJackpot',
    path: '/games/jackpot-monthly',
  },
  {
    image: (
      <YellowSpades
        height="20"
        width="20"
      />
    ),
    name: 'categoriesItem.jackpots',
    path: '/games/jackpots',
  },
];

export const liveItems = [
  {
    image: <SidebarSlotsSVG />,
    name: 'categoriesItem.slots',
    path: '/games/slots',
  },
  {
    image: <SidebarLiveSVG />,
    name: 'categoriesItem.live',
    path: '/games/casino-live',
  },
  {
    image: <SidebarBonusSVG />,
    name: 'categoriesItem.blackjack',
    path: '/games/blackjack',
  },
  {
    image: <SidebarFruitsSVG />,
    name: 'categoriesItem.baccarat',
    path: '/games/baccarat',
  },
  {
    image: <SidebarFireSVG />,
    name: 'categoriesItem.poker',
    path: '/games/poker',
  },
  {
    image: <SidebarMegawaysSVG />,
    name: 'categoriesItem.roulette',
    path: '/games/roulette',
  },
];

export const cardMethods = [
  {
    image: <AbnArmo />,
    name: 'AbnArmo',
  },
  {
    image: <ING />,
    name: 'ING',
  },
  {
    image: <Rabobank />,
    name: 'Rabobank',
  },
  {
    image: <SNS />,
    name: 'SNS',
  },
  {
    image: <AsnBank />,
    name: 'AsnBank',
  },
  {
    image: <BankTransfer />,
    name: 'BankTransfer',
  },
  {
    image: <Astropay />,
    name: 'Astropay',
  },
  {
    image: <Ezeewallet />,
    name: 'Ezeewallet',
  },
  {
    image: <Neosurf />,
    name: 'Neosurf',
  },
  {
    image: <Payop />,
    name: 'Payop',
  },
];

export const kryptoMethods = [
  {
    image: <Bitcoin />,
    name: 'Bitcoin',
  },
  {
    image: <BitcoinCash />,
    name: 'BitcoinCash',
  },
  {
    image: <Ethereum />,
    name: 'Ethereum',
  },
  {
    image: <Tether />,
    name: 'Tether',
  },
  {
    image: <DogeCoin />,
    name: 'DogeCoin',
  },
];

export const partners = [
  {
    href: 'https://betsquare.com/',
    icon: <BetSquare />,
    image: null,
    name: 'BetSquare',
    style: 'w-[84px] md:w-[133px] h-[17px] md:h-[27px]',
  },
  {
    href: 'https://clashofslots.com/',
    icon: <ClashOfSlots />,
    image: null,
    name: 'ClashOfSlots',
    style: 'w-[62px] md:w-[93px] h-[25px] md:h-[38px]',
  },
  {
    href: 'https://www.correctcasinos.com/',
    icon: <CorrectCasino />,
    image: null,
    name: 'CorrectCasino',
    style: 'w-[124px] md:w-[171px] h-[12px] md:h-[16px]',
  },
  {
    href: 'https://casinosanalyzer.com/',
    image: logoCasinosAnalyzer,
    name: 'Casinos Analyzer',
    style: 'w-[64px] md:w-[97px] h-[25px] md:h-[38px] object-contain',
  },
  {
    href: 'https://www.top10casinos.com/',
    image: top10casinos,
    name: 'Top 10 Casinos',
    style: 'w-[64px] md:w-[102px] h-[25px] md:h-[38px] object-contain',
  },
];

export const paymentsCards = [
  {
    image: <Visa />,
    name: 'Visa',
    style: 'h-[13px] w-[44px] md:h-[24px] md:w-[77px]',
  },
  {
    image: <Mastercard />,
    name: 'Mastercard',
    style: 'h-[9px] w-[49px] md:h-[19px] md:w-[100px]',
  },
  {
    image: <Skrill />,
    name: 'Skrill',
    style: 'h-[14px] w-[43px] md:h-[26px] md:w-[70px]',
  },
  {
    image: <Neteller />,
    name: 'Neteller',
    style: 'h-[8px] w-[49px] md:h-[17px] md:w-[100px]',
  },
  {
    image: <Ezeewallet />,
    name: 'Ezeewallet',
    style: 'h-[9px] w-[50px] md:h-[20px] md:w-[100px]',
  },
  {
    image: <Neosurf />,
    name: 'Neosurf',
    style: 'h-[13px] w-[44px] md:h-[21px] md:w-[84px]',
  },
  {
    image: <Astropay />,
    name: 'Astropay',
    style: 'h-[14px] w-[39px] md:h-[31px] md:w-[78px]',
  },
  {
    image: <Flexepin />,
    name: 'Flexepin',
    style: 'h-[15px] w-[50px] md:h-[27px] md:w-[83px]',
  },
  {
    image: <Cashtocode />,
    name: 'Cashtocode',
    style: 'h-[8px] w-[50px] md:h-[17px] md:w-[103px]',
  },
  {
    image: <Interac />,
    name: 'Interac',
    style: 'h-[15px] w-[15px] md:h-[32px] md:w-[30px]',
  },
  {
    image: <Jeton />,
    name: 'Jeton',
    style: 'h-[13px] w-[51px] md:h-[25px] md:w-[89px]',
  },
  {
    image: <MB />,
    name: 'MB',
    style: 'h-[12px] w-[13px] md:h-[27px] md:w-[28px]',
  },
  {
    image: <EcoPayz />,
    name: 'EcoPayz',
    style: 'h-[12px] w-[48px] md:h-[25px] md:w-[97px]',
  },
  {
    image: <BankTransfer />,
    name: 'BankTransfer',
    style: 'h-[13px] w-[37px] md:h-[28px] md:w-[75px]',
  },
  {
    image: <Paysafe />,
    name: 'Paysafe',
    style: 'h-[8px] w-[50px] md:h-[18px] md:w-[101px]',
  },
  {
    image: <Ripple />,
    name: 'Ripple',
    style: 'h-[13px] w-[49px] md:h-[29px] md:w-[99px]',
  },
  {
    image: <Bitcoin />,
    name: 'Bitcoin',
    style: 'h-[10px] w-[49px] md:h-[22px] md:w-[100px]',
  },
  {
    image: <Ethereum />,
    name: 'Ethereum',
    style: 'h-[17px] w-[67px] md:h-[32px] md:w-[126px]',
  },
  {
    image: <Litecoin />,
    name: 'Litecoin',
    style: 'h-[16px] w-[56px] md:h-[29px] md:w-[104px]',
  },
  {
    image: <Tether />,
    name: 'Tether',
    style: 'h-[12px] w-[55px] md:h-[24px] md:w-[108px]',
  },
];

const arrayTransactions = [
  'Deposit',
  'Withdraw',
  'Bet',
  'Win',
  'Bonus',
  'Jackpot',
  'Change Balance Inc',
  'Change Balance Dec',
  'Referral',
  'Convert Balance',
  'Money Prize',
  'Bonus Transaction',
  'Cancel Bonus Fine',
  'Refund',
  'Rollback Bet',
  'Rollback Win',
  'Bet Sport',
  'Jackpot Increasing',
  'Level Bonus',
];

dayjs.extend(localeData);

export const months = dayjs.monthsShort().map((elem, index) => {
  return { id: String(index + 1), name: elem };
});

export const days = new Array(31).fill(null).map((_, i) => {
  return { id: String(`${i + 1}`), name: `${i + 1}` };
});

const currentYear = new Date().getFullYear();

export const years = new Array(100).fill(null).map((year, i) => {
  return { id: String(`${currentYear - 18 - i}`), name: `${currentYear - 18 - i}` };
});

export const transactionTypesOptions = arrayTransactions.map((element, index) => {
  return { id: String(index + 1), name: element };
});

export const IconCurrency = {
  AUD: 'AU$',
  BRL: 'R$',
  CAD: 'C$',
  CHF: 'CHF',
  DKK: 'kr',
  EUR: '€',
  FS: 'FS',
  INR: '₹',
  JPY: '¥',
  NOK: 'kr',
  NZD: 'NZ$',
  PLN: 'zł',
  RUB: '₽',
  SEK: 'kr',
  USD: '$',
};

export const neosurfPaymentMethodLimits = {
  AUD: 900,
  BRL: 2900,
  CAD: 800,
  CHF: 500,
  CZK: 13200,
  DKK: 4000,
  EUR: 550,
  INR: 49000,
  NGN: 448400,
  NZD: 990,
  PLN: 2400,
  SEK: 6500,
  USD: 590,
  ZAR: 11100,
};

export const payofPaymentMethodLimits = {
  EUR: 900,
};

export const payofCanadaPaymentMethodLimits = {
  CAD: 800,
};

export const payopCanadaPaymentMethodLimits = {
  CAD: 1300,
};

export const redefinedMaxAmountMap: Record<string, Record<string, number>> = {
  payop: payofPaymentMethodLimits,
  payop_200007: payofCanadaPaymentMethodLimits,
  payop_200008: neosurfPaymentMethodLimits,
  payop_210013: payopCanadaPaymentMethodLimits,
  payop_381: payopCanadaPaymentMethodLimits,
  payop_700001: payopCanadaPaymentMethodLimits,
};

export const allCountriesData: Country[] = allCountries;

const getArrayOfCountryPhoneCodes = (countries: Country[]) => {
  const phoneCodes = new Set<string>(countries.map((country) => country.phoneCodes[0]));
  return [...phoneCodes]
    .sort((a, b) => Number(a.replace('+', '')) - Number(b.replace('+', '')))
    .map((code) => ({ id: code, name: code }))
    .filter((code) => code.id !== undefined);
};

export const mapCountryPhoneCode = allCountries.reduce(
  (acc, country) => {
    acc[country.name] = country.phoneCodes[0];
    return acc;
  },
  {} as Record<string, string>,
);

export const mapCountryAlpha2 = allCountries.reduce(
  (acc, country) => {
    acc[country.name] = country.alpha2;
    return acc;
  },
  {} as Record<string, string>,
);

export const phoneCodes = getArrayOfCountryPhoneCodes(allCountries);
export const countriesOptions = allCountries.map((c) => ({ id: c.alpha3, name: c.name }));
export const bonusTransactionsOptions = BONUS_TRANSACTION_TYPE.map((transaction, index) => ({
  id: `${index}`,
  name: transaction,
}));
export const graduationJackpots = [
  {
    icon: iconJacktopGold,
    img: goldJackpotBackground,
    key: 'gold',
  },
  {
    icon: iconJacktopSilver,
    img: silverJackpotBackground,
    key: 'silver',
  },
  {
    icon: iconJacktopBronze,
    img: bronzeJackpotBackground,
    key: 'bronze',
  },
];

export enum ScreenModes {
  Desktop,
  Mobile,
}

export const SCREEN_MODE = ScreenModes.Mobile;

export const bonusQueue = [
  `${t('tableBonusQueue.name')}`,
  `${t('tableBonusQueue.amount')}`,
  `${t('tableBonusQueue.maxBet')}`,
  `${t('tableBonusQueue.wager')}`,
  `${t('tableBonusQueue.date')}`,
];

export const sortType = [
  { id: 'amountAsc', name: 'sortType.amountUp', sort: 'amount', sortDir: 'asc' },
  { id: 'amountDesc', name: 'sortType.amountDown', sort: 'amount', sortDir: 'desc' },
  { id: 'dateAsc', name: 'sortType.dateUp', sort: 'date', sortDir: 'asc' },
  { id: 'dateDesc', name: 'sortType.amountUp', sort: 'date', sortDir: 'desc' },
];

export const modalMap: Record<MODAL_NAME, FC> = {
  [MODAL_NAME.ATTENTION_BONUS]: () => <AttentionModal />,
  [MODAL_NAME.BALANCE_CHANGE_CURRENCY]: () => <ChangeCurrencyModal />,
  [MODAL_NAME.DEPOSIT_CRYPTO]: () => <DepositCryptoModal />,
  [MODAL_NAME.DEPOSIT_HELLOCLEVER_RESULT]: () => <HellocleverResultModal />,
  [MODAL_NAME.DEPOSIT_PAYMENT_INFO]: () => <DepositPaymentInfo />,
  [MODAL_NAME.FORM_FIELDS]: () => <FormFieldsModal />,
  [MODAL_NAME.GAME_NOTIFICATION]: () => <GameNotificationModal />,
  [MODAL_NAME.IOSSETUP]: () => <IosSetupModal />,
  [MODAL_NAME.LOGIN]: () => <LoginModal />,
  [MODAL_NAME.NEW_TOURNAMENT]: () => <NewTournament />,
  [MODAL_NAME.NODAPAY_METHODS]: () => <NodapayMethodsModal />,
  [MODAL_NAME.POPUP_DEMO]: () => <PopUpDemo />,
  [MODAL_NAME.POPUP_DEPOSIT]: () => <DepositModal />,
  [MODAL_NAME.POPUP_FREESPINS]: () => <PopUpFreeSpins />,
  [MODAL_NAME.POPUP_GET_LEVEL_REWARD]: () => <PopUpGetLevelReward />,
  [MODAL_NAME.POPUP_PROMOCODE]: () => <PopUpPromoCode />,
  [MODAL_NAME.POPUP_SINGLE_GAME]: () => <PopUpGame />,
  [MODAL_NAME.RESET_PASSWORD]: () => <ResetPasswordModal />,
  [MODAL_NAME.SIGNUP]: () => <SignUpModal />,
  [MODAL_NAME.SUPPORT]: () => <SupportModal />,
  [MODAL_NAME.UPDATE_MAIL_AND_PASSWORD]: () => <UpdateMailAndPassword />,
  [MODAL_NAME.UPDATE_USER_INFORMATION]: () => <UpdateUserInformation />,
  [MODAL_NAME.VIP_RULES]: () => <VipRulesModal />,
};

export const categoryBadgeGame = {
  1: {
    id: 1,
    style: 'game-badge-new',
    title: 'categoryBadgeGame.new',
    type: GameCardBannerType.NEW,
  },
  2: {
    id: 2,
    style: 'game-badge-top',
    title: 'categoryBadgeGame.top',
    type: GameCardBannerType.TOP,
  },
};

export const vipData = [
  {
    blocks: [
      {
        content: 'Enjoy extra privileges, so play your favorite games, improve your strategy and have fun!',
        title: 'VIP member status',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
    ],
    code: 'level_1',
  },
  {
    blocks: [
      {
        content: '10% cashback on your weekly play with 3X wagering.',
        title: 'CashBack 10%',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_2',
  },
  {
    blocks: [
      {
        content: 'Bonus up to 300 euros with a wager 40 in bonus slots.',
        title: 'Reward: 50% on a deposit',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_3',
  },
  {
    blocks: [
      {
        content: '100 Free Spins with a bet of €/$0,20 per spin. Wager 40.',
        title: 'Reward: 100 Free Spins',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_4',
  },
  {
    blocks: [
      {
        content: 'You can choose one bonus and activate it via support chat:',
        title: 'Personal Amazing Bonus',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_5',
  },
  {
    blocks: [
      {
        content: '11% cashback on your weekly play with 3X wagering.',
        title: 'CashBack 11%',
      },
      {
        content: 'We give our most active players a special personalized VIP service.',
        title: 'Personal VIP service',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_6',
  },
  {
    blocks: [
      {
        content: '12% cashback on your weekly play with 3X wagering.',
        title: 'CashBack 12%',
      },
      {
        content: 'We give our most active players a special personalized VIP service.',
        title: 'Personal VIP service',
      },
      {
        content: 'Once you reach Level 7, contact your account manager for more information.',
        title: 'Individual Withdrawal Limits',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_7',
  },
  {
    blocks: [
      {
        content: '13% cashback on your weekly play with 3X wagering.',
        title: 'CashBack 13%',
      },
      {
        content: 'We give our most active players a special personalized VIP service.',
        title: 'Personal VIP service',
      },
      {
        content: 'Once you reach Level 8, contact your account manager for more information.',
        title: 'Individual Withdrawal Limits',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_8',
  },
  {
    blocks: [
      { content: '15% cashback on your weekly play with 3X wagering.', title: 'CashBack 15%' },
      {
        content: '100 Free Spins with a bet of €/$0,20 per spin. Wager 40.',
        title: 'Reward: 100 Free Spins',
      },
      {
        content: 'We give our most active players a special personalized VIP service.',
        title: 'Personal VIP service',
      },
      {
        content: 'Once you reach Level 9, contact your account manager for more information.',
        title: 'Individual Withdrawal Limits',
      },
      {
        content: 'Top level service from experienced Account Managers.',
        title: '24-Hour Support Service',
      },
      {
        content: 'Special events and prizes based on levels.',
        title: 'Extra Activity rewards',
      },
    ],
    code: 'level_9',
  },
];

export const L = {
  NLD: {
    getPageCodeFormat: (page?: string) => (page ? `${page}_jt` : undefined),
    logo: (
      <img
        alt="first license"
        className="max-w-[65px] "
        src={lnld}
      />
    ),
    text: 'Betsomnia is owned and operated by FGS SOFTWARE SOLUTIONS S.R.L., a company registered and established under the laws of Costa Rica. FGS SOFTWARE SOLUTIONS S.R.L. is regulated by the Government of Costa Rica. SOFTWARE SOLUTIONS S.R.L. registration number is 3-102-871832.',
  },
  OTHERS: {
    getPageCodeFormat: (page?: string) => page,
    logo: (
      <Link
        target="_blank"
        to="https://licensing.gaming-curacao.com/validator/?lh=21c0bec8198be1c2d52fe40bb0af4961"
      >
        <License className="w-[138px] xl:w-[191px]" />
      </Link>
    ),
    text: 'www.betsomnia.com is operated by Fair Game Software KFT (registration number 13-09-204926, registered under the laws of Hungary, registered address: 2161 Csomád, Kossuth Lajos út 79, is an agent of FairGame G.P. N.V ) which provides management, payment and support services related to the operation of the website. The operations of the website have been leased by the license holder FairGame G.P. N.V. (registration number 141938, address Abraham de Veerstraat 9, Curacao, with license number GLH-OCCHKTW0703032017 and regulated by the laws of Curacao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ as an Information Service Provider) to Fair Game Software KFT.',
  },
};

export const sidebarBaners = [
  {
    image: sidebarBaner1,
    link: '/games/jackpot-daily',
  },
  {
    image: sidebarBaner2,
    link: '/games/jackpot-weekly',
  },
  {
    image: sidebarBaner3,
    link: '/games/jackpot-monthly',
  },
];

export const NLD_BANNED_PROVIDERS = {
  '1x2 Gaming': true,
  '3 Oaks': true,
  Ainsworth: true,
  'Atomic Slot Lab': true,
  AugustGaming: true,
  BigTimeGaming: true,
  Blueprint: true,
  Dlv: true,
  EAGaming: true,
  EGT: true,
  Evoplay: true,
  Ezugi: true,
  Fantasma: true,
  Fazi: true,
  Foxium: true,
  GameArt: true,
  Gamomat: true,
  Givme: true,
  GoldenHero: true,
  GoldenRace: true,
  Habanero: true,
  Hacksaw: true,
  IronDogStudio: true,
  iSoftBet: true,
  JustForTheWin: true,
  Kalamba: true,
  LeapGaming: true,
  LightningBox: true,
  Microgaming: true,
  NetEnt: true,
  NetGame: true,
  'Nolimit City': true,
  ORYX: true,
  Quickspin: true,
  Rabcat: true,
  'Real Dealer': true,
  'Red Tiger': true,
  RedRake: true,
  ReelNRG: true,
  RelaxGaming: true,
  Spearhead: true,
  Swintt: true,
  ThreeOaks: true,
  Thunderkick: true,
  TrueLab: true,
  Wazdan: true,
  Yggdrasil: true,
};

export const SUPPORTED_LANGUAGES = {
  // el: true,
  de: true,
  // 'en-AU': true,
  // 'en-NZ': true,
  // 'en-CA': true,
  // se: true,
  // es: true,
  // fr: true,
  en: true,
  pl: true,
  pt: true,
};

export const contentGamesPageCodes = {
  books: 'book',
  dropwins: 'drops_win',
  'fruits-games': 'fruit',
  megaways: 'megaways',
};

export const BlockGamesIcons = [
  {
    icon: SidebarFireSVG,
    title: 'gameCategories.top',
  },
  {
    icon: SidebarLiveSVG,
    title: 'gameCategories.live',
  },
  {
    icon: SidebarDiamondSVG,
    title: 'gameCategories.newGames',
  },
];

export const accountBonusTypes = [
  {
    content: 'bonusType.registration',
    name: 'Registration',
    type: 0,
  },
  {
    content: 'bonusType.registrationPromoCode',
    name: 'RegistrationPromoCode',
    type: 1,
  },
  {
    content: 'bonusType.firstAuthorization',
    name: 'FirstAuthorization',
    type: 2,
  },
  {
    content: 'bonusType.promoCode',
    name: 'PromoCode',
    type: 3,
  },
  {
    content: 'bonusType.birthday',
    name: 'Birthday',
    type: 4,
  },
  {
    content: 'bonusType.deposits',
    name: 'Deposits',
    type: 5,
  },
  {
    content: 'bonusType.periodic',
    name: 'Periodic',
    type: 6,
  },
  {
    content: 'bonusType.increaseWinnings',
    name: 'IncreaseWinnings',
    type: 7,
  },
  {
    content: 'bonusType.activatedByLink',
    name: 'ActivatedByLink',
    type: 8,
  },
];
