import classNames from 'classnames';
import { t } from 'i18next';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useRegistration } from '../../api/auth';
import { useGetBlacklistEmails } from '../../api/blacklist';
import { useGetUserCountry, useSendSMSCode, useUpdateSubscriptions, useVerifySMSCode } from '../../api/profile';
import { Autocomplete } from '../../components/Autocomplete';
import { parseAffilliateReferralLink } from '../../components/ReferralLinkHandler';
import { SmsTimer } from '../../components/SmsTimer';
import { Eye, Spin } from '../../components/svg';
import { countriesOptions, mapCountryPhoneCode } from '../../constants/index';
import {
  FieldType,
  useForm,
  validateCountry,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validatePhoneFormat,
  validateRequired,
} from '../../hooks/useForm';
import { MESSAGE_STATUS, useMessage } from '../../hooks/useMessage';
import { usePasswordChecklist } from '../../hooks/usePasswordChecklist';
import { findCountryCodeByName, findCountryNameByCode } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { calculateSeconds, generatePassword } from '../../utils/timer';

enum SignUpMethodEnum {
  Email = 'Email',
  Phone = 'Phone',
}

export const SignUp = () => {
  const { mutateAsync: updateSubscription } = useUpdateSubscriptions();
  const [signUpMethod] = useState<SignUpMethodEnum>(SignUpMethodEnum.Email);
  const [isConfirmedPhone, setIsConfirmedPhone] = useState(false);
  const [searchParams] = useSearchParams();
  const { userCountry } = useGetUserCountry({});
  const { openMessage } = useMessage();
  const { isLoading: isLoadingSmsCode, mutateAsync: sendSMSCode } = useSendSMSCode();
  const { isEmailBlocked } = useGetBlacklistEmails();
  const { isLoading: isLoadingVerifySMSCode, mutateAsync: verifySmsCode } = useVerifySMSCode();
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const { isLoading, mutateAsync } = useRegistration({
    successReg: () => {
      navigate('/');
    },
  });
  //
  // const handleSignUp = (event: any) => {
  //  const { target } = event;
  //  setSignUpMethod(target.value.trim());
  // };

  const {
    errors,
    // fieldsProps: {countryProps, emailProps, is18yearsProps, newsProps, passwordProps, phoneProps, smsCodeProps},
    fieldsProps: { countryProps, emailProps, passwordProps, phoneProps, smsCodeProps },
    focused,
    isFormValid,
    setSomeValues,
    setSubmitTried,
    submitTried,
    updateDefaultStatePartially,
    values,
  } = useForm<{
    country: FieldType.Autocomplete;
    email: FieldType.Text;
    // is18years: FieldType.Checkbox;
    // news: FieldType.Checkbox;
    password: FieldType.Text;
    phone: FieldType.Phone;
    smsCode: FieldType.Number;
  }>({
    country: {
      fieldType: FieldType.Autocomplete,
      options: countriesOptions,
      placeholder: `${t('general.chooseYourCoutry')}`,
      validation: [validateRequired, validateCountry],
    },
    email: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.email')}`,
      validation:
        signUpMethod === SignUpMethodEnum.Email ? [validateRequired, validateMaxLength(256), validateEmail] : [],
    },
    // is18years: {
    //   fieldType: FieldType.Checkbox,
    //   validation: [validateRequired],
    // },
    // news: {
    //  defaultValue: true,
    //  fieldType: FieldType.Checkbox,
    // },
    password: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.password')}`,
      validation:
        signUpMethod === SignUpMethodEnum.Email ? [validateRequired, validateMaxLength(256), validatePassword] : [],
    },
    phone: {
      defaultValue: phoneCode,
      fieldType: FieldType.Phone,
      phoneCode: phoneCode,
      placeholder: `${t('general.phone')}`,
      validation: signUpMethod === SignUpMethodEnum.Email ? [] : [validateRequired, validatePhoneFormat],
    },
    smsCode: {
      fieldType: FieldType.Number,
      placeholder: `${t('general.confirmationCode')}`,
      validation: isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone ? [validateRequired] : [],
    },
  });

  useEffect(() => {
    setPhoneCode(values.country ? mapCountryPhoneCode[values.country] : '');
  }, [values.country]);

  useEffect(() => {
    setSomeValues({ phone: phoneCode });
  }, [phoneCode]);

  useGetUserCountry({
    onSuccess: (response) => {
      response.data.country &&
        updateDefaultStatePartially({
          country: findCountryNameByCode(response.data.country),
        });
    },
  });

  const handleTimerClick = async () => {
    return sendSMSCode(values.phone.replace(/\D+/g, '')).then(() => {
      localStorage.setItem(`callTime`, `${new Date().toISOString()}`);
    });
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const { affiliateId, affiliateUserId } = parseAffilliateReferralLink(searchParams.get('stag'));

      const registration = async () => {
        const unixTimestamp = Math.floor(new Date().getTime() / 1000);

        sendGoogleAnalyticsEvent(signUpMethod === SignUpMethodEnum.Phone ? 'user_signup_tel' : 'user_signup', {
          affiliate_id: affiliateId ?? null,
          geo: userCountry?.country,
          unix_timestamp: unixTimestamp,
        });

        try {
          await mutateAsync({
            country: findCountryCodeByName(values.country),
            email: values.email,
            password: values.password,
            ...(affiliateId && affiliateUserId
              ? { affiliate_id: affiliateId, affiliate_user_id: affiliateUserId }
              : {}),
          });

          // updateSubscription({
          //  // eslint-disable-next-line camelcase
          //  email_bonuses_sub: values.news,
          // });
        } catch (error) {
          openMessage?.(`${t('general.error')}`, MESSAGE_STATUS.ERROR);
        }
      };

      setSubmitTried(true);
      if (!isFormValid) return;

      if (!isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone) {
        const seconds = calculateSeconds();
        if (seconds > 0) {
          return openMessage?.(`The next SMS can be sent in ${seconds} seconds`, MESSAGE_STATUS.INFO);
        } else {
          await sendSMSCode(values.phone.replace(/\D+/g, '')).then(() => {
            // FOR DEBUG
            // await Promise.resolve().then(() => {
            setIsConfirmedPhone(true);
            localStorage.setItem(`callTime`, `${new Date().toISOString()}`);
          });
        }
        return;
      }

      if (isEmailBlocked(values.email)) {
        return openMessage?.(`${t('registration.regionBlocked')}`, MESSAGE_STATUS.ERROR);
      }

      if (isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone) {
        const verifyResult = await verifySmsCode({
          code: Number(values.smsCode),
          phone: values.phone.replace(/\D+/g, ''),
        });

        // FOR DEBUG
        // const verifyResult = await Promise.resolve({ data: { valid: true } });

        if (verifyResult.data.valid) {
          const email = `${values.phone.replace(/\D+/g, '')}-${Date.now()}@phone.com`;
          values.email = email;
          values.password = generatePassword(email);

          await registration();
        } else {
          openMessage?.(`${t('signUpModal.invalidCode')}`, MESSAGE_STATUS.ERROR);
        }
        return;
      }

      await registration();
    },
    [
      setSubmitTried,
      isFormValid,
      isEmailBlocked,
      values.email,
      values.password,
      values.country,
      // values.news,
      searchParams,
      openMessage,
      t,
      mutateAsync,
      updateSubscription,
    ],
  );

  const passwordChecklist = usePasswordChecklist(values.password);

  const renderCountry = () => (
    <>
      <div
        className="mb-2 block w-full xl:mb-6 xl:ml-3 xl:inline-block xl:w-[15rem]"
        id="country-autocomplete"
      >
        <Autocomplete
          {...countryProps}
          className="bs-field block w-full rounded-2xl p-4 xl:w-[15rem]"
          emptyStateContent="autocompleteEmptyState.noSuchCountry"
          inputBorderColor={classNames(
            focused !== 'country' && submitTried && Boolean(errors.country) && 'border-border_4',
          )}
          inputWithPadding={false}
        />
      </div>
      <Tooltip
        anchorSelect="#country-autocomplete"
        className="rounded-4xl absolute bg-background_6 text-text_2"
        isOpen={submitTried && Boolean(errors.country)}
        place="top"
      >
        <div>{errors.country}</div>
      </Tooltip>
    </>
  );

  return (
    <form
      className="bs-section-bonus__form rounded-3xl p-4 text-left xl:p-0"
      onSubmit={handleSubmit}
    >
      <fieldset>
        {/* <div className="bs-radio mb-6 flex h-11 gap-x-2 rounded-xl p-[3px] text-center xl:inline-flex"> */}
        {/*   <input */}
        {/*     checked={signUpMethod === SignUpMethodEnum.Email} */}
        {/*     className="bs-radio__input" */}
        {/*     id="type_email" */}
        {/*     name="type" */}
        {/*     onChange={handleSignUp} */}
        {/*     type="radio" */}
        {/*     value={SignUpMethodEnum.Email} */}
        {/*   /> */}
        {/*   <label */}
        {/*     className="bs-radio__label flex-auto rounded-[0.625rem] px-8 py-2 uppercase leading-5" */}
        {/*     htmlFor="type_email" */}
        {/*   > */}
        {/*     Email */}
        {/*   </label> */}
        {/*   <input */}
        {/*     checked={signUpMethod === SignUpMethodEnum.Phone} */}
        {/*     className="bs-radio__input bs-btn" */}
        {/*     id="type_phone" */}
        {/*     name="type" */}
        {/*     onChange={handleSignUp} */}
        {/*     type="radio" */}
        {/*     value={SignUpMethodEnum.Phone} */}
        {/*   /> */}
        {/*   <label */}
        {/*     className="bs-radio__label flex-auto rounded-[0.625rem] px-8 py-2 uppercase leading-5" */}
        {/*     htmlFor="type_phone" */}
        {/*   > */}
        {/*     Phone */}
        {/*   </label> */}
        {/* </div> */}
        <div>
          {signUpMethod === SignUpMethodEnum.Email && (
            <>
              <input
                id="email-input"
                type="email"
                {...emailProps}
                className="bs-field mb-2 block w-full rounded-2xl p-4 xl:mb-6 xl:inline-block xl:w-[15rem]"
              />
              {!focused && (
                <Tooltip
                  anchorSelect="#email-input"
                  className="rounded-4xl absolute bg-background_6 text-text_2"
                  isOpen={submitTried && Boolean(errors.email)}
                  place="top"
                >
                  <div>{errors.email}</div>
                </Tooltip>
              )}
              <div className="relative block w-full xl:inline-block xl:w-[15rem] mb-2 xl:mb-6 xl:ml-3">
                <input
                  id="password-input"
                  type={passwordShown ? 'text' : 'password'}
                  {...passwordProps}
                  className="bs-field  block w-full rounded-2xl xl:inline-block p-4"
                />

                <div className="absolute right-0 top-[3px] p-4 cursor-pointer transition-transform">
                  <Eye
                    passwordShown={passwordShown}
                    setPasswordShown={setPasswordShown}
                  />
                </div>
              </div>

              {!focused && (
                <Tooltip
                  anchorSelect="#password-input"
                  className="rounded-4xl absolute bg-background_6 text-text_2"
                  isOpen={submitTried && Boolean(errors.password)}
                  place="top"
                >
                  <div>{errors.password}</div>
                </Tooltip>
              )}
              <Tooltip
                anchorSelect="#password-input"
                className="rounded-4xl absolute bg-background_6 text-text_2"
                openOnClick
                place="top"
              >
                <div>
                  {passwordChecklist.map((error) => (
                    <p
                      className={classNames({ 'line-through': error.done })}
                      key={error.text}
                    >
                      {error.text}
                    </p>
                  ))}
                </div>
              </Tooltip>
              {renderCountry()}
            </>
          )}
          {signUpMethod === SignUpMethodEnum.Phone && (
            <>
              <div
                className={classNames(
                  'relative block w-full xl:inline-block xl:w-[15rem]',
                  !errors.phone ? 'mb-8' : 'mb-2',
                )}
              >
                <input
                  id="phone-input"
                  type="tel"
                  {...phoneProps}
                  className="bs-field  w-full rounded-2xl p-4"
                  disabled={isConfirmedPhone}
                />
                <div className="bs-timer absolute b-0 w-full font-poppins">
                  {!errors.phone && !isConfirmedPhone && (
                    <div
                      className="text-text_2 py-[4px] select-none cursor-pointer"
                      onClick={handleSubmit}
                    >
                      Send code
                    </div>
                  )}
                  {isConfirmedPhone && (
                    <div
                      className="text-text_2 py-[4px] select-none cursor-pointer"
                      onClick={() => setIsConfirmedPhone(false)}
                    >
                      Reenter phone
                    </div>
                  )}
                </div>
                {!focused && (
                  <Tooltip
                    anchorSelect="#phone-input"
                    className="rounded-4xl absolute bg-background_6 text-text_2"
                    isOpen={submitTried && Boolean(errors.phone)}
                    place="top"
                  >
                    <div>{errors.phone}</div>
                  </Tooltip>
                )}
              </div>
              <div
                className={classNames(
                  'relative block xl:ml-3 w-full xl:inline-block xl:w-[15rem]',
                  isConfirmedPhone ? 'mb-8' : 'mb-2',
                )}
              >
                <input
                  id="confirmation-input"
                  type="text"
                  {...smsCodeProps}
                  className="bs-field p-4  w-full rounded-2xl"
                  disabled={!isConfirmedPhone}
                />
                {isConfirmedPhone && (
                  <div className="bs-timer absolute b-0 w-full font-poppins">
                    <SmsTimer sendAgainClick={handleTimerClick} />
                  </div>
                )}

                {!focused && (
                  <Tooltip
                    anchorSelect="#confirmation-input"
                    className="rounded-4xl absolute bg-background_6 text-text_2"
                    isOpen={submitTried && Boolean(errors.smsCode)}
                    place="top"
                  >
                    <div>{errors.smsCode}</div>
                  </Tooltip>
                )}
              </div>
              {renderCountry()}
            </>
          )}
        </div>

        {!isConfirmedPhone && (
          <>
            {/* <div className="bs-switch mb-4">*/}
            {/*  <input*/}
            {/*    id="agree_with_terms"*/}
            {/*    type="checkbox"*/}
            {/*    {...is18yearsProps}*/}
            {/*    className="bs-switch__input"*/}
            {/*  />*/}
            {/*  <label*/}
            {/*    className="bs-switch__label xl:text-[0.875rem]/6"*/}
            {/*    htmlFor="agree_with_terms"*/}
            {/*  >*/}
            {/*    Agree with terms and conditions*/}
            {/*  </label>*/}
            {/*  {!focused && submitTried && errors.is18years && (*/}
            {/*    <Tooltip*/}
            {/*      anchorSelect="#agree_with_terms"*/}
            {/*      className="rounded-4xl z-50 absolute bg-background_6 text-text_2"*/}
            {/*      isOpen={submitTried && Boolean(errors.is18years)}*/}
            {/*      place="bottom"*/}
            {/*    >*/}
            {/*      <div>{errors.is18years}</div>*/}
            {/*    </Tooltip>*/}
            {/*  )}*/}
            {/* </div>*/}
            {/* <div className="bs-switch mb-6 xl:mb-8"> */}
            {/*   <input */}
            {/*     id="news" */}
            {/*     type="checkbox" */}
            {/*     {...newsProps} */}
            {/*     className="bs-switch__input" */}
            {/*   /> */}
            {/*   <label */}
            {/*     className="bs-switch__label xl:text-[0.875rem]/6" */}
            {/*     htmlFor="news" */}
            {/*   > */}
            {/*     Agree with emails */}
            {/*   </label> */}
            {/* </div> */}
          </>
        )}

        <button
          className="bs-btn w-full flex rounded-2xl px-16 py-3 text-center justify-center xl:w-auto"
          disabled={isLoading || isLoadingSmsCode || isLoadingVerifySMSCode}
          type="submit"
        >
          {(isLoadingVerifySMSCode || isLoadingSmsCode || isLoading) && <Spin />}
          Get bonus
        </button>
      </fieldset>
    </form>
  );
};
