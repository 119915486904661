import { ButtonHTMLAttributes, FC } from 'react';
import { UpIcon } from 'src/components/svg/ScrollToTopButton';

interface ScrollToTopButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  visible?: boolean;
}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({ visible = true, ...rest }) => {
  return (
    <>
      {visible ? (
        <div className="flex justuify-center items-center fixed lg:bottom-5 bottom-[75px] right-5 z-[1000] bg-background_28 rounded-md p-4 opacity-30 hover:opacity-50 duration-300">
          <button {...rest}>
            <UpIcon />
          </button>
        </div>
      ) : null}
    </>
  );
};
