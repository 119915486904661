import { Input, InputProps } from 'src/components/Inputs/Input';
import { Button } from 'src/components/Buttons/Button';
import { ButtonProps } from 'src/components/Buttons/Button.types';

type PropsForInput = InputProps;
type PropsForButton = ButtonProps;

export const InputWithButton = ({
  inputProps,
  buttonProps
}: {
  inputProps: PropsForInput;
  buttonProps: PropsForButton;
}) => {
  return (
    <div className="w-full flex justify-start items-center h-[40px]">
      <Input
        {...inputProps}
        containerClassName="rounded-r-none h-full flex-1 bg-background_15 text-text_1"
      />
      <Button {...buttonProps} mode="input-button" />
    </div>
  );
};
