import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OneSignal from 'react-onesignal';
import { MutationCache, Query, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { isDev, isProdEnv } from './api';
import { getJWT } from './api/auth/auth.requests';
import { QueryBoundaries } from './components/QueryBoundaries';
import { Router } from './components/Router';
// import { TwitchWindow } from './components/TwitchWindow';
import { TokenContext, useApiOptions } from './hooks/useApi';
import { DrawerContext, useInitDrawer } from './hooks/useDrawer';
import { FreeSpinsContext, useIniFreeSpins } from './hooks/useFreeSpins';
import { FullScreenContext, useInitFullScreen } from './hooks/useFullScreen';
import { MESSAGE_STATUS, MessageContext, useInitMessage } from './hooks/useMessage';
import { MODAL_NAME, ModalContext, useInitModal } from './hooks/useModal';
// import { TwitchProvider } from './hooks/useTwitch';

import './utils/i18n';

import 'react-tooltip/dist/react-tooltip.css';

if (isProdEnv) {
  OneSignal.init({
    appId: 'd89d6c69-b70f-4968-9bd3-38b682d8ad95',
    serviceWorkerPath: '/build/',
  });
}

export const App = () => {
  const { freeSpinsChanged, oldFreeSpins, saweFreeSpins, setFreeSpinsChanged } = useIniFreeSpins();
  const { closeModal, isTransparentBg, modalProps, openedModal, openModal, showCloseModal } = useInitModal();
  const { clearToken, isAuthenticated, language, saveJWT, saveToken, token, tokenReady } = useApiOptions();
  const { closeMessage, openedMessages, openMessage } = useInitMessage();
  const fullScreenContext = useInitFullScreen();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
        mutationCache: new MutationCache({
          onError: async (error: any, _, __, mutation) => {
            if (error.response.status === 401) {
              const jwtResponse = await getJWT();
              saveJWT(jwtResponse.data.jwt);
              mutation.execute();
              return;
            }
            if (
              error?.response?.data?.message === 'Your session has expired.' &&
              !error?.request?.responseURL?.includes('logout')
            ) {
              clearToken();
              openModal(MODAL_NAME.LOGIN);
            }
            if (
              typeof error?.response?.data?.message === 'string' &&
              error?.response?.data?.message.includes('Undefined property:')
            ) {
              return;
            }
            if (
              typeof error?.response?.data?.message === 'string' &&
              /'Connection timed'/gi.test(error?.response?.data?.message) &&
              /'cURL error'/gi.test(error?.response?.data?.message)
            ) {
              return;
            }
            if (mutation.meta?.preventCommonErrorHandler) {
              return;
            }

            openMessage(error?.response?.data?.message, MESSAGE_STATUS.ERROR);
          },
        }),
        queryCache: new QueryCache({
          onError: async (error: any, query: Query) => {
            if (error.response.status === 401) {
              const jwtResponse = await getJWT();
              saveJWT(jwtResponse.data.jwt);
              query.invalidate();
              return;
            }
            if (error?.response?.data?.message === 'Your session has expired.') {
              clearToken();
              openModal?.(MODAL_NAME.LOGIN, undefined, true, false, true);
            }
            if (
              typeof error?.response?.data?.message === 'string' &&
              /server error/gi.test(error?.response?.data?.message)
            ) {
              return;
            }
            if (error?.response?.status === 404) {
              return;
            }
            if (
              typeof error?.response?.data?.message === 'string' &&
              error?.response?.data?.message.includes('Undefined property:')
            ) {
              return;
            }
            if (error?.response?.data?.errors?.['X-Authorization']) {
              // пока нет обработчика
              return;
            }
            if (
              typeof error?.response?.data?.message === 'string' &&
              /Connection timed/gi.test(error?.response?.data?.message) &&
              /cURL error/gi.test(error?.response?.data?.message)
            ) {
              return;
            }
            openMessage?.(error?.response?.data?.message, MESSAGE_STATUS.ERROR);
          },
        }),
      }),

    [],
  );

  useEffect(() => {
    const idToken = localStorage.getItem('idToken');
    idToken && saveToken?.(idToken);
  }, [saveToken]);

  const drawerContext = useInitDrawer();

  const { i18n } = useTranslation();

  useEffect(() => {
    return () => {
      localStorage.setItem('language', i18n.language);
    };
  }, [i18n.language]);

  return (
    <TokenContext.Provider value={{ clearToken, isAuthenticated, language, saveJWT, saveToken, token, tokenReady }}>
      {/* <TwitchProvider> */}
      {/*   <TwitchWindow></TwitchWindow> */}
      <FullScreenContext.Provider value={fullScreenContext}>
        <DrawerContext.Provider value={drawerContext}>
          <MessageContext.Provider value={{ closeMessage, openedMessages, openMessage }}>
            <ModalContext.Provider
              value={{
                closeModal,
                isTransparentBg,
                modalProps,
                openedModal,
                openModal,
                showCloseModal,
              }}
            >
              <FreeSpinsContext.Provider value={{ freeSpinsChanged, oldFreeSpins, saweFreeSpins, setFreeSpinsChanged }}>
                <QueryClientProvider client={queryClient}>
                  {isDev && <ReactQueryDevtools initialIsOpen={false} />}
                  <QueryBoundaries>
                    <Router />
                  </QueryBoundaries>
                </QueryClientProvider>
              </FreeSpinsContext.Provider>
            </ModalContext.Provider>
          </MessageContext.Provider>
        </DrawerContext.Provider>
      </FullScreenContext.Provider>
      {/* </TwitchProvider> */}
    </TokenContext.Provider>
  );
};
