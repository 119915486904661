import { CONTENT_URL } from 'src/api';
import { useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NewTournament = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModal();
  const navigate = useNavigate();

  return (
    <div className="max-w-[400px]">
      <img src={`${CONTENT_URL}${modalProps.image}`} className="z-0 relative translate-y-[5%] max-h-[200px]" />
      <div className="p-[20px] bg-background_1 z-[1] relative">
        <p className="text-center text-text_8 text-[20px] font-bold mb-[20px]">
          {modalProps?.title}
        </p>
        <p className="text-center text-text_2 text-[16px] font-bold mb-[20px]">
          {modalProps?.description}
        </p>
        <Button
          title={t('general.participate') as string}
          label={t('general.participate') as string}
          mode="primary"
          additionalClassName="!w-full max-w-[400px] mb-[20px]"
          onClick={() => {
            navigate(`/tournaments/${modalProps.code}`, { state: { id: modalProps.code } });
            closeModal?.();
          }}
        />
        <div className="flex gap-3">
          <Button
            title={t('general.cancel') as string}
            label={t('general.cancel') as string}
            mode="secondary"
            additionalClassName="m-auto"
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            title={t('general.readMore') as string}
            label={t('general.readMore') as string}
            mode="primary"
            additionalClassName="m-auto"
            onClick={() => {
              navigate(`/tournaments/${modalProps.code}`, { state: { id: modalProps.code } });
              closeModal?.();
            }}
          />
        </div>
      </div>
    </div>
  );
};
