import { useState, useCallback, Dispatch, SetStateAction } from 'react';
export type UseBooleanReturnType = [
  boolean,
  () => void,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean>>
];
export function useBoolean(initialValue = false): UseBooleanReturnType {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggleValue = useCallback(() => setValue((prevValue) => !prevValue), []);
  return [value, setTrue, setFalse, toggleValue, setValue];
}
