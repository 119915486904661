import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/bigLogoWithDescription.png';
import curacao from 'src/assets/landing/curacaoLanding.png';
import landingDeBg from 'src/assets/landing/landingBg.webp';
import landingEnBg from 'src/assets/landing/landingEnBg.webp';
import poseidon from 'src/assets/landing/landingPoseidon.png';
import rick from 'src/assets/landing/landingRick.png';
import zeus from 'src/assets/landing/landingZeus.png';
import numberOne from 'src/assets/landing/numberOne.png';
import numberOneEn from 'src/assets/landing/numberOneEn.png';
import numberThree from 'src/assets/landing/numberThree.png';
import numberThreeEn from 'src/assets/landing/numberThreeEn.png';
import numberTwo from 'src/assets/landing/numberTwo.png';
import numberTwoEn from 'src/assets/landing/numberTwoEn.png';
import { Responsive } from 'src/components/Responsive';
import { MoreMethods } from 'src/components/svg';
import { AllRightsLanding } from 'src/components/svg/AllRightsLanding';
import { SslLanding } from 'src/components/svg/SslLanding';
import { cardMethods, kryptoMethods } from 'src/constants';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { getBrowserLanguage } from 'src/utils/i18n';

import './index.css';

export const Welcome = ({ title }: { title: string }) => {
  const { openModal } = useModal();
  const lang = getBrowserLanguage();
  const { t } = useTranslation();

  const isDe = lang === 'de';

  return (
    <div className="bg-background_28 font-furuta">
      <div
        className="relative px-[15px] overflow-hidden"
        style={{
          backgroundImage: `url(${isDe ? landingDeBg : landingEnBg})`,
          backgroundPosition: `${isDe ? 'top -25%' : 'left 50%'}`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div
          className="absolute top-0 left-0 w-full h-[250px] rotate-180"
          style={{
            background: 'var(--gradient_12)',
          }}
        />

        <img
          alt="logo"
          className="w-[170px] mt-[20px] md:w-[240px] mx-auto relative"
          src={logo}
        />

        <div
          className={classNames(
            'absolute h-auto',
            isDe
              ? `w-[323px] bottom-[-380px] md:w-[362px] lg:w-[430px] right-[-75px] rickAnimOne md:right-[20px] md:rickAnimTwo`
              : 'w-[430px] bottom-[-355px] right-[-175px] md:w-[570px] md:bottom-[-495px] lg:w-[660px] rickAnimFour md:right-[-151px] md:rickAnimThree r_xl:right-[-270px] r_xl:rickAnimFive',
          )}
        >
          <img
            alt="character"
            className="w-full h-full object-cover"
            src={isDe ? rick : poseidon}
          />
        </div>

        <div
          className={classNames(
            'absolute w-[430px] h-auto bottom-[-280px] md:bottom-[-330px] md:w-[520px] lg:w-[625px] zeusAnimOne left-[-110px] md:zeusAnimTwo md:left-0 r_xl:left-[-140px] r_xl:zeusAnimThree',
          )}
        >
          <img
            alt="character"
            className="w-full h-full object-cover"
            src={zeus}
          />
        </div>

        <div className="relative text-center mx-auto z-30 max-w-[355px] sm:max-w-[320px] md:max-w-[415px] lg:max-w-[600px] flex flex-col items-center justify-center min-h-[90vh] pb-20 mt-[20px]">
          <div className={classNames('mb-10', !isDe && 'text-block-wrapper ')}>
            <div className="text-[32px] leading-10 sm:text-[46px] md:text-[56px] font-black text-text_2 italic uppercase mb-[4px] sm:leading-[55px] md:mb-[15px]">
              {t(`${title}`)}
            </div>
            <div
              className={classNames(
                'text-[20px] leading-5 sm:text-[32px] md:text-[36px] font-semibold uppercase grid gap-[5px] mb-auto sm:leading-[40px]',
                isDe ? 'text-text_14' : 'goldText',
              )}
            >
              <div>{t('landing.freespins')}</div>

              <div className="hidden r_mid:block">
                {t('landing.cashback')} {t('landing.cashbackPercent')}
              </div>

              <div className="block r_mid:hidden">
                <p>{t('landing.cashback')}</p>
                <p>{t('landing.cashbackPercent')}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 lg:hidden"></div>
          <button
            className="buttonAnim font-semibold text-text_2 rounded-[60px] w-[80%] py-[15px] text-[20px] mb-6 uppercase"
            onClick={() => {
              openModal?.(MODAL_NAME.SIGNUP);
            }}
          >
            {t('general.depositNow')}
          </button>

          <div className="text-xl font-medium text-text_2">{t('landing.takeAMinute')}</div>
        </div>

        <div
          className="absolute bottom-0 left-0 w-full h-[410px] z-10"
          style={{
            background: 'var(--gradient_12)',
          }}
        />
      </div>

      <div
        className="relative z-20 mt-[-55px] p-[10px] rounded-[50px] text-text_2 grid gap-[30px] md:gap-[40px] py-[20px] md:py-[37px]"
        style={{
          background: isDe ? 'var(--background_33)' : 'var(--gradient_11)',
        }}
      >
        <Responsive at={['lg', 'xl']}>
          <div className="max-w-[1200px] mx-auto grid gap-[40px] grid-cols-3">
            <NumberItem
              description={`${t('landing.oneDesc')}`}
              image={isDe ? numberOne : numberOneEn}
              title={`${t('landing.oneTitle')}`}
            />
            <NumberItem
              description={`${t('landing.twoDesc')}`}
              image={isDe ? numberTwo : numberTwoEn}
              title={`${t('landing.twoTitle')}`}
            />
            <NumberItem
              description={`${t('landing.threeDesc')}`}
              image={isDe ? numberThree : numberThreeEn}
              title={`${t('landing.threeTitle')}`}
            />
          </div>
        </Responsive>

        <LandingCards cards={cardMethods} />

        <LandingCards
          border={true}
          cards={kryptoMethods}
          label={`${t('landing.depositCrypto')}`}
        />

        <Responsive at={['xs', 'sm', 'md']}>
          <div className="grid gap-6 max-w-[387px] mx-auto">
            <NumberItem
              description={`${t('landing.oneDesc')}`}
              image={isDe ? numberOne : numberOneEn}
              title={`${t('landing.oneTitle')}`}
            />
            <NumberItem
              description={`${t('landing.twoDesc')}`}
              image={isDe ? numberTwo : numberTwoEn}
              title={`${t('landing.twoTitle')}`}
            />
            <NumberItem
              description={`${t('landing.threeDesc')}`}
              image={isDe ? numberThree : numberThreeEn}
              title={`${t('landing.threeTitle')}`}
            />
          </div>
        </Responsive>
      </div>

      <div className="flex flex-col items-center text-center max-w-[780px] mx-auto py-[50px]">
        <img
          alt="logo"
          className="w-[218px] mb-[12px]"
          src={logo}
        />

        <Link
          className="text-[15px] underline text-text_15 mb-[41px]"
          to="/info_pages/terms_and_conditions"
        >
          {t('landing.bonusTerms')}
        </Link>

        <div className="flex justify-between items-center">
          <AllRightsLanding />
          <SslLanding />
          <img
            alt="coracaoLogo"
            className="w-[160px]"
            src={curacao}
          />
        </div>
      </div>
    </div>
  );
};

const NumberItem: FC<{
  description: string;
  image: string;
  title: string;
}> = ({ description, image, title }) => {
  return (
    <div className="flex w-full items-center">
      <img
        alt="landingNumberImage"
        className="mr-[12px] w-[77px] object-contain"
        src={image}
      />
      <div>
        <div className="mb-[4px] text-[20px] font-semibold">{title}</div>
        <div className="text-[17px] leading-[20px]">{description}</div>
      </div>
    </div>
  );
};

const LandingCards: FC<{
  border?: boolean;
  cards: { image: ReactElement; name: string }[];
  label?: string;
  needMore?: boolean;
}> = ({ border = false, cards, label, needMore = false }) => {
  const lang = getBrowserLanguage();
  const isDe = lang === 'de';

  return (
    <div>
      {label && <div className="text-[17px] md:text-[20px] text-center mb-[12px]">{label}</div>}
      <div
        className={classNames(
          'mx-auto max-w-[570px] flex flex-wrap justify-center p-[15px] gap-[5px] rounded-[20px]',
          border && 'border-border_6 border-2',
        )}
      >
        {cards.map((card, id) => {
          if (isDe && card.name === 'Mastercard') {
            return;
          }

          return (
            <div
              className="bg-background_14 basis-[85px] sm:basis-[99px] p-[5px] rounded-[12px] flex flex-col items-center justify-center h-[50px] cardImage"
              key={id}
            >
              {card.image}
            </div>
          );
        })}
        {needMore && (
          <div className="bg-background_14 basis-[85px] sm:basis-[99px] p-[5px] rounded-[12px] flex flex-col items-center justify-center h-[50px] cardImage">
            <MoreMethods />
          </div>
        )}
      </div>
    </div>
  );
};
