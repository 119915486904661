import classNames from 'classnames';
import { FC } from 'react';
import { AccountAsideContainerProps } from './AccountAsideContainer.types';

export const AccountAsideContainer: FC<AccountAsideContainerProps> = ({
  children,
  extended,
  additionalClassName
}) => {
  return (
    <div
      className={classNames(
        'h-full flex flex-col justify-start items-center ease-in-out duration-300',
        extended ? 'w-270' : 'w-70',
        'main-layout-aside',
        additionalClassName
      )}>
      {children}
    </div>
  );
};
