

export default function EffectCoverflow({ extendParams, on, swiper }) {
  extendParams({
    coverflowEffect: {
      slideShadows: true,
      stretch: 0,
    },
  });

  const setTranslate = () => {
    const { height: swiperHeight, slides, slidesGrid, slidesSizesGrid, width: swiperWidth } = swiper;
    const params = swiper.params.coverflowEffect;
    const transform = swiper.translate;
    // debugger;
    // Each slide offset from center
    for (let i = 0, length = slides.length; i < length; i += 1) {
      const slideEl = slides[i];
      const slideSize = slidesSizesGrid[i];
      const slideOffset = slideEl.swiperSlideOffset;
      let s = 0;
      for (let x = 0, length = slidesGrid.length; x < length; x++) {
        if (-transform <= slidesGrid[x]) {
          s = x;
          break;
        }
      }

      s = i - s;
      if (s < 0) {
        s = slides.length + s;
      }

      let offsetMultiplier = params.stretch * s;

      let x1 = slidesGrid[1];
      let x2 = slidesGrid[slidesGrid.length - 1];
      let y1 = (slidesGrid.length - 1) * params.stretch;
      let y2 = params.stretch;
      let x = -transform - slideOffset;

      let asd = -transform - slideOffset;

      if (asd >= 0 && asd <= slidesGrid[1]) {
        y1 = 0;
        y2 = (slidesGrid.length - 1) * params.stretch;
        x1 = 0;
        x2 = slidesGrid[1];
      } else if (asd < 0) {
        y1 = i * params.stretch;
        y2 = 0;
        x1 = 0;
        x2 = slideOffset;
        x = -transform;
      }

      let y = y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);

      let translateY = params.stretch > 0 ? -y : 0;
      let translateX = params.stretch > 0 ? -(slideOffset - y + transform) : 0;

      let scale = params.scale;

      // Fix for ultra small values
      if (Math.abs(translateX) < 0.001) translateX = 0;
      if (Math.abs(translateY) < 0.001) translateY = 0;
      // //if (Math.abs(translateZ) < 0.001) translateZ = 0;
      if (Math.abs(scale) < 0.001) scale = 0;

      // const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(${scale})`;
      const slideTransform = `translate(${translateX}px,${translateY}px) scale(${scale})`;
      const targetEl = effectTarget(params, slideEl);
      targetEl.style.transform = slideTransform;

      slideEl.style.zIndex = -s;

      if (params.slideShadows) {
        // Set shadows
        let shadowBeforeEl = slideEl.querySelector('.swiper-slide-shadow-left');
        let shadowAfterEl = slideEl.querySelector('.swiper-slide-shadow-right');
        if (!shadowBeforeEl) {
          shadowBeforeEl = createShadow('coverflow', slideEl, 'left');
        }
        if (!shadowAfterEl) {
          shadowAfterEl = createShadow('coverflow', slideEl, 'right');
        }
        if (shadowBeforeEl) shadowBeforeEl.style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
        if (shadowAfterEl) shadowAfterEl.style.opacity = -offsetMultiplier > 0 ? -offsetMultiplier : 0;
      }
    }
  };
  const setTransition = (duration) => {
    const transformElements = swiper.slides.map((slideEl) => getSlideTransformEl(slideEl));

    transformElements.forEach((el) => {
      el.style.transitionDuration = `${duration}ms`;
      el.querySelectorAll(
        '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left',
      ).forEach((shadowEl) => {
        shadowEl.style.transitionDuration = `${duration}ms`;
      });
    });
  };

  effectInit({
    effect: 'coverflow',
    on,
    overwriteParams: () => ({
      watchSlidesProgress: true,
    }),
    perspective: () => true,
    setTransition,
    setTranslate,
    swiper,
  });

  function createShadow(params, slideEl, side) {
    const shadowClass = `swiper-slide-shadow${side ? `-${side}` : ''}`;
    const shadowContainer = getSlideTransformEl(slideEl);
    let shadowEl = shadowContainer.querySelector(`.${shadowClass}`);
    if (!shadowEl) {
      shadowEl = createElement('div', `swiper-slide-shadow${side ? `-${side}` : ''}`);
      shadowContainer.append(shadowEl);
    }
    return shadowEl;
  }

  function effectTarget(effectParams, slideEl) {
    const transformEl = getSlideTransformEl(slideEl);
    if (transformEl !== slideEl) {
      transformEl.style.backfaceVisibility = 'hidden';
      transformEl.style['-webkit-backface-visibility'] = 'hidden';
    }
    return transformEl;
  }

  function getSlideTransformEl(slideEl) {
    return (
      slideEl.querySelector('.swiper-slide-transform') ||
      (slideEl.shadowEl && slideEl.shadowEl.querySelector('.swiper-slide-transform')) ||
      slideEl
    );
  }

  function createElement(tag, classes = []) {
    const el = document.createElement(tag);
    el.classList.add(...(Array.isArray(classes) ? classes : [classes]));
    return el;
  }

  function effectInit(params) {
    const {
      effect,
      getEffectParams,
      on,
      overwriteParams,
      perspective,
      recreateShadows,
      setTransition,
      setTranslate,
      swiper,
    } = params;
    on('beforeInit', () => {
      if (swiper.params.effect !== effect) return;
      swiper.classNames.push(`${swiper.params.containerModifierClass}${effect}`);
      if (perspective && perspective()) {
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
      }
      const overwriteParamsResult = overwriteParams ? overwriteParams() : {};
      Object.assign(swiper.params, overwriteParamsResult);
      Object.assign(swiper.originalParams, overwriteParamsResult);
    });
    on('setTranslate', () => {
      if (swiper.params.effect !== effect) return;
      setTranslate();
    });
    on('setTransition', (_s, duration) => {
      if (swiper.params.effect !== effect) return;
      setTransition(duration);
    });
    on('transitionEnd', () => {
      swiper.loopFix({
        direction: swiper.swipeDirection,
      });
      if (swiper.params.effect !== effect) return;
      if (recreateShadows) {
        if (!getEffectParams || !getEffectParams().slideShadows) return;
        // remove shadows
        swiper.slides.forEach((slideEl) => {
          slideEl
            .querySelectorAll(
              '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left',
            )
            .forEach((shadowEl) => shadowEl.remove());
        });
        // create new one
        recreateShadows();
      }
    });
    // on('transitionStart', () => {
    //     swiper.loopFix({
    //         direction: swiper.swipeDirection
    //       });
    //
    // });
    let requireUpdateOnVirtual;
    on('virtualUpdate', () => {
      if (swiper.params.effect !== effect) return;
      if (!swiper.slides.length) {
        requireUpdateOnVirtual = true;
      }
      requestAnimationFrame(() => {
        if (requireUpdateOnVirtual && swiper.slides && swiper.slides.length) {
          setTranslate();
          requireUpdateOnVirtual = false;
        }
      });
    });
  }
}
