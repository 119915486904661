import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useFreeSpins } from 'src/hooks/useFreeSpins';

import { modalMap } from '../../constants';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { SimpleClose } from '../svg';

export const Modal = () => {
  const { closeModal, isTransparentBg, openedModal, showCloseModal } = useModal();
  const { setFreeSpinsChanged } = useFreeSpins();

  const InnerContent = useMemo(() => (openedModal ? modalMap[openedModal] : null), [openedModal]);

  useEffect(() => {
    if (InnerContent) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [InnerContent]);

  const handleCloseClick = () => {
    if (openedModal === MODAL_NAME.POPUP_DEPOSIT) {
      sendGoogleAnalyticsEvent('popup_closed', {
        popup_id: MODAL_NAME.POPUP_DEPOSIT,
      });
    }

    setFreeSpinsChanged?.(false);
    closeModal?.();
  };

  return (
    <div
      className="fixed left-0 top-0 z-[900] flex h-screen w-screen flex-col items-center justify-center self-center bg-background_9 transition-all"
      style={{ transform: openedModal ? 'translateY(0px)' : 'translateY(-100%)' }}
    >
      <div className="flex max-w-[90%] flex-col items-center justify-center max-h-screen-90">
        <div className="mb-[24px] flex w-full items-center justify-end">
          {showCloseModal && <SimpleClose onShow={handleCloseClick} />}
        </div>
        <div
          className={classNames(
            'modal-wrapper max-h-[100%] max-w-[100%] overflow-y-auto rounded-[20px] shadow-xl',
            !isTransparentBg ? 'bg-background_1' : 'bg-opacity-0',
            openedModal === MODAL_NAME.POPUP_DEPOSIT && 'deposit-modal-window',
          )}
        >
          {InnerContent ? <InnerContent /> : null}
        </div>
      </div>
    </div>
  );
};
