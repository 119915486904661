import { ChangeEvent, MouseEvent } from 'react';

import { DropDownOption } from '../../types';
import { Validator } from './useForm.types';

export enum FieldType {
  Text,
  Date,
  Autocomplete,
  Checkbox,
  Dropdown,
  Radio,
  Multiselect,
  Number,
  WithoutWildcards,
  Phone,
}

export type AnyFieldConfig = {
  label?: string;
  validation?: Validator[];
};

// TEXT
export type TextFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Text;
  placeholder?: string;
};
export type TextFieldProps = {
  name: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  placeholder?: string;
  value: string;
};

// DATE
export type DateFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Date;
  placeholder?: string;
};
export type DateFieldProps = {
  name: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

// AUTOCOMPLETE
export type AutocompleteFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Autocomplete;
  options?: DropDownOption[];
  placeholder?: string;
};
export type AutocompleteFieldProps = {
  filter: (value: string | undefined, options: DropDownOption[]) => DropDownOption[];
  name: string;
  onBlur: () => void;
  onChange: (value: string | undefined, option?: DropDownOption) => void;
  onFocus: () => void;
  onSelect: (value: string | undefined, option?: DropDownOption) => void;
  options?: DropDownOption[];
  placeholder?: string;
  value: string;
};

// CHECKBOX
export type CheckboxFieldConfig = AnyFieldConfig & {
  defaultValue?: boolean;
  fieldType: FieldType.Checkbox;
};
export type CheckboxFieldProps = {
  checked: boolean;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

// DROPDOWN
export type DropdownFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Dropdown;
  options?: DropDownOption[];
  placeholder?: string;
};
// TODO перепроверить
export type DropdownFieldProps = {
  label?: string;
  name: string;
  onChange: (element?: string) => void;
  options?: DropDownOption[];
  value: string | undefined;
};

// RADIO
export type RadioFieldConfig = AnyFieldConfig & {
  defaultValue?: boolean;
  fieldType: FieldType.Radio;
  options?: DropDownOption[];
};
// TODO перепроверить все типы
export type RadioFieldProps = {
  // defaultChecked?: boolean;
  defaultValue?: string;
  name: string;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  options?: DropDownOption[];
  value: string;
};

// MULTISELECT
export type MultiselectFieldConfig = AnyFieldConfig & {
  defaultValue?: string[];
  fieldType: FieldType.Multiselect;
  options?: DropDownOption[];
  placeholder?: string;
};
export type MultiselectFieldProps = {
  name: string;
  onBlur: () => void;
  onChange: (selectedValues: string[], selectedOptions: DropDownOption[]) => void;
  options?: DropDownOption[];
  placeholder?: string;
  values: string[];
};

// Number
export type NumberFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Number;
  placeholder?: string;
};
export type NumberFieldProps = {
  name: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export type PhoneFieldConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.Phone;
  phoneCode: string;
  placeholder?: string;
};
export type PhoneFieldProps = {
  name: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export type WithoutWildcardsConfig = AnyFieldConfig & {
  defaultValue?: string;
  fieldType: FieldType.WithoutWildcards;
  placeholder?: string;
};
export type WithoutWildcardsProps = {
  name: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export type FieldConfig<T extends FieldType> = T extends FieldType.Text
  ? TextFieldConfig
  : T extends FieldType.Date
  ? DateFieldConfig
  : T extends FieldType.Autocomplete
  ? AutocompleteFieldConfig
  : T extends FieldType.Checkbox
  ? CheckboxFieldConfig
  : T extends FieldType.Dropdown
  ? DropdownFieldConfig
  : T extends FieldType.Radio
  ? RadioFieldConfig
  : T extends FieldType.Multiselect
  ? MultiselectFieldConfig
  : T extends FieldType.Number
  ? NumberFieldConfig
  : T extends FieldType.WithoutWildcards
  ? WithoutWildcardsConfig
  : T extends FieldType.Phone
  ? PhoneFieldConfig
  : never;

export type FieldProps<T extends FieldType> = T extends FieldType.Text
  ? TextFieldProps
  : T extends FieldType.Date
  ? DateFieldProps
  : T extends FieldType.Autocomplete
  ? AutocompleteFieldProps
  : T extends FieldType.Checkbox
  ? CheckboxFieldProps
  : T extends FieldType.Dropdown
  ? DropdownFieldProps
  : T extends FieldType.Radio
  ? RadioFieldProps
  : T extends FieldType.Multiselect
  ? MultiselectFieldProps
  : T extends FieldType.Number
  ? NumberFieldProps
  : T extends FieldType.WithoutWildcards
  ? WithoutWildcardsProps
  : T extends FieldType.Phone
  ? PhoneFieldProps
  : never;

export type FieldState<T extends FieldType> = T extends FieldType.Text
  ? string
  : T extends FieldType.Date
  ? string
  : T extends FieldType.Autocomplete
  ? string | undefined
  : T extends FieldType.Checkbox
  ? boolean
  : T extends FieldType.Dropdown
  ? string | undefined
  : T extends FieldType.Radio
  ? string | undefined
  : T extends FieldType.Multiselect
  ? string[] | undefined
  : T extends FieldType.Number
  ? string
  : T extends FieldType.WithoutWildcards
  ? string
  : T extends FieldType.Phone
  ? string
  : never;
