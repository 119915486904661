import { useMemo } from 'react';
import {
  arrayFromRange,
  leftItemCount,
  paginationDots,
  rightItemCount,
  siblingCount,
  totalPageNumbers
} from 'src/constants/usePaginationConstants';

export const usePagination = (pages: number, currentPage: number = 1): (string | number)[] => {
  const pagination = useMemo(() => {
    if (totalPageNumbers >= pages) {
      return arrayFromRange(1, pages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pages - 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = arrayFromRange(1, leftItemCount);
      return [...leftRange, paginationDots, pages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = arrayFromRange(pages - rightItemCount + 1, pages);
      return [1, paginationDots, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = arrayFromRange(leftSiblingIndex, rightSiblingIndex);
      return [1, paginationDots, ...middleRange, paginationDots, pages];
    }

    return [];
  }, [pages, currentPage]);

  return pagination;
};
