import { useBoolean } from 'src/hooks/useBoolean';
import { SimpleClose } from '../svg';
import './styles.css';
import classNames from 'classnames';
import { CONTENT_URL } from 'src/api';

export const VerificationPreviewCard = ({
  url,
  className,
  isLocalFile,
  onDelete
}: {
  url?: string;
  className?: string;
  isLocalFile?: boolean;
  onDelete?: () => void;
}) => {
  const [isFullscreen, open, close] = useBoolean();

  if (isFullscreen) {
    return (
      <div className="lightbox show">
        <SimpleClose onShow={close} className="fixed top-0 right-[10px] mt-2.5" />
        <img src={`${CONTENT_URL}${url}`} alt="verification card" className="show_image" />
      </div>
    );
  }

  return (
    <div className={classNames(className, 'h-full shrink-0 flex flex-row')}>
      <img
        onClick={open}
        src={`${isLocalFile ? '' : CONTENT_URL}${url}`}
        className={classNames(
          'w-[65px] h-[75px] self-center text-center cursor-pointer rounded-[5px] border-[1px] border-vap_preview_card_border',
          isLocalFile && 'opacity-50'
        )}
        alt="verification card"
      />
      {isLocalFile && (
        <div className="w-3 h-3 ml-1" onClick={onDelete}>
          <SimpleClose className="w-full" />
        </div>
      )}
    </div>
  );
};
