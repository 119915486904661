import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'src/api/transactions';
import { TableCell } from 'src/components/Table/TableCell';
import { TablePagination } from 'src/components/Table/TablePagination';
import { findSortById, getCurrencyIcon, makeFloat } from 'src/utils';
import { useGetTransactions } from '../../../api/transactions/transactions.hooks';
import { Table } from '../../../components/Table';
import { HistoryForm } from './HistoryForm';
import { StatusCell } from './StatusCell';
import { useTransactionHistory } from './useTransactionHistory';
import { transactionHistoryHeader } from 'src/constants';
import { useOpenDepositPaymentInfo } from 'src/hooks/useOpenDepositPaymentInfo';
import { useAutoDepositSuccess } from 'src/modules/autoDeposit';
// import { useAutoDepositSuccess } from 'src/modules/autoDeposit';

export const TransactionHistory = () => {
  const { t } = useTranslation();
  const [cleared, setCleared] = useState<boolean>(false);

  const sortType = [
    { id: 'amountAsc', name: `${t('sortType.amountUp')}`, sort: 'amount', sortDir: 'asc' },
    { id: 'amountDesc', name: `${t('sortType.amountDown')}`, sort: 'amount', sortDir: 'desc' },
    { id: 'dateAsc', name: `${t('sortType.dateUp')}`, sort: 'date', sortDir: 'asc' },
    { id: 'dateDesc', name: `${t('sortType.dateDown')}`, sort: 'date', sortDir: 'desc' }
  ];

  const isOfNeededType = [
    { id: '0', name: `${t('sortType.deposit')}` },
    { id: '1', name: `${t('sortType.withdrawal')}` },
    { id: '6', name: `${t('sortType.balanceInc')}` },
    { id: '7', name: `${t('sortType.balanceDec')}` }
  ];

  const {
    values,
    resetForm,
    fieldsProps: {
      dateFromProps,
      dateToProps,
      amountFromProps,
      amountToProps,
      sortByProps,
      typeProps
    }
  } = useTransactionHistory(sortType, isOfNeededType);

  const { arrayTransactions, refetch, page, setPage, numberPages } = useGetTransactions({
    amount_from: values.amountFrom ? parseFloat(values.amountFrom) / 0.01 : undefined,
    amount_to: values.amountTo ? parseFloat(values.amountTo) / 0.01 : undefined,
    date_end: values.dateTo ? values.dateTo : undefined,
    date_start: values.dateFrom ? values.dateFrom : undefined,
    type: values.type?.length === 0 ? '0,1,6,7' : values.type?.join(','),
    sort: findSortById(values.sortBy!)?.sort,
    sort_dir: findSortById(values.sortBy!)?.sortDir
  });

  useOpenDepositPaymentInfo({ transactions: arrayTransactions });

  useAutoDepositSuccess({ transactions: arrayTransactions });

  const handleNextPage = useCallback(() => {
    setPage((old) => old + 1);
  }, [setPage]);

  const handlePrevPage = useCallback(() => {
    setPage((old) => Math.max(old - 1, 0));
  }, [setPage]);

  const hanleChangePage = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleClearForm = useCallback(() => {
    resetForm();
    setCleared(!cleared);
  }, [cleared, resetForm]);

  useEffect(() => {
    refetch();
  }, [refetch, page, cleared]);

  return (
    <div className="flex flex-col gap-10">
      <div className="max-w-[550px]">
        <HistoryForm
          refetch={refetch}
          resetForm={handleClearForm}
          dateFromProps={dateFromProps}
          dateToProps={dateToProps}
          amountFromProps={amountFromProps}
          amountToProps={amountToProps}
          sortByProps={sortByProps}
          typeProps={typeProps}
        />
      </div>
      {arrayTransactions?.length === 0 ? (
        <div className="flex flex-col gap-3">
          <p className="font-bold text-2xl text-text_8">{t('transactionTable.notFoundBasic')}</p>
          <p className="font-medium text-sm text-text_2">
            {t('transactionTable.notFoundBasicText')}
          </p>
        </div>
      ) : (
        <>
          <p className="text-text-secondary text-[10px] font-bold leading-[16px] text-right mb-2.5 responsiveHide">
            {t('general.tapScroll')} &gt;&gt;
          </p>
          <div className="overflow-auto">
            <div className="w-[900px]">
              <Table
                header={transactionHistoryHeader}
                tableRow={arrayTransactions?.map((data, index) => (
                  <TableRowTransaction key={index} data={data} />
                ))}
              />
            </div>
          </div>
          {numberPages ? (
            <TablePagination
              currentPage={page}
              pages={numberPages}
              nextPage={handleNextPage}
              prevPage={handlePrevPage}
              onClick={hanleChangePage}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

function TableRowTransaction({ data }: { data: Transaction }) {
  const { id, type, amount, dateComplete, dateCreate, currency, status, commission, stringId } =
    data;
  const { t } = useTranslation();

  const TRANSACTION_STATUS: { [key: string]: string } = {
    '0': `${t('transactionStatus.pending')}`,
    '1': `${t('transactionStatus.complete')}`,
    '2': `${t('transactionStatus.cancel')}`,
    '3': `${t('transactionStatus.failed')}`,
    '4': `${t('transactionStatus.refund')}`,
    '5': `${t('transactionStatus.pendingWager')}`,
    '6': `${t('transactionStatus.rejected')}`,
    '7': `${t('transactionStatus.notConfirmed')}`,
    '8': `${t('transactionStatus.processing')}`,
    '9': `${t('transactionStatus.aggregatorRejected')}`,
    '10': `${t('transactionStatus.rollback')}`,
    '11': `${t('transactionStatus.wagerZeroing')}`,
    '12': `${t('transactionStatus.notConfirmedBlockAmount')}`
  };

  const TRANSACTION_TYPE: string[] = [
    `${t('transactionType.deposit')}`,
    `${t('transactionType.withdraw')}`,
    `${t('transactionType.bet')}`,
    `${t('transactionType.win')}`,
    `${t('transactionType.bonus')}`,
    `${t('transactionType.jackpot')}`,
    `${t('transactionType.changeBalanceInc')}`,
    `${t('transactionType.changeBalanceDec')}`,
    `${t('transactionType.referral')}`,
    `${t('transactionType.convertBalance')}`,
    `${t('transactionType.moneyPrize')}`,
    `${t('transactionType.bonusTransaction')}`,
    `${t('transactionType.cancelBonusFine')}`,
    `${t('transactionType.refund')}`,
    `${t('transactionType.rollbackBet')}`,
    `${t('transactionType.rollbackWin')}`,
    `${t('transactionType.betSport')}`,
    `${t('transactionType.jackpotIncreasing')}`,
    `${t('transactionType.freeSpinWin')}`,
    `${t('transactionType.zeroing')}`,
    `${t('transactionType.winSport')}`,
    `${t('transactionType.increment')}`,
    `${t('transactionType.cashback')}`,
    `${t('transactionType.levelBonus')}`
  ];

  return (
    <tr className="even:bg-background_12 text-center">
      <TableCell item={id} />
      <TableCell item={TRANSACTION_TYPE[type]} />
      <TableCell item={makeFloat(amount).toFixed(2)} />
      <TableCell item={`${makeFloat(commission).toFixed(2)}${getCurrencyIcon(currency)}`} />
      <TableCell item={`${currency}`} />
      <StatusCell
        id={stringId}
        transactionId={id}
        item={`${dayjs(dateComplete === 0 ? dateCreate * 1000 : dateComplete * 1000).format(
          'DD-MM-YYYY'
        )} ${TRANSACTION_STATUS[status]}`}
        status={status}
        type={type}
        // className="flex flex-col"
      />
    </tr>
  );
}
