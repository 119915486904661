import classNames from 'classnames';
import { useState } from 'react';
import { Eye } from '../svg';
import { Input, InputProps } from './Input';

export type PasswordInputProps = InputProps;

export const PasswordInput = ({
  label,
  labelWithPadding,
  ...inputPasswordProps
}: PasswordInputProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="flex flex-col justify-between h-full items-start gap-2 w-[100%]">
      {label ? (
        <label className={classNames('text-base text-text_2', labelWithPadding && 'pl-[20px]')}>
          {label}
        </label>
      ) : null}
      <Input
        className="placeholder:text-text-placeholder"
        {...inputPasswordProps}
        htmlType={passwordShown ? 'text' : 'password'}
        rightIcon={<Eye setPasswordShown={setPasswordShown} passwordShown={passwordShown} />}
      />
    </div>
  );
};
