import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import bigLogo from 'src/assets/images/bigLogoWithDescription.png';
import smallLogo from 'src/assets/images/smallLogo.png';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';
import { useDrawer } from 'src/hooks/useDrawer';

import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { DesktopCollapsedSidebarContent } from '../Sidebar/DesktopCollapsedSidebarContent';
import { SidebarContent } from '../Sidebar/SidebarContent';
import { SidebarArrow } from '../svg';

import './index.css';

export const AsideInner: FC = () => {
  const [isUserChange, setIsUserChange] = useState(false);

  const {
    _closeAccountSidebar,
    _closeMainAccountSidebar,
    _closeMainSidebar,
    _mainAccountSidebarOpened,
    _mainSidebarOpened,
    _openAccountSidebar,
    _openMainAccountSidebar,
    _openMainSidebar,
    closeLeftDrawer,
  } = useDrawer();

  const isOpened = _mainSidebarOpened || _mainAccountSidebarOpened;

  const toggle = () => {
    if (!isUserChange) {
      setIsUserChange(true);
    }

    if (isOpened) {
      _closeMainAccountSidebar?.();
      _closeMainSidebar?.();
      _openAccountSidebar?.();
    } else {
      sendGoogleAnalyticsEvent('burger_menu_opened');

      _openMainAccountSidebar?.();
      _openMainSidebar?.();
      _closeAccountSidebar?.();
    }
  };

  const location = useLocation();
  const { isXl, isXs, isXxs } = useBreakpoints();
  const isMobileScreen = isXxs || isXs;

  useEffect(() => {
    if (!isUserChange) {
      if (isXl && !location.pathname.startsWith('/account')) {
        _openMainAccountSidebar?.();
        _openMainSidebar?.();
        _closeAccountSidebar?.();
      } else {
        _closeMainAccountSidebar?.();
        _closeMainSidebar?.();
        _openAccountSidebar?.();
      }
    }
  }, [isXl]);

  return (
    <div
      className={classNames(
        'flex min-h-full w-full flex-col items-center justify-start py-6 text-text_2',
        isOpened ? 'pl-[15px]' : '',
      )}
    >
      <div
        className={classNames('toggle-container cursor-pointer', isOpened && 'toggle-container__opened')}
        onClick={toggle}
      >
        <SidebarArrow />
      </div>
      <Link
        onClick={() => {
          isMobileScreen && closeLeftDrawer?.();
        }}
        to="/"
      >
        {isOpened ? (
          <div
            className="logo logo__opened"
            onClick={() => isMobileScreen && _closeMainSidebar?.()}
          >
            <img
              height="50"
              src={bigLogo}
              width="170"
            />
          </div>
        ) : (
          <div className="mt-[25px]">
            <img
              alt="logo"
              className="logo"
              src={smallLogo}
            />
          </div>
        )}
      </Link>
      {isOpened ? (
        <div className="sidebarScroll flex w-full flex-col items-center justify-start pr-[10px] pb-[70px]">
          <SidebarContent />
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-start overflow-auto">
          <DesktopCollapsedSidebarContent />
        </div>
      )}
    </div>
  );
};
