export const paginationDots: string = '...';
export const siblingCount = 1;
export const totalPageNumbers = siblingCount + 5;
export const leftItemCount = 4;
export const rightItemCount = 4;

export const arrayFromRange = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};
