export const VipCardInfo = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.875 1.85767C5.01963 1.85767 1.89423 4.98307 1.89423 8.83844C1.89423 12.6938 5.01963 15.8192 8.875 15.8192C12.7304 15.8192 15.8558 12.6938 15.8558 8.83844C15.8558 4.98307 12.7304 1.85767 8.875 1.85767ZM0.625 8.83844C0.625 4.28209 4.31865 0.58844 8.875 0.58844C13.4313 0.58844 17.125 4.28209 17.125 8.83844C17.125 13.3948 13.4313 17.0884 8.875 17.0884C4.31865 17.0884 0.625 13.3948 0.625 8.83844Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60577 8.20382C7.60577 7.85334 7.8899 7.56921 8.24038 7.56921H8.875C9.22549 7.56921 9.50962 7.85334 9.50962 8.20382V12.0115C9.8601 12.0115 10.1442 12.2956 10.1442 12.6461C10.1442 12.9966 9.8601 13.2807 9.50962 13.2807H8.875C8.52451 13.2807 8.24038 12.9966 8.24038 12.6461V8.83844C7.8899 8.83844 7.60577 8.55431 7.60577 8.20382Z"
        fill="white"
      />
      <path
        d="M8.71635 6.29998C9.24208 6.29998 9.66827 5.87379 9.66827 5.34806C9.66827 4.82232 9.24208 4.39613 8.71635 4.39613C8.19061 4.39613 7.76442 4.82232 7.76442 5.34806C7.76442 5.87379 8.19061 6.29998 8.71635 6.29998Z"
        fill="white"
      />
    </svg>
  );
};
