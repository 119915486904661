import { useEffect, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import nl from './assets/images/countries/NL.png';
import award from './assets/images/icons/Award1.png';
import c24x7 from './assets/images/icons/c24x7.png';
import gift from './assets/images/icons/gift.png';
import money from './assets/images/icons/money.png';
import profile from './assets/images/icons/ProfileAccepted2.png';
import logoBrandname from './assets/images/logo-brandname.svg';
import logoTrademark from './assets/images/logo-trademark.svg';
import logo from './assets/images/logo.svg';
import r18 from './assets/images/row-3/18plus.svg';
import { bottomMenu, jackpotImages, partners, row1Images, row2Images, socialMedia } from './constants';
import EffectCoverflow from './EffectCoverflow.js';
import { SignUp } from './SignUp';

import 'swiper/css';
import 'swiper/css/pagination';

import './index.scss';

export const Welcome2 = () => {
  const [coverflowEffect, setCoverflowEffect] = useState(() => {
    if (window.innerWidth < 1280)
      return {
        modifier: 1, // Модификатор множителя
        rotate: 0, // Угол поворота слайдов
        scale: 1,
        slideShadows: false, // Показывать тени
        stretch: 0,
      };
    else
      return {
        modifier: 1, // Модификатор множителя
        rotate: 0, // Угол поворота слайдов
        scale: 1,
        slideShadows: false, // Показывать тени
        stretch: 50,
      };
  });
  const initialSwiperSlidesPerView: 'auto' | number = window.innerWidth < 1280 ? 1.2 : 'auto';
  const [swiperKey, setSwiperKey] = useState(window.innerWidth < 1280 ? 0 : 1);
  const [swiperSlidesPerView, setSwiperSlidesPerView] = useState(initialSwiperSlidesPerView);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1280) {
        setCoverflowEffect({
          modifier: 1, // Модификатор множителя
          rotate: 0, // Угол поворота слайдов
          scale: 1,
          slideShadows: false, // Показывать тени
          stretch: 0,
        });
        setSwiperKey(0);
        setSwiperSlidesPerView(1.2);
      } else {
        setCoverflowEffect({
          modifier: 1, // Модификатор множителя
          rotate: 0, // Угол поворота слайдов
          scale: 1,
          slideShadows: false, // Показывать тени
          stretch: 50,
        });
        setSwiperKey(1);
        setSwiperSlidesPerView('auto');
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="relative z-0 bs overflow-hidden">
      <h1 className="hidden">Betsomnia</h1>

      <header className="bs-header relative py-8 xl:pt-[7.5rem]">
        <div className="bs-header__wrapper bs-container relative">
          <div className="bs-header__inner xl:grid xl:grid-flow-col xl:grid-rows-[auto_auto_minmax(0,_1fr)] xl:justify-between">
            <div className="text-center xl:row-span-2 xl:text-left">
              <a
                className="mb-3 inline-block xl:mb-8"
                href="/"
              >
                <img
                  alt="Betsomnia"
                  className="inline-block h-[4.25rem] w-auto xl:h-[6.25rem]"
                  src={logoTrademark}
                />
              </a>
              <div>
                <h2 className="inline-block font-['Paytone_One'] text-2xl font-normal uppercase xl:text-[3.25rem]/[3.75rem]">
                  Welcome to
                  <img
                    alt="Betsomnia"
                    className="mx-auto mb-5 mt-1 block h-8 w-auto xl:mx-0 xl:mb-10 xl:mt-2 xl:h-[4.75rem]"
                    src={logoBrandname}
                  />
                </h2>
              </div>
              <p className="mx-auto mb-6 xl:mb-[3.25rem] xl:w-auto font-poppins">
                <span className="block xl:inline-block font-poppins">Dive into the exclusive world of the</span>
                <span className="mr-2 space-x-2 xl:mx-2">
                  <span
                    className="inline-block h-3 w-[1.125rem] bg-cover bg-center bg-no-repeat align-middle xl:h-5 xl:w-[1.875rem] xl:align-sub"
                    style={{
                      backgroundImage: `url(${nl})`,
                    }}
                  ></span>
                  <span className="bs-header__country font-poppins">Netherlands</span>
                </span>
                VIP online gambling!
              </p>
            </div>

            <div
              className={`bs-slider overflow-hidden -mx-4 xl:-mt-8 sm:px-0 xl:order-last xl:row-span-3 xl:w-[31.5rem]`}
            >
              <Swiper
                centeredSlides={true}
                className="pb-6 xl:pt-28 xl:pr-4 mb-2"
                coverflowEffect={coverflowEffect}
                effect="coverflow"
                grabCursor={true}
                key={swiperKey}
                loop={true}
                loopedSlides={1}
                loopPreventsSliding={false}
                modules={[Pagination, EffectCoverflow]}
                pagination={{ clickable: true }}
                slidesPerView={swiperSlidesPerView}
                slideToClickedSlide={true}
                spaceBetween={20}
              >
                <SwiperSlide>
                  <div className="bs-slider-slide w-full relative xl:w-[400px] h-[360px] bg-background_40 rounded-3xl">
                    <div className="h-72 left-[20px] top-[20px] flex-col justify-start items-start gap-4 inline-flex text-text_2">
                      <div className="text-white text-2xl font-normal font-paytone-one uppercase leading-12">
                        No limits
                      </div>
                      <div className="font-poppins">
                        <div className="self-stretch opacity-60 text-xs font-medium leading-tight">
                          We don't think that local limits are okay, so we just canceled them!
                          <br />
                          <br />
                          If you are a regular player you can withdraw:
                        </div>
                        <div className="flex-col justify-start items-start gap-2 mt-5 mb-5 flex">
                          <div className="justify-start items-center gap-2 inline-flex">
                            <div className="px-1.5 py-0.5 bg-border_8 bg-opacity-5 rounded-[800px] justify-center items-center flex">
                              <div className="text-white text-sm font-medium leading-tight">€</div>
                            </div>
                            <div className="text-white text-sm font-medium leading-tight">5,000 DAILY</div>
                          </div>
                          <div className="justify-start items-center gap-2 inline-flex">
                            <div className="px-1.5 py-0.5 bg-border_8 bg-opacity-5 rounded-[800px] justify-center items-center flex">
                              <div className="text-white text-sm font-medium leading-tight">€</div>
                            </div>
                            <div className="text-white text-sm font-medium leading-tight">10,000 WEEKLY</div>
                          </div>
                          <div className="justify-start items-center gap-2 inline-flex">
                            <div className="px-1.5 py-0.5 bg-border_8 bg-opacity-5 rounded-[800px] justify-center items-center flex">
                              <div className="text-white text-sm font-medium leading-tight">€</div>
                            </div>
                            <div className="text-white text-sm font-medium leading-tight">30,000 MONTHLY</div>
                          </div>
                        </div>
                        <div className="self-stretch text-white text-xs font-medium uppercase leading-tight">
                          Become our VIP player, and get limits even doubled from this!
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bs-slider-slide w-full relative xl:w-[400px] h-[360px] bg-background_40 rounded-3xl">
                    <div className="h-72 left-[20px] top-[20px] flex-col justify-start items-start gap-4 inline-flex text-text_2">
                      <div className="h-72 flex-col justify-start items-start gap-3 flex">
                        <div className="text-white text-2xl font-normal font-paytone-one uppercase leading-10">
                          HUGE BONUSES
                        </div>
                        <div className="self-stretch h-[236px] flex-col justify-start items-start gap-3 flex font-poppins">
                          <div className="self-stretch opacity-60 text-white text-xs font-medium leading-tight">
                            Look no further than Betsomnia, the ultimate destination for avid thrill and bonuses
                            seekers.
                          </div>
                          <div className="self-stretch">
                            <span className="opacity-60 text-xs font-medium leading-tight">
                              Here you can make a deposit of
                            </span>
                            <span className="text-white text-xs font-medium leading-tight ml-1 mr-1">25,000€</span>
                            <span className="text-white opacity-60 text-xs font-medium leading-tight">
                              and instantly witness your balance soar to an incredible
                            </span>
                            <span className="text-xs font-medium leading-tight ml-1">50,000€!</span>
                          </div>
                          <div className="self-stretch opacity-60 text-white text-xs font-medium leading-tight">
                            Our exclusive welcome offers are designed to give you an exhilarating head start.
                          </div>
                          <div className="self-stretch">
                            <span className="text-white opacity-60 text-xs font-medium  leading-tight">
                              Our generosity knows no bounds -{' '}
                            </span>
                            <span className="text-white text-xs font-medium leading-tight">1st, 2nd, 3rd </span>
                            <span className="text-white opacity-60 text-xs font-medium leading-tight">
                              deposit, and beyond, we have a multitude of amazing bonuses waiting for you!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="bs-slider-slide w-full relative xl:w-[400px] h-[360px] bg-background_40 rounded-3xl">
                    <div className="w-[280px] h-[276px] text-text_2 flex-col justify-start items-start gap-6 inline-flex">
                      <div className="self-stretch h-[92px] flex-col justify-start items-start gap-3 flex">
                        <div className="text-white text-2xl font-normal font-paytone-one uppercase leading-10">
                          VIP Program
                        </div>
                        <div className="w-[220px] opacity-60 text-white text-xs font-medium font-poppins leading-tight">
                          For us VIP is not just an abbreviation, but a call to action! That’s why we:
                        </div>
                      </div>
                      <div className="self-stretch h-40 flex-col justify-start items-start gap-4 flex font-poppins">
                        <div className="justify-start items-center gap-3 inline-flex">
                          <div className="w-4 h-4 pl-[0.31px] pr-[0.49px] pt-[0.17px] pb-[0.74px] justify-center items-center flex">
                            <img
                              alt="award"
                              className="w-full h-full"
                              src={award}
                            />
                          </div>
                          <div className="text-white text-xs font-medium font-['Poppins'] leading-none">
                            Give our VIPs high rewards
                          </div>
                        </div>
                        <div className="self-stretch justify-start items-center gap-3 inline-flex">
                          <div className="w-4 h-4">
                            <img
                              alt="profile"
                              className="w-full h-full"
                              src={profile}
                            />
                          </div>
                          <div className="grow shrink basis-0 text-white text-xs font-medium font-['Poppins'] leading-none">
                            Provide mega-professional personal VIP managers
                          </div>
                        </div>
                        <div className="justify-start items-center gap-3 inline-flex">
                          <div className="w-4 h-4 relative">
                            <img
                              alt="mega-professional personal VIP managers"
                              className="w-full h-full"
                              src={c24x7}
                            />
                          </div>
                          <div className="text-white text-xs font-medium font-['Poppins'] leading-none">
                            Work 24/7 for your convenience
                          </div>
                        </div>
                        <div className="justify-start items-center gap-3 inline-flex">
                          <div className="w-4 h-4 relative">
                            <img
                              alt="mega-professional personal VIP managers"
                              className="w-full h-full"
                              src={gift}
                            />
                          </div>
                          <div className="text-white text-xs font-medium font-['Poppins'] leading-none">
                            Give private bonuses for VIP players
                          </div>
                        </div>
                        <div className="justify-start items-center gap-3 inline-flex">
                          <div className="w-4 h-4 relative">
                            <img
                              alt="mega-professional personal VIP managers"
                              className="w-full h-full"
                              src={money}
                            />
                          </div>
                          <div className="text-white text-xs font-medium font-['Poppins'] leading-none">
                            Guarantee increased cashback up to 20%!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div>
              <a
                className="bs-btn block rounded-2xl bg-yellow-500 px-16 py-3 text-center justify-center xl:inline-block"
                href="#get_bonus"
              >
                Get bonus
              </a>
            </div>
          </div>
        </div>
      </header>

      <section
        className="bs-section-bonus relative"
        id="get_bonus"
      >
        <div className="bs-container pb-10 pt-60 xl:pb-20 xl:pt-12">
          <div className="bs-section-bonus__card relative text-center xl:h-[18.25rem] xl:rounded-3xl xl:p-[3.25rem] xl:text-left">
            <div className="mb-6 xl:flex xl:items-baseline">
              <h2 className="mb-2 font-['Paytone_One'] text-2xl font-normal uppercase xl:mb-0 xl:text-[2rem]/[2.5rem]">
                Get <span className="bs-section-bonus__bonus font-['Paytone_One']">Welcome Pack</span> 225%
              </h2>
              <p className="xl:ml-3 font-poppins">up to €25500 or 0.5 BTC + 150FS on first 3 deposits!</p>
            </div>
            <SignUp />
          </div>
        </div>
      </section>

      <div className="relative z-10 overflow-hidden">
        <ul className="mb-3 space-x-2 whitespace-nowrap leading-[0] xl:mb-4 xl:space-x-4">
          {row1Images.map((_, i) => (
            <li
              className="inline-block h-10 rounded-xl bg-[#15161B] p-2.5 text-center xl:h-24 xl:w-72 xl:rounded-3xl xl:p-6"
              key={i}
            >
              <img
                alt=""
                className="inline-block h-[96%] w-auto"
                src={_}
              />
            </li>
          ))}
        </ul>

        <ul className="space-x-2 whitespace-nowrap leading-[0] xl:space-x-4">
          {row2Images.map((_, i) => (
            <li
              className="inline-block h-10 rounded-xl bg-[#15161B] p-2.5 text-center xl:h-24 xl:w-72 xl:rounded-3xl xl:p-6 xl:first:p-4"
              key={i}
            >
              <img
                alt=""
                className="inline-block max-h-full min-h-[20px] w-auto"
                src={_}
              />
            </li>
          ))}
        </ul>
      </div>

      <section className="bs-section-jackpot relative text-center xl:text-left">
        <div className="bs-container xl:py-20">
          <div className="bs-section-jackpot__card relative pb-[3.25rem] pt-[13.375rem] xl:h-[21rem] xl:rounded-3xl xl:p-[2.5rem]">
            <h2>
              <span className="bs-section-jackpot__label mb-4 inline-block rounded-lg px-2 py-1 text-xs xl:float-left xl:block">
                Total jackpot
              </span>
              <div className="bs-section-jackpot__amount mb-5 font-['Paytone_One'] xl:clear-both xl:mb-6">
                $24,000.000
              </div>
            </h2>
            <div className="mb-8 flex flex-col gap-4 overflow-hidden xl:mb-10 xl:flex-row xl:items-center">
              <ul className="space-x-2 whitespace-nowrap leading-[0]">
                {jackpotImages.map((_, i) => (
                  <li
                    className="inline-block h-10 w-10 overflow-hidden rounded-xl"
                    key={i}
                  >
                    <img
                      alt=""
                      className="object-cover"
                      src={_}
                    />
                  </li>
                ))}
              </ul>
              <p className="text-base font-poppins">+ 3,596 games</p>
            </div>
            <a
              className="bs-btn mx-4 block rounded-2xl px-16 py-3 text-center xl:mx-0 xl:inline-block"
              href="#get_bonus"
            >
              Play right now
            </a>
          </div>
        </div>
      </section>

      <footer className="bs-footer">
        <div className="bs-container pb-4 pt-4 xl:grid xl:grid-flow-col xl:grid-rows-[auto_minmax(0,_1fr)_auto] xl:justify-between xl:pt-10">
          <div className="xl:row-span-2">
            <div className="mb-2 flex h-12 items-center xl:mb-1 xl:h-[4.25rem]">
              <a
                className="inline-block"
                href="/"
              >
                <img
                  alt="Betsomnia"
                  className="w-32 xl:w-[11.5rem]"
                  src={logo}
                />
              </a>
            </div>
            <p className="mb-6 font-normal leading-6 xl:mb-0 xl:max-w-[550px] xl:text-[0.875rem]">
              Betsomnia is owned and operated by FGS SOFTWARE SOLUTIONS S.R.L., a company registered and established
              under the laws of Costa Rica. FGS SOFTWARE SOLUTIONS S.R.L. is regulated by the Government of Costa Rica.
              SOFTWARE SOLUTIONS S.R.L. registration number is 3-102-871832.
            </p>
          </div>
          <div className="flex flex-col overflow-hidden xl:order-2 xl:flex-col-reverse xl:items-end xl:justify-end">
            <ul className="bs-menu mb-3 flex flex-wrap xl:mb-2 xl:max-w-[400px] xl:justify-end xl:text-[0.875rem]/[1.25rem]">
              {bottomMenu.map(({ href, name }, i) => (
                <li
                  className="bs-menu__item mb-3 flex items-center xl:mb-2"
                  key={i}
                >
                  <a
                    className="underline hover:no-underline"
                    href={href}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
            <ul className="bs-social-media flex space-x-4 leading-6 xl:mb-7">
              {socialMedia
                .filter((it) => it.href !== '#')
                .map(({ href, image, label }) => (
                  <li key={label}>
                    <a
                      className={`block w-[24px] h-[24px] overflow-hidden`}
                      href={href}
                      target="_blank"
                    >
                      {image}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="mt-4 xl:col-span-2 xl:mt-6">
            <hr />
            <div className="-mx-4 overflow-x-auto px-4 py-4 sm:-mx-0 sm:px-0 xl:py-6">
              <ul className=" space-x-4 whitespace-nowrap leading-[0] xl:justify-between">
                <li className="inline-block">
                  <img
                    alt="logo18+"
                    className="h-5"
                    src={r18}
                  />
                </li>
                {partners.map((_, i) => (
                  <li
                    className="inline-block"
                    key={i}
                  >
                    <img
                      alt=""
                      className="h-5 w-auto"
                      src={_.image}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <hr />
            <div className="-mx-4 overflow-x-auto px-4 pb-6 pt-4 sm:-mx-0 sm:px-0 xl:pt-6">
              <ul className="space-x-6 whitespace-nowrap leading-[0] xl:justify-between">
                {row1Images.map((_, i) => (
                  <li
                    className="inline-block"
                    key={i}
                  >
                    <img
                      alt=""
                      className="h-6 w-auto"
                      src={_}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
