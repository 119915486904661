import { useMemo } from 'react';
import { makeFloat } from 'src/utils';

import { redefinedMaxAmountMap } from '../../constants/index';
import { PaymentMethod } from '../../types';

export const factor = [1, 5, 25, 50];

export const useAmountPresets = ({
  currency,
  minPayment,
  paymentMethod,
}: {
  currency: string;
  minPayment?: number;
  paymentMethod?: PaymentMethod;
}) => {
  const amountPresets = useMemo(() => {
    if (!minPayment) {
      return [20, 100, 500, 1000];
    }

    const predefinedValue = paymentMethod && redefinedMaxAmountMap[paymentMethod.method]?.[currency];
    const maxPayment = predefinedValue ?? (paymentMethod?.max_amount ? Number(paymentMethod?.max_amount) : null);

    const result = factor.map((multiplier) => {
      return multiplier * makeFloat(minPayment);
    });

    if (maxPayment && maxPayment < result[result.length - 1]) {
      const filtered = result.filter((value) => value < maxPayment);
      return filtered.length < 4 ? [...filtered, maxPayment] : filtered;
    } else {
      return result;
    }
  }, [minPayment, paymentMethod, currency]);

  return amountPresets;
};
