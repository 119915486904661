import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BonusTransaction } from 'src/api/bonusesTransaction/bonusesTransaction.types';
import { TableCell } from 'src/components/Table/TableCell';
import { TablePagination } from 'src/components/Table/TablePagination';
import { BonusTransactionType, STATUS_BONUS_TRANSACTION_NAME } from 'src/types';
import { useGetAllBonusesTransactions } from '../../api/bonusesTransaction/bonusesTransaction.hooks';
import { HistoryForm } from '../../components';
import { Table } from '../../components/Table';
import { bonusHeader } from '../../constants';
import { getCurrencyIcon, makeFloat } from '../../utils';
import { useGameHistoryForm } from './useGameHistory';

export type Values = {
  transactionTypes: string[];
  dateFrom: string;
  dateTo: string;
  amountFrom: string;
  amountTo: string;
};
const isOfNeededType = [
  { id: '0', name: 'Bonus' },
  { id: '1', name: 'Money Prize' },
  { id: '4', name: 'FreeSpinWin' },
  { id: '5', name: 'Bet' },
  { id: '7', name: 'Win' },
  { id: '11', name: 'Cashback' },
  { id: '12', name: 'Level Bonus' }
];

export const BonusTransactionHistory = () => {
  const { t } = useTranslation();
  const [cleared, setCleared] = useState<boolean>(false);
  const {
    values,
    resetForm,
    fieldsProps: {
      transactionTypesProps,
      dateFromProps,
      dateToProps,
      amountFromProps,
      amountToProps
    }
  } = useGameHistoryForm(isOfNeededType);

  const { numberPages, arrayBonusesTansactions, refetch, setPage, page } =
    useGetAllBonusesTransactions(
      {
        bonus_type: 0,
        amount_from: values.amountFrom ? parseFloat(values.amountFrom) / 0.01 : undefined,
        amount_to: values.amountTo ? parseFloat(values.amountTo) / 0.01 : undefined,
        date_end: values.dateTo ? values.dateTo : undefined,
        date_start: values.dateFrom ? values.dateFrom : undefined,
        type: values.transactionTypes?.length === 0 ? '0,1,4,5,7,11,12' : values.transactionTypes?.join(',')
      },
      true
    );
  const handleNextPage = useCallback(() => {
    setPage((old) => old + 1);
  }, [setPage]);
  const handlePrevPage = useCallback(() => {
    setPage((old) => Math.max(old - 1, 0));
  }, [setPage]);

  const hanleChangePage = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );
  const handleClearForm = useCallback(() => {
    resetForm();
    setCleared(!cleared);
  }, [cleared, resetForm]);

  useEffect(() => {
    refetch();
  }, [refetch, page, cleared]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="max-w-[550px]">
        <HistoryForm
          transaction={t('transactionTable.bonusTransaction')}
          resetForm={handleClearForm}
          transactionTypesProps={transactionTypesProps}
          dateFromProps={dateFromProps}
          dateToProps={dateToProps}
          amountFromProps={amountFromProps}
          amountToProps={amountToProps}
          refetch={refetch}
        />
      </div>
      {arrayBonusesTansactions?.length === 0 ? (
        <div className="flex flex-col gap-3">
          <p className="font-bold text-2xl text-text_8">{t('transactionTable.notFoundBonus')}</p>
          <p className="font-medium text-sm text-text_2">
            {t('transactionTable.notFoundBonusText')}
          </p>
        </div>
      ) : (
        <>
          <p className="text-text_2 text-[12px] font-bold leading-[16px] text-right mb-2.5 responsiveHide">
            {t('general.tapScroll')} &gt;&gt;
          </p>
          <div className="overflow-auto mt-2.5">
            <div className="w-[900px]">
              <Table
                header={bonusHeader}
                tableRow={arrayBonusesTansactions?.map((data, index) => (
                  <TableRowTransaction key={index} data={data} />
                ))}
              />
            </div>
          </div>
          {numberPages ? (
            <div className="max-w-[900px]">
              <TablePagination
                currentPage={page}
                pages={numberPages}
                nextPage={handleNextPage}
                prevPage={handlePrevPage}
                onClick={hanleChangePage}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

function TableRowTransaction({ data }: { data: BonusTransaction }) {
  const { id, type, amount, balance, date, currency, status } = data;

  return (
    <tr className="even:bg-background_12">
      <TableCell item={id} />
      <TableCell item={BonusTransactionType[type]} />
      <TableCell item={makeFloat(amount).toFixed(2)} />
      <TableCell item={`${makeFloat(balance).toFixed(2)}${getCurrencyIcon(currency)}`} />
      <TableCell
        item={`${dayjs(date * 1000).format('DD-MM-YYYY')} ${STATUS_BONUS_TRANSACTION_NAME[status]}`}
      />
    </tr>
  );
}
