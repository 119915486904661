import { executeAdditionalGTMScript } from 'src/utils';

import { apiProvider, messageWhizProvider } from '..';
import {
  AllSubscriptions,
  FullInfoResponse,
  ResendConfirmEmailResponse,
  UpdateInfoBody,
  UpdateInfoResponse,
  UpdateSubscriptionsBody,
  UpdateSubscriptionsResponse,
  UserCounty,
} from './profile.types';

// TODO X-Authorization, language
export const fullInfo = async () => {
  const fullInfo = await apiProvider.request<FullInfoResponse>({
    method: 'get',
    url: '/profile/full-info',
  });
  // скрипт перенесен сюда из хука, чтобы он вызывался только при запросе, а не при вызовах хука из каждого компонента
  if (fullInfo?.data?.id) {
    localStorage.setItem('userId', fullInfo.data.id.toString());
    executeAdditionalGTMScript(fullInfo?.data?.id);
  }
  return fullInfo;
};

// TODO X-Authorization, language
export const allSubscriptions = () => {
  return apiProvider.request<AllSubscriptions>({
    method: 'get',
    url: '/profile/all-subscriptions',
  });
};

// TODO X-Authorization
export const updateInfo = (data: UpdateInfoBody) => {
  return apiProvider.request<UpdateInfoResponse>({
    data,
    method: 'put',
    url: '/profile/update-info',
  });
};

// TODO X-Authorization
export const updateSubscriptions = (data: UpdateSubscriptionsBody) => {
  return apiProvider.request<UpdateSubscriptionsResponse>({
    data,
    method: 'put',
    url: '/profile/update-subscriptions',
  });
};

// TODO language
export const resendConfirmEmail = () => {
  return apiProvider.request<ResendConfirmEmailResponse>({
    method: 'post',
    url: '/profile/resend-confirm-email',
  });
};

export const getUserCountry = () => {
  return apiProvider.request<UserCounty>({
    method: 'get',
    url: '/profile/get-user-country',
  });
};

export const sendSMSCode = (phone: string) => {
  return messageWhizProvider.request({
    data: {
      campaign_id: 173424,
      message_body: 'verification code: {{verification_code}}',
      recipient: phone,
      sender_id: '29309093',
      utc_offset: 120,
      verification: true,
    },
    method: 'post',
    url: '/broadcasts/single?otp=true',
  });
};

export const verifySMSCode = (param: { code: number; phone: string }) => {
  return messageWhizProvider.request({
    data: {
      verification_code: param.code,
    },
    method: 'post',
    url: `/recipients/${param.phone}/verification`,
  });
};
