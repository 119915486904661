import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import noProviders from 'src/assets/images/noProviders.webp';
import { useClickOutside } from 'src/hooks/useClickOutside';

import { Provider } from '../../api/content';
import { useGetAllProviders } from '../../api/content/content.hooks';
import { useDrawer } from '../../hooks/useDrawer';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { InputSearch } from '../Inputs/InputSearch';
import { ProviderButton } from '../ProviderButton/ProviderButton';
import { SearchIcon, Spin } from '../svg';

import './styles.css';

export const Providers = () => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const providersRef = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { isBottomDrawerOpen } = useDrawer();
  const handleChangeProviders = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const { allProviders, isFetched, isFetching } = useGetAllProviders(
    {
      currency: 'EUR',
      per_page: 999999,
    },
    isBottomDrawerOpen,
  );
  const { t } = useTranslation();

  const providersToRender = useMemo(
    () => allProviders?.filter((provider) => provider.name.toLowerCase().includes(searchValue.toLowerCase())),
    [searchValue, allProviders],
  );

  const handleClear = () => {
    setSearchValue('');
  };

  const handleProviderClick = (provider: Provider) => {
    sendGoogleAnalyticsEvent('provider_chosen', {
      provider_id: provider.name,
    });
  };

  useClickOutside(providersRef, handleClear);

  return (
    <div
      className="flex flex-col items-center mt-11 h-[360px] providers-list pb-[20px] z-[80] ease-in-out duration-300"
      ref={providersRef}
      style={{
        transform: isBottomDrawerOpen ? 'translateY(-18%)' : 'translateY(100%)',
      }}
    >
      <>
        {isBottomDrawerOpen ? (
          <>
            <div className="w-[90%] my-[28px]">
              <InputSearch
                className="flex flex-row items-center bg-[transparent] w-full h-auto py-[5px] border-b-2 border-b-border_6 justify-around r_sm:py-0 "
                classNameInput="search-input text-text_2 bg-[transparent] w-full px-2.5 r_sm:p-0 r_sm:h-[20px] text-[14px] font-semibold r_sm:pl-[3px]"
                leftIcon={<SearchIcon />}
                onChange={handleChangeProviders}
                placeholder={`${t('general.searchProvider')}`}
                value={searchValue}
              />
            </div>
            <div
              className={`${
                isFetching && !isFetched
                  ? 'flex items-center justify-center w-full h-full'
                  : ' w-[90%] grid grid-cols-1 gap-2 overflow-y-scroll px-[15px]'
              }`}
              ref={parentRef}
            >
              {providersToRender?.map((provider) => (
                <ProviderButton
                  key={provider.id}
                  onClick={() => handleProviderClick(provider)}
                  provider={provider}
                />
              ))}
              {isFetching && (
                <div className="h-full w-full flex justify-center items-center self-center text-center">
                  <Spin classNameSvg="w-[30px] h-[30px]" />
                </div>
              )}
              <div
                className="h-[20px] bg-[transparent]"
                ref={childRef}
              ></div>
            </div>
            {!isFetching && providersToRender?.length === 0 && (
              <div className="flex flex-col justify-start items-center w-full h-full">
                <p className="text-text_2 w-full text-center">{t('general.noProvidersFound')}</p>
                <img
                  alt="no providers"
                  className="max-h-[80%] max-w-[300px]"
                  src={noProviders}
                />
              </div>
            )}
          </>
        ) : null}
      </>
    </div>
  );
};
