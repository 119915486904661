import { useTranslation } from 'react-i18next';
import { useGetSinglePage } from '../../api/content/content.hooks';

export const IosSetupModal = () => {
  const { t } = useTranslation();

  const { data } = useGetSinglePage('ios_app');

  return (
    <div className="p-[20px] h-full min-w-[550px] max-w-[550px] r_sm:min-w-full text-text_2">
      <p className="w-full font-bold text-[20px] mb-[40px] text-text_2">{data?.title || ''}</p>
      <div
        dangerouslySetInnerHTML={{ __html: data?.content ?? t('apiErrors.not_response') ?? '' }}
        className="text-[14px] font-medium text-text_2"></div>
    </div>
  );
};
