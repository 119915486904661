import classNames from 'classnames';
interface BalanceCardInfoProps {
  balanceCard?: boolean;
  title: string;
  text: string;
}

export const BalanceCardInfo = ({ balanceCard = false, title, text }: BalanceCardInfoProps) => {
  return (
    <div>
      <div
        className={classNames('font-bold text-[16px]', balanceCard ? 'text-text_12' : 'text-text_2')}>
        {title}
      </div>
      <div className="font-bold text-[20px] text-text_2">{text}</div>
    </div>
  );
};
