import { useTranslation } from 'react-i18next';
import { SingleGame } from 'src/api/games';
import { GameCard } from '../GameCard';
import { Responsive } from '../Responsive';

type GamesSearchResultsProps = {
  allGames: SingleGame[];
  isFetching: boolean;
  image?: string;
  isFetched?: boolean;
  navigationState?: string;
};

export const GamesSearchResults = ({
  allGames,
  isFetching,
  image,
  isFetched,
  navigationState
}: GamesSearchResultsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {allGames.length > 0 && (
        <div className="p-4 w-full">
          <Responsive at={['xs', 'sm', 'md']}>
            <div className="grid grid-cols-3 gap-x-1.5 gap-y-2.5 w-full">
              {allGames.map((game) => (
                <GameCard key={game.id} game={game} navigationState={navigationState}/>
              ))}
            </div>
          </Responsive>
          <Responsive at={['lg', 'xl']}>
            <div className="grid grid-cols-6 gap-x-1.5 gap-y-2.5 w-full">
              {allGames.map((game) => (
                <GameCard key={game.id} game={game} navigationState={navigationState}/>
              ))}
            </div>
          </Responsive>
        </div>
      )}
      {(!isFetched && isFetching) ||
        (allGames.length === 0 && (
          <div className="w-full my-[30px] flex flex-col justify-center items-center">
            <p className="text-text_2 text-center font-bold w-[70%] text-sm">
              {t('gamesPage.noGamesInCategory')}
            </p>
            <img src={image} className="w-auto" alt="no games" />
          </div>
        ))}
    </>
  );
};
