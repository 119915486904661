import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useGetJWT } from 'src/api/auth';
import { useGetBlockedCountry } from 'src/api/blacklist';
import { useGetAllBonusesTransactions } from 'src/api/bonusesTransaction';
import { useGetDepositMethods } from 'src/api/deposit';
import { Layout } from 'src/components';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';
import { useApi } from 'src/hooks/useApi';
import { useBodyScroll } from 'src/hooks/useBlockScroll';
import { useChangeEmailInLiveChat } from 'src/hooks/useChangeEmailInLiveChat';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useDrawer } from 'src/hooks/useDrawer';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import {
  Bonus,
  Bonuses,
  ContentPage,
  Faqs,
  GamePage,
  Games,
  History,
  PersonalAreaProfile,
  ProviderTournament,
  PwaPage,
  Tournaments,
  VIP,
  Welcome,
  Welcome2,
} from 'src/pages';
import { PersonalAreaPromo } from 'src/pages/AccountPromo';
import { AccountVip } from 'src/pages/AccountVip/AccountVip';
import { PersonalAreaWallet } from 'src/pages/AccountWallet';
import { MainPage } from 'src/pages/Main';
import { ScrollToTop } from 'src/utils/scrollToTop';

import { LocalTournament } from '../../pages/LocalTournament';
import { AccountLayout } from '../AccountLayout';
import { BlockedCountryPlug } from '../BlockedCountryPlug/BlockedCountryPlug';
import { FreeSpinsHandler } from '../FreeSpinsHandler';
import { GlobalGoogleAnalyticsEvents } from '../GlobalGoogleAnalyticsEvents';
import { GameLayout } from '../Layout/GameLayout';
import { MessagesContainer } from '../Message/MessagesContainer';
import { Modal } from '../Modal';
import { OpenSingnUpByLink } from '../OpenSingnUpByLink';
import { ProtectedAuthRoute } from '../ProtectedAuthRoute/ProtectedAuthRoute';
import { ReferralLinkHandler } from '../ReferralLinkHandler';

export function Router() {
  const { isAuthenticated, saveJWT } = useApi();
  const { openedModal, openModal } = useModal();
  const { refFullscreen } = useFullScreen();

  const { _mainAccountSidebarOpened, _mainSidebarOpened, isLeftDrawerOpen } = useDrawer();
  const { isMobile } = useDeviceDetect();
  const { toggleLocked } = useBodyScroll();
  const { isLg, isXl } = useBreakpoints();

  const isOpened = _mainSidebarOpened || _mainAccountSidebarOpened || isLeftDrawerOpen;
  const isLargeWindow = isLg || isXl;

  useEffect(() => {
    toggleLocked(!!isOpened && ((isMobile && !isLargeWindow) || !isLargeWindow));
  }, [isOpened, isMobile, toggleLocked, isLargeWindow]);

  useGetJWT({
    onSuccess: (data) => {
      saveJWT?.(data.data.jwt);
    },
  });
  const { blockedCoutry, isFetched, isLoading } = useGetBlockedCountry();

  const { arrayBonusesTansactions, isFetching } = useGetAllBonusesTransactions({
    // eslint-disable-next-line camelcase
    bonus_type: 0,
    status: '0',
  });

  useEffect(() => {
    if (
      arrayBonusesTansactions.length > 0 &&
      openedModal !== MODAL_NAME.SIGNUP &&
      !isFetching &&
      arrayBonusesTansactions[0].status === 0
    ) {
      openModal?.(MODAL_NAME.POPUP_PROMOCODE, { bonusId: arrayBonusesTansactions[0].id }, false, true, true);
    }
  }, [arrayBonusesTansactions, isFetching, openModal, openedModal]);

  useGetDepositMethods();
  useChangeEmailInLiveChat();

  if (blockedCoutry && !isLoading && isFetched) {
    return <BlockedCountryPlug />;
  }

  return (
    <div
      className="bg-background_2"
      ref={refFullscreen}
    >
      <MessagesContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Modal />
        <Routes>
          {isAuthenticated && (
            <Route
              element={
                <ProtectedAuthRoute isAuthenticated={isAuthenticated}>
                  <AccountLayout />
                </ProtectedAuthRoute>
              }
              path="/:lang?/account"
            >
              <Route
                element={<Navigate to={'/account/profile/main'} />}
                index
              />
              <Route
                element={<PersonalAreaProfile />}
                path="profile/*"
              />
              <Route
                element={<History />}
                path="transaction-history/*"
              />
              <Route
                element={<PersonalAreaWallet />}
                path="wallet/*"
              />
              <Route
                element={<PersonalAreaPromo />}
                path="promo"
              />
              <Route
                element={<AccountVip />}
                path="vip"
              />
            </Route>
          )}
          <Route
            element={<Layout />}
            path="/:lang?"
          >
            <Route
              element={<MainPage />}
              index
            />
            <Route
              element={<MainPage />}
              path="activate-bonus/:bonusId"
            />
            <Route
              element={<Tournaments />}
              path="tournaments"
            />
            <Route
              element={<ProviderTournament />}
              path="tournaments/:tournamentCode"
            />
            <Route
              element={<Navigate to={'/tournaments'} />}
              path="tournaments/local"
            />
            <Route
              element={<LocalTournament />}
              path="tournaments/local/:id"
            />
            <Route
              element={<Bonuses />}
              path="bonuses"
            />
            <Route
              element={<Bonus />}
              path="bonuses/:bonus"
            />
            <Route
              element={<ContentPage />}
              path="info_pages/:page"
            />
            <Route
              element={<Faqs />}
              path="faq"
            />
            <Route
              element={<Games />}
              path="games/:categoryId?/:search?"
            />
            <Route
              element={<VIP />}
              path="vip"
            />
            <Route
              element={<PwaPage />}
              path="pwa-instruction/:deviceId?"
            />
          </Route>
          <Route
            element={<GameLayout />}
            path="/:lang?/game"
          >
            <Route
              element={<Navigate to={'/games'} />}
              index
            />
            <Route
              element={<GamePage />}
              path=":gameId/:demo?"
            />
          </Route>
          <Route
            element={isAuthenticated ? <Navigate to={'/'} /> : <Welcome title="landing.titleOne" />}
            path="welcome"
          />
          <Route
            element={isAuthenticated ? <Navigate to={'/'} /> : <Welcome title="landing.titleTwo" />}
            path="welcomepage"
          />
          <Route
            element={<Welcome2 />}
            path="welcome2"
          />
          <Route
            element={
              <Navigate
                state={{ checkAutoDeposits: true }}
                to={'/account/wallet/history'}
              />
            }
            path="/:lang?/deposit-success/*"
          />
          <Route
            element={<Navigate to={'/account/wallet/history'} />}
            path="/:lang?/deposit-failure/*"
          />
          <Route
            element={<Navigate to={'/account/wallet/history'} />}
            path="/:lang/deposit-return/*"
          />
          <Route
            element={<Navigate to={'/'} />}
            path="*"
          />
        </Routes>
        <ReferralLinkHandler />
        <FreeSpinsHandler />
        <OpenSingnUpByLink />
        <GlobalGoogleAnalyticsEvents />
      </BrowserRouter>
    </div>
  );
}
