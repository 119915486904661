import React, { Context, createContext, Dispatch, FC, SetStateAction, useState } from 'react';

// @ts-ignore
// eslint-disable-next-line import/exports-last
export const TwitchContext: Context<{
  isClosed: boolean;
  isCollapsed: boolean;
  isMuted: boolean;
  isOnline: boolean;
  isWindow: boolean;
  setIsClosed?: Dispatch<SetStateAction<boolean>>;
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>;
  setIsMuted?: Dispatch<SetStateAction<boolean>>;
  setIsOnline?: Dispatch<SetStateAction<boolean>>;
  setIsWindow?: Dispatch<SetStateAction<boolean>>;
}> = createContext({
  isCollapsed: false,
  isWindow: false,
  player: null,
});

interface TwitchProviderProps {
  children: React.ReactNode;
}

export const TwitchProvider: FC<TwitchProviderProps> = ({ children }) => {
  const [isWindow, setIsWindow] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  return (
    <TwitchContext.Provider
      value={{
        isClosed,
        isCollapsed,
        isMuted,
        isOnline,
        isWindow,
        setIsClosed,
        setIsCollapsed,
        setIsMuted,
        setIsOnline,
        setIsWindow,
      }}
    >
      {children}
    </TwitchContext.Provider>
  );
};
