import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import bigLogo from 'src/assets/images/bigLogoWithDescription.png';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDrawer } from 'src/hooks/useDrawer';
import { useScrollToTopOnMount } from 'src/hooks/useScrollToTop';

import { CloseComponentWithoutStyles } from '../svg';
import { SidebarContent } from './SidebarContent';

import './styles.css';

export const Sidebar = () => {
  const { closeLeftDrawer, isLeftDrawerOpen, isTopDrawerOpen } = useDrawer();

  useEffect(() => {
    isTopDrawerOpen && closeLeftDrawer?.();
  }, [closeLeftDrawer, isTopDrawerOpen]);

  const handleCloseSidebar = () => {
    closeLeftDrawer?.();
  };

  const pageRef = useScrollToTopOnMount([isLeftDrawerOpen]);
  useClickOutside(pageRef, handleCloseSidebar);

  return (
    <div
      className="sidebar z-[650]"
      ref={pageRef}
      style={{
        transform: isLeftDrawerOpen ? 'translateX(0px)' : 'translateX(-250px)',
      }}
    >
      <div className="flex flex-col justify-end items-end mb-[20px] w-full">
        <div className="flex justify-center items-center w-[30px] h-[30px] cursor-pointer">
          <CloseComponentWithoutStyles onShow={closeLeftDrawer} />
        </div>
        <div className="w-full flex justify-center items-center">
          <Link
            onClick={() => closeLeftDrawer?.()}
            to="/"
          >
            <div className="logo__opened">
              <img
                alt="Logo"
                height="50"
                src={bigLogo}
                width="170"
              />
            </div>
          </Link>
        </div>
      </div>
      <div className="px-4">
        <SidebarContent />
      </div>
    </div>
  );
};
