import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetNodapayMethods } from 'src/api/payment';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

import { useGetUserCountry } from '../../api/profile';

export type AffiliateReferralParams = {
  affiliateId?: string;
  affiliateUserId?: string;
};

export function ReferralLinkHandler() {
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useCheckForNodapayMethods();

  useEffect(() => {
    if (location.pathname.includes('landing')) return;
    const afilliateReferralData = searchParams.get('stag');

    if (afilliateReferralData && !isAuthenticated) {
      openModal?.(MODAL_NAME.SIGNUP, undefined, true, false, true);
    }
  }, [isAuthenticated, searchParams]);

  return null;
}

export function parseAffilliateReferralLink(searchParamValue: null | string): AffiliateReferralParams {
  if (!searchParamValue) return {};
  const [affiliateId, affiliateUserId] = searchParamValue.split('_');
  return { affiliateId, affiliateUserId };
}

const SHOW_NODAPAY_SS_KEY = 'showNodapay';

function useCheckForNodapayMethods() {
  const { isAuthenticated } = useApi();
  const { isSWE } = useGetUserCountry({});
  const { openedModal, openModal } = useModal();
  const { data: nodapayMethods } = useGetNodapayMethods();
  const location = useLocation();

  useEffect(() => {
    const showNodapayFromSession = sessionStorage.getItem(SHOW_NODAPAY_SS_KEY);
    const openNodapayModal = showNodapayFromSession === null ? true : JSON.parse(showNodapayFromSession);
    const nodapayMethodsExist = Array.isArray(nodapayMethods) && nodapayMethods?.length;
    const notAuthenticated = isAuthenticated !== undefined && !isAuthenticated;
    const signupIsNotOpened = openedModal !== MODAL_NAME.SIGNUP;

    if (location.pathname === '/account/wallet/deposit') {
      return;
    }

    if (
      !isSWE &&
      openNodapayModal &&
      nodapayMethodsExist &&
      notAuthenticated &&
      signupIsNotOpened &&
      location.pathname !== '/welcome2'
    ) {
      openModal?.(MODAL_NAME.NODAPAY_METHODS, undefined, true, false, true);
      sessionStorage.setItem(SHOW_NODAPAY_SS_KEY, 'false');
      return;
    }
    if (!isSWE && openNodapayModal && isAuthenticated && signupIsNotOpened) {
      openedModal !== MODAL_NAME.POPUP_DEPOSIT && openModal?.(MODAL_NAME.POPUP_DEPOSIT);
      sessionStorage.setItem(SHOW_NODAPAY_SS_KEY, 'false');
    }
  }, [openModal, nodapayMethods, isAuthenticated, openedModal]);
}
