import { ChangeEvent } from 'react';

export const InputFile = ({
  disabled,
  handleFileChange
}: {
  disabled?: boolean;
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <input
      disabled={disabled}
      accept=".png, .jpg, .jpeg"
      type="file"
      name="file"
      className="opacity-0 cursor-pointer absolute w-full h-full top-0 left-0"
      onChange={handleFileChange}
    />
  );
};
