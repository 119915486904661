import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

export const OpenSingnUpByLink = () => {
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const clearUrl = useCallback(() => {
    navigate(`${location.pathname}`);
  }, [location.pathname, navigate]);
  useEffect(() => {
    const haveAction = searchParams.get('action');
    if (haveAction && !isAuthenticated) {
      openModal?.(MODAL_NAME.SIGNUP);
    }
    haveAction && clearUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, searchParams]);
  return null;
};
