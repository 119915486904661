import classNames from 'classnames';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetShortBalance } from 'src/api/balance';
import Logo from 'src/assets/images/smallLogo.png';
import { Button } from 'src/components/Buttons/Button';
import { GameHeaderBurger } from 'src/components/GameHeaderBurger/GameHeaaderBurger';
import { Responsive } from 'src/components/ResponsiveNew/Responsive';
import { GameHeaderAccountIcon } from 'src/components/svg/Accounts';
import { ArrowSingleGame } from 'src/components/svg/Arrows';
import { BigCloseGame } from 'src/components/svg/Closes';
import { ActivateFullScreen, DeactivateFullScreen } from 'src/components/svg/FullScreens';
import { useApi } from 'src/hooks/useApi';
import { useBoolean } from 'src/hooks/useBoolean';
import { useDrawer } from 'src/hooks/useDrawer';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { MODAL_NAME, useModal, useModalUtils } from 'src/hooks/useModal';

import { useApplyTournament } from '../../api/tournaments';
import { useGameModals, useGetGameData } from './hooks';

const HEADER_MAIN_HEIGHT = 50;

export const GamePage = () => {
  const { deactivateFullScreen, isFullscreen, onFullscreenClick } = useFullScreen();
  const [headerVisible, openHeader, closeHeader, toggleHeader] = useBoolean(true);
  const applyTournament = useApplyTournament();
  const game = useGetGameData();

  useGameModals(game);

  const handleFullscreenOpen = () => {
    onFullscreenClick?.();
    closeHeader();
  };

  useEffect(() => {
    if (isFullscreen === false) {
      openHeader();
    }
  }, [isFullscreen]);

  useEffect(() => {
    return () => {
      deactivateFullScreen?.();
    };
    // выход из фулскрина при выходе из игры
  }, []);

  useEffect(() => {
    if (game?.activeTournamentId) {
      applyTournament.mutate(game?.activeTournamentId);
    }
  }, [game?.activeTournamentId]);

  return (
    <div className="w-full h-screen">
      <Header
        headerVisible={headerVisible}
        isFullscreen={isFullscreen}
        onFullscreenClose={deactivateFullScreen}
        onFullscreenOpen={handleFullscreenOpen}
        toggleHeader={toggleHeader}
      />
      {game?.gameUrl && (
        <div
          className="w-full h-full transition-all duration-[0.5s] flex justify-center"
          style={{ paddingTop: headerVisible ? HEADER_MAIN_HEIGHT : 0 }}
        >
          <iframe
            allow="autoplay; fullscreen"
            allowFullScreen
            className="w-full h-full lg:w-4/5"
            id="game-iframe"
            seamless
            src={game?.gameUrl}
          />
        </div>
      )}
    </div>
  );
};

function Header({
  headerVisible,
  isFullscreen,
  onFullscreenClose,
  onFullscreenOpen,
  toggleHeader,
}: {
  headerVisible: boolean;
  isFullscreen?: boolean;
  onFullscreenClose?: () => void;
  onFullscreenOpen?: () => void;
  toggleHeader: () => void;
}) {
  const { isAuthenticated } = useApi();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { openDepositModal } = useModalUtils();
  const { closeLeftDrawer } = useDrawer();
  const navigate = useNavigate();
  const { shortBalanceFormatted } = useGetShortBalance();
  const location = useLocation();
  const prevPath = useMemo(() => {
    const historyLength = location.state?.history?.length || 0;
    return location.state?.history?.[historyLength - 1] || '/';
  }, [location.state?.history]);

  const handleClose = () => {
    navigate(prevPath);
  };
  const onProfileClick = () => {
    isAuthenticated ? navigate('/account/profile/main') : openModal?.(MODAL_NAME.SIGNUP);
  };

  return (
    <div
      className={classNames(
        'flex flex-col w-[100%] absolute transition-all duration-[0.5s] z-50',
        headerVisible ? 'translate-y-0' : 'translate-y-[-100%]',
      )}
    >
      <div
        className="flex items-center w-full gap-2.5 px-2.5 sm:px-4 header-game max-w-[2560px] lg:px-[100px]"
        style={{ height: HEADER_MAIN_HEIGHT }}
      >
        <GameHeaderBurger />
        <img
          alt="logo"
          className="w-[45px] h-auto hidden sm:block cursor-pointer"
          onClick={() => navigate('/')}
          role="link"
          src={Logo}
        />
        <div className="flex flex-row items-center gap-2.5 justify-end flex-1">
          <IsAuthenticated>
            <Button
              additionalClassName="whitespace-nowrap px-5 w-auto jsm:text-sm h-[35px] min-w-[190px] jsm:min-w-[140px] text-sm normal-case"
              mode="primary"
              onClick={() => {
                openDepositModal();
                closeLeftDrawer?.();
              }}
              title={`${t('general.deposit')}`}
            >
              {`${t('general.deposit')} ${shortBalanceFormatted}`}
            </Button>
            <Responsive from="lg">
              <div
                className="cursor-pointer"
                onClick={onProfileClick}
              >
                <GameHeaderAccountIcon />
              </div>
            </Responsive>
          </IsAuthenticated>
          <IsNotAuthenticated>
            <Button
              additionalClassName="shadow-xl h-[35px] w-auto px-4 max-w-[140px] min-w-[140px]"
              label={`${t('general.deposit')}`}
              mode="primary"
              onClick={() => {
                openModal?.(MODAL_NAME.SIGNUP);
              }}
              title={`${t('general.deposit')}`}
            />
          </IsNotAuthenticated>
          <Responsive from={'lg'}>
            <FullscreenButton
              isFullScreen={isFullscreen}
              onFullscreenClose={onFullscreenClose}
              onFullscreenOpen={onFullscreenOpen}
            />
          </Responsive>
          <BigCloseGame
            className="w-6 h-6"
            onShow={handleClose}
          />
        </div>
      </div>
      <div
        className={classNames(
          'absolute left-0 top-[100%] cursor-pointer bg-[rgba(255,255,255,.25)] px-4 py-2',
          !isFullscreen && 'lg:hidden',
        )}
        onClick={toggleHeader}
      >
        <ArrowSingleGame
          className="w-4"
          style={{
            transform: !headerVisible ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
          }}
        />
      </div>
    </div>
  );
}

function FullscreenButton({
  isFullScreen,
  onFullscreenClose,
  onFullscreenOpen,
}: {
  isFullScreen?: boolean;
  onFullscreenClose?: () => void;
  onFullscreenOpen?: () => void;
}) {
  return !isFullScreen ? (
    <button
      onClick={onFullscreenOpen}
      title="on fullscreen"
    >
      <ActivateFullScreen />
    </button>
  ) : (
    <button
      onClick={onFullscreenClose}
      title="deactivate fullscreen"
    >
      <DeactivateFullScreen />
    </button>
  );
}

function IsAuthenticated({ children }: PropsWithChildren) {
  const { isAuthenticated } = useApi();
  if (!isAuthenticated) return null;
  return <>{children}</>;
}

function IsNotAuthenticated({ children }: PropsWithChildren) {
  const { isAuthenticated } = useApi();
  if (isAuthenticated) return null;
  return <>{children}</>;
}
