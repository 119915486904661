import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetShortBalance, useGetWithdrawal } from 'src/api/balance';
import { useGetTransactions } from 'src/api/transactions';
import { useGetWithdrawalMethods, useMakeWithdrawal } from 'src/api/withdrawal/withdrawal.hooks';
import { Button } from 'src/components/Buttons/Button';
import { PaymentMethods } from 'src/components/DepositComponents/PaymentMethods';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { Input } from 'src/components/Inputs/Input';
import {
  validateTransactionValue,
  validateNumbersString,
  validatePositiveNumber,
  validateWithdrawalAccountRequired,
  validateWithdrawalCardRequired
} from 'src/hooks/useForm';
import { FieldType, useForm, validateMaxLength, validateRequired } from 'src/hooks/useForm/index';
import { PaymentMethod } from 'src/types';
import {
  makeFloat,
  prepareMethodOptions,
  validateDepositButton
} from 'src/utils';
import { useShortBalanceFormatted } from './Deposit';
import { Link, useNavigate } from 'react-router-dom';
import { useGetFullInfo } from 'src/api/profile';

export const Withdrawal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fullInfo: { email: userEmail } = {} } = useGetFullInfo({});
  const { mutateAsync: makeWithdrawal, isLoading } = useMakeWithdrawal();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const { methods } = useGetWithdrawalMethods();
  const { withdrawDetails } = useGetWithdrawal({});
  const { data: shortBalance, isLoading: isGetShortBalanceLoading } = useGetShortBalance();

  const { transactions: allTransactions } = useGetTransactions(
    { type: '1', currency: shortBalance?.currency || 'EUR' },
    'withdrawalTransactions',
    !isGetShortBalanceLoading
  );
  const balance = useShortBalanceFormatted();
  const [coin, setCoin] = useState('');

  const {
    values,
    errors,
    fieldsProps: { withdrawAmountProps, accountProps, holderNameProps, walletTagProps },
    touched,
    setValues,
    isFormValid,
    resetForm
  } = useForm<{
    withdrawAmount: FieldType.Number;
    account: FieldType.Text;
    holderName: FieldType.Text;
    walletTag: FieldType.Text;
  }>({
    withdrawAmount: {
      placeholder: `${t('withdrawal.amount')}`,
      fieldType: FieldType.Number,
      validation: [
        validateRequired,
        validateMaxLength(20),
        validateNumbersString,
        validatePositiveNumber,
        validateTransactionValue({
          min: methods?.min,
          max: methods?.max,
          currency: balance.currencySign,
          minPaymentMethod: paymentMethod?.min_amount,
          maxPaymentMethod: paymentMethod?.max_amount,
          balance: Number(withdrawDetails?.available_for_withdraw)
        })
      ]
    },
    account: {
      placeholder:
        paymentMethod?.type === 'coin'
          ? `${t('accountWalletDeposite.walletAddress')}`
          : `${t('withdrawal.account')}`,
      fieldType: FieldType.Text,
      validation: [validateWithdrawalAccountRequired(paymentMethod?.type, 'bank')]
    },
    holderName: {
      placeholder: `${t('withdrawal.cardName')}`,
      fieldType: FieldType.Text,
      validation: [validateWithdrawalCardRequired(paymentMethod?.type, 'card')]
    },
    walletTag: {
      placeholder: `${t('accountWalletDeposite.walletTag')}`,
      fieldType: FieldType.Text,
      validation: [validateWithdrawalCardRequired(paymentMethod?.type, 'coin')]
    }
  });

  const onSelectPaymentMethod = (value?: PaymentMethod) => {
    setPaymentMethod(value);
    setValues({
      withdrawAmount: '',
      account: '',
      holderName: '',
      walletTag: ''
    });
  };

  const awaitingForWithdrawal = useMemo(
    () =>
      makeFloat(
        allTransactions?.transactions
          .filter((el) => el.status === 7 || el.status === 8 || el.status === 12)
          .reduce((acc, el) => acc + el.amount, 0)
      ),
    [allTransactions]
  );

  const onSelectCoinType = (element?: string) => {
    element && setCoin(element);
  };

  const handleSubmitClick = async () => {
    if (!paymentMethod) return;
    const method = coin ? `${paymentMethod.method}_${coin}` : paymentMethod.method;
    await makeWithdrawal({
      amount: `${values.withdrawAmount}00`,
      account: values.account || undefined,
      currency: shortBalance?.currency || 'EUR',
      method,
      email: userEmail,
      paymentAccount: values.holderName || values.walletTag || undefined
    });
    resetForm();
    navigate('/account/wallet/history');
  };

  const header = (
    <div className="flex justify-between flex-wrap items-center r_xsm:justify-center">
      <p className="text-[16px] font-bold text-text_2 flex items-center gap-2 r_xsm:flex-col ">
        {t('accountWalletWithdrawal.available')}:{' '}
        <span className="text-[20px] font-bold text-text_12">
          {balance.currencySign}
          {makeFloat(withdrawDetails?.available_for_withdraw).toFixed(2)}
        </span>
      </p>
      <p className="text-[16px] font-bold text-text_2 flex items-center gap-2 r_xsm:flex-col ">
        {t('accountWalletWithdrawal.awaiting')}:{' '}
        <span className="text-[20px] font-bold text-text_12">
          {balance.currencySign}
          {awaitingForWithdrawal.toFixed(2)}
        </span>
      </p>
    </div>
  );

  return (
    <div className="flex flex-col gap-7 px-4 lg:px-0 active-balance-card rounded-[20px]">
      <div className="flex flex-col gap-3 rounded-[20px] py-[30px] px-[20px]">
        <PaymentMethods
          paymentMethods={methods?.paymentMethods}
          balance={balance}
          onSelectPaymentMethod={onSelectPaymentMethod}
          selectedMethod={paymentMethod?._reactDepositMethodId}
          header={header}
        />
        {paymentMethod ? (
          <>
            {paymentMethod?.options && paymentMethod?.options.length ? (
              <div className="z-[21]">
                <Dropdown
                  value={coin}
                  onChange={onSelectCoinType}
                  placeholder="Select cryptocurrency"
                  options={prepareMethodOptions(paymentMethod)}
                />
              </div>
            ) : null}
            {paymentMethod?.type !== 'bank' && (
              <div className="flex w-full gap-3 r_small:flex-col">
                <div className="flex flex-col w-full">
                  <Input {...accountProps} />
                  {touched.account && errors.account && (
                    <span className="text-[red] text-xs">{errors.account}</span>
                  )}
                </div>
                {paymentMethod?.type === 'coin' ? (
                  <div className="flex flex-col w-full">
                    <Input {...walletTagProps} />
                    {touched.walletTag && errors.walletTag && (
                      <span className="text-[red] text-xs">{errors.walletTag}</span>
                    )}
                  </div>
                ) : null}
              </div>
            )}
            {paymentMethod?.type === 'card' && (
              <div className="flex flex-col w-full">
                <Input {...holderNameProps} />
                {touched.holderName && errors.holderName && (
                  <span className="text-[red] text-xs">{errors.holderName}</span>
                )}
              </div>
            )}
            <div className="flex flex-col w-full">
              <Input
                {...withdrawAmountProps}
                autoComplete="off"
                rightIcon={<div>{balance.currencySign}</div>}
              />
              {errors.withdrawAmount && (
                <span className="text-[red] text-xs">{errors.withdrawAmount}</span>
              )}
            </div>
            <div className="text-[12px] font-medium text-text_2 self-end">
              {t('accountWalletWithdrawal.minWithdrawal')}: {balance.currencySign}
              {makeFloat(methods?.min)}
            </div>
          </>
        ) : null}
        <p className="text-[12px] mb-[20px] font-medium text-text_2 text-center">
          {t('accountWalletWithdrawal.desc1')}
          {t('accountWalletWithdrawal.desc2')}
          <Link
            to="/account/profile/main"
            className="pl-[3px] underline lowercase cursor-pointer text-text_8">
            {t('accountWalletWithdrawal.desc3')}
          </Link>
        </p>
        <div className=" flex justify-center">
          <Button
            title={`${t('general.send')}`}
            disabled={validateDepositButton(isFormValid, coin, paymentMethod)}
            mode="primary"
            loading={isLoading}
            onClick={handleSubmitClick}>
            {t('general.send')}
          </Button>
        </div>
      </div>
    </div>
  );
};
