import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { selectScenarioForCalculate } from './responsiveUtilsAndConstants';
import { ResponsiveAtProps, ResponsiveFromProps } from './types';

export const Responsive = (props: ResponsiveAtProps | ResponsiveFromProps) => {
  const [showElement, setShowElement] = useState(false);
  const firstRenderRef = useRef<boolean>(false);
  const memoisedShowElement = useRef<boolean>(false);

  const handleResize = useCallback(() => {
    const clientWidth = document.documentElement.clientWidth;
    const calculatedToShow = selectScenarioForCalculate(props, clientWidth);
    if (memoisedShowElement.current !== calculatedToShow) {
      setShowElement(() => calculatedToShow);
      memoisedShowElement.current = calculatedToShow;
    }
  }, [props]);

  useLayoutEffect(() => {
    if (!firstRenderRef.current) {
      handleResize();
      firstRenderRef.current = true;
    }
    if (firstRenderRef.current) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      firstRenderRef.current = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [firstRenderRef.current, props]);

  return showElement ? props.children : null;
};
