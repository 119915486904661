import Bowser from 'bowser';
import classNames from 'classnames';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useGetFullBalance, useGetShortBalance } from 'src/api/balance';
import aviator from 'src/assets/images/aviator.png';
import android from 'src/assets/sidebar-icons/android.png';
import googlePlay from 'src/assets/sidebar-icons/google-play.png';
import huawei from 'src/assets/sidebar-icons/huawei.png';
import letter from 'src/assets/sidebar-icons/letter.png';
import { categoriesItem, menuItem } from 'src/constants';
import { useApi } from 'src/hooks/useApi';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useDrawer } from 'src/hooks/useDrawer';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { getCurrencyIcon, makeFloat, openChat } from 'src/utils';

import { useGetUserCountry } from '../../api/profile';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Button } from '../Buttons/Button';
import { GameCategoriesItem } from '../GameCategoriesItem/GameCategoriesItem';
import Languages from '../Languages';
import { Separator } from '../Separator/Separator';
import { AccountForProfile, Arrow } from '../svg';

import './styles.css';

export const SidebarContent: FC = () => {
  const [isCategories, setIsCategories] = useState(true);
  const { data: shortBalance } = useGetShortBalance();
  const { fullBalance } = useGetFullBalance();
  const { isNLD } = useGetUserCountry({});
  const { isAuthenticated } = useApi();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const params = useParams();
  const userDevice = Bowser.parse(window.navigator.userAgent);

  const { openModal } = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { _closeMainAccountSidebar, _closeMainSidebar, closeBottomDrawer, closeLeftDrawer, goToAccount } = useDrawer();
  const { isMobile } = useDeviceDetect();

  const closeAllDrawers = () => {
    _closeMainSidebar?.();
    _closeMainAccountSidebar?.();
    closeLeftDrawer?.();
    closeBottomDrawer?.();
  };

  const currency = useMemo(() => {
    return fullBalance?.currency ?? '';
  }, [fullBalance]);
  // TODO если нужен будет вывод баланса из fullBalance
  // const balance = useMemo(() => {
  //   return makeFloat(
  //     getSummOfPossiblyUndefinedNums(
  //       fullBalance?.balance[currency as keyof typeof fullBalance.balance],
  //       bonusAccountCasino?.balance
  //     )
  //   ).toFixed(2);
  // }, [fullBalance, currency, bonusAccountCasino]);
  const balance = useMemo(() => {
    return `${makeFloat(shortBalance?.balance).toFixed(2)}`;
  }, [shortBalance]);
  const icon = getCurrencyIcon(currency);

  const handeleLanguagesClick = useCallback(() => {
    menuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [menuRef]);

  const handleBannerClick = () => {
    const pathname = '/game/34642';

    sendGoogleAnalyticsEvent('burger_banner_opened', {
      banner_id: pathname,
    });

    if (!isAuthenticated) {
      openModal?.(MODAL_NAME.SIGNUP);
      return;
    }

    isMobile && closeAllDrawers();
    closeLeftDrawer?.();

    navigate(pathname);
  };

  return (
    <>
      <div className="flex w-full flex-col items-center gap-y-[12px] py-1">
        {isAuthenticated ? (
          <>
            <Button
              additionalClassName="w-full"
              mode="primary"
              onClick={() => {
                openModal?.(MODAL_NAME.POPUP_DEPOSIT);
                isMobile && closeAllDrawers();
                closeLeftDrawer?.();
              }}
              title={`${t('general.deposit')}`}
            >
              {t('general.deposit')}
            </Button>
            <Button
              additionalClassName="w-full"
              leftIcon={<AccountForProfile fill="#FFF" />}
              mode="secondary"
              onClick={() => {
                navigate('/account/profile/main');
                isMobile && closeAllDrawers();
                goToAccount?.();
              }}
              title={`${t('general.account')}`}
            >
              {<p className="ml-[12px] uppercase text-text_2">{t('general.account')}</p>}
            </Button>
          </>
        ) : (
          <>
            <Button
              mode="secondary"
              onClick={() => {
                isMobile && closeAllDrawers();
                closeLeftDrawer?.();
                openModal?.(MODAL_NAME.SIGNUP);
              }}
              title={`${t('general.signUp')}`}
            >
              {t('general.signUp')}
            </Button>
            <Button
              mode="primary"
              onClick={() => {
                isMobile && closeAllDrawers();
                closeLeftDrawer?.();
                openModal?.(MODAL_NAME.LOGIN);
              }}
              title={`${t('general.logIn')}`}
            >
              {t('general.logIn')}
            </Button>
          </>
        )}
        <Separator width="w-[100%]" />
        {isAuthenticated && (
          <>
            <div className="flex flex-col items-center justify-center">
              <span className="sidebar-heading">{t('general.balance')}</span>
              <p className="sidebar-balance">
                <span className="mr-[4px]">{icon}</span>
                <span>{balance}</span>
              </p>
            </div>
          </>
        )}
        {isAuthenticated ? (
          <Separator
            noMarginTop
            width="w-[100%]"
          />
        ) : null}
        <>
          <div
            className="flex w-full cursor-pointer items-center justify-around"
            onClick={() => setIsCategories(!isCategories)}
          >
            <p className="text-base font-bold uppercase">{t('general.gamesCategories')}</p>
            <Arrow
              className={classNames(
                'h-[15px] w-[15px] stroke-[3px] text-end duration-300 ease-in-out',
                !isCategories && 'origin-center rotate-180',
              )}
            />
          </div>
          {isCategories ? (
            <div className="sidebar-game grid w-full grid-cols-3 gap-2">
              {categoriesItem.map((item) => (
                <NavLink
                  aria-label={`${t(item.name)}`}
                  className={({ isActive }) => classNames(isActive && !params.search && 'active-link')}
                  key={item.path}
                  onClick={() => isMobile && closeAllDrawers()}
                  to={item.path}
                >
                  <GameCategoriesItem
                    content={item.name}
                    image={item.image}
                  />
                </NavLink>
              ))}
            </div>
          ) : null}
        </>
        <Separator
          noMarginTop
          width="w-[100%]"
        />
        <div className="flex w-full flex-col">
          {menuItem.map((item, index) => (
            <NavLink
              aria-label={`${t(item.name)}`}
              className={({ isActive }) => (isActive ? 'text-text_8' : 'text-text_2')}
              key={index}
              onClick={() => {
                isMobile && closeAllDrawers();
                closeLeftDrawer?.();
              }}
              to={item.path}
            >
              <div className="flex w-full flex-row items-center justify-start pt-[5px] hover:text-text_8">
                <div className="mr-[16px] flex max-h-[20px] w-[26px] items-center justify-center">{item.image}</div>
                <p className="max-w-[130px] text-[12px] font-bold uppercase not-italic">{t(item.name)}</p>
              </div>
            </NavLink>
          ))}
          <div
            className="flex w-full cursor-pointer flex-row items-center justify-start pt-[5px] hover:text-text_8"
            onClick={() => {
              isMobile && closeAllDrawers();
              closeLeftDrawer?.();
              openChat();
            }}
          >
            <div className="mr-[16px] flex w-[26px] items-center justify-center">
              <img
                alt="letter"
                src={letter}
                style={{ maxWidth: '20px' }}
              />
            </div>
            <p className="text-[12px] font-bold uppercase not-italic">{t('menuItem.liveChat')}</p>
          </div>
        </div>
        <Separator
          noMarginTop
          width="w-[100%]"
        />
        <div className="my-[18px] flex flex-col gap-5">
          {(!isAuthenticated || !isNLD) && (
            <>
              <div
                className="cursor-pointer hover:grayscale"
                onClick={handleBannerClick}
              >
                <img
                  alt="aviator"
                  src={aviator}
                />
              </div>
              <Separator
                noMarginTop
                width="w-[100%]"
              />
            </>
          )}
        </div>
        <div
          className="flex flex-col items-center justify-center"
          ref={menuRef}
        >
          {isAuthenticated && (
            <div className="flex mt-[18px] flex-col gap-y-[12px]">
              <p className="uppercase text-[12px] font-bold text-center">Download app in</p>

              <div className="flex gap-x-[7px]">
                <a
                  className="w-[52px] h-[52px] rounded-[8px] bg-[#141414] border-1 border-[#ff6300] flex items-center justify-center"
                  download
                  href="https://betsomnia.com/app.apk"
                  onClick={() => {
                    sendGoogleAnalyticsEvent('apk_click', {
                      device_name: userDevice.os.name,
                    });
                  }}
                >
                  <img
                    alt=""
                    className="w-[24px] h-[24px]"
                    src={android}
                  />
                </a>

                <a
                  className="w-[52px] h-[52px] rounded-[8px] bg-[#141414] border-1 border-[#ff6300] flex items-center justify-center"
                  download
                  href="https://betsomnia.com/app.apk"
                  onClick={() => {
                    sendGoogleAnalyticsEvent('apk_click', {
                      device_name: userDevice.os.name,
                    });
                  }}
                >
                  <img
                    alt=""
                    className="w-[24px] h-[24px]"
                    src={googlePlay}
                  />
                </a>

                <a
                  className="w-[52px] h-[52px] rounded-[8px] bg-[#141414] border-1 border-[#ff6300] flex items-center justify-center"
                  href="https://betsomnia.click/csw2l4k.php?key=fvtg2w2cs3vc3pfxd2ur"
                >
                  <img
                    alt=""
                    className="w-[24px] h-[24px]"
                    src={huawei}
                  />
                </a>
              </div>
            </div>
          )}
          <Languages handleClick={handeleLanguagesClick} />
        </div>
      </div>
    </>
  );
};
