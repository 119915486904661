import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllUserLevels } from 'src/api/content';
import { useGetFullInfo } from 'src/api/profile';
import { Button } from 'src/components/Buttons/Button';
import { Carousel } from 'src/components/Carousel/Carousel';
import { Responsive } from 'src/components/Responsive';
import { LevelCard, Levels } from 'src/components/VIP';
import noLevels from 'src/assets/images/noTournamentsImage.webp';
import { Link } from 'react-router-dom';
import { Wrapper } from 'src/components/Wrapper/Wrapper';
import { openChat } from 'src/utils';

export const AccountVip = () => {
  const { t } = useTranslation();
  const { allLevels } = useGetAllUserLevels({
    loyalty_page_code: 'loyalty'
  });
  const { fullInfo } = useGetFullInfo({});
  const [activeIndex, setActiveIndex] = useState(0);

  // * data for card
  const userLevelData = allLevels.filter((level) => {
    return (
      level.levelInfo?.level === fullInfo?.level ||
      (fullInfo?.level && level.levelInfo?.level === fullInfo?.level + 1)
    );
  });

  return (
    <div>
      <Responsive at={['lg', 'md', 'xl']}>
        <div className='w-full flex flex-col justify-start items-center'>
          <Wrapper padding='pt-10'>
            {fullInfo && allLevels ? (
              <Carousel
                initialSlide={fullInfo.level - 1}
                slides={allLevels.map((level, index) => {
                  return (
                    <LevelCard
                      key={index}
                      level={level}
                      blockAccordions={index !== activeIndex ? true : undefined}
                    />
                  );
                })}
                setActiveIndex={setActiveIndex}
                arrows={true}
                effect="cards"
                inactiveSlideClassname="blur-sm pointer-events-none"
              />
            ) : null}
            <Button
              title={`${t('mainSidebarLinks.liveChat')}`}
              mode="secondary"
              additionalClassName="text-base font-bold mx-auto mt-[20px]"
              onClick={openChat}>
              {t('mainSidebarLinks.liveChat')}
            </Button>
          </Wrapper>
        </div>
      </Responsive>
      <Responsive at={['gf', 'xs', 'sm']}>
        <div className="flex flex-col justify-start items-center mt-10">
          {userLevelData.length === 0 ? (
            <div className="flex flex-col justify-center items-center max-w-[80%] mt-[30px]">
              <p className="text-text_2 text-center w-full">{t('vip.noLevels')}</p>
              <img src={noLevels} alt="no levels" className="-mt-10" />
            </div>
          ) : (
            <Levels allLevels={userLevelData} showOne />
          )}
          {userLevelData.length > 0 && (
            <>
              <Button
                title={`${t('mainSidebarLinks.liveChat')}`}
                mode="secondary"
                additionalClassName="text-base font-bold mt-[20px]"
                onClick={openChat}>
                {t('mainSidebarLinks.liveChat')}
              </Button>
              <p className="text-xs font-medium mt-[20px] text-text_2 max-w-[60%] text-center">
                {t('general.personalManager')}
              </p>
            </>
          )}
          <Link
            to="/vip"
            className="text-base font-bold mt-[35px] text-text_2 underline"
            aria-label={`${t('general.moreLevelsInfo')}`}>
            {t('general.moreLevelsInfo')}
          </Link>
        </div>
      </Responsive>
    </div>
  );
};
