import { useEffect, useRef } from 'react';

type Props = {
  callback: () => void;
  childRef: React.MutableRefObject<HTMLDivElement | null>;
  negativeCallBack?: () => void;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
};

export function useScroll({ callback, childRef, negativeCallBack, parentRef }: Props) {
  const observer = useRef<IntersectionObserver | null>();
  useEffect(() => {
    if (parentRef.current && childRef.current) {
      const options = {
        rootMargin: '0px',
        threshold: 0.5,
      };

      const targetRefValue = childRef.current;

      observer.current = new IntersectionObserver(([target]) => {
        if (target.isIntersecting) {
          callback();
        } else {
          negativeCallBack && negativeCallBack();
        }
      }, options);

      observer.current.observe(targetRefValue as Element);

      return function () {
        observer.current?.unobserve(targetRefValue as Element);
      };
    }
  }, [callback, parentRef, childRef]);
}
