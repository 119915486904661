import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/useApi';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { Button } from '../Buttons/Button';

export const PopUpDemo = () => {
  const { openModal, modalProps } = useModal();
  const { isAuthenticated } = useApi();
  const { t } = useTranslation();

  const location = useLocation();

  const history = useMemo(() => {
    return location.state.history || [];
  }, [location.state.history]);

  return (
    <div className="flex flex-col items-center justify-center text-center p-[15px] py-[20px] w-[300px] r_xsm:w-full">
      <p className="text-text_1 text-[16px] font-bold leading-5">
        {t('popup.gamedemofirst')}
      </p>
      <p className="text-text_1 text-[16px] font-bold leading-5">
        {t('popup.gamedemosecond')}
      </p>
      <Link
        to={isAuthenticated ? `/game/${modalProps.gameId}` : ''}
        state={{ history: [...history, location.pathname] }}>
        <Button
          mode="primary"
          additionalClassName="mt-[30px]"
          onClick={() => {
            !isAuthenticated && openModal?.(MODAL_NAME.SIGNUP);
          }}
          title={`${t('general.depositplay')}`}>
          {t('general.depositplay')}
        </Button>
      </Link>
    </div>
  );
};
