import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useApi } from './useApi';

export enum MODAL_NAME {
  ATTENTION_BONUS = 'ATTENTION_BONUS',
  BALANCE_CHANGE_CURRENCY = 'BALANCE_CHANGE_CURRENCY',
  DEPOSIT_CRYPTO = 'DEPOSIT_CRYPTO',
  DEPOSIT_HELLOCLEVER_RESULT = 'DEPOSIT_HELLOCLEVER_RESULT',
  DEPOSIT_PAYMENT_INFO = 'DEPOSIT_PAYMENT_INFO',
  FORM_FIELDS = 'FORM_FIELDS',
  GAME_NOTIFICATION = 'GAME_NOTIFICATION',
  IOSSETUP = 'IOS_SETUP',
  LOGIN = 'LOGIN',
  NEW_TOURNAMENT = 'NEW_TOURNAMENT',
  NODAPAY_METHODS = 'NODAPAY_METHODS',
  POPUP_DEMO = 'POPUP_DEMO',
  POPUP_DEPOSIT = 'POPUP_DEPOSIT',
  POPUP_FREESPINS = 'PopUpFreeSpins',
  POPUP_GET_LEVEL_REWARD = 'POPUP_GET_LEVEL_REWARD',
  POPUP_PROMOCODE = 'POPUP_PROMOCODE',
  POPUP_SINGLE_GAME = 'POPUP_SINGLE_GAME',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SIGNUP = 'SIGNUP',
  SUPPORT = 'SUPPORT',
  UPDATE_MAIL_AND_PASSWORD = 'UPDATE_MAIL_AND_PASSWORD',
  UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION',
  VIP_RULES = 'VIP_RULES',
}

type ModalContextValueType<T = any> = {
  closeModal?: () => void;
  isTransparentBg?: boolean;
  modalProps?: T;
  openedModal?: MODAL_NAME;
  openModal?: (el: MODAL_NAME, props?: any, showClose?: boolean, transparentBg?: boolean, saveStack?: boolean) => void;
  showCloseModal?: boolean;
  toggleModal?: () => void;
};

export const ModalContext = createContext<{
  closeModal?: () => void;
  isTransparentBg?: boolean;
  modalProps?: any;
  openedModal?: MODAL_NAME;
  openModal?: (el: MODAL_NAME, props?: any, showClose?: boolean, transparentBg?: boolean, saveStack?: boolean) => void;
  showCloseModal?: boolean;
  toggleModal?: () => void;
}>({});

type OpenedModal = {
  name: MODAL_NAME;
  props?: any;
  saveStack?: boolean;
  showClose: boolean;
  transparentBg: boolean;
};

export const useInitModal = () => {
  const [openedModal, setOpenedModal] = useState<MODAL_NAME | undefined>(undefined);
  const [showCloseModal, setShowCloseModal] = useState<boolean | undefined>(true);
  const [modalProps, setModalProps] = useState();
  const [openedModalsStack, setOpenedModalsStack] = useState<OpenedModal[]>([]);
  const [isTransparentBg, setIsTransparentBg] = useState<boolean>(false);

  const openModal = useCallback(
    (name: MODAL_NAME, props?: any, showClose = true, transparentBg = false, saveStack = false) => {
      setOpenedModalsStack((modals) => {
        const newModals = [...(saveStack ? modals : []), { name, props, showClose, transparentBg }];
        if (props?.content) return newModals;
        const isRepeated = !!modals.find((modal) => modal.name === name);
        return isRepeated ? modals : newModals;
      });
    },
    [],
  );
  const closeModal = useCallback(() => {
    const newStack: OpenedModal[] = [];
    for (let i = 0; i < openedModalsStack.length - 1; i++) {
      newStack.push(openedModalsStack[i]);
    }
    setOpenedModalsStack(() => newStack);
  }, [openedModalsStack]);

  useEffect(() => {
    if (!openedModalsStack.length) {
      setModalProps(undefined);
      setShowCloseModal(true);
      setOpenedModal(undefined);
      setIsTransparentBg(false);
    } else {
      setModalProps(openedModalsStack[openedModalsStack.length - 1].props);
      setShowCloseModal(openedModalsStack[openedModalsStack.length - 1].showClose);
      setOpenedModal(openedModalsStack[openedModalsStack.length - 1].name);
      setIsTransparentBg(openedModalsStack[openedModalsStack.length - 1].transparentBg);
    }
  }, [openedModalsStack]);

  return { closeModal, isTransparentBg, modalProps, openedModal, openModal, showCloseModal };
};

export const useModal = <T = any>() => {
  const contextValue = useContext(ModalContext);
  return contextValue as ModalContextValueType<T>;
};

export const useModalUtils = () => {
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();

  const openDepositModal = () => {
    isAuthenticated ? openModal?.(MODAL_NAME.POPUP_DEPOSIT) : openModal?.(MODAL_NAME.SIGNUP);
  };

  return {
    openDepositModal,
  };
};
