import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGetBonuses, useGetSingleBonus } from '../../api/bonuses/bonuses.hooks';
import { Button } from '../../components/Buttons/Button';
import { GameCard } from '../../components/GameCard';
import { BonusGame } from '../../api/bonuses/bonuses.types';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'src/components/Skeleton';
import { CONTENT_URL } from 'src/api';
import { Responsive } from 'src/components/Responsive';
import { PageTitle } from 'src/components';
import { useNewGetAllGames } from 'src/api/games';
import { BonusAccordion } from 'src/components/BonusAccordion/BonusAccordion';
import { Wrapper } from 'src/components/Wrapper/Wrapper';
import diceIcon from 'src/assets/sidebar-icons/dice.png';
import { CardSeeAll } from 'src/components/CardSeeAll/CardSeeAll';

const COUNT_GAME = 29;

export const Bonus: FC = () => {
  const { t } = useTranslation();
  const { bonus } = useParams<{ bonus: string }>();
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const { data: allBonuses } = useGetBonuses();

  const { allGames, fetchNextPage, hasNextPage } = useNewGetAllGames(
    {
      is_wagering: 1,
      per_page: COUNT_GAME
    },
    'bonusPageIsWageringGames'
  );

  const { data } = useGetSingleBonus(bonus);

  const handleClickDeposit = useCallback(() => {
    if (isAuthenticated) {
      openModal?.(MODAL_NAME.POPUP_DEPOSIT);
    } else {
      openModal?.(MODAL_NAME.SIGNUP);
    }
  }, [isAuthenticated, openModal]);

  return (
    <div className="w-full">
      <PageTitle title="Promo" />
      <div className="px-2.5 pt-2 flex items-center justify-center self-center flex-col mt-2">
        {!data ? (
          <Skeleton />
        ) : (
          <Wrapper additionalClassName="flex flex-col justify-center items-center">
            <Responsive at={['lg', 'xl']}>
              <div
                className="mb-11 w-full relative flex justify-start items-center red-shadow h-[269px] max-w-[1020px] rounded-[20px] red-shadow px-[80px]"
                style={{
                  backgroundImage: `url(${CONTENT_URL}${data?.imageBackground})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%'
                }}>
                <div className="flex gap-2.5 flex-col max-w-[550px] justify-center h-full z-10">
                  <div
                    className="font-black text-text_2 text-3xl break-words"
                    dangerouslySetInnerHTML={{ __html: data.name }}></div>
                  <div
                    className="text-text_2 text-3xl short-text"
                    dangerouslySetInnerHTML={{ __html: data.shortDescription }}></div>
                  <Button
                    title={`${t('general.deposit')}`}
                    mode="primary"
                    onClick={handleClickDeposit}
                    additionalClassName="mt-10 px-6">
                    {t('general.deposit')}
                  </Button>
                </div>
              </div>
            </Responsive>
            <Responsive at={['sm', 'xs', 'md']}>
              <div className="w-full flex flex-col justify-center items-center gap-5">
                <div className="rounded-[10px] min-h-[130px] red-shadow relative overflow-y-hidden w-full">
                  <img
                    src={`${CONTENT_URL}${data.imageBanner}`}
                    alt="bonus-image"
                    className="rounded-[10px] absolute top-0 right-0 min-h-[130px]"
                  />
                </div>
                <div className="flex justify-center items-center w-full gap-[30px]">
                  <Button
                    title={`${t('general.deposit')}`}
                    mode="primary"
                    additionalClassName="min-w-full"
                    onClick={handleClickDeposit}
                    label={`${t('general.deposit')}`}
                  />
                </div>
                <div className="flex flex-col justify-center items-center w-full z-10">
                  <div
                    className="font-bold text-text_2 text-4xl break-words text-left w-full"
                    dangerouslySetInnerHTML={{ __html: data.name }}></div>
                  <div
                    className="font-normal text-text_2 text-xl break-words w-full text-left short-text"
                    dangerouslySetInnerHTML={{ __html: data.shortDescription }}></div>
                </div>
              </div>
            </Responsive>
            <div className="px-8 mb-[30px] text-text_2 max-w-[700px] w-full r_sm:px-0">
              {data && data.tag === 'vip-pack'
                ? allBonuses
                    .filter((bonus) => bonus.tag === 'standard')
                    .map((bonus) => <BonusAccordion bonus={bonus} key={bonus.id} />)
                : null}
              <div
                className="static-page bonus-content"
                dangerouslySetInnerHTML={{
                  __html: data?.fullDescription
                }}></div>
            </div>
            {allGames.length === 0 ? null : (
              <>
                <div className="my-3 flex flex-col gap-2">
                  <div className="w-full flex justify-center items-center gap-3">
                    <img src={diceIcon} alt="icon" className="w-[30px] h-[30px] hidden md:flex" />
                    <p className="text-text_8 font-bold text-xl text-center uppercase">
                      {t('bonusPage.gamesParticipating')}
                    </p>
                  </div>
                </div>
                <div className="grid r_sm:grid-cols-3 grid-cols-6 gap-x-1.5 gap-y-2.5 mt-4 w-full">
                  {allGames?.map((game: BonusGame) =>
                    game ? <GameCard key={game.id} game={game} /> : null
                  )}
                  {hasNextPage && <CardSeeAll onClick={fetchNextPage} moreButton />}
                </div>
              </>
            )}
          </Wrapper>
        )}
      </div>
    </div>
  );
};
