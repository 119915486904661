import React from 'react';
import classnames from 'classnames';

type SeparatorProps = {
  width: string;
  noMarginTop?: boolean;
  white?: boolean;
  borderColor?: string;
};

export const Separator: React.FC<SeparatorProps> = ({ width, noMarginTop, white, borderColor }) => (
  <div
    className={classnames(
      'border-1',
      noMarginTop ? 'mt-0' : 'mt-[20px]',
      borderColor ? borderColor : 'border-border_8',
      width,
      {
        'border-border_6': Boolean(white)
      }
    )}
  />
);
