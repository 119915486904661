import axios from 'axios';

export const isDev = import.meta.env.VITE_MODE === 'development';
export const API_URL = `${window.origin}/api/`;
export const MESSAGEWHIZ_URL = `${window.origin}/messagewhiz/`;
export const CONTENT_URL = `${window.origin}`;
export const STORAGE_URL = 'https://95160e01.gcdn.easyrocket.net';

export const apiProvider = axios.create({ baseURL: API_URL });

export const messageWhizProvider = axios.create({ baseURL: MESSAGEWHIZ_URL });

export const isProdEnv = window.origin.includes('betsomnia') || import.meta.env.VITE_DOMAIN?.includes('dusk.gbx-co.re');
