import { FC } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useDrawer } from 'src/hooks/useDrawer';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';

interface GameCategoriesItemProps extends React.HTMLAttributes<HTMLDivElement> {
  image: JSX.Element;
  content: string;
}

export const GameCategoriesItem: FC<GameCategoriesItemProps> = ({ image, content, ...rest }) => {
  const { t } = useTranslation();
  const { isXxs, isXs } = useBreakpoints();
  const isMobileScreen = isXxs || isXs;
  const { closeLeftDrawer, _closeMainSidebar } = useDrawer();

  const handleClickCategory = () => {
    isMobileScreen && _closeMainSidebar?.();
    closeLeftDrawer?.();
  };

  return (
    <div {...rest} className="game-category-item-wrapper" onClick={handleClickCategory}>
      <div className="game-category-item">
        <div className="game-category-item-icon">{image}</div>
        <p className="game-category-item-content">{t(content)}</p>
      </div>
    </div>
  );
};
