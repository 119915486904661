import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'src/api/transactions';
import { TableCell } from 'src/components/Table/TableCell';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'src/types';
import { useGetTransactions } from '../../api/transactions/transactions.hooks';
import { HistoryForm } from '../../components';
import { Table } from '../../components/Table';
import { bonusHeader } from '../../constants';
import { getCurrencyIcon, makeFloat } from '../../utils';
import { useGameHistoryForm } from './useGameHistory';
import { TablePagination } from 'src/components/Table/TablePagination';
import './styles.css';

const isOfNeededType = [
  { id: '2', name: 'Bet' },
  { id: '3', name: 'Win' },
  { id: '4', name: 'Bonus' },
  { id: '10', name: 'Money Prize' },
  { id: '18', name: 'FreeSpinWin' },
  { id: '22', name: 'Cashback' },
  { id: '23', name: 'Level Bonus' }
];

export const BasicTransactionHistory = () => {
  const { t } = useTranslation();
  const [cleared, setCleared] = useState<boolean>(false);
  const {
    values,
    resetForm,
    fieldsProps: {
      transactionTypesProps,
      dateFromProps,
      dateToProps,
      amountFromProps,
      amountToProps
    }
  } = useGameHistoryForm(isOfNeededType);

  const { numberPages, refetch, page, setPage, arrayTransactions } = useGetTransactions({
    amount_from: values.amountFrom ? parseFloat(values.amountFrom) / 0.01 : undefined,
    amount_to: values.amountTo ? parseFloat(values.amountTo) / 0.01 : undefined,
    date_end: values.dateTo ? values.dateTo : undefined,
    date_start: values.dateFrom ? values.dateFrom : undefined,
    type:
      values.transactionTypes?.length === 0
        ? '2,3,4,10,18,22,23'
        : values.transactionTypes?.join(',')
  });

  useEffect(() => {
    refetch();
  }, [refetch, page, cleared]);

  const handleClearForm = useCallback(() => {
    resetForm();
    setCleared(!cleared);
  }, [cleared, resetForm]);

  const handleNextPage = useCallback(() => {
    setPage((old) => old + 1);
  }, [setPage]);

  const handlePrevPage = useCallback(() => {
    setPage((old) => Math.max(old - 1, 0));
  }, [setPage]);

  const hanleChangePage = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  return (
    <div className="flex flex-col gap-10">
      <div className="max-w-[550px]">
        <HistoryForm
          transaction={t('transactionTable.basicTransaction')}
          resetForm={handleClearForm}
          transactionTypesProps={transactionTypesProps}
          dateFromProps={dateFromProps}
          dateToProps={dateToProps}
          amountFromProps={amountFromProps}
          amountToProps={amountToProps}
          refetch={refetch}
        />
      </div>
      {arrayTransactions?.length === 0 ? (
        <div className="flex flex-col gap-3">
          <p className="font-bold text-2xl text-text_8">{t('transactionTable.notFoundBasic')}</p>
          <p className="font-medium text-sm text-text_2">
            {t('transactionTable.notFoundBasicText')}
          </p>
        </div>
      ) : (
        <>
          <p className="text-text_2 text-[12px] font-bold leading-[16px] text-right mb-2.5 responsiveHide">
            {t('general.tapScroll')} &gt;&gt;
          </p>
          <div className="overflow-auto mt-2.5">
            <div className="w-[900px]">
              <Table
                header={bonusHeader}
                tableRow={arrayTransactions?.map((data, index) => (
                  <TableRowTransaction key={index} data={data} />
                ))}
              />
            </div>
          </div>
          {numberPages ? (
            <div className="max-w-[900px]">
              <TablePagination
                currentPage={page}
                pages={numberPages}
                nextPage={handleNextPage}
                prevPage={handlePrevPage}
                onClick={hanleChangePage}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

function TableRowTransaction({ data }: { data: Transaction }) {
  const { id, type, amount, balance, dateComplete, dateCreate, currency, status } = data;

  return (
    <tr className="even:bg-background_12">
      <TableCell item={id} />
      <TableCell item={TRANSACTION_TYPE[type]} />
      <TableCell item={makeFloat(amount).toFixed(2)} />
      <TableCell item={`${makeFloat(balance).toFixed(2)} ${getCurrencyIcon(currency)}`} />
      <TableCell
        item={`${dayjs(dateComplete === 0 ? dateCreate * 1000 : dateComplete * 1000).format(
          'DD-MM-YYYY'
        )} ${TRANSACTION_STATUS[status]}`}
      />
    </tr>
  );
}
