type LangIconsProps = {
  className?: string;
};

export const EnLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      fill="none"
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2734 23C18.6247 23 23.7734 17.8513 23.7734 11.5C23.7734 5.14873 18.6247 0 12.2734 0C5.92216 0 0.773438 5.14873 0.773438 11.5C0.773438 17.8513 5.92216 23 12.2734 23Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.1507 4.49857C2.24737 5.67386 1.56622 7.02848 1.1696 8.50007H7.15221L3.1507 4.49857Z"
        fill="#0052B4"
      />
      <path
        d="M23.3773 8.50007C22.9807 7.02852 22.2995 5.6739 21.3962 4.49861L17.3948 8.50007H23.3773Z"
        fill="#0052B4"
      />
      <path
        d="M1.1696 14.5001C1.56626 15.9716 2.24741 17.3262 3.1507 18.5015L7.15207 14.5001H1.1696Z"
        fill="#0052B4"
      />
      <path
        d="M19.2749 2.37731C18.0996 1.47398 16.745 0.792826 15.2734 0.396166V6.37873L19.2749 2.37731Z"
        fill="#0052B4"
      />
      <path
        d="M5.272 20.6227C6.44729 21.526 7.80191 22.2072 9.27347 22.6038V16.6213L5.272 20.6227Z"
        fill="#0052B4"
      />
      <path
        d="M9.27342 0.396166C7.80187 0.792826 6.44725 1.47398 5.272 2.37727L9.27342 6.37868V0.396166Z"
        fill="#0052B4"
      />
      <path
        d="M15.2735 22.6038C16.745 22.2072 18.0996 21.526 19.2749 20.6227L15.2735 16.6213V22.6038Z"
        fill="#0052B4"
      />
      <path
        d="M17.3948 14.5001L21.3962 18.5015C22.2995 17.3263 22.9807 15.9716 23.3773 14.5001H17.3948Z"
        fill="#0052B4"
      />
      <path
        d="M23.6761 10H13.7735V0.0973457C13.2824 0.0334219 12.7818 0 12.2734 0C11.765 0 11.2644 0.0334219 10.7735 0.0973457V9.99997H0.870783C0.806859 10.491 0.773438 10.9916 0.773438 11.5C0.773438 12.0085 0.806859 12.509 0.870783 13H10.7734V22.9027C11.2644 22.9666 11.765 23 12.2734 23C12.7818 23 13.2824 22.9666 13.7734 22.9027V13H23.6761C23.74 12.509 23.7734 12.0085 23.7734 11.5C23.7734 10.9916 23.74 10.491 23.6761 10Z"
        fill="#D80027"
      />
      <path
        d="M15.2735 14.5001L20.4052 19.6318C20.6412 19.3958 20.8663 19.1492 21.0811 18.8935L16.6877 14.5H15.2735V14.5001Z"
        fill="#D80027"
      />
      <path
        d="M9.27342 14.5001H9.27333L4.14168 19.6317C4.37761 19.8677 4.62428 20.0929 4.87997 20.3077L9.27342 15.9142V14.5001Z"
        fill="#D80027"
      />
      <path
        d="M9.27342 8.50007V8.49998L4.14172 3.36824C3.90571 3.60417 3.68056 3.85084 3.46574 4.10653L7.85923 8.50003L9.27342 8.50007Z"
        fill="#D80027"
      />
      <path
        d="M15.2735 8.50007L20.4052 3.36829C20.1693 3.13227 19.9226 2.90712 19.6669 2.69235L15.2735 7.08584V8.50007Z"
        fill="#D80027"
      />
    </svg>
  );
};

export const NlLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          fill="#fff"
          r="256"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M0 0h512v512H0z"
          fill="#0052b4"
        />
        <path
          d="m256 100.2 8.3 25.5H291l-21.7 15.7 8.3 25.6-21.7-15.8-21.7 15.8 8.3-25.6-21.7-15.7h26.8zm-110.2 45.6 24 12.2 18.9-19-4.2 26.5 23.9 12.2-26.5 4.2-4.2 26.5-12.2-24-26.5 4.3 19-19zM100.2 256l25.5-8.3V221l15.7 21.7 25.6-8.3-15.8 21.7 15.8 21.7-25.6-8.3-15.7 21.7v-26.8zm45.6 110.2 12.2-24-19-18.9 26.5 4.2 12.2-23.9 4.2 26.5 26.5 4.2-24 12.2 4.3 26.5-19-19zM256 411.8l-8.3-25.5H221l21.7-15.7-8.3-25.6 21.7 15.8 21.7-15.8-8.3 25.6 21.7 15.7h-26.8zm110.2-45.6-24-12.2-18.9 19 4.2-26.5-23.9-12.2 26.5-4.2 4.2-26.5 12.2 24 26.5-4.3-19 19zM411.8 256l-25.5 8.3V291l-15.7-21.7-25.6 8.3 15.8-21.7-15.8-21.7 25.6 8.3 15.7-21.7v26.8zm-45.6-110.2-12.2 24 19 18.9-26.5-4.2-12.2 23.9-4.2-26.5-26.5-4.2 24-12.2-4.3-26.5 19 19z"
          fill="#ffda44"
        />
      </g>
    </svg>
  );
};

export const SeLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          fill="#fff"
          r="256"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z"
          fill="#0052b4"
        />
        <path
          d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z"
          fill="#ffda44"
        />
      </g>
    </svg>
  );
};

export const EsLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          fill="#fff"
          r="256"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="m0 128 256-32 256 32v256l-256 32L0 384Z"
          fill="#ffda44"
        />
        <path
          d="M0 0h512v128H0zm0 384h512v128H0z"
          fill="#d80027"
        />
        <g fill="#eee">
          <path d="M144 304h-16v-80h16zm128 0h16v-80h-16z" />
          <ellipse
            cx="208"
            cy="296"
            rx="48"
            ry="32"
          />
        </g>
        <g fill="#d80027">
          <rect
            height="24"
            rx="8"
            width="16"
            x="128"
            y="192"
          />
          <rect
            height="24"
            rx="8"
            width="16"
            x="272"
            y="192"
          />
          <path d="M208 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z" />
        </g>
        <rect
          fill="#ff9811"
          height="16"
          ry="8"
          width="32"
          x="120"
          y="208"
        />
        <rect
          fill="#ff9811"
          height="16"
          ry="8"
          width="32"
          x="264"
          y="208"
        />
        <rect
          fill="#ff9811"
          height="16"
          rx="8"
          width="32"
          x="120"
          y="304"
        />
        <rect
          fill="#ff9811"
          height="16"
          rx="8"
          width="32"
          x="264"
          y="304"
        />
        <path
          d="M160 272v24c0 8 4 14 9 19l5-6 5 10a21 21 0 0 0 10 0l5-10 5 6c6-5 9-11 9-19v-24h-9l-5 8-5-8h-10l-5 8-5-8z"
          fill="#ff9811"
        />
        <path d="M122 252h172m-172 24h28m116 0h28" />
        <path
          d="M122 248a4 4 0 0 0-4 4 4 4 0 0 0 4 4h172a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 24a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm144 0a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4z"
          fill="#d80027"
        />
        <path
          d="M196 168c-7 0-13 5-15 11l-5-1c-9 0-16 7-16 16s7 16 16 16c7 0 13-4 15-11a16 16 0 0 0 17-4 16 16 0 0 0 17 4 16 16 0 1 0 10-20 16 16 0 0 0-27-5c-3-4-7-6-12-6zm0 8c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm24 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm-44 10 4 1 4 8c0 4-4 7-8 7s-8-3-8-8c0-4 4-8 8-8zm64 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-7l4-8z"
          fill="#eee"
        />
        <path
          d="M220 284v12c0 7 5 12 12 12s12-5 12-12v-12z"
          fill="none"
        />
        <path
          d="M200 160h16v32h-16z"
          fill="#ff9811"
        />
        <path
          d="M208 224h48v48h-48z"
          fill="#eee"
        />
        <path
          d="m248 208-8 8h-64l-8-8c0-13 18-24 40-24s40 11 40 24zm-88 16h48v48h-48z"
          fill="#d80027"
        />
        <rect
          fill="#d80027"
          height="32"
          rx="10"
          ry="10"
          width="20"
          x="222"
          y="232"
        />
        <path
          d="M168 232v8h8v16h-8v8h32v-8h-8v-16h8v-8zm8-16h64v8h-64z"
          fill="#ff9811"
        />
        <g fill="#ffda44">
          <circle
            cx="186"
            cy="202"
            r="6"
          />
          <circle
            cx="208"
            cy="202"
            r="6"
          />
          <circle
            cx="230"
            cy="202"
            r="6"
          />
        </g>
        <path
          d="M169 272v43a24 24 0 0 0 10 4v-47h-10zm20 0v47a24 24 0 0 0 10-4v-43h-10z"
          fill="#d80027"
        />
        <g fill="#338af3">
          <circle
            cx="208"
            cy="272"
            r="16"
          />
          <rect
            height="16"
            ry="8"
            width="32"
            x="264"
            y="320"
          />
          <rect
            height="16"
            ry="8"
            width="32"
            x="120"
            y="320"
          />
        </g>
      </g>
    </svg>
  );
};
export const FrLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          fill="#fff"
          r="256"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z"
          fill="#eee"
        />
        <path
          d="M0 0h167v512H0z"
          fill="#0052b4"
        />
        <path
          d="M345 0h167v512H345z"
          fill="#d80027"
        />
      </g>
    </svg>
  );
};
export const GrLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle
          cx="256"
          cy="256"
          fill="#fff"
          r="256"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M0 0h99l29 32 28-32h356v57l-32 28 32 29v57l-32 28 32 29v57l-32 28 32 28v57l-32 29 32 28v57H0v-57l32-28-32-29v-56l32-29-32-28V171l32-29-32-28Z"
          fill="#0052b4"
        />
        <path
          d="M99 0v114H0v57h99v114H0v57h512v-57H156V171h100v-57H156V0Zm157 57v57h256V57Zm0 114v57h256v-57ZM0 398v57h512v-57z"
          fill="#eee"
        />
      </g>
    </svg>
  );
};

export const DeLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      enableBackground="new 0 0 512 512"
      height="23"
      viewBox="0 0 512 512"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957l-240.077-22.26z"
        fill="#ffda44"
      />
      <path d="m256 0c-110.071 0-203.906 69.472-240.077 166.957l240.077 22.26 240.077-22.261c-36.171-97.484-130.006-166.956-240.077-166.956z" />
      <path
        d="m15.923 166.957c-10.29 27.733-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z"
        fill="#d80027"
      />
    </svg>
  );
};

export const PtLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      fill="none"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipRule="evenodd"
        fillRule="evenodd"
      >
        <path
          d="m480 256c0 123.712-100.288 224-224 224s-224-100.288-224-224 100.288-224 224-224 224 100.288 224 224z"
          fill="#e92b2b"
        />
        <path
          d="m192 470.721c-92.5276-27.537-160-113.251-160-214.724 0-101.472 67.4724-187.186 160-214.7236z"
          fill="#32ab45"
        />
        <path
          d="m192 208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm-64 48c0-35.346 28.654-64 64-64s64 28.654 64 64-28.654 64-64 64-64-28.654-64-64z"
          fill="#ffe70e"
        />
        <path
          d="m226.344 301.657-80-80 11.313-11.313 80 80z"
          fill="#ffe70e"
        />
        <path
          d="m184 312.567v-113.137h16v113.137z"
          fill="#ffe70e"
        />
        <path
          d="m135.988 248.57h113.137v16h-113.137z"
          fill="#ffe70e"
        />
        <path
          d="m157.656 301.657 80-80-11.313-11.313-80 80z"
          fill="#ffe70e"
        />
      </g>
      <path
        d="m216 224h-48c-4.418 0-8 3.582-8 8v32c0 17.673 14.327 32 32 32s32-14.327 32-32v-32c0-4.418-3.582-8-8-8z"
        fill="#e92b2b"
      />
      <path
        d="m208 240h-32v24c0 8.837 7.163 16 16 16s16-7.163 16-16z"
        fill="#fbfbfb"
      />
    </svg>
  );
};

export const AuLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12132_100434)">
        <path
          d="M64 32C64 49.673 49.673 64 32 64C14.327 64 0 49.673 0 32C0 32.0075 32 0.003625 32 0C49.673 0 64 14.327 64 32Z"
          fill="#0052B4"
        />
        <path
          d="M31.9141 31.9997H31.9997C31.9997 31.9707 31.9997 31.9429 31.9997 31.9141C31.9712 31.9427 31.9427 31.9712 31.9141 31.9997Z"
          fill="#F0F0F0"
        />
        <path
          d="M32 16.6956C32 11.065 32 7.37537 32 0H31.9946C14.324 0.002875 0 14.3285 0 31.9999H16.6956V22.5984L26.0971 31.9999H31.9145C31.9431 31.9714 31.9716 31.9429 32.0001 31.9142C32.0001 29.7584 32.0001 27.8347 32.0001 26.0972L22.5985 16.6956H32Z"
          fill="#F0F0F0"
        />
        <path
          d="M16.1893 4.17383C11.1845 7.0237 7.0237 11.1845 4.17383 16.1893V32H12.5217V12.5218V12.5217H32C32 9.88883 32 7.38058 32 4.17383H16.1893Z"
          fill="#D80027"
        />
        <path
          d="M31.9996 28.0649L20.6304 16.6959H16.6953C16.6953 16.6957 16.6953 16.6959 16.6953 16.6959L31.9994 32H31.9996C31.9996 32 31.9996 29.2869 31.9996 28.0649Z"
          fill="#D80027"
        />
        <path
          d="M19.2994 37.5654L21.0557 41.2377L25.0216 40.3211L23.2456 43.9838L26.4348 46.5129L22.4639 47.4079L22.4751 51.4784L19.2994 48.9318L16.1239 51.4784L16.1351 47.4079L12.1641 46.5129L15.3534 43.9838L13.5772 40.3211L17.5433 41.2377L19.2994 37.5654Z"
          fill="#F0F0F0"
        />
        <path
          d="M47.9104 44.522L48.7885 46.3581L50.7715 45.8997L49.8834 47.7311L51.4781 48.9957L49.4926 49.4432L49.4981 51.4785L47.9104 50.2051L46.3226 51.4785L46.3281 49.4432L44.3428 48.9957L45.9374 47.7311L45.0494 45.8997L47.0323 46.3581L47.9104 44.522Z"
          fill="#F0F0F0"
        />
        <path
          d="M39.7416 25.0435L40.6196 26.8797L42.6026 26.4212L41.7146 28.2526L43.3092 29.5172L41.3238 29.9647L41.3292 32L39.7416 30.7266L38.1538 32L38.1592 29.9647L36.1738 29.5172L37.7685 28.2526L36.8805 26.4212L38.8635 26.8797L39.7416 25.0435Z"
          fill="#F0F0F0"
        />
        <path
          d="M47.9104 13.9131L48.7885 15.7493L50.7715 15.291L49.8835 17.1223L51.478 18.3868L49.4926 18.8345L49.4981 20.8697L47.9104 19.5963L46.3226 20.8697L46.3281 18.8345L44.3428 18.3868L45.9373 17.1223L45.0494 15.291L47.0323 15.7493L47.9104 13.9131Z"
          fill="#F0F0F0"
        />
        <path
          d="M55.0463 22.2607L55.9243 24.097L57.9073 23.6385L57.0193 25.4699L58.6139 26.7345L56.6284 27.1821L56.6339 29.2172L55.0463 27.944L53.4585 29.2172L53.4639 27.1821L51.4785 26.7345L53.0731 25.4699L52.1851 23.6385L54.168 24.097L55.0463 22.2607Z"
          fill="#F0F0F0"
        />
        <path
          d="M49.9435 32L50.6341 34.1258H52.8693L51.061 35.4396L51.7518 37.5653L49.9435 36.2515L48.1352 37.5653L48.8258 35.4396L47.0176 34.1258H49.2527L49.9435 32Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_12132_100434">
          <rect
            fill="white"
            height="64"
            width="64"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NzLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12132_100433)">
        <path
          d="M64 32C64 49.673 49.673 64 32 64C14.327 64 0 49.673 0 32C0 32.0076 32 0.0035 32 0C49.673 0 64 14.327 64 32Z"
          fill="#0052B4"
        />
        <path
          d="M31.9141 31.9997H31.9997C31.9997 31.9709 31.9997 31.9429 31.9997 31.9141C31.9712 31.9427 31.9427 31.9712 31.9141 31.9997Z"
          fill="#F0F0F0"
        />
        <path
          d="M32 16.6956C32 11.065 32 7.37563 32 0H31.9946C14.324 0.003 0 14.3286 0 32H16.6956V22.5986L26.0971 32H31.9145C31.943 31.9715 31.9716 31.943 32.0001 31.9144C32.0001 29.7585 32.0001 27.8349 32.0001 26.0972L22.5985 16.6956H32Z"
          fill="#F0F0F0"
        />
        <path
          d="M16.1893 4.17383C11.1845 7.0237 7.0237 11.1845 4.17383 16.1893V32H12.5217V12.522V12.5217H32C32 9.88883 32 7.38058 32 4.17383H16.1893Z"
          fill="#D80027"
        />
        <path
          d="M31.9996 28.0649L20.6304 16.6958C20.6304 16.6958 16.6953 16.6961 16.6953 16.6958V16.6961L31.9994 32.0001H31.9996C31.9996 32.0001 31.9996 29.2867 31.9996 28.0649Z"
          fill="#D80027"
        />
        <path
          d="M55.4162 23.7451L56.1069 25.8709H58.342L56.5337 27.1846L57.2245 29.3102L55.4162 27.9966L53.6079 29.3102L54.2986 27.1846L52.4902 25.8709H54.7254L55.4162 23.7451Z"
          fill="#D80027"
        />
        <path
          d="M47.4259 39.0488L48.462 42.2376H51.8146L49.1021 44.2081L50.1384 47.3967L47.4259 45.4261L44.7134 47.3967L45.7496 44.2081L43.0371 42.2376H46.3897L47.4259 39.0488Z"
          fill="#D80027"
        />
        <path
          d="M47.7274 14.0059L48.5909 16.6632H51.3848L49.1243 18.3051L49.9878 20.9624L47.7274 19.32L45.4672 20.9624L46.3306 18.3051L44.0703 16.6632H46.8641L47.7274 14.0059Z"
          fill="#D80027"
        />
        <path
          d="M40.0401 23.6519L41.0761 26.8406H44.4289L41.7164 28.8111L42.7526 31.9997L40.0401 30.0291L37.3276 31.9997L38.3639 28.8111L35.6514 26.8406H39.004L40.0401 23.6519Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_12132_100433">
          <rect
            fill="white"
            height="64"
            width="64"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CaLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12132_100432)">
        <path
          d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
          fill="#F0F0F0"
        />
        <path
          d="M64.0001 32C64.0001 19.3133 56.617 8.35129 45.9131 3.17529V60.8247C56.617 55.6488 64.0001 44.6868 64.0001 32Z"
          fill="#D80027"
        />
        <path
          d="M0 32C0 44.6868 7.38313 55.6488 18.087 60.8248V3.17529C7.38313 8.35129 0 19.3133 0 32Z"
          fill="#D80027"
        />
        <path
          d="M37.5648 36.1737L43.1299 33.3911L40.3474 31.9999V29.2172L34.7821 31.9999L37.5648 26.4346H34.7821L31.9995 22.2607L29.2169 26.4346H26.4343L29.2169 31.9999L23.6516 29.2172V31.9999L20.8691 33.3911L26.4343 36.1737L25.043 38.9564H30.6083V43.1302H33.3908V38.9564H38.956L37.5648 36.1737Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_12132_100432">
          <rect
            fill="white"
            height="64"
            width="64"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PlLang = ({ className }: LangIconsProps) => {
  return (
    <svg
      className={className}
      height="512"
      id="flag-icon-css-pl"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd">
        <path
          d="M512 512H0V0h512z"
          fill="#fff"
        />
        <path
          d="M512 512H0V256h512z"
          fill="#dc143c"
        />
      </g>
    </svg>
  );
};
