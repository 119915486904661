import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../components/Languages/const';

export const useGetCurrency = () => {
  const { i18n } = useTranslation();

  const currentLang = useMemo(() => {
    return i18n.language || 'en';
  }, [i18n.language]);

  const currentLanguageObject = useMemo(() => {
    if (currentLang in languageOptions) {
      return languageOptions[currentLang as keyof typeof languageOptions];
    }
    return undefined;
  }, [currentLang]);

  return currentLanguageObject;
};

export const useFormatter = () => {
  const currentLanguageObject = useGetCurrency();

  const numberFormatter = new Intl.NumberFormat(currentLanguageObject?.code, {
    style: 'currency',
    currency: currentLanguageObject?.currency
  });

  return { numberFormatter };
};
