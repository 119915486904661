import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAllUserLevels } from 'src/api/content';
import { FullInfoResponse, useGetFullInfo } from 'src/api/profile';
import flashIcon from 'src/assets/images/flash.webp';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { getLevelInNumber, getLevelsString, openChat } from 'src/utils';
import i18n from 'src/utils/i18n';

import { CONTENT_URL } from '../../api';
import { AllUserLevelsResponse } from '../../api/content/content.types';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Accordion } from '../Accordion';
import { Button } from '../Buttons/Button';
import { Divider } from '../Divider/Divider';
import { PercentageScale } from '../PercentageScale/PercentageScale';
import { VipCardInfo } from '../svg/VipCardInfo';

const CURRENT_LEVEL_STYLE = 'border-x-border_17 border-t-border_17 border-[3px]';

export const Levels = ({ allLevels, showOne = false }: { allLevels: AllUserLevelsResponse[]; showOne?: boolean }) => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="mx-[0px] grid w-full max-w-[340px] gap-11 sm:grid-cols-3 sm:px-[70px] md:max-w-[1100px] r_lg:mx-[30px] r_lg:grid-cols-1 r_lg:gap-y-10 r_sm:mx-[5px] r_sm:grid-cols-1 r_sm:px-0 r_xl:mx-[0px] r_xl:px-2.5">
        {allLevels.map((level, index) => {
          if (showOne && index === 1) return null;
          return (
            <LevelCard
              key={level.code}
              level={level}
            />
          );
        })}
      </div>
    </div>
  );
};

export function LevelCard({ blockAccordions, level }: { blockAccordions?: true; level: AllUserLevelsResponse }) {
  const { fullInfo } = useGetFullInfo({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { allLevels } = useGetAllUserLevels({ loyalty_page_code: 'loyalty' });
  const { openModal } = useModal();

  const [userLevel, userPoints] = useMemo(() => {
    if (!fullInfo) return [undefined, undefined];
    return [fullInfo.level, fullInfo.points];
  }, [fullInfo]);

  const levelNumber = level.levelInfo?.level;
  const levelPointsTo = useMemo(
    () => getLevelPointsTo({ allLevels, levelNumber }),
    [allLevels, levelNumber, i18n.language],
  );
  const isLevelCompleted = useMemo(() => {
    if (userLevel === undefined || levelNumber === undefined) return false;
    return userLevel > levelNumber;
  }, [levelNumber, userLevel]);

  const isCurrentLevel = useMemo(() => {
    if (userLevel === undefined || levelNumber === undefined) return false;
    return userLevel === levelNumber;
  }, [levelNumber, userLevel]);

  const getRewardAvailable = useMemo(() => {
    if (userLevel === 9) return true;
    if (userPoints && levelPointsTo) {
      if (userLevel === levelNumber && userPoints >= levelPointsTo) return true;
    }
    return false;
  }, [levelNumber, levelPointsTo, userLevel, userPoints]);

  const handleInfoButtonClick = (level: AllUserLevelsResponse) => {
    openModal?.(MODAL_NAME.VIP_RULES);

    sendGoogleAnalyticsEvent('loyalty_rule_popup_opened', {
      program_id: level.title,
    });
  };

  return (
    <div
      className=" max-w-[340px]"
      key={level.code}
    >
      <div
        className={classNames(
          'relative flex h-[220px] flex-row rounded-t-[15px]',
          isLevelCompleted && 'grayscale-[80%]',
          isCurrentLevel && CURRENT_LEVEL_STYLE,
        )}
        style={{
          background: `no-repeat url(${CONTENT_URL}${level.imageMobile})`,
          backgroundSize: 'cover',
        }}
      >
        <img
          alt={level.title}
          className="absolute bottom-0 h-[200px] w-[150px]"
          src={`${CONTENT_URL}${level.image}`}
        />
        <div className="absolute right-9 top-6 flex flex-col justify-center pt-2.5">
          <div className="mb-5 flex items-center justify-end gap-4">
            <div
              className="cursor-pointer"
              onClick={() => handleInfoButtonClick(level)}
            >
              <VipCardInfo />
            </div>
          </div>
          <p className="text-[20px] font-bold text-text_8">{level.title}</p>
          {!isLevelCompleted && (
            <>
              <p className="pt-2.5 text-[16px] text-text_2">{t('general.expPoints')}</p>
              <p className="text-[16px] text-text_8">
                {userPoints ?? '-'} / {levelPointsTo || '\u221e'}
              </p>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'relative bottom-[15px] flex h-auto flex-col justify-between rounded-b-[15px] bg-background_1 px-[15px] pt-[20px] r_sm:px-2.5',
          fullInfo?.level === levelNumber && 'border-[3px] border-t-0 border-x-border_17 border-b-border_17',
        )}
      >
        <div className="flex items-center">
          <img
            alt="flash"
            className="w-[25px]"
            src={flashIcon}
          />
          <div className="flex flex-[1] flex-col justify-between pl-[5%]">
            <PercentageScale
              additionalClassName="bg-background_7 z-[2]"
              value={getLevelInNumber(levelPointsTo, fullInfo?.points)}
            />
            <div className="flex items-center justify-between px-[3%]">
              <p className="text-[16px] font-bold text-text_8">{level.title}</p>
              {!isLevelCompleted && (
                <p className="z-[2] text-[12px] font-bold text-text_6">
                  {getLevelProgress({ allLevels, fullInfo, levelNumber, levelPointsTo })}
                </p>
              )}
            </div>
          </div>
        </div>
        <Divider
          color="border-border_6"
          height="border-1"
        />
        <LevelContent
          blockAccordions={blockAccordions}
          level={level}
        />
        <div className="my-[10px] mt-[20px] flex flex-row items-center justify-between md:my-[20px] r_sm:mb-2.5">
          <Button
            disabled={!getRewardAvailable}
            mode="secondary"
            onClick={blockAccordions && openChat}
            title={`${t('general.getReward')}`}
          >
            {t('general.getReward')}
          </Button>
          <Button
            additionalClassName="ml-2.5"
            mode="primary"
            onClick={() => !blockAccordions && navigate('/games/top-games')}
            title={`${t('general.play')}`}
          >
            {t('general.play')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export function getLevelPointsTo({
  allLevels,
  levelNumber,
}: {
  allLevels: AllUserLevelsResponse[];
  levelNumber: number | undefined;
}): number | undefined {
  if (allLevels.length === 0 || levelNumber === undefined) return undefined;
  const nextLevelInfo = allLevels[levelNumber]?.levelInfo;
  if (!nextLevelInfo) return undefined;
  return nextLevelInfo.fromPoints - 1;
}

export function getLevelProgress({
  allLevels,
  fullInfo,
  levelNumber,
  levelPointsTo,
}: {
  allLevels: AllUserLevelsResponse[];
  fullInfo?: FullInfoResponse;
  levelNumber?: number;
  levelPointsTo?: number;
}) {
  if (!fullInfo || (!levelNumber && levelNumber !== 0) || (!levelPointsTo && levelPointsTo !== 0)) return '';
  return allLevels.length > 0 && fullInfo?.points && levelNumber != allLevels.length
    ? getLevelsString(levelPointsTo - fullInfo?.points, levelNumber + 1)
    : '';
}

export function LevelContent({ blockAccordions, level }: { blockAccordions?: true; level: AllUserLevelsResponse }) {
  if (!level.childs) return null;

  const handleAccordionClick = (open: boolean) => {
    if (open) {
      sendGoogleAnalyticsEvent('loyalty_rule_expanded', {
        loyalty_level_id: level.title,
      });
    }
  };

  return (
    <div>
      {level.childs.map((levelContentBlock, index) => (
        <Accordion
          blockAccordions={blockAccordions}
          content={levelContentBlock.content}
          key={index}
          onClick={handleAccordionClick}
          title={levelContentBlock.title}
        />
      ))}
    </div>
  );
}
