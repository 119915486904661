import { NavLink } from 'react-router-dom';
import { useDrawer } from 'src/hooks/useDrawer';

import smallLogo from '../../assets/images/smallLogo.png';

export const Logo = () => {
  const { closeBottomDrawer } = useDrawer();
  return (
    <NavLink
      aria-label={'main page'}
      onClick={closeBottomDrawer}
      style={{ cursor: 'pointer' }}
      to="/"
    >
      <img
        alt="logo"
        className="max-h-[35px] w-auto h-full ml-[15px] r_sm:h-[35px] object-contain r_sm:ml-2.5"
        src={smallLogo}
      />
    </NavLink>
  );
};
