export const SslLanding = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="35"
      viewBox="0 0 101 42"
      fill="none">
      <g clipPath="url(#clip0_27_8557)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.769 19.77C11.769 17.9289 13.3048 16.4362 15.1994 16.4362H15.4632C15.4632 14.7473 15.6734 12.5619 16.7641 10.7439C17.9646 8.74278 20.0731 7.44824 23.1565 7.44824C26.2283 7.44824 28.3714 8.72929 29.6334 10.6909C30.7993 12.5029 31.0847 14.6902 31.0847 16.4362H31.3484C33.243 16.4362 34.779 17.9289 34.779 19.77V29.8734C34.779 31.7146 33.243 33.2071 31.3484 33.2071H15.1995C13.3048 33.2071 11.769 31.7146 11.769 29.8734V19.77ZM26.9682 16.4362H19.5797C19.5797 14.949 19.7915 13.6405 20.3204 12.7588C20.7397 12.06 21.4487 11.4488 23.1565 11.4488C24.8761 11.4488 25.668 12.0735 26.1431 12.8118C26.7143 13.6996 26.9682 15.0061 26.9682 16.4362ZM28.8187 21.7864L27.809 20.8267C27.6705 20.6951 27.5026 20.6291 27.3044 20.6291C27.1065 20.6291 26.9381 20.6951 26.7996 20.8267L21.9304 25.4645L19.7484 23.3818C19.6096 23.2502 19.4414 23.1844 19.2435 23.1844C19.0455 23.1844 18.8773 23.2502 18.7386 23.3818L17.7292 24.3417C17.5909 24.4738 17.5215 24.6336 17.5215 24.8219C17.5215 25.0101 17.5909 25.1704 17.7292 25.302L21.4258 28.817C21.5643 28.9491 21.7324 29.0146 21.9304 29.0146C22.1283 29.0146 22.2964 28.9491 22.4352 28.817L28.8187 22.7468C28.9571 22.6149 29.0265 22.455 29.0265 22.2668C29.0265 22.0785 28.9571 21.9185 28.8187 21.7864Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.28262 6.60307C1.28262 2.9207 4.35439 -0.0644531 8.14359 -0.0644531H39.018C42.8071 -0.0644531 45.879 2.9207 45.879 6.60307V34.94C45.879 38.6224 42.8071 41.6075 39.018 41.6075H8.14359C4.35439 41.6075 1.28262 38.6224 1.28262 34.94V6.60307ZM8.14359 4.26943H39.018C40.3441 4.26943 41.4193 5.31423 41.4193 6.60307V34.94C41.4193 36.2288 40.3441 37.2737 39.018 37.2737H8.14359C6.81736 37.2737 5.74226 36.2288 5.74226 34.94V6.60307C5.74226 5.31423 6.81736 4.26943 8.14359 4.26943Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M57.933 22.8555C59.0054 23.2939 60.2907 23.5131 61.7889 23.5131C63.1768 23.5131 64.4067 23.3096 65.4792 22.9025C66.5673 22.4797 67.4111 21.8847 68.0104 21.1175C68.6254 20.3347 68.933 19.4187 68.933 18.3695C68.933 17.4144 68.7278 16.6238 68.3178 15.9974C67.9078 15.3555 67.2613 14.8387 66.3781 14.4473C65.5107 14.0402 64.3438 13.7036 62.8771 13.4374C61.9781 13.2495 61.2842 13.0694 60.7954 12.8972C60.3223 12.7093 59.9753 12.5058 59.7545 12.2865C59.5496 12.0673 59.447 11.7933 59.447 11.4645C59.447 10.9791 59.6363 10.6112 60.0147 10.3606C60.3933 10.1101 60.9372 9.98485 61.6469 9.98485C62.2621 9.98485 62.7745 10.1101 63.1846 10.3606C63.6103 10.6112 63.8706 10.9165 63.9652 11.2766C64.0599 11.4175 64.1623 11.5193 64.2728 11.5819C64.3989 11.6446 64.5566 11.6759 64.7459 11.6759H67.8684C68.0104 11.6759 68.1285 11.6289 68.2233 11.535C68.3178 11.441 68.3651 11.3236 68.3651 11.1827C68.3337 10.4937 68.0498 9.79696 67.5135 9.09237C66.9931 8.37211 66.2283 7.77712 65.219 7.30738C64.2096 6.83765 63.0191 6.60278 61.6469 6.60278C60.3223 6.60278 59.1631 6.81416 58.1696 7.23692C57.176 7.65968 56.4112 8.24684 55.8751 8.99841C55.3388 9.74998 55.0708 10.6033 55.0708 11.5584C55.0708 12.9677 55.5359 14.0559 56.4663 14.8231C57.4126 15.5747 58.832 16.1462 60.7244 16.5375C61.7495 16.7568 62.5222 16.9525 63.0426 17.1248C63.563 17.2969 63.9416 17.4928 64.1782 17.7119C64.4305 17.9312 64.5566 18.2129 64.5566 18.5575C64.5566 19.0428 64.32 19.4264 63.8469 19.7083C63.3738 19.9901 62.6878 20.1311 61.7889 20.1311C61.032 20.1311 60.4248 19.9979 59.9674 19.7317C59.5101 19.4656 59.1788 19.1289 58.9738 18.7218C58.8477 18.5809 58.7216 18.4791 58.5954 18.4165C58.485 18.3383 58.3351 18.2992 58.146 18.2992H55.1653C55.0233 18.2992 54.8972 18.354 54.7868 18.4634C54.6922 18.5575 54.6449 18.667 54.6449 18.7923C54.6765 19.6378 54.9604 20.4207 55.4965 21.141C56.0485 21.8456 56.8606 22.4171 57.933 22.8555Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M73.6197 22.8553C74.6919 23.2936 75.9774 23.5129 77.4755 23.5129C78.8633 23.5129 80.0934 23.3093 81.1658 22.9022C82.254 22.4794 83.0976 21.8845 83.6969 21.1172C84.3119 20.3344 84.6195 19.4184 84.6195 18.3693C84.6195 17.4142 84.4145 16.6235 84.0045 15.9972C83.5943 15.3552 82.9478 14.8385 82.0647 14.4471C81.1972 14.0399 80.0303 13.7033 78.5636 13.4371C77.6648 13.2492 76.9708 13.0692 76.4819 12.8969C76.0088 12.709 75.6619 12.5055 75.4411 12.2863C75.2361 12.0671 75.1335 11.7931 75.1335 11.4643C75.1335 10.9789 75.3228 10.6109 75.7014 10.3604C76.0798 10.1099 76.6239 9.98461 77.3336 9.98461C77.9486 9.98461 78.4612 10.1099 78.8712 10.3604C79.297 10.6109 79.5571 10.9162 79.6519 11.2764C79.7464 11.4173 79.849 11.5191 79.9593 11.5817C80.0854 11.6443 80.2431 11.6756 80.4324 11.6756H83.5549C83.6969 11.6756 83.8152 11.6287 83.9098 11.5347C84.0045 11.4408 84.0518 11.3233 84.0518 11.1824C84.0202 10.4935 83.7364 9.79671 83.2001 9.09212C82.6797 8.37187 81.9148 7.77687 80.9055 7.30713C79.8963 6.83741 78.7056 6.60254 77.3336 6.60254C76.0088 6.60254 74.8497 6.81391 73.8563 7.23668C72.8627 7.65944 72.0977 8.2466 71.5616 8.99817C71.0254 9.74974 70.7573 10.6031 70.7573 11.5582C70.7573 12.9674 71.2226 14.0556 72.153 14.8228C73.0993 15.5744 74.5185 16.1459 76.4109 16.5373C77.436 16.7566 78.2087 16.9523 78.7293 17.1246C79.2497 17.2967 79.6281 17.4926 79.8647 17.7117C80.117 17.9309 80.2431 18.2127 80.2431 18.5573C80.2431 19.0426 80.0066 19.4261 79.5336 19.7081C79.0604 19.9898 78.3745 20.1309 77.4755 20.1309C76.7185 20.1309 76.1114 19.9977 75.6541 19.7315C75.1967 19.4654 74.8655 19.1287 74.6605 18.7216C74.5342 18.5807 74.4081 18.4789 74.2819 18.4162C74.1715 18.338 74.0218 18.2989 73.8325 18.2989H70.8519C70.71 18.2989 70.5839 18.3537 70.4735 18.4632C70.3787 18.5573 70.3315 18.6667 70.3315 18.7921C70.363 19.6375 70.6469 20.4205 71.1832 21.1408C71.735 21.8454 72.5473 22.4169 73.6197 22.8553Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M87.5791 23.2788C87.4214 23.2788 87.2794 23.224 87.1533 23.1144C87.0428 22.989 86.9877 22.8481 86.9877 22.6915V7.42529C86.9877 7.25306 87.0428 7.11214 87.1533 7.00253C87.2794 6.89293 87.4214 6.83813 87.5791 6.83813H90.7016C90.8751 6.83813 91.0171 6.89293 91.1275 7.00253C91.2378 7.11214 91.2931 7.25306 91.2931 7.42529V19.7322H98.4606C98.6342 19.7322 98.7761 19.7949 98.8865 19.9202C98.9969 20.0298 99.052 20.1707 99.052 20.3428V22.6915C99.052 22.8481 98.9969 22.989 98.8865 23.1144C98.7761 23.224 98.6342 23.2788 98.4606 23.2788H87.5791Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M57.649 36.6069C56.9946 36.6069 56.4269 36.5051 55.9459 36.3015C55.4728 36.098 55.11 35.8318 54.8576 35.503C54.6053 35.1663 54.4713 34.8061 54.4555 34.4226C54.4555 34.3599 54.4792 34.3051 54.5265 34.2582C54.5818 34.2033 54.6448 34.1759 54.7158 34.1759H55.3189C55.4687 34.1759 55.5713 34.2543 55.6265 34.4109C55.6975 34.7396 55.8986 35.0216 56.2297 35.2564C56.5687 35.4912 57.0419 35.6087 57.649 35.6087C58.3036 35.6087 58.7964 35.4952 59.1275 35.2681C59.4587 35.0333 59.6242 34.7084 59.6242 34.2934C59.6242 34.0272 59.5415 33.8119 59.376 33.6475C59.2183 33.4831 58.9817 33.3383 58.6663 33.213C58.3508 33.0878 57.8777 32.9312 57.2469 32.7434C56.6319 32.5711 56.143 32.3871 55.7803 32.1914C55.4175 31.9957 55.1455 31.753 54.9642 31.4633C54.7828 31.1736 54.692 30.8096 54.692 30.3711C54.692 29.9485 54.8065 29.5688 55.035 29.232C55.2716 28.8955 55.6067 28.6331 56.0405 28.4452C56.4742 28.2495 56.9906 28.1516 57.5898 28.1516C58.205 28.1516 58.7333 28.2612 59.1748 28.4805C59.6164 28.6919 59.9515 28.962 60.1802 29.2907C60.4088 29.6196 60.5311 29.9485 60.5468 30.2772C60.5468 30.3399 60.5232 30.3947 60.476 30.4416C60.4365 30.4886 60.3774 30.512 60.2985 30.512H59.6717C59.506 30.512 59.4035 30.4376 59.3641 30.2889C59.3168 29.9602 59.1314 29.6901 58.8081 29.4787C58.4848 29.2594 58.0789 29.1498 57.5898 29.1498C57.0537 29.1498 56.6319 29.2516 56.3243 29.4551C56.0167 29.6587 55.863 29.9602 55.863 30.3594C55.863 30.6256 55.934 30.8447 56.0759 31.017C56.2257 31.1893 56.4504 31.3419 56.7502 31.4751C57.0497 31.6003 57.4874 31.7452 58.063 31.9095C58.7333 32.0896 59.2615 32.2775 59.648 32.4732C60.0344 32.6612 60.3221 32.8998 60.5114 33.1896C60.7007 33.4714 60.7952 33.8315 60.7952 34.2699C60.7952 34.998 60.5114 35.5695 59.9437 35.9845C59.376 36.3995 58.611 36.6069 57.649 36.6069Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M62.6181 36.4893C62.5392 36.4893 62.4722 36.4659 62.4169 36.419C62.3697 36.3641 62.3461 36.2976 62.3461 36.2193V28.551C62.3461 28.4647 62.3697 28.3982 62.4169 28.3513C62.4722 28.2965 62.5392 28.269 62.6181 28.269H67.503C67.5819 28.269 67.6448 28.2965 67.6921 28.3513C67.7474 28.3982 67.775 28.4647 67.775 28.551V28.9972C67.775 29.0754 67.7513 29.1419 67.704 29.1967C67.6567 29.2437 67.5897 29.2672 67.503 29.2672H63.4815V31.8391H67.2427C67.3216 31.8391 67.3847 31.8665 67.432 31.9213C67.4871 31.9682 67.5147 32.0347 67.5147 32.1208V32.5553C67.5147 32.6337 67.4871 32.7002 67.432 32.755C67.3847 32.802 67.3216 32.8254 67.2427 32.8254H63.4815V35.4911H67.5976C67.6843 35.4911 67.7513 35.5147 67.7987 35.5616C67.846 35.6086 67.8696 35.6751 67.8696 35.7613V36.2193C67.8696 36.2976 67.842 36.3641 67.7869 36.419C67.7396 36.4659 67.6764 36.4893 67.5976 36.4893H62.6181Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M70.0057 35.7614C70.5575 36.3249 71.362 36.6069 72.4185 36.6069C73.1204 36.6069 73.7156 36.4855 74.2045 36.2429C74.6935 35.9924 75.064 35.6673 75.3163 35.2681C75.5686 34.8688 75.7066 34.4383 75.7303 33.9764C75.7382 33.9059 75.7146 33.8511 75.6593 33.8119C75.612 33.765 75.5529 33.7414 75.4819 33.7414H74.8313C74.7526 33.7414 74.6895 33.761 74.6422 33.8003C74.5949 33.8394 74.5593 33.9099 74.5358 34.0117C74.4095 34.5987 74.169 35.0137 73.8142 35.2564C73.4672 35.4912 73.0021 35.6087 72.4185 35.6087C71.0622 35.6087 70.3605 34.861 70.3131 33.3657C70.3053 33.1386 70.3014 32.8059 70.3014 32.3675C70.3014 31.9292 70.3053 31.6041 70.3131 31.3929C70.3605 29.8975 71.0622 29.1498 72.4185 29.1498C73.0021 29.1498 73.4672 29.2711 73.8142 29.514C74.1612 29.7488 74.4016 30.1597 74.5358 30.747C74.5752 30.927 74.6738 31.017 74.8313 31.017H75.4819C75.545 31.017 75.6002 30.9975 75.6474 30.9584C75.7027 30.9114 75.7303 30.8566 75.7303 30.7939V30.7704C75.7066 30.3163 75.5686 29.8897 75.3163 29.4904C75.064 29.0912 74.6935 28.7701 74.2045 28.5275C73.7156 28.2769 73.1204 28.1516 72.4185 28.1516C71.3698 28.1516 70.5694 28.4374 70.0174 29.0089C69.4656 29.5726 69.1699 30.3477 69.1304 31.3341C69.1226 31.5533 69.1186 31.9056 69.1186 32.3909C69.1186 32.8686 69.1226 33.213 69.1304 33.4244C69.1699 34.4188 69.4616 35.1977 70.0057 35.7614Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M80.4381 36.6073C79.4209 36.6073 78.6324 36.3372 78.0725 35.7969C77.5205 35.2489 77.2445 34.423 77.2445 33.3192V28.5515C77.2445 28.4652 77.2683 28.3987 77.3155 28.3518C77.3707 28.2969 77.4377 28.2695 77.5165 28.2695H78.1198C78.2065 28.2695 78.2735 28.2969 78.321 28.3518C78.3761 28.3987 78.4037 28.4652 78.4037 28.5515V33.3426C78.4037 34.0942 78.5811 34.6578 78.9359 35.0337C79.2908 35.4095 79.7915 35.5974 80.4381 35.5974C81.0846 35.5974 81.5853 35.4095 81.9402 35.0337C82.295 34.6578 82.4724 34.0942 82.4724 33.3426V28.5515C82.4724 28.4652 82.4962 28.3987 82.5434 28.3518C82.5986 28.2969 82.6656 28.2695 82.7444 28.2695H83.3596C83.4384 28.2695 83.5014 28.2969 83.5487 28.3518C83.604 28.4066 83.6316 28.4731 83.6316 28.5515V33.3192C83.6316 34.423 83.3516 35.2489 82.7917 35.7969C82.2399 36.3372 81.4553 36.6073 80.4381 36.6073Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.6796 36.4192C85.7349 36.4662 85.8019 36.4896 85.8808 36.4896H86.4841C86.5628 36.4896 86.6299 36.4662 86.6851 36.4192C86.7402 36.3644 86.7679 36.2979 86.7679 36.2195V33.1898H88.8377L90.5527 36.1725C90.608 36.2743 90.6671 36.3525 90.7301 36.4073C90.8011 36.4622 90.8957 36.4896 91.014 36.4896H91.5818C91.6448 36.4896 91.7001 36.4662 91.7474 36.4192C91.8025 36.3722 91.8301 36.3174 91.8301 36.2548C91.8301 36.2157 91.8184 36.1725 91.7947 36.1256L89.9731 33.0018C90.5094 32.853 90.9234 32.587 91.2151 32.2033C91.5147 31.8197 91.6645 31.3304 91.6645 30.7355C91.6645 29.9368 91.4082 29.3262 90.8957 28.9035C90.3833 28.4807 89.6736 28.2693 88.7667 28.2693H85.8808C85.8019 28.2693 85.7349 28.2967 85.6796 28.3515C85.6324 28.3985 85.6088 28.465 85.6088 28.5512V36.2195C85.6088 36.2979 85.6324 36.3644 85.6796 36.4192ZM90.4819 30.7236C90.4819 31.7023 89.8943 32.1916 88.7194 32.1916H86.7679V29.2558H88.7194C89.8943 29.2558 90.4819 29.7451 90.4819 30.7236Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M93.6309 36.4893C93.5521 36.4893 93.4849 36.4659 93.4298 36.419C93.3825 36.3641 93.3588 36.2976 93.3588 36.2193V28.551C93.3588 28.4647 93.3825 28.3982 93.4298 28.3513C93.4849 28.2965 93.5521 28.269 93.6309 28.269H98.5157C98.5946 28.269 98.6577 28.2965 98.705 28.3513C98.7603 28.3982 98.7878 28.4647 98.7878 28.551V28.9972C98.7878 29.0754 98.7641 29.1419 98.7168 29.1967C98.6696 29.2437 98.6026 29.2672 98.5157 29.2672H94.4944V31.8391H98.2556C98.3344 31.8391 98.3974 31.8665 98.4449 31.9213C98.5 31.9682 98.5276 32.0347 98.5276 32.1208V32.5553C98.5276 32.6337 98.5 32.7002 98.4449 32.755C98.3974 32.802 98.3344 32.8254 98.2556 32.8254H94.4944V35.4911H98.6104C98.6971 35.4911 98.7641 35.5147 98.8114 35.5616C98.8587 35.6086 98.8824 35.6751 98.8824 35.7613V36.2193C98.8824 36.2976 98.8548 36.3641 98.7995 36.419C98.7523 36.4659 98.6893 36.4893 98.6104 36.4893H93.6309Z"
          fill="white"
          fillOpacity="0.4"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_8557">
          <rect width="100" height="42" fill="white" transform="translate(0.863678)" />
        </clipPath>
      </defs>
    </svg>
  );
};
