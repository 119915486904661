import { Dispatch, SetStateAction } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Carousel.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { EffectCards, EffectCoverflow, Autoplay, Navigation, Pagination } from 'swiper';
import classNames from 'classnames';

type CarouselProps = {
  slides: JSX.Element[];
  autoplay?: boolean | {
    delay: number,
    disableOnInteraction: boolean,
  };
  arrows?: boolean;
  onSlideChange?: (arg: any) => void;
  effect?: 'cards' | 'coverflow';
  slideClassname?: string;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  inactiveSlideClassname?: string;
  initialSlide?: number;
  pagination?: {
    clickable: boolean;
  };
  swiperClassName?: string;
  spaceBetween?: number;
  slidesPerView?: number;
};

export const Carousel = ({
  slides,
  arrows = true,
  autoplay = false,
  effect,
  inactiveSlideClassname,
  setActiveIndex,
  initialSlide,
  slideClassname = 'w-[40%] r_lg:w-[80%] r_xl:w-[50%] r_xxl:w-[30%]',
  pagination,
  swiperClassName,
  spaceBetween,
  slidesPerView
}: CarouselProps) => {
  return (
    <Swiper
      initialSlide={initialSlide}
      navigation={arrows}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
        scale: 0.5
      }}
      cardsEffect={{
        slideShadows: false,
        perSlideOffset: 40
      }}
      className={classNames('w-full', swiperClassName ? swiperClassName : '')}
      autoplay={autoplay}
      slidesPerView={slidesPerView ? slidesPerView : 'auto'}
      modules={[Navigation, EffectCards, EffectCoverflow, Autoplay, Pagination]}
      centeredSlides={true}
      rewind={true}
      effect={effect}
      onSlideChange={(slide) => {
        setActiveIndex?.(slide.activeIndex);
      }}
      pagination={pagination}
      spaceBetween={spaceBetween}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className={slideClassname}>
          {({ isActive }) => {
            if (effect !== 'coverflow') {
              return (
                <div className={classNames(isActive ? '' : inactiveSlideClassname)}>{slide}</div>
              );
            }
            return (
              <div
                className={classNames(
                  isActive ? '' : 'opacity-40',
                  isActive ? '' : inactiveSlideClassname
                )}>
                {slide}
              </div>
            );
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
