import { useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { openChat } from 'src/utils';

export const DepositPaymentInfo = () => {
  const { modalProps, closeModal } = useModal();
  const { t } = useTranslation();

  return (
    <div className="p-[20px] max-w-[600px] flex flex-col gap-7">
      <div>
        <p className="text-2xl text-center text-text_8 font-bold">{modalProps?.text?.title}</p>
      </div>
      <div
        className="static-page text-text_2"
        dangerouslySetInnerHTML={{ __html: modalProps?.text?.content || '' }}></div>
      <div className="w-full flex justify-center items-center">
        <Button
          title={t('modalCancelBonus.ask') as string}
          label={t('modalCancelBonus.ask') as string}
          additionalClassName="w-full max-w-[300px] mt-[15px]"
          mode="primary"
          onClick={() => {
            closeModal?.();
            openChat();
          }}
        />
      </div>
    </div>
  );
};
