export const Safari = () => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9828_95760)">
        <path
          d="M14.0008 28.0406C21.6059 28.0406 27.7711 21.8754 27.7711 14.2703C27.7711 6.66518 21.6059 0.5 14.0008 0.5C6.39565 0.5 0.230469 6.66518 0.230469 14.2703C0.230469 21.8754 6.39565 28.0406 14.0008 28.0406Z"
          fill="url(#paint0_linear_9828_95760)"
        />
        <g opacity="0.08">
          <path
            d="M14.0009 0.5C6.3955 0.5 0.230469 6.66503 0.230469 14.2705C0.230469 21.8759 6.3955 28.041 14.0009 28.041C21.6064 28.041 27.7714 21.876 27.7714 14.2705C27.7714 6.66509 21.6064 0.5 14.0009 0.5ZM14.0009 27.123C6.91421 27.123 1.14851 21.3573 1.14851 14.2705C1.14851 7.1838 6.91421 1.41804 14.0009 1.41804C21.0877 1.41804 26.8534 7.18374 26.8534 14.2705C26.8534 21.3572 21.0877 27.123 14.0009 27.123Z"
            fill="url(#paint1_linear_9828_95760)"
          />
        </g>
        <g opacity="0.16">
          <path
            d="M14.0009 0.5C6.3955 0.5 0.230469 6.66503 0.230469 14.2705C0.230469 21.8759 6.3955 28.041 14.0009 28.041C21.6064 28.041 27.7714 21.876 27.7714 14.2705C27.7714 6.66509 21.6064 0.5 14.0009 0.5ZM14.0009 27.582C6.64936 27.582 0.689461 21.6221 0.689461 14.2705C0.689461 6.91884 6.64936 0.958992 14.0009 0.958992C21.3525 0.958992 27.3124 6.91884 27.3124 14.2705C27.3124 21.6221 21.3525 27.582 14.0009 27.582Z"
            fill="black"
          />
        </g>
        <path
          d="M13.9988 26.6636C20.8434 26.6636 26.392 21.1149 26.392 14.2703C26.392 7.42567 20.8434 1.87701 13.9988 1.87701C7.15413 1.87701 1.60547 7.42567 1.60547 14.2703C1.60547 21.1149 7.15413 26.6636 13.9988 26.6636Z"
          fill="url(#paint2_linear_9828_95760)"
        />
        <g opacity="0.16">
          <path
            d="M13.9989 1.87701C7.15406 1.87701 1.60547 7.42561 1.60547 14.2705C1.60547 21.1153 7.15406 26.6639 13.9989 26.6639C20.8438 26.6639 26.3924 21.1153 26.3924 14.2705C26.3924 7.42561 20.8438 1.87701 13.9989 1.87701ZM13.9989 26.2049C7.40792 26.2049 2.06452 20.8615 2.06452 14.2705C2.06452 7.67952 7.40792 2.33606 13.9989 2.33606C20.5899 2.33606 25.9333 7.67947 25.9333 14.2705C25.9333 20.8615 20.5899 26.2049 13.9989 26.2049Z"
            fill="url(#paint3_linear_9828_95760)"
          />
        </g>
        <g opacity="0.36">
          <path
            d="M14 5.05184C13.8943 5.05184 13.8086 4.96614 13.8086 4.86043V3.02435C13.8086 2.91864 13.8943 2.83295 14 2.83295C14.1057 2.83295 14.1914 2.91864 14.1914 3.02435V4.86043C14.1914 4.96614 14.1057 5.05184 14 5.05184Z"
            fill="white"
          />
          <path
            d="M12.6509 4.22176C12.5563 4.22176 12.474 4.15165 12.4613 4.05535L12.3415 3.14557C12.3277 3.04073 12.4015 2.94459 12.5063 2.93081C12.6108 2.91725 12.7072 2.9908 12.721 3.09558L12.8409 4.00536C12.8546 4.1102 12.7809 4.20634 12.6761 4.22012C12.6677 4.22122 12.6592 4.22176 12.6509 4.22176Z"
            fill="white"
          />
          <path
            d="M10.047 4.91997C9.9719 4.91997 9.9007 4.87551 9.87013 4.80179L9.51854 3.95353C9.47807 3.85586 9.52439 3.74391 9.62207 3.70339C9.71985 3.66297 9.83174 3.70929 9.87221 3.80691L10.2238 4.65517C10.2643 4.75284 10.2179 4.86479 10.1203 4.90531C10.0963 4.91526 10.0714 4.91997 10.047 4.91997Z"
            fill="white"
          />
          <path
            d="M7.71156 6.26783C7.65409 6.26783 7.59727 6.24207 7.55959 6.19296L7.00052 5.46452C6.93615 5.38069 6.95195 5.26054 7.03584 5.19617C7.11968 5.13186 7.23988 5.14761 7.3042 5.2315L7.86327 5.95994C7.92763 6.04377 7.91183 6.16392 7.82794 6.22829C7.79321 6.25498 7.7522 6.26783 7.71156 6.26783Z"
            fill="white"
          />
          <path
            d="M5.80649 8.17443C5.7658 8.17443 5.72484 8.16152 5.69012 8.13483L4.96162 7.57576C4.87773 7.5114 4.86193 7.39125 4.9263 7.30741C4.99066 7.22357 5.11087 7.20777 5.19465 7.27208L5.92314 7.83115C6.00703 7.89552 6.02284 8.01567 5.95847 8.0995C5.92079 8.14867 5.86397 8.17443 5.80649 8.17443Z"
            fill="white"
          />
          <path
            d="M4.45761 10.5095C4.43322 10.5095 4.40839 10.5048 4.38439 10.4949L3.53613 10.1433C3.43846 10.1028 3.39214 9.99081 3.43261 9.89314C3.47307 9.79552 3.58502 9.74909 3.68275 9.78962L4.531 10.1412C4.62868 10.1817 4.675 10.2937 4.63453 10.3913C4.60396 10.465 4.5327 10.5095 4.45761 10.5095Z"
            fill="white"
          />
          <path
            d="M3.76159 13.1136C3.75328 13.1136 3.74486 13.1131 3.73638 13.112L2.8266 12.9922C2.72176 12.9784 2.64799 12.8822 2.66182 12.7774C2.67561 12.6726 2.77175 12.5993 2.87658 12.6127L3.78636 12.7325C3.8912 12.7463 3.96497 12.8424 3.95114 12.9472C3.93845 13.0435 3.85614 13.1136 3.76159 13.1136Z"
            fill="white"
          />
          <path
            d="M11.5648 5.37229C11.4803 5.37229 11.403 5.31591 11.3801 5.23038L10.905 3.45675C10.8777 3.35465 10.9383 3.24965 11.0404 3.22231C11.1424 3.19502 11.2474 3.2555 11.2748 3.35766L11.7499 5.13128C11.7772 5.23339 11.7166 5.33839 11.6145 5.36573C11.5979 5.37016 11.5812 5.37229 11.5648 5.37229Z"
            fill="white"
          />
          <path
            d="M9.29327 6.31191C9.2271 6.31191 9.16279 6.27762 9.1273 6.2162L8.20926 4.62616C8.15643 4.53462 8.18777 4.41759 8.27931 4.3647C8.37086 4.31193 8.48789 4.34316 8.54077 4.43476L9.45881 6.0248C9.51164 6.11634 9.4803 6.23337 9.38876 6.28626C9.35862 6.30359 9.3257 6.31191 9.29327 6.31191Z"
            fill="white"
          />
          <path
            d="M7.34501 7.80766C7.29601 7.80766 7.24701 7.78896 7.20965 7.7516L5.91154 6.45354C5.83678 6.37878 5.83678 6.2576 5.91154 6.18284C5.98629 6.10808 6.10748 6.10808 6.18224 6.18284L7.48036 7.4809C7.55512 7.55566 7.55512 7.67685 7.48036 7.7516C7.44295 7.78896 7.39395 7.80766 7.34501 7.80766Z"
            fill="white"
          />
          <path
            d="M5.85163 9.75678C5.81915 9.75678 5.78628 9.74852 5.7561 9.73113L4.16606 8.81309C4.07451 8.76021 4.04312 8.64317 4.096 8.55163C4.14888 8.46008 4.26597 8.4288 4.35746 8.48157L5.9475 9.39961C6.03905 9.4525 6.07044 9.56953 6.01756 9.66107C5.98217 9.72249 5.91781 9.75678 5.85163 9.75678Z"
            fill="white"
          />
          <path
            d="M4.91051 12.0258C4.89416 12.0258 4.87748 12.0237 4.86085 12.0193L3.08723 11.5442C2.98513 11.5168 2.92453 11.4119 2.95188 11.3097C2.97922 11.2077 3.084 11.1471 3.18632 11.1744L4.95995 11.6495C5.06205 11.6768 5.12264 11.7817 5.0953 11.8839C5.07238 11.9694 4.99506 12.0258 4.91051 12.0258Z"
            fill="white"
          />
          <path
            d="M4.58998 14.4617H2.75391C2.6482 14.4617 2.5625 14.376 2.5625 14.2703C2.5625 14.1646 2.6482 14.0789 2.75391 14.0789H4.58998C4.6957 14.0789 4.78139 14.1646 4.78139 14.2703C4.78139 14.376 4.6957 14.4617 4.58998 14.4617Z"
            fill="white"
          />
          <path
            d="M2.85136 15.9296C2.75675 15.9296 2.6745 15.8596 2.66181 15.7632C2.64803 15.6584 2.7218 15.5622 2.82659 15.5485L3.73637 15.4287C3.84142 15.415 3.93734 15.4886 3.95112 15.5935C3.96491 15.6983 3.89113 15.7944 3.78635 15.8082L2.87657 15.928C2.86809 15.9291 2.85967 15.9296 2.85136 15.9296Z"
            fill="white"
          />
          <path
            d="M3.61343 18.7653C3.53829 18.7653 3.46708 18.7208 3.43651 18.6471C3.39604 18.5495 3.44236 18.4375 3.54004 18.397L4.38829 18.0454C4.48607 18.0049 4.59797 18.0513 4.63843 18.149C4.6789 18.2466 4.63258 18.3586 4.53491 18.3991L3.68665 18.7507C3.6627 18.7606 3.63782 18.7653 3.61343 18.7653Z"
            fill="white"
          />
          <path
            d="M5.07827 21.3078C5.0208 21.3078 4.96398 21.2821 4.9263 21.2329C4.86193 21.1491 4.87773 21.029 4.96162 20.9646L5.69012 20.4055C5.77401 20.3411 5.89416 20.3569 5.95847 20.4409C6.02284 20.5247 6.00703 20.6448 5.92314 20.7092L5.19465 21.2683C5.15992 21.295 5.11896 21.3078 5.07827 21.3078Z"
            fill="white"
          />
          <path
            d="M7.15613 23.3836C7.11544 23.3836 7.07448 23.3707 7.03975 23.344C6.95586 23.2796 6.94005 23.1594 7.00442 23.0756L7.56349 22.3472C7.62786 22.2633 7.74806 22.2475 7.83184 22.3118C7.91573 22.3762 7.93154 22.4964 7.86717 22.5802L7.3081 23.3086C7.27037 23.3578 7.21355 23.3836 7.15613 23.3836Z"
            fill="white"
          />
          <path
            d="M9.6992 24.8516C9.67481 24.8516 9.64998 24.8469 9.62597 24.8369C9.5283 24.7965 9.48198 24.6845 9.52245 24.5868L9.87403 23.7385C9.9145 23.6409 10.0264 23.5944 10.1242 23.6349C10.2218 23.6754 10.2682 23.7874 10.2277 23.885L9.87611 24.7333C9.84554 24.8071 9.77429 24.8516 9.6992 24.8516Z"
            fill="white"
          />
          <path
            d="M12.5315 25.6113C12.5232 25.6113 12.5148 25.6108 12.5063 25.6097C12.4014 25.5959 12.3277 25.4997 12.3415 25.3949L12.4613 24.4851C12.4752 24.3803 12.5713 24.3066 12.6761 24.3204C12.7809 24.3342 12.8547 24.4303 12.8409 24.5351L12.721 25.4449C12.7084 25.5412 12.6261 25.6113 12.5315 25.6113Z"
            fill="white"
          />
          <path
            d="M3.14057 17.3722C3.05602 17.3722 2.9787 17.3158 2.95578 17.2302C2.92844 17.1281 2.98903 17.0232 3.09113 16.9959L4.86476 16.5207C4.96708 16.4935 5.07186 16.554 5.0992 16.6561C5.12655 16.7582 5.06595 16.8632 4.96385 16.8905L3.19023 17.3657C3.17366 17.3701 3.15698 17.3722 3.14057 17.3722Z"
            fill="white"
          />
          <path
            d="M4.26195 20.0847C4.19578 20.0847 4.13147 20.0503 4.09598 19.9889C4.04315 19.8974 4.07448 19.7803 4.16603 19.7274L5.75607 18.8094C5.84767 18.7565 5.9647 18.7879 6.01753 18.8795C6.07036 18.971 6.03902 19.088 5.94748 19.1409L4.35744 20.059C4.3273 20.0764 4.29444 20.0847 4.26195 20.0847Z"
            fill="white"
          />
          <path
            d="M8.37878 24.201C8.3463 24.201 8.31343 24.1927 8.28324 24.1753C8.1917 24.1224 8.16031 24.0053 8.21319 23.9138L9.13123 22.3238C9.18411 22.2322 9.3012 22.2009 9.39269 22.2537C9.48424 22.3066 9.51563 22.4236 9.46274 22.5152L8.5447 24.1052C8.50927 24.1667 8.4449 24.201 8.37878 24.201Z"
            fill="white"
          />
          <path
            d="M11.09 25.3247C11.0736 25.3247 11.057 25.3225 11.0404 25.3181C10.9383 25.2908 10.8777 25.1858 10.905 25.0837L11.3801 23.31C11.4074 23.2079 11.5122 23.1473 11.6145 23.1746C11.7166 23.202 11.7772 23.3069 11.7499 23.409L11.2748 25.1827C11.2519 25.2682 11.1746 25.3247 11.09 25.3247Z"
            fill="white"
          />
          <path
            d="M14 25.7074C13.8943 25.7074 13.8086 25.6217 13.8086 25.516V23.6799C13.8086 23.5742 13.8943 23.4885 14 23.4885C14.1057 23.4885 14.1914 23.5742 14.1914 23.6799V25.516C14.1914 25.6217 14.1057 25.7074 14 25.7074Z"
            fill="white"
          />
          <path
            d="M15.4672 25.6111C15.3726 25.6111 15.2904 25.541 15.2777 25.4447L15.1579 24.5349C15.1441 24.4301 15.2179 24.3339 15.3227 24.3201C15.4277 24.3064 15.5237 24.3801 15.5374 24.4849L15.6572 25.3947C15.6711 25.4995 15.5972 25.5957 15.4924 25.6095C15.484 25.6105 15.4755 25.6111 15.4672 25.6111Z"
            fill="white"
          />
          <path
            d="M18.3009 24.8514C18.2259 24.8514 18.1546 24.807 18.1241 24.7333L17.7724 23.8849C17.732 23.7873 17.7783 23.6753 17.876 23.6349C17.9734 23.5944 18.0856 23.6407 18.1261 23.7384L18.4778 24.5867C18.5182 24.6844 18.4719 24.7964 18.3742 24.8368C18.3503 24.8467 18.3254 24.8514 18.3009 24.8514Z"
            fill="white"
          />
          <path
            d="M20.8444 23.3834C20.787 23.3834 20.7301 23.3576 20.6925 23.3085L20.1333 22.5801C20.069 22.4963 20.0848 22.3761 20.1687 22.3118C20.2524 22.2474 20.3726 22.2631 20.437 22.3471L20.9961 23.0755C21.0605 23.1594 21.0447 23.2795 20.9608 23.3439C20.9261 23.3705 20.8851 23.3834 20.8444 23.3834Z"
            fill="white"
          />
          <path
            d="M22.9236 21.3078C22.883 21.3078 22.842 21.2949 22.8072 21.2682L22.0788 20.7091C21.9949 20.6447 21.9791 20.5246 22.0434 20.4407C22.1077 20.3568 22.2279 20.3409 22.3118 20.4054L23.0402 20.9645C23.1241 21.0288 23.1399 21.149 23.0756 21.2328C23.0379 21.282 22.981 21.3078 22.9236 21.3078Z"
            fill="white"
          />
          <path
            d="M24.3873 18.7651C24.3629 18.7651 24.3381 18.7604 24.3141 18.7505L23.4659 18.3989C23.3682 18.3584 23.3218 18.2464 23.3623 18.1488C23.4028 18.0511 23.5148 18.0049 23.6124 18.0452L24.4606 18.3968C24.5583 18.4373 24.6047 18.5493 24.5642 18.6469C24.5337 18.7207 24.4624 18.7651 24.3873 18.7651Z"
            fill="white"
          />
          <path
            d="M25.1484 15.9296C25.14 15.9296 25.1316 15.929 25.1231 15.9279L24.2133 15.8081C24.1085 15.7944 24.0347 15.6982 24.0485 15.5934C24.0623 15.4887 24.1586 15.4147 24.2633 15.4286L25.1731 15.5484C25.2779 15.5622 25.3517 15.6583 25.3379 15.7631C25.3252 15.8594 25.2429 15.9296 25.1484 15.9296Z"
            fill="white"
          />
          <path
            d="M16.9087 25.3246C16.8241 25.3246 16.7468 25.2682 16.7239 25.1826L16.2488 23.4089C16.2214 23.3068 16.282 23.2019 16.3842 23.1745C16.486 23.1472 16.5912 23.2078 16.6185 23.3099L17.0937 25.0836C17.121 25.1857 17.0604 25.2906 16.9583 25.318C16.9417 25.3224 16.9251 25.3246 16.9087 25.3246Z"
            fill="white"
          />
          <path
            d="M19.6213 24.2008C19.5552 24.2008 19.4908 24.1665 19.4554 24.1051L18.5374 22.515C18.4845 22.4235 18.5159 22.3065 18.6075 22.2536C18.6989 22.2008 18.816 22.232 18.8689 22.3236L19.7869 23.9137C19.8398 24.0052 19.8084 24.1223 19.7169 24.1751C19.6868 24.1926 19.6538 24.2008 19.6213 24.2008Z"
            fill="white"
          />
          <path
            d="M21.9505 22.4136C21.9015 22.4136 21.8525 22.3949 21.8151 22.3575L20.517 21.0594C20.4422 20.9847 20.4422 20.8635 20.517 20.7888C20.5917 20.714 20.7129 20.714 20.7877 20.7888L22.0858 22.0869C22.1605 22.1616 22.1605 22.2828 22.0858 22.3575C22.0485 22.3949 21.9995 22.4136 21.9505 22.4136Z"
            fill="white"
          />
          <path
            d="M23.7383 20.0846C23.7058 20.0846 23.6729 20.0763 23.6428 20.0589L22.0528 19.1408C21.9612 19.0879 21.9298 18.9709 21.9827 18.8794C22.0356 18.7878 22.1527 18.7565 22.2442 18.8093L23.8342 19.7273C23.9257 19.7802 23.9571 19.8973 23.9042 19.9888C23.8688 20.0502 23.8044 20.0846 23.7383 20.0846Z"
            fill="white"
          />
          <path
            d="M24.8637 17.3721C24.8473 17.3721 24.8307 17.37 24.8141 17.3656L23.0404 16.8904C22.9383 16.8631 22.8777 16.7582 22.905 16.6561C22.9323 16.5539 23.0375 16.4934 23.1394 16.5206L24.9131 16.9958C25.0152 17.0231 25.0758 17.1281 25.0485 17.2302C25.0256 17.3157 24.9482 17.3721 24.8637 17.3721Z"
            fill="white"
          />
          <path
            d="M25.2462 14.4616H23.4102C23.3044 14.4616 23.2188 14.3759 23.2188 14.2702C23.2188 14.1645 23.3044 14.0788 23.4102 14.0788H25.2462C25.3519 14.0788 25.4376 14.1645 25.4376 14.2702C25.4376 14.3759 25.3519 14.4616 25.2462 14.4616Z"
            fill="white"
          />
          <path
            d="M25.2462 14.4616H23.4102C23.3044 14.4616 23.2188 14.3759 23.2188 14.2702C23.2188 14.1645 23.3044 14.0788 23.4102 14.0788H25.2462C25.3519 14.0788 25.4376 14.1645 25.4376 14.2702C25.4376 14.3759 25.3519 14.4616 25.2462 14.4616Z"
            fill="white"
          />
          <path
            d="M24.238 13.1131C24.1435 13.1131 24.0612 13.043 24.0485 12.9467C24.0347 12.8419 24.1085 12.7457 24.2133 12.7319L25.1231 12.6121C25.2279 12.5987 25.3241 12.6721 25.3379 12.7769C25.3517 12.8817 25.2779 12.9779 25.1731 12.9917L24.2633 13.1115C24.2548 13.1126 24.2464 13.1131 24.238 13.1131Z"
            fill="white"
          />
          <path
            d="M23.5392 10.5094C23.4641 10.5094 23.3928 10.4649 23.3623 10.3912C23.3218 10.2935 23.3681 10.1816 23.4659 10.1411L24.3141 9.78949C24.4115 9.74913 24.5237 9.79535 24.5642 9.89302C24.6047 9.99069 24.5584 10.1026 24.4606 10.1432L23.6124 10.4947C23.5884 10.5047 23.5636 10.5094 23.5392 10.5094Z"
            fill="white"
          />
          <path
            d="M22.1955 8.17443C22.138 8.17443 22.0812 8.14868 22.0435 8.09957C21.9791 8.01573 21.9949 7.89558 22.0788 7.83122L22.8073 7.27215C22.8911 7.20772 23.0112 7.22358 23.0756 7.30747C23.14 7.39131 23.1242 7.51146 23.0403 7.57583L22.3118 8.1349C22.2771 8.16158 22.2361 8.17443 22.1955 8.17443Z"
            fill="white"
          />
          <path
            d="M20.285 6.26793C20.2444 6.26793 20.2034 6.25502 20.1686 6.22833C20.0848 6.16397 20.069 6.04382 20.1333 5.95998L20.6924 5.23155C20.7568 5.14766 20.8768 5.1318 20.9608 5.19622C21.0446 5.26059 21.0604 5.38073 20.9961 5.46457L20.437 6.19301C20.3993 6.24217 20.3425 6.26793 20.285 6.26793Z"
            fill="white"
          />
          <path
            d="M17.9532 4.91991C17.9287 4.91991 17.9039 4.91521 17.8799 4.90526C17.7823 4.86479 17.7359 4.75279 17.7763 4.65512L18.128 3.80686C18.1685 3.70919 18.2807 3.66292 18.3781 3.70333C18.4757 3.7438 18.5221 3.8558 18.4817 3.95348L18.13 4.80173C18.0994 4.8754 18.0282 4.91991 17.9532 4.91991Z"
            fill="white"
          />
          <path
            d="M15.348 4.22175C15.3396 4.22175 15.3312 4.22121 15.3227 4.22011C15.2179 4.20633 15.1441 4.11014 15.1579 4.00535L15.2777 3.09557C15.2915 2.99074 15.3876 2.91729 15.4924 2.9308C15.5972 2.94458 15.6711 3.04078 15.6572 3.14556L15.5374 4.05534C15.5248 4.15164 15.4426 4.22175 15.348 4.22175Z"
            fill="white"
          />
          <path
            d="M23.0898 12.0258C23.0052 12.0258 22.9279 11.9694 22.905 11.8839C22.8777 11.7818 22.9383 11.6768 23.0404 11.6495L24.8141 11.1744C24.9161 11.1471 25.0211 11.2077 25.0485 11.3098C25.0758 11.4119 25.0152 11.5169 24.9131 11.5442L23.1394 12.0193C23.1229 12.0236 23.1062 12.0258 23.0898 12.0258Z"
            fill="white"
          />
          <path
            d="M22.1486 9.75679C22.0825 9.75679 22.0182 9.7225 21.9827 9.66108C21.9298 9.56954 21.9612 9.45251 22.0528 9.39962L23.6428 8.48158C23.7342 8.42881 23.8513 8.46004 23.9042 8.55164C23.9571 8.64319 23.9257 8.76022 23.8342 8.8131L22.2442 9.73114C22.214 9.74853 22.1811 9.75679 22.1486 9.75679Z"
            fill="white"
          />
          <path
            d="M18.703 6.31189C18.6705 6.31189 18.6376 6.30364 18.6075 6.28625C18.5159 6.23336 18.4845 6.11633 18.5374 6.02478L19.4554 4.43474C19.5083 4.3432 19.6254 4.31192 19.7169 4.36469C19.8084 4.41757 19.8398 4.5346 19.7869 4.62615L18.8689 6.21619C18.8335 6.27755 18.7691 6.31189 18.703 6.31189Z"
            fill="white"
          />
          <path
            d="M16.4338 5.37228C16.4173 5.37228 16.4007 5.37015 16.3842 5.36572C16.2821 5.33838 16.2214 5.23343 16.2487 5.13128L16.7239 3.35765C16.7512 3.2556 16.8564 3.19501 16.9583 3.2223C17.0604 3.24964 17.121 3.35459 17.0937 3.45675L16.6185 5.23037C16.5956 5.3159 16.5183 5.37228 16.4338 5.37228Z"
            fill="white"
          />
          <path
            d="M14 5.05184C13.8943 5.05184 13.8086 4.96614 13.8086 4.86043V3.02435C13.8086 2.91864 13.8943 2.83295 14 2.83295C14.1057 2.83295 14.1914 2.91864 14.1914 3.02435V4.86043C14.1914 4.96614 14.1057 5.05184 14 5.05184Z"
            fill="white"
          />
        </g>
        <path d="M22.0731 6.19568L12.375 12.6476L13.9981 14.2707L22.0731 6.19568Z" fill="#F4413D" />
        <path d="M22.075 6.19568L14 14.2707L15.6231 15.8937L22.075 6.19568Z" fill="#CC2B2B" />
        <path d="M12.3777 12.6472L5.92578 22.3453L14.0008 14.2703L12.3777 12.6472Z" fill="white" />
        <path
          d="M5.92578 22.3454L15.6238 15.8934L14.0008 14.2703L5.92578 22.3454Z"
          fill="#D8D8D8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9828_95760"
          x1="14.0008"
          y1="28.0406"
          x2="14.0008"
          y2="0.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9828_95760"
          x1="14.0009"
          y1="28.041"
          x2="14.0009"
          y2="0.5"
          gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="0.36" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9828_95760"
          x1="13.9988"
          y1="26.6636"
          x2="13.9988"
          y2="1.87701"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3871E9" />
          <stop offset="1" stopColor="#61C5F6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9828_95760"
          x1="13.9989"
          y1="26.6639"
          x2="13.9989"
          y2="1.87701"
          gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="0.36" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_9828_95760">
          <rect width="28" height="28" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
