import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function usePasswordChecklist(password: string) {
  const { t } = useTranslation();
  const passwordRules = [
    {
      done: false,
      regex: /[0-9]/,
      text: `${t('rulesPassword.min1')}`,
    },
    {
      done: false,
      regex: /[a-z]/,
      text: `${t('rulesPassword.min1Lower')}`,
    },
    {
      done: false,
      regex: /[A-Z]/,
      text: `${t('rulesPassword.min1Upper')}`,
    },
    {
      done: false,
      regex: /^.{8,}$/,
      text: `${t('rulesPassword.min1Characters')}`,
    },
  ];

  const [passwordChecklist, setPasswordChecklist] = useState(passwordRules);

  const runThroughPasswordChecklist = useCallback(() => {
    const passwordValidationResult = passwordChecklist.map((rule) => {
      return {
        ...rule,
        done: rule.regex.test(password.trim()),
      };
    });

    setPasswordChecklist(passwordValidationResult);
  }, [passwordChecklist, password]);

  useEffect(() => {
    runThroughPasswordChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return passwordChecklist;
}
