import classNames from 'classnames';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../hooks/useClickOutside';
import { DropDownOption } from '../../types';
import { Input } from '../Inputs/Input';
import { AutocompleteIcon } from '../svg';

// TODO add other regular input props
type Props = {
  className?: string;
  disabled?: boolean;
  emptyStateContent: string;
  filter?: (value: string | undefined, options: DropDownOption[]) => DropDownOption[];
  inputBorderColor?: string;
  label?: string;
  labelWithPadding?: boolean;
  inputWithPadding?: boolean;
  onBlur?: (inputValue: string | undefined, option?: DropDownOption) => void;
  onChange?: (inputValue: string, option?: DropDownOption) => void;
  onFocus?: () => void;
  onSelect?: (inputValue: string | undefined, option?: DropDownOption) => void;
  options?: DropDownOption[];
  value?: string;
};

export const Autocomplete = ({
  className,
  disabled,
  emptyStateContent,
  filter,
  inputBorderColor,
  label,
  labelWithPadding,
  inputWithPadding,
  onBlur,
  onChange,
  onFocus,
  onSelect,
  options = [],
  value = '',
  ...restProps
}: Props) => {
  const { t } = useTranslation();
  const [valueString, setValueString] = useState(value);
  const [valueObject, setValueObject] = useState<DropDownOption | undefined>(undefined);

  const [focused, setFocused] = useState(false);

  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValueString(value);
  }, [value]);

  const suggestions = useSuggestions(valueString, options, filter);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange?.(e.target.value, valueObject);
      setValueString(e.target.value);
    },
    [onChange, valueObject]
  );

  const handleSelect = useCallback(
    (option: DropDownOption) => {
      setValueObject(option);
      setValueString(option.name);
      setFocused(false);
      onSelect?.(valueString, option);
      onBlur?.(valueString, option);
    },
    [onBlur, onSelect, valueString]
  );

  const handleBlur = useCallback(() => {
    if (!focused) return;
    setFocused(false);
    onBlur?.(valueString, valueObject);
  }, [focused, onBlur, valueObject, valueString]);

  useClickOutside(autocompleteRef, handleBlur);

  return (
    <div className="flex flex-col justify-between h-full items-start gap-2 w-[100%]">
      {label ? (
        <label className={classNames('text-base text-text_2', labelWithPadding && 'pl-[20px]')}>
          {label}
        </label>
      ) : null}
      <div className="flex relative justify-center w-full" ref={autocompleteRef}>
        <Input
          {...restProps}
          arrowClassName={focused ? 'opened' : ''}
          autoComplete="off"
          className={`${className}`}
          disabled={disabled}
          inputBorderColor={inputBorderColor}
          inputWithPadding={inputWithPadding}
          onBlur={(e) => disabled || e.preventDefault()}
          onChange={(e) => disabled || handleChange(e)}
          onFocus={() => {
            if (disabled) return;
            setFocused(true);
            onFocus?.();
          }}
          overrideContainerClassName={"w-full" + (focused ? ' z-[41]' : '')}
          ref={inputRef}
          rightIcon={<AutocompleteIcon onClick={() => setFocused(!focused)} />}
          value={valueString}
        />
        {focused && suggestions.length ? (
          <div className="absolute rounded-[20px] top-[0] bg-background_15 z-40 flex flex-col items-start overflow-y-auto w-full max-h-[150px] pt-[2.5em] pb-2 border border-solid border-border_3 shadow-lg">
            {suggestions.map((element) => (
              <div
                className="text-[14px] cursor-pointer py-2 px-4 w-full hover:bg-background_2 hover:text-text_2"
                key={element.id}
                onClick={() => handleSelect(element)}>
                {element.name}
              </div>
            ))}
          </div>
        ) : null}
        {focused && !suggestions.length ? (
          <div className="absolute rounded-[20px] top-[0] bg-background_15 z-40 flex flex-col items-start overflow-y-auto w-full max-h-[150px] pt-[2.5em] pb-2 border border-solid border-border_3 shadow-lg">
            <div className="text-[14px] cursor-pointer py-2 px-4 w-full hover:text-text_11">
              {t(`${emptyStateContent}`)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function useSuggestions(
  valueString: string,
  options: DropDownOption[],
  filter: ((value: string | undefined, options: DropDownOption[]) => DropDownOption[]) | undefined
) {
  return useMemo(() => {
    //* если инпут пустой, в опциях отображаем все варианты автокомплита
    if (valueString.length === 0) {
      return options;
    }
    //* фильтруем опции по тому, что введено в инпут
    const filtered = filter?.(valueString, options) || options;

    if (filtered.find((suggestion) => suggestion.name === valueString) && filtered.length === 1) {
      //* если введенное значение найдено в массиве опций и оно оказалось единственным мы находим его индекс в массиве опций ⬇️
      const index = options.findIndex((suggestion) => suggestion.name === valueString);
      //* потом создаем копию массива опций ⬇️
      const optionsCopy = [...options];
      //* и удаляем из копии массива элемент, индекс которого мы искали ранее ⬇️
      const [itemFromOptions] = optionsCopy.splice(index, 1);
      //* затем возвращаем новый массив опций в котором найденный элемент на первом месте, а скопированный массив(из которого мы этот элемент удалили) идет после него ⬇️
      return [itemFromOptions, ...optionsCopy];
    }

    return filtered;
  }, [filter, options, valueString]);
}
