import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAllFreeSpins } from 'src/api/bonusesTransaction';
import { useApi } from 'src/hooks/useApi';
import { useFreeSpins } from 'src/hooks/useFreeSpins';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

export function FreeSpinsHandler() {
  const { openModal } = useModal();
  const { saweFreeSpins, freeSpinsChanged } = useFreeSpins();
  const { isAuthenticated } = useApi();
  const location = useLocation();

  const { refetch } = useGetAllFreeSpins({
    onSuccess: (data) => {
      !location.pathname.includes('/game/') &&
        isAuthenticated &&
        saweFreeSpins?.(data?.data?.freeSpins);
    }
  });

  useEffect(() => {
    if (!location) return;
    isAuthenticated && refetch();

    if (freeSpinsChanged) {
      openModal?.(MODAL_NAME.POPUP_FREESPINS);
    }
  }, [isAuthenticated, freeSpinsChanged, location, refetch]);

  return null;
}
