import { AxiosResponse, AxiosError } from 'axios';
import { useQuery, useInfiniteQuery } from 'react-query';
import {
  allCurrencies,
  allProviders,
  allUserLevels,
  allWinners,
  gamesByPageCode,
  singlePage
} from './content.requests';
import {
  SinglePageResponse,
  AllProvidersBody,
  AllProvidersResponse,
  Provider,
  AllWinnersResponse,
  AllUserValuesBody,
  AllUserLevelsResponse
} from './content.types';
import { useTranslation } from 'react-i18next';
import { useGetUserCountry } from '../profile';
import { useMemo } from 'react';
import { NLD_BANNED_PROVIDERS } from 'src/constants';
import { SingleGame } from '../games/games.types';
import { useGetShortBalance } from '../balance';
import { useGamePlatform } from 'src/hooks/useGamePlatform';
import { useShouldRefetch } from 'src/hooks/useShouldRefetch';

export function useGetAllWinners() {
  // TODO language
  const { i18n } = useTranslation();
  const { data: response, ...query } = useQuery<AxiosResponse<AllWinnersResponse>, AxiosError>(
    ['all-winners', i18n.language],
    () => allWinners(i18n.language)
  );
  return { allWinners: response?.data || [], ...query };
}

export function useGetAllUserLevels(params: AllUserValuesBody) {
  const { i18n } = useTranslation();
  const { data: response, ...query } = useQuery<AxiosResponse<AllUserLevelsResponse[]>, AxiosError>(
    ['all-user-levels', i18n.language],
    () => allUserLevels({ ...params, language: i18n.language })
  );
  return {
    allLevels: response?.data || [],
    ...query
  };
}

export function useGetAllProviders(params: AllProvidersBody, enabled?: boolean) {
  const { i18n } = useTranslation();
  const { isNLD } = useGetUserCountry({});
  const { data: shortBalance } = useGetShortBalance();
  const platformId = useGamePlatform();

  const { data: response, ...query } = useQuery<AxiosResponse<AllProvidersResponse>, AxiosError>(
    ['providers', i18n.language, shortBalance?.currency],
    () => allProviders({ ...params, language: i18n.language, currency: shortBalance?.currency, platform_id: platformId }),
    { enabled: enabled === undefined ? true : enabled }
  );

  const providers = useMemo(() => {
    if (!response) return [];
    if (!response?.data?.data) return [];
    if (isNLD) {
      return response.data.data.filter((provider) => !(provider.name in NLD_BANNED_PROVIDERS));
    }
    return response.data.data;
  }, [isNLD, response]);

  return {
    allProviders: providers,
    allProvidersGames: response?.data?.data || [],
    ...query
  };
}

export function useGetProvidersPaginated(params: AllProvidersBody) {
  const { i18n } = useTranslation();

  const response = useInfiniteQuery<AxiosResponse<AllProvidersResponse>, AxiosError>({
    queryKey: ['providers', params.name, params.currency, i18n.language],
    queryFn: ({ pageParam = 1 }) =>
      allProviders({ ...params, language: i18n.language, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      const pagesCount = Math.ceil(lastPage.data.totalCount / lastPage.data.perPage);
      const pagesLoaded = allPages.length;
      return pagesLoaded < pagesCount ? pagesLoaded + 1 : undefined;
    }
  });

  const providers =
    response.data?.pages.reduce<Provider[]>(
      (providers, page) => [...providers, ...page.data.data],
      []
    ) || [];

  return { providers, ...response };
}

export function useGetAllCurrencies() {
  return useQuery(['all-currencies'], () => allCurrencies);
}

export function useGetSinglePage<TData extends SinglePageResponse>(
  pageCode?: string,
  onSuccess?: (data: AxiosResponse<TData, any>) => void
) {
  const { i18n } = useTranslation();

  const { data: response, ...query } = useQuery<AxiosResponse<TData>, AxiosError>(
    ['context-singlePage', pageCode, i18n.language],
    () => singlePage<TData>(pageCode!, i18n.language),
    { enabled: Boolean(pageCode), onSuccess }
  );
  return {
    data: response?.data,
    childs: response?.data?.childs || [],
    ...query
  };
}

export function useGetGamesByPageCode(
  pageCode?: string,
  options?: {
    enabled?: boolean | undefined;
    onSuccess?: (data: AxiosResponse<SingleGame[], any>) => void;
  }
) {
  const { currencyShouldRefetch, refetchDependencies, refetchParams } = useShouldRefetch({
    lang: true,
    currency: true
  });
  const platformId = useGamePlatform();

  const { data: response, ...query } = useQuery<AxiosResponse<SingleGame[]>, AxiosError>(
    ['context-singlePage', pageCode, platformId, ...refetchDependencies],
    () => gamesByPageCode(pageCode!, { platformId, ...refetchParams }),
    { enabled: Boolean(options?.enabled && currencyShouldRefetch), onSuccess: options?.onSuccess }
  );
  return {
    data: response?.data,
    games: response?.data && Array.isArray(response?.data) ? response?.data : [],
    ...query
  };
}
