import { useTranslation } from 'react-i18next';
import './styles.css';

export const CardSeeAll = ({
  onClick,
  moreButton = false
}: {
  onClick?: () => void;
  moreButton?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => onClick?.()}
      className="see-all-card-wrapper bg-gradient-to-b overflow-hidden from-[#FFF000] to-[#FAAD14]">
      <p className="see-all-card-title">
        {moreButton ? t('general.moreGames') : t('general.allGames')}
      </p>
      <p className="see-all-card-content">+7650</p>
    </div>
  );
};
