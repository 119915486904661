import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useGetShortBalance } from 'src/api/balance';
import { useCancelBonusWager } from 'src/api/bonusesTransaction';
import { BonusAccountCasino, BonusAccountSport } from 'src/api/balance/balance.types';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { getCurrencyIcon, makeFloat } from 'src/utils';
import { Button } from '../Buttons/Button';
import { accountBonusTypes } from 'src/constants';

export const SingleCardBonusBalance = ({
  bonusAccount,
  bonusType
}: {
  bonusAccount: BonusAccountSport & BonusAccountCasino;
  bonusType: number;
}) => {
  const { data: shortBalance } = useGetShortBalance();
  const { mutateAsync: cancelActiveBonus } = useCancelBonusWager();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const currencySign = useMemo(() => {
    return shortBalance?.currency ? getCurrencyIcon(shortBalance?.currency) : '';
  }, [shortBalance?.currency]);

  const balance = useMemo(() => {
    return shortBalance?.balance && bonusAccount?.balance
      ? `${makeFloat(bonusAccount?.balance).toFixed(2)}`
      : '0.00';
  }, [bonusAccount?.balance, shortBalance?.balance]);

  const amount = useMemo(() => {
    return bonusAccount?.wager?.amount
      ? `${makeFloat(bonusAccount?.wager?.amount).toFixed(2)}`
      : '0.00';
  }, [bonusAccount?.wager?.amount]);

  const activeBonusName = useMemo(() => {
    return bonusAccount.wager.bonus?.name;
  }, [bonusAccount.wager.bonus?.name]);

  const activeBonusType = useMemo(() => {
    return accountBonusTypes.find((bonus) => bonus.type === bonusAccount.wager.bonus?.type);
  }, [bonusAccount.wager.bonus?.type]);

  const need = useMemo(() => {
    return bonusAccount?.wager?.need ? `${makeFloat(bonusAccount?.wager?.need).toFixed(2)}` : '0';
  }, [bonusAccount?.wager?.need]);

  const maxBet = useMemo(() => {
    return bonusAccount?.wager?.maxBet
      ? `${makeFloat(bonusAccount?.wager?.maxBet).toFixed(2)}`
      : '0.00';
  }, [bonusAccount?.wager?.maxBet]);

  const date = useMemo(() => {
    return `${dayjs.unix(bonusAccount?.wager?.dateStart).format('DD.MM.YYYY')}`;
  }, [bonusAccount?.wager?.dateStart]);

  const oddsSingle = useMemo(() => {
    return bonusAccount?.wager.minOddsSingle;
  }, [bonusAccount?.wager?.minOddsSingle]);
  const oddsMulti = useMemo(() => {
    return bonusAccount?.wager.minOddsMulti;
  }, [bonusAccount?.wager.minOddsMulti]);
  const oddsSystem = useMemo(() => {
    return bonusAccount?.wager?.minOddsSystem;
  }, [bonusAccount?.wager?.minOddsSystem]);

  const rejectBonus = useCallback(() => {
    cancelActiveBonus({ bonus_type: bonusType });
  }, [bonusType, cancelActiveBonus]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <p className="font-bold text-2xl text-text_8">{t('blockActiveBonus.blockTitle')}</p>
      <ul className="flex flex-col gap-2.5">
        {bonusAccount.wager.bonus && (
          <>
            <li className="flex items-center justify-between gap-20 r_sm:gap-4">
              <p className="font-bold text-base w-1/2 text-text_2">{t('blockActiveBonus.name')}:</p>
              <p className="font-medium text-sm flex-grow whitespace-nowrap w-full text-text_2">
                {activeBonusName}
              </p>
            </li>
            <li className="flex items-center justify-between gap-20 r_sm:gap-4">
              <p className="font-bold text-base w-1/2 text-text_2">{t('blockActiveBonus.type')}:</p>
              <p className="font-medium text-sm flex-grow whitespace-nowrap w-full text-text_2">
                {t(`${activeBonusType?.content}`)}
              </p>
            </li>
          </>
        )}

        <li className="flex items-center justify-between gap-20 r_sm:gap-4">
          <p className="font-bold text-base w-1/2 text-text_2">{t('blockActiveBonus.balance')}:</p>
          <p className="font-medium text-sm flex-grow whitespace-nowrap w-full text-text_2">
            {currencySign}
            {balance}
          </p>
        </li>
        <li className="flex items-center justify-between gap-20 r_sm:gap-4">
          <p className="font-bold text-base w-1/2 text-text_2">{t('blockActiveBonus.wagering')}:</p>
          <p className="font-medium text-sm flex-grow flex flex-wrap w-full text-text_2">
            <span>
              {currencySign}
              {amount}
            </span>{' '}
            /
            <span>
              {currencySign}
              {need}
            </span>
          </p>
        </li>
        <li className="flex items-center justify-between gap-20 r_sm:gap-4">
          <p className="font-bold text-base w-1/2 text-text_2">{t('blockActiveBonus.maxBet')}:</p>
          <p className="font-medium text-sm flex-grow whitespace-nowrap w-full text-text_2">
            {currencySign}
            {maxBet}
          </p>
        </li>
        <li className="flex items-center justify-between gap-20 r_sm:gap-4">
          <p className="font-bold text-base w-1/2 text-text_2">
            {t('blockActiveBonus.activationDate')}:
          </p>
          <p className="font-medium text-sm flex-grow whitespace-nowrap w-full text-text_2">
            {date}
          </p>
        </li>
        {oddsSingle && (
          <li className="flex items-center justify-between gap-20 r_sm:gap-2">
            <p className="font-bold text-base w-1/2 text-text_2">
              {t('blockActiveBonus.minOddsSingle')}:
            </p>
            <p className="font-medium text-sm flex-grow whitespace-nowrap text-text_2">
              {' '}
              {oddsSingle}
            </p>
          </li>
        )}
        {oddsMulti && (
          <li className="flex items-center justify-between gap-20 r_sm:gap-2">
            <p className="font-bold text-base w-1/2 text-text_2">
              {t('blockActiveBonus.minOddsMulti')}:
            </p>
            <p className="font-medium text-sm flex-grow whitespace-nowrap text-text_2">
              {oddsMulti}
            </p>
          </li>
        )}
        {oddsSystem && (
          <li className="flex items-center justify-between gap-20 r_sm:gap-2">
            <p className="font-bold text-base w-1/2 text-text_2">
              {t('blockActiveBonus.minOddsSystem')}:
            </p>
            <p className="font-medium text-sm flex-grow whitespace-nowrap text-text_2">
              {oddsSystem}
            </p>
          </li>
        )}
      </ul>
      <div className="mt-4 r_sm:flex r_sm:items-center r_sm:justify-center">
        <Button
          title={`${t('general.cancel')}`}
          additionalClassName="button-transparent"
          onClick={() =>
            bonusAccount?.wager?.bonusTransactionId &&
            openModal?.(MODAL_NAME.ATTENTION_BONUS, { rejectBonus: rejectBonus })
          }
          label={`${t('general.cancel')}`}
        />
      </div>
    </div>
  );
};
