import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import './styles.css';

type Values = {
  description?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  classNamesForDescription?: string;
};

export const Checkbox = ({
  description,
  onChange,
  checked = false,
  disabled,
  id,
  name,
  classNamesForDescription
}: Values) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsChecked(!isChecked);
      onChange?.(e);
    },
    [isChecked, onChange]
  );
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  return (
    <div className="flex gap-4">
      <div className="er-checkbox relative flex w-[15px] h-[15px]">
        <input
          type="checkbox"
          className="absolute z-20 opacity-0 cursor-pointer w-full h-full"
          id={id}
          name={name}
          checked={isChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        <span
          className="w-[15px] h-[15px] rounded z-10 border bg-background_15 border-border_22 relative
        after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:opacity-1 after:text-xs"></span>
      </div>
      <span className={`${classNamesForDescription ?? ''}`}>{description}</span>
    </div>
  );
};
