import { useTranslation } from 'react-i18next';
import Moon from 'src/assets/images/moon.webp';
import Wizard from 'src/assets/images/wizard.webp';

import './styles.css';

export const BlockedCountryPlug = () => {
  const { t } = useTranslation();

  return (
    <div className="blocked-country-wrapper">
      <img src={Moon} className="moon-image" alt="moon" />
      <p className="blocked-country-title">OOOOPS...</p>
      <p className="blocked-country-subtitle">{t('general.blockedCountry')}</p>
      <img src={Wizard} className="wizard-image" alt="wizard" />
    </div>
  );
};
