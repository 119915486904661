import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDepositSuccess } from 'src/api/deposit';
import { Transaction } from 'src/api/transactions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useAutoDepositSuccess({ transactions }: { transactions: Transaction[] }) {
  const { state } = useLocation();
  const mostRecentTransaction: Transaction | undefined = transactions[0];
  const { mutateAsync } = useDepositSuccess();

  const doCheckForAutoDeposit = useMemo(() => {
    if (!state) return false;
    return Boolean(state.checkAutoDeposits);
  }, [state]);

  useEffect(() => {
    const autosubmittedDeposits = JSON.parse(localStorage.getItem('autosubmittedDeposits') || '{}');
    const id = mostRecentTransaction?.id;
    const isSupportedMethod =
      mostRecentTransaction &&
      (mostRecentTransaction.paymentMethod?.includes('astropay') ||
        mostRecentTransaction.paymentMethod?.includes('volt') ||
        mostRecentTransaction.paymentMethod?.includes('noda'));
    /**
     * Самая недавняя транзакция существует И в статусе 8 (пендинг) И метод либо astropay либо volt
     * добавлена проверка с локалстораджем на случай если эффект сработает несколько раз
     */
    if (
      doCheckForAutoDeposit &&
      mostRecentTransaction &&
      !(id in autosubmittedDeposits) &&
      mostRecentTransaction.status === 8 &&
      isSupportedMethod
    ) {
      mutateAsync({ merchantId: id, fee: mostRecentTransaction.commission });
      localStorage.setItem(
        'autosubmittedDeposits',
        JSON.stringify({ ...autosubmittedDeposits, [id]: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);
}
