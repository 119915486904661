import * as React from 'react';
import { MODAL_NAME, useModal } from './useModal';
import { useDrawer } from './useDrawer';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetect } from './useDeviceDetect';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [
  handleClick?: () => void,
  promptable?: IBeforeInstallPromptEvent | null,
  promptToInstall?: () => void,
  isInstalled?: boolean,
  isIos?: boolean
] {
  const { isMobile, isIos } = useDeviceDetect();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { closeLeftDrawer, closeDesktopSidebar } = useDrawer();

  const [promptable, setPromptable] = React.useState<IBeforeInstallPromptEvent | null>(null);

  const [isInstalled, setIsInstalled] = React.useState<boolean>(false);

  const promptToInstall = React.useCallback(() => {
    if (promptable) {
      return promptable.prompt();
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    );
  }, [promptable]);

  React.useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptable(e);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  React.useEffect(() => {
    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener('appinstalled', onInstall as any);

    return () => {
      window.removeEventListener('appinstalled', onInstall as any);
    };
  }, []);

  const handleClick = React.useCallback(() => {
    if (!isIos) {
      navigate('/pwa-instruction');
      isMobile && closeDesktopSidebar?.();
      closeLeftDrawer?.();
    } else if (isIos) {
      openModal?.(MODAL_NAME.IOSSETUP);
      isMobile && closeDesktopSidebar?.();
      closeLeftDrawer?.();
    } else {
      !isInstalled && promptable && promptToInstall?.();
      isMobile && closeDesktopSidebar?.();
    }
  }, [
    closeDesktopSidebar,
    closeLeftDrawer,
    isInstalled,
    isIos,
    isMobile,
    navigate,
    openModal,
    promptToInstall,
    promptable
  ]);

  return [handleClick, promptable, promptToInstall, isInstalled];
}
