import './index.css';
import classNames from 'classnames';

export const CustomMarquee = ({
  children,
  className = '',
  containerClassName = ''
}: //   horizontal = false
{
  children: React.ReactNode[];
  horizontal?: boolean;
  className?: string;
  containerClassName?: string;
}) => {
  return (
    <div className={classNames('customMarqueeVertical', containerClassName)}>
      <div className={classNames('customMarqueeVertical-content', className)}>{children}</div>
      <div className={classNames('customMarqueeVertical-content', className)}>{children}</div>
    </div>
  );
};
