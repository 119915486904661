import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CONTENT_URL } from 'src/api';

import { Child } from '../../api/content/content.types';
import { IconCurrency } from '../../constants/index';
import { useGetCurrency } from '../../hooks/useCurrency';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Button } from '../Buttons/Button';
import { LittleClock } from '../svg';
import { TournamentMembers } from '../TournamentPreview';

export const TournamentsCard = ({ content, type }: { content: Child, type: 'local' | 'provider' }) => {
  // const { openModal } = useModal();
  // const { isAuthenticated } = useApi();
  const { t } = useTranslation();

  const handleButtonReadMoreClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_readmore', {
      tournament_id: content.title,
    });
  };

  const handleButtonParticipateClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_participate', {
      tournament_id: content.title,
    });
  };


  return (
    <div className="w-full max-w-[340px] flex flex-col justify-center items-center rounded-2xl red-shadow">
      <div
        className="flex rounded-t-2xl w-full h-[300px]"
        style={{
          backgroundImage: `url(${CONTENT_URL}${content.imageMobile})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="w-[49px] h-[23px] px-[5px] ml-[30px] mt-[20px] py-1 bg-orange-gradient rounded-[5px] justify-start items-start gap-2.5 inline-flex">
          <div className="text-center text-text_2 text-xs font-bold font-['Montserrat'] leading-[13.20px]">active</div>
        </div>
      </div>
      <div className="bg-background_38 px-4 w-full py-8 rounded-b-2xl flex flex-col justify-between items-start gap-5">
        <p className="text-text_8 text-[20px] font-bold uppercase">{content.title}</p>
        <div
          className="text-[16px] font-medium short-text-tournament text-text_2"
          dangerouslySetInnerHTML={{
            __html: content?.content,
          }}
        ></div>
        <div className="w-full flex justify-between items-center">
          <Link
            onClick={() => handleButtonReadMoreClick(content)}
            state={{ id: content.code }}
            to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
          >
            <Button
              additionalClassName="w-[140px]"
              label={`${t('general.readMore')}`}
              mode="secondary"
              title={`${t('general.readMore')}`}
            />
          </Link>
          <Link
            aria-label={`${t('tournaments.participate')}`}
            onClick={() => handleButtonParticipateClick(content)}
            state={{ id: content.code }}
            to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
          >
            <Button
              additionalClassName="w-[140px]"
              label={`${t('tournaments.participate')}`}
              mode="primary"
              title={`${t('tournaments.participate')}`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const TournamentsCardCompleted = ({ content, type }: { content: Child, type: 'local' | 'provider' }) => {
  // const { openModal } = useModal();
  // const { isAuthenticated } = useApi();
  // const { t } = useTranslation();

  const handleButtonReadMoreClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_readmore', {
      tournament_id: content.title,
    });
  };

  // const handleButtonParticipateClick = (content: Child) => {
  //   sendGoogleAnalyticsEvent('tournament_participate', {
  //     tournament_id: content.title,
  //   });
  // };

  const currentLanguageObject = useGetCurrency();
  const currencySymbol = IconCurrency[currentLanguageObject.currency as keyof typeof IconCurrency];

  const allPlaces = useMemo(
    () =>
      content?.places.reduce((acc, place) => {
        let prize = '';

        if (place?.amount?.[currentLanguageObject.currency]) {
          prize = `${currencySymbol} ${(place.amount[currentLanguageObject.currency] / 100).toLocaleString()}`;
        }

        if (place?.freeSpins?.number) {
          prize = `${place.freeSpins.number} FS`;
        }

        acc.push(prize);
        return acc;
      }, [] as string[]) ?? [],
    [content?.places, currentLanguageObject.currency],
  );

  const fistThreePlaces = useMemo(() => allPlaces?.slice(0, 3), [allPlaces]);

  const members = useMemo(() => {
    if (fistThreePlaces?.length && content?.members) {
      return content.members.length >= fistThreePlaces?.length
        ? content.members
        : [
            ...content.members,
            ...new Array(fistThreePlaces.length - content.members.length).map((_, i) => ({
              login: '',
              place: content.members.length + i,
              points: 0,
              progress: [
                {
                  currency: currentLanguageObject.currency,
                  progress: 0,
                  taskType: 2,
                },
              ],
            })),
          ];
    } else {
      return content?.members;
    }
  }, [fistThreePlaces, content?.members]);

  const allMembers = useMemo(() => {
    if (!members || !allPlaces) {
      return [];
    }

    const member = [...members]
      .sort((a, b) => b.progress?.[0].progress - a.progress?.[0].progress)
      .map((member, index) => ({
        ...member,
        prize: allPlaces[index],
      }));

    return allPlaces.map((_, index) => {
      return (
        member[index] ?? {
          prize: allPlaces[index],
        }
      );
    });
  }, [members, allPlaces]);

  return (
    <div className="w-full max-w-[340px] flex flex-col justify-center items-center rounded-2xl">
      <div
        className="flex rounded-t-2xl w-full h-[300px]"
        style={{
          backgroundImage: `url(${CONTENT_URL}${content.imageMobile})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="h-[23px] px-[5px] ml-[30px] mt-[20px] py-1 bg-background_16 rounded-[5px] justify-start items-start gap-2.5 inline-flex">
          <div className="text-center text-xs font-bold font-['Montserrat'] leading-[13.20px]">inactive</div>
        </div>
      </div>
      <div className="bg-[#171d29] w-full px-4 py-8 rounded-b-2xl flex flex-col justify-between items-start gap-5">
        <p className="text-text_8 text-[20px] font-bold uppercase">{content.title}</p>
        <div className="content-box flex gap-2">
          <LittleClock></LittleClock>
          {content.dateStart.toLocaleDateString()} - {content.dateEnd.toLocaleDateString()}
        </div>
        <TournamentMembers members={allMembers}></TournamentMembers>
        <div className="w-full flex justify-center items-center">
          <Link
            onClick={() => handleButtonReadMoreClick(content)}
            state={{ id: content.code }}
            to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
          >
            <Button
              additionalClassName="w-[140px]"
              label={`SEE RESULTS`}
              mode="transparent-button"
              title={`SEE RESULTS`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};


