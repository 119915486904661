import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMessage, MESSAGE_STATUS } from 'src/hooks/useMessage';
import { allKycInfo, uploadKycFiles } from './kyc.requests';
import { AllKycInfoResponse } from './kyc.types';

export function useGetAllKycInfo({
  onSuccess
}: {
  onSuccess?: (response: AxiosResponse<AllKycInfoResponse>) => void;
}) {
  const { data: response, ...query } = useQuery<AxiosResponse<AllKycInfoResponse>, AxiosError>(
    ['all-kyc-info'],
    () => allKycInfo(),
    {
      onSuccess
    }
  );
  return {
    allKycInfo: response?.data,
    kycStatus: response?.data.kyc_status,
    uploadedFiles: response?.data.files,
    ...query
  };
}

export function useUploadKycFiles() {
  const { t } = useTranslation();
  const { openMessage } = useMessage();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: uploadKycFiles,
    onSuccess: () => {
      queryClient.invalidateQueries();
      openMessage?.(`${t('apiErrors.imageSuccessfully')}`, MESSAGE_STATUS.SUCCESS);
    },
    onError: (error: AxiosError<{ message: string }>) => {
      openMessage?.(`${t('apiErrors.failedUploaded')}`, MESSAGE_STATUS.ERROR);
      console.log({ error });
    }
  });
  return mutation;
}
