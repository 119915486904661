import classNames from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useLogout } from 'src/api/auth';
import { useGetFullInfo } from 'src/api/profile';
import { profileMenuItems, COMPONENT_TYPE } from 'src/constants/profileMenuItems';
import { useApi } from 'src/hooks/useApi';
import { Button } from '../Buttons/Button';
import { Separator } from '../Separator/Separator';
import Flash from 'src/assets/images/flash.webp';
import './styles.css';
import { useGetFullBalance } from 'src/api/balance';
import { getCurrencyIcon, getLevelsString, makeFloat } from 'src/utils';
import { useGetAllUserLevels } from 'src/api/content';
import { PercentageScale } from '../PercentageScale/PercentageScale';
import { BackArrow } from '../svg';

export const DesktopAside: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { clearToken } = useApi();
  const location = useLocation();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();
  const { mutateAsync: logout } = useLogout({});
  const { fullInfo } = useGetFullInfo({});
  const { allLevels } = useGetAllUserLevels({ loyalty_page_code: 'loyalty' });

  const handleLogout = useCallback(async () => {
    await logout({ language: i18n.language });
    clearToken?.();
  }, [clearToken, i18n.language, logout]);

  const currency = useMemo(() => {
    return fullBalance?.currency ?? 'EUR';
  }, [fullBalance]);

  const userLevel = useMemo(() => {
    return fullInfo?.level ?? 1;
  }, [fullInfo]);

  const balance = useMemo(() => {
    return makeFloat(fullBalance?.balance[currency as keyof typeof fullBalance.balance]).toFixed(2);
  }, [fullBalance, currency]);

  const bonusBalance = useMemo(() => {
    return bonusAccountCasino?.balance ? makeFloat(bonusAccountCasino?.balance).toFixed(2) : '0.00';
  }, [bonusAccountCasino?.balance]);

  const icon = getCurrencyIcon(currency);

  const levelInfo = allLevels.find((lvl) => {
    return userLevel === lvl?.levelInfo?.level;
  });

  const nextLevelInfo = allLevels.find((lvl) => {
    if (userLevel === undefined) return undefined;
    return userLevel + 1 === lvl.levelInfo?.level;
  });

  const userPoints = useMemo(() => {
    return fullInfo?.points;
  }, [fullInfo]);

  const nextLevelPoints = nextLevelInfo?.levelInfo?.fromPoints;

  const getLevelPercentage = () => {
    if (userPoints === undefined || nextLevelPoints === undefined) return undefined;
    return (userPoints / nextLevelPoints) * 100;
  };

  const cashback = useMemo(() => {
    return levelInfo?.levelInfo?.cashback?.percent ?? 0;
  }, [levelInfo?.levelInfo?.cashback?.percent]);

  const handleEnterPromoClick = () => {
    if (location.pathname.includes('wallet/deposit')) {
      const promoCodeElement = document.getElementById('depositPromocode');
      if (promoCodeElement) {
        promoCodeElement.scrollIntoView({
          behavior: 'smooth'
        });
      }
    } else {
      navigate('/account/wallet/deposit', { state: { scrollToPromocode: true } });
    }
  };

  const userLevelData = allLevels.find((level) => {
    return level.levelInfo?.level === userLevel;
  });

  return (
    <div className="desctop-aside h-screen overflow-auto">
      <div className="w-full mb-[24px]">
        <span className="heading">{t('accountWalletTabs.balance')}</span>
        <p className="balance">
          <span className="mr-[4px]">{icon}</span>
          <span>{balance}</span>
        </p>
        <span className="heading">{t('general.cashback')}</span>
        <p className="balance-secondary">
          <span>{`${cashback} %`}</span>
        </p>
        <span className="heading">{t('general.bonusBalance')}</span>
        <p className="balance-secondary">
          <span className="mr-[4px]">{icon}</span>
          <span>{bonusBalance}</span>
        </p>
      </div>
      <div className="buttons-container flex flex-col gap-7">
        <Button
          title={`${t('accountWalletBalance.deposite')}`}
          mode="primary"
          className="text-text_1"
          onClick={() => navigate('/account/wallet/deposit')}
          label={`${t('accountWalletBalance.deposite')}`}
        />
        <Button
          title={`${t('general.withdrawal')}`}
          mode="secondary"
          onClick={() => navigate('/account/wallet/withdrawal')}
          label={`${t('general.withdrawal')}`}
        />
        <p className="promo-link text-text_8" onClick={() => handleEnterPromoClick()}>
          {t('promoCode.subtitle')}
        </p>
      </div>
      <Separator width="w-full" />
      <div className="navigation-container">
        {profileMenuItems.map((item) => (
          <div key={item.key} className="flex justify-start items-center mb-2 w-full">
            <div className="mr-4 w-[20px] h-[20px] flex justify-center items-center">
              {<item.icon />}
            </div>
            <NavLink
              to={item.route}
              aria-label={`${t(`${item.content}`)}`}
              onClick={
                item.type === COMPONENT_TYPE.BUTTON
                  ? () => {
                      handleLogout();
                    }
                  : undefined
              }
              className={classNames(
                'font-bold text-[12px] uppercase text-text_2 hover:text-text_8',
                location.pathname.includes(item.key) ? 'text-text_8' : ''
              )}>
              {t(`${item.content}`)}
            </NavLink>
          </div>
        ))}
      </div>
      <Separator width="w-full" />
      <div className="level-info">
        {!userLevel || userLevel <= 0 ? (
          <></>
        ) : (
          <>
            <div className="level-container">
              <img src={Flash} alt="flash" />
              <p>{userLevelData?.title}</p>
            </div>
            <p className="mt-[16px] mb-[6px] text-text_6 font-medium text-sm">
              {nextLevelPoints &&
                userPoints &&
                getLevelsString(nextLevelPoints - userPoints - 1, userLevel + 1)}
            </p>
            <PercentageScale additionalClassName="mt-[6px]" value={getLevelPercentage() || 0} />
          </>
        )}
        <Link to="/" className="w-full flex justify-start items-center mt-[60px]">
          <BackArrow />
          <p className="ml-3 text-base font-bold uppercase hover:text-text_8">
            {t('general.backToMain')}
          </p>
        </Link>
      </div>
    </div>
  );
};
