import { AxiosResponse } from 'axios';
import { MutationFunction } from 'react-query';

import { apiProvider } from '..';
import { WithLanguage } from '../types';
import {
  ActivateBonusViaLinkBody,
  ActivateBonusViaLinkResponse,
  ActivatePromoCodeBody,
  ActivatePromoCodeResponse,
  BonusesResponse,
  CheckPromoCodeParams,
  ShortBonus,
  SingleBonus,
} from './bonuses.types';

// TODO language
export const bonuses = (language?: string) => {
  return apiProvider.request<BonusesResponse>({
    method: 'get',
    params: {
      language: language || 'en',
    },
    url: '/bonuses',
  });
};

// TODO language
export const singleBonus = (id: string, language?: string) => {
  return apiProvider.request<SingleBonus>({
    method: 'get',
    params: {
      language: language || 'en',
    },
    url: `/bonuses/${id}`,
  });
};

export const activateBonusViaLink: MutationFunction<
  AxiosResponse<ActivateBonusViaLinkResponse>,
  WithLanguage<ActivateBonusViaLinkBody>
> = (data) =>
  apiProvider.request<ActivateBonusViaLinkResponse>({
    data,
    method: 'put',
    url: '/bonuses/activate-bonus-via-link',
  });

// TODO language
export const activatePromoCode = (data: ActivatePromoCodeBody) => {
  return apiProvider.request<ActivatePromoCodeResponse>({
    data,
    method: 'post',
    url: '/bonuses/activate-promo-code',
  });
};

export const checkPromoCode = (data: CheckPromoCodeParams) => {
  return apiProvider.request<ShortBonus>({
    method: 'get',
    url: `/bonuses/available?promocode=${data.promoCode}&amount=${data.depositAmount}`,
  });
};
