import classNames from 'classnames';
import {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  DetailedHTMLProps,
  useCallback,
  useState,
  ChangeEvent
} from 'react';
import './styles.css';

export interface InputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  htmlType?: string;
  label?: ReactNode;
  rightIcon?: ReactNode;
  overrideContainerClassName?: string;
  disabled?: boolean;
  labelWithPadding?: boolean;
  inputBorderColor?: string;
  containerClassName?: string;
  value: string;
}

export const InputDate = forwardRef<HTMLInputElement, InputProps>(function _InputDate(
  {
    htmlType,
    label,
    rightIcon,
    className,
    inputBorderColor,
    overrideContainerClassName,
    onChange,
    disabled,
    labelWithPadding,
    containerClassName,
    value,
    ...inputProps
  },
  ref
) {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleChangeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  return (
    <div
      className={
        overrideContainerClassName ||
        'flex flex-col items-start gap-2 w-full justify-between h-full '
      }>
      {label ? (
        <label className={classNames('text-base text-text_2', labelWithPadding && 'pl-[20px]')}>
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'flex flex-row items-center w-full relative  bg-background_15 border-solid rounded-[20px] h-[42px] border-1',
          inputBorderColor ? inputBorderColor : 'border-border_3',
          containerClassName && containerClassName
        )}>
        <p
          className={classNames(
            'w-full h-full pl-5 flex justify-start items-center',
            inputValue === '' ? 'text-text_6 font-medium text-sm' : ' text-sm',
            className
          )}>
          {value}
        </p>
        <input
          disabled={disabled}
          {...inputProps}
          className={classNames(
            'opacity-0 cursor-pointer absolute w-full h-full top-0 left-0',
            {
              'cursor-not-allowed': disabled
            },
            className,
            'custom-date-input'
          )}
          type={htmlType}
          ref={ref}
          value={value}
          onChange={(event) => {
            disabled || handleChangeDate(event);
            disabled || onChange?.(event);
          }}
        />
        {rightIcon && (
          <div
            className={classNames('absolute right-[20px] bg-background_15 pl-1 cursor-pointer', {
              'bg-background_7': disabled
            })}>
            {rightIcon}
          </div>
        )}
      </div>
    </div>
  );
});
