import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

import { useDrawer } from '../../hooks/useDrawer';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import {
  CategoriesMobile,
  FilledCategoriesMobile,
  FilledProvidersMobile,
  FilledWalletMobile,
  NavbarDepositIcon,
  ProvidersMobile,
  SearchMobile,
  WalletMobile,
} from '../svg';

import './styles.css';

export const Navbar = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApi();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    _closeMainAccountSidebar,
    _closeMainSidebar,
    closeBottomDrawer,
    closeLeftDrawer,
    closeTopDrawer,
    isBottomDrawerOpen,
    isTopDrawerOpen,
    toggleBottomDrawer,
    toggleIsOpenDrawer,
  } = useDrawer();

  const [activeCategory, setActiveCategory] = useState({
    GAME: false,
    PROMO: false,
    PROVIDERS: false,
    SEARCH: false,
  });

  useEffect(() => {
    setActiveCategory((prevState) => ({
      ...prevState,
      PROVIDERS: isBottomDrawerOpen ? true : false,
    }));
  }, [isBottomDrawerOpen]);

  useEffect(() => {
    setActiveCategory((prevState) => ({
      ...prevState,
      GAME: location.pathname.includes('games'),
    }));
  }, [location]);

  useEffect(() => {
    setActiveCategory((prevState) => ({
      ...prevState,
      PROMO: location.pathname.includes('bonuses'),
    }));
  }, [location]);

  const closeAllDrawers = () => {
    _closeMainSidebar?.();
    _closeMainAccountSidebar?.();
    closeLeftDrawer?.();
    closeBottomDrawer?.();
    closeTopDrawer?.();
  };

  return (
    <div className="z-20">
      <div className="navbar">
        <div
          className="flex flex-col justify-center items-center h-full gap-2 cursor-pointer w-1/5"
          onClick={() => {
            navigate('/games/top-games');
            closeAllDrawers();
          }}
        >
          {activeCategory.GAME ? <FilledCategoriesMobile /> : <CategoriesMobile />}
          <p className="font-bold text-[10px] text-text_2">{t('mobileMenuItem.categories')}</p>
        </div>
        <div
          className="flex flex-col justify-center items-center h-full gap-2 cursor-pointer w-1/5"
          onClick={() => {
            navigate('/bonuses');
            closeAllDrawers();
          }}
        >
          {activeCategory.PROMO ? <FilledWalletMobile /> : <WalletMobile />}
          <p className="font-bold text-[10px] text-text_2">{t('mobileMenuItem.promo')}</p>
        </div>
        <div
          className="flex justify-center cursor-pointer w-1/5"
          onClick={() => {
            isAuthenticated ? openModal?.(MODAL_NAME.POPUP_DEPOSIT) : openModal?.(MODAL_NAME.SIGNUP);
            closeBottomDrawer?.();
          }}
        >
          <NavbarDepositIcon className="relative w-auto" />
        </div>
        <div
          className="flex flex-col justify-center items-center h-full gap-2 cursor-pointer w-1/5"
          onClick={() => {
            toggleIsOpenDrawer?.();
            closeAllDrawers?.();
          }}
        >
          <SearchMobile />
          <p className="font-bold text-[10px] text-text_2">{t('mobileMenuItem.search')}</p>
        </div>
        <div
          className="flex flex-col justify-center items-center h-full gap-2 cursor-pointer w-1/5"
          onClick={() => {
            toggleBottomDrawer?.();
            _closeMainSidebar?.();
            isTopDrawerOpen && closeTopDrawer?.();

            if (!isBottomDrawerOpen) {
              sendGoogleAnalyticsEvent('providers_list_opened');
            }
          }}
        >
          {activeCategory.PROVIDERS ? <FilledProvidersMobile /> : <ProvidersMobile />}
          <p className="font-bold text-[10px] text-text_2">{t('mobileMenuItem.providers')}</p>
        </div>
      </div>
    </div>
  );
};
