import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';
import { useApi } from 'src/hooks/useApi';
import { useDrawer } from 'src/hooks/useDrawer';

import { Button } from '../Buttons/Button';
import { SimpleClose } from '../svg';

export const PWAModal: FC = () => {
  const [handleClick] = useAddToHomescreenPrompt();
  const { closeMobileAppPopup, openMobileAppPopup } = useDrawer();
  const { t } = useTranslation();
  const location = useLocation();
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [previousPath, setPreviousPath] = useState<string>();
  const { isAuthenticated } = useApi();

  useEffect(() => {
    return () => {
      setPreviousPath(location.pathname);
    };
  }, [location]);

  useEffect(() => {
    if (previousPath && location.pathname !== previousPath && isAuthenticated) {
      const lastClose = localStorage.getItem('timeHideMobilePopup');

      if (!lastClose) {
        setShowMobilePopup(true);
        openMobileAppPopup?.();
      }

      if (Number(lastClose) > Date.now() + 24 * 3600 * 1000) {
        setShowMobilePopup(true);
        openMobileAppPopup?.();
      }
    }
  }, [previousPath]);

  const handleCloseClick = () => {
    localStorage.setItem('timeHideMobilePopup', String(Date.now()));
    setShowMobilePopup(false);
    closeMobileAppPopup?.();
  };

  const handleRedirectClick = () => {
    handleClick?.();
    setShowMobilePopup(false);
    closeMobileAppPopup?.();
  };

  return showMobilePopup ? (
    <div className="fixed inset-0 z-[900] flex items-center justify-center transition-all bg-background_9">
      <div>
        <SimpleClose
          className="mb-[10px] mr-[10px] ml-auto"
          onShow={handleCloseClick}
        />

        <div className="flex flex-col items-center w-[340px] h-[548px] pt-[40px] rounded-[20px] bg-background_38 bg-[url('/pwa-modal-bg-mobile.png')] bg-cover bg-no-repeat lg:w-[896px] lg:h-[560px] lg:bg-[url('/pwa-modal-bg-desktop.png')]">
          <div className="flex flex-col max-w-[520px] font-bold uppercase text-center">
            <p className="text-[25px] text-text_2 leading-[1.4] lg:text-[40px]">{t('modalPWA.title')}</p>
          </div>

          <Button
            additionalClassName="max-w-[190px] mt-[180px] lg:max-w-[210px] lg:mt-auto lg:mb-[32px]"
            label={t<string>('modalPWA.button')}
            onClick={handleRedirectClick}
          />
        </div>
      </div>
    </div>
  ) : null;
};
