export const Profile = () => {
  return (
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.79774 11.5056H12.7011C13.0271 11.5056 13.3006 11.3952 13.5215 11.1743C13.7424 10.9534 13.8525 10.6803 13.8517 10.3551C13.8517 10.0291 13.7412 9.75563 13.5203 9.53473C13.2994 9.31382 13.0264 9.20375 12.7011 9.20451H5.79774C5.47175 9.20451 5.1983 9.31497 4.97739 9.53588C4.75648 9.75679 4.64641 10.0299 4.64718 10.3551C4.64718 10.6811 4.75763 10.9545 4.97854 11.1754C5.19945 11.3963 5.47252 11.5064 5.79774 11.5056ZM5.79774 6.90339H12.7011C13.0271 6.90339 13.3006 6.79293 13.5215 6.57202C13.7424 6.35112 13.8525 6.07805 13.8517 5.75282C13.8517 5.42683 13.7412 5.15338 13.5203 4.93247C13.2994 4.71156 13.0264 4.60149 12.7011 4.60226H5.79774C5.47175 4.60226 5.1983 4.71271 4.97739 4.93362C4.75648 5.15453 4.64641 5.4276 4.64718 5.75282C4.64718 6.07882 4.75763 6.35227 4.97854 6.57317C5.19945 6.79408 5.47252 6.90415 5.79774 6.90339ZM18.3964 21.2567L13.7366 15.1874C13.4106 14.7464 13.0079 14.4062 12.5285 14.1669C12.0491 13.9276 11.5314 13.8075 10.9753 13.8068H0.0449219V2.30113C0.0449219 1.66832 0.270432 1.1264 0.721453 0.675383C1.17247 0.224362 1.71401 -0.000765089 2.34605 1.95342e-06H16.1528C16.7856 1.95342e-06 17.3275 0.225513 17.7786 0.676534C18.2296 1.12755 18.4547 1.66909 18.4539 2.30113V20.7102C18.4539 20.806 18.449 20.8973 18.439 20.984C18.429 21.0707 18.4148 21.1616 18.3964 21.2567ZM2.34605 23.0113C1.71324 23.0113 1.17171 22.7858 0.721453 22.3348C0.271199 21.8837 0.0456889 21.3422 0.0449219 20.7102V16.1079H10.9753C11.167 16.1079 11.3446 16.1512 11.508 16.2379C11.6714 16.3246 11.8102 16.4442 11.9245 16.5969L16.7569 22.925C16.661 22.9633 16.5605 22.9875 16.4554 22.9975C16.3503 23.0074 16.2495 23.0121 16.1528 23.0113H2.34605Z"
        fill="white"
      />
    </svg>
  );
};

export const BigProfileIcon = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 39.375C31.6482 39.375 39.875 31.1482 39.875 21C39.875 10.8518 31.6482 2.625 21.5 2.625C11.3518 2.625 3.125 10.8518 3.125 21C3.125 31.1482 11.3518 39.375 21.5 39.375ZM21.5 42C33.098 42 42.5 32.598 42.5 21C42.5 9.40202 33.098 0 21.5 0C9.90202 0 0.5 9.40202 0.5 21C0.5 32.598 9.90202 42 21.5 42Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9044 25.3851C14.1107 24.2499 15.7307 23.625 17.4044 23.625H25.5956C27.2693 23.625 28.8893 24.2499 30.0956 25.3851C31.3046 26.5229 32 28.0836 32 29.7292V31.5C32 32.2249 31.4124 32.8125 30.6875 32.8125C29.9626 32.8125 29.375 32.2249 29.375 31.5V29.7292C29.375 28.8331 28.9974 27.9563 28.2966 27.2968C27.5931 26.6348 26.6228 26.25 25.5956 26.25H17.4044C16.3772 26.25 15.4069 26.6348 14.7034 27.2968C14.0026 27.9563 13.625 28.8331 13.625 29.7292V31.5C13.625 32.2249 13.0374 32.8125 12.3125 32.8125C11.5876 32.8125 11 32.2249 11 31.5V29.7292C11 28.0836 11.6954 26.5229 12.9044 25.3851Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 10.5C19.3254 10.5 17.5625 12.2629 17.5625 14.4375C17.5625 16.6121 19.3254 18.375 21.5 18.375C23.6746 18.375 25.4375 16.6121 25.4375 14.4375C25.4375 12.2629 23.6746 10.5 21.5 10.5ZM14.9375 14.4375C14.9375 10.8131 17.8756 7.875 21.5 7.875C25.1244 7.875 28.0625 10.8131 28.0625 14.4375C28.0625 18.0619 25.1244 21 21.5 21C17.8756 21 14.9375 18.0619 14.9375 14.4375Z"
        fill="white"
      />
    </svg>
  );
};
