import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useApi } from 'src/hooks/useApi';

import { MESSAGE_STATUS, useMessage } from '../../hooks/useMessage';
import {
  allSubscriptions,
  fullInfo,
  getUserCountry,
  resendConfirmEmail,
  sendSMSCode,
  updateInfo,
  updateSubscriptions,
  verifySMSCode,
} from './profile.requests';
import { AllSubscriptions, FullInfoResponse, UserCounty } from './profile.types';

export function useGetFullInfo({
  onSuccess,
}: {
  onSuccess?: (response: AxiosResponse<FullInfoResponse>) => void;
} = {}) {
  const { isAuthenticated } = useApi();
  const { data: response, ...query } = useQuery<AxiosResponse<FullInfoResponse>, AxiosError>(
    ['full-info'],
    () => fullInfo(),
    {
      enabled: isAuthenticated,
      onSuccess: (response) => {
        onSuccess?.(response);
      },
    },
  );
  return { fullInfo: response?.data, ...query };
}

export function useGetAllSubscriptions({
  onSuccess,
}: {
  onSuccess?: (response: AxiosResponse<AllSubscriptions>) => void;
}) {
  const { data: response, ...query } = useQuery<AxiosResponse<AllSubscriptions>, AxiosError>(
    ['all-subscriptions'],
    () => allSubscriptions(),
    {
      onSuccess,
    },
  );
  return {
    notifications: response?.data.notifications || {},
    subscriptions: response?.data.subscriptions || {},
    ...query,
  };
}

export function useUpdateInfo({ onChange }: { onChange?: () => void }) {
  const mutation = useMutation({
    mutationFn: updateInfo,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      onChange?.();
    },
  });

  return mutation;
}

export function useUpdateSubscriptions() {
  const mutation = useMutation({
    mutationFn: updateSubscriptions,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
  });

  return mutation;
}

export function useSendSMSCode() {
  const { openMessage } = useMessage();
  const mutation = useMutation({
    mutationFn: sendSMSCode,
    onError: (error: AxiosError<{ errors?: { recipient: string[] } }>) => {
      const errorText = error?.response?.data?.errors?.recipient.join(', ');
      openMessage?.(errorText ?? 'Unknown error', MESSAGE_STATUS.ERROR);
    },
  });

  return mutation;
}

export function useVerifySMSCode() {
  return useMutation({
    mutationFn: verifySMSCode,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
  });
}

export function useResendConfirmEmail({ onChange }: { onChange?: () => void }) {
  const mutation = useMutation({
    mutationFn: resendConfirmEmail,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      onChange?.();
    },
  });

  return mutation;
}

export function useGetUserCountry({ onSuccess }: { onSuccess?: (response: AxiosResponse<UserCounty>) => void }) {
  const { data: response, ...query } = useQuery<AxiosResponse<UserCounty>, AxiosError>(
    ['user-country'],
    () => getUserCountry(),
    {
      onSuccess,
    },
  );
  return {
    isAUS: response?.data.country === 'AUS',
    isBEL: response?.data.country === 'BEL',
    isCZE: response?.data.country === 'CZE',
    isDEU: response?.data.country === 'DEU',
    isESP: response?.data.country === 'ESP',
    isFRA: response?.data.country === 'FRA',
    isNLD: response?.data?.country === 'NLD',
    isSWE: response?.data.country === 'SWE',
    userCountry: response?.data,
    userCountryCurrency: response?.data.currency,
    ...query,
  };
}
