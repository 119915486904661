import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';

type Props = {
  content?: string;
};

export const PopUpGetLevelReward = ({ content }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="w-[300px] p-[20px] r_xsm:w-full">
      {content ? (
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      ) : (
        <p>{t('general.notWorking')}</p>
      )}
      <Button mode="primary" additionalClassName="mt-[30px] uppercase" title={`${t('general.ok')}`}>
        {t('general.ok')}
      </Button>
    </div>
  );
};
