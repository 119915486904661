import { useEffect } from 'react';
import { MODAL_NAME, useModal } from './useModal';
import { useGetSinglePage } from 'src/api/content';
import { Transaction } from 'src/api/transactions';

export const useOpenDepositPaymentInfo = ({ transactions }: { transactions: Transaction[] }) => {
  const { openModal } = useModal();
  const { data: payments } = useGetSinglePage('payment_pending');

  useEffect(() => {
    if (payments?.childs && transactions) {
      const makePaymentsChildrenObject = Object.fromEntries(
        payments?.childs.map((n) => [n.code, { ...n, seen: false }])
      );

      const transactionWithStatusPendingFromStorageString = localStorage.getItem(
        'TransactionWithStatusPending'
      );

      const transactionWithStatusPendingFromStorage = JSON.parse(
        transactionWithStatusPendingFromStorageString || '{}'
      );
      const transactionForLocalStorage = { ...transactionWithStatusPendingFromStorage };

      transactions.forEach((transaction) => {
        if (
          transaction?.paymentMethod &&
          transaction.paymentMethod in makePaymentsChildrenObject &&
          transaction.status === 8 &&
          !(transaction.id in transactionWithStatusPendingFromStorage)
        ) {
          transactionForLocalStorage[`${transaction.id}`] = transaction.id;
          if (!makePaymentsChildrenObject[transaction.paymentMethod].seen) {
            openModal?.(
              MODAL_NAME.DEPOSIT_PAYMENT_INFO,
              {
                text: makePaymentsChildrenObject[transaction.paymentMethod]
              },
              undefined,
              undefined,
              true
            );
          }
          makePaymentsChildrenObject[transaction.paymentMethod].seen = true;
        }
      });
      localStorage.setItem(
        'TransactionWithStatusPending',
        JSON.stringify(transactionForLocalStorage)
      );
    }
  }, [transactions, payments]);
};
