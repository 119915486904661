import { useEffect } from 'react';

import { MODAL_NAME } from '../../hooks/useModal';
import { Deposit } from '../../pages/AccountWallet/Deposit';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';

export const DepositModal = () => {
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    sendGoogleAnalyticsEvent('popup_showed', {
      popup_id: MODAL_NAME.POPUP_DEPOSIT,
      user_id: userId,
    });
  }, []);

  return (
    <div className="h-[100%] w-[100%] max-w-[900px] lg:min-w-[900px]">
      <Deposit />
    </div>
  );
};
