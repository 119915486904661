import { generateSeonSession } from 'src/utils';
import { apiProvider } from '..';
import {
  DepositMethodsParams,
  DepositMethodsResponse,
  DepositSuccessBody,
  MakeDepositBody,
  MakeDepositResponse,
  RedirectDeposit
} from './deposit.types';

// TODO language, X-Authorization
export const depositMethods = (params: DepositMethodsParams) => {
  return apiProvider.request<DepositMethodsResponse>({
    method: 'get',
    url: '/deposit/all-methods',
    params
  });
};

// TODO language, X-Authorization
export const makeDeposit = async (data: MakeDepositBody) => {
  const antifraud = await generateSeonSession(data.email);
  return apiProvider.request<MakeDepositResponse>({
    method: 'post',
    url: '/deposit/make-deposit',
    data: {
      ...data,
      antifraud
    }
  });
};

export const makeRedirectDeposit = (data: RedirectDeposit) => {
  return apiProvider.request<string>({
    method: 'post',
    url: data.url,
    data: data.params
  });
};

export const depositSuccess = (data: DepositSuccessBody) => {
  return apiProvider.request({
    method: 'post',
    url: '/transactions/deposit/succeeded',
    data
  });
};
