import { createSearchParams, useNavigate } from 'react-router-dom';
import { STORAGE_URL } from 'src/api';
import { Provider } from 'src/api/content';
import { useDrawer } from 'src/hooks/useDrawer';
import providerDefault from '../../assets/images/providerDefault.svg';

export const ProviderButton = ({
  provider,
  onClick
}: {
  provider: Provider;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  const { closeBottomDrawer } = useDrawer();

  const handleClick = () => {
    navigate({
      pathname: '/games',
      search: createSearchParams({
        providerId: provider.id.toString()
      }).toString()
    });
  };

  return (
    <div
      onClick={() => {
        handleClick();
        closeBottomDrawer?.();
        onClick?.();
      }}
      className="flex flex-row items-center justify-between px-[6px] py-[10px] rounded-md w-full h-[34px] cursor-pointer gap-2 bg-background_2 provider-card">
      <div className="flex justify-start items-center gap-2">
        <img
          src={`${STORAGE_URL}${provider.providerIcon}`}
          className="h-[14px] w-[16px] object-contain"
          alt={provider.name}
          onError={(event) => {
            const element = event.target as HTMLImageElement;
            element.src = providerDefault;
            element.onerror = null;
          }}
        />
        <p className="text-text_2 text-[14px]">{provider.name}</p>
      </div>
      <p className="text-text_8 text-[14px]">{provider.gamesCount}</p>
    </div>
  );
};
