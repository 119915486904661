import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SingleTournamentMembers, useGetSingleTournament } from '../../api/tournaments';
import { GameCard } from '../../components';
import { Button } from '../../components/Buttons/Button';
import { IconCurrency } from '../../constants/index';
import { useGetCurrency } from '../../hooks/useCurrency';

// @ts-ignore
import { label, prize, tournament, tournamentContent } from './styles.module.css';

const Header = () => {
  return (
    <div className="bg-red-gradient text-text_2 text-[20px]  flex justify-center items-center w-full h-[82px]">
      <h1>TOURNAMENT</h1>
    </div>
  );
};

function convertTimestampToReadableDate(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
}

const Frame = ({ list }: { list: { label: string; value: string }[] }) => {
  return (
    <div className="inline-flex items-start gap-[6px] relative text-text_2">
      {list.map((item) => (
        <div className="inline-flex flex-col items-center justify-center gap-[4px] relative flex-[0_0_auto]">
          <div className="relative w-[52px] h-[36px] bg-[#ffffff0a] rounded-[2px] flex justify-center items-center">
            <div className="h-[26px] top-[4px] left-[10px] text-[26px] tracking-[0] leading-[26px] [font-family:'Mulish'] font-extrabold">
              {item.value}
            </div>
          </div>
          <div className="relative w-fit font-medium text-white text-[12px] tracking-[0] leading-[17px] whitespace-nowrap">
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};

enum PlaceEnum {
  Gold,
  Silver,
  Bronze,
}

const OwlImg = ({ place }: { place: PlaceEnum }) => {
  switch (place) {
    case PlaceEnum.Gold:
      return <div className="bg-[url('/src/assets/images/tor-gold.png')] absolute h-[100px] w-[101px]"></div>;
    case PlaceEnum.Silver:
      return <div className="bg-[url('/src/assets/images/tor-silver.png')] absolute h-[100px] w-[101px]"></div>;
    case PlaceEnum.Bronze:
      return <div className="bg-[url('/src/assets/images/tor-bronze.png')] absolute h-[100px] w-[101px]"></div>;
  }
};

const getPlaceGradient = (place: PlaceEnum) => {
  switch (place) {
    case PlaceEnum.Gold:
      return 'bg-gold-gradient';
    case PlaceEnum.Silver:
      return 'bg-silver-gradient';
    case PlaceEnum.Bronze:
      return 'bg-bronze-gradient';
  }
};

const Place = ({ label, place, sum }: { label: string; place: PlaceEnum; sum: string }) => {
  return (
    <div className="h-[100px] w-[269px] flex flex-col justify-center relative">
      <OwlImg place={place}></OwlImg>
      <div className="pl-[50px]">
        <div
          className="h-[70px] w-full flex flex-col justify-center items-center"
          // @ts-ignore
          style={{ 'box-shadow': '0px 0px 4px 0px rgba(254, 0, 0, 0.6)' }}
        >
          <div className="flex flex-col shrink">
            <div className="text-text_2">{label}</div>
            <span className={classNames(getPlaceGradient(place), 'gradient-text font-bold text-[28px]')}>{sum}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const TournamentDescription = ({ htmlString }: { htmlString: string }) => (
  <div
    className="w-full pt-[70px] text-text_2 tournament-content"
    dangerouslySetInnerHTML={{ __html: htmlString }}
  ></div>
);

const getPlaceLeaderBordGradient = (index: number) => {
  switch (index) {
    case 0:
      return 'bg-table-gold-gradient';
    case 1:
      return 'bg-table-silver-gradient';
    case 2:
      return 'bg-table-bronze-gradient';
    default:
      return '';
  }
};

// const getProgress = (progress: { currency: string; progress: number; taskType: number }[]) => {
//   const progressSum = progress.reduce((acc, item) => {
//     if (item.currency === 'EUR') {
//       acc += item.progress;
//     }
//     return acc;
//   }, 0);
//   return progressSum;
// };
export const LeaderBord = ({ members = [] }: { members?: SingleTournamentMembers[] }) => {
  return (
    <div className="w-[calc(100%+10px)] py-[70px] text-text_2">
      <span className="text-[20px] font-[700] uppercase">Current Leaderboard</span>

      <div className="overflow-x-auto scrollbar-first mt-[20px]">
        <div className="w-[870px] flex scrollbar-first flex-col gap-y-[10px]">
          <div className="grid grid-cols-5 gap-[10px] text-[12px] font-[700] uppercase">
            <div className="pl-[70px]">Place</div>
            <div className="col-span-2">Player</div>
            <div>Progress</div>
            <div>Prize</div>
          </div>

          {members.map((member, index) => (
            <div
              className={classNames(
                getPlaceLeaderBordGradient(index),
                'grid grid-cols-5 gap-[10px] items-center text-[15px] h-[30px] rounded-[10px] col-span-5 font-medium even:bg-[#121212] odd:bg-[rgb(3_3_3_/_10%)]',
              )}
              key={member.login}
            >
              <div className="pl-[70px]">{index + 1}</div>
              <div className="col-span-2">{member.login}</div>
              <div>{member.progress?.[0].progress ? `${member.progress?.[0].progress} points` : ''}</div>
              <div>{member.prize}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export const TournamentsGames = ({ games }: { games: { id: number; image: string; name: string }[] }) => {
  return (
    <div className="w-full">
      <div className="flex py-[25px]">
        <div className="h-[30px] w-[30px] bg-[url('/src/assets/images/medal.png')]"></div>
        <span
          className="text-text_2 text-[18px] font-[700]"
          id="games-title"
        >
          Games participating in the tournament
        </span>
      </div>

      <div className="grid grid-cols-3 md:grid-cols-6 gap-x-1.5 gap-y-2.5 w-full px-[5px]">
        {games.map((game) => (
          <GameCard
            game={game}
            key={game.id}
          />
        ))}
      </div>
    </div>
  );
};

export const getLabelPlaceByIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'First place:';
    case 1:
      return 'Second place:';
    case 2:
      return 'Third place:';
    default:
      return `${index + 1} place:`;
  }
};

interface TournamentEstimation {
  days: number;
  hours: number;
  isEnd: boolean;
  minutes: number;
  seconds: number;
}

function calculateTimeRemaining(timestamp: number): TournamentEstimation {
  const now = new Date().getTime();
  const timeLeft = timestamp * 1000 - now;

  if (timeLeft < 0) {
    return { days: 0, hours: 0, isEnd: true, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, isEnd: false, minutes, seconds };
}

export const LocalTournament: FC = () => {
  const params = useParams();
  const currentLanguageObject = useGetCurrency();
  const id = params.id;
  const result = useGetSingleTournament(Number(id), { currency: currentLanguageObject.currency });
  const data = result.data?.data;
  const [estimation, setEstimation] = useState<TournamentEstimation>({
    days: 0,
    hours: 0,
    isEnd: false,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.dateEnd) {
        const estimation = calculateTimeRemaining(data?.dateEnd);
        setEstimation(estimation);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const currencySymbol = IconCurrency[currentLanguageObject.currency as keyof typeof IconCurrency];
  const fullPrizePool = data?.fullPrizePool.amount[currentLanguageObject.currency];
  const games = data?.tasks.reduce(
    (acc, task) => {
      if (task.games) {
        acc.push(...task.games);
      }
      return acc;
    },
    [] as { id: number; image: string; name: string }[],
  );
  const allPlaces = useMemo(
    () =>
      data?.places.reduce((acc, place) => {
        let prize = '';

        if (place?.amount?.[currentLanguageObject.currency]) {
          prize = `${currencySymbol} ${(place.amount[currentLanguageObject.currency] / 100).toLocaleString()}`;
        }

        if (place?.freeSpins?.number) {
          prize = `${place.freeSpins.number} FS`;
        }

        acc.push(prize);
        return acc;
      }, [] as string[]) ?? [],
    [data?.places, currentLanguageObject.currency],
  );

  const fistThreePlaces = useMemo(() => allPlaces?.slice(0, 3), [allPlaces]);

  const members = useMemo(() => {
    if (fistThreePlaces?.length && data?.members) {
      return data.members.length >= fistThreePlaces?.length
        ? data.members
        : [
            ...data.members,
            ...new Array(fistThreePlaces.length - data.members.length).map((_, i) => ({
              login: '',
              place: data.members.length + i,
              points: 0,
              progress: [
                {
                  currency: currentLanguageObject.currency,
                  progress: 0,
                  taskType: 2,
                },
              ],
            })),
          ];
    } else {
      return data?.members;
    }
  }, [fistThreePlaces, data?.members]);

  const allMembers = useMemo(() => {
    if (!members || !allPlaces) {
      return [];
    }

    const member = [...members]
      .sort((a, b) => b.progress?.[0].progress - a.progress?.[0].progress)
      .map((member, index) => ({
        ...member,
        prize: allPlaces[index],
      }));

    return allPlaces.map((_, index) => {
      return (
        member[index] ?? {
          prize: allPlaces[index],
        }
      );
    });
  }, [members, allPlaces]);

  const TournamentLabel = () => (
    <div className="z-10 flex-col justify-center items-center flex">
      <span className="md:text-[34px] text-[26px] md:font-medium font-[700]">{data?.name}</span>
      <span className="text-[15px] md:font-medium font-[700]">
        {data && convertTimestampToReadableDate(data?.dateStart)} -{' '}
        {data && convertTimestampToReadableDate(data?.dateEnd)}
      </span>
      <div className={classNames(label, 'pt-[10px]')}>
        <div className={prize}>
          {currencySymbol} {(Number(fullPrizePool) / 100).toLocaleString()}
        </div>
      </div>
    </div>
  );

  if (!data) {
    return <></>;
  }

  return (
    <div className="w-full">
      <Header />
      <div className="w-full max-w-[1280px] px-3 flex flex-col justify-center items-center mx-auto">
        <div className="max-w-[1000px] red-shadow w-full text-text_2 rounded-[20px]">
          <div className="relative rounded-[10px] flex justify-center items-center w-full h-[188px] bg-[url('/src/assets/images/tor_head_bg.png')]">
            <div className="z-0 absolute bottom-0 md:left-[68px] left-[calc(50%-96px)] w-[193px] h-[167px] bg-[url('/src/assets/images/tor_owl.png')]"></div>
            <div className="hidden md:block">
              <TournamentLabel />
            </div>
          </div>
        </div>
        <div className="md:hidden block text-text_2">
          <TournamentLabel />
        </div>
        <div className={classNames(label, 'py-[70px] flex flex-col items-center')}>
          <div className={tournament}>TOURNAMENT ENDS IN:</div>
          <div className="py-[20px]">
            <Frame
              list={[
                { label: 'Days', value: estimation.days.toString() },
                { label: 'Hours', value: estimation.hours.toString() },
                { label: 'Minutes', value: estimation.minutes.toString() },
                { label: 'Seconds', value: estimation.seconds.toString() },
              ]}
            />
          </div>
          <Button>
            <a href="#games-title">PARTICIPATE</a>
          </Button>
        </div>
        <div className="gap-[30px] flex lg:flex-row flex-col">
          {fistThreePlaces?.map((place, index) => (
            <Place
              key={place}
              label={getLabelPlaceByIndex(index)}
              place={index}
              sum={place}
            />
          ))}
        </div>
        <div className="max-w-[870px] w-full">
          <TournamentDescription htmlString={data?.description} />
          <LeaderBord members={allMembers} />
        </div>
        {games && <TournamentsGames games={games} />}
      </div>
    </div>
  );
};
