import classNames from 'classnames';
import { useState } from 'react';
import { Child } from '../../api/content/content.types';

export const Faq = ({ faq }: { faq: Child }) => {
  const [activeFaq, setActiveFaq] = useState<boolean>(true);
  const handleClick = () => {
    setActiveFaq(!activeFaq);
  };

  return (
    <div
      className="py-4 px-7 cursor-pointer bg-background_1 first:rounded-t-[20px] last:rounded-b-[20px] w-full"
      onClick={handleClick}>
      <div>
        <div className="flex justify-between">
          <div className="font-bold text-base text-text_2 w-4/5">{faq.title}</div>
          <div>
            <svg
              className={classNames('transition ease-in-out duration-500', {
                'rotate-180': !activeFaq
              })}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="#FFF"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.71209 6.52459C4.0782 6.15847 4.6718 6.15847 5.03791 6.52459L10 11.4867L14.9621 6.52459C15.3282 6.15847 15.9218 6.15847 16.2879 6.52459C16.654 6.8907 16.654 7.4843 16.2879 7.85041L10.6629 13.4754C10.2968 13.8415 9.7032 13.8415 9.33709 13.4754L3.71209 7.85041C3.34597 7.4843 3.34597 6.8907 3.71209 6.52459Z"
                fill="#FFF"
              />
            </svg>
          </div>
        </div>
        <div>
          <div
            className={classNames('mt-[15px] text-base text-text_2', {
              hidden: activeFaq
            })}
            dangerouslySetInnerHTML={{ __html: faq.content }}></div>
        </div>
      </div>
    </div>
  );
};
