type PropsEye = {
  setPasswordShown: (value: React.SetStateAction<boolean>) => void;
  passwordShown: boolean;
};

export const Eye = ({ setPasswordShown, passwordShown }: PropsEye) => {
  if (!passwordShown) {
    return (
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setPasswordShown(!passwordShown)}>
        <path
          d="M1.32227 1L14.8223 15.5M10.826 8.04231C10.826 9.42456 9.70502 10.5448 8.32277 10.5448C6.94052 10.5448 5.82031 9.42456 5.82031 8.04231C5.82031 6.65927 6.94052 5.53906 8.32277 5.53906C9.70502 5.53906 10.826 6.65927 10.826 8.04231ZM8.32133 13.8222C11.336 13.8222 14.0934 11.6547 15.6458 8.04149C14.0934 4.42833 11.336 2.26074 8.32133 2.26074H8.3245C5.30983 2.26074 2.55246 4.42833 1 8.04149C2.55246 11.6547 5.30983 13.8222 8.3245 13.8222H8.32133Z"
          stroke="#9D9D9D"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => setPasswordShown(!passwordShown)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.826 6.78096C10.826 8.16321 9.70502 9.28342 8.32277 9.28342C6.94052 9.28342 5.82031 8.16321 5.82031 6.78096C5.82031 5.39792 6.94052 4.27771 8.32277 4.27771C9.70502 4.27771 10.826 5.39792 10.826 6.78096Z"
        stroke="#9D9D9D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32133 12.5615C11.336 12.5615 14.0934 10.3939 15.6458 6.78075C14.0934 3.16758 11.336 1 8.32133 1H8.3245C5.30983 1 2.55246 3.16758 1 6.78075C2.55246 10.3939 5.30983 12.5615 8.3245 12.5615H8.32133Z"
        stroke="#9D9D9D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossedEye = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.32227 1L14.8223 15.5M10.826 8.04231C10.826 9.42456 9.70502 10.5448 8.32277 10.5448C6.94052 10.5448 5.82031 9.42456 5.82031 8.04231C5.82031 6.65927 6.94052 5.53906 8.32277 5.53906C9.70502 5.53906 10.826 6.65927 10.826 8.04231ZM8.32133 13.8222C11.336 13.8222 14.0934 11.6547 15.6458 8.04149C14.0934 4.42833 11.336 2.26074 8.32133 2.26074H8.3245C5.30983 2.26074 2.55246 4.42833 1 8.04149C2.55246 11.6547 5.30983 13.8222 8.3245 13.8222H8.32133Z"
      stroke="#9D9D9D"
      strokeLinejoin="round"
    />
  </svg>
);
