import { useMemo } from 'react';
import { useGetSinglePage } from 'src/api/content';
import { Wrapper } from '../Wrapper/Wrapper';

export const LoyaltyVip = () => {
  const { data } = useGetSinglePage('loyalty_rules');
  const rules = useMemo(() => data?.childs?.find((child) => child.code === 'loyalty_vip'), [data]);

  return rules ? (
    <div className="w-full flex justify-center items-center pt-[50px] loyalty-container">
      <Wrapper additionalClassName="flex justify-center">
        <div className="max-w-[400px]">
          <p className="text-text_10 font-bold text-[40px] r_lg:text-[25px] text-center">
            {rules.title}
          </p>
          <p
            className="text-text_2 text-[14px] text-start"
            dangerouslySetInnerHTML={{ __html: rules.content }}></p>
        </div>
      </Wrapper>
    </div>
  ) : null;
};
