import { useGetUserCountry } from 'src/api/profile';
import { L } from 'src/constants';

export function useIsCountryExclusion() {
  const { isNLD, isAUS, isCZE, isESP, isFRA, isBEL } = useGetUserCountry({});
  return isNLD || isAUS || isCZE || isESP || isFRA || isBEL;
}

export function useGetKefteme() {
  const isExclusion = useIsCountryExclusion();
  if (isExclusion) {
    return L.NLD;
  }
  return L.OTHERS;
}
