import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';
import { VerificationCardData } from 'src/pages/AccountProfile/Verification';
import { InputFile } from '../Inputs/InputFile';
import { Star, UploadFile } from '../svg';

const MAX_FILE_SIZE = 2048;

export const VerificationDragAndDropCard = ({
  card,
  handleFileDrop
}: {
  card: VerificationCardData;
  handleFileDrop: (file: File) => void;
}) => {
  const { openMessage } = useMessage();
  const { t } = useTranslation();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (
        !file.type.endsWith('image/jpeg') &&
        !file.type.endsWith('image/png') &&
        !file.type.endsWith('image/jpg')
      ) {
        openMessage?.(`${t('apiErrors.fileType')}`, MESSAGE_STATUS.ERROR);
        return;
      }

      const fileSizeKiloBytes = file.size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        openMessage?.(`${t('apiErrors.imageSize')}`, MESSAGE_STATUS.ERROR);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        handleFileDrop(file);
      };
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex gap-4 items-center relative">
        {card.image}
        <p className="font-bold text-xl text-text_2">{t(`${card.title}`)}</p>
        {card.important && <Star classNames="absolute right-0" />}
      </div>
      <div className="-mt-2.5 ">
        <p className="font-bold text-xs text-text_2">{t(`${card.description}`)}</p>
      </div>
      <div>
        <p className="font-medium text-sm text-text_2">{t(`${card.content}`)}</p>
      </div>
      <div className="relative border-dashed border-[1px] flex items-center justify-center min-h-[300px] px-10 text-center rounded-sm bg-background_1 border-border_3">
        <InputFile handleFileChange={handleFileChange} />
        <div className="flex flex-col items-center justify-center">
          <UploadFile classNames="mb-[15px]" />
          <p className="font-bold text-base mb-1 text-text_2">{t('accountProfile.selectUpload')}</p>
          <p className="text-text_2 text-sm text-center">{t('accountProfile.supportedFormates')}</p>
        </div>
      </div>
    </div>
  );
};
