import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useChangePassword } from '../../api/auth';
import { useUpdateInfo } from '../../api/profile';
import {
  FieldType,
  useForm,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validateRequired,
} from '../../hooks/useForm';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { Button } from '../Buttons/Button';
import { Input } from '../Inputs/Input';

export const UpdateMailAndPassword = () => {
  const { t } = useTranslation();
  const { modalProps, openModal } = useModal();

  const { isLoading: isLoadingPassword, mutateAsync: mutatePassword } = useChangePassword();

  const { isLoading: isLoadingEmail, mutateAsync: mutateEmail } = useUpdateInfo({
    // onChange: () => openModal?.(MODAL_NAME.UPDATE_USER_INFORMATION, { isUserFilled: true }),
  });
  const {
    errors,
    fieldsProps: { emailProps, passwordProps },
    isFormValid,
    touched,
    values,
  } = useForm<{
    email: FieldType.Text;
    password: FieldType.Text;
  }>({
    email: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.email')}`,
      validation: [validateRequired, validateMaxLength(256), validateEmail],
    },
    password: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.password')}`,
      validation: [validateRequired, validateMaxLength(256), validatePassword],
    },
  });

  const handleClick = useCallback(async () => {
    if (isFormValid && modalProps?.oldPassword) {
      const resultEmail = await mutateEmail({
        email: values.email ? values.email.trim() : undefined,
      });

      if (resultEmail.data.success) {
        const resultPassword = await mutatePassword({
          new_password: values.password.trim(),
          password: modalProps.oldPassword.trim(),
        });

        if (resultPassword.data.success) {
          localStorage.removeItem('phone');
          return !modalProps?.isUserFilled
            ? openModal?.(MODAL_NAME.UPDATE_USER_INFORMATION)
            : openModal?.(MODAL_NAME.POPUP_DEPOSIT, { isUserFilled: true });
        }
      }
    }
  }, [mutateEmail, mutatePassword, values.email, values.password, isFormValid]);

  return (
    <div className="h-full w-full r_lg:w-[420px] r_sm:w-[340px] r_xl:w-[420px] r_xxl:w-[420px] r_xsm:w-full">
      <div className="modal-wrapper flex flex-col items-center gap-[28px] !overflow-y-auto bg-[#000] px-[10px] pt-[30px] pb-[50px]">
        <div className="flex flex-col gap-[20px]">
          <p className="text-center text-[26px] font-bold uppercase leading-[1.15] text-text_14">
            {t('registration.fillInformation')}
          </p>
          <div className="flex flex-col gap-[16px]">
            <div>
              <Input
                {...emailProps}
                className="placeholder:text-[14px]"
              />
              {touched.email && errors.email && <span className="text-xs text-[red]">{errors.email}</span>}
            </div>
            <div>
              <Input
                {...passwordProps}
                className="placeholder:text-[14px]"
              />
              {touched.password && errors.password && <span className="text-xs text-[red]">{errors.password}</span>}
            </div>
          </div>
        </div>
        <Button
          additionalClassName="r_3xl:max-w-[140px]"
          disabled={isLoadingPassword || isLoadingEmail}
          loading={isLoadingPassword || isLoadingEmail}
          mode="primary"
          onClick={() => handleClick()}
          title={`${t('general.deposit')}`}
        >
          {t('general.deposit')}
        </Button>
      </div>
    </div>
  );
};
