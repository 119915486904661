type PropsArrow = {
  className?: string;
  openList?: () => void;
  style?: React.CSSProperties;
};

export const Arrow = ({ className, openList, style }: PropsArrow) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      onClick={openList}
      className={className}
      style={style}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
};

export const ArrowSingleGame = ({ className, openList, style }: PropsArrow) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={openList}
      className={className}
      style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3447 6.4541C11.0335 6.7653 10.529 6.7653 10.2178 6.4541L6 2.23633L1.78223 6.4541C1.47103 6.7653 0.966473 6.7653 0.655274 6.4541C0.344075 6.1429 0.344075 5.63835 0.655275 5.32715L5.43652 0.545899C5.74772 0.2347 6.25228 0.2347 6.56348 0.545899L11.3447 5.32715C11.6559 5.63835 11.6559 6.1429 11.3447 6.4541Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowBack = () => {
  return (
    <svg
      className="mr-[9px]"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.90625 7C0.90625 10.3653 3.63467 13.0938 7 13.0938C10.3653 13.0938 13.0938 10.3653 13.0938 7C13.0938 3.63467 10.3653 0.90625 7 0.90625C3.63467 0.90625 0.90625 3.63467 0.90625 7ZM7.13623 4.32344C7.17994 4.36679 7.21469 4.41833 7.23848 4.47512C7.26227 4.5319 7.27464 4.59282 7.27488 4.65439C7.27513 4.71595 7.26324 4.77696 7.2399 4.83394C7.21657 4.89091 7.18223 4.94272 7.13887 4.98643L5.60605 6.53125H9.51953C9.64385 6.53125 9.76308 6.58064 9.85099 6.66854C9.9389 6.75645 9.98828 6.87568 9.98828 7C9.98828 7.12432 9.9389 7.24355 9.85099 7.33146C9.76308 7.41936 9.64385 7.46875 9.51953 7.46875H5.60605L7.13887 9.01357C7.18223 9.05732 7.21655 9.10917 7.23986 9.16618C7.26318 9.22319 7.27504 9.28423 7.27477 9.34582C7.2745 9.40741 7.2621 9.46835 7.23828 9.52515C7.21446 9.58195 7.17968 9.6335 7.13594 9.67686C7.09219 9.72021 7.04034 9.75453 6.98333 9.77785C6.92632 9.80117 6.86528 9.81303 6.80369 9.81276C6.7421 9.81249 6.68116 9.80009 6.62437 9.77627C6.56757 9.75245 6.51602 9.71767 6.47266 9.67393L4.14678 7.33018C4.05965 7.24237 4.01076 7.12369 4.01076 7C4.01076 6.87631 4.05965 6.75763 4.14678 6.66982L6.47266 4.32607C6.51602 4.28227 6.5676 4.24746 6.62444 4.22361C6.68128 4.19977 6.74226 4.18737 6.8039 4.18713C6.86553 4.18688 6.92661 4.1988 6.98364 4.22219C7.04066 4.24558 7.09252 4.27998 7.13623 4.32344Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};

export const SidebarArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 12.0005H19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5.00049L19 12.0005L12 19.0005"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BackArrow = () => {
  return (
    <svg width="31" height="17" viewBox="0 0 31 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5 8.41699L1.5 8.41699"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 15.417L1.5 8.41699L8.5 1.41699"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LoyaltyArrow = () => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.7229 27.4714L22.3063 37.888C21.9 38.2942 21.3667 38.4984 20.8333 38.4984C20.3 38.4984 19.7666 38.2942 19.3604 37.888C18.5458 37.0734 18.5458 35.7567 19.3604 34.9422L28.3042 25.9984L19.3605 17.0547C18.5459 16.2401 18.5459 14.9234 19.3605 14.1089C20.175 13.2943 21.4917 13.2943 22.3063 14.1089L32.7229 24.5256C33.5375 25.3401 33.5375 26.6567 32.7229 27.4714Z"
        fill="white"
      />
    </svg>
  );
};
