import { apiProvider } from '..';
import { SingleGame } from '../games/games.types';
import {
  AllCurrenciesResponse,
  AllWinnersResponse,
  AllUserLevelsResponse,
  SinglePageResponse,
  AllProvidersBody,
  AllUserValuesBody,
  GamesByPageCodeBody
} from './content.types';

// TODO language
export const allWinners = (language?: string) => {
  return apiProvider.request<AllWinnersResponse>({
    method: 'get',
    url: '/content/all-winners',
    params: {
      language: language || 'en'
    }
  });
};

// TODO language
export const allUserLevels = ({
  loyalty_page_code = 'loyalty',
  language,
  ...params
}: AllUserValuesBody) => {
  return apiProvider.request<AllUserLevelsResponse[]>({
    method: 'get',
    url: '/content/all-user-levels',
    params: { loyalty_page_code, language: language || 'en', ...params }
  });
};

// TODO language
export const allProviders = ({
  currency = 'EUR',
  page = 1,
  per_page = 20,
  platform_id = 1,
  language,
  name,
  ...params
}: AllProvidersBody) => {
  return apiProvider.request({
    method: 'get',
    url: '/content/all-providers',
    params: {
      currency,
      page,
      per_page,
      platform_id,
      name: name && name.length <= 2 ? undefined : name || undefined,
      language: language || 'en',
      ...params
    }
  });
};

// TODO language
export const allCurrencies = () => {
  return apiProvider.request<AllCurrenciesResponse>({
    method: 'get',
    url: '/content/all-currencies'
  });
};

export const singlePage = <TData = SinglePageResponse>(pageCode: string, language?: string) => {
  return apiProvider.request<TData>({
    method: 'get',
    url: `/content/pages/${pageCode}`,
    params: {
      language: language || 'en'
    }
  });
};

export const gamesByPageCode = (pageCode: string, params: GamesByPageCodeBody) => {
  return apiProvider.request<SingleGame[]>({
    method: 'get',
    url: `/content/games/${pageCode}`,
    params
  });
};
