export const SidebarDiamondSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13048_7264)">
        <path
          d="M16.7097 7.61523H7.29034L12 23.3136L16.7097 7.61523Z"
          fill="#D6C697"
        />
        <path
          d="M10.1889 21.5585L6.00586 7.61523H0L10.1889 21.5585Z"
          fill="#D6C697"
        />
        <path
          d="M16.5405 6.38489L14.0804 1.46411H9.91956L7.45898 6.38489H16.5405Z"
          fill="#D6C697"
        />
        <path
          d="M17.9936 7.61523L13.8111 21.5585L24 7.61523H17.9936Z"
          fill="#D6C697"
        />
        <path
          d="M6.0835 6.38495L8.54425 1.46362H4.00323C3.80951 1.46362 3.62714 1.55481 3.51105 1.70972L0.00457764 6.38458L6.0835 6.38495Z"
          fill="#D6C697"
        />
        <path
          d="M17.9167 6.38495H23.9956L20.4891 1.71008C20.3729 1.55518 20.1903 1.46417 19.9966 1.46399H15.4561L17.9167 6.38495Z"
          fill="#D6C697"
        />
      </g>
      <defs>
        <clipPath id="clip0_13048_7264">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.385498)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SidebarFireSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13048_7259)">
        <path
          clipRule="evenodd"
          d="M14.1505 24.1042C15.3439 22.3394 15.4539 20.4333 14.4804 18.3858C14.2097 19.4842 13.7145 20.1509 12.9948 20.3859C13.6632 18.4772 13.1047 16.4151 11.3194 14.1995C11.2808 16.4878 10.731 18.1546 9.66965 19.2002C8.20762 20.6391 8.22483 22.263 9.72122 24.072C3.50944 20.7662 2.63306 16.3326 7.092 10.7712C7.36856 12.1147 8.03873 12.9396 9.10261 13.2458C7.94297 8.32481 9.16308 4.13179 12.7629 0.666748C12.785 8.35645 15.1431 9.03994 18.1245 12.4725C21.3435 16.5916 19.4518 21.3453 14.1505 24.1042Z"
          fill="#D6C697"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_13048_7259">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.385498)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SidebarFruitsSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13048_7296)">
        <path
          d="M20.4498 19.628C17.791 23.5699 13.3401 24.2645 12.0395 24.384C12.016 24.3859 11.991 24.386 11.9664 24.384C10.6658 24.2645 6.21486 23.5699 3.55608 19.628C2.37046 17.8708 1.7264 16.1046 1.64089 14.3794C1.48767 11.2839 3.2058 8.64676 5.76781 7.38723C12.4386 4.1094 18.6762 12.6488 13.7466 18.9804C13.5977 19.1718 13.6461 19.402 13.7916 19.531C13.9621 19.6825 14.2252 19.661 14.3687 19.4835C17.5404 15.574 16.6017 10.3338 13.3814 7.60725C13.1381 7.40123 13.2204 7.00779 13.5263 6.91758C18.9473 5.31872 25.7537 11.7672 20.4498 19.628Z"
          fill="#D6C697"
        />
        <path
          d="M14.2042 3.56737C13.1527 4.16683 12.5035 5.15743 12.3393 6.27695C12.3026 6.52677 12.0322 6.68584 11.7711 6.56083C11.6163 6.48677 11.5248 6.3225 11.5498 6.15278C11.5672 6.03447 11.5895 5.91351 11.6178 5.7906C10.3353 6.20724 8.85406 6.08863 7.79095 5.54557C6.14478 4.70449 5.50672 3.13583 5.27319 2.27574C5.10118 1.64367 5.43371 0.993109 6.04627 0.762585C6.88036 0.448053 8.52552 0.0460121 10.1717 0.887098C10.4812 1.04561 11.9864 1.9012 12.6114 3.8449C12.947 3.46086 13.3475 3.13483 13.8076 2.8723C13.9991 2.76279 14.2436 2.8298 14.3531 3.02182C14.4627 3.21334 14.3962 3.45786 14.2042 3.56737Z"
          fill="#D6C697"
        />
      </g>
      <defs>
        <clipPath id="clip0_13048_7296">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.385498)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SidebarLiveSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6666 19.0522H2.66665C2.31302 19.0522 1.97389 18.9117 1.72384 18.6617C1.47379 18.4116 1.33331 18.0725 1.33331 17.7189V7.05221C1.33331 6.69858 1.47379 6.35945 1.72384 6.1094C1.97389 5.85935 2.31302 5.71887 2.66665 5.71887H14.6666C15.0203 5.71887 15.3594 5.85935 15.6095 6.1094C15.8595 6.35945 16 6.69858 16 7.05221V17.7189C16 18.0725 15.8595 18.4116 15.6095 18.6617C15.3594 18.9117 15.0203 19.0522 14.6666 19.0522ZM22 18.3855C21.8232 18.3855 21.6536 18.3152 21.5286 18.1902L17.5286 14.1902C17.4036 14.0652 17.3334 13.8957 17.3333 13.7189V11.0522C17.3334 10.8754 17.4036 10.7059 17.5286 10.5809L21.5286 6.58087C21.6219 6.48767 21.7407 6.42419 21.87 6.39848C21.9993 6.37277 22.1333 6.38597 22.2551 6.43642C22.3769 6.48687 22.481 6.57229 22.5542 6.6819C22.6275 6.79151 22.6666 6.92037 22.6666 7.05221V17.7189C22.6666 17.8957 22.5964 18.0653 22.4714 18.1903C22.3464 18.3153 22.1768 18.3855 22 18.3855Z"
        fill="#D6C697"
      />
    </svg>
  );
};

export const SidebarMegawaysSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13048_7274)">
        <path
          d="M0 24.3212H24V12.4596H0V24.3212ZM16.5236 14.9419H21.6337V16.4558L20.2661 18.5935C19.7143 19.4561 19.4226 20.4533 19.4226 21.4773V22.0469H18.0164V21.4773C18.0164 20.1842 18.3847 18.925 19.0815 17.8357L20.0331 16.3482H17.9299V17.7124H16.5236V14.9419ZM9.44498 14.9419H14.5551V16.4558L13.1875 18.5935C12.6357 19.4561 12.344 20.4533 12.344 21.4773V22.0469H10.9377V21.4773C10.9377 20.1842 11.306 18.925 12.0029 17.8357L12.9545 16.3482H10.8512V17.7124H9.44494L9.44498 14.9419ZM2.27255 14.9419H7.38258V16.4558L6.015 18.5935C5.46319 19.4561 5.17153 20.4533 5.17153 21.4773V22.0469H3.76528V21.4773C3.76528 20.1842 4.13358 18.925 4.83042 17.8357L5.78203 16.3482H3.6788V17.7124H2.27255V14.9419Z"
          fill="#D6C697"
        />
        <path
          d="M1.38764 10.4699L3.63286 9.95999L5.87808 10.4699L6.08691 8.177L7.26567 6.1992L5.14955 5.29203L3.63286 3.55981L2.11617 5.29203L0 6.1992L1.17877 8.177L1.38764 10.4699Z"
          fill="#D6C697"
        />
        <path
          d="M9.75477 8.35995L12 7.85L14.2452 8.35995L14.4541 6.06702L15.6328 4.08931L13.5167 3.18209L12 1.44983L10.4833 3.18209L8.36713 4.08931L9.54589 6.06702L9.75477 8.35995Z"
          fill="#D6C697"
        />
        <path
          d="M18.1219 10.4699L20.3671 9.95999L22.6123 10.4699L22.8212 8.177L24 6.1992L21.8838 5.29203L20.3671 3.55981L18.8504 5.29203L16.7343 6.1992L17.9131 8.177L18.1219 10.4699Z"
          fill="#D6C697"
        />
      </g>
      <defs>
        <clipPath id="clip0_13048_7274">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.885498)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SidebarSlotsSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13048_7284)">
        <path
          d="M21.06 12.9075C20.4652 12.6095 19.8058 12.4638 19.1408 12.4834C18.4759 12.5031 17.8262 12.6874 17.25 13.02C16.6738 12.6876 16.024 12.5033 15.359 12.4836C14.694 12.464 14.0345 12.6096 13.4396 12.9075C13.299 12.9803 13.1651 13.0575 13.0343 13.1385C14.1329 14.3004 14.7369 15.8438 14.7188 17.4428C14.6811 19.2781 14.0422 21.0504 12.9 22.4876C14.0643 23.6256 15.6221 24.2717 17.25 24.2918C21.0596 24.2918 23.9063 20.6756 23.9063 17.4428C23.9174 16.4933 23.6549 15.5608 23.1502 14.7565C22.6455 13.9523 21.9198 13.3104 21.06 12.9075Z"
          fill="#D6C697"
        />
        <path
          d="M10.56 12.9075C9.96518 12.6095 9.30575 12.4638 8.64079 12.4834C7.97583 12.5031 7.32614 12.6874 6.74997 13.02C6.17371 12.6875 5.52397 12.5032 4.85896 12.4835C4.19396 12.4639 3.53447 12.6096 2.9396 12.9075C2.07989 13.3104 1.35437 13.9523 0.849709 14.7566C0.345054 15.5608 0.0826061 16.4933 0.0937229 17.4427C0.0937229 20.6756 2.94035 24.2917 6.74997 24.2917C10.5596 24.2917 13.4062 20.6756 13.4062 17.4427C13.4174 16.4933 13.1549 15.5607 12.6501 14.7565C12.1454 13.9522 11.4198 13.3103 10.56 12.9075Z"
          fill="#D6C697"
        />
        <path
          d="M4.5 1.79176C6.74932 1.78917 8.9547 2.41451 10.8679 3.59738C8.48679 5.59307 6.87674 8.35708 6.31537 11.4128C6.462 11.4581 6.6075 11.5073 6.75 11.565C7.04767 11.4455 7.35539 11.3528 7.6695 11.2879C8.24433 8.5463 9.77444 6.0981 11.9869 4.38001C14.1926 6.10623 15.726 8.54884 16.3219 11.2856C16.639 11.3509 16.9496 11.4444 17.25 11.565C17.3929 11.5069 17.5391 11.4578 17.6861 11.412C17.2193 8.87947 16.0253 6.53759 14.25 4.67213H15.7369C16.6093 4.67114 17.4457 4.32413 18.0626 3.70724C18.6795 3.09034 19.0265 2.25393 19.0275 1.38151C19.0275 1.22487 18.9653 1.07464 18.8545 0.963874C18.7437 0.853111 18.5935 0.790884 18.4369 0.790884H16.4119C15.6167 0.791934 14.8488 1.08066 14.2498 1.60375C13.6509 2.12683 13.2615 2.84895 13.1535 3.63676C10.7332 1.59392 7.66716 0.475172 4.5 0.479259C4.32595 0.479259 4.15903 0.5484 4.03596 0.67147C3.91289 0.794541 3.84375 0.961461 3.84375 1.13551C3.84375 1.30956 3.91289 1.47648 4.03596 1.59955C4.15903 1.72262 4.32595 1.79176 4.5 1.79176Z"
          fill="#D6C697"
        />
      </g>
      <defs>
        <clipPath id="clip0_13048_7284">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.385498)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SidebarBonusSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9776 5.45947C23.2574 5.45947 23.4841 5.23269 23.4841 4.95294C23.4841 4.67319 23.2574 4.44641 22.9776 4.44641C22.6979 4.44641 22.4711 4.67319 22.4711 4.95294C22.4711 5.23269 22.6979 5.45947 22.9776 5.45947Z"
        fill="#D6C697"
      />
      <path
        d="M6.30892 8.76078L5.06378 9.55246L6.58422 11.9437L9.15109 10.3117L7.63061 7.92041L6.32908 8.74803C6.32252 8.75272 6.31577 8.7568 6.30892 8.76078Z"
        fill="#D6C697"
      />
      <path
        d="M1.96945 8.90216C1.90918 9.17293 1.95893 9.45656 2.10776 9.69066C2.2566 9.92475 2.49232 10.0901 2.76309 10.1504L2.96761 10.196L4.52236 9.22138C4.53965 9.2106 4.55805 9.20172 4.57725 9.19489L5.49047 8.6143L3.21778 8.10852C2.94702 8.04855 2.66354 8.09843 2.42951 8.24722C2.19549 8.396 2.03004 8.63154 1.96945 8.90216Z"
        fill="#D6C697"
      />
      <path
        d="M8.54332 3.95371C8.27254 3.89343 7.98891 3.94317 7.75481 4.09201C7.52071 4.24085 7.35533 4.47658 7.29504 4.74735L6.58966 7.91559L7.51502 7.32721C7.52942 7.31263 7.54538 7.29966 7.5626 7.28854L9.1053 6.31012L9.33621 5.2056C9.39703 4.93445 9.34767 4.65024 9.19898 4.41548C9.0503 4.18072 8.81445 4.01462 8.54332 3.95371Z"
        fill="#D6C697"
      />
      <path
        d="M12.4328 5.26145C12.351 5.24319 12.2675 5.23394 12.1836 5.23389C11.9683 5.23402 11.7575 5.29573 11.5761 5.41173L8.10529 7.61856L9.62577 10.0098L14.0555 7.19331L13.1447 5.761C13.065 5.63481 12.961 5.52568 12.8388 5.43993C12.7166 5.35419 12.5786 5.29352 12.4328 5.26145Z"
        fill="#D6C697"
      />
      <path
        d="M4.58908 9.85425L1.04228 12.1094C0.788219 12.2713 0.608769 12.5274 0.543301 12.8215C0.477833 13.1156 0.531693 13.4237 0.693064 13.6781L1.6037 15.1104L6.10956 12.2455L4.58908 9.85425Z"
        fill="#D6C697"
      />
      <path
        d="M11.1951 23.364H14.8356C15.1369 23.3637 15.4258 23.2439 15.6388 23.0308C15.8519 22.8178 15.9717 22.5289 15.9721 22.2276V14.8921H11.1951V23.364Z"
        fill="#D6C697"
      />
      <path
        d="M3.21877 22.2276C3.21912 22.5289 3.33896 22.8178 3.55201 23.0308C3.76505 23.2439 4.05391 23.3637 4.35521 23.364H7.05769V14.8921H3.21863L3.21877 22.2276Z"
        fill="#D6C697"
      />
      <path
        d="M7.62018 14.8921H10.6326V23.364H7.62018V14.8921Z"
        fill="#D6C697"
      />
      <path
        d="M19.3982 11.7747L19.5327 10.9903C19.5416 10.9386 19.5358 10.8854 19.516 10.8368C19.4962 10.7882 19.4633 10.7461 19.4208 10.7152C19.3783 10.6844 19.3281 10.666 19.2758 10.6623C19.2234 10.6585 19.1711 10.6694 19.1246 10.6938L18.4202 11.0642L17.7157 10.6938C17.6693 10.6694 17.6169 10.6585 17.5646 10.6622C17.5122 10.666 17.462 10.6844 17.4195 10.7152C17.377 10.746 17.344 10.7882 17.3243 10.8368C17.3045 10.8854 17.2987 10.9386 17.3076 10.9903L17.4422 11.7747L16.8722 12.3302C16.8346 12.3669 16.8081 12.4133 16.7955 12.4642C16.7829 12.5152 16.7848 12.5687 16.8011 12.6186C16.8173 12.6685 16.8471 12.7129 16.8873 12.7467C16.9274 12.7805 16.9762 12.8024 17.0281 12.81L17.8156 12.9245L18.1678 13.6381C18.1911 13.6852 18.227 13.7248 18.2716 13.7525C18.3161 13.7803 18.3676 13.7949 18.4201 13.7949C18.4726 13.7949 18.524 13.7803 18.5686 13.7525C18.6132 13.7248 18.6491 13.6852 18.6723 13.6381L19.0245 12.9245L19.812 12.81C19.8639 12.8024 19.9127 12.7805 19.9528 12.7467C19.9929 12.7128 20.0228 12.6685 20.039 12.6185C20.0552 12.5686 20.0572 12.5152 20.0446 12.4642C20.032 12.4133 20.0054 12.3669 19.9678 12.3302L19.3982 11.7747Z"
        fill="#D6C697"
      />
      <path
        d="M22.633 7.8995L21.8595 7.71153L21.5759 6.96795C21.5572 6.91891 21.5252 6.87607 21.4834 6.84429C21.4416 6.81251 21.3918 6.79305 21.3395 6.78811C21.2873 6.78318 21.2347 6.79296 21.1877 6.81636C21.1407 6.83976 21.1012 6.87584 21.0737 6.92052L20.656 7.598L19.8611 7.63794C19.8087 7.64058 19.7581 7.65782 19.715 7.68773C19.6718 7.71764 19.6379 7.75901 19.6171 7.80717C19.5963 7.85534 19.5893 7.90837 19.5971 7.96027C19.6048 8.01217 19.6269 8.06088 19.6609 8.10088L20.1761 8.70753L19.9688 9.47577C19.9551 9.52643 19.9558 9.57992 19.9709 9.63018C19.986 9.68045 20.0149 9.72548 20.0543 9.76018C20.0937 9.79489 20.142 9.81789 20.1937 9.82657C20.2455 9.83526 20.2987 9.82928 20.3472 9.80933L21.0831 9.5068L21.7497 9.94166C21.7937 9.97033 21.8448 9.98614 21.8973 9.98728C21.9497 9.98843 22.0015 9.97487 22.0466 9.94815C22.0918 9.92142 22.1286 9.88259 22.1528 9.83605C22.1771 9.78951 22.1878 9.73711 22.1839 9.68478L22.1236 8.89124L22.7431 8.39169C22.7839 8.35875 22.8147 8.31504 22.832 8.2655C22.8493 8.21597 22.8524 8.16258 22.841 8.11138C22.8295 8.06018 22.804 8.0132 22.7672 7.97576C22.7304 7.93832 22.6839 7.9119 22.633 7.8995Z"
        fill="#D6C697"
      />
      <path
        d="M21.1616 2.73139C21.1501 2.68017 21.1246 2.63318 21.0878 2.59573C21.051 2.55828 21.0045 2.53187 20.9535 2.51947L20.1801 2.3315L19.8965 1.58792C19.8778 1.53888 19.8457 1.49604 19.804 1.46427C19.7622 1.43249 19.7124 1.41304 19.6601 1.4081C19.6079 1.40317 19.5553 1.41296 19.5083 1.43637C19.4613 1.45977 19.4218 1.49585 19.3943 1.54053L18.9766 2.21801L18.1817 2.25795C18.1293 2.26058 18.0787 2.27782 18.0355 2.30773C17.9924 2.33763 17.9585 2.379 17.9377 2.42717C17.9168 2.47533 17.9099 2.52836 17.9176 2.58027C17.9253 2.63218 17.9474 2.68089 17.9814 2.72089L18.4969 3.3275L18.2893 4.09583C18.2756 4.14649 18.2764 4.19997 18.2915 4.25023C18.3066 4.30048 18.3355 4.3455 18.3748 4.3802C18.4142 4.4149 18.4625 4.43789 18.5143 4.44658C18.566 4.45526 18.6192 4.44929 18.6677 4.42934L19.4036 4.12681L20.0703 4.56176C20.1142 4.59044 20.1653 4.60624 20.2178 4.60739C20.2702 4.60854 20.322 4.59498 20.3672 4.56825C20.4123 4.54153 20.4491 4.5027 20.4734 4.45616C20.4976 4.40962 20.5083 4.35722 20.5044 4.30489L20.4441 3.51125L21.0636 3.0117C21.1044 2.97877 21.1353 2.93506 21.1526 2.88553C21.1699 2.83599 21.173 2.7826 21.1616 2.73139Z"
        fill="#D6C697"
      />
      <path
        d="M16.3209 11.4188C14.9581 11.0816 13.9771 11.4482 13.3941 11.8152C12.7594 12.2148 12.4489 12.6991 12.4359 12.7195C12.396 12.7825 12.3827 12.8588 12.399 12.9316C12.4153 13.0044 12.4599 13.0677 12.5229 13.1076C12.5859 13.1476 12.6622 13.1609 12.7349 13.1446C12.8077 13.1283 12.8711 13.0837 12.911 13.0207C12.9532 12.9551 13.9708 11.4169 16.1857 11.9648C16.2216 11.9741 16.2591 11.9761 16.2959 11.9708C16.3326 11.9655 16.368 11.953 16.3999 11.9339C16.4318 11.9149 16.4596 11.8897 16.4817 11.8599C16.5039 11.83 16.5198 11.7961 16.5288 11.76C16.5377 11.7239 16.5394 11.6865 16.5337 11.6497C16.5281 11.613 16.5153 11.5778 16.4959 11.546C16.4766 11.5143 16.4511 11.4867 16.4211 11.4649C16.391 11.4431 16.3569 11.4274 16.3208 11.4188H16.3209Z"
        fill="#D6C697"
      />
      <path
        d="M13.2209 10.429C13.2042 10.4621 13.1942 10.4982 13.1914 10.5352C13.1887 10.5722 13.1933 10.6094 13.205 10.6446C13.2167 10.6798 13.2353 10.7124 13.2596 10.7404C13.284 10.7684 13.3136 10.7913 13.3469 10.8078C13.3801 10.8243 13.4163 10.8341 13.4533 10.8365C13.4903 10.839 13.5275 10.8341 13.5626 10.8221C13.5977 10.8102 13.6301 10.7914 13.658 10.7668C13.6858 10.7423 13.7085 10.7125 13.7247 10.6791C14.0347 10.1145 14.4564 9.61889 14.9641 9.22253C16.0558 8.3822 17.3232 8.25221 18.731 8.8367C18.7652 8.85086 18.8017 8.85816 18.8387 8.85818C18.8756 8.85821 18.9122 8.85096 18.9463 8.83684C18.9804 8.82273 19.0115 8.80204 19.0376 8.77594C19.0637 8.74984 19.0845 8.71884 19.0986 8.68473C19.1128 8.65061 19.1201 8.61405 19.1201 8.57711C19.1201 8.54017 19.1129 8.50359 19.0988 8.46946C19.0847 8.43532 19.064 8.4043 19.0379 8.37817C19.0118 8.35203 18.9808 8.33129 18.9467 8.31714C16.9444 7.48618 15.4893 8.10845 14.621 8.77684C14.0451 9.22478 13.5683 9.78735 13.2209 10.429Z"
        fill="#D6C697"
      />
      <path
        d="M15.9245 6.57196C15.8535 6.54904 15.7764 6.55525 15.71 6.58923C15.6435 6.62322 15.5934 6.68219 15.5704 6.75318C15.5475 6.82417 15.5537 6.90135 15.5877 6.96776C15.6217 7.03416 15.6807 7.08435 15.7517 7.10727C16.1389 7.22136 16.5404 7.27938 16.9441 7.27958C17.4144 7.27958 17.9576 7.19465 18.4448 6.90594C19.0823 6.52823 19.4911 5.88468 19.6604 4.99344C19.6673 4.95716 19.667 4.91987 19.6595 4.88371C19.652 4.84755 19.6374 4.81322 19.6166 4.78268C19.5959 4.75215 19.5693 4.72601 19.5384 4.70575C19.5075 4.68549 19.4729 4.67151 19.4366 4.66462C19.4004 4.65772 19.3631 4.65804 19.3269 4.66555C19.2907 4.67306 19.2564 4.68762 19.2259 4.7084C19.1953 4.72918 19.1692 4.75577 19.1489 4.78666C19.1287 4.81754 19.1147 4.85211 19.1078 4.8884C18.9701 5.61313 18.6514 6.12871 18.1604 6.42079C17.2266 6.97607 15.9448 6.57843 15.9245 6.57196Z"
        fill="#D6C697"
      />
      <path
        d="M11.8529 14.0201C12.1327 14.0201 12.3594 13.7934 12.3594 13.5136C12.3594 13.2339 12.1327 13.0071 11.8529 13.0071C11.5732 13.0071 11.3464 13.2339 11.3464 13.5136C11.3464 13.7934 11.5732 14.0201 11.8529 14.0201Z"
        fill="#D6C697"
      />
      <path
        d="M15.8382 4.83264C16.1179 4.83264 16.3447 4.60586 16.3447 4.32611C16.3447 4.04636 16.1179 3.81958 15.8382 3.81958C15.5584 3.81958 15.3317 4.04636 15.3317 4.32611C15.3317 4.60586 15.5584 4.83264 15.8382 4.83264Z"
        fill="#D6C697"
      />
      <path
        d="M11.1391 11.6746C11.4188 11.6746 11.6456 11.4478 11.6456 11.168C11.6456 10.8883 11.4188 10.6615 11.1391 10.6615C10.8594 10.6615 10.6326 10.8883 10.6326 11.168C10.6326 11.4478 10.8594 11.6746 11.1391 11.6746Z"
        fill="#D6C697"
      />
      <path
        d="M22.1437 12.4991C22.4234 12.4991 22.6502 12.2724 22.6502 11.9926C22.6502 11.7129 22.4234 11.4861 22.1437 11.4861C21.8639 11.4861 21.6371 11.7129 21.6371 11.9926C21.6371 12.2724 21.8639 12.4991 22.1437 12.4991Z"
        fill="#D6C697"
      />
    </svg>
  );
};

export const SidebarBooksSVG = () => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67131 3.21765C3.67066 3.21765 3.66993 3.21765 3.66929 3.21765C3.4423 3.21765 3.2288 3.30614 3.06759 3.46695C2.9046 3.62953 2.81482 3.84602 2.81482 4.07648V16.9545C2.81482 17.4268 3.20052 17.8119 3.6747 17.8131C5.67374 17.8179 9.02292 18.2345 11.3334 20.6524V7.17516C11.3334 7.01508 11.2925 6.86469 11.2153 6.74025C9.319 3.68634 5.67487 3.22234 3.67131 3.21765Z"
        fill="#D6C697"
      />
      <path
        d="M21.1853 16.9545V4.07636C21.1853 3.84589 21.0955 3.62941 20.9325 3.46682C20.7713 3.30601 20.5577 3.21753 20.3309 3.21753C20.3302 3.21753 20.3295 3.21753 20.3288 3.21753C18.3253 3.2223 14.6812 3.6863 12.7848 6.74021C12.7076 6.86465 12.6668 7.01504 12.6668 7.17512V20.6523C14.9773 18.2344 18.3265 17.8177 20.3255 17.813C20.7996 17.8118 21.1853 17.4266 21.1853 16.9545Z"
        fill="#D6C697"
      />
      <path
        d="M23.1413 6.1875H22.5186V16.9545C22.5186 18.1602 21.5362 19.1434 20.3286 19.1464C18.633 19.1505 15.8372 19.482 13.8572 21.356C17.2817 20.5176 20.8917 21.0626 22.9491 21.5315C23.206 21.59 23.4714 21.5295 23.6773 21.3655C23.8823 21.2019 24 20.9572 24 20.6947V7.04617C24.0001 6.57271 23.6148 6.1875 23.1413 6.1875Z"
        fill="#D6C697"
      />
      <path
        d="M1.48138 16.9545V6.1875H0.85867C0.385294 6.1875 0 6.57271 0 7.04617V20.6945C0 20.9571 0.117657 21.2016 0.322749 21.3652C0.528406 21.5292 0.793619 21.5899 1.05091 21.5312C3.10829 21.0623 6.71841 20.5173 10.1427 21.3558C8.16278 19.4818 5.36697 19.1504 3.67137 19.1463C2.46385 19.1434 1.48138 18.1602 1.48138 16.9545Z"
        fill="#D6C697"
      />
    </svg>
  );
};

export const SidebarDropwinsSVG = () => {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6439 4.84481C19.4402 5.24845 19.0948 5.55027 18.6693 5.69209C18.2402 5.8339 17.7857 5.80118 17.382 5.59754L15.2729 4.543C15.0948 4.45209 14.8911 4.44118 14.702 4.50663C14.5129 4.56845 14.3639 4.69936 14.2729 4.87754L14.0039 5.41936L13.1602 4.99754L13.4293 4.463C13.6329 4.05936 13.9748 3.75754 14.4039 3.61572C14.8329 3.4739 15.2875 3.50663 15.6875 3.70663L17.8002 4.76481C17.9784 4.84845 18.1748 4.86663 18.3639 4.80118C18.5529 4.73936 18.7057 4.60845 18.7966 4.43027C18.9093 4.20118 19.1966 4.09936 19.4257 4.21936C19.662 4.33209 19.7566 4.61209 19.6439 4.84481Z"
        fill="#D6C697"
      />
      <path
        d="M11.8729 6.82297C11.9275 6.84479 11.982 6.86661 12.0366 6.88843C12.2293 6.96843 12.422 7.05207 12.6147 7.14661C12.8911 7.28479 13.1493 7.43752 13.4038 7.59752C13.4729 7.64116 13.5384 7.68479 13.6038 7.72843C13.6766 7.7757 13.7529 7.82297 13.8257 7.87388L14.0257 7.47025C14.2438 7.03025 14.0657 6.49207 13.6257 6.27752L12.7057 5.8157C12.4947 5.71025 12.2511 5.69207 12.0257 5.76843C11.8002 5.84479 11.6184 6.00116 11.5129 6.2157L11.3129 6.62297C11.342 6.63388 11.3747 6.64479 11.4038 6.6557C11.5602 6.70297 11.7166 6.76116 11.8729 6.82297Z"
        fill="#D6C697"
      />
      <path
        d="M13.6911 8.96111C13.6875 8.95747 13.6875 8.95747 13.6911 8.96111C13.4693 8.77565 13.2329 8.60474 12.982 8.44111C12.7311 8.27747 12.4693 8.12838 12.1929 7.98656C11.6511 7.71747 11.0875 7.51383 10.5275 7.37929C9.90202 7.2302 9.27657 7.15747 8.65839 7.15747C5.73475 7.15747 3.01111 8.7902 1.64748 11.5102C-0.290706 15.3829 1.28748 20.1138 5.16384 22.0557C9.0402 23.9938 13.7711 22.412 15.7093 18.5357C17.3602 15.2447 16.5311 11.3066 13.6911 8.96111ZM3.68748 12.5284C2.95293 13.9938 2.90202 15.7029 3.54566 17.212C3.64748 17.452 3.53475 17.7284 3.29838 17.8302C3.23657 17.8557 3.17475 17.8666 3.11293 17.8666C2.93111 17.8666 2.75657 17.7575 2.6802 17.5793C1.92748 15.8157 1.98929 13.8193 2.84748 12.1066C2.96384 11.8738 3.24748 11.7793 3.4802 11.8957C3.70929 12.012 3.80384 12.2957 3.68748 12.5284Z"
        fill="#D6C697"
      />
      <path
        d="M6.18563 20.0121C5.56381 19.7031 5.01472 19.2885 4.5529 18.7794C4.37836 18.5867 4.08018 18.5721 3.88745 18.7467C3.69472 18.9212 3.68018 19.2194 3.85472 19.4121C4.39654 20.0049 5.03654 20.4921 5.76018 20.8521C5.82927 20.8849 5.89836 20.9031 5.97109 20.9031C6.14199 20.9031 6.30927 20.8085 6.3929 20.6449C6.50927 20.4121 6.41472 20.1285 6.18563 20.0121Z"
        fill="#D6C697"
      />
      <path
        d="M19.0475 0.892119C18.7893 0.855755 18.5493 1.0303 18.5093 1.28485L18.3129 2.55394C18.2729 2.81212 18.4511 3.05212 18.7057 3.09212C18.7311 3.09575 18.7529 3.09939 18.7784 3.09939C19.0075 3.09939 19.2075 2.93212 19.2438 2.69939L19.4402 1.4303C19.4802 1.17212 19.302 0.932119 19.0475 0.892119Z"
        fill="#D6C697"
      />
      <path
        d="M21.382 1.93211C21.1711 1.77938 20.8766 1.83029 20.7275 2.0412L19.9966 3.07029C19.8475 3.2812 19.8948 3.57575 20.1057 3.72484C20.1893 3.78302 20.2838 3.81211 20.3784 3.81211C20.5238 3.81211 20.6693 3.74302 20.7602 3.61575L21.4911 2.58666C21.6402 2.37575 21.5929 2.08484 21.382 1.93211Z"
        fill="#D6C697"
      />
      <path
        d="M23.1275 3.79754C23.0075 3.56845 22.7202 3.48118 22.4911 3.60118L21.3566 4.20118C21.1275 4.32118 21.0402 4.60845 21.1602 4.83754C21.2438 4.99754 21.4075 5.08845 21.5784 5.08845C21.6511 5.08845 21.7275 5.07027 21.7966 5.0339L22.9311 4.4339C23.1602 4.31027 23.2475 4.02663 23.1275 3.79754Z"
        fill="#D6C697"
      />
    </svg>
  );
};

export const SidebarBJSVG = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4.46975C17.5868 9.20329 18.6993 16.4625 18.6993 16.4625C18.6993 16.4625 14.6578 6.24561 9.43368 0C8.72748 8.04016 11.3098 18.6932 11.3098 18.6932C11.3098 18.6932 8.02977 12.0883 0 11.7186C6.66205 15.4572 9.24437 22.193 9.8357 24C13.8066 21.607 18.3475 20.2655 23.0109 20.1077C22.4834 18.4253 20.6158 11.3364 24 4.46975Z"
        fill="#D6C697"
      />
    </svg>
  );
};
