import { FC } from 'react';
import { GamesControlPanel } from 'src/components/GamesControlPanel';
import { MainPageBanners } from 'src/components/MainPageBanners/MainPageBanners';
import { Providers } from 'src/components/Providers';
import { Wrapper } from 'src/components/Wrapper/Wrapper';
import { useCheckBonusInLink } from 'src/modules/activateBonusViaLink';

import NoGames from '../../assets/images/noSearchGames.webp';
import { BlockGames } from '../../components/BlockGames';

export const MainPage: FC = () => {
  useCheckBonusInLink();
  return (
    <Wrapper>
      <MainPageBanners />
      <GamesControlPanel
        image={NoGames}
        target="globalSearch"
      />
      <BlockGames categoryId={'top-games'} />
      <BlockGames categoryId={'new-games'} />
      <BlockGames categoryId={'casino-live'} />
      <Providers />
    </Wrapper>
  );
};

// className="flex flex-col px-3 max-w-[1200px] w-full m-auto items-center"
