import { t } from 'i18next';
import { CountryCode, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { makeFloat } from 'src/utils';

import { allCountriesData, mapCountryAlpha2, redefinedMaxAmountMap } from '../../constants';
import allCountries from '../../constants/countries.v1.json';

export function validateRequired(value: any) {
  if (!value) return t('validation.valueRequired');
  return undefined;
}

export function validatePhoneFormat(value: string, values: any) {
  const countryCode = mapCountryAlpha2[values.country];
  if (countryCode && isValidPhoneNumber(value, countryCode as CountryCode)) {
    return;
  } else {
    return t('registration.invalidPhoneNumberError');
  }
}

export const validateMaxLength = (maxLength: number) => (value: string) => {
  if (value && value.length > maxLength) return `${t('general.valueLonger')} ${maxLength} ${t('general.characters')}`;
  return undefined;
};

export const validateDifferenceOfPasswords = (value: string, values: any) => {
  if (value === values.oldPassword) {
    return t('accountProfileSecurity.newPasswordFromOld');
  }
  return undefined;
};

export const validateNumbersString = (value: any) => {
  if (!value.length) {
    return undefined;
  }

  if (/^[0-9]+$/.test(value)) {
    return undefined;
  }
  if (!/^[0-9]+$/.test(value)) {
    return 'registration.lettersInPhoneNumberError';
  }
};

export const validatePhoneByCountryCode = (value: string, values: any) => {
  if (!value.length) {
    return undefined;
  }

  const alpha2 = allCountriesData.find((country) => country.phoneCodes.includes(values.countryCode))?.alpha2;
  if (!alpha2) {
    return t('registration.notFoundCountryPhoneCodeError');
  }
  if (isValidPhoneNumber(`+${getCountryCallingCode(alpha2 as CountryCode)}${value}`, alpha2 as CountryCode)) {
    return undefined;
  }
  return t('registration.invalidPhoneNumberError');
};

export const validatePositiveNumber = (value: number) => {
  if (value <= 0) return t('depositTab.invalidAmountError');
  return undefined;
};
export const validateTransactionValue =
  ({
    balance,
    currency,
    max,
    maxPaymentMethod,
    method,
    min,
    minPaymentMethod,
    notFormattedCurrency,
  }: {
    balance?: number;
    currency: string;
    max: number | undefined;
    maxPaymentMethod: string | undefined;
    method?: string;
    min: number | undefined;
    minPaymentMethod: string | undefined;
    notFormattedCurrency?: string;
  }) =>
  (value: string) => {
    const amountValue = Number(value);

    if (
      method &&
      notFormattedCurrency &&
      redefinedMaxAmountMap[method]?.[notFormattedCurrency] &&
      amountValue > redefinedMaxAmountMap[method][notFormattedCurrency]
    ) {
      return `${t('depositTab.moreThenMaximumAmountError')} ${currency} ${
        redefinedMaxAmountMap[method][notFormattedCurrency]
      }`;
    }

    if (amountValue < +makeFloat(min) || amountValue < Number(minPaymentMethod)) {
      return t('depositTab.lessThanMinimumAmountError');
    }

    if (max !== 0 && amountValue > +makeFloat(max)) {
      return `${t('depositTab.moreThenMaximumAmountError')} ${currency} ${makeFloat(max)}`;
    }

    if (Number(maxPaymentMethod) !== 0 && amountValue > Number(maxPaymentMethod)) {
      return `${t('depositTab.moreThenMaximumAmountError')} ${currency} ${Number(maxPaymentMethod)}`;
    }

    if (balance && amountValue > +makeFloat(balance)) {
      return t('accountWalletWithdrawal.notEnoughAmount');
    }

    return undefined;
  };

export const validateEmail = (email: string) => {
  if (
    email
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return undefined;
  }
  return t('registration.enterValidEmail');
};

export const validateCountry = (country: string | undefined) => {
  if (allCountries.find((c) => c.name === country)?.alpha3) {
    return undefined;
  }
  return t('registration.invalidCountry');
};

export const validateForStringEquality = (value: string, values: any) => {
  if (value !== values.newPassword) {
    return t('accountProfileSecurity.passwordsAreNotEqual');
  }
  return undefined;
};

export const validateWithdrawalAccountRequired =
  (type: string | undefined, typeToValidate: string) => (value: string) => {
    if (type !== typeToValidate) {
      if (!value) {
        return t('validation.valueRequired');
      }
      return undefined;
    }
    return undefined;
  };

export const validateWithdrawalCardRequired = (type: string | undefined, typeToValidate: string) => (value: string) => {
  if (type === typeToValidate) {
    if (!value) {
      return t('validation.valueRequired');
    }
    return undefined;
  }
  return undefined;
};

export const validatePassword = (password: string) => {
  const passwordRules = [
    {
      regex: /[0-9]/,
      text: `${t('rulesPassword.min1')}`,
    },
    {
      regex: /[a-z]/,
      text: `${t('rulesPassword.min1Lower')}`,
    },
    {
      regex: /[A-Z]/,
      text: `${t('rulesPassword.min1Upper')}`,
    },
    {
      regex: /^.{8,}$/,
      text: `${t('rulesPassword.min1Characters')}`,
    },
    {
      regex: /^.{0,50}$/,
      text: `${t('rulesPassword.max50Characters')}`,
    },
  ];

  for (let i = 0; i < passwordRules.length; i++) {
    const good = passwordRules[i].regex.test(password.trim());
    if (!good) {
      return passwordRules[i].text;
    }
  }
  return undefined;
};
