import classNames from 'classnames';
import { FC } from 'react';

type SquareSkeletonProps = {
  count: number;
  classNameForSqueare?: string;
};

export const SquareSkeleton: FC<SquareSkeletonProps> = ({ count, classNameForSqueare }) => {
  return (
    <>
      {Array.from(Array(count), (_, index) => (
        <div
          className={classNames(
            classNameForSqueare ? classNameForSqueare : '',
            'animated-background'
          )}
          key={index}></div>
      ))}
    </>
  );
};
