import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';
import { IModalContent } from 'src/pages/Game/hooks';

export const GameNotificationModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModal();

  return (
    <div className="flex flex-col items-center justify-center gap-[20px] p-[30px] w-[300px]">
      {modalProps.map((item: IModalContent, index: number) => {
        return (
          <div key={index} className="mb-[10px]">
            <p className="font-bold text-base w-full text-center text-text_2">{item.content}</p>
            {item.haveAdditionalContent && (
              <p className="text-2xl font-bold text-text_8 w-full text-center">
                {item.additionalContent}
              </p>
            )}
          </div>
        );
      })}
      <Button
        mode="primary"
        additionalClassName="py-[10px] px-[30px] w-fit"
        onClick={() => closeModal?.()}
        title={`${t('gameNotification.close')}`}>
        {t('gameNotification.close')}
      </Button>
    </div>
  );
};
