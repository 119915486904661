import { FullInfoResponse } from 'src/api/profile';

export const shouldFetchDefalutValue = (
  toShow: boolean,
  nameOfTheField: string,
  fullInfo: FullInfoResponse | undefined
) => {
  if (toShow && fullInfo) {
    switch (nameOfTheField) {
      case 'name':
        return true;
      case 'surname':
        return true;
      default:
        return false;
    }
  }
  return false;
};
