import { InputHTMLAttributes } from 'react';

import './styles.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

export const Radio = ({ label, ...inputProps }: Props) => {
  return (
    <div className="flex items-center gap-2 relative">
      <input
        {...inputProps}
        className="absolute w-full h-full opacity-0 cursor-pointer"
        readOnly
        type="radio"
      />
      <label className="text-text_2 text-[14px] font-medium pl-[24px] after:content-[''] after:absolute after:left-0 after:min-w-[16px] after:h-[16px] after:rounded-full after:border-1 after:border-border_16 before:content-[''] after:bg-background_15 before:z-[1] before:w-[8px] before:h-[8px] before:absolute before:rounded-full before:bg-background_31 before:left-[4px] before:opacity-0 before:top-[4px]">
        {label}
      </label>
    </div>
  );
};
