import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Input } from '../Inputs/Input';
import { useTranslation } from 'react-i18next';
import { FullInfoResponse, useGetFullInfo } from 'src/api/profile';
import './styles.css';
import { countriesOptions } from 'src/constants';
import { shouldFetchDefalutValue } from './formGeneratorUtils';
import { PaymentFormField } from 'src/types';
import { DepositTransaction } from 'src/api/deposit';
import { useProcessHelloclever } from './hooks';
import { Button } from '../Buttons/Button';

export const FormGenerator = ({
  formFields,
  checkoutUrl,
  transaction,
  paymentMethodName
}: {
  formFields?: PaymentFormField[];
  checkoutUrl?: string;
  transaction?: DepositTransaction;
  paymentMethodName?: string;
}) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [birthdayValue, setBirthdayValue] = useState('');
  const [visibleBirthdayValue, setVisibleBirthdayValue] = useState('');
  const { fullInfo, isLoading } = useGetFullInfo({
    onSuccess: (response) => {
      setVisibleBirthdayValue(response.data.birthday);
    }
  });
  const [isAccount, setIsAccount] = useState(false);
  const processIfHelloclever = useProcessHelloclever();

  // ----возможно пригодится в будущем
  // const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
  //   e.preventDefault();
  //   const formFields: Record<string, any> = {};
  //   // @ts-ignore можно получать target напрямую
  //   const formData = new FormData(e.target);
  //   for (const pair of formData.entries()) {
  //     formFields[pair[0]] = pair[1];
  //   }
  //   axios.post(data.checkoutUrl, formFields, {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   });
  // };

  const handleBirthdayChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setVisibleBirthdayValue(e.target.value);
  };

  useEffect(() => {
    const date = new Date(visibleBirthdayValue);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    setBirthdayValue(() => formattedDate);
  }, [visibleBirthdayValue]);

  return (
    <form
      className="flex flex-col items-center gap-4 m-2.5"
      method="POST"
      action={checkoutUrl}
      ref={formRef}
      onSubmit={(event) => {
        setIsAccount(true);
        processIfHelloclever({ event, transaction, checkoutUrl, paymentMethodName });
      }}>
      <p className="text-2xl jsm:text-xl font-bold whitespace-normal text-center text-text_7">
        {t('general.paymentDetails')}
      </p>
      {isLoading || !formFields
        ? null
        : formFields.map((field) => {
            if (field.name !== 'birthday' && field.name !== 'country') {
              return (
                <Input
                  required={field.toShow}
                  label={field.title}
                  name={field.name}
                  defaultValue={
                    shouldFetchDefalutValue(field.toShow, field.name, fullInfo)
                      ? (fullInfo![field.name as keyof FullInfoResponse] as string)
                      : field.value
                  }
                  key={field.name}
                  placeholder={field.title ? field.title : 'asd'}
                  overrideContainerClassName={field.toShow ? '' : 'hidden'}
                  labelWithPadding={true}
                />
              );
            }
            if (field.name === 'birthday') {
              return (
                <>
                  <div
                    className={'flex flex-col justify-between h-full items-start gap-2 w-[100%]'}
                    key={field.name}>
                    {field.title ? (
                      <label className={'text-base text-text_2 pl-[20px]'}>{field.title}</label>
                    ) : null}
                    <div className="flex flex-row items-center w-full relative border-solid rounded-[20px] px-[20px] py-[12px] border-1 bg-background_15 border-border_3 formGeneratorDateInput">
                      <input
                        className={
                          'input w-full h-full justify-between placeholder:text-[14px] z-[10000] min-h-[20px]'
                        }
                        value={visibleBirthdayValue}
                        onChange={handleBirthdayChange}
                        required
                        type="date"
                      />
                    </div>
                  </div>
                  <input type="text" className="hidden" name={field.name} value={birthdayValue} />
                </>
              );
            }
            if (field.name === 'country') {
              return (
                <select
                  name={field.name}
                  key={field.name}
                  className="w-full border-solid rounded-[20px] px-[20px] py-[12px] border-1 bg-background_15 border-border_3">
                  {countriesOptions.map((option) => (
                    <option value={option.id} selected={option.id === field.value} key={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              );
            }
            return null;
          })}
      <Button
        type="submit"
        mode="primary"
        className="mt-[20px] !bg-button_primary_background"
        title={`${t('general.confirm')}`}
        loading={isAccount}>
        {t('general.confirm')}
      </Button>
    </form>
  );
};
