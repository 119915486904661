/* eslint-disable camelcase */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetFullBalance, useGetShortBalance } from 'src/api/balance';
import { AllFreeSpins, useGetAllFreeSpins } from 'src/api/bonusesTransaction';
import { GameData, useGetDemoGame, useGetSingleGame } from 'src/api/games';
import { MODAL_NAME, useModal, useModalUtils } from 'src/hooks/useModal';
import { getCurrencyIcon, makeFloat } from 'src/utils';

export function useGetGameData() {
  const { demo, gameId } = useParams();
  const { game: demoGame } = useGetDemoGame(demo ? gameId : undefined);
  const { game } = useGetSingleGame(!demo ? gameId : undefined);
  return (demo ? demoGame : game) as GameData;
}

export interface IModalContent {
  content: string;
  haveAdditionalContent?: boolean;
  additionalContent?: string;
}

export function useGameModals(game: GameData | undefined) {
  const firstRenderRef = useRef(true);
  const { t } = useTranslation();
  const { demo, gameId } = useParams();
  const { openModal } = useModal();
  const { openDepositModal } = useModalUtils();
  const { freeSpins } = useGetAllFreeSpins({});
  const { bonusAccountCasino } = useGetFullBalance();
  const { data: shortBalance, isFetched: shortBalanceReady } = useGetShortBalance();
  const [modalContent, setModalContent] = useState<IModalContent[]>([]);

  const userBonusGames = useMemo(() => {
    return bonusAccountCasino?.wager.bonus
      ? bonusAccountCasino.wager.bonus.wageringGames
      : undefined;
  }, [bonusAccountCasino]);

  const isCurrentGameInUserWagerBous = userBonusGames?.some(
    (game) => game.id.toString() === gameId
  );

  const gameMaxBet = useMemo(() => {
    return game && game.maxBet;
  }, [game]);

  const gameJackpot = useMemo(() => {
    return game && game.jackpot;
  }, [game]);

  const gameWagering = useMemo(() => {
    return game && game.isWagering;
  }, [game]);

  const gameFreeSpins = useMemo(() => {
    return game && game.freeSpins;
  }, [game]);

  const currency = useMemo(() => {
    return game?.currency ?? 'EUR';
  }, [game]);

  const icon = getCurrencyIcon(currency);

  const betAmount = useMemo(() => {
    return freeSpins?.betAmount ? `${icon} ${makeFloat(freeSpins?.betAmount).toFixed(2)}` : '';
  }, [icon, freeSpins?.betAmount]);

  const userFreeSpins = useMemo(() => {
    if (!freeSpins) return undefined;
    if (freeSpins.numberDay && freeSpins.numberDay > 0) {
      return freeSpins.numberDay;
    }
    return freeSpins.number;
  }, [freeSpins]);

  useEffect(() => {
    let openDemoModalTimeout: NodeJS.Timeout | undefined;
    if (demo) {
      openDemoModalTimeout = setTimeout(openDepositModal, 5000);
    }
    return () => clearTimeout(openDemoModalTimeout);
  }, []);

  useEffect(() => {
    if (shortBalanceReady && firstRenderRef.current) {
      if (shortBalance) {
        const openModalInGame =
          shortBalance.balance <= 0
            ? setTimeout(
                () => openModal?.(MODAL_NAME.POPUP_DEPOSIT, {}, undefined, undefined, true),
                5000
              )
            : null;
        firstRenderRef.current = false;

        return () => {
          openModalInGame ? clearTimeout(openModalInGame) : null;
          firstRenderRef.current = true;
        };
      } else {
        const openModalInGame = setTimeout(
          () => openModal?.(MODAL_NAME.POPUP_DEPOSIT, {}, undefined, undefined, true),
          5000
        );
        firstRenderRef.current = false;

        return () => {
          openModalInGame ? clearTimeout(openModalInGame) : null;
          firstRenderRef.current = true;
        };
      }
    }
  }, [shortBalance, shortBalanceReady]);

  useEffect(() => {
    if (gameWagering && gameMaxBet && gameMaxBet > 0) {
      setModalContent((prev) => [
        ...prev,
        {
          content: `${t('gameNotification.maxBet')}`,
          haveAdditionalContent: true,
          additionalContent: `${icon}${makeFloat(gameMaxBet).toFixed(2)}`
        }
      ]);
    }
  }, [gameMaxBet, gameWagering, icon]);

  useEffect(() => {
    const shouldShowFreespinsModal = figureShowingFreespinsModalByFreespinsConditions(
      freeSpins,
      userFreeSpins,
      gameFreeSpins
    );
    if (shouldShowFreespinsModal) {
      setModalContent((prev) => [
        ...prev,
        {
          content: `${t('gameNotification.freeSpins')}`,
          haveAdditionalContent: true,
          additionalContent: betAmount
        }
      ]);
    }
  }, [betAmount, userFreeSpins, freeSpins, t, gameFreeSpins]);

  useEffect(() => {
    if (gameJackpot) {
      setModalContent((prev) => [
        ...prev,
        {
          content: `${t('gameNotification.jackpot')}`,
          haveAdditionalContent: true,
          additionalContent: `${icon}${makeFloat(gameJackpot.minBet).toFixed(2)}`
        }
      ]);
    }
  }, [gameJackpot, icon]);

  useEffect(() => {
    if (gameWagering || isCurrentGameInUserWagerBous) {
      setModalContent((prev) => [...prev, { content: `${t('gameNotification.wagering')}` }]);
    }
  }, [gameWagering, isCurrentGameInUserWagerBous]);

  useEffect(() => {
    if (modalContent.length) {
      openModal?.(MODAL_NAME.GAME_NOTIFICATION, modalContent, undefined, undefined, true);
    }
  }, [modalContent]);
}

function figureShowingFreespinsModalByFreespinsConditions(
  freeSpins: AllFreeSpins | undefined,
  userFreeSpins: number | undefined,
  gameFreeSpins: boolean | undefined
) {
  if (!userFreeSpins || !freeSpins || userFreeSpins <= 0 || !gameFreeSpins) return false;
  if (freeSpins.dayLimit === 0 || (freeSpins?.dayLimit > 0 && freeSpins?.numberDay > 0)) {
    return true;
  }
  return false;
}
