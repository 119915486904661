import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useGetSinglePage } from 'src/api/content';
import { GreyControlArrow } from '../svg/GreyControlArrow';
import { useLocation } from 'react-router-dom';

export const SeoBlock = ({ additionalClassName }: { additionalClassName?: string }) => {
  const location = useLocation();

  const textElement = useRef<HTMLDivElement>(null);
  const [isBigText, setIsBigText] = useState<boolean>(false);
  const [extended, setExtended] = useState<boolean>(false);

  const { data } = useGetSinglePage(
    `${
      location.pathname === '/' || location.pathname.includes('account')
        ? 'seo_home'
        : `seo_${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`
    }`
  );

  useEffect(() => {
    if (!textElement.current) {
      return;
    }

    const sizes = textElement.current.getBoundingClientRect();

    if (sizes.height > 100) {
      setIsBigText(true);
    }
  }, []);

  if (!data) return null;

  return (
    <div
      className={classNames(
        additionalClassName,
        'flex flex-col justify-center items-center gap-y-[10px] py-[30px]'
      )}>
      <p
        className={classNames(
          'w-full text-[12px] text-text_18 overflow-hidden transition-[max-height] ease-in duration-300',
          extended ? '' : 'max-h-[100px]'
        )}
        dangerouslySetInnerHTML={{ __html: data?.content }}
        ref={textElement}
      />

      {isBigText && (
        <button
          className={classNames(
            extended ? 'rotate-180' : '',
            'transition-transform ease-in duration-300'
          )}
          onClick={() => setExtended(!extended)}
          type="button">
          <GreyControlArrow />
        </button>
      )}
    </div>
  );
};
