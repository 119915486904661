import {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
  RefObject,
  useEffect
} from 'react';

function fullScreen(element: HTMLDivElement | null) {
  // @ts-ignore
  if(element?.requestFullscreen) {
    // @ts-ignore
    element.requestFullscreen();
    // @ts-ignore
  } else if(element?.webkitrequestFullscreen) {
    // @ts-ignore
    element.webkitRequestFullscreen();
    // @ts-ignore
  } else if(element?.mozRequestFullscreen) {
    // @ts-ignore
    element.mozRequestFullScreen();
  }
}

function fullScreenCancel() {
  // @ts-ignore
  if(document.exitFullscreen) {
    // @ts-ignore
    document.exitFullscreen();
    // @ts-ignore
  } else if(document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if(document.mozExitFullscreen) {
    // @ts-ignore
    document.mozExitFullscreen();
  }
}

export const FullScreenContext = createContext<{
  deactivateFullScreen?: () => void;
  isFullscreen?: boolean;
  refFullscreen?: RefObject<HTMLDivElement>;
  onFullscreenClick?: () => void;
}>({});

export const useInitFullScreen = () => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const refFullscreen = useRef<HTMLDivElement>(null);

  const deactivateFullScreen = useCallback(() => {
    fullScreenCancel();
    // setIsFullscreen(false);
  }, []);

  const onFullscreenClick = useCallback(() => {
    fullScreen(refFullscreen.current)
    // setIsFullscreen(true);
  }, []);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  return {
    deactivateFullScreen,
    isFullscreen,
    refFullscreen,
    onFullscreenClick
  };
};

export const useFullScreen = () => {
  return useContext(FullScreenContext);
};
