import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { BonusesResponse, ShortBonus } from 'src/api/bonuses';
import { useGetAllBonusesTransactions } from 'src/api/bonusesTransaction';
import gift from 'src/assets/sidebar-icons/gift_2.png';
import { Info } from 'src/components/svg';
import { useGetDayOfWeek } from 'src/hooks/useGetDayOfWeek';
import { useModal } from 'src/hooks/useModal';
import { BalanceFormatted } from 'src/pages/AccountWallet/Deposit';
import { makeFloat, prepareBonuses } from 'src/utils';

import './DepositBonuses.css';

type Props = {
  bonuses: BonusesResponse;
  clickedBonusId?: number;
  onBonusClick?: (bonus: ShortBonus) => void;
  setClickedBonusId?: (id: number | undefined) => void;
  shortBalance: BalanceFormatted;
};

export const DepositBonuses = ({ bonuses, clickedBonusId, onBonusClick, setClickedBonusId, shortBalance }: Props) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { numberDayOfWeek } = useGetDayOfWeek();

  const { allBonusesTransactions } = useGetAllBonusesTransactions({
    bonus_type: 0,
    status: '1,5,6,11',
  });

  const filteredBonuses = useMemo(() => {
    const preparedBonuses = prepareBonuses(bonuses, allBonusesTransactions?.bonusTransactions.length);

    return preparedBonuses.filter(
      (bonus) => bonus.activeDayOfWeek.some((day) => day === numberDayOfWeek) || bonus.activeDayOfWeek.length === 0,
    );
  }, [bonuses, allBonusesTransactions, numberDayOfWeek]);

  if (filteredBonuses.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-[22px]">
      <div className="flex w-full items-center justify-start gap-x-[20px]">
        <p className="gradient-text bg-orange-gradient-2 text-center font-bold uppercase">{t('depositTab.Bonus')}</p>

        <img
          alt="gift"
          className="max-w-[18px]"
          src={gift}
        />
      </div>

      <div className="w-full overflow-auto">
        <div className="flex gap-5 overflow-x-auto text-text_2">
          {filteredBonuses.map((bonus) => (
            <div
              className={classNames(
                bonus.id === clickedBonusId
                  ? 'border-border_19 bg-orange-gradient'
                  : 'border-border_6 hover:border-border_21',
                'group flex min-w-[214px] cursor-pointer items-center gap-[10px] rounded-lg border px-[10px] py-[12px] lg:px-[24px]',
              )}
              key={bonus.id}
              onClick={() => {
                onBonusClick?.(bonus);
                setClickedBonusId?.(clickedBonusId === bonus.id ? undefined : bonus.id);
              }}
            >
              <div
                className={classNames(
                  bonus.id === clickedBonusId
                    ? 'after:absolute after:left-1/2 after:top-1/2 after:h-[8px] after:w-[8px] after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:bg-background_15'
                    : 'group-hover:border-border_21',
                  'relative h-[16px] max-h-[16px] w-[16px] min-w-[16px] rounded-full border border-border_6',
                )}
              />

              <div className="flex flex-col gap-y-[6px]">
                <div className="flex items-center justify-between">
                  <p className="font-bold">
                    {bonus.percent}% {bonus.freeSpins ? `+ ${bonus.freeSpins} FS` : null}
                  </p>

                  <NavLink
                    aria-label={`${bonus.name}`}
                    onClick={() => {
                      closeModal?.();
                    }}
                    to={`/bonuses/${bonus.id}`}
                  >
                    <Info fill={bonus.id === clickedBonusId ? 'white' : 'transparent'} />
                  </NavLink>
                </div>

                <p className="text-[12px]">
                  {t('accountWalletDeposite.upTo')} {shortBalance.currencySign}
                  {makeFloat(bonus.maxPayout?.[shortBalance.currency])?.toFixed(0)}{' '}
                  {t('accountWalletDeposite.forOneDepositFrom')} {shortBalance.currencySign}
                  {makeFloat(bonus.minDepositAmount?.[shortBalance.currency])?.toFixed(0)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
