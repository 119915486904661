import { apiProvider } from '..';
import {
  ActiveUserTournamentsResponse,
  ApplyTournamentResponse,
  ProviderTournamentsResponse,
  SingleProviderTournament,
  SingleProviderTournamentBody,
  SingleTournamentBody,
  SingleTournamentResponse,
  TournamentsBody,
  TournamentsProviderBody,
  TournamentsResponse,
  UserTournamentProgressResponse
} from './tournaments.types';
import { SCREEN_MODE } from 'src/constants';

// TODO language
export const tournaments = ({ currency = 'EUR', ...params }: TournamentsBody) => {
  return apiProvider.request<TournamentsResponse>({
    method: 'get',
    url: '/tournaments',
    params: { currency, ...params }
  });
};

// TODO language
export const providerTournaments = ({
  tournaments_page_code = 'tournaments',
  currency = 'EUR',
  platform_id = SCREEN_MODE,
  ...params
}: TournamentsProviderBody) => {
  return apiProvider.request<ProviderTournamentsResponse>({
    method: 'get',
    url: '/tournaments/provider-tournaments',
    params: { tournaments_page_code, platform_id, currency, ...params }
  });
};

// TODO language,platform_id
export const singleProviderTournament = ({
  currency = 'EUR',
  language = 'en',
  tournamentCode
}: SingleProviderTournamentBody) => {
  return apiProvider.request<SingleProviderTournament>({
    method: 'get',
    url: `/tournaments/provider-tournaments/${tournamentCode}`,
    params: { currency, language }
  });
};

// TODO language
export const singleTournament = (
  id: number,
  { currency = 'EUR', ...params }: SingleTournamentBody
) => {
  return apiProvider.request<SingleTournamentResponse>({
    method: 'get',
    url: `/tournaments/${id}`,
    params: { currency, ...params }
  });
};

// TODO language X-Authorization
export const tournamentWithUsersProgress = (id: number) => {
  return apiProvider.request({
    method: 'get',
    url: `/tournaments/tournament-with-users-progress/${id}`
  });
};

// TODO language X-Authorization
export const activeUserTournaments = () => {
  return apiProvider.request<ActiveUserTournamentsResponse>({
    method: 'get',
    url: '/tournaments/active-user-tournaments'
  });
};

// TODO language X-Authorization
export const userTournamentProgress = (id: number) => {
  return apiProvider.request<UserTournamentProgressResponse>({
    method: 'get',
    url: '/tournaments/user-tournament-progress',
    params: { tournament_id: id }
  });
};

// TODO X-Authorization,language
export const applyTournament = (id: number) => {
  return apiProvider.request<ApplyTournamentResponse>({
    method: 'post',
    url: '/tournaments/apply-tournament',
    data: { tournament_id: id }
  });
};
