import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GameCategories } from '../../constants';
import './styles.css';
import noGamesInCategory from '../../assets/images/noSearchGames.webp';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { GamesControlPanel } from 'src/components/GamesControlPanel';
import { Wrapper } from 'src/components/Wrapper/Wrapper';

export const Games = () => {
  const location = useLocation();
  const { openModal } = useModal();
  const { categoryId } = useParams();
  const { isAuthenticated } = useApi();

  useEffect(() => {
    if (location.pathname.includes('jackpot') && !isAuthenticated) {
      openModal?.(MODAL_NAME.SIGNUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameCategoryName = useMemo(() => {
    return categoryId && GameCategories[categoryId].name;
  }, [categoryId]);

  const gameCategoryValue = useMemo(() => {
    return categoryId ? GameCategories[categoryId].value : undefined;
  }, [categoryId]);

  const gameCategoryParam = useMemo(() => {
    if (!gameCategoryName || gameCategoryValue === undefined) return {};
    return { [gameCategoryName]: gameCategoryValue };
  }, [gameCategoryName, gameCategoryValue]);

  return (
    <Wrapper>
      <GamesControlPanel
        gameCategoryParam={gameCategoryParam}
        target={categoryId}
        image={noGamesInCategory}
      />
    </Wrapper>
  );
};
