import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScroll } from 'src/hooks/useScroll';

import { useGetGamesPaginated } from '../../api/games/games.hooks';
import NoGames from '../../assets/images/noSearchGames.webp';
import { useDebounce } from '../../hooks/useDebounce';
import { useDrawer } from '../../hooks/useDrawer';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { GameCard } from '../GameCard';
import { InputSearch } from '../Inputs/InputSearch';
import { CloseSearchTopbar, SearchIcon } from '../svg';

export const TopbarSearch = () => {
  const { closeIsTopSearchDrawerOpenDrawer, isTopSearchDrawerOpen } = useDrawer();
  const [searchValue, setSearchValue] = useState<string>('');
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const handleChangeGames = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const search = useDebounce<string>(searchValue, 500);

  useEffect(() => {
    if (search) {
      sendGoogleAnalyticsEvent('search_used');
    }
  }, [search]);

  useEffect(() => {
    if (isTopSearchDrawerOpen) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isTopSearchDrawerOpen]);

  const { t } = useTranslation();

  const { allGames, fetchNextPage, hasNextPage, isFetched } = useGetGamesPaginated(
    { name: searchValue, per_page: 9 },
    'TopbarSearch',
    Boolean(searchValue && searchValue.length <= 2 ? undefined : searchValue),
  );

  useScroll({
    callback: () => {
      if (hasNextPage && searchValue.length >= 3) {
        fetchNextPage();
      }
    },
    childRef,
    parentRef,
  });

  return (
    <div
      className="flex flex-col items-center self-center fixed top-0 bottom-0 z-[700] transition-all w-full h-screen bg-background_1"
      ref={parentRef}
      style={{
        transform: isTopSearchDrawerOpen ? 'translateY(0px)' : 'translateY(-100%)',
      }}
    >
      <CloseSearchTopbar
        closeTopDrawer={() => {
          closeIsTopSearchDrawerOpenDrawer?.();
          setSearchValue('');
        }}
      />
      <div className="mx-[20px] w-[90%]">
        <InputSearch
          className="flex flex-row items-center bg-[transparent] w-full h-auto py-[5px] border-b-2 border-b-border_6 justify-around"
          classNameInput="search-input text-[14px] text-text_2 font-medium bg-[transparent] w-full px-2.5"
          leftIcon={<SearchIcon className="r_sm:w-[20px] r_sm:h-[20px]" />}
          onChange={handleChangeGames}
          placeholder={t('general.searchGamePlaceholder')}
          value={searchValue}
        />
      </div>
      {allGames.length > 0 && (
        <p className="pt-[20px] pb-2.5 font-bold text-center text-[20px] text-text_2">{t('general.searchResults')}</p>
      )}
      {allGames.length === 0 && isTopSearchDrawerOpen && searchValue.length !== 0 && isFetched ? (
        <div className="flex flex-col w-full items-center justify-center text-center px-[15px] pt-[15px]">
          <p className="text-text_2 m-auto">{t('gamesPage.noGamesInCategory')}</p>
          <img
            alt="no games"
            className="w-auto"
            src={NoGames}
          />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-2.5 overflow-auto px-2.5 w-full">
            {allGames?.map((game) => (
              <GameCard
                game={game}
                key={game.id}
              />
            ))}
            <div
              className="h-0 mb-[70px]"
              ref={childRef}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};
