import { Loyalty } from 'src/components/VIP/Loyalty';
import { LoyaltyVip } from 'src/components/VIP/LoyaltyVip';
import { useGetAllUserLevels } from '../../api/content/content.hooks';
import { Guaranteed, Levels } from '../../components/VIP';
import './styles.css';
import { PageTitle } from 'src/components';
import { useTranslation } from 'react-i18next';

export const VIP = () => {
  const { allLevels } = useGetAllUserLevels({ loyalty_page_code: 'loyalty' });
  const { t } = useTranslation();

  return (
    <div className="flex flex-col vip-page r_sm:bg-none w-full">
      <PageTitle title={t('pagesTitle.loyalty')} />
      <Guaranteed />
      <Loyalty />
      <Levels allLevels={allLevels} />
      <LoyaltyVip />
    </div>
  );
};
