import {
  useGetSingleBonusTransaction,
  useUpdateBonusStatus
} from 'src/api/bonusesTransaction/bonusesTransaction.hooks';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';
import { CONTENT_URL } from '../../api';
import gift from '../../assets/images/gift.webp';
import { getCurrencyIcon, makeFloat } from 'src/utils';
import { useGetFullBalance } from 'src/api/balance';
import classNames from 'classnames';

export const PopUpPromoCode = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModal();
  const { bonusTransaction } = useGetSingleBonusTransaction(modalProps.bonusId);
  const { mutateAsync: updateBonusStatus } = useUpdateBonusStatus();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();

  const currency = useMemo(() => {
    return fullBalance?.currency ?? 'EUR';
  }, [fullBalance]);
  const icon = getCurrencyIcon(currency);

  const text = useMemo(() => {
    if (bonusAccountCasino?.balance === 0) {
      return t('promoCode.textPopUpFirst');
    } else if (bonusAccountCasino && bonusAccountCasino?.balance > 0) {
      return `${t('promoCode.textPopUpSecond')} ${icon}${makeFloat(
        bonusAccountCasino?.balance
      ).toFixed(2)}`;
    }
  }, [bonusAccountCasino, icon, t]);

  const handleActiveBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps.bonusId, status: 3 });
    closeModal?.();
  }, [closeModal, modalProps.bonusId, updateBonusStatus]);

  const handleCancelBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps.bonusId, status: 4 });
    closeModal?.();
  }, [closeModal, modalProps.bonusId, updateBonusStatus]);

  const handleSendQueueBonus = useCallback(async () => {
    await updateBonusStatus({ bonus_id: modalProps.bonusId, status: 1 });
    closeModal?.();
  }, [closeModal, modalProps.bonusId, updateBonusStatus]);

  return (
    <div
      className={classNames(
        'flex flex-col max-w-[400px]',
        bonusTransaction?.bonus?.imageBanner ? undefined : 'bg-background_1'
      )}>
      {bonusTransaction?.bonus?.imageBanner ? (
        <img
          src={`${CONTENT_URL}${bonusTransaction?.bonus?.imageBanner}`}
          alt={bonusTransaction?.bonus.name}
          className="w-full z-0 relative translate-y-[5%]"
        />
      ) : null}
      <div
        className={classNames(
          'flex flex-col gap-5 items-center justify-center py-7 px-5  relative',
          bonusTransaction?.bonus?.imageBanner ? 'bg-background_1' : undefined
        )}>
        {bonusTransaction?.bonus?.imageBanner ? null : (
          <img src={gift} alt="gift" className="h-auto w-auto" />
        )}
        <p className="text-center text-base font-bold px-[15px] text-text_2">{text}</p>
        <div className="flex flex-wrap gap-5 justify-center max-w-[300px]">
          <div className="w-full flex justify-center items-center">
            <Button
              mode="primary"
              additionalClassName="w-full max-w-[300px]"
              title={`${t('promoCode.activate')}`}
              label={`${t('promoCode.activate')}`}
              onClick={handleActiveBonus}
            />
          </div>
          <div className="flex w-full gap-2.5 justify-between items-center">
            <Button
              title={`${t('general.cancel')}`}
              mode="secondary"
              label={`${t('general.cancel')}`}
              onClick={handleCancelBonus}
            />
            <Button
              title={`${t('promoCode.toQueue')}`}
              mode="primary"
              label={`${t('promoCode.toQueue')}`}
              onClick={handleSendQueueBonus}
              additionalClassName="container-with-wide-content"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
