type SearchIconProvidersProps = {
  className?: string;
};

export const NavbarDepositIcon = ({ className }: SearchIconProvidersProps) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <g filter="url(#filter0_d_12055_103452)">
        <circle cx="29" cy="25" r="25" fill="#E83E44" />
      </g>
      <path
        d="M16.8662 18.0668V33.6668C16.8662 34.1265 17.0488 34.5674 17.3739 34.8924C17.699 35.2175 18.1398 35.4001 18.5995 35.4001H39.3995C39.8593 35.4001 40.3001 35.2175 40.6252 34.8924C40.9503 34.5674 41.1329 34.1265 41.1329 33.6668V19.8001C41.1329 19.3404 40.9503 18.8995 40.6252 18.5744C40.3001 18.2494 39.8593 18.0668 39.3995 18.0668H21.1995M16.8662 18.0668V16.3334C16.8662 15.8737 17.0488 15.4328 17.3739 15.1078C17.699 14.7827 18.1398 14.6001 18.5995 14.6001H32.4662C32.9259 14.6001 33.3668 14.7827 33.6919 15.1078C34.0169 15.4328 34.1995 15.8737 34.1995 16.3334V18.0668H21.1995M16.8662 18.0668H21.1995M31.5995 28.4668H36.7995"
        stroke="white"
        strokeWidth="1.73333"
      />
      <defs>
        <filter
          id="filter0_d_12055_103452"
          x="0"
          y="0"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12055_103452"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12055_103452"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

type SearchIconThinProps = {
  classNameIcon?: string;
};

export const SearchIconThin = ({ classNameIcon }: SearchIconThinProps) => {
  return (
    <svg
      width="25"
      height="25"
      className={classNameIcon}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23721 2.07154C5.65836 1.12195 7.32919 0.615112 9.03839 0.615112H9.03844C11.3304 0.615258 13.5284 1.52578 15.149 3.14641C16.7696 4.76704 17.6802 6.96505 17.6803 9.25697V9.25702C17.6803 10.9662 17.1735 12.637 16.2239 14.0582C16.0572 14.3076 15.8782 14.5474 15.6878 14.7767L20.9322 20.0211C21.2442 20.3331 21.2442 20.8389 20.9322 21.1509C20.6203 21.4628 20.1145 21.4628 19.8025 21.1509L14.5581 15.9065C13.8952 16.4568 13.1501 16.9078 12.3455 17.2411C10.7664 17.8952 9.02881 18.0663 7.35244 17.7329C5.67608 17.3994 4.13624 16.5764 2.92764 15.3678C1.71905 14.1592 0.89599 12.6193 0.56254 10.943C0.22909 9.2666 0.400229 7.529 1.05431 5.9499C1.7084 4.3708 2.81605 3.02112 4.23721 2.07154ZM9.03834 2.21278C7.64514 2.21279 6.28323 2.62593 5.12483 3.39995C3.96641 4.17398 3.06353 5.27414 2.53037 6.56131C1.99721 7.84847 1.85771 9.26483 2.12951 10.6313C2.40132 11.9977 3.07221 13.2529 4.05737 14.238C5.04252 15.2232 6.29768 15.8941 7.66413 16.1659C9.03058 16.4377 10.4469 16.2982 11.7341 15.765C13.0213 15.2319 14.1214 14.329 14.8955 13.1706C15.6695 12.0122 16.0826 10.6503 16.0826 9.25707M9.03839 2.21278C10.9066 2.21292 12.6982 2.95512 14.0193 4.27614C15.3403 5.59716 16.0825 7.38881 16.0826 9.25702"
        fill="#FBC211"
      />
    </svg>
  );
};

type SearchIconProps = {
  className?: string;
};

export const SearchIcon = ({ className }: SearchIconProps) => {
  return (
    <svg
      width="15"
      height="15"
      className={className}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.65995 2.26087C4.49388 1.70366 5.4743 1.40625 6.47725 1.40625H6.47728C7.82216 1.40634 9.11193 1.94062 10.0629 2.8916C11.0139 3.84257 11.5482 5.13234 11.5482 6.47722V6.47725C11.5482 7.48019 11.2508 8.46062 10.6936 9.29454C10.5958 9.4409 10.4908 9.58159 10.3791 9.71618L13.4565 12.7935C13.6395 12.9766 13.6395 13.2734 13.4565 13.4565C13.2734 13.6395 12.9766 13.6395 12.7935 13.4565L9.71618 10.3791C9.32718 10.702 8.88998 10.9667 8.41783 11.1622C7.49123 11.546 6.47162 11.6465 5.48795 11.4508C4.50427 11.2551 3.6007 10.7722 2.89151 10.063C2.18232 9.35379 1.69936 8.45023 1.50369 7.46655C1.30802 6.48287 1.40845 5.46326 1.79226 4.53666C2.17607 3.61006 2.82603 2.81808 3.65995 2.26087ZM6.47722 2.34375C5.6597 2.34376 4.86054 2.58618 4.1808 3.04037C3.50105 3.49456 2.97125 4.14013 2.6584 4.89543C2.34554 5.65072 2.26368 6.48183 2.42318 7.28365C2.58267 8.08547 2.97635 8.82199 3.55443 9.40007C4.13251 9.97815 4.86902 10.3718 5.67084 10.5313C6.47266 10.6908 7.30377 10.609 8.05907 10.2961C8.81437 9.98324 9.45993 9.45344 9.91412 8.77369C10.3683 8.09395 10.6107 7.29479 10.6107 6.47728M6.47725 2.34375C7.5735 2.34383 8.62482 2.77934 9.39998 3.55451C10.1751 4.32967 10.6107 5.381 10.6107 6.47725"
        fill="white"
      />
    </svg>
  );
};

type PropsSearch = {
  onClick?: () => void;
};

export const SearchDelete = ({ onClick }: PropsSearch) => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path d="M9 0.5L1 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 0.5L9 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
