import { useTranslation } from 'react-i18next';
import { useForm, FieldType } from '../../../hooks/useForm/index';

export const useTransactionHistory = (sortByOptions?: any[], typeOptions?: any[]) => {
  const { t } = useTranslation();
  return useForm<{
    dateFrom: FieldType.Date;
    dateTo: FieldType.Date;
    amountFrom: FieldType.Number;
    amountTo: FieldType.Number;
    sortBy: FieldType.Dropdown;
    type: FieldType.Multiselect;
  }>({
    dateFrom: {
      fieldType: FieldType.Date
    },
    dateTo: {
      fieldType: FieldType.Date
    },
    amountFrom: {
      placeholder: `${t('transactions.amountFrom')}`,
      fieldType: FieldType.Number
    },
    amountTo: {
      placeholder: `${t('transactions.amountTo')}`,
      fieldType: FieldType.Number
    },
    sortBy: {
      placeholder: `${t('transactions.sortBy')}`,
      fieldType: FieldType.Dropdown,
      options: sortByOptions
    },
    type: {
      placeholder: `${t('transactions.type')}`,
      fieldType: FieldType.Multiselect,
      options: typeOptions
    }
  });
};
