import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getSummOfPossiblyUndefinedNums, makeFloat } from 'src/utils';
import { useGetFullBalance, useGetShortBalance } from '../../api/balance/balance.hooks';
import { BalanceCard } from '../../components/BalanceCard/BalanceCard';

export const Balance = () => {
  const { t } = useTranslation();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();
  const { data: currentBalance } = useGetShortBalance();
  const cards = useMemo(() => {
    const result =
      fullBalance?.balance &&
      Object.keys(fullBalance?.balance).map((key) => ({
        currency: key,
        value:
          key === currentBalance?.currency
            ? getSummOfPossiblyUndefinedNums(
                fullBalance?.balance[key as keyof (typeof fullBalance)['balance']],
                bonusAccountCasino?.balance
              )
            : fullBalance?.balance[key as keyof (typeof fullBalance)['balance']],
        withdrawValue: makeFloat(
          fullBalance?.balance[key as keyof (typeof fullBalance)['balance']]
        ).toFixed(2)
      }));

    const sortedResult: Array<{ currency: string; value: any; withdrawValue: string }> = [];

    if (result) {
      for (const i of result) {
        if (i.currency === currentBalance?.currency) {
          sortedResult.unshift(i);
        } else sortedResult.push(i);
      }
    }

    return sortedResult;
  }, [currentBalance?.currency, fullBalance?.balance]);

  return (
    <div className="flex flex-col gap-5">
      <p className="font-medium text-[14px] text-text_2">{t('accountWalletBalance.description')}</p>
      <div className="grid grid-cols-balanceCard gap-12 r_laptop:flex r_laptop:flex-col r_laptop:gap-7 px-4 lg:px-0">
        {cards.map((card) => (
          <BalanceCard key={card.currency} card={card} />
        ))}
      </div>
    </div>
  );
};
