import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGetFullBalance } from 'src/api/balance';
import { useDrawer } from 'src/hooks/useDrawer';
import { getCurrencyIcon, getSummOfPossiblyUndefinedNums, makeFloat } from 'src/utils';

import { useApi } from '../../hooks/useApi';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Button } from '../Buttons/Button';
import { HeaderBurgerIcon } from '../svg/HeaderBurgerIcon';
import { Logo } from './Logo';

import './styles.css';

export const HeaderInner = () => {
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const { t } = useTranslation();
  const { bonusAccountCasino, fullBalance } = useGetFullBalance();
  const location = useLocation();
  const { _openMainSidebar, closeBottomDrawer, openLeftDrawer } = useDrawer();

  const isAccountLayout = location.pathname.includes('account');

  const currency = useMemo(() => {
    return fullBalance?.currency ?? 'EUR';
  }, [fullBalance]);

  const balance = useMemo(() => {
    return `${getCurrencyIcon(fullBalance?.currency ?? 'EUR')}${makeFloat(
      getSummOfPossiblyUndefinedNums(
        fullBalance?.balance[currency as keyof typeof fullBalance.balance],
        bonusAccountCasino?.balance,
      ),
    ).toFixed(2)}`;
  }, [fullBalance, currency, bonusAccountCasino?.balance]);

  return (
    <header className="header-inner">
      <div className="flex justify-center items-center">
        <div
          className="p-2 flex justify-center items-center"
          onClick={() => {
            if (isAccountLayout) {
              openLeftDrawer?.();
            } else {
              sendGoogleAnalyticsEvent('burger_menu_opened');
              _openMainSidebar?.();
            }
            closeBottomDrawer?.();
          }}
        >
          <HeaderBurgerIcon />
        </div>
        <Logo />
      </div>
      {isAuthenticated ? (
        <div className="flex flex-row lg:w-[70%] w-[50%] items-center gap-4 justify-end ml-[19%] r_sm:ml-[10%] pl-[0px]">
          <Button
            className="w-[40%] text-text_1 r_sm:w-auto r_sm:text-sm r_sm:h-[35px] r_gf:w-auto max-w-[190px]"
            label={`${t('general.deposit')} ${balance}`}
            mode="primary"
            onClick={() => {
              openModal?.(MODAL_NAME.POPUP_DEPOSIT);
              closeBottomDrawer?.();
            }}
            title={`${t('general.deposit')} ${balance}`}
          />
        </div>
      ) : (
        <div className="flex flex-row w-[70%] justify-end gap-x-2.5">
          <Button
            className="container-with-wide-content"
            label={`${t('general.logIn')}`}
            mode="secondary"
            onClick={() => {
              openModal?.(MODAL_NAME.LOGIN);
              closeBottomDrawer?.();
            }}
            title={`${t('general.logIn')}`}
          />
          <Button
            className="container-with-wide-content"
            label={`${t('general.signUp')}`}
            mode="primary"
            onClick={() => {
              openModal?.(MODAL_NAME.SIGNUP);
              closeBottomDrawer?.();
            }}
            title={`${t('general.signUp')}`}
          ></Button>
        </div>
      )}
    </header>
  );
};
