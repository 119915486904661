import { SUPPORTED_LANGUAGES } from 'src/constants';

import { DeLang, EnLang, PlLang, PtLang } from '../svg';

export type LangOptions = Array<keyof typeof languageOptions>;

export const languageOptions: Record<keyof typeof SUPPORTED_LANGUAGES, any> = {
  // },
  de: {
    code: 'de-DE',
    currency: 'EUR',
    icon: DeLang,
    labelCode: 'de',
    labelName: 'German',
    title: 'de',
  },
  // 'en-AU': {
  //   icon: AuLang,
  //   labelCode: 'en',
  //   labelName: 'English-AU',
  //   currency: 'EUR',
  //   code: 'en-AU',
  //   title: 'au'
  // },
  // 'en-NZ': {
  //   icon: NzLang,
  //   labelCode: 'en',
  //   labelName: 'English-NZ',
  //   currency: 'EUR',
  //   code: 'en-NZ',
  //   title: 'nz'
  // },
  // 'en-CA': {
  //   icon: CaLang,
  //   labelCode: 'en',
  //   labelName: 'English-CA',
  //   currency: 'EUR',
  //   code: 'en-CA',
  //   title: 'ca'
  // },
  // nl: {
  //   icon: NlLang,
  //   labelCode: 'nl',
  //   labelName: 'European',
  //   currency: 'EUR',
  //   code: 'nl-NL'
  // },
  // se: {
  //   icon: SeLang,
  //   labelCode: 'se',
  //   labelName: 'Sweden',
  //   currency: 'EUR',
  //   code: 'se-SE'
  // },
  // es: {
  //   icon: EsLang,
  //   labelCode: 'es',
  //   labelName: 'Spanish',
  //   currency: 'EUR',
  //   code: 'es-ES',
  //   title: 'es'
  // },
  // fr: {
  //   icon: FrLang,
  //   labelCode: 'fr',
  //   labelName: 'French',
  //   currency: 'EUR',
  //   code: 'fr-FR',
  //   title: 'fr'
  // },
  // el: {
  //   icon: GrLang,
  //   labelCode: 'el',
  //   labelName: 'Greece',
  //   currency: 'EUR',
  //   code: 'el-EL',
  //   title: 'gr'
  en: {
    code: 'en-GB',
    currency: 'EUR',
    icon: EnLang,
    labelCode: 'en',
    labelName: 'English',
    title: 'en',
  },
  pl: {
    code: 'pl-PL',
    currency: 'EUR',
    icon: PlLang,
    labelCode: 'pl',
    labelName: 'Poland',
    title: 'pl',
  },
  pt: {
    code: 'pt-PT',
    currency: 'EUR',
    icon: PtLang,
    labelCode: 'pt',
    labelName: 'Portuguese',
    title: 'pt',
  },
};

export const arrayLanguages = Object.values(languageOptions);
