import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CONTENT_URL } from 'src/api';
import { PageTitle } from 'src/components';
import { GameLoading } from 'src/components/GameCard/GameLoading';
import { Responsive } from 'src/components/Responsive';
import { Skeleton } from 'src/components/Skeleton';
import { useGetSingleProviderTournament } from '../../api/tournaments/tournaments.hooks';
import { Button } from '../../components/Buttons/Button';
import { GameCard } from '../../components/GameCard';
import { useApi } from '../../hooks/useApi';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { useGetAllProviders } from 'src/api/content';
import { Wrapper } from 'src/components/Wrapper/Wrapper';
import diamondIcon from 'src/assets/sidebar-icons/diamond.png';
import { useScroll } from 'src/hooks/useScroll';

const COUNT_GAME = 12;

export const ProviderTournament = () => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [showAllGames] = useState<boolean>(false);
  const [bonusGamesPage, setBonusGamesPage] = useState<number>(1);
  const { isAuthenticated } = useApi();
  const params = useParams();
  const { allProviders } = useGetAllProviders({ per_page: 9999 });

  const id = useMemo(() => {
    return params.tournamentCode || undefined;
  }, [params]);

  const { tournament, tournamentGamesCount, isFetching } = useGetSingleProviderTournament({
    tournamentCode: id || '',
    currency: 'EUR'
  });

  const handleShowMoreGames = useMemo(() => {
    if (showAllGames) {
      return tournament?.games.map((game) => {
        return {
          ...game,
          providerName: allProviders.find((el) => el.id === game.providerId)?.name
        };
      });
    }
    const slicedGames = tournament?.games.slice(0, COUNT_GAME * bonusGamesPage);
    return slicedGames?.map((game) => {
      return {
        ...game,
        providerName: allProviders.find((el) => el.id === game.providerId)?.name
      };
    });
  }, [bonusGamesPage, showAllGames, tournament?.games, allProviders]);

  useScroll({
    parentRef,
    childRef,
    callback: () => {
      if (handleShowMoreGames?.length !== tournamentGamesCount) {
        setBonusGamesPage(bonusGamesPage + 1);
      }
    }
  });

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <PageTitle title={t('tournaments.title')} additionalClassName="w-full" />
      <Wrapper>
        <div
          ref={parentRef}
          className="px-2.5 pt-2 flex items-center justify-center self-center flex-col mt-2 w-full">
          {!tournament ? (
            <Skeleton />
          ) : (
            <>
              <Responsive at={['xs', 'sm', 'md']}>
                <div className="flex justify-center items-center gap-7 w-[340px]">
                  <div
                    className="mb-11 mt-5 w-full h-[188px] flex justify-center items-center red-shadow rounded-2xl"
                    style={{
                      backgroundImage: `url(${CONTENT_URL}${tournament?.imageMobile})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}></div>
                </div>
                <p className="text-text_8 text-[20px] font-bold uppercase w-full text-left mb-9 max-w-[700px] px-0 md:px-8">
                  {tournament.title}
                </p>
                {isAuthenticated ? (
                  <a
                    href="#tournament-games"
                    aria-label={`${t('tournaments.participate')}`}
                    className="w-full flex justify-center items-center">
                    <Button
                      title={`${t('tournaments.participate')}`}
                      mode="primary"
                      additionalClassName="mb-9 w-full max-w-[300px]"
                      label={`${t('tournaments.participate')}`}
                    />
                  </a>
                ) : (
                  <Button
                    title={`${t('tournaments.participate')}`}
                    mode="primary"
                    additionalClassName="mb-9 w-full max-w-[300px]"
                    onClick={() => openModal?.(MODAL_NAME.SIGNUP)}
                    label={`${t('tournaments.participate')}`}
                  />
                )}
              </Responsive>
              <Responsive at={['lg', 'xl']}>
                <div
                  className="mb-11 mt-5 w-full max-w-[1020px] h-[188px] flex justify-center items-center red-shadow rounded-2xl"
                  style={{
                    backgroundImage: `url(${CONTENT_URL}${tournament?.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%'
                  }}></div>
                {isAuthenticated ? (
                  <a href="#tournament-games" aria-label={`${t('tournaments.participate')}`}>
                    <Button
                      title={`${t('tournaments.participate')}`}
                      mode="primary"
                      additionalClassName="m-auto mb-11"
                      label={`${t('tournaments.participate')}`}
                    />
                  </a>
                ) : (
                  <Button
                    title={`${t('tournaments.participate')}`}
                    mode="primary"
                    onClick={() => openModal?.(MODAL_NAME.SIGNUP)}
                    additionalClassName="m-auto mb-11"
                    label={`${t('tournaments.participate')}`}
                  />
                )}
              </Responsive>
              <div className="px-8 mb-[30px] text-text_2 max-w-[700px] w-full r_sm:px-0">
                <div
                  className="static-page bonus-content"
                  dangerouslySetInnerHTML={{
                    __html: tournament ? tournament?.content : ''
                  }}></div>
              </div>

              <div
                className="my-3 w-full flex justify-center items-center gap-3"
                id="tournament-games">
                <img src={diamondIcon} alt="icon" className="w-[30px] h-[30px] hidden md:flex" />
                <p className="text-text_8 font-bold text-xl text-center uppercase">
                  {t('tournaments.gamesInSeries')}
                </p>
              </div>
              <div className="grid r_sm:grid-cols-3 grid-cols-6 gap-x-1.5 gap-y-2.5 mt-4 w-full">
                {isFetching ? (
                  <GameLoading count={6} />
                ) : (
                  handleShowMoreGames?.map((game) => (
                    <GameCard key={game.id} game={game} isWagering />
                  ))
                )}
              </div>
              <div className="h-0" ref={childRef}></div>
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
};
