import { ShortBalanceResponse } from 'src/api/balance';
import { BonusesResponse } from 'src/api/bonuses';
import { AllUserLevelsResponse, Child } from 'src/api/content';
import { Jackpot } from 'src/api/jackpot';
import { allCountriesData, contentGamesPageCodes, IconCurrency, sortType } from '../constants';
import allCountries from '../constants/countries.v1.json';
import { DropDownOption, PaymentMethod, PaymentMethodOption } from '../types';
import { LangOptions } from 'src/components/Languages/const';

export const makeFloat = (value?: number | string) => {
  if (value === undefined) return 0.0;
  if (typeof value === 'string') return parseFloat(value) * 0.01;
  return value * 0.01;
};

export const getCurrencyIcon = (currency?: string) => {
  if (!currency) {
    return '';
  }
  return IconCurrency[currency as keyof typeof IconCurrency];
};

export const getPhoneCodeByCountryCode = (countryCode: string) => {
  const countyInfo = allCountriesData.find((country) => country.alpha3 === countryCode);
  return countyInfo?.phoneCodes[0];
};

export const findCountryCodeByName = (name: string | undefined) => {
  if (!name) return undefined;
  return allCountries.find((c) => c.name === name)?.alpha3;
};

export const findCountryNameByCode = (code: string) => allCountries.find((c) => c.alpha3 === code)?.name;

export const prepareVoucherOptions = (options?: PaymentMethodOption[]): DropDownOption[] => {
  return (options || [])
    .sort((currentOption, nextOption) => (currentOption.id || 0) - (nextOption.id || 0))
    .map((el) => ({
      id: el.id?.toString() || '',
      name: `${el.amount} ${el.currency}`,
    }));
};

export const validateDepositButton = (isFormValid: boolean, coin: string, paymentMethod?: PaymentMethod) => {
  if (paymentMethod?.options?.length && paymentMethod?.type !== 'voucher') {
    return !isFormValid || !coin || !paymentMethod;
  }
  return !isFormValid || !paymentMethod;
};

export const paymentMethodsArrayCheck = (methods?: PaymentMethod[]) => {
  if (!methods) return [];
  if (!Array.isArray(methods)) return [];
  return methods;
};

export const splitArrayIntoChunks = <TChunk>(chunkSize: number, initArray: TChunk[]): TChunk[][] => {
  const resultArray: TChunk[][] = [];

  for (let i = 0; i < initArray.length; i += chunkSize) {
    const chunk: TChunk[] = initArray.slice(i, i + chunkSize);
    resultArray.push(chunk);
  }

  return resultArray;
};

export const paymentMethodsSort = (methods: PaymentMethod[] | undefined) => {
  if (methods) {
    const zeros = methods.filter((el, index) => {
      el._reactDepositMethodId = index;
      return Number(el?.order) === 0;
    });

    const nums = methods.filter((el) => Number(el.order) !== 0);
    const sortedNums = nums.sort((a, b) => Number(a.order) - Number(b.order));
    return [...sortedNums, ...zeros];
  }
  return [];
};

const BonusTransactionType: { [key: number]: string } = {
  0: 'Bonus',
  1: 'MoneyPrize',
  2: 'Manual',
  3: 'Referral',
  4: 'FreeSpinWin',
  5: 'Bet',
  6: 'BetSport',
  7: 'Win',
  8: 'Zeroing',
  9: 'CancelWager',
  10: 'WinSport',
  11: 'CashBack',
  12: 'LevelBonus',
  13: 'Refund',
  14: 'RollbackBet',
  15: 'RollbackWin',
};

export const setBonusTransactionTypeName = (typeNumber: number) => {
  if (typeNumber in BonusTransactionType) {
    return BonusTransactionType[typeNumber];
  }
  return '';
};

export const findSortById = (id: string) => sortType.find((c) => c.id === id);

export const getLevelInPercents = (pointsToReach?: string | number, userPoints?: number) => {
  if (pointsToReach === undefined || userPoints === undefined || userPoints === 0) return '0%';
  if (pointsToReach === '\u221e') return '100%';
  if (typeof pointsToReach === 'string') {
    return `${(userPoints / parseInt(pointsToReach, 10)) * 100}%`;
  }
  if (typeof pointsToReach === 'number') {
    return `${((userPoints / pointsToReach) * 100).toFixed(2)}%`;
  }
};

export const getLevelInNumber = (pointsToReach?: string | number, userPoints?: number) => {
  if (pointsToReach === undefined || userPoints === undefined || userPoints === 0) return 0;
  if (pointsToReach === '\u221e') return 100;
  if (typeof pointsToReach === 'string') {
    return (userPoints / parseInt(pointsToReach, 10)) * 100;
  }
  if (typeof pointsToReach === 'number') {
    return (userPoints / pointsToReach) * 100;
  }
};

export const prepareLevelSlidesArray = (userLevel?: number, array: AllUserLevelsResponse[] = []) => {
  if (userLevel === 0) return array;
  if (!userLevel) return [];
  const activeLevelIndex = array.findIndex((el) => el.levelInfo?.level === userLevel);
  const startOfNewArray = array.slice(activeLevelIndex);
  const endOfNewArray = array.slice(0, activeLevelIndex);

  return startOfNewArray.concat(endOfNewArray);
};

export const showShortBalance = (shortBalance: ShortBalanceResponse | undefined) => {
  return `${getCurrencyIcon(shortBalance?.currency || 'EUR')} ${makeFloat(shortBalance?.balance).toFixed(2)}`;
};

const JACKPOTS_ORDER = ['jackpot-monthly', 'jackpot-weekly', 'jackpot-daily'];
export const prepareJackpotsForBanner = (jackpots: Jackpot[]) => {
  const result: Jackpot[] = [];
  JACKPOTS_ORDER.forEach((code) => {
    const j = jackpots.find((jackpot) => jackpot.code === code);
    if (j) result.push(j);
  });
  return result;
};

export const prepareJackpotsForGamesPageSlider = (
  slides: Child[] | undefined,
  jackpots: Jackpot[],
): Array<Jackpot | null> => {
  const mappedJackpots = jackpots.map((jackpot) => {
    const slideImage = slides?.find((el) => el.code === jackpot.code)?.imageMobile;
    if (slideImage) {
      return { ...jackpot, imageMobile: slideImage };
    } else {
      return null;
    }
  });

  return mappedJackpots.filter((jackpot) => jackpot !== null);
};

export const prepareMethodOptions = (paymentMethod: PaymentMethod) => {
  if (!paymentMethod) return undefined;
  if (paymentMethod.type === 'coin' && paymentMethod.options) {
    return paymentMethod.options.map((el) => ({
      id: el.name,
      name: el.title,
    }));
  } else if (paymentMethod.options) {
    return paymentMethod.options.map((el) => ({
      id: el.id ? el.id.toString() : el.title,
      name: el.title,
    }));
  }
  return undefined;
};

export const prepareBonuses = (bonuses: BonusesResponse, depositsCount?: number) => {
  if (depositsCount == undefined) return [];

  const filteredBonuses = bonuses.filter((bonus) => bonus.tag).filter((bonus) => bonus.type === 5);
  const partnerBonuses = filteredBonuses.filter((bonus) => bonus.tag === 'partner');
  const sortedByThreeTagsArray = [];

  for (let i = 0; i < filteredBonuses.length; i++) {
    if (filteredBonuses[i].tag === 'standard') {
      sortedByThreeTagsArray.push(filteredBonuses[i]);
    }
    if (filteredBonuses[i].tag === 'vip') {
      const standardIndex = sortedByThreeTagsArray.findIndex((el) => el.tag === 'standard');
      if (standardIndex === -1) {
        sortedByThreeTagsArray.push(filteredBonuses[i]);
      } else {
        sortedByThreeTagsArray.splice(standardIndex, 0, filteredBonuses[i]);
      }
    }
    if (filteredBonuses[i].tag === 'special') {
      sortedByThreeTagsArray.unshift(filteredBonuses[i]);
    }
  }

  const sortedByTagsArray = partnerBonuses.concat(sortedByThreeTagsArray);

  return sortedByTagsArray
    .filter(
      (bonus) =>
        depositsCount + 1 >= bonus.numberDeposits.from &&
        (depositsCount + 1 <= bonus.numberDeposits.to || bonus.numberDeposits.to == 0),
    )
    .slice(0, 3);
};

export const openChat = () => {
  // @ts-ignore
  if (typeof window.LiveChatWidget !== undefined) {
    // @ts-ignore
    window.LiveChatWidget?.call?.('maximize');
  }
};
export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const containsLanguage = (arrayLanguages: LangOptions, route: string) => {
  // eslint-disable-next-line no-useless-escape
  const languageRegExp = new RegExp(`\/(${arrayLanguages.join('|')})(\/|$)`);
  return route.match(languageRegExp);
};

export const getLevelsString = (from?: number, to?: number): string => {
  if (from === undefined || to === undefined) {
    return '';
  }
  return `${from} exp. to Level ${to}`;
};

export const upToTop = ({ behaviorSmooth, pageRef }: { behaviorSmooth?: boolean; pageRef: any }) => {
  if (pageRef.current) {
    behaviorSmooth ? pageRef.current?.scrollTo({ top: 0, behavior: 'smooth' }) : pageRef.current?.scrollTo({ top: 0 });
  } else {
    behaviorSmooth ? window.scrollTo({ top: 0, behavior: 'smooth' }) : window.scrollTo({ top: 0 });
  }
};

export const getSummOfPossiblyUndefinedNums = (first?: number, second?: number) => {
  if (first && second) {
    return first + second;
  } else if (!first && second) {
    return second;
  } else if (first && !second) {
    return first;
  }
  return 0;
};

export const getDiffOfPossiblyUndefinedNums = (first?: number, second?: number) => {
  if (first && second && second > first) {
    return 0;
  } else if (first && second) {
    return first - second;
  } else if (!first && second) {
    return 0;
  } else if (first && !second) {
    return first;
  }
  return 0;
};

export const debounce = (fn: typeof Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export function makeRouteWithLanguage(languageCode: string, locationPathname: string) {
  return `/${languageCode}${locationPathname
    .split('/')
    .reduce((prev, curr, index) => (index > 1 ? `${prev}/${curr}` : prev), '')}`;
}

export const getGamePageCodeScenario = (pageCode: string | undefined, searchValueLength: number) => {
  if (pageCode === undefined) return false;
  if (pageCode in contentGamesPageCodes && !searchValueLength) {
    return true;
  }
  return false;
};

export const getGamePageCode = (pageCode: string | undefined) => {
  if (pageCode === undefined) return undefined;
  if (pageCode in contentGamesPageCodes) {
    return contentGamesPageCodes[pageCode as keyof typeof contentGamesPageCodes];
  }
  return pageCode;
};

export const executeAdditionalGTMScript = (userId: number | undefined) => {
  (function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'userId',
      user_id: userId ? userId.toString() : userId,
    });
  })();
};

export const executeRegistrationGTMScript = (userId: number) => {
  executeAdditionalGTMScript(userId);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'registration',
    user_id: userId.toString(),
  });
};

export const executeLoginGTMScript = (userId: number) => {
  executeAdditionalGTMScript(userId);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login',
    user_id: userId.toString(),
  });
};

export const generateSeonSession = async (email: string | undefined): Promise<{ session: string | undefined }> => {
  const seon = window.seon;
  seon?.config({
    host: 'deviceinf.com',
    session_id: email,
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
  });
  const session: string | undefined = await seon?.getBase64Session();
  return { session };
};

export const isString = (value: any): value is string => {
  return typeof value === 'string';
};
