import { useCallback, useMemo, useRef, useState } from 'react';
import { FieldConfig, FieldType } from './fields.types';
import { DEFAULT_STATE, getFieldProps } from './useForm.constants';
import {
  GetConfig,
  FormValuesScheme,
  GetState,
  GetBooleanState,
  GetProps,
  GetErrorsState
} from './useForm.types';

export const useForm = <TFields extends FormValuesScheme>(config: GetConfig<TFields>) => {
  const defaultState = useRef(prepareDefaultState<TFields>(config));
  const [submitTried, setSubmitTried] = useState<boolean>(false);
  const [values, setValues] = useState<GetState<TFields>>(defaultState.current);
  const [touched, setTouched] = useState(() => prepareDefaultTouchedState<TFields>(config));
  const [focused, setFocused] = useState<string | undefined>();
  const [dirty, setDirty] = useState(() => prepareDefaultTouchedState(config));
  const errors = useMemo(() => validateAllValues<TFields>(config, values), [config, values]);

  const setAsTouched = useCallback(
    (fieldName: string) => {
      if (touched[fieldName]) return;
      setTouched((touched) => ({ ...touched, [fieldName]: true }));
    },
    [touched]
  );

  const setAsDirty = useCallback(
    (fieldName: string, value: any) => {
      if (!dirty[fieldName] && value !== defaultState.current[fieldName]) {
        setDirty((dirty) => ({ ...dirty, [fieldName]: true }));
        return;
      }
      if (dirty[fieldName] && value === defaultState.current[fieldName]) {
        setDirty((dirty) => ({ ...dirty, [fieldName]: false }));
        return;
      }
    },
    [dirty]
  );

  const checkFieldStatuses = useCallback(
    (fieldName: string, value: any) => {
      setAsDirty(fieldName, value);
    },
    [setAsDirty]
  );

  const updateDefaultState = useCallback(
    (allFormValues: GetState<TFields>) => {
      setValues(allFormValues);
      defaultState.current = allFormValues;
      setTouched(prepareDefaultTouchedState(config));
      setDirty(prepareDefaultTouchedState(config));
    },
    [config]
  );

  const updateDefaultStatePartially = useCallback(
    (someNewDefaultValues: Partial<GetState<TFields>>) => {
      setValues((values) => {
        const newValues = { ...values, ...someNewDefaultValues };
        defaultState.current = newValues;
        return newValues;
      });
      setTouched(prepareDefaultTouchedState(config));
      setDirty(prepareDefaultTouchedState(config));
    },
    [config]
  );

  const fieldsProps = useMemo(() => {
    const props = {} as GetProps<TFields>;
    for (const fieldName in config) {
      const field = config[fieldName];
      // @ts-ignore ts не может понять что `${fieldName}Props` это корректный ключ для объекта GetProps<TFields> из-за цикла
      props[`${fieldName}Props`] = getFieldProps[field.fieldType]({
        fieldName,
        values,
        // @ts-ignore из-за цикла нет возможности точно указать какой именно field конфиг здесь передается
        field,
        setAsTouched,
        checkFieldStatuses,
        setValues,
        setFocused
      });
    }

    return props;
  }, [config, checkFieldStatuses, setAsTouched, values]);

  const isFormValid = useMemo(() => {
    for (const fieldName in errors) {
      if (errors[fieldName]) return false;
    }
    return true;
  }, [errors]);

  const setSomeValues = (someValues: Partial<GetState<TFields>>) =>
    setValues((values) => ({ ...values, ...someValues }));

  const resetForm = () => {
    setValues(defaultState.current);
    setTouched(prepareDefaultTouchedState(config));
    setDirty(prepareDefaultTouchedState(config));
  };

  return {
    values,
    setValues,
    errors,
    fieldsProps,
    touched,
    dirty,
    isFormValid,
    updateDefaultState,
    updateDefaultStatePartially,
    defaultState: defaultState.current,
    setSomeValues,
    resetForm,
    submitTried,
    setSubmitTried,
    focused
  };
};

function prepareDefaultState<TFormValuesScheme extends FormValuesScheme>(
  config: GetConfig<TFormValuesScheme>
): GetState<TFormValuesScheme> {
  const state = {} as GetState<TFormValuesScheme>;
  for (const fieldName in config) {
    const field = config[fieldName];
    state[fieldName] = field.defaultValue || DEFAULT_STATE[field.fieldType];
  }
  return state;
}

function validateAllValues<TFormValuesScheme extends FormValuesScheme>(
  config: GetConfig<TFormValuesScheme>,
  values: GetState<TFormValuesScheme>
): GetErrorsState<TFormValuesScheme> {
  const errors = {} as GetErrorsState<TFormValuesScheme>;

  for (const fieldName in config) {
    const field = config[fieldName];
    errors[fieldName] = validateOneField<TFormValuesScheme>(field, values[fieldName], values);
  }

  return errors;
}

function validateOneField<TFormValuesScheme extends FormValuesScheme>(
  field: FieldConfig<FieldType>,
  value: any,
  values: GetState<TFormValuesScheme>
): string | undefined {
  if (!field.validation) return undefined;
  const { validation } = field;
  for (let i = 0; i < validation.length; i++) {
    const errorMessage = validation[i](value, values);
    if (errorMessage) return errorMessage;
  }
  return undefined;
}

function prepareDefaultTouchedState<TFormValuesScheme extends FormValuesScheme>(
  config: GetConfig<TFormValuesScheme>
): GetBooleanState<TFormValuesScheme> {
  const state = {} as GetBooleanState<TFormValuesScheme>;
  for (const fieldName in config) {
    state[fieldName] = false;
  }
  return state;
}
