import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';
import { useDrawer } from 'src/hooks/useDrawer';
import { openChat } from 'src/utils';
import { menuItem } from '../../constants';
import { useApi } from '../../hooks/useApi';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import CompactLanguageSelector, { Position } from '../Languages/CompactLanguageSelector';
import { Separator } from '../Separator/Separator';
import { SidebarTooltip, Tooltips } from '../SidebarTooltip/SidebarTooltip';
import {
  AccountForProfile,
  CollapsedSidebarLogin,
  CollapsedSidebarSignUp,
  Download,
  LiveChatIcon
} from '../svg';
import './styles.css';

const loginData = [
  {
    id: 1,
    tooltipText: 'general.signUp',
    tooltipId: 'sidebar-sign-up',
    tooltip: Tooltips.SIGNUP,
    icon: CollapsedSidebarSignUp,
    modalToOpen: MODAL_NAME.SIGNUP
  },
  {
    id: 2,
    tooltipText: 'general.logIn',
    tooltipId: 'sidebar-log-in',
    tooltip: Tooltips.LOGIN,
    icon: CollapsedSidebarLogin,
    modalToOpen: MODAL_NAME.LOGIN
  }
];

export const DesktopCollapsedSidebarContent: FC = () => {
  const [activeTooltip, setActiveTooltip] = useState<Tooltips>(Tooltips.NONE);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const navigate = useNavigate();
  const [handleClick] = useAddToHomescreenPrompt();
  const { closeDesktopSidebar, closeLeftDrawer, goToAccount } = useDrawer();
  const { isXxs, isXs } = useBreakpoints();
  const isMobileScreen = isXxs || isXs;

  const handleActiveTooltip = (value: Tooltips): void => {
    setActiveTooltip(value);
  };

  const clearTooltipState = (): void => setActiveTooltip(Tooltips.NONE);

  return (
    <div className="collapsed-wrapper">
      <div className="collapsed-container">
        {isAuthenticated ? (
          <SidebarTooltip
            id="account-icon"
            mouseEnterFunction={() => handleActiveTooltip(Tooltips.ACCOUNT)}
            mouseLeaveFunction={clearTooltipState}
            containerClickFunction={() => {
              navigate('/account/profile/main');
              goToAccount?.();
            }}
            showTooltip={activeTooltip === Tooltips.ACCOUNT}
            tooltipContent={t('general.account')}
            containerAdditionalClassName="mb-[15px] cursor-pointer">
            <AccountForProfile fill="#ffffff" />
          </SidebarTooltip>
        ) : (
          <>
            {loginData.map((item) => (
              <SidebarTooltip
                key={item.id}
                id={item.tooltipId}
                mouseEnterFunction={() => handleActiveTooltip(item.tooltip)}
                mouseLeaveFunction={clearTooltipState}
                containerClickFunction={() => openModal?.(item.modalToOpen)}
                showTooltip={activeTooltip === item.tooltip}
                tooltipContent={t(item.tooltipText)}
                containerAdditionalClassName="mb-[15px] cursor-pointer">
                <item.icon />
              </SidebarTooltip>
            ))}
          </>
        )}
      </div>
      <Separator width="w-[100%]" noMarginTop />
      <div className="collapsed-container mt-[15px]">
        {menuItem.map((item, index) => (
          <NavLink to={item.path} key={index} aria-label={`${item.name}`}>
            <SidebarTooltip
              id={item.tooltipId}
              mouseEnterFunction={() => handleActiveTooltip(item.tooltip)}
              mouseLeaveFunction={clearTooltipState}
              showTooltip={activeTooltip === item.tooltip}
              tooltipContent={t(item.name)}
              containerAdditionalClassName="mb-[5px]">
              {item.image}
            </SidebarTooltip>
          </NavLink>
        ))}
        <SidebarTooltip
          id={'support-chat'}
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.CHAT)}
          mouseLeaveFunction={clearTooltipState}
          showTooltip={activeTooltip === Tooltips.CHAT}
          tooltipContent={t('mainSidebarLinks.liveChat')}
          containerAdditionalClassName="mb-[5px] cursor-pointer"
          containerClickFunction={() => {
            isMobileScreen && closeDesktopSidebar?.();
            closeLeftDrawer?.();
            openChat();
          }}>
          <LiveChatIcon />
        </SidebarTooltip>
      </div>
      <Separator width="w-[100%]" noMarginTop />
      <div className="collapsed-container mt-[15px]">
        <SidebarTooltip
          id="sidebar-download-app"
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.DOWNLOAD)}
          mouseLeaveFunction={clearTooltipState}
          showTooltip={activeTooltip === Tooltips.DOWNLOAD}
          tooltipContent={t('general.installApp')}
          containerAdditionalClassName="mb-[15px]"
          containerWithoutHover>
          <div
            className="cursor-pointer border px-2.5 py-1 rounded-[5px] hover:border-border_5 fill-background_15 hover:fill-background_5 transition"
            onClick={() => {
              handleClick?.();
            }}>
            <Download className="w-[30px] h-[30px] transition" />
          </div>
        </SidebarTooltip>
      </div>
      <Separator width="w-[100%]" noMarginTop />
      <div className="collapsed-container mt-[15px]">
        <CompactLanguageSelector
          arrowPosition={Position.BOTTOM}
          classNameLanguageList="w-[65px] right-[-14%] left-[-7.5px]"
        />
      </div>
    </div>
  );
};
