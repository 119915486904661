import classNames from 'classnames';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllBonusesTransactions } from 'src/api/bonusesTransaction';
import { useGetFullBalance, useGetWithdrawal } from '../../api/balance/balance.hooks';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { getCurrencyIcon, makeFloat } from '../../utils';
import { Button } from '../Buttons/Button';
import { Switch } from '../Switch';
import { BalanceCardInfo } from './BalanceCardInfo';
import { useNavigate } from 'react-router-dom';

interface BalanceCardProps {
  card: {
    currency: string;
    value: any;
    withdrawValue: string;
  };
}

export const BalanceCard = ({ card }: BalanceCardProps) => {
  const navigate = useNavigate();
  const { fullBalance } = useGetFullBalance();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { withdrawDetails } = useGetWithdrawal({});
  const { lengthArrayBonusesTansactions } = useGetAllBonusesTransactions({
    bonus_type: 0,
    status: '1',
    type: '0,1,2,3,4,11,12',
    currency: card.currency
  });

  const cardCurrency = useMemo(() => {
    return card.currency && card.currency;
  }, [card.currency]);

  const cardCurrencyIcon = useMemo(() => {
    return card.currency && `${getCurrencyIcon(card.currency)}`;
  }, [card.currency]);

  const cardAmount = useMemo(() => {
    return card.value ? `${makeFloat(card.value).toFixed(2)}` : '0.00';
  }, [card.value]);

  const handleClickDeposit = useCallback(() => {
    fullBalance?.currency === cardCurrency
      ? navigate('/account/wallet/deposit')
      : openModal?.(MODAL_NAME.BALANCE_CHANGE_CURRENCY, { currency: card.currency });
  }, [card.currency, cardCurrency, fullBalance?.currency, navigate, openModal]);

  const withdrawValue = useMemo(() => {
    if (fullBalance?.currency === cardCurrency) {
      return makeFloat(withdrawDetails?.available_for_withdraw).toFixed(2);
    }
    if (cardAmount) {
      return cardAmount;
    } else return '0.00';
  }, [cardAmount, cardCurrency, fullBalance?.currency, withdrawDetails?.available_for_withdraw]);

  return (
    <div
      className={classNames(
        'rounded-[20px] p-[26px] flex flex-col gap-3 r_sm:-mx-5',
        fullBalance?.currency === cardCurrency ? 'active-balance-card' : 'inactive-balance-card'
      )}>
      <div className="flex justify-between items-center">
        {fullBalance?.currency === cardCurrency ? (
          <>
            <p className="text-text_12 text-[24px] font-bold">{t('accountWalletBalance.active')}</p>
          </>
        ) : (
          <>
            <p className="text-text_2 text-[24px] font-bold">
              {t('accountWalletBalance.inactive')}
            </p>
            <Switch
              alwaysDisabled={true}
              onClick={() => {
                openModal?.(MODAL_NAME.BALANCE_CHANGE_CURRENCY, { currency: card.currency });
              }}
            />
          </>
        )}
      </div>
      <BalanceCardInfo
        balanceCard={fullBalance?.currency == cardCurrency}
        title={cardCurrency}
        text={`${cardCurrencyIcon} ${cardAmount}`}
      />
      <div className="flex justify-between items-center gap-5">
        <BalanceCardInfo
          balanceCard={fullBalance?.currency == cardCurrency}
          title={`${t('withdrawal.fundsWithdrawn')}`}
          text={`${cardCurrencyIcon} ${withdrawValue}`}
        />
        <BalanceCardInfo
          balanceCard={fullBalance?.currency == cardCurrency}
          title={`${t('withdrawal.bonusInQueue')}`}
          text={`${lengthArrayBonusesTansactions}`}
        />
      </div>
      <Button
        mode="primary"
        disabled={fullBalance?.currency !== cardCurrency}
        onClick={handleClickDeposit}
        label={`${t('accountWalletBalance.deposite')}`}
        title={`${t('accountWalletBalance.deposite')}`}
        className="m-auto"
      />
    </div>
  );
};
