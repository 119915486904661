import classNames from 'classnames';
import { useRef } from 'react';

import { useClickOutside } from '../../hooks/useClickOutside';
import { useDropdown } from '../../hooks/useDropdown';
import { DropDownOption } from '../../types';
import { Arrow } from '../svg';

import './styles.css';

type Props = {
  className?: string;
  classNameArrow?: string;
  disabled?: boolean;
  label?: string;
  labelWithPadding?: boolean;
  onChange?: (element?: string) => void;
  options?: DropDownOption[];
  optionsSize?: number;
  placeholder?: string;
  value?: string | undefined;
};

export const Dropdown = ({
  className,
  classNameArrow,
  disabled,
  label,
  labelWithPadding,
  onChange,
  options = [],
  optionsSize = 16,
  placeholder,
  value
}: Props) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const {
    close,
    onSelect,
    selectedOptionName,
    showList,
    toggle,
    value: selectedValue
  } = useDropdown({
    onChange,
    options,
    value
  });

  useClickOutside(dropdownRef, close);

  return (
    <div className="relative text-[16px] w-full cursor-pointer z-10" ref={dropdownRef}>
      {label ? (
        <label
          className={classNames(
            'text-base mb-2 block text-text_2',
            labelWithPadding && 'pl-[20px]'
          )}>
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'flex relative flex-row items-center h-[42px] border-1 border-solid border-border_3 rounded-[20px] bg-background_15',
          className && `${className}`,
          showList && 'z-[41]',
          disabled && 'bg-background_7 cursor-not-allowed'
        )}
        onClick={() => disabled || toggle()}>
        {selectedOptionName || selectedValue || value ? (
          <p className="text-[16px] text-text_1 py-2.5 w-full px-5">
            {selectedOptionName || selectedValue || value}
          </p>
        ) : (
          <p className="text-[16px] text-text-placeholder text-text_6 py-2.5 w-full px-5">
            {placeholder}
          </p>
        )}
        <Arrow
          className={classNames(
            'absolute right-5 w-[12px] h-full stroke-[3px] transition-transform text-text_6',
            classNameArrow ? `${classNameArrow}` : '',
            { opened: showList }
          )}
        />
      </div>
      {showList && (
        <div
          className={classNames(
            'entrance absolute rounded-b-[20px] bg-[white] z-40 flex flex-col items-start overflow-y-scroll w-full max-h-[150px] pt-4 pb-2 shadow',
            label ? 'top-[55px]' : 'top-[25px]'
          )}>
          {options.map((option) => (
            <div
              className={`text-[${optionsSize}px] cursor-pointer py-2 px-5 w-full r_xsm:px-2.5 hover:bg-background_2 hover:text-text_2`}
              key={option.id}
              onClick={() => onSelect(option.id, option)}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
