import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNewGetAllGames } from 'src/api/games/games.hooks';
import { BlockGamesIcons, GameCategories } from 'src/constants/index';

import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { CardSeeAll } from '../CardSeeAll/CardSeeAll';
import { GameCard } from '../GameCard';
import { GameLoading } from '../GameCard/GameLoading';
import { Responsive } from '../Responsive/index';

type CategoryProps = {
  categoryId: keyof typeof GameCategories;
};

export const BlockGames: FC<CategoryProps> = ({ categoryId }) => {
  const { t } = useTranslation();
  const { name, title, value } = GameCategories[categoryId];
  const [totalGames, setTotalGames] = useState(
    window.innerWidth >= 1024 ? 17 : window.innerWidth <= 1023 && window.innerWidth > 767 ? 7 : 5,
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 1024) {
        setTotalGames(17);
      } else if (window.innerWidth <= 1023 && window.innerWidth >= 767) {
        setTotalGames(7);
      } else if (window.innerWidth < 766) {
        setTotalGames(5);
      } else {
        setTotalGames(17);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const { allGames, isFetching } = useNewGetAllGames(
    {
      [name]: value,
      per_page: totalGames,
    },
    categoryId,
  );

  const navigate = useNavigate();

  const navigateToAllGamesInCategory = () => {
    const pathname = `/games/${categoryId}`;

    sendGoogleAnalyticsEvent('all_games_press', {
      target_page_id: pathname,
    });

    navigate(pathname);
  };

  const icon = useMemo(() => {
    return BlockGamesIcons.find((item) => item.title === title);
  }, [title, BlockGamesIcons]);

  if (allGames.length === 0) {
    return null;
  }

  return (
    <div className="mt-5 w-full">
      <div className="flex items-center justify-start pb-4">
        <div className="flex items-center justify-center gap-2">
          {icon?.icon && icon.icon()}
          <p className="text-[18px] font-bold uppercase text-text_2">{t(title)}</p>
        </div>
      </div>
      <Responsive at={['xs', 'sm', 'md']}>
        <div className="grid grid-cols-4 gap-x-1.5 gap-y-2.5 r_sm:grid-cols-3">
          {isFetching ? (
            <GameLoading count={totalGames} />
          ) : (
            <>
              {allGames.map((game) => (
                <GameCard
                  game={game}
                  key={game.id}
                />
              ))}
              <CardSeeAll onClick={navigateToAllGamesInCategory} />
            </>
          )}
        </div>
      </Responsive>
      <Responsive at={['lg', 'xl']}>
        <div className="grid grid-cols-6 gap-x-1.5 gap-y-2.5">
          {isFetching ? (
            <GameLoading count={totalGames} />
          ) : (
            <>
              {allGames.map((game) => (
                <GameCard
                  game={game}
                  key={game.id}
                />
              ))}
              <CardSeeAll onClick={navigateToAllGamesInCategory} />
            </>
          )}
        </div>
      </Responsive>
    </div>
  );
};
