import classNames from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import wallet from 'src/assets/images/wallet.png';
import { MoreMethods } from 'src/components/svg';
import { PaymentMethod } from 'src/types';

export const CardPaymentMethod: FC<{
  id: string;
  method: PaymentMethod;
  onClick?: (method: PaymentMethod) => void;
  selected?: boolean;
}> = ({ id, method, onClick, selected }) => {
  const title = useMemo(() => {
    return method.title ? method.title : '';
  }, [method.title]);

  const handleClick = useCallback(() => {
    onClick?.(method);
  }, [method, onClick]);

  return (
    <div
      className={classNames(
        'relative h-[45px] w-[89px] rounded-[12px] bg-background_37 p-[1px] text-text_2 shadow-payment  lg:h-[60px] lg:w-[134px]',
        selected ? 'bg-orange-gradient' : '',
      )}
    >
      <div className="flex h-full w-full flex-col items-center justify-start gap-1 overflow-hidden rounded-[12px] bg-background_37 px-[5px] py-[1px]">
        <input
          className="absolute left-0 top-0 h-[100%] w-[100%] cursor-pointer opacity-0"
          id={id}
          name={id}
          onClick={handleClick}
          type="radio"
          value={id}
        />
        {method.logoUrl ? (
          <img
            alt={title}
            className="h-[60%] w-full max-w-[70px] object-contain"
            onError={(event) => {
              const element = event.target as HTMLImageElement;
              element.src = wallet;
              element.classList.add('!max-w-[28px]');
            }}
            src={method.logoUrl}
          />
        ) : (
          <MoreMethods />
        )}
        <p className={classNames('max-w-[100%] truncate text-center text-[.54rem] lg:text-xs')}>{title}</p>
      </div>
    </div>
  );
};
