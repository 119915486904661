import { useMemo } from 'react';
import { Skeleton } from 'src/components/Skeleton';
import { useGetBonuses } from '../../api/bonuses/bonuses.hooks';
import { BonusCard, PageTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import noBonus from 'src/assets/images/noBonus.webp';

const bonusTypes = {
  0: 'Registration',
  3: 'PromoCode',
  5: 'Deposits',
  6: 'Periodic',
  7: 'IncreaseWinnings',
  8: 'ActivatedByLink'
};

export const Bonuses = () => {
  const { data, isLoading } = useGetBonuses();
  const { t } = useTranslation();
  const bonuses = useMemo(() => {
    const sortBonuses = data.filter((bonus) => {
      if (
        bonus.type in bonusTypes &&
        bonus.fullDescription &&
        bonus.shortDescription &&
        bonus.imageBanner &&
        bonus.imageBackground
      ) {
        return true;
      }
      return false;
    });
    return sortBonuses;
  }, [data]);

  return (
    <div className='w-full'>
      <PageTitle title="Promo" />
      <div className="flex flex-col gap-8 mt-8 px-2.5 r_sm:mt-0 r_sm:pt-0">
        {isLoading ? (
          <Skeleton rows={6} />
        ) : (
          <>
            {bonuses.length > 0 ? (
              <div className="flex flex-col gap-16 self-center items-center w-full r_sm:gap-4">
                {bonuses.map((bonus) => (
                  <BonusCard key={bonus.id} bonus={bonus} />
                ))}
              </div>
            ) : (
              <div className="w-full my-[30px] flex flex-col justify-center items-center">
                <p className="text-text_2 text-center font-bold w-[70%] text-2xl">
                  {t('bonusPage.noBonus')}
                </p>
                <img src={noBonus} alt="no bonus" className="w-auto mt-6" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
