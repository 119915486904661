import classNames from 'classnames';
import { useEffect, useState } from 'react';

export const Accordion = ({
  blockAccordions,
  content,
  onClick,
  title,
}: {
  blockAccordions?: true;
  content: string;
  onClick?: (open: boolean) => void;
  title: string;
}) => {
  const [accordion, setAccordion] = useState<boolean>(true);
  const handleClick = () => {
    if (!blockAccordions) {
      setAccordion(!accordion);
      onClick?.(accordion);
    }
  };

  useEffect(() => {
    blockAccordions ? setAccordion(true) : null;
  }, [blockAccordions]);

  return (
    <div
      className="px-1 cursor-pointer bg-background_1 text-text_2"
      onClick={handleClick}
    >
      <div className="border-b-[1px] border-border_6">
        <div className="flex justify-between my-[10px]">
          <p className="font-bold text-base w-4/5">{title}</p>
          <div>
            <svg
              className={classNames('transition ease-in-out duration-300', {
                'rotate-180': !accordion,
              })}
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=""
                clipRule="evenodd"
                d="M3.71209 6.52459C4.0782 6.15847 4.6718 6.15847 5.03791 6.52459L10 11.4867L14.9621 6.52459C15.3282 6.15847 15.9218 6.15847 16.2879 6.52459C16.654 6.8907 16.654 7.4843 16.2879 7.85041L10.6629 13.4754C10.2968 13.8415 9.7032 13.8415 9.33709 13.4754L3.71209 7.85041C3.34597 7.4843 3.34597 6.8907 3.71209 6.52459Z"
                fill="#FFF"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div
          className={classNames('my-[10px] font-normal text-base text-text_2', {
            hidden: accordion,
          })}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};
