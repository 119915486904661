import { useTranslation } from 'react-i18next';
import { useGetFullBalance } from 'src/api/balance';
import { BlockSeparator } from 'src/components/BlockSeparator';
import { PersonalAreaBonusBalance } from 'src/components/PersonalAreaBonusBalance';
import { PersonalAreaFreeSpins } from 'src/components/PersonalAreaFreeSpins';
import { PersonalAreaPromocode } from 'src/components/PersonalAreaPromoCode';
import { PesonalAreaBonusQueue } from 'src/components/PesonalAreaBonusQueue';
import { Wrapper } from 'src/components/Wrapper/Wrapper';
import { BonusType } from 'src/types';

export const PersonalAreaPromo = () => {
  const { bonusAccountCasino } = useGetFullBalance();

  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col justify-start items-center">
      <Wrapper padding="pt-10">
        <div className="max-w-[975px] flex flex-col gap-7">
          <p className="font-bold text-2xl text-text_2 r_small:text-center uppercase hidden lg:block">
            {t('accountPromo.title')}
          </p>
          <PersonalAreaFreeSpins />
          <BlockSeparator />
          {bonusAccountCasino && (
            <PersonalAreaBonusBalance
              bonusAccount={bonusAccountCasino}
              bonusType={BonusType.Casino}
            />
          )}
          <BlockSeparator />
          <PesonalAreaBonusQueue bonusType={BonusType.Casino} />
          <BlockSeparator />
          <PersonalAreaPromocode />
        </div>
      </Wrapper>
    </div>
  );
};
