import React from 'react';
import classNames from 'classnames';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  tabs: Tab[];
  verticalLayout?: boolean;
  containerClassname?: string;
  wideTab?: boolean;
};

type Tab = {
  label: string;
  key: string;
  children: React.ReactNode;
};

export const Tabs = ({
  tabs,
  verticalLayout = false,
  containerClassname = '',
  wideTab = false
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col gap-10">
      <div
        className={classNames(
          'rounded-[20px] border border-border_20 flex justify-between overflow-auto',
          verticalLayout ? 'flex-col' : '',
          containerClassname
        )}>
        {tabs.map((tab) => (
          <div
            onClick={() => {
              navigate(`${tab.key}`);
            }}
            key={tab.key}
            className={classNames(
              'px-[1px] py-[1px] font-bold text-sm cursor-pointer w-full text-center  uppercase',
              location.pathname.includes(tab.key)?  'rounded-[20px] bg-orange-gradient': 'text-text_2',
              verticalLayout ? 'rounded-[17px]' : '',
              wideTab ? `w-[calc(100%/${tabs.length})] text-center` : ''
            )}>
            <div className={'w-full h-full bg-background_2  px-[18px] py-3 rounded-[20px]'}>
              <div className={classNames( location.pathname.includes(tab.key) ? 'bg-orange-gradient gradient-text': '' )}>{tab.label}</div>
            </div>
          </div>
        ))}
      </div>

      <Routes>
        {tabs?.map((tab) => {
          return <Route element={tab.children} path={tab.key} key={tab.key} />;
        })}
        <Route path="*" element={<Navigate to={tabs[0].key} replace />} />
      </Routes>
    </div>
  );
};
