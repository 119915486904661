import { useDrawer } from 'src/hooks/useDrawer';

export const GameHeaderBurger = () => {
  const { openLeftDrawer, closeBottomDrawer } = useDrawer();
  return (
    <div
      className="flex flex-row flex-wrap items-center cursor-pointer"
      onClick={() => {
        openLeftDrawer?.();
        closeBottomDrawer?.();
      }}>
      <svg
        width="45"
        height="46"
        viewBox="0 0 45 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.375 13.625C9.375 12.5896 10.2146 11.75 11.25 11.75H33.75C34.7871 11.75 35.625 12.5896 35.625 13.625C35.625 14.6621 34.7871 15.5 33.75 15.5H11.25C10.2146 15.5 9.375 14.6621 9.375 13.625ZM9.375 23C9.375 21.9629 10.2146 21.125 11.25 21.125H33.75C34.7871 21.125 35.625 21.9629 35.625 23C35.625 24.0371 34.7871 24.875 33.75 24.875H11.25C10.2146 24.875 9.375 24.0371 9.375 23ZM33.75 34.25H11.25C10.2146 34.25 9.375 33.4121 9.375 32.375C9.375 31.3379 10.2146 30.5 11.25 30.5H33.75C34.7871 30.5 35.625 31.3379 35.625 32.375C35.625 33.4121 34.7871 34.25 33.75 34.25Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
