// import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { useApi } from 'src/hooks/useApi';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';
import { useModal } from 'src/hooks/useModal';

import { useGetUserCountry } from '../profile';
import { changePassword, fullRegistration, getJWT, login, logout, registration, resetPassword } from './auth.requests';
import {
  ChangePasswordBody,
  GETJWTResponse,
  LoginBody,
  LoginResponse,
  LogoutBody,
  LogoutResponse,
  RegistrationBody,
  ResetPasswordBody,
  ResetPasswordResponse,
} from './auth.types';

export function useLogin({
  onSuccess,
  ...restOptions
}: Omit<UseMutationOptions<AxiosResponse<LoginResponse, any>, AxiosError, LoginBody>, 'mutationFn'>) {
  const { saveToken } = useApi();
  const { closeModal } = useModal();
  const { i18n } = useTranslation();

  return useMutation((data: LoginBody) => login({ ...data, language: data.language ?? i18n.language }), {
    ...restOptions,
    onSuccess: (response, ...args) => {
      saveToken?.(response.data.sessionUuid);
      localStorage.setItem('idToken', response.data.sessionUuid);
      localStorage.setItem('userId', response.data?.userId?.toString());
      closeModal?.();
      onSuccess?.(response, ...args);
    },
  });
}

export function useLogout({
  onSuccess,
  ...restOptions
}: Omit<UseMutationOptions<AxiosResponse<LogoutResponse, any>, AxiosError, LogoutBody>, 'mutationFn'>) {
  const { i18n } = useTranslation();

  return useMutation((data: LogoutBody) => logout({ language: data.language ?? i18n.language }), {
    ...restOptions,
    onSuccess: (...args) => {
      localStorage.removeItem('idToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('phone');
      localStorage.removeItem('email');
      onSuccess?.(...args);
    },
  });
}

export function useRegistration({ successReg, ...restOptions }: { successReg?: () => void }) {
  const { saveToken } = useApi();
  const { i18n } = useTranslation();
  const { userCountryCurrency } = useGetUserCountry({});

  return useMutation(
    (data: RegistrationBody) =>
      registration({
        ...data,
        currency: userCountryCurrency?.length ? userCountryCurrency?.[0] : undefined,
        language: data.language ?? i18n.language,
      }),
    {
      ...restOptions,
      onSuccess: (response) => {
        saveToken?.(response.data.sessionUuid);
        successReg?.();
        localStorage.setItem('idToken', response.data.sessionUuid);
        localStorage.setItem('userId', response.data?.userId?.toString());
      },
    },
  );
}

export function useFullRegistration() {
  return useMutation({
    mutationFn: fullRegistration,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
  });
}

export function useResetPassword({
  onSuccess,
  ...restOptions
}: Omit<UseMutationOptions<AxiosResponse<ResetPasswordResponse, any>, AxiosError, ResetPasswordBody>, 'mutationFn'>) {
  const { i18n } = useTranslation();

  return useMutation(
    (data: ResetPasswordBody) => resetPassword({ ...data, language: data.language ?? i18n.language }),
    {
      ...restOptions,
      onSuccess: (response, ...args) => {
        onSuccess?.(response, ...args);
      },
    },
  );
}

export function useChangePassword() {
  const { openMessage } = useMessage();
  const { i18n, t } = useTranslation();

  return useMutation(
    (data: ChangePasswordBody) => changePassword({ ...data, language: data.language ?? i18n.language }),
    {
      onError: (error: AxiosError<{ message: string }>) => {
        console.log({ error });
      },
      onSuccess: () => {
        openMessage?.(`${t('accountProfile.passwordChangedSuccessfully')}`, MESSAGE_STATUS.SUCCESS);
      },
    },
  );
}

export function useGetJWT({ onSuccess }: { onSuccess?: (data: AxiosResponse<GETJWTResponse, any>) => void }) {
  const { data: response, ...query } = useQuery<AxiosResponse<GETJWTResponse>, AxiosError>(
    ['JWTAuth'],
    () => getJWT(),
    {
      onSuccess: onSuccess,
      refetchInterval: 270000,
      suspense: true,
    },
  );

  return {
    jwt: response?.data?.jwt,
    ...query,
  };
}
