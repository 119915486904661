import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetFullInfo, useUpdateInfo } from '../../api/profile';
import { days, months, phoneCodes, years } from '../../constants';
import {
  FieldType,
  useForm,
  validateMaxLength,
  validateNumbersString,
  validatePhoneByCountryCode
} from '../../hooks/useForm';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { getPhoneCodeByCountryCode } from '../../utils';
import { Autocomplete } from '../Autocomplete';
import { Button } from '../Buttons/Button';
import { Dropdown } from '../Dropdowns/Dropdown';
import { Input } from '../Inputs/Input';
import { RadioGroup } from '../RadioGroup';

export const UpdateUserInformation = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const { isLoading, mutateAsync } = useUpdateInfo({
    onChange: () => openModal?.(MODAL_NAME.POPUP_DEPOSIT, { isUserFilled: true })
  });
  const {
    errors,
    fieldsProps: {
      addressProps,
      cityProps,
      countryCodeProps,
      dayProps,
      genderProps,
      monthProps,
      nameProps,
      phoneNumberProps,
      postcodeProps,
      surnameProps,
      yearProps
    },
    setValues,
    touched,
    values
  } = useForm<{
    address: FieldType.Text;
    city: FieldType.WithoutWildcards;
    countryCode: FieldType.Autocomplete;
    day: FieldType.Dropdown;
    gender: FieldType.Radio;
    month: FieldType.Dropdown;
    name: FieldType.Text;
    phoneNumber: FieldType.Number;
    postcode: FieldType.WithoutWildcards;
    surname: FieldType.Text;
    year: FieldType.Dropdown;
  }>({
    address: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.fullAddress')}`,
      validation: [validateMaxLength(256)]
    },
    city: {
      fieldType: FieldType.WithoutWildcards,
      placeholder: `${t('general.city')}`,
      validation: [validateMaxLength(256)]
    },
    countryCode: {
      fieldType: FieldType.Autocomplete,
      options: phoneCodes,
      placeholder: `${t('general.selectCode')}`,
      validation: [validateMaxLength(15)]
    },
    day: {
      fieldType: FieldType.Dropdown,
      options: days,
      placeholder: `${t('general.day')}`,
      validation: []
    },
    gender: {
      fieldType: FieldType.Radio,
      options: [
        { id: 'm', name: `${t('accountProfileMain.male')}` },
        { id: 'f', name: `${t('accountProfileMain.female')}` }
      ]
    },
    month: {
      fieldType: FieldType.Dropdown,
      options: months,
      placeholder: `${t('general.month')}`,
      validation: []
    },
    name: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.firstName')}`,
      validation: [validateMaxLength(256)]
    },
    phoneNumber: {
      fieldType: FieldType.Number,
      placeholder: `${t('general.phoneNumber')}`,
      validation: [validateMaxLength(20), validateNumbersString, validatePhoneByCountryCode]
    },
    postcode: {
      fieldType: FieldType.WithoutWildcards,
      placeholder: `${t('general.postCode')}`,
      validation: [validateMaxLength(100)]
    },
    surname: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.lastName')}`,
      validation: [validateMaxLength(256)]
    },
    year: {
      fieldType: FieldType.Dropdown,
      options: years,
      placeholder: `${t('general.year')}`,
      validation: []
    }
  });

  const prepareDate = useCallback(() => {
    return values.day && values.month && values.year
      ? dayjs()
          .date(parseInt(values.day))
          .month(parseInt(values.month) - 1)
          .year(parseInt(values.year))
          .format('YYYY-MM-DD')
      : undefined;
  }, [values.day, values.month, values.year]);

  const handleClick = useCallback(() => {
    mutateAsync({
      address: values.address ? values.address.replaceAll(' ', '') : undefined,
      birthday: prepareDate(),
      city: values.city ? values.city.replaceAll(' ', '') : undefined,
      code: values.countryCode ? values.countryCode?.replace('+', '') : undefined,
      name: values.name ? values.name.replaceAll(' ', '') : undefined,
      phone: values.phoneNumber ? values.phoneNumber.replaceAll(' ', '') : undefined,
      postcode: values.postcode ? values.postcode.replaceAll(' ', '') : undefined,
      sex: values.gender ? values.gender : undefined,
      surname: values.surname ? values.surname.replaceAll(' ', '') : undefined
    });
  }, [
    mutateAsync,
    prepareDate,
    values.gender,
    values.name,
    values.surname,
    values.address,
    values.city,
    values.postcode,
    values.countryCode,
    values.phoneNumber
  ]);

  useGetFullInfo({
    onSuccess: (response) => {
      const birthDate = dayjs(response?.data?.birthday);

      setValues({
        address: response.data.address,
        city: response.data.city,
        countryCode: getPhoneCodeByCountryCode(response.data.country),
        day: response?.data?.birthday === '' ? undefined : `${birthDate.date()}`,
        gender: response.data.sex,
        month: response?.data?.birthday === '' ? undefined : `${birthDate.month() + 1}`,
        name: response.data.name,
        phoneNumber: response.data.phone.number,
        postcode: response.data.postcode,
        surname: response.data.surname,
        year: response?.data?.birthday === '' ? undefined : `${birthDate.year()}`
      });
    }
  });

  return (
    <div className="w-full h-full r_sm:w-[340px] r_xsm:w-full r_lg:w-[420px] r_xl:w-[420px] r_xxl:w-[420px]">
      <div className="modal-wrapper flex flex-col gap-[28px] items-center pt-[30px] px-[10px] pb-[50px] !overflow-y-auto bg-[#000]">
        <div className="flex flex-col gap-[20px]">
          <p className="font-bold text-[26px] text-center leading-[1.15] text-text_14 uppercase">
            {t('registration.fillInformation')}
          </p>
          <div className="flex flex-col gap-[16px]">
            <div>
              <Input {...nameProps} className="placeholder:text-[14px]" />
              {touched.name && errors.name && (
                <span className="text-[red] text-xs">{errors.name}</span>
              )}
            </div>
            <div>
              <Input {...surnameProps} className="placeholder:text-[14px]" />
              {touched.surname && errors.surname && (
                <span className="text-[red] text-xs">{errors.surname}</span>
              )}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-around gap-2 self-center w-full">
                <Dropdown {...dayProps} classNameArrow="right-2.5 r_sm:right-[5px]" />
                <Dropdown {...monthProps} classNameArrow="right-2.5 r_sm:right-[5px]" />
                <Dropdown {...yearProps} classNameArrow="right-2.5 r_sm:right-[5px]" />
              </div>
            </div>
          </div>
          <div>
            <RadioGroup {...genderProps} checkValue={values?.gender} />
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="flex w-full justify-between gap-3">
              <div className="w-[35%] r_lg:w-[30%] r_xl:w-[30%] r_xxl:w-[30%]">
                <Autocomplete
                  {...countryCodeProps}
                  emptyStateContent="autocompleteEmptyState.noSuchCode"
                />
              </div>
              <div className="w-[65%] r_lg:w-[70%] r_xl:w-[70%] r_xxl:w-[70%]">
                <Input {...phoneNumberProps} />
                {touched.phoneNumber && errors.phoneNumber && (
                  <span className="text-[red] text-xs">{t(errors.phoneNumber)}</span>
                )}
              </div>
            </div>
            <Input {...addressProps} />
            {touched.address && errors.address && (
              <span className="text-[red] text-xs">{t(errors.address)}</span>
            )}
            <div className="flex w-full justify-center gap-3">
              <div>
                <Input {...cityProps} />
                {touched.city && errors.city && (
                  <span className="text-[red] text-xs">{t(errors.city)}</span>
                )}
              </div>
              <div>
                <Input {...postcodeProps} />
                {touched.postcode && errors.postcode && (
                  <span className="text-[red] text-xs">{t(errors.postcode)}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button
          additionalClassName="r_3xl:max-w-[140px]"
          loading={isLoading}
          mode="primary"
          onClick={() => handleClick()}
          title={`${t('general.deposit')}`}>
          {t('general.deposit')}
        </Button>
      </div>
    </div>
  );
};
