import { useMemo } from 'react';
import { useGetSinglePage } from 'src/api/content';

export const VipRulesModal = () => {
  const { data } = useGetSinglePage('loyalty_rules');
  const rules = useMemo(() => data?.childs?.find((child) => child.code === 'loyalty_main'), [data]);
  return (
    <div className="flex flex-col items-center gap-5 p-[15px] w-[400px] r_mid:w-full h-full">
      {rules ? (
        <>
          <p className="text-text_8 text-[26px] font-bold text-center">{rules?.title}</p>
          <div dangerouslySetInnerHTML={{ __html: rules.content }} className='text-text_2 pb-6'></div>
        </>
      ) : null}
    </div>
  );
};
