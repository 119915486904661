import { apiProvider } from '..';
import {
  ChangeCurrencyBody,
  ChangeCurrencyResponse,
  FullBalanceResponse,
  ShortBalanceResponse,
  WithdrawCancelResponse,
  WithdrawDetailsResponse
} from './balance.types';

// TODO X-Authorization, language
export const fullBalance = () => {
  return apiProvider.request<FullBalanceResponse>({
    method: 'get',
    url: '/balance/full-balance'
  });
};

// TODO X-Authorization, language
export const shortBalance = () => {
  return apiProvider.request<ShortBalanceResponse>({
    method: 'get',
    url: '/balance/short-balance'
  });
};

// TODO X-Authorization, language
export const changeCurrency = (data: ChangeCurrencyBody) => {
  return apiProvider.request<ChangeCurrencyResponse>({
    method: 'put',
    url: '/balance/change-currency',
    data
  });
};

export const withdrawDetails = () => {
  return apiProvider.request<WithdrawDetailsResponse>({
    method: 'get',
    url: '/balance/withdraw-details'
  });
};

export const withdrawCancel = (transactionId: number) => {
  return apiProvider.request<WithdrawCancelResponse>({
    method: 'post',
    url: `/balance/withdraw/cancel/${transactionId}`
  });
};
