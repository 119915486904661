export const FreeSpin = ({
  description,
  value
}: {
  description: string;
  value: string | number;
}) => {
  return (
    <div className="flex items-center justify-between gap-5">
      <p className=" text-lg flex-grow r_sm:text-sm w-[80%] text-start flex flex-row items-start text-text_2">
        {description}
      </p>
      <p className="text-lg font-bold w-1/2 r_sm:text-base text-text_2">{value}</p>
    </div>
  );
};
