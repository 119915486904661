import { useApi } from 'src/hooks/useApi';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useGetShortBalance } from 'src/api/balance';

export type RefetchParamsBody = {
  language?: string;
  currency?: string;
};

export type RefetchDependencies = (string | boolean | undefined)[];

export type UseShouldRefetch = {
  lang?: boolean;
  currency?: boolean;
  customCurrency?: string;
};

export const useShouldRefetch = ({ lang, currency, customCurrency }: UseShouldRefetch = {}) => {
  const { isAuthenticated } = useApi();
  const { data: { currency: userCurrency } = {} } = useGetShortBalance();
  const {
    i18n: { language }
  } = useTranslation();

  return useMemo(() => {
    const currencyShouldRefetch = (isAuthenticated && !!userCurrency) || !isAuthenticated;

    const refetchParams: RefetchParamsBody = {};
    const refetchDependencies: RefetchDependencies = [];

    if (lang) {
      refetchParams.language = language ? language : 'en';
      refetchDependencies.push(language ? language : 'en');
    }

    if (currency) {
      refetchParams.currency = isAuthenticated ? userCurrency : 'EUR';
      refetchDependencies.push(isAuthenticated, userCurrency);
    }

    if (customCurrency) {
      refetchParams.currency = customCurrency;
      refetchDependencies.push(customCurrency);
    }

    return { refetchParams, refetchDependencies, currencyShouldRefetch };
  }, [isAuthenticated, currency, customCurrency, lang, language, userCurrency]);
};
