import { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { apiProvider } from '../api';

export const TokenContext = createContext<{
  token?: string;
  language?: string;
  apiProviderInstance?: () => void;
  isAuthenticated?: boolean;
  saveToken?: (token: string) => void;
  clearToken?: () => void;
  tokenReady?: boolean;
  saveJWT?: (token: string) => void;
}>({});

export const useApiOptions = () => {
  const [token, setToken] = useState('');
  const isAuthenticated = useMemo(() => Boolean(token), [token]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [language] = useState('en');
  const [tokenReady, setTokenReady] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    if (!token) {
      setTokenReady(true);
    }
  }, []);

  const saveToken = useCallback((token: string) => {
    setTokenReady(false);
    setToken(token);
    apiProvider.defaults.headers.common['X-Authorization'] = token;

    setTokenReady(true);
  }, []);
  const saveJWT = useCallback((token: string) => {
    apiProvider.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, []);
  const clearToken = useCallback(() => {
    setToken('');
    localStorage.removeItem('idToken');
    localStorage.removeItem('userId');
    delete apiProvider.defaults.headers.common['X-Authorization'];
    setTokenReady(true);
  }, []);
  return { token, language, saveToken, isAuthenticated, clearToken, tokenReady, saveJWT };
};

export const useApi = () => {
  return useContext(TokenContext);
};
