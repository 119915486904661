import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import './styles.css';

type WithBackgroundProps = {
  children: ReactNode;
};

export const WithBackground: FC<WithBackgroundProps> = ({ children }) => {
  const location = useLocation();

  return (
    <div
      className={classNames(
        'account-layout-wrapper',
        location.pathname.includes('account/vip') ? 'account-layout-wrapper-vip-location' : ''
      )}>
      {children}
    </div>
  );
};
