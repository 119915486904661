import { useTranslation } from 'react-i18next';
import { Tabs } from 'src/components/Tabs';
import { BasicTransactionHistory } from './BasicTransactionHistory';
import { BonusTransactionHistory } from './BonusTransactionHistory';
import { Wrapper } from 'src/components/Wrapper/Wrapper';

export const History = () => {
  const { t } = useTranslation();
  const profileTabs = [
    {
      label: `${t('accountWalletTabs.basic')}`,
      key: 'basic',
      children: <BasicTransactionHistory />
    },
    {
      label: `${t('accountWalletTabs.bonus')}`,
      key: 'bonus',
      children: <BonusTransactionHistory />
    }
  ];

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <Wrapper padding="pt-10">
        <div className="flex flex-col gap-[30px]">
          <p className="font-bold text-2xl text-text_2 uppercase hidden lg:block">
            {t('accountWalletTabs.title')}
          </p>
          <Tabs tabs={profileTabs} containerClassname="max-w-[550px]" wideTab />
        </div>
      </Wrapper>
    </div>
  );
};
