import { FieldType, useForm } from '../../hooks/useForm/index';
import { DropDownOption } from '../../types';

export const useGameHistoryForm = (transactionTypesOptions: DropDownOption[]) => {
  return useForm<{
    transactionTypes: FieldType.Multiselect;
    dateFrom: FieldType.Date;
    dateTo: FieldType.Date;
    amountFrom: FieldType.Number;
    amountTo: FieldType.Number;
  }>({
    transactionTypes: {
      placeholder: 'Transaction types',
      fieldType: FieldType.Multiselect,
      options: transactionTypesOptions
    },
    dateFrom: {
      fieldType: FieldType.Date
    },
    dateTo: {
      fieldType: FieldType.Date
    },
    amountFrom: {
      placeholder: 'Amount from',
      fieldType: FieldType.Number
    },
    amountTo: {
      placeholder: 'Amount to',
      fieldType: FieldType.Number
    }
  });
};
