import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useActivateBonusViaLink } from 'src/api/bonuses';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

const BONUS_VIA_LINK_LS_KEY = 'activateBonusIdLater';

/**
 * Один раз вызывается на главной
 */
export function useCheckBonusInLink() {
  const { isAuthenticated } = useApi();
  const { openModal } = useModal();
  const { bonusId } = useParams<{ bonusId: string }>();
  const { mutateAsync } = useActivateBonusViaLink();
  const navigate = useNavigate();

  const endFlow = () => {
    navigate('/');
    clearSavedBonus();
  };

  useEffect(() => {
    (async () => {
      if (bonusId && !isAuthenticated) {
        openModal?.(MODAL_NAME.SIGNUP);
        localStorage.setItem(BONUS_VIA_LINK_LS_KEY, bonusId);
        return;
      }
      if (bonusId) {
        await mutateAsync(
          { bonus_id: bonusId },
          {
            onSuccess: () => {
              endFlow();
            },
            onError: () => {
              endFlow();
            }
          }
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusId, isAuthenticated]);
}

/**
 * Функция возвращаемая отсюда должна быть вызвана после успешной регистрации.
 * Она активирует бонус который был в ссылке. Ожидается что пользователь уже зареган
 */
export function useCallbackCheckForBonusToActivate() {
  const { mutateAsync } = useActivateBonusViaLink();
  return useCallback(() => {
    const bonusId = localStorage.getItem(BONUS_VIA_LINK_LS_KEY);
    if (bonusId) {
      mutateAsync({ bonus_id: bonusId });
      clearSavedBonus();
    }
  }, [mutateAsync]);
}

function clearSavedBonus() {
  localStorage.removeItem(BONUS_VIA_LINK_LS_KEY);
}
