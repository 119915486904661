export const MessageError = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 0C-3.33177 0.418357 -3.33495 19.5796 10.0001 20C23.3317 19.5816 23.3349 0.420286 10.0001 0Z"
        fill="#F44336"
      />
      <path
        d="M11.0205 10.0894L14.0785 7.03122C14.7494 6.38013 13.7109 5.34099 13.0594 6.01213L10.0012 9.07021L6.94329 6.01213C6.29183 5.34099 5.25328 6.38013 5.92419 7.03122L8.98227 10.0894L5.92434 13.1475C5.25342 13.7986 6.2919 14.8378 6.94343 14.1666L10.0012 11.1085L13.0592 14.1666C13.7106 14.8378 14.7493 13.7986 14.0783 13.1475L11.0205 10.0894Z"
        fill="white"
      />
    </svg>
  );
};

export const MessageSuccess = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99997 0C-3.33174 0.418357 -3.33491 19.5796 9.99997 20C23.3318 19.5816 23.3349 0.420286 9.99997 0Z"
        fill="#52C41A"
      />
      <path
        d="M15.553 7.10741L8.56744 14.0923C8.50055 14.1592 8.42112 14.2123 8.33368 14.2486C8.24625 14.2848 8.15253 14.3035 8.05789 14.3035C7.96325 14.3035 7.86953 14.2848 7.7821 14.2486C7.69466 14.2123 7.61523 14.1592 7.54834 14.0923L4.44373 10.9877C3.77281 10.3366 4.8113 9.29741 5.46284 9.96855L8.05789 12.5635L14.5339 6.0883C15.1854 5.41716 16.2239 6.45631 15.553 7.10741Z"
        fill="white"
      />
    </svg>
  );
};

export const MessageDefault = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3266 0.536937L22.5988 18.3781C22.9232 18.9187 22.7069 19.5675 22.1663 19.8919C21.95 20 21.8419 20 21.6256 20H1.08128C0.432512 20 0 19.5675 0 18.9187C0 18.7025 2.41685e-08 18.5943 0.108128 18.3781L10.3803 0.536937C10.7047 -0.003704 11.3535 -0.111832 11.8941 0.104424C12.1104 0.212552 12.2185 0.32068 12.3266 0.536937ZM10.2722 14.5936V16.7562H12.4347V14.5936H10.2722ZM10.2722 7.02462V12.431H12.4347V7.02462H10.2722Z"
        fill="#FAAD14"
      />
    </svg>
  );
};
