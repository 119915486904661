import { ReactNode } from 'react';
import { TableHeader } from './TableHeader';

type Props = {
  header: string[];
  tableRow?: ReactNode;
};

export const Table = ({ header, tableRow }: Props) => {
  return (
    <table className="w-full">
      <thead className="rounded-md">
        <tr>
          <TableHeader header={header} />
        </tr>
      </thead>
      <tbody>{tableRow}</tbody>
    </table>
  );
};
