import { apiProvider } from '..';
import {
  AllBonusesTransactionsBody,
  AllBonusesTransactionsResponse,
  AllFreeSpinsResponse,
  BonusTransaction,
  CancelBonusWagerBody,
  CancelBonusWagerResponse,
  UpdateBonusStatusBody,
  UpdateBonusStatusResponse
} from './bonusesTransaction.types';

// TODO X-Authorization, language
export const allBonusesTransactions = (params: AllBonusesTransactionsBody) => {
  return apiProvider.request<AllBonusesTransactionsResponse>({
    method: 'get',
    url: '/bonuses-transactions',
    params
  });
};

export const singleBonusTransaction = (id: number) => {
  return apiProvider.request<BonusTransaction>({
    method: 'get',
    url: `/bonuses-transactions/${id}`
  });
};

// TODO X-Authorization, language
export const allFreeSpins = () => {
  return apiProvider.request<AllFreeSpinsResponse>({
    method: 'get',
    url: '/bonuses-transactions/all-free-spins'
  });
};

// TODO X-Authorization, language
export const updateBonusStatus = (data: UpdateBonusStatusBody) => {
  return apiProvider.request<UpdateBonusStatusResponse>({
    method: 'put',
    url: '/bonuses-transactions/update-bonus-status',
    data
  });
};

// TODO X-Authorization, language
export const cancelBonusWager = (data: CancelBonusWagerBody) => {
  return apiProvider.request<CancelBonusWagerResponse>({
    method: 'put',
    url: '/bonuses-transactions/cancel-bonus-wager',
    data
  });
};
