import { apiProvider } from '..';
import { AllKycInfoResponse, UploadKycFilesResponse } from './kyc.types';

// TODO X-Authorization, language
export const allKycInfo = () => {
  return apiProvider.request<AllKycInfoResponse>({
    method: 'get',
    url: '/kyc/all-kyc-info'
  });
};

// TODO X-Authorization, language, UploadKycFilesBody
export const uploadKycFiles = (data: FormData) => {
  return apiProvider.request<UploadKycFilesResponse>({
    method: 'post',
    url: '/kyc/upload-kyc-files',
    data
  });
};
