import { useRef } from 'react';
import { useMultiselect } from '../../hooks/useDropdown';
import { DropDownOption } from '../../types';
import { Arrow, CrossOnElement } from '../svg';
import classNames from 'classnames';
import './styles.css';
import { useClickOutside } from '../../hooks/useClickOutside';

export const Multiselect = ({
  values,
  onChange,
  options = [],
  placeholder,
  zIndex = 10,
  onBlur,
  label,
  labelWithPadding
}: {
  values?: string[];
  onChange?: (selectedValues: string[], selectedOptions: DropDownOption[]) => void;
  options?: DropDownOption[];
  placeholder?: string;
  zIndex?: number;
  onBlur?: () => void;
  label?: string;
  labelWithPadding?: boolean;
}) => {
  const multiselectRef = useRef<HTMLDivElement | null>(null);
  const { onSelect, showList, selectedArray, toggle, close, isSelected } = useMultiselect({
    values,
    options,
    onChange,
    onBlur
  });

  useClickOutside(multiselectRef, close);

  return (
    <div className="relative text-[14px] w-full" ref={multiselectRef} style={{ zIndex }}>
      {label ? (
        <label
          className={classNames(
            'text-base mb-2 block text-text_2',
            labelWithPadding && 'pl-[20px]'
          )}>
          {label}
        </label>
      ) : null}
      <div
        className="flex relative flex-row items-center border-1 border-solid border-border_3 rounded-[20px] bg-background_15 min-h-[42px] px-5"
        onClick={toggle}
        style={{ zIndex: zIndex + 1 }}>
        {selectedArray.length === 0 ? (
          <p className="text-text_6 w-full capitalize text-[16px]">{placeholder}</p>
        ) : (
          <div className="flex mr-8 gap-2 overflow-auto">
            {selectedArray.map((element) => {
              return (
                <div
                  key={element.id}
                  className="flex flex-row rounded-[20px] p-[5px] bg-background_1 cursor-pointer items-center w-full whitespace-nowrap"
                  onClick={() => onSelect(element.id, element)}>
                  <p className="text-text_2 text-[12px]">{element.name}</p>
                  <CrossOnElement />
                </div>
              );
            })}
          </div>
        )}
        <Arrow
          className={classNames(
            'absolute right-5 w-[12px] h-full stroke-[3px] transition-transform text-text_6',
            { opened: showList }
          )}
        />
      </div>
      {showList && (
        <div
          style={{ zIndex }}
          className="entrance absolute rounded-b-[20px] top-[55px] bg-[white] flex flex-col items-start overflow-y-scroll w-full max-h-[200px] pt-[20px] pb-2 shadow">
          {options.map((option) => (
            <div
              className={classNames(
                'text-[12px] cursor-pointer py-2 px-5 w-full hover:bg-background_1 hover:text-text_2',
                {
                  'bg-background_1 text-text_2': isSelected(option.id)
                }
              )}
              onClick={() => onSelect(option.id, option)}
              key={option.id}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
