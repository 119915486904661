export const VerificarionPayment = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_4859_19667)">
        <path
          d="M15.75 3H2.25C1.42157 3 0.75 3.67157 0.75 4.5V13.5C0.75 14.3284 1.42157 15 2.25 15H15.75C16.5784 15 17.25 14.3284 17.25 13.5V4.5C17.25 3.67157 16.5784 3 15.75 3Z"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 7.5H17.25"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4859_19667">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VerificarionAddress = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_4859_19716)">
        <path
          d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4859_19716">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VerificarionIdentity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_4859_19678)">
        <path
          d="M12 15.75V14.25C12 13.4544 11.6839 12.6913 11.1213 12.1287C10.5587 11.5661 9.79565 11.25 9 11.25H3.75C2.95435 11.25 2.19129 11.5661 1.62868 12.1287C1.06607 12.6913 0.75 13.4544 0.75 14.25V15.75"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 8.25C8.03185 8.25 9.375 6.90685 9.375 5.25C9.375 3.59315 8.03185 2.25 6.375 2.25C4.71815 2.25 3.375 3.59315 3.375 5.25C3.375 6.90685 4.71815 8.25 6.375 8.25Z"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 8.25L14.25 9.75L17.25 6.75"
          stroke="#FFF"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4859_19678">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Star = ({ classNames }: { classNames?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={classNames}>
      <path
        d="M13.8515 16.8755C13.7332 16.876 13.6176 16.8391 13.5214 16.7701L8.99999 13.4921L4.47854 16.7701C4.38194 16.8401 4.26557 16.8777 4.14624 16.8772C4.02691 16.8768 3.91081 16.8384 3.81472 16.7677C3.71863 16.6969 3.64754 16.5974 3.61172 16.4836C3.5759 16.3697 3.57721 16.2475 3.61545 16.1344L5.37889 10.9113L0.808581 7.7771C0.709584 7.70929 0.634868 7.61159 0.595348 7.49829C0.555828 7.38499 0.553575 7.26202 0.588919 7.14735C0.624263 7.03268 0.695351 6.93232 0.791797 6.86092C0.888243 6.78953 1.00499 6.75085 1.12499 6.75054H6.76335L8.46491 1.51401C8.50156 1.40096 8.57309 1.30242 8.66921 1.23253C8.76534 1.16264 8.88114 1.125 8.99999 1.125C9.11883 1.125 9.23463 1.16264 9.33076 1.23253C9.42689 1.30242 9.49841 1.40096 9.53506 1.51401L11.2366 6.7523H16.875C16.9951 6.75224 17.1121 6.79065 17.2089 6.8619C17.3056 6.93316 17.377 7.03351 17.4125 7.14827C17.4481 7.26303 17.446 7.38616 17.4065 7.49964C17.367 7.61311 17.2923 7.71096 17.1931 7.77886L12.6211 10.9113L14.3835 16.133C14.412 16.2176 14.42 16.3077 14.4069 16.396C14.3937 16.4842 14.3598 16.5681 14.3078 16.6406C14.2558 16.7132 14.1874 16.7723 14.108 16.8131C14.0287 16.854 13.9408 16.8754 13.8515 16.8755Z"
        fill="#FFF"
      />
    </svg>
  );
};

export const Clock = ({ classNames }: { classNames?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M10.0001 18.3337C14.6025 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.6027 5.39771 18.3337 10.0001 18.3337Z"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V10L13.3333 11.6667"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
