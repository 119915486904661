import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import gift2 from 'src/assets/images/gift2.webp';
import { countriesOptions, mapCountryPhoneCode } from 'src/constants';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { sendGoogleAnalyticsEvent } from 'src/utils/sendGoogleAnalyticsEvent';

import { useRegistration } from '../../api/auth';
import { useGetBlacklistEmails } from '../../api/blacklist';
import { useGetUserCountry, useSendSMSCode, useUpdateSubscriptions, useVerifySMSCode } from '../../api/profile';
import {
  FieldType,
  useForm,
  validateCountry,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validatePhoneFormat,
  validateRequired,
} from '../../hooks/useForm/index';
import { MESSAGE_STATUS, useMessage } from '../../hooks/useMessage';
import { usePasswordChecklist } from '../../hooks/usePasswordChecklist';
import { findCountryCodeByName, findCountryNameByCode } from '../../utils/index';
import { calculateSeconds, generatePassword } from '../../utils/timer';
import { Autocomplete } from '../Autocomplete';
import { Button } from '../Buttons/Button';
import { Checkbox } from '../Checkbox/index';
import { Input } from '../Inputs/Input';
import { PasswordInput } from '../Inputs/PasswordInput';
// import { OrangeTab } from '../OrangeTab';
import { parseAffilliateReferralLink } from '../ReferralLinkHandler/index';
// import { SmsTimer } from '../SmsTimer';

const staticBonus = {
  descriptionKey: 'staticBonus.description',
  title: '225% + 150 FS',
};

enum SignUpMethodEnum {
  Email = 'Email',
  Phone = 'Phone',
}
export const SignUpModal = () => {
  const { mutateAsync: updateSubscription } = useUpdateSubscriptions();
  const { isLoading: isLoadingSmsCode, mutateAsync: sendSMSCode } = useSendSMSCode();
  const { isLoading: isLoadingVerifySMSCode, mutateAsync: verifySmsCode } = useVerifySMSCode();
  const { openMessage } = useMessage();
  const { t } = useTranslation();
  const { isAuthenticated } = useApi();
  const [searchParams] = useSearchParams();
  const { isEmailBlocked } = useGetBlacklistEmails();
  const { userCountry } = useGetUserCountry({});
  const [signUpMethod] = useState<SignUpMethodEnum>(SignUpMethodEnum.Email);
  const [isConfirmedPhone, setIsConfirmedPhone] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const { openModal } = useModal();

  const { isLoading, mutateAsync } = useRegistration({
    successReg: () => openModal?.(MODAL_NAME.POPUP_DEPOSIT),
  });

  const {
    errors,
    fieldsProps: { countryProps, emailProps, is18yearsProps, newsProps, passwordProps },
    focused,
    isFormValid,
    setSomeValues,
    setSubmitTried,
    submitTried,
    updateDefaultStatePartially,
    values,
  } = useForm<{
    country: FieldType.Autocomplete;
    email: FieldType.Text;
    is18years: FieldType.Checkbox;
    news: FieldType.Checkbox;
    password: FieldType.Text;
    phone: FieldType.Phone;
    smsCode: FieldType.Number;
  }>({
    country: {
      fieldType: FieldType.Autocomplete,
      options: countriesOptions,
      placeholder: `${t('general.chooseYourCoutry')}`,
      validation: [validateRequired, validateCountry],
    },
    email: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.email')}`,
      validation:
        signUpMethod === SignUpMethodEnum.Email ? [validateRequired, validateMaxLength(256), validateEmail] : [],
    },
    is18years: {
      fieldType: FieldType.Checkbox,
      validation: [validateRequired],
    },
    news: {
      defaultValue: true,
      fieldType: FieldType.Checkbox,
    },
    password: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.password')}`,
      validation:
        signUpMethod === SignUpMethodEnum.Email ? [validateRequired, validateMaxLength(256), validatePassword] : [],
    },
    phone: {
      defaultValue: phoneCode,
      fieldType: FieldType.Phone,
      phoneCode: phoneCode,
      placeholder: `${t('general.phone')}`,
      validation: signUpMethod === SignUpMethodEnum.Email ? [] : [validateRequired, validatePhoneFormat],
    },
    smsCode: {
      fieldType: FieldType.Number,
      placeholder: `${t('general.confirmationCode')}`,
      validation: isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone ? [validateRequired] : [],
    },
  });

  useEffect(() => {
    setPhoneCode(values.country ? mapCountryPhoneCode[values.country] : '');
  }, [values.country]);

  useEffect(() => {
    setSomeValues({ phone: phoneCode });
  }, [phoneCode]);

  useGetUserCountry({
    onSuccess: (response) => {
      response.data.country &&
        updateDefaultStatePartially({
          country: findCountryNameByCode(response.data.country),
        });
    },
  });

  const handleClick = useCallback(async () => {
    const { affiliateId, affiliateUserId } = parseAffilliateReferralLink(searchParams.get('stag'));

    const registration = async () => {
      const unixTimestamp = Math.floor(new Date().getTime() / 1000);

      sendGoogleAnalyticsEvent(signUpMethod === SignUpMethodEnum.Phone ? 'user_signup_tel' : 'user_signup', {
        affiliate_id: affiliateId ?? null,
        geo: userCountry?.country,
        unix_timestamp: unixTimestamp,
      });

      try {
        await mutateAsync({
          country: findCountryCodeByName(values.country),
          email: values.email.trim(),
          password: values.password.trim(),
          ...(affiliateId && affiliateUserId ? { affiliate_id: affiliateId, affiliate_user_id: affiliateUserId } : {}),
        });

        updateSubscription({
          // eslint-disable-next-line camelcase
          email_bonuses_sub: values.news,
        });
      } catch (error) {
        openMessage?.(`${t('general.error')}`, MESSAGE_STATUS.ERROR);
      }
    };

    setSubmitTried(true);
    if (!isFormValid) return;

    if (!isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone) {
      const seconds = calculateSeconds();
      if (seconds > 0) {
        return openMessage?.(`The next SMS can be sent in ${seconds} seconds`, MESSAGE_STATUS.INFO);
      } else {
        await sendSMSCode(values.phone.replace(/\D+/g, '')).then(() => {
          // FOR DEBUG
          // await Promise.resolve().then(() => {
          setIsConfirmedPhone(true);
          localStorage.setItem(`callTime`, `${new Date().toISOString()}`);
        });
      }
      return;
    }

    if (isEmailBlocked(values.email)) {
      return openMessage?.(`${t('registration.regionBlocked')}`, MESSAGE_STATUS.ERROR);
    }

    if (isConfirmedPhone && signUpMethod === SignUpMethodEnum.Phone) {
      const verifyResult = await verifySmsCode({
        code: Number(values.smsCode),
        phone: values.phone.replace(/\D+/g, ''),
      });

      // FOR DEBUG
      // const verifyResult = await Promise.resolve({ data: { valid: true } });

      if (verifyResult.data.valid) {
        const email = `${values.phone.replace(/\D+/g, '')}-${Date.now()}@phone.com`;
        values.email = email;
        values.password = generatePassword(email);

        await registration();
      } else {
        openMessage?.(`${t('signUpModal.invalidCode')}`, MESSAGE_STATUS.ERROR);
      }
      return;
    }

    await registration();
  }, [
    setSubmitTried,
    isFormValid,
    isEmailBlocked,
    values.email,
    values.password,
    values.country,
    values.news,
    searchParams,
    openMessage,
    t,
    mutateAsync,
    updateSubscription,
  ]);

  const passwordChecklist = usePasswordChecklist(values.password);

  const renderEmail = () => (
    <div className="relative w-full">
      <Input
        id="email-input"
        {...emailProps}
        inputBorderColor={classNames(focused !== 'email' && submitTried && Boolean(errors.email) && 'border-border_4')}
      />
      {!focused && (
        <Tooltip
          anchorSelect="#email-input"
          className="rounded-4xl absolute bg-background_6 text-text_2"
          isOpen={submitTried && Boolean(errors.email)}
          place="top"
        >
          <div>{errors.email}</div>
        </Tooltip>
      )}
    </div>
  );

  // const renderPhone = () => {
  //  return (
  //    <div className="relative w-full">
  //      <Input
  //        id="phone-input"
  //        {...phoneProps}
  //        inputBorderColor={classNames(
  //          focused !== 'phone' && submitTried && Boolean(errors.phone) && 'border-border_4',
  //        )}
  //      />
  //      {errors.phone && (
  //        <Tooltip
  //          anchorSelect="#phone-input"
  //          className="rounded-4xl absolute bg-background_6 text-text_2"
  //          isOpen={submitTried && Boolean(errors.phone)}
  //          place="top"
  //        >
  //          <div>{errors.phone}</div>
  //        </Tooltip>
  //      )}
  //    </div>
  //  );
  // };
  const renderPassword = () => (
    <div className="relative w-full">
      <PasswordInput
        id="password-input"
        {...passwordProps}
        inputBorderColor={classNames(
          focused !== 'password' && submitTried && Boolean(errors.password) && 'border-border_4',
        )}
      />

      {!focused && (
        <Tooltip
          anchorSelect="#password-input"
          className="rounded-4xl absolute bg-background_6 text-text_2"
          isOpen={submitTried && Boolean(errors.password)}
          place="top"
        >
          <div>{errors.password}</div>
        </Tooltip>
      )}

      <Tooltip
        anchorSelect="#password-input"
        className="rounded-4xl absolute bg-background_6 text-text_2"
        openOnClick
        place="top"
      >
        <div>
          {passwordChecklist.map((error) => (
            <p
              className={classNames({ 'line-through': error.done })}
              key={error.text}
            >
              {error.text}
            </p>
          ))}
        </div>
      </Tooltip>
    </div>
  );

  const renderCountry = () => (
    <div className="relative w-full">
      <div
        className="w-full"
        id="country-autocomplete"
      >
        <Autocomplete
          {...countryProps}
          emptyStateContent="autocompleteEmptyState.noSuchCountry"
          inputBorderColor={classNames(
            focused !== 'country' && submitTried && Boolean(errors.country) && 'border-border_4',
          )}
        />
      </div>

      <Tooltip
        anchorSelect="#country-autocomplete"
        className="rounded-4xl absolute bg-background_6 text-text_2"
        isOpen={submitTried && Boolean(errors.country)}
        place="top"
      >
        <div>{errors.country}</div>
      </Tooltip>
    </div>
  );

  // const renderConfirmationCode = () => {
  //  const handleClick = async () => {
  //    return sendSMSCode(values.phone).then(() => {
  //      localStorage.setItem(`callTime`, `${new Date().toISOString()}`);
  //    });
  //  };
  //
  //  return (
  //    <div className="relative w-full">
  //      <Input
  //        id="confirmation-input"
  //        {...smsCodeProps}
  //        inputBorderColor={classNames(
  //          focused !== 'smsCode' && submitTried && Boolean(errors.smsCode) && 'border-border_4',
  //        )}
  //      />
  //      <SmsTimer sendAgainClick={handleClick} />
  //      {!focused && (
  //        <Tooltip
  //          anchorSelect="#email-input"
  //          className="rounded-4xl absolute bg-background_6 text-text_2"
  //          isOpen={submitTried && Boolean(errors.smsCode)}
  //          place="top"
  //        >
  //          <div>{errors.smsCode}</div>
  //        </Tooltip>
  //      )}
  //    </div>
  //  );
  // };

  return (
    <div className="h-full w-full r_lg:w-[420px] r_sm:w-[340px] r_xl:w-[420px] r_xxl:w-[420px] r_xsm:w-full">
      <div className="modal-wrapper flex flex-col items-center gap-[28px] !overflow-y-auto bg-[#000] px-[10px] pb-[50px] pt-[30px]">
        <div className="flex w-full flex-col gap-[20px]">
          <p className="text-center text-[26px] font-bold uppercase text-text_14">{t('general.signUp')}</p>
          <div className="flex w-full flex-col gap-[16px]">
            {/* EMAIL */}
            {renderEmail()}
            {/* PASSWORD */}
            {renderPassword()}
            {/* COUNTRY */}
            {renderCountry()}
          </div>
          {/* <div className="flex w-full flex-col gap-[16px]"> */}
          {/*   {isConfirmedPhone ? ( */}
          {/*     renderConfirmationCode() */}
          {/*   ) : ( */}
          {/*     <> */}
          {/*       {renderPhone()} {renderCountry()} */}
          {/*     </> */}
          {/*   )} */}
          {/* </div> */}
        </div>

        {!isConfirmedPhone && (
          <div className="flex flex-col gap-[10px]">
            <div className="relative w-full">
              <Checkbox
                classNamesForDescription="text-[14px] font-medium text-text_2"
                description={
                  (
                    <Trans
                      components={{
                        1: (
                          <Link
                            className="gradient-text bg-orange-gradient"
                            target="_blank"
                            to="/info_pages/terms_and_conditions"
                          />
                        ),
                        2: (
                          <Link
                            className="gradient-text bg-orange-gradient"
                            target="_blank"
                            to="/info_pages/privacy_policy"
                          />
                        ),
                      }}
                      i18nKey="registration.text"
                    />
                  ) as unknown as string
                }
                id="is18-checkbox"
                {...is18yearsProps}
              />

              {!focused && submitTried && errors.is18years && (
                <Tooltip
                  anchorSelect="#is18-checkbox"
                  className="rounded-4xl absolute bg-background_6 text-text_2"
                  isOpen={submitTried && Boolean(errors.is18years)}
                  place="bottom"
                >
                  <div>{errors.is18years}</div>
                </Tooltip>
              )}
            </div>

            <Checkbox
              classNamesForDescription="text-[14px] font-medium text-text_2"
              description={`${t('registration.news')}`}
              {...newsProps}
            />
          </div>
        )}

        {!isConfirmedPhone && (
          <div className="flex w-full flex-col gap-y-[16px]">
            <div className="gradient-text bg-orange-gradient-2 font-bold uppercase block text-center text-[14px] md:text-[16px] max-w-[290px] mx-auto">
              {t('registration.chooseYourBonuses')}

              <img
                alt=""
                className="hidden w-[20px] md:inline ml-[10px]"
                src={gift2}
              />
            </div>

            <ul className="scrollbar flex gap-x-[10px] overflow-x-auto">
              <li className="flex min-h-[88px] shrink-0 basis-[195px] flex-col gap-y-[4px] rounded-lg border border-border_21 bg-cover bg-right p-[12px] text-text_2">
                <div className="flex justify-between gap-x-[15px] text-[17px] font-bold">
                  <p>{staticBonus.title}</p>

                  {/* <NavLink */}
                  {/*   aria-label={`${bonus.name}`} */}
                  {/*   onClick={() => closeModal?.()} */}
                  {/*   to={`/bonuses/${bonus.id}`} */}
                  {/* > */}
                  {/*   <Info /> */}
                  {/* </NavLink> */}
                </div>

                <p className="max-w-[107px] text-[12px] font-medium">{t(staticBonus.descriptionKey)}</p>
              </li>
            </ul>
          </div>
        )}

        <div className="flex w-full flex-col items-center gap-[16px]">
          <Button
            additionalClassName="r_3xl:max-w-[140px]"
            disabled={isLoading || isLoadingSmsCode || isLoadingVerifySMSCode}
            loading={isLoading || isLoadingSmsCode || isLoadingVerifySMSCode}
            mode="primary"
            onClick={() => handleClick()}
            title={`${isAuthenticated ? t('registration.nextStep') : t('general.signUp')}`}
          >
            {t('general.signUp')}
          </Button>
          {isConfirmedPhone && (
            <Button
              additionalClassName="r_3xl:max-w-[140px]"
              disabled={isLoading || isLoadingSmsCode || isLoadingVerifySMSCode}
              loading={isLoading || isLoadingSmsCode || isLoadingVerifySMSCode}
              mode="black-button"
              onClick={() => setIsConfirmedPhone(false)}
            >
              {t('general.back')}
            </Button>
          )}

          <p className="m-auto flex w-full justify-center gap-1 text-xs font-bold">
            <span className="text-[14px] uppercase text-text_2">{t('registration.alreadyHaveAccount')}</span>
            <button
              className="text-[14px] uppercase text-text_14"
              onClick={() => openModal?.(MODAL_NAME.LOGIN)}
              type="button"
            >
              {t('registration.toLogin')}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
