import { apiProvider } from '..';
import {
  BlacklistEmailDomainResponse,
  BlacklistEmailFragmentResponse,
  BlacklistEmailResponse,
  BlockedCountryResponse
} from './blacklist.types';

export const blacklistEmail = () => {
  return apiProvider.request<BlacklistEmailResponse>({
    method: 'get',
    url: '/blacklist/email-blacklist'
  });
};

export const blacklistEmailDomain = () => {
  return apiProvider.request<BlacklistEmailDomainResponse>({
    method: 'get',
    url: '/blacklist/email-domain-blacklist'
  });
};
export const blacklistEmailFragment = () => {
  return apiProvider.request<BlacklistEmailFragmentResponse>({
    method: 'get',
    url: '/blacklist/email-fragment-blacklist'
  });
};

export const blockedCoutry = () => {
  return apiProvider.request<BlockedCountryResponse>({
    method: 'get',
    url: '/blacklist/check-country-block'
  });
};
