import { useMemo } from 'react';

export const useGetDayOfWeek = (date?: string): { dayOfWeek: string; numberDayOfWeek: number } => {
  const result = useMemo(() => {
    const dayOfWeek = date ? new Date(date) : new Date();
    const numberDayOfWeek = dayOfWeek.getDay();
    const daysArray = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    return { dayOfWeek: daysArray[numberDayOfWeek], numberDayOfWeek };
  }, [date]);

  return result;
};
