import classNames from 'classnames';

export const Wrapper = ({
  children,
  additionalClassName,
  padding
}: {
  children: React.ReactNode;
  additionalClassName?: string;
  padding?: string;
}) => {
  return (
    <div
      className={classNames(
        'md:min-w-[708px] w-full max-w-[1280px] h-full px-3',
        padding ? padding : '',
        additionalClassName
      )}>
      {children}
    </div>
  );
};
