import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';
import './styles.css';

export enum Tooltips {
  NONE = 'NONE',
  ACCOUNT = 'ACCOUNT',
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
  GAMES = 'GAMES',
  JACKPOTS = 'JACKPOTS',
  LIVE = 'LIVE',
  PROMO = 'PROMO',
  TOURNAMENTS = 'TOURNAMENTS',
  VIP = 'VIP',
  DOWNLOAD = 'DOWNLOAD',
  CHAT = 'CHAT'
}

interface SidebarTooltipProps {
  id: string;
  mouseEnterFunction: () => void;
  mouseLeaveFunction: () => void;
  containerClickFunction?: () => void;
  children: ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
  containerAdditionalClassName?: string;
  containerWithoutHover?: boolean;
}

export const SidebarTooltip: FC<SidebarTooltipProps> = ({
  id,
  mouseEnterFunction,
  mouseLeaveFunction,
  containerClickFunction,
  children,
  showTooltip,
  tooltipContent,
  containerAdditionalClassName,
  containerWithoutHover = false
}) => {
  return (
    <>
      <div
        id={id}
        className={classNames(
          containerAdditionalClassName,
          containerWithoutHover ? 'no-hover-tooltip' : 'tooltip'
        )}
        onMouseEnter={mouseEnterFunction}
        onMouseLeave={mouseLeaveFunction}
        onClick={containerClickFunction}>
        {children}
      </div>
      <Tooltip
        anchorSelect={`#${id}`}
        className="sidebar-tooltip"
        place="right"
        isOpen={showTooltip}
        noArrow>
        {tooltipContent}
      </Tooltip>
    </>
  );
};
