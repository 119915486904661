import { useEffect, useMemo, useState } from 'react';
import { useGetAllFreeSpins } from 'src/api/bonusesTransaction/bonusesTransaction.hooks';
import { useGetShortBalance } from 'src/api/balance';
import { GameCard } from '../GameCard';
import { useGetGamesPaginated } from 'src/api/games/games.hooks';
import { useTranslation } from 'react-i18next';
import { getCurrencyIcon, makeFloat } from 'src/utils';
import { Button } from '../Buttons/Button';
import { FreeSpin } from '../AccountPromo/FreeSpin';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';

export const PersonalAreaFreeSpins = () => {
  const { t } = useTranslation();
  const [gamesCount, setGamesCount] = useState<number>(7);
  const { freeSpins } = useGetAllFreeSpins({});
  const { data } = useGetShortBalance();
  const { isMobile } = useDeviceDetect();
  const { allGames, fetchNextPage, hasNextPage } = useGetGamesPaginated(
    { per_page: gamesCount, free_spins: 1 },
    'free-spins'
  );

  useEffect(() => {
    setGamesCount(isMobile ? 7 : 100);
  }, [isMobile]);

  const currencySing = useMemo(() => {
    return data?.currency ? getCurrencyIcon(data?.currency) : '';
  }, [data?.currency]);

  const number = useMemo(() => {
    return freeSpins?.number ? `${freeSpins?.number}` : '--';
  }, [freeSpins?.number]);

  const dayLimit = useMemo(() => {
    return freeSpins?.dayLimit ? `${freeSpins?.dayLimit}` : '--';
  }, [freeSpins?.dayLimit]);

  const betAmount = useMemo(() => {
    return freeSpins?.betAmount
      ? `${currencySing} ${makeFloat(freeSpins?.betAmount).toFixed(2)}`
      : '--';
  }, [currencySing, freeSpins?.betAmount]);

  const numberDay = useMemo(() => {
    return freeSpins?.numberDay ? `${freeSpins?.numberDay}` : '--';
  }, [freeSpins?.numberDay]);

  return (
    <div className="flex flex-col gap-10 r_sm:rounded-2xl r_sm:py-7 r_sm:px-4 r_sm:gap-7 r_sm:-mx-5 r_sm:bg-background_1 !mx-1 !lg:mx-0">
      <div className="flex flex-col gap-3 rounded-2xl p-10 max-w-[780px] r_sm:!p-0 r_laptop:p-5 bg-background_1">
        <div className="font-bold text-2xl text-text_8">{t('freeSpins.freeSpins')}</div>
        <div className="flex flex-col justify-between h-auto gap-2.5 text-end r_sm:text-end">
          <FreeSpin description={`${t('freeSpins.amount')}:`} value={number} />
          <FreeSpin description={`${t('freeSpins.limitDay')}:`} value={dayLimit} />
          <FreeSpin description={`${t('freeSpins.betAmount')}:`} value={betAmount} />
          <FreeSpin description={`${t('freeSpins.leftForDay')}:`} value={numberDay} />
        </div>
      </div>
      {allGames.length > 0 && (
        <div className="flex flex-col gap-3 r_sm:-mx-3">
          <div className="ml-3 md:ml-0">
            <p className="font-bold text-xl py-0.5 text-text_8">{t('freeSpins.gameFreeSpins')}</p>
          </div>
          <div className="grid grid-cols-freeSpins gap-x-4 gap-y-2 max-w-[1000px] r_sm:grid-cols-3 r_sm:gap-x-1">
            {allGames.map((game) => (
              <GameCard game={game} key={game.id} />
            ))}
          </div>
          {hasNextPage && (
            <Button
              mode="primary"
              additionalClassName="m-auto mt-7"
              onClick={() => fetchNextPage()}
              title={`${t('general.moreGames')}`}>
              {t('general.moreGames')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
