export const SearchMobile = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3637 21.3635L17.4092 17.4089M19.5455 12.2727C19.5455 16.2893 16.2893 19.5455 12.2727 19.5455C8.25611 19.5455 5 16.2893 5 12.2727C5 8.25611 8.25611 5 12.2727 5C16.2893 5 19.5455 8.25611 19.5455 12.2727Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
