import { Input } from '../Inputs/Input';
import { Multiselect } from '../Multiselect';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { DateFieldProps, MultiselectFieldProps, TextFieldProps } from '../../hooks/useForm/index';
import { InputDate } from '../Inputs/InputDate';

type Props = {
  resetForm: () => void;
  transactionTypesProps: MultiselectFieldProps;
  dateFromProps: DateFieldProps;
  dateToProps: DateFieldProps;
  amountFromProps: TextFieldProps;
  amountToProps: TextFieldProps;
  refetch: () => void;
  transaction: string;
};
export const HistoryForm = ({
  resetForm,
  transactionTypesProps,
  dateFromProps,
  dateToProps,
  amountFromProps,
  amountToProps,
  refetch,
  transaction
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <Multiselect {...transactionTypesProps} label={transaction} labelWithPadding />
      <div className="grid grid-cols-2 gap-y-[27px] gap-x-[20px] my-[27px]">
        <div className="flex flex-col">
          <InputDate
            {...dateFromProps}
            labelWithPadding
            label={t('transactionTable.dateFrom')}
            placeholder={`${t('transactionTable.dateFrom')}`}
          />
        </div>
        <div className="flex flex-col">
          <InputDate
            {...dateToProps}
            labelWithPadding
            label={t('transactionTable.dateTo')}
            placeholder={`${t('transactionTable.dateTo')}`}
          />
        </div>
        <div className="flex flex-col">
          <Input
            {...amountFromProps}
            labelWithPadding
            label={t('transactionTable.amountFrom')}
            placeholder={`${t('transactionTable.amountFrom')}`}
          />
        </div>
        <div className="flex flex-col">
          <Input
            {...amountToProps}
            labelWithPadding
            label={t('transactionTable.amountTo')}
            placeholder={`${t('transactionTable.amountTo')}`}
          />
        </div>
        <Button mode="secondary" onClick={resetForm} title={`${t('transactionTable.clear')}`}>
          {t('transactionTable.clear')}
        </Button>
        <Button
          title={`${t('general.search')}`}
          mode="primary"
          onClick={() => {
            refetch?.();
          }}>
          {t('general.search')}
        </Button>
      </div>
    </div>
  );
};
