export const AccountBurger = () => {
  return (
    <div className="flex flex-row flex-wrap items-center z-[600]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-[1.5rem] h-[26px] stroke-[2px] text-text_12 cursor-pointer">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    </div>
  );
};
