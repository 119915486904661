import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from 'src/components';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';
import { useDrawer } from 'src/hooks/useDrawer';
import { executeAdditionalGTMScript } from 'src/utils';

import { AsideInner } from '../AsideInner/AsideInner';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Header/Header';
import { HeaderInner } from '../Header/HeaderInner';
import { useLanguageService } from '../LanguageService/LanguageService';

export const Layout: FC = () => {
  useLanguageService();

  return (
    <FullScreenContainer>
      <Header
        additionalClassName="z-[600]"
        hideHeaderInDesctopView
      >
        <HeaderInner />
      </Header>
      <WithDesktopSidebar>
        <div className="w-full flex flex-col justify-start items-center md:gap-y-[60px] xl:gap-y-[84px]">
          <Outlet />
          <Footer />
        </div>
      </WithDesktopSidebar>
    </FullScreenContainer>
  );
};

function WithDesktopSidebar({ children }: PropsWithChildren) {
  const { _closeMainAccountSidebar, _closeMainSidebar, _mainSidebarOpened, closeBottomDrawer, isMobileAppPopupOpened } =
    useDrawer();
  const location = useLocation();

  const closeAllDrawers = () => {
    closeBottomDrawer?.();
    _closeMainSidebar?.();
    _closeMainAccountSidebar?.();
  };

  const { isLg, isXl } = useBreakpoints();
  const isPc = isLg || isXl;

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    executeAdditionalGTMScript(userId ? parseInt(userId) : undefined);
  }, [location.pathname]);

  return (
    <>
      <aside
        className={classNames(
          'bg-background_4 fixed bottom-0 md:left-0 h-full z-[700] flex flex-col justify-start items-center ease-in-out duration-300 aside-border',
          _mainSidebarOpened ? 'gf:w-full w-270 left-0' : 'w-70 -left-[300px]',
        )}
      >
        <AsideInner />
      </aside>
      <main
        className={classNames(
          'w-full h-full ease-in-out duration-300 flex justify-center md:mt-0',
          isMobileAppPopupOpened ? 'mt-[100px]' : 'mt-[60px]',
          _mainSidebarOpened ? 'lg:pl-[270px]' : 'lg:pl-[70px]',
          'md:pl-[70px]',
        )}
      >
        {_mainSidebarOpened && !isPc && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-background_5 z-[600] blured"
            onClick={() => {
              !isPc && closeAllDrawers();
            }}
          />
        )}
        {children}
      </main>
    </>
  );
}
