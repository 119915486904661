import { useTranslation } from 'react-i18next';
import { Tabs } from 'src/components/Tabs';
import { Main } from './Main';
import { Verification } from './Verification';
import { Security } from './Security';
import { Wrapper } from 'src/components/Wrapper/Wrapper';

export const PersonalAreaProfile = () => {
  const { t } = useTranslation();
  const profileTabs = [
    {
      label: `${t('accountProfileTabs.general')}`,
      key: 'main',
      children: <Main />
    },
    {
      label: `${t('accountProfileTabs.verification')}`,
      key: 'verification',
      children: <Verification />
    },
    {
      label: `${t('accountProfileTabs.security')}`,
      key: 'security',
      children: <Security />
    }
  ];

  return (
    <div className="w-full flex flex-col justify-start items-center">
      <Wrapper padding="pt-10">
        <div className="flex flex-col gap-[30px] max-w-[550px]">
          <p className="font-bold text-2xl text-text_2 uppercase hidden lg:block">
            {t('accountProfile.title')}
          </p>
          <Tabs tabs={profileTabs} />
        </div>
      </Wrapper>
    </div>
  );
};
