import { FC } from 'react';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    seon: any;
  }
}

export enum GameCategory {
  BJLeague = 613,
  BooksOfSlots = 581,
  CasualGames = 585,
  DropWinsGames = 587,
  FeatureBuy = 579,
  FruitsGames = 586,
  Megaways = 580,
  NewGames = 1,
  ReadyToDrop = 582,
  SportGames = 584,
  TopGames = 2,
  Videoslot = 583,
}

export type UploadedPrewievImage = ArrayBuffer | null | string | undefined;

export type UploadedFile = {
  file?: File;
  imagePreviewUrl?: UploadedPrewievImage;
  responseImageUrl?: string;
};

export const GamesCategoryName: { [key: number]: string } = {
  [GameCategory.BJLeague]: 'BJ League',
  [GameCategory.BooksOfSlots]: 'Books Of Slots',
  [GameCategory.CasualGames]: 'Casual Games',
  [GameCategory.DropWinsGames]: 'Drop Wins Games',
  [GameCategory.FeatureBuy]: 'Feature Buy',
  [GameCategory.FruitsGames]: 'Fruits Games',
  [GameCategory.Megaways]: 'Megaways',
  [GameCategory.NewGames]: 'New Games',
  [GameCategory.ReadyToDrop]: 'Ready To Drop',
  [GameCategory.SportGames]: 'Sport Games',
  [GameCategory.TopGames]: 'Top Games',
  [GameCategory.Videoslot]: 'Videoslot',
};

export enum KycStatus {
  DocumentsExpired = 3,
  Less18Years = 4,
  NotVerified = 1,
  Pending = 0,
  Verified = 2,
}

export const KYC_STATUS_NAME: { [key: number]: string } = {
  [KycStatus.DocumentsExpired]: 'kycStatus.documentsExpired',
  [KycStatus.Less18Years]: 'kycStatus.less18Years',
  [KycStatus.NotVerified]: 'kycStatus.notVerified',
  [KycStatus.Pending]: 'kycStatus.pending',
  [KycStatus.Verified]: 'kycStatus.verified',
};

export enum BONUS_TYPE {
  ActivatedByLink = 8,
  Birthday = 4,
  Deposits = 5,
  FirstAuthorization = 2,
  IncreaseWinnings = 7,
  Periodic = 6,
  PromoCode = 3,
  Registration = 0,
  RegistrationPromoCode = 1,
}
export type Country = {
  alpha2: string;
  alpha3: string;
  currencies: string[];
  flag: string;
  name: string;
  phoneCodes: string[];
  timezones: {
    gmtOffset: number;
    gmtOffsetName: string;
    zoneName: string;
  }[];
};

export type DropDownOption = { id: string; name: string };

export type StepProps = {
  availableSteps: boolean[];
  changeStep: (value: number) => void;
  completedSteps: boolean[];
};

export type StepsOption = {
  children: FC<StepProps>;
  title: string;
};

export enum BonusTransactionType {
  Bet = 5,
  BetSport = 6,
  Bonus = 0,
  CancelWager = 9,
  CashBack = 11,
  FreeSpinWin = 4,
  LevelBonus = 12,
  Manual = 2,
  MoneyPrize = 1,
  Referral = 3,
  Refund = 13,
  RollbackBet = 14,
  RollbackWin = 15,
  Win = 7,
  WinSport = 10,
  Zeroing = 8,
}

export const BONUS_TRANSACTION_TYPE: string[] = [
  'Bonus',
  'MoneyPrize',
  'Manual',
  'Referral',
  'FreeSpinWin',
  'Bet',
  'BetSport',
  'Win',
  'Zeroing',
  'CancelWager',
  'WinSport',
  'CashBack',
  'LevelBonus',
  'Refund',
  'RollbackBet',
  'RollbackWin',
];

export type GetTransactionsRequestBody = {
  'amount-from'?: string;
  'amount-to'?: string;
  'date-end'?: string;
  'date-start'?: string;
  page?: number;
  sort?: string;
  'sort-dir'?: string;
  status?: string;
  type?: string;
};

export enum StatusBonusTransaction {
  Activated = 3,
  Cancel = 5,
  Complete = 6,
  Credited = 2,
  Pending = 0,
  Queue = 1,
  Refund = 7,
  Rejected = 4,
}

export const STATUS_BONUS_TRANSACTION_NAME: { [key: number]: string } = {
  [StatusBonusTransaction.Activated]: 'Activated',
  [StatusBonusTransaction.Cancel]: 'Cancel',
  [StatusBonusTransaction.Complete]: 'Complete',
  [StatusBonusTransaction.Credited]: 'Credited',
  [StatusBonusTransaction.Pending]: 'Pending',
  [StatusBonusTransaction.Queue]: 'Queue',
  [StatusBonusTransaction.Refund]: 'Refund',
  [StatusBonusTransaction.Rejected]: 'Rejected',
};

export const TRANSACTION_STATUS: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Complete',
  '10': 'Rollback',
  '11': 'Wager Zeroing',
  '12': 'Not Confirmed Block Amount',
  '2': 'Cancel',
  '3': 'Failed',
  '4': 'Refund',
  '5': 'Pending Wager',
  '6': 'Rejected',
  '7': 'Not Confirmed',
  '8': 'Processing',
  '9': 'Aggregator Rejected',
};

export const TRANSACTION_TYPE: string[] = [
  'Deposit',
  'Withdraw',
  'Bet',
  'Win',
  'Bonus',
  'Jackpot',
  'Change Balance Inc',
  'Change Balance Dec',
  'Referral',
  'Convert Balance',
  'Money Prize',
  'Bonus Transaction',
  'Cancel Bonus Fine',
  'Refund',
  'Rollback Bet',
  'Rollback Win',
  'Bet Sport',
  'Jackpot Increasing',
  'Free Spin Win',
  'Zeroing',
  'Win Sport',
  'Increment',
  'CashBack',
  'Level Bonus',
];

export type PaymentFormField = {
  name: string;
  title: string;
  toShow: boolean;
  type: string;
  value: string;
};

export type PaymentMethod = {
  _reactDepositMethodId?: number;
  commission: string;
  commission_payer: number;
  exchangeCurrency?: string;
  exchangeRate?: string;
  formFields?: PaymentFormField[];
  logoUrl: string;
  max_amount: string;
  method: string;
  min_amount: string;
  options?: PaymentMethodOption[];
  order: string;
  success: boolean;
  title: string;
  type: string;
};

export type PaymentMethodOption = {
  amount?: number;
  currency?: string;
  exchangeRate: string;
  id?: number;
  name: string;
  title: string;
};

export type ResponsiveBreakpoint = 'gf' | 'lg' | 'md' | 'sm' | 'xl' | 'xs';

export enum BonusType {
  'All' = 0,
  'Casino' = 1,
  'Sport' = 2,
}

export type GameCategoriesMap = Record<string, { name: string; title: string; value: boolean | number }>;

export type HellocleverResponse = {
  account: string;
  amount: string;
  currency: string;
  nonce: number;
  orderId: number;
  success: boolean;
};

export type WithLanguage<T> = T & { language?: string };
