import classNames from 'classnames';
import { FC } from 'react';

type SkeletonProps = {
  showTitle?: boolean;
  rows?: number;
  ActiveAnimation?: boolean;
};

export const Skeleton: FC<SkeletonProps> = ({
  showTitle = true,
  rows = 4,
  ActiveAnimation = true
}) => {
  return (
    <div className={classNames('w-full flex flex-col gap-2', ActiveAnimation && 'animate-pulse')}>
      {showTitle && (
        <h3
          className="h-[16px] rounded bg-background_6"
          style={{
            width: '38%'
          }}
        />
      )}
      <ul className="flex flex-col gap-2">
        {Array.from(Array(rows), (_, index) => (
          <li key={index} className="w-full h-[16px] bg-background_6 rounded"></li>
        ))}
      </ul>
    </div>
  );
};
