import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { getNodapayMethods } from './payment.requests';
import { NodapayMethodsResponse } from './payment.types';
import { isProdEnv } from '..';

export const useGetNodapayMethods = () => {
  const { data: response, ...query } = useQuery<AxiosResponse<NodapayMethodsResponse>, AxiosError>(
    ['all-nodapay-methods'],
    getNodapayMethods,
    { enabled: isProdEnv }
  );
  return {
    data: response?.data,
    ...query
  };
};
