import {
  BonusTransaction,
  useGetAllBonusesTransactions,
  useUpdateBonusStatus
} from 'src/api/bonusesTransaction';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../Table';
import { TableCell } from '../Table/TableCell';
import { Button } from '../Buttons/Button';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { getCurrencyIcon, makeFloat, setBonusTransactionTypeName } from 'src/utils';
import dayjs from 'dayjs';

export const PesonalAreaBonusQueue = ({ bonusType }: { bonusType: number }) => {
  const { t } = useTranslation();
  const { arrayBonusesTansactions } = useGetAllBonusesTransactions({
    bonus_type: bonusType,
    status: '1',
    type: '0,1,2,3,4,11,12'
  });

  const bonusQueue = [
    `${t('tableBonusQueue.name')}`,
    `${t('tableBonusQueue.amount')}`,
    `${t('tableBonusQueue.maxBet')}`,
    `${t('tableBonusQueue.wager')}`,
    `${t('tableBonusQueue.date')}`
  ];

  return (
    <>
      {arrayBonusesTansactions && arrayBonusesTansactions?.length === 0 ? (
        <div className="flex flex-col gap-3">
          <p className="font-bold text-2xl text-text_8">{t('tableBonusQueue.title')}</p>
          <p className="font-medium text-sm text-text_2">{t('tableBonusQueue.notFound')}</p>
        </div>
      ) : (
        <div>
          <p className="text-text_2 text-[12px] font-bold leading-[16px] text-right mb-2.5 responsiveHide">
            {t('general.tapScroll')} &gt;&gt;
          </p>
          <div className="overflow-auto">
            <div className="max-w-[975px] min-w-[975px]">
              <Table
                header={bonusQueue}
                tableRow={arrayBonusesTansactions.map((bonus) => (
                  <TableRow data={bonus} key={bonus.id} />
                ))}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function TableRow({ data }: { data: BonusTransaction }) {
  const { amount, amountDeposit, wager, date, maxBet, currency, bonus, type, id } = data;
  const { t } = useTranslation();
  const { openModal } = useModal();

  const { mutateAsync: updateBonusStatus } = useUpdateBonusStatus();

  const colName = useMemo(() => {
    if (bonus) {
      return bonus?.name;
    }
    return setBonusTransactionTypeName(type);
  }, [bonus, type]);

  const colAmount = useMemo(() => {
    const sum = amount + amountDeposit;
    return `${makeFloat(sum).toFixed(2)}`;
  }, [amount, amountDeposit]);

  const colCurrency = useMemo(() => {
    return getCurrencyIcon(currency);
  }, [currency]);

  const colWager = useMemo(() => {
    return wager;
  }, [wager]);

  const colDate = useMemo(() => {
    return `${dayjs.unix(date).format('DD.MM.YYYY')}`;
  }, [date]);

  const colMaxbet = useMemo(() => {
    return `${makeFloat(maxBet).toFixed(2)}`;
  }, [maxBet]);

  const rejectBonus = useCallback(() => {
    updateBonusStatus({ bonus_id: id, status: 4 });
  }, [id, updateBonusStatus]);

  return (
    <>
      <tr className="even:bg-background_12">
        <TableCell item={colName} className="w-[120px]" />
        <TableCell item={`${colCurrency} ${colAmount}`} className="w-[120px]" />
        <TableCell item={`${colCurrency} ${colMaxbet}`} className="w-[120px]" />
        <TableCell item={`x ${colWager}`} className="w-[120px]" />
        <TableCell item={colDate} className="w-[120px]" />
      </tr>
      <tr className="border-solid border-b-1 border-border_7 last:border-0">
        <td colSpan={5} className="flex gap-3 pb-7 pl-1">
          <Button
            mode="secondary"
            onClick={() => openModal?.(MODAL_NAME.ATTENTION_BONUS, { rejectBonus: rejectBonus })}
            label={`${t('general.cancel')}`}
            title={`${t('general.cancel')}`}
          />
          <Button
            mode="primary"
            onClick={() => updateBonusStatus({ bonus_id: id, status: 3 })}
            label={`${t('promoCode.activate')}`}
            title={`${t('promoCode.activate')}`}
          />
        </td>
      </tr>
    </>
  );
}
