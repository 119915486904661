import Bowser from 'bowser';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useGetUserCountry } from '../../api/profile';
import { useDebounce } from '../../hooks/useDebounce';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { parseAffilliateReferralLink } from '../ReferralLinkHandler';

const GlobalGoogleAnalyticsEvents = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { userCountry } = useGetUserCountry({});
  const userDevice = Bowser.parse(window.navigator.userAgent);
  const pathname = location.pathname === '/' ? 'home' : location.pathname;
  const [scroll, setScroll] = useState<null | number>(null);
  const scrollNumber = useDebounce(scroll, 1000);

  useEffect(() => {
    if (scrollNumber) {
      sendGoogleAnalyticsEvent('scrolling_depth', {
        device_resolution: `${window.innerWidth} x ${window.innerHeight} px`,
        page_id: pathname,
        scrolling_vertical: `${(scrollNumber * 100).toFixed(0)}%`,
      });
    }
  }, [scrollNumber]);

  useEffect(() => {
    if (userCountry) {
      const { affiliateId } = parseAffilliateReferralLink(searchParams.get('stag'));
      const unixTimestamp = Math.floor(new Date().getTime() / 1000);

      sendGoogleAnalyticsEvent('open_website', {
        affiliate_id: affiliateId ?? null,
        browser_name: userDevice.browser.name,
        device_name: userDevice.os.name,
        device_resolution: `${window.innerWidth} x ${window.innerHeight} px`,
        geo: userCountry?.country_name ?? userCountry?.country_alpha2 ?? null,
        unix_timestamp: unixTimestamp,
      });
    }
  }, [userCountry]);

  useEffect(() => {
    const searchParamsStag = searchParams.get('stag');
    const searchParamsTrackingLink = searchParams.get('tracking_link');
    const openPageSeconds = Date.now();

    if (searchParamsStag && searchParamsTrackingLink) {
      const affiliateId = searchParamsStag.split('_')?.[0];

      if (affiliateId) {
        sendGoogleAnalyticsEvent('sms_click', {
          affiliate_id: affiliateId,
        });
      }
    }

    if (!pathname.startsWith('/game/')) {
      sendGoogleAnalyticsEvent('page_opened', {
        page_id: pathname,
      });
    }

    const handleWindowScroll = (event: Event) => {
      const target = (event.target as Document).scrollingElement;

      if (!target) {
        return;
      }

      const round = (number: number) => Math.round(number * 1e2) / 1e2;
      const scrollY = round(target.scrollTop / (target.scrollHeight - target.clientHeight));

      if (scrollY !== scroll) {
        setScroll(scrollY);
      }
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      const closedPageSeconds = Date.now();
      const pageTime = (Math.round(closedPageSeconds - openPageSeconds) / 1000).toFixed(0);

      sendGoogleAnalyticsEvent('page_time', {
        page_id: pathname,
        page_time: `${pageTime} seconds`,
      });

      window.removeEventListener('scroll', handleWindowScroll);
      setScroll(null);
    };
  }, [location.pathname]);

  return null;
};

export { GlobalGoogleAnalyticsEvents };
