import classNames from 'classnames';
import { FC, HTMLProps, ReactNode, useEffect } from 'react';
import { useBoolean } from 'src/hooks/useBoolean';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';
import { Responsive } from '../Responsive';
import { Navbar } from '../Navbar';
import { TopbarSearch } from '../TopbarSearch';
import { Providers } from '../Providers';

interface FullScreenContainerProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  additionalClassName?: string;
}

export const FullScreenContainer: FC<FullScreenContainerProps> = ({
  children,
  additionalClassName,
  ...rest
}) => {
  const [buttonVisible, showButton, hideButton] = useBoolean();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        showButton();
      } else {
        hideButton();
      }
    });
  }, []);

  const handleScrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={classNames(
        'w-full min-h-screen flex flex-col justify-start items-center',
        additionalClassName
      )}
      {...rest}>
      <ScrollToTopButton onClick={handleScrollTo} visible={buttonVisible} />
      {children}
      <Responsive at={['xs', 'sm']}>
        <>
          <div className="fixed bottom-0 w-full z-[700]">
            <Navbar />
          </div>
          <TopbarSearch />
          <Providers />
        </>
      </Responsive>
    </div>
  );
};
