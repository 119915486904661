export const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-[3%] h-[3%] mr-1 stroke-[2px] text-text_2 max-r_sm:h-[35px] max-r_sm:w-[35px]">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
};

type PropsAccountIcon = {
  className?: string;
  fill?: string;
};

export const AccountForProfile = ({ className, fill }: PropsAccountIcon) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.375C19.2822 24.375 24.375 19.2822 24.375 13C24.375 6.71776 19.2822 1.625 13 1.625C6.71776 1.625 1.625 6.71776 1.625 13C1.625 19.2822 6.71776 24.375 13 24.375ZM13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67892 15.7146C8.4257 15.0119 9.42854 14.625 10.4646 14.625H15.5354C16.5715 14.625 17.5743 15.0119 18.3211 15.7146C19.0695 16.4189 19.5 17.3851 19.5 18.4038V19.5C19.5 19.9487 19.1362 20.3125 18.6875 20.3125C18.2388 20.3125 17.875 19.9487 17.875 19.5V18.4038C17.875 17.8491 17.6413 17.3063 17.2074 16.898C16.7719 16.4882 16.1713 16.25 15.5354 16.25H10.4646C9.82874 16.25 9.22806 16.4882 8.79256 16.898C8.35872 17.3063 8.125 17.8491 8.125 18.4038V19.5C8.125 19.9487 7.76123 20.3125 7.3125 20.3125C6.86377 20.3125 6.5 19.9487 6.5 19.5V18.4038C6.5 17.3851 6.93048 16.4189 7.67892 15.7146Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 6.5C11.6538 6.5 10.5625 7.59131 10.5625 8.9375C10.5625 10.2837 11.6538 11.375 13 11.375C14.3462 11.375 15.4375 10.2837 15.4375 8.9375C15.4375 7.59131 14.3462 6.5 13 6.5ZM8.9375 8.9375C8.9375 6.69384 10.7563 4.875 13 4.875C15.2437 4.875 17.0625 6.69384 17.0625 8.9375C17.0625 11.1812 15.2437 13 13 13C10.7563 13 8.9375 11.1812 8.9375 8.9375Z"
        fill={fill}
      />
    </svg>
  );
};

export const GameHeaderAccountIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08824 14.5058C7.77757 13.8571 8.70327 13.5 9.65967 13.5H14.3403C15.2967 13.5 16.2224 13.8571 16.9118 14.5058C17.6026 15.1559 18 16.0478 18 16.9881V18C18 18.4142 17.6642 18.75 17.25 18.75C16.8358 18.75 16.5 18.4142 16.5 18V16.9881C16.5 16.4761 16.2843 15.975 15.8838 15.5982C15.4818 15.2199 14.9273 15 14.3403 15H9.65967C9.07268 15 8.51821 15.2199 8.11621 15.5982C7.71574 15.975 7.5 16.4761 7.5 16.9881V18C7.5 18.4142 7.16421 18.75 6.75 18.75C6.33579 18.75 6 18.4142 6 18V16.9881C6 16.0478 6.39736 15.1559 7.08824 14.5058Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C10.7574 6 9.75 7.00736 9.75 8.25C9.75 9.49264 10.7574 10.5 12 10.5C13.2426 10.5 14.25 9.49264 14.25 8.25C14.25 7.00736 13.2426 6 12 6ZM8.25 8.25C8.25 6.17893 9.92893 4.5 12 4.5C14.0711 4.5 15.75 6.17893 15.75 8.25C15.75 10.3211 14.0711 12 12 12C9.92893 12 8.25 10.3211 8.25 8.25Z"
        fill="white"
      />
    </svg>
  );
};
