import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from '../locale/en/translationEn.json';
// import translationEs from '../locale/es/translationEs.json';
// import translationFr from '../locale/fr/translationFr.json';
// import translationEl from '../locale/el/translationEl.json';
import translationDe from '../locale/de/translationDe.json';
import translationPl from '../locale/pl/translationPl.json';
import translationPt from '../locale/pt/translationPt.json';

export const SUPPORTED_LANGUAGES = {
  // el: true,
  de: true,
  // se: true,
  // es: true,
  // fr: true,
  en: true,
  pl: true,
  pt: true,
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources: Record<keyof typeof SUPPORTED_LANGUAGES, any> = {
  // },
  de: {
    translation: translationDe,
  },
  // se: {
  //   translation: translationSe
  // },
  // es: {
  //   translation: translationEs
  // },
  // fr: {
  //   translation: translationFr
  // },
  // el: {
  //   translation: translationEl
  en: {
    translation: translationEn,
  },
  pl: {
    translation: translationPl,
  },
  pt: {
    translation: translationPt,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    lng: detectBrowserLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    resources,
    supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
  });

export default i18n;

export function isLanguageSupported(lang: null | string | undefined) {
  if (!lang) return false;
  return lang in SUPPORTED_LANGUAGES;
}

function returnAndSaveLanguage(language: string) {
  localStorage.setItem('language', language);
  return language;
}

export function getBrowserLanguage() {
  return navigator.language.split('-')[0];
}

function getLocalstoageLanguage() {
  return localStorage.getItem('language');
}

function detectBrowserLanguage() {
  const localStorageLanguage = getLocalstoageLanguage();
  if (localStorageLanguage && isLanguageSupported(localStorageLanguage)) {
    return returnAndSaveLanguage(localStorageLanguage);
  }
  const browserLanguage = getBrowserLanguage();
  if (browserLanguage && isLanguageSupported(browserLanguage)) {
    return returnAndSaveLanguage(browserLanguage);
  }
  return returnAndSaveLanguage('en');
}
