export const LIMIT_RESEND = 60;

export const calculateSeconds = (): number => {
  const storageDate = localStorage.getItem('callTime');
  if (storageDate) {
    return LIMIT_RESEND - Math.floor(-(new Date(storageDate).getTime() - new Date().getTime()) / 1000);
  }
  return 0;
};

export const generatePassword = (mail: string): string => {
  return `${mail}Aa1`;
};
