import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'src/components/Tabs';
import { Balance } from './Balance';
import { Deposit } from './Deposit';
import { TransactionHistory } from './TransactionHistory/TransactionHistory';
import { Withdrawal } from './Withdrawal';
import i18n from 'src/utils/i18n';
import { Responsive } from 'src/components/ResponsiveNew/Responsive';
import { Wrapper } from 'src/components/Wrapper/Wrapper';

export const PersonalAreaWallet = () => {
  const { t } = useTranslation();
  const profileTabs = useMemo(
    () => [
      {
        label: `${t('accountWalletTabs.balance')}`,
        key: 'balance',
        children: <Balance />
      },
      {
        label: `${t('accountWalletTabs.deposit')}`,
        key: 'deposit',
        children: <Deposit />
      },
      {
        label: `${t('accountWalletTabs.fundsWithdrawal')}`,
        key: 'withdrawal',
        children: <Withdrawal />
      },
      {
        label: `${t('accountWalletTabs.history')}`,
        key: 'history',
        children: <TransactionHistory />
      }
    ],
    [i18n.language]
  );

  return (
    <div className="w-full flex flex-col justify-start items-center">
      <Wrapper padding="pt-10 !px-[10px] !lg:px-0">
        <div className="max-w-[900px] flex flex-col gap-10">
          <p className="font-bold text-2xl text-text_2 uppercase hidden lg:block">
            {t('accountWallet.title')}
          </p>
          <Responsive from={'lg'}>
            <Tabs tabs={profileTabs} />
          </Responsive>
          <Responsive to={'md'}>
            <Tabs tabs={profileTabs} verticalLayout />
          </Responsive>
        </div>
      </Wrapper>
    </div>
  );
};
