import classNames from 'classnames';
import './styles.css';

type Props = {
  title: string;
  className?: string;
  additionalClassName?: string;
};

export const PageTitle = ({ title, className, additionalClassName }: Props) => {
  return (
    <div className={classNames('title', additionalClassName ? additionalClassName : '')}>
      <h2
        className={classNames(
          'uppercase text-text_2 text-3xl font-bold py-6 r_sm:py-4 r_sm:text-xl text-center r_sm:mb-0',
          className ? className : ''
        )}>
        {title}
      </h2>
    </div>
  );
};
