import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { useModal } from 'src/hooks/useModal';
import { useFreeSpins } from 'src/hooks/useFreeSpins';
import { useMemo } from 'react';
import { getCurrencyIcon, makeFloat } from 'src/utils';
import { useGetShortBalance } from 'src/api/balance';
import { useGetGamesPaginated } from 'src/api/games';
import { GameLoading } from '../GameCard/GameLoading';
import { GameCard } from '../GameCard';

export const PopUpFreeSpins = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { oldFreeSpins: freeSpins, setFreeSpinsChanged } = useFreeSpins();
  const { data } = useGetShortBalance();
  const { allGames, isFetching } = useGetGamesPaginated(
    { per_page: 6, free_spins: 1 },
    'free-spins'
  );
  const currencySing = useMemo(() => {
    return data?.currency ? getCurrencyIcon(data?.currency) : '';
  }, [data?.currency]);

  const number = useMemo(() => {
    return freeSpins?.number ? `${freeSpins?.number}` : '--';
  }, [freeSpins?.number]);

  const dayLimit = useMemo(() => {
    return freeSpins?.dayLimit ? `${freeSpins?.dayLimit}` : '--';
  }, [freeSpins?.dayLimit]);

  const betAmount = useMemo(() => {
    return freeSpins?.betAmount
      ? `${currencySing} ${makeFloat(freeSpins?.betAmount).toFixed(2)}`
      : '--';
  }, [currencySing, freeSpins?.betAmount]);

  const numberDay = useMemo(() => {
    return freeSpins?.numberDay ? `${freeSpins?.numberDay}` : '--';
  }, [freeSpins?.numberDay]);
  return (
    <div className="flex flex-col gap-9 p-[24px] w-[400px] r_mid:w-full">
      <div className="flex flex-col gap-7 items-center w-full">
        <div>
          <p className="font-bold text-2xl text-text_8">{t('freeSpins.freeSpins')}</p>
        </div>
        <div className="flex flex-col justify-between h-auto gap-2.5 w-full r_sm:text-end">
          <div className="flex items-center justify-between gap-20">
            <p className="text-sm font-bold w-1/2 r_sm:text-xs text-text_2">
              {t('freeSpins.amount')}:
            </p>
            <p className="whitespace-nowrap text-lg font-bold text-text_2 flex-grow r_sm:text-sm">
              {number}
            </p>
          </div>
          <div className="flex items-center justify-between gap-20">
            <p className="text-sm font-bold w-1/2 r_sm:text-xs text-text_2">
              {t('freeSpins.limitDay')}:
            </p>
            <p className="text-lg whitespace-nowrap font-bold text-text_2 flex-grow r_sm:text-sm">
              {dayLimit}
            </p>
          </div>
          <div className="flex items-center justify-between gap-20">
            <p className="text-sm font-bold w-1/2 r_sm:text-xs text-text_2">
              {t('freeSpins.betAmount')}:
            </p>
            <p className="text-lg whitespace-nowrap font-bold text-text_2 flex-grow r_sm:text-sm">
              {betAmount}
            </p>
          </div>
          <div className="flex items-center justify-between gap-20">
            <p className="text-sm font-bold w-1/2 r_sm:text-xs text-text_2">
              {t('freeSpins.leftForDay')}:
            </p>
            <p className="text-lg whitespace-nowrap font-bold text-text_2 flex-grow r_sm:text-sm">
              {numberDay}
            </p>
          </div>
        </div>
        {allGames.length > 0 && (
          <div className="flex flex-col gap-3">
            <div className="ml-3">
              <p className="font-bold text-base py-0.5 text-center text-text_8">
                {t('freeSpins.gameFreeSpins')}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-x-2 gap-y-1 r_sm:gap-x-1">
              {isFetching ? (
                <GameLoading count={3} />
              ) : (
                allGames.map((game) => <GameCard game={game} key={game.id} />)
              )}
            </div>
          </div>
        )}
        <Button
          title={`${t('general.accept')}`}
          mode="primary"
          onClick={() => {
            closeModal?.();
            setFreeSpinsChanged?.(false);
          }}
          label={`${t('general.accept')}`}
        />
      </div>
    </div>
  );
};
