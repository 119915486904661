import { FC, useCallback, useState } from 'react';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { Trans, useTranslation } from 'react-i18next';
import { RadioGroup } from 'src/components/RadioGroup';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';
import {
  useGetAllSubscriptions,
  useGetFullInfo,
  useResendConfirmEmail,
  useUpdateInfo,
  useUpdateSubscriptions
} from '../../api/profile/profile.hooks';
import { Autocomplete } from '../../components/Autocomplete';
import { Button } from '../../components/Buttons/Button';
import { Input } from '../../components/Inputs/Input';
import { Switch } from '../../components/Switch';
import { countriesOptions, phoneCodes } from '../../constants';
import {
  FieldType,
  useForm,
  validateMaxLength,
  validateNumbersString,
  validatePhoneByCountryCode,
  validateRequired
} from '../../hooks/useForm/index';
import {
  findCountryCodeByName,
  findCountryNameByCode,
  getPhoneCodeByCountryCode,
  openChat
} from '../../utils';
import { days, months, years } from 'src/constants';
import dayjs from 'dayjs';
import { useGetAllKycInfo } from 'src/api/kyc';
import { KycStatus } from 'src/types';

export type Values = {
  name?: string;
  surname?: string;
  country?: string | undefined;
  city?: string;
  postCode?: string;
  address?: string;
  newsSubscription?: boolean;
  technicalSubscription?: boolean;
};

export const Main: FC = () => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState('');
  const { openMessage } = useMessage();
  const [showSupportText, setShowSupportText] = useState(false);
  const { kycStatus } = useGetAllKycInfo({});
  const {
    values,
    errors,
    fieldsProps: {
      nameProps,
      surnameProps,
      countryProps,
      cityProps,
      postcodeProps,
      addressProps,
      genderProps,
      newsSubscriptionProps,
      technicalSubscriptionProps,
      countryCodeProps,
      phoneNumberProps,
      dayProps,
      monthProps,
      yearProps
    },
    touched,
    updateDefaultStatePartially
  } = useForm<{
    name: FieldType.Text;
    surname: FieldType.Text;
    country: FieldType.Autocomplete;
    city: FieldType.WithoutWildcards;
    postcode: FieldType.WithoutWildcards;
    address: FieldType.Text;
    gender: FieldType.Radio;
    newsSubscription: FieldType.Checkbox;
    technicalSubscription: FieldType.Checkbox;
    countryCode: FieldType.Autocomplete;
    phoneNumber: FieldType.Number;
    day: FieldType.Dropdown;
    month: FieldType.Dropdown;
    year: FieldType.Dropdown;
  }>({
    name: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.firstName')}`,
      validation: [validateMaxLength(256)]
    },
    surname: {
      placeholder: `${t('general.lastName')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    country: {
      placeholder: `${t('general.chooseCountry')}`,
      fieldType: FieldType.Autocomplete,
      options: countriesOptions
    },
    city: {
      placeholder: `${t('accountProfileMain.city')}`,
      fieldType: FieldType.WithoutWildcards,
      validation: [validateMaxLength(256)]
    },
    postcode: {
      placeholder: `${t('accountProfileMain.postcode')}`,
      fieldType: FieldType.WithoutWildcards,
      validation: [validateMaxLength(256)]
    },
    address: {
      placeholder: `${t('accountProfileMain.addressFull')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    gender: {
      fieldType: FieldType.Radio,
      options: [
        { id: 'm', name: `${t('accountProfileMain.male')}` },
        { id: 'f', name: `${t('accountProfileMain.female')}` }
      ]
    },
    newsSubscription: {
      label: `${t('accountProfileMain.subscriptionPromo')}`,
      fieldType: FieldType.Checkbox
    },
    technicalSubscription: {
      label: `${t('accountProfileMain.subscriptionTechnical')}`,
      fieldType: FieldType.Checkbox
    },
    countryCode: {
      placeholder: 'Prefix',
      fieldType: FieldType.Autocomplete,
      options: phoneCodes,
      validation: [validateRequired, validateMaxLength(15)]
    },
    phoneNumber: {
      placeholder: `${t('general.phoneNumber')}`,
      fieldType: FieldType.Number,
      validation: [validateMaxLength(20), validateNumbersString, validatePhoneByCountryCode]
    },
    day: {
      placeholder: `${t('general.day')}`,
      fieldType: FieldType.Dropdown,
      options: days,
      validation: [validateRequired]
    },
    month: {
      placeholder: `${t('general.month')}`,
      options: months,
      fieldType: FieldType.Dropdown,
      validation: [validateRequired]
    },
    year: {
      placeholder: `${t('general.year')}`,
      options: years,
      fieldType: FieldType.Dropdown,
      validation: [validateRequired]
    }
  });

  const showSuccessMessage = (message: string) =>
    openMessage?.(`${t(message)}`, MESSAGE_STATUS.SUCCESS);

  const { mutateAsync: updateProfileInfo, isLoading: loadingUpdateInfo } = useUpdateInfo({
    onChange: onUpdateInfoSuccess
  });

  const { mutateAsync: updateSubscription, isLoading: loadingUpdateSubscriptions } =
    useUpdateSubscriptions();

  useGetAllSubscriptions({
    onSuccess: (response) => {
      updateDefaultStatePartially({
        newsSubscription:
          response?.data?.subscriptions?.email?.bonuses ||
          response?.data?.subscriptions?.email?.news,
        technicalSubscription:
          response?.data?.subscriptions?.email?.technical &&
          response?.data?.subscriptions?.email?.financial
      });
    }
  });

  function onVerifiedEmailSuccess() {
    showSuccessMessage('accountProfile.confirmationSent');
  }

  const { mutateAsync: resendConfirmEmail } = useResendConfirmEmail({
    onChange: onVerifiedEmailSuccess
  });

  const { fullInfo, refetch: refetchFullInfo } = useGetFullInfo({
    onSuccess: (response) => {
      setUserEmail(() => (response?.data ? response?.data?.email : ''));
      updateDefaultStatePartially({
        name: response?.data?.name,
        surname: response?.data?.surname,
        country: findCountryNameByCode(response?.data?.country),
        city: response?.data?.city,
        postcode: response?.data?.postcode,
        address: response?.data?.address,
        gender: response?.data?.sex,
        countryCode: response?.data?.phone.code
          ? `+${response?.data?.phone.code}`
          : getPhoneCodeByCountryCode(response.data.country),
        phoneNumber: response?.data?.phone.number,
        day: response.data.birthday ? dayjs(response.data.birthday).date().toString() : '',
        month: response.data.birthday ? (dayjs(response.data.birthday).month() + 1).toString() : '',
        year: response.data.birthday ? dayjs(response.data.birthday).year().toString() : ''
      });
      !response?.data?.name && !response?.data?.surname && !response?.data?.birthday
        ? setShowSupportText(false)
        : setShowSupportText(true);
    }
  });

  function onUpdateInfoSuccess() {
    showSuccessMessage('accountProfile.profileDataUpdate');
    refetchFullInfo();
  }

  const prepareDate = useCallback(() => {
    return values.day && values.month && values.year
      ? dayjs()
          .date(parseInt(values.day))
          .month(parseInt(values.month) - 1)
          .year(parseInt(values.year))
          .format('YYYY-MM-DD')
      : undefined;
  }, [values.day, values.month, values.year]);

  const handleSubmit = useCallback(async () => {
    updateProfileInfo({
      name: values?.name === '' ? undefined : values?.name?.replaceAll(' ', ''),
      surname: values?.surname === '' ? undefined : values?.surname?.replaceAll(' ', ''),
      country: findCountryCodeByName(values.country)?.replaceAll(' ', ''),
      city: values?.city === '' ? undefined : values?.city?.replaceAll(' ', ''),
      postcode: values?.postcode === '' ? undefined : values?.postcode?.replaceAll(' ', ''),
      address: values?.address === '' ? undefined : values?.address?.replaceAll(' ', ''),
      sex: values?.gender === '' ? undefined : values?.gender,
      code: values?.countryCode === '' ? undefined : values?.countryCode?.replace('+', ''),
      phone: values?.phoneNumber === '' ? undefined : values?.phoneNumber,
      birthday: prepareDate()
    });

    updateSubscription({
      // eslint-disable-next-line camelcase
      email_promo_sub: values?.newsSubscription,
      // eslint-disable-next-line camelcase
      email_bonuses_sub: values?.newsSubscription,
      // eslint-disable-next-line camelcase
      email_technical_sub: values?.technicalSubscription,
      // eslint-disable-next-line camelcase
      email_financial_sub: values?.technicalSubscription
    });
  }, [
    updateProfileInfo,
    values?.name,
    values?.surname,
    values.country,
    values?.city,
    values?.postcode,
    values?.address,
    values?.gender,
    values?.countryCode,
    values?.phoneNumber,
    values?.newsSubscription,
    values?.technicalSubscription,
    prepareDate,
    updateSubscription
  ]);

  const copyFunc = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    openMessage?.('Mail has been copied', MESSAGE_STATUS.SUCCESS);
  };


  return (
    <div className="flex flex-col gap-[30px]">
      <p className="font-bold text-[18px] text-text_2">
        {t('accountMain.welcome')},{' '}
        <span
          className="font-bold text-[18px] text-text_8 underline cursor-pointer"
          onClick={() => copyFunc(userEmail)}>
          {userEmail}
        </span>
      </p>
      <Input
        {...nameProps}
        disabled={fullInfo?.name ? true : false}
        label={t('accountProfileMain.firstName')}
        labelWithPadding
      />
      {touched.name && errors.name && <span className="text-[red] text-xs">{errors.name}</span>}
      <Input
        {...surnameProps}
        disabled={fullInfo?.surname ? true : false}
        label={t('accountProfileMain.lastName')}
        labelWithPadding
      />
      {touched.surname && errors.surname && (
        <span className="text-[red] text-xs">{t(errors.surname)}</span>
      )}
      <div className="flex flex-col w-full z-[50] gap-2">
        <p className="text-[16px] pl-[20px] text-text_2">{t('accountProfileMain.birthDate')}</p>
        <div className="flex flex-row justify-around gap-2 self-center w-full">
          <Dropdown
            {...dayProps}
            optionsSize={14}
            disabled={fullInfo?.birthday ? true : false}
            classNameArrow="r_xsm:right-[5px]"
          />
          <Dropdown
            {...monthProps}
            optionsSize={14}
            disabled={fullInfo?.birthday ? true : false}
            classNameArrow="r_xsm:right-[5px]"
          />
          <Dropdown
            {...yearProps}
            optionsSize={14}
            disabled={fullInfo?.birthday ? true : false}
            classNameArrow="r_xsm:right-[5px]"
          />
        </div>
      </div>
      <Autocomplete
        {...countryProps}
        label={`${t('accountProfileMain.country')}`}
        labelWithPadding
        disabled={kycStatus === KycStatus.Verified ? true : false}
        emptyStateContent="autocompleteEmptyState.noSuchCountry"
      />
      {touched.country && errors.country && (
        <span className="text-[red] text-xs">{t(errors.country)}</span>
      )}
      <div className="flex w-full justify-between gap-5">
        <Input
          {...cityProps}
          label={t('accountProfileMain.city')}
          labelWithPadding
          disabled={fullInfo?.city ? true : false}
        />
        {touched.city && errors.city && (
          <span className="text-[red] text-xs">{t(errors.city)}</span>
        )}
        <Input
          {...postcodeProps}
          label={t('accountProfileMain.postcode')}
          labelWithPadding
          disabled={fullInfo?.postcode ? true : false}
        />
        {touched.postcode && errors.postcode && (
          <span className="text-[red] text-xs">{t(errors.postcode)}</span>
        )}
      </div>
      <Input
        {...addressProps}
        label={t('accountProfileMain.address')}
        labelWithPadding
        disabled={fullInfo?.address ? true : false}
      />
      {touched.address && errors.address && (
        <span className="text-[red] text-xs">{t(errors.address)}</span>
      )}
      <div>
        <p className="font-medium text-base text-text_2 pb-[8px] pl-[20px] self-start">
          {t('accountMain.phone')}
        </p>
        <div className="flex w-full justify-between gap-3">
          <div className="w-[50%]">
            <Autocomplete
              {...countryCodeProps}
              emptyStateContent="autocompleteEmptyState.noSuchCode"
            />
          </div>
          <Input {...phoneNumberProps} />
        </div>
      </div>
      <RadioGroup {...genderProps} checkValue={values?.gender} />
      <div className="flex flex-col gap-2">
        <Switch {...newsSubscriptionProps} />
        <Switch {...technicalSubscriptionProps} />
      </div>
      {showSupportText && (
        <span className="text-[16px] text-text_2">
          <Trans
            i18nKey="general.changeName"
            components={{
              1: (
                <button
                  title={`${t('general.changeName')}`}
                  onClick={openChat}
                  className="pl-[3px] font-thin text-text_8 text-[16px] underline cursor-pointer"
                />
              )
            }}
          />
        </span>
      )}
      <div className="flex flex-col">
        <Input
          labelWithPadding
          label={`${t('accountProfileMain.emailVerification')}`}
          disabled
          value={userEmail}
        />
        {fullInfo?.emailConfirmed ? (
          <p className="text-sm text-text_9">{t('kycStatus.verified')}</p>
        ) : (
          <span className="text-[14px] pt-[15px] text-text_2">
            <Trans
              i18nKey="general.emailConfirmation"
              components={{
                1: (
                  <button
                    onClick={() => resendConfirmEmail()}
                    className="font-thin text-text_8 text-[14px] underline cursor-pointer"
                  />
                )
              }}
            />
          </span>
        )}
      </div>
      <Button
        title={`${t('general.save')}`}
        mode="secondary"
        additionalClassName="mt-2.5 r_sm:m-auto"
        onClick={handleSubmit}
        disabled={loadingUpdateInfo || loadingUpdateSubscriptions}
        loading={loadingUpdateInfo || loadingUpdateSubscriptions}>
        {t('general.save')}
      </Button>
    </div>
  );
};
