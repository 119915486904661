import { useActivatePromoCode } from 'src/api/bonuses/bonuses.hooks';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MESSAGE_STATUS, useMessage } from '../../hooks/useMessage';
import { InputWithButton } from '../InputWithButton/InputWithButton';
import { throttle } from 'lodash';

export const PersonalAreaPromocode: FC = () => {
  const { t } = useTranslation();
  const { openMessage } = useMessage();

  const [promoCode, setPromoCode] = useState<string>('');
  const { mutateAsync } = useActivatePromoCode({
    handleError: () => setPromoCode('')
  });

  const handleChangePromoCode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value);
  }, []);

  const handleActivatePromoCode = useCallback(() => {
    if (promoCode) {
      mutateAsync({ promo_code: promoCode });
      setPromoCode('');
    } else {
      openMessage?.(t('general.enterPromoCode'), MESSAGE_STATUS.INFO);
    }
  }, [mutateAsync, openMessage, promoCode]);

  const throttleActivatePromoCode = useMemo(() => {
    return throttle(handleActivatePromoCode, 10000, { trailing: false });
  }, [handleActivatePromoCode]);

  return (
    <div className="flex flex-col justify-between gap-5">
      <p className="font-bold text-2xl text-text_8">{t('promoCode.title')}</p>
      <InputWithButton
        inputProps={{
          placeholder: 'promo code',
          onChange: handleChangePromoCode
        }}
        buttonProps={{
          label: `${t('promoCode.activate')}`,
          title: `${t('promoCode.activate')}`,
          onClick: () => throttleActivatePromoCode()
        }}
      />
      <p className="text-base text-text_2">{t('promoCode.text')}</p>
    </div>
  );
};
