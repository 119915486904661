import { useNavigate } from 'react-router';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

export const ProtectedAuthRoute = ({
  children,
  isAuthenticated
}: {
  children: JSX.Element;
  isAuthenticated: boolean | undefined;
}) => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  if (isAuthenticated) {
    return children;
  } else {
    navigate('/');
    openModal?.(MODAL_NAME.SIGNUP);
  }
  return null;
};
