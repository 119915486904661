import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetNodapayMethods } from 'src/api/payment';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';
import { NodapayMethodCard } from '../NodapayMethodCard/NodapayMethodCard';
import { SquareSkeleton } from '../SquareSkeleton';

export const NodapayMethodsModal = () => {
  const { isAuthenticated } = useApi();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { data: methods } = useGetNodapayMethods();

  const navigateToDepositModal = () => {
    if (isAuthenticated) {
      navigate('account/wallet/deposit');
      closeModal?.();
    } else {
      openModal?.(MODAL_NAME.SIGNUP);
    }
  };

  return (
    <div className="flex flex-col gap-7 w-full">
      <div className="flex flex-col gap-3 rounded-[20px] py-[30px] px-[20px] r_sm:px-[10px] bg-gradient">
        <p className="text-text_2 font-bold text-[38px] r_sm:text-[30px] text-center">
          {t('NodapayModal.welcomeToJacktop')}
        </p>
        <p className="text-text_2 font-bold text-[26px] r_sm:text-[20px] text-center">
          <Trans components={{ '1': <span className="r_sm:text-text_8" /> }}>
            {t('NodapayModal.makeQuickDeposit')}
          </Trans>
        </p>
        <div
          className={classNames(
            'max-h-[300px] flex flex-wrap gap-5 overflow-scroll py-5 r_sm:gap-[5px] r_sm:py-2.5 r_xsm:justify-center',
            methods && methods?.length > 3 ? 'flex-1' : ''
          )}>
          {methods?.length ? (
            methods?.map((method, index) => (
              <NodapayMethodCard
                key={index}
                method={method}
                onClick={navigateToDepositModal}
                id={`${index}`}
              />
            ))
          ) : (
            <SquareSkeleton
              count={5}
              classNameForSqueare="!pb-[15%] w-[120px] h-[100px] min-w-[120px]"
            />
          )}
          <div
            className={
              'rounded-[10px] relative w-[120px] min-h-[86px] flex flex-col items-center justify-center p-[15px] gap-1 bg-background_5'
            }>
            <input
              type="radio"
              className="absolute w-[100%] h-[100%] top-0 left-0 opacity-0 cursor-pointer"
              onClick={navigateToDepositModal}
            />
            <p className="text-[15px] text-center text-black font-bold uppercase">
              {t('NodapayModal.allBanks')}
            </p>
          </div>
        </div>
        <Button
          mode="primary"
          additionalClassName="m-auto"
          label={t('general.deposit') as string}
          onClick={navigateToDepositModal}
        />
      </div>
    </div>
  );
};
