export const AllRightsLanding = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 44 46" fill="none">
      <path
        d="M38.7028 17.5475C39.3259 19.3866 39.642 21.3099 39.642 23.2717C39.642 33.1082 31.6392 41.1108 21.8024 41.1108C11.9657 41.1108 3.96284 33.1082 3.96284 23.2717C3.96284 13.4349 11.9656 5.43246 21.8024 5.43246C23.7643 5.43246 25.6878 5.74827 27.5265 6.3716V2.4017C25.6692 1.89259 23.751 1.63428 21.8024 1.63428C9.87162 1.63428 0.165039 11.3408 0.165039 23.2717C0.165039 35.2025 9.87162 44.909 21.8024 44.909C33.7332 44.909 43.4398 35.2025 43.4398 23.2717C43.4398 21.3232 43.1815 19.405 42.6725 17.5475H38.7028Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6471 22.6144C30.7979 21.9866 31.4882 20.8568 31.4882 19.5805C31.4882 17.1746 29.4587 15.6472 26.2367 15.6472C23.0566 15.6472 21.0062 17.1326 21.0062 19.476C21.0062 20.7523 21.8012 21.9237 23.0357 22.6144C21.3409 23.2837 20.2529 24.7065 20.2529 26.3804C20.2529 28.9745 22.5962 30.6276 26.2577 30.6276C29.9191 30.6276 32.2623 28.9536 32.2623 26.3174C32.2623 24.6856 31.258 23.2837 29.6471 22.6144ZM26.2577 27.9287C24.6468 27.9287 23.7678 27.2383 23.7678 26.0037C23.7678 24.7903 24.6466 24.0998 26.2577 24.0998C27.8686 24.0998 28.7683 24.7904 28.7683 26.0037C28.7683 27.2593 27.8686 27.9287 26.2577 27.9287ZM26.2577 21.4216C24.8978 21.4216 24.1446 20.8361 24.1446 19.7898C24.1446 18.702 24.8976 18.0952 26.2577 18.0952C27.6176 18.0952 28.3708 18.702 28.3708 19.7898C28.3708 20.8359 27.6176 21.4216 26.2577 21.4216Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M13.7702 18.7847V30.4596H17.4944V15.793H11.0713V18.7847H13.7702Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M37.3849 3.27734V7.40012H41.5073V10.3645H37.3849V14.4871H34.4202V10.3645H30.3223V7.40012H34.4202V3.27734H37.3849Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
