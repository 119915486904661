import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDrawer } from 'src/hooks/useDrawer';
import { Sidebar } from '..';
import { useLanguageService } from '../LanguageService/LanguageService';
import { executeAdditionalGTMScript } from 'src/utils';

export const GameLayout: FC = () => {
  const location = useLocation();
  useLanguageService();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    executeAdditionalGTMScript(userId ? parseInt(userId) : undefined);
  }, [location.pathname]);

  return (
    <>
      <Outlet />
      <WithSidebar />
    </>
  );
};

function WithSidebar() {
  const { isLeftDrawerOpen, closeLeftDrawer } = useDrawer();

  return (
    <div
      className="absolute top-0 left-0 w-full h-full transition-all duration-500 z-[700]"
      style={{
        transform: isLeftDrawerOpen ? 'translateX(0px)' : 'translateX(-100%)'
      }}>
      <div
        className="absolute top-0 left-0 transition-all duration-500 w-full h-full z-0"
        onClick={closeLeftDrawer}></div>
      <Sidebar />
    </div>
  );
}
