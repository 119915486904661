import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetShortBalance } from 'src/api/balance/balance.hooks';
import { ShortBonus, useGetBonuses } from 'src/api/bonuses';
import { useGetAllBonusesTransactions } from 'src/api/bonusesTransaction';
import { useGetDepositMethods, useMakeDeposit, useMakeRedirectDeposit } from 'src/api/deposit/deposit.hooks';
import { useGetFullInfo } from 'src/api/profile';
import { Button } from 'src/components/Buttons/Button';
import { AmountPresets } from 'src/components/DepositComponents/AmountPresets/AmountPresets';
import { DepositBonuses } from 'src/components/DepositComponents/DepositBonuses/DepositBonuses';
import { DepositPromoCode } from 'src/components/DepositComponents/DepositPromoCode';
import { PaymentMethods } from 'src/components/DepositComponents/PaymentMethods';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { Input } from 'src/components/Inputs/Input';
import { Spin } from 'src/components/svg';
import {
  FieldType,
  useForm,
  validateMaxLength,
  validateNumbersString,
  validatePositiveNumber,
  validateRequired,
  validateTransactionValue,
} from 'src/hooks/useForm';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { useAmountPresets } from 'src/hooks/walletDepositHooks/useAmountPreset';
import { PaymentMethod } from 'src/types';
import {
  getCurrencyIcon,
  makeFloat,
  prepareMethodOptions,
  prepareVoucherOptions,
  showShortBalance,
  validateDepositButton,
} from 'src/utils';

import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent.js';
import { generatePassword } from '../../utils/timer';

export type Values = {
  depositAmount?: string;
};

export type BalanceFormatted = {
  amount: string;
  currency: string;
  currencySign: string;
  data: string;
};

interface DepositProps {
  isModal?: boolean;
  isUserFilled?: boolean;
}

export const Deposit: FC<DepositProps> = () => {
  const depositRef = useRef<HTMLInputElement>(null);
  const promoCodeRef = useRef<HTMLInputElement>(null);
  const { refetch: refetchBonusesTransactions } = useGetAllBonusesTransactions(
    {
      bonus_type: 0,
      status: '0',
    },
    false,
    false,
  );
  const [isAttentionNodePay, setIsAttentionNodePay] = useState<boolean>(false);

  const { t } = useTranslation();
  const location = useLocation();
  const { openedModal, openModal } = useModal();
  const { data, sortedPaymentMethods } = useGetDepositMethods();
  const { isLoading, mutateAsync } = useMakeDeposit();
  const { fullInfo: { email: userEmail } = {} } = useGetFullInfo({});
  const makeRedirectDeposit = useMakeRedirectDeposit();
  const balance = useShortBalanceFormatted();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [coin, setCoin] = useState('');
  const [promoCode, setPromoCode] = useState<string>('');
  const [clickedId, setClickedId] = useState<number>();
  const { data: bonuses } = useGetBonuses();
  const [searchParams] = useSearchParams();
  const amountPresets = useAmountPresets({
    currency: balance.currency,
    minPayment: data?.min,
    paymentMethod,
  });

  const {
    errors,
    fieldsProps: { amountProps },
    isFormValid,
    setValues,
    values,
  } = useForm<{ amount: FieldType.Number }>({
    amount: {
      defaultValue: '100',
      fieldType: FieldType.Number,
      placeholder: `${t('general.enterAmount')}`,
      validation: [
        validateRequired,
        validateMaxLength(20),
        validateNumbersString,
        validatePositiveNumber,
        validateTransactionValue({
          currency: balance.currencySign,
          max: data?.max,
          maxPaymentMethod: paymentMethod?.max_amount,
          method: paymentMethod?.method,
          min: data?.min,
          minPaymentMethod: paymentMethod?.min_amount,
          notFormattedCurrency: balance.currency,
        }),
      ],
    },
  });

  const handleChangePromoCode = useCallback((code: string) => {
    setPromoCode(code);
  }, []);

  useEffect(() => {
    setValues({ amount: amountPresets[1]?.toString() });
  }, [amountPresets]);

  useEffect(() => {
    if (location?.state?.scrollToDeposit) {
      setTimeout(() => {
        depositRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
      });
    }
  }, [location?.state?.scrollToDeposit]);

  useEffect(() => {
    if (location?.state?.scrollToPromocode) {
      setTimeout(() => {
        promoCodeRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
      });
    }
  }, [location?.state?.scrollToPromocode]);

  useEffect(() => {
    const httpReferrer = searchParams.get('http_referrer');
    if (httpReferrer && httpReferrer.includes('somnia.megapartners.io')) {
      setPromoCode('BETSOMNIA150FS');
    }
  }, []);

  const clickedBonus = useMemo(() => {
    return bonuses.find((bonus) => bonus.id === clickedId);
  }, [bonuses, clickedId]);

  const changeDepositSumm = (value: number) => {
    setValues({ amount: value.toString() });
  };
  const onSelectPaymentMethod = useCallback(
    (value?: PaymentMethod) => {
      if (value) {
        sendGoogleAnalyticsEvent('payment_system_chosen', {
          payment_system_id: value.method,
        });
      }

      if (coin) {
        setCoin('');
      }
      setPaymentMethod(value);
    },
    [coin],
  );

  const handleClickBonus = useCallback((id?: number) => {
    setClickedId(id);
  }, []);

  const handleBonusClick = (bonus: ShortBonus) => {
    sendGoogleAnalyticsEvent('deposit_bonus_chosen', {
      bonus_id: bonus.name,
    });
  };

  const onSelectCoinType = (element?: string) => {
    if (element) {
      if (paymentMethod?.type === 'coin') {
        sendGoogleAnalyticsEvent('crypto_coin_chosen', {
          coin_id: element,
        });
      }

      setCoin(element);
    }
  };

  const { fullInfo } = useGetFullInfo();

  const isPhoneEmail = useMemo(() => {
    return fullInfo?.email && fullInfo.email.includes('@phone.com');
  }, [fullInfo?.email]);

  const isFilledProfile = useMemo(() => {
    return Boolean(
      fullInfo?.name &&
        fullInfo?.surname &&
        fullInfo?.sex &&
        fullInfo?.birthday &&
        fullInfo?.address &&
        fullInfo?.city &&
        fullInfo?.postcode &&
        fullInfo?.country &&
        fullInfo?.phone,
    );
  }, [fullInfo]);

  useEffect(() => {
    const isAttention = fullInfo?.country === 'SWE' && paymentMethod && paymentMethod.method.startsWith('nodapay');
    setIsAttentionNodePay(!!isAttention);
  }, [paymentMethod, fullInfo?.country]);

  const handleVoucherDropdownChange = (element: string | undefined) => {
    setValues({ amount: element || '0' });
  };
  const handleSubmitClick = useCallback(async () => {
    if (promoCode) {
      sendGoogleAnalyticsEvent('promocode_used', {
        promocode: promoCode,
      });
    }

    const method = coin ? `${paymentMethod?.method}_${coin}` : paymentMethod?.method;
    const methodForModal = paymentMethod?.type;
    const amount = parseInt(values.amount!, 10) * 100;

    if (isPhoneEmail && fullInfo?.email) {
      openModal?.(MODAL_NAME.UPDATE_MAIL_AND_PASSWORD, {
        isUserFilled: isFilledProfile,
        oldPassword: generatePassword(fullInfo.email),
      });
      return;
    }

    if (methodForModal !== 'coin' && !isFilledProfile) {
      openModal?.(MODAL_NAME.UPDATE_USER_INFORMATION);
      return;
    }

    const objectToSend = {
      amount,
      email: userEmail,
      method: method!,
      promocode: promoCode,
    };
    const response = await mutateAsync(objectToSend);
    refetchBonusesTransactions();
    if (!response) {
      return;
    }
    const userId = localStorage.getItem('userId');
    sendGoogleAnalyticsEvent('deposit_made', {
      deposit_amount: amount,
      deposit_payment_system: paymentMethod?.type === 'coin' ? null : paymentMethod?.method,
      deposit_token: paymentMethod?.type === 'coin' ? coin : null,
      deposit_type: paymentMethod?.type === 'coin' ? 'crypto' : 'fiat',
      user_id: userId,
    });

    if (methodForModal === 'coin') {
      openModal?.(MODAL_NAME.DEPOSIT_CRYPTO, response.data);
    }
    if (response?.data?.method?.toUpperCase() === 'POST' && response?.data?.formFields) {
      openModal?.(MODAL_NAME.FORM_FIELDS, {
        ...response.data,
        paymentMethodName: paymentMethod?.title,
      });
    } else if (response?.data?.method === 'POST' && response?.data?.params) {
      makeRedirectDeposit.mutate({
        params: response.data.params,
        url: response.data.checkoutUrl,
      });
    } else if (response?.data?.checkoutUrl) {
      setTimeout(() => {
        window.open(response.data.checkoutUrl, '_self');
      });
    }
  }, [
    coin,
    paymentMethod?.method,
    paymentMethod?.type,
    paymentMethod?.title,
    values.amount,
    promoCode,
    userEmail,
    mutateAsync,
    refetchBonusesTransactions,
    openModal,
    makeRedirectDeposit,
  ]);

  const minDeposit = useMemo(() => {
    return paymentMethod?.min_amount && balance.currency
      ? showShortBalance({
          balance: parseFloat(paymentMethod.min_amount),
          currency: balance.currency,
        })
      : null;
  }, [paymentMethod, balance.currency]);

  return (
    <div className="active-balance-card flex flex-col gap-7 rounded-[20px] px-4 lg:px-0">
      <div
        className={classNames(
          'flex flex-col gap-3 rounded-[20px] px-[20px] pt-[30px] r_sm:px-[10px] r_mid:pt-[12px]',
          openedModal === MODAL_NAME.POPUP_DEPOSIT ? '' : ' r_sm:mx-[-20px]',
        )}
        id="depositPromocode"
        ref={depositRef}
      >
        <PaymentMethods
          balance={balance}
          onSelectPaymentMethod={onSelectPaymentMethod}
          paymentMethods={sortedPaymentMethods}
          selectedMethod={paymentMethod?._reactDepositMethodId}
        />
        <div className="flex w-full justify-center pt-[12px] pb-[12px] lg:justify-start ">
          <span className="deposit-label-font">Amount</span>
          {minDeposit && (
            <span className="deposit-label-description-font ml-[16px]">
              {`min.deposit: ${balance.currencySign}${makeFloat(data?.min)}`}
            </span>
          )}
        </div>
        {isAttentionNodePay && (
          <div className="flex w-full justify-center pt-[12px] pb-[12px] lg:justify-start text-text_14">
            Attention! By using this payment method you MUST pay in EUR, any other currency will not pass!
          </div>
        )}
        {paymentMethod?.type !== 'voucher' && paymentMethod?.options && paymentMethod?.options.length ? (
          <div className="z-[22]">
            <Dropdown
              onChange={onSelectCoinType}
              options={prepareMethodOptions(paymentMethod)}
              placeholder={`${t('accountDeposit.select')} ${paymentMethod.type}`}
              value={coin}
            />
          </div>
        ) : null}

        {(paymentMethod?.type === 'voucher' && !paymentMethod?.options) || paymentMethod?.type !== 'voucher' ? (
          <AmountPresets
            amountPresets={amountPresets}
            onClick={changeDepositSumm}
            value={values.amount}
          />
        ) : null}

        {paymentMethod?.type !== 'voucher' && (
          <>
            <Input
              autoComplete="off"
              {...amountProps}
              className="text-center text-[16px] font-bold placeholder:text-center"
              placeholder={`${t('general.minDeposit')}: ${balance.currencySign}${makeFloat(data?.min)}`}
              rightIcon={<div>{balance.currencySign}</div>}
            />
            {errors.amount && <span className="text-xs text-text_2">{errors.amount}</span>}
          </>
        )}
        {paymentMethod?.type === 'voucher' && !paymentMethod?.options && (
          <>
            <Input
              autoComplete="off"
              {...amountProps}
              className="text-center text-[16px] font-bold placeholder:text-center"
              placeholder={`${t('general.minDeposit')}: ${balance.currencySign}${makeFloat(data?.min)}`}
              rightIcon={<div>{balance.currencySign}</div>}
            />
            {errors.amount && <span className="text-xs text-text_2">{errors.amount}</span>}
          </>
        )}
        {paymentMethod?.type === 'voucher' && paymentMethod?.options && (
          <div className="z-[21]">
            <Dropdown
              onChange={handleVoucherDropdownChange}
              options={prepareVoucherOptions(paymentMethod?.options)}
              placeholder={`${t('accountWalletDeposite.selectAmount')}`}
              value={values.amount}
            />
          </div>
        )}
        <div ref={promoCodeRef}></div>
        <DepositBonuses
          bonuses={bonuses}
          clickedBonusId={clickedId}
          onBonusClick={handleBonusClick}
          setClickedBonusId={handleClickBonus}
          shortBalance={balance}
        />
        <DepositPromoCode
          changePromoCode={handleChangePromoCode}
          depositAmount={values?.amount}
          externalPromoCode={promoCode}
          placeholder={`${t('general.placeholderPromo')}`}
        />
        <div className="mb-[60px] flex justify-center pt-[20px] r_small:block r_small:w-full r_mid:mb-[30px]">
          <Button
            additionalClassName="flex-col text-center r_small:w-full r_small:max-w-full"
            disabled={validateDepositButton(isFormValid, coin, paymentMethod)}
            label={`${t('accountWalletBalance.deposite')}`}
            mode="primary"
            onClick={handleSubmitClick}
          >
            {!isLoading ? (
              <>
                <p className="uppercase">{`${t('accountWalletBalance.deposite')}`}</p>
                {clickedBonus && (
                  <p className="text-[14px] font-normal">
                    + bonus {clickedBonus.percent}%{clickedBonus.freeSpins ? ` + ${clickedBonus.freeSpins} FS` : null}
                  </p>
                )}
              </>
            ) : (
              <Spin />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export function useShortBalanceFormatted() {
  const shortBalance = useGetShortBalance();

  const currencySign = useMemo(() => {
    if (!shortBalance?.data?.currency) return '€';
    return getCurrencyIcon(shortBalance?.data?.currency);
  }, [shortBalance?.data?.currency]);

  const currencyName = useMemo(() => {
    if (!shortBalance?.data?.currency) return 'EUR';
    return shortBalance?.data?.currency;
  }, [shortBalance?.data?.currency]);

  const currentBalance = useMemo(() => {
    if (!shortBalance?.data?.balance === undefined) return 'N/A';
    return makeFloat(shortBalance?.data?.balance).toFixed(2);
  }, [shortBalance?.data?.balance]);

  return {
    amount: currentBalance,
    currency: currencyName,
    currencySign,
    data: useMemo(() => `${currencySign} ${currentBalance}`, [currencySign, currentBalance]),
  };
}
