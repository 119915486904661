import { createContext, useCallback, useState, useContext, ReactNode, useRef } from 'react';
import uuid from 'react-uuid';

export enum MESSAGE_STATUS {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface OpenedMessage {
  id: string;
  text: string | ReactNode;
  status: MESSAGE_STATUS;
  closed: boolean;
}

export const MessageContext = createContext<{
  openMessage?: (text: string, status: MESSAGE_STATUS, error?: any) => void;
  openedMessages?: OpenedMessage[];
  closeMessage?: (id: string) => void;
  destroyMessage?: (id: string, messages: OpenedMessage[]) => void;
}>({});

export const useInitMessage = () => {
  const [openedMessages, setOpenedMessages] = useState<OpenedMessage[]>([]);
  const openedMessagesRef = useRef<OpenedMessage[]>([]);

  const closeMessage = (id: string) => {
    setOpenedMessages((oldMessages) => oldMessages.filter((el) => el.id !== id));
    openedMessagesRef.current = openedMessagesRef.current.filter((el) => el.id !== id);
  };

  const openMessage = useCallback((text: string, status: MESSAGE_STATUS, error?: any) => {
    const errorMessage = error?.response?.data?.message;

    if (status === MESSAGE_STATUS.ERROR) {
      const isRepeated = openedMessagesRef.current.find((message) => message.text === text);
      const curlRegex = /\bcurl\b/;
      if (!!isRepeated === false && !curlRegex.test(text)) {
        setOpenedMessages((messages) => [
          {
            id: error ? errorMessage : text,
            text: error ? errorMessage : text,
            status,
            closed: false
          },
          ...messages
        ]);
        openedMessagesRef.current.push({
          id: text,
          text: error ? errorMessage : text,
          status,
          closed: false
        });
        return;
      }
    } else {
      setOpenedMessages((messages) => [{ id: uuid(), text, status, closed: false }, ...messages]);
      openedMessagesRef.current.push({
        id: uuid(),
        text,
        status,
        closed: false
      });
    }
  }, []);

  return { openMessage, openedMessages, closeMessage };
};

export const useMessage = () => {
  return useContext(MessageContext);
};
