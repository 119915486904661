import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'src/hooks/useApi';
import {
  allBonusesTransactions,
  allFreeSpins,
  cancelBonusWager,
  singleBonusTransaction,
  updateBonusStatus
} from './bonusesTransaction.requests';
import {
  AllBonusesTransactionsBody,
  AllBonusesTransactionsResponse,
  AllFreeSpinsResponse,
  BonusTransaction
} from './bonusesTransaction.types';

export function useGetAllBonusesTransactions(
  params: AllBonusesTransactionsBody,
  isNeedPages = false,
  isEnabled = true
) {
  const [page, setPage] = React.useState(1);
  const { isAuthenticated } = useApi();

  const { data: response, ...query } = useQuery<
    AxiosResponse<AllBonusesTransactionsResponse>,
    AxiosError
  >(
    [
      'bonuses-transactions',
      `bonus page ${isNeedPages && page}`,
      `bonus bonus_type ${params.bonus_type}`,
      `bonus status ${params.status}`,
      `bonus date_start ${params.date_start}`,
      `bonus date_end ${params.date_end}`,
      `bonus currency ${params.currency}`,
      `bonus amount_from ${params.amount_from}`,
      `bonus amount_to ${params.amount_to}`,
      `bonus type ${params.type}`
    ],
    () => {
      return !isNeedPages
        ? allBonusesTransactions({ ...params })
        : allBonusesTransactions({ ...params, page });
    },
    {
      keepPreviousData: true,
      enabled: isAuthenticated && isEnabled
    }
  );
  return {
    page,
    setPage,
    allBonusesTransactions: response?.data,
    arrayBonusesTansactions: response?.data?.bonusTransactions || [],
    lengthArrayBonusesTansactions: response?.data?.bonusTransactions.length || 0,
    numberPages: response?.data?.numberPages,
    ...query
  };
}

export function useGetAllFreeSpins({
  onSuccess
}: {
  onSuccess?: (data: AxiosResponse<AllFreeSpinsResponse>) => void;
}) {
  const { isAuthenticated } = useApi();
  const { data: response, ...query } = useQuery<AxiosResponse<AllFreeSpinsResponse>, AxiosError>(
    ['all-free-spins'],
    () => allFreeSpins(),
    { onSuccess: onSuccess, enabled: isAuthenticated }
  );

  return {
    freeSpins: response?.data?.freeSpins,
    ...query
  };
}

export function useGetSingleBonusTransaction(id: number) {
  const { data: response, ...query } = useQuery<AxiosResponse<BonusTransaction>, AxiosError>(
    ['singleBonusTransaction'],
    () => singleBonusTransaction(id)
  );
  return {
    bonusTransaction: response?.data,
    ...query
  };
}

export function useUpdateBonusStatus() {
  const QueryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateBonusStatus,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      QueryClient.invalidateQueries(['bonuses-transactions']);
      QueryClient.invalidateQueries(['full-balance']);
      QueryClient.invalidateQueries(['short-balance']);
    }
  });

  return mutation;
}

export function useCancelBonusWager() {
  const QueryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: cancelBonusWager,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      QueryClient.invalidateQueries(['bonuses-transactions']);
      QueryClient.invalidateQueries(['full-balance']);
      QueryClient.invalidateQueries(['short-balance']);
    }
  });

  return mutation;
}
