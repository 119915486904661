import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import classNames from 'classnames';
import './styles.css';

type Props = {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  alwaysDisabled?: boolean;
};

export const Switch: FC<Props> = ({
  label,
  checked = false,
  disabled,
  onChange,
  name,
  onClick,
  alwaysDisabled = false
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsChecked((isChecked) => !isChecked);
      onChange?.(e);
    },
    [onChange]
  );

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className="flex gap-2.5 items-center" onClick={onClick}>
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={(event) => !alwaysDisabled && handleChange(event)}
          name={name}
        />
        <span
          className={classNames('slider round ', {
            'cursor-not-allowed': disabled
          })}></span>
      </label>
      <p className="font-medium text-[16px] text-text_2">{label}</p>
    </div>
  );
};
