import classNames from 'classnames';
import { FC, ReactNode, useRef } from 'react';

import { AccountMenu } from '../AccountMenu';
import { PWAModal } from '../Modal/PWAModal';

import './styles.css';

type HeaderProps = {
  children: ReactNode;
  accountHeader?: boolean;
  hideHeaderInDesctopView?: boolean;
  hideHeaderInAccountDesctopView?: boolean;
  additionalClassName?: string;
  accountHeaderChildren?: ReactNode;
};

export const Header: FC<HeaderProps> = ({
  children,
  accountHeader = false,
  hideHeaderInDesctopView = false,
  additionalClassName,
  accountHeaderChildren,
  hideHeaderInAccountDesctopView
}) => {
  const iconMenuRef = useRef(null);
  return (
    <>
      <PWAModal />

      <div
        className={classNames(
          'w-full fixed top-0 left-0 right-0 header-shadow',
          additionalClassName
        )}>
        <header
          className={classNames(
            'flex justify-center items-center',
            accountHeader ? 'h-[100px] flex-col' : '',
            hideHeaderInDesctopView ? 'md:hidden' : '',
            hideHeaderInAccountDesctopView ? 'lg:hidden' : ''
          )}>
          <div
            className={classNames(
              accountHeader ? 'h-[50px]' : 'h-full',
              'w-full flex justify-center items-center'
            )}>
            {children}
          </div>
          {accountHeader ? (
            <div className="w-full h-[50px] flex justify-center items-center relative bg-background_1">
              {accountHeaderChildren}
              <AccountMenu iconMenuRef={iconMenuRef} />
            </div>
          ) : null}
        </header>
      </div>
    </>
  );
};
