import { breakpoints } from './breakpoints';

export type ResponsiveAtProps = {
  children: React.ReactElement;
  at: keyof typeof breakpoints | Array<keyof typeof breakpoints>;
};

export type ResponsiveFromProps = {
  children: React.ReactElement;
  from?: number | keyof typeof breakpoints;
  to?: number | keyof typeof breakpoints;
};

// typeGuards
export function isFrom(
  props: ResponsiveAtProps | ResponsiveFromProps
): props is ResponsiveFromProps {
  return (
    (props as ResponsiveFromProps).from !== undefined ||
    (props as ResponsiveFromProps).to !== undefined
  );
}

export function isAt(props: ResponsiveAtProps | ResponsiveFromProps): props is ResponsiveAtProps {
  return (props as ResponsiveAtProps).at !== undefined;
}
