import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import { Button } from 'src/components/Buttons/Button';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';

export const DepositCryptoModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModal();
  const { openMessage } = useMessage();

  return (
    <div className="flex flex-col items-center gap-5 p-[15px] w-[400px] r_mid:w-full h-full">
      <p className="text-2xl r_sm:text-xl font-bold whitespace-normal text-center text-text_2">
        {`${t(
          'accountWalletDeposite.successTitle'
        ).toUpperCase()} ${modalProps.exchangeCurrency.toUpperCase()}`}
      </p>
      <div className="flex flex-col items-start gap-2 w-full text-text_2">
        <Trans
          components={{
            '1': (
              <span
                className="text-text_8 break-all underline cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(modalProps.amount);
                  openMessage?.(`${t('general.amountCopied')}`, MESSAGE_STATUS.SUCCESS);
                }}
              />
            ),
            '2': (
              <span
                className="text-text_8 break-all underline cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(modalProps.account || '');
                  openMessage?.(`${t('general.amountCopied')}`, MESSAGE_STATUS.SUCCESS);
                }}
              />
            )
          }}
          values={{
            amount: `${modalProps.exchangeAmount} ${modalProps.exchangeCurrency}`,
            paymentAddress: modalProps.account || ''
          }}>
          {t('accountWalletDeposite.successText')}
        </Trans>
        <Button
          mode="primary"
          onClick={() => closeModal?.()}
          label={`${t('modalResetPassword.okay')}`}
          title={`${t('modalResetPassword.okay')}`}
          additionalClassName="mt-[30px] m-auto"
        />
      </div>
    </div>
  );
};
