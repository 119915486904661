import { useEffect, useState } from 'react';

function detect() {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  );
}

function detectIos() {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(userAgent.match(/iPhone|iPad|iPod/i));
}

export function useDeviceDetect() {
  const [isMobile, setMobile] = useState(detect());
  const [isIos, setIsIos] = useState(detectIos());

  useEffect(() => {
    setMobile(detect());
    setIsIos(detectIos());
  }, []);

  return { isMobile, isIos };
}
