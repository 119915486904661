import { Profile, Wallet, Promo, Vip, GameHistory, LogOut } from '../components/svg';

export enum COMPONENT_TYPE {
  LINK = 'LINK',
  BUTTON = 'BUTTON'
}

export const profileMenuItems = [
  {
    key: 'profile',
    type: COMPONENT_TYPE.LINK,
    route: '/account/profile',
    content: 'profileMenuItems.profile',
    icon: Profile
  },
  // {
  //   key: 'jackpots',
  //   type: COMPONENT_TYPE.LINK,
  //   route: '/account/jackpots',
  //   content: 'profileMenuItems.jackpots',
  //   icon: Jackpots
  // },
  {
    key: 'wallet',
    type: COMPONENT_TYPE.LINK,
    route: '/account/wallet',
    content: 'profileMenuItems.wallet',
    icon: Wallet
  },
  {
    key: 'promo',
    type: COMPONENT_TYPE.LINK,
    route: '/account/promo',
    content: 'profileMenuItems.promo',
    icon: Promo
  },
  {
    key: 'vip',
    type: COMPONENT_TYPE.LINK,
    route: '/account/vip',
    content: 'profileMenuItems.vip',
    icon: Vip
  },
  {
    key: 'transaction-history',
    type: COMPONENT_TYPE.LINK,
    route: '/account/transaction-history',
    content: 'profileMenuItems.gameHistory',
    icon: GameHistory
  },
  {
    key: 'logOut',
    type: COMPONENT_TYPE.BUTTON,
    route: '/',
    content: 'profileMenuItems.logOut',
    icon: LogOut
  }
];
