import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGetSinglePage } from '../../api/content/content.hooks';
import { PageTitle } from '../../components';
import { Skeleton } from 'src/components/Skeleton';
import { useGetKefteme } from 'src/hooks/useGetKefteme';

export const ContentPage = () => {
  const { page } = useParams<{ page: string }>();

  const { getPageCodeFormat } = useGetKefteme();
  const { data, isFetching } = useGetSinglePage(getPageCodeFormat(page));

  const content = useMemo(() => {
    return data?.content;
  }, [data?.content]);

  return (
    <div className="w-full">
      <PageTitle title={data?.title || ''} />
      <div className="px-2.5 flex items-center justify-center">
        {isFetching ? (
          <Skeleton rows={1} />
        ) : (
          <div
            className="mt-[30px] py-10 px-7 bg-background_1 rounded-[20px] shadow-xl static-page max-w-[900px] w-full r_sm:px-2.5 text-text_2"
            dangerouslySetInnerHTML={{
              __html: content || ''
            }}
          />
        )}
      </div>
    </div>
  );
};
