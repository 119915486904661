import { useTranslation } from 'react-i18next';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { Button } from '../Buttons/Button';

export const PopUpGame = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-[15px] py-[20px] w-[300px] r_xsm:w-full">
      <p className="text-text_1 text-[16px] font-bold leading-5">
        {t('popup.singlegamefirst')}
      </p>
      <p className="text-text_1 text-[16px] font-bold  leading-5">
        {t('popup.singlegamesecond')}
      </p>
      <Button
        mode="primary"
        additionalClassName="mt-[30px]"
        onClick={() => {
          openModal?.(MODAL_NAME.POPUP_DEPOSIT);
        }}
        title={`${t('general.depositplay')}`}>
        {t('general.depositplay')}
      </Button>
    </div>
  );
};
