export const DeactivateFullScreen = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4998 0.0899658L17.9098 1.49997L13.4098 5.99997H16.9998V7.99997H9.99984V0.999966H11.9998V4.58997L16.4998 0.0899658ZM17.9098 16.5L16.4998 17.91L11.9998 13.41V17H9.99984V9.99997H16.9998V12H13.4098L17.9098 16.5ZM1.49984 0.0899658L5.99984 4.58997V0.999966H7.99984V7.99997H0.999844V5.99997H4.58984L0.0898438 1.49997L1.49984 0.0899658ZM0.0898438 16.5L4.58984 12H0.999844V9.99997H7.99984V17H5.99984V13.41L1.49984 17.91L0.0898438 16.5Z"
        fill="white"
      />
    </svg>
  );
};

export const ActivateFullScreen = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 10.09L7.91 11.5L3.41 16H7V18H0V11H2V14.59L6.5 10.09ZM7.91 6.5L6.5 7.91L2 3.41V7H0V0H7V2H3.41L7.91 6.5ZM11.5 10.09L16 14.59V11H18V18H11V16H14.59L10.09 11.5L11.5 10.09ZM10.09 6.5L14.59 2H11V0H18V7H16V3.41L11.5 7.91L10.09 6.5Z"
        fill="white"
      />
    </svg>
  );
};

export const BigFullScreenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48" fill="none">
      <path
        d="M19.44 40.56V36.72H12.5472L21.1872 28.08L18.48 25.3728L9.84 34.0128V27.12H6V40.56H19.44ZM28.08 21.1872L36.72 12.5472V19.44H40.56V6H27.12V9.84H34.0128L25.3728 18.48L28.08 21.1872Z"
        fill="white"
      />
    </svg>
  );
};

export const BigDeactivateFSIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48" fill="none">
      <path
        d="M39.0002 6.18005L30.0002 15.1801V8.00005H26.0002V22.0001H40.0002V18.0001H32.8202L41.8202 9.00005L39.0002 6.18005ZM8.00018 26.0001V30.0001H15.1802L6.18018 39.0001L9.00018 41.8201L18.0002 32.8201V40.0001H22.0002V26.0001H8.00018Z"
        fill="white"
      />
    </svg>
  );
};
