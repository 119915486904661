import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useGetShortBalance } from 'src/api/balance';
import { useGetAllProviders, useGetGamesByPageCode } from 'src/api/content';
import { useGetGamesPaginated } from 'src/api/games';
import { useScroll } from 'src/hooks/useScroll';
import { getGamePageCode, getGamePageCodeScenario } from 'src/utils';

import { useDebounce } from '../../hooks/useDebounce';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { GamesAndProvidersSearch } from '../GamesAndProvidersSearch/GamesAndProvidersSearch';
import { GamesCategoriesSwitcher } from '../GamesCategoriesSwitcher/GamesCategoriesSwitcher';
import { GamesSearchResults } from '../GamesSearchResults';
import { Responsive } from '../Responsive';

type GamesControlPanelProps = {
  desctopPagination?: number;
  enabled?: boolean;
  gameCategoryParam?: { [gameCategoryName: string]: boolean | number | string | undefined };
  image?: string;
  mobilePagination?: number;
  target: string | undefined;
};

export const GamesControlPanel = ({
  desctopPagination,
  enabled = true,
  gameCategoryParam = {},
  image,
  mobilePagination,
  target,
}: GamesControlPanelProps) => {
  const location = useLocation();
  const pageRef = useRef<HTMLDivElement>(null);
  const [gamesSearchValue, setGamesSearchValue] = useState('');
  const [totalGames, setTotalGames] = useState(window.innerWidth >= 1024 ? 48 : 24);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const paginationPropsChangedRef = useRef<boolean>(true);
  const params = useParams();

  useEffect(() => {
    if (paginationPropsChangedRef.current && mobilePagination && desctopPagination) {
      if (window.innerWidth >= 1024) {
        setTotalGames(desctopPagination);
      } else if (window.innerWidth <= 1023) {
        setTotalGames(mobilePagination);
      }
      paginationPropsChangedRef.current = false;
    }
    const handleWindowResize = () => {
      if (window.innerWidth >= 1024) {
        setTotalGames(desctopPagination ?? 48);
      } else if (window.innerWidth <= 1023) {
        setTotalGames(mobilePagination ?? 24);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      paginationPropsChangedRef.current = true;
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [mobilePagination, desctopPagination]);

  const isGamesPage = location.pathname.includes('games');

  useEffect(() => {
    if (isGamesPage) {
      setTimeout(() => pageRef?.current?.scrollIntoView(true));
    }
  }, [isGamesPage, location.pathname]);

  const gameCategory = useMemo(() => {
    if (gamesSearchValue.length !== 0) return {};
    return gameCategoryParam;
  }, [gameCategoryParam, gamesSearchValue.length]);

  const { providerId, providerTitle } = useGetProviderFromUrl();

  const shouldGetGamesFromContent = useMemo(
    () => getGamePageCodeScenario(target, gamesSearchValue.length),
    [target, gamesSearchValue.length],
  );

  const { allGames, fetchNextPage, hasNextPage, isFetched, isFetching } = useGetGamesPaginated(
    {
      name: gamesSearchValue,
      ...(!providerId && !gamesSearchValue ? gameCategory : undefined),
      per_page: totalGames,
      provider_id: !gamesSearchValue ? providerId : undefined,
    },
    target,
    enabled && !shouldGetGamesFromContent ? true : false,
  );

  const { games } = useGetGamesByPageCode(getGamePageCode(target), {
    enabled: enabled && shouldGetGamesFromContent ? true : false,
  });

  const handleChangeGames = useCallback((value: string) => {
    setGamesSearchValue(value);
  }, []);

  useScroll({
    callback: () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
    childRef,
    parentRef,
  });

  const search = useDebounce<string>(gamesSearchValue, 500);

  useEffect(() => {
    if (search) {
      sendGoogleAnalyticsEvent('search_used');
    }
  }, [search]);

  const searchResults = (
    <div
      className="flex flex-col items-center justify-center"
      ref={parentRef}
    >
      <GamesSearchResults
        allGames={shouldGetGamesFromContent ? games : allGames}
        image={image}
        isFetched={isFetched}
        isFetching={isFetching}
      />
      <div
        className="mb-[70px] h-0"
        ref={childRef}
      ></div>
    </div>
  );

  return (
    <>
      <div className="my-3 flex w-full justify-center text-[30px] font-bold text-text_2 r_sm:mt-1">
        {gamesSearchValue.length === 0 && providerTitle}
      </div>
      <div className="w-full">
        <Responsive at={['lg', 'md', 'xl']}>
          <GamesAndProvidersSearch
            onChange={handleChangeGames}
            value={gamesSearchValue}
          />
        </Responsive>
      </div>
      <GamesCategoriesSwitcher
        lightActiveLink={!gamesSearchValue && !providerId && !params.search}
        onCategoryClick={() => setGamesSearchValue('')}
      />
      {isGamesPage && searchResults}
      {!isGamesPage && gamesSearchValue.length >= 3 && searchResults}
    </>
  );
};

function useGetProviderFromUrl() {
  const [searchParams] = useSearchParams();

  const { data: shortBalance } = useGetShortBalance();

  const providerId = useMemo(() => {
    const providerIdFromUrl = searchParams.get('providerId');
    if (!providerIdFromUrl) return undefined;
    return parseInt(providerIdFromUrl, 10);
  }, [searchParams]);

  const { allProviders } = useGetAllProviders(
    {
      currency: shortBalance?.currency || 'EUR',
      per_page: 9000,
    },
    Boolean(providerId),
  );

  const providerTitle = useMemo(() => {
    if (!providerId) return undefined;
    return allProviders?.find((provider) => provider.id === providerId)?.name;
  }, [providerId, allProviders]);

  return { providerId, providerTitle };
}
