import { useTranslation } from 'react-i18next';
import bronse from 'src/assets/images/bronse.webp';
import gold from 'src/assets/images/gold.webp';
import hammer from 'src/assets/images/hammer.webp';
import silver from 'src/assets/images/silver.webp';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

import { Button } from '../Buttons/Button';
import { LoyaltyArrow } from '../svg';

const loyaltyData = [
  {
    image: hammer,
    text: 'loyaltyData.useSimplified',
    title: 'loyaltyData.loginOn',
  },
  {
    image: bronse,
    text: 'loyaltyData.enjoyPlaying',
    title: 'loyaltyData.playFavourite',
  },
  {
    image: silver,
    text: 'loyaltyData.goUp',
    title: 'loyaltyData.increase',
  },
  {
    image: gold,
    text: 'loyaltyData.asSoonAs',
    title: 'loyaltyData.increaseLevel',
  },
];

export const Loyalty = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  return (
    <>
      <div className="w-full flex flex-col items-center mb-[40px] r_small:overflow-x-scroll r_small:items-baseline r_small:py-6">
        <div className="w-[900px] md:max-w-full flex justify-between gap-[2%] r_small:w-[900px] pb-[10px]">
          {loyaltyData.map((el, index) => (
            <div
              className="w-[25%] p-[20px] rounded-[30px] relative r_lg:px-[15px] bg-background_2 small-red-shadow r_small:bg-background_8"
              key={index}
            >
              <div className="flex justify-center items-start h-[120px]">
                <img
                  alt={el.title}
                  className="max-h-[120px] w-auto"
                  src={el.image}
                />
                <p className="text-text_2 text-4xl font-extrabold">{`0${index + 1}`}</p>
              </div>
              <p className="font-bold text-[16px] text-text_2">{`${t(el.title)}`}</p>
              <p className="font-[400] text-[12px] text-text_2">{`${t(el.text)}`}</p>
              {index !== loyaltyData.length - 1 ? (
                <div className="flex justify-center items-center w-[50px] h-[50px] border border-[inherit] rounded-full absolute top-1/2 right-0 translate-y-[-50%] translate-x-[65%] z-20 bg-background_31">
                  <LoyaltyArrow />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <Button
        additionalClassName="px-[48px] mb-[100px] m-auto max-w-[200px]"
        label={t('vip.seeRules') as string}
        mode="secondary"
        onClick={() => {
          openModal?.(MODAL_NAME.VIP_RULES);
        }}
        title={t('vip.seeRules') as string}
      />
    </>
  );
};
