// import SingleGame from '../../types/SingleGame';

export type GamesResponse = {
  data: SingleGame[];
  page: number;
  perPage: number;
  totalCount: number;
};

export type GamesBody = {
  category_id?: number;
  currency?: string;
  date_added_dir?: string;
  free_spins?: number;
  is_wagering?: 0 | 1;
  jackpot?: boolean;
  language?: string;
  name?: string;
  page?: number;
  per_page?: number;
  platform_id?: number;
  provider_id?: number;
  source_id?: number;
  type_id?: number;
};

export enum GAMESOURCE {
  Casino = 0,
  CasinoLive = 1,
  Sport = 2,
  VirtualSport = 3,
}

export type SingleGame = {
  categories?: {
    id: number;
    sort: number;
  }[];
  dateAdded?: number;
  device?: number;
  freeSpins?: boolean;
  hasDemo?: boolean;
  id: number;
  image: string;
  isWagering?: boolean;
  jacktop?: {
    amount: number;
    group: {
      currency: string;
      name: string;
    };
    id: number;
    name: string;
  };
  name: string;
  providerId?: number;
  providerName?: string;
  source?: GAMESOURCE;
  type?: number;
};

export type SingleGameResponse = {
  currency: string;
  freeSpins: boolean;
  gameUrl: string;
  id: number;
  image: string;
  isWagering: boolean;
  jackpot: {
    currency: string;
    id: number;
    minBet: number;
  };
  maxBet: number;
  name: string;
  providerId: number;
  source: number;
  tournament: {
    dateEnd: number;
    dateStart: number;
    description: string;
    id: number;
    image: string;
    member: {
      overallProgress: number;
      place: number;
      status: number;
    };
    name: string;
  };
  type: number;
};

export type SingleGameBody = {
  lobby_url?: string;
  platform_id: number;
};

export type DemoGameBody = {
  platform_id?: number;
};

// DemoGameData и GameData - это актуальные типы игр
export type DemoGameData = {
  gameUrl: string;
  id: number;
  image: string;
  name: string;
  providerId: number;
  source: number;
  type: number;
};

export type GameData = DemoGameData & {
  activeTournamentId?: number;
  categories?: {
    id: number;
    sort: number;
  }[];
  currency: string;
  freeSpins: boolean;
  isWagering: boolean;
  jackpot: {
    currency: string;
    group?: { currency: string; name: string };
    id: number;
    minBet: number;
  };
  maxBet: number;
  providerName: string;
  tournament?: {
    dateEnd: number;
    dateStart: number;
    description: string;
    id: number;
    image: string;
    name: string;
  };
};
