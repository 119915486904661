import { useEffect, useState } from 'react';

export const useBodyScroll = (): { isLocked: boolean; toggleLocked: (locked: boolean) => void } => {
  const bodyStyle = document.body.style;

  const [isLocked, setIsLocked] = useState(bodyStyle.overflow === 'hidden');

  useEffect(() => {
    bodyStyle.overflow = isLocked ? 'hidden' : 'auto';
  }, [isLocked, bodyStyle]);

  const toggleLocked = (locked: boolean) => setIsLocked(locked);

  return { isLocked, toggleLocked };
};
