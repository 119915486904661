import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDropdown } from 'src/hooks/useDropdown';

import { Arrow } from '../svg';
import { arrayLanguages, languageOptions } from './const';

import './styles.css';

export default React.memo(function Languages({
  additionalClassName,
  handleClick,
}: {
  additionalClassName?: string;
  handleClick?: () => void;
}) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();

  const { close, showList, toggle } = useDropdown({
    onChange: () => i18n.changeLanguage,
    value: i18n.language,
  });

  const languageName = useMemo(() => {
    const language = languageOptions[i18n.language as keyof typeof languageOptions];
    return language?.labelName;
  }, [i18n.language]);

  const LanguageIcon = useMemo(() => {
    const language = languageOptions[i18n.language as keyof typeof languageOptions];
    return language?.icon;
  }, [i18n.language]);

  useClickOutside(dropdownRef, close);

  const handleOpenClick = () => {
    if (!showList) {
      handleClick?.();
    }
    toggle();
  };

  return (
    <div
      className={classNames(
        'w-[190px] p-[1px] rounded-[20px] mt-[30px] z-20 cursor-pointer h-[180px]',
        additionalClassName,
      )}
    >
      <div
        className="relative text-[14px] w-full z-20 border border-border_6 rounded-[20px]"
        ref={dropdownRef}
      >
        <div
          className="flex gap-2.5 relative flex-row items-center w-full h-full z-20 rounded-[20px] px-[18px] py-[11px] bg-background_2"
          onClick={handleOpenClick}
        >
          <LanguageIcon
            className={classNames('max-w-[24px] max-h-[24px] min-h-[24px] min-w-[24px] w-[24px] h-[24px] rounded-full')}
          />
          <p className="text-[16px] text-text_2 font-bold not-italic">{languageName}</p>
          <Arrow
            className={classNames(
              'absolute right-5 w-[12px] h-full stroke-[3px] transition-transform stroke-background_15',
              { opened: showList },
            )}
          />
        </div>
        {showList && (
          <div className="bg-background_2 languages absolute rounded-b-[20px] top-[50%] z-10 flex flex-col items-start overflow-y-scroll w-[calc(100%+2px)] left-[-1px] max-h-[150px] pt-6 pb-2 shadow px-2 border border-border_6">
            {arrayLanguages.map((option) => (
              <div
                className={classNames(
                  'text-[16px] text-text_2 font-bold not-italic cursor-pointer py-[7px] px-2 flex items-center gap-2.5 hover:text-text_8 transition-all',
                  {
                    'text-text_8': languageName === option.labelName,
                  },
                )}
                key={option.labelName}
                onClick={() => {
                  i18n.changeLanguage(option.labelCode);
                  toggle?.();
                }}
              >
                <>
                  <option.icon className="max-w-[24px] max-h-[24px] min-h-[24px] min-w-[24px] w-[24px] h-[24px] rounded-full" />
                  {option.labelName}
                </>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
