import { useTranslation } from 'react-i18next';
import { AccountMenu } from '../AccountMenu';
import { AccountBurger } from './AccountBurger';
import './styles.css';
import { useDrawer } from 'src/hooks/useDrawer';
import { profileMenuItems } from 'src/constants/profileMenuItems';
import { useMemo, useRef } from 'react';
import { AccountForProfile } from '../svg';
import { useLocation } from 'react-router-dom';

export const AccountHeader = () => {
  const { t } = useTranslation();
  const { toggleTopDrawer } = useDrawer();
  const location = useLocation();
  const iconMenuRef = useRef(null);
  const currentTab = useMemo(
    () => profileMenuItems.find((el) => location.pathname.includes(el.route)),
    [location]
  );

  return (
    <div className="personal-sidebar z-[600]">
      <div className="personal-container z-[600]" onClick={toggleTopDrawer}>
        <div className="flex flex-row z-[600] items-center gap-3  text-text_12">
          {currentTab ? <currentTab.icon /> : <AccountForProfile />}
          <p className="font-bold text-sm">{t(`${currentTab?.content}`)}</p>
        </div>
        <div ref={iconMenuRef}>
          <AccountBurger />
        </div>
      </div>
      <AccountMenu iconMenuRef={iconMenuRef} />
    </div>
  );
};
