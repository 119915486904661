import { useMemo, useState } from 'react';
import { useGetSinglePage } from 'src/api/content';
import { Autoplay, Navigation, Pagination } from 'swiper';

import { MainPageSliderCard } from '../MainPageSliderCard/MainPageSliderCard';
import { NewCarousel } from '../NewCarousel/NewCarousel';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

export const MainPageBanners = () => {
  const { childs } = useGetSinglePage('main_sliders');
  const [showSliderControls, setShowSliderControls] = useState<boolean>(false);

  const mainPageSlides = useMemo(
    () =>
      childs.map((child) => (
        <MainPageSliderCard
          card={child}
          key={child.code}
        />
      )),
    [childs],
  );

  return (
    <div
      className="w-full"
      onMouseEnter={() => setShowSliderControls(true)}
      onMouseLeave={() => setShowSliderControls(false)}
    >
      {childs.length > 0 ? (
        <NewCarousel
          autoplay={
            childs.length > 3
              ? {
                  delay: 7000,
                  disableOnInteraction: true,
                }
              : false
          }
          breakpoints={{
            1024: {
              initialSlide: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              initialSlide: 1,
              slidesPerView: 3,
              spaceBetween: 20,
            },
            320: {
              initialSlide: 0,
              slidesPerView: 1,
              spaceBetween: 5,
            },
            768: {
              initialSlide: 0,
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
          centeredSlidesBounds
          className="custom-swiper-controls min-w-full py-6"
          classNameForSlide="flex justify-center items-center"
          modules={[Navigation, Pagination, Autoplay]}
          navigation={childs.length > 3 && showSliderControls}
          pagination={{
            clickable: true,
          }}
          rewind={true}
          slides={mainPageSlides}
        />
      ) : null}
    </div>
  );
};
