import { FC, PropsWithChildren } from 'react';
import { useBreakpoints } from '../../hooks/responsiveBreakpoints';

import { ResponsiveBreakpoint } from '../../types';

type ResponsiveWrapperProps = {
  at: ResponsiveBreakpoint | ResponsiveBreakpoint[];
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore // TODO тайпскрипт чет бушует
export const Responsive: FC<PropsWithChildren<ResponsiveWrapperProps>> = ({ at, children }) => {
  const { active } = useBreakpoints();
  if (typeof at === 'string') {
    return active === at ? children : null;
  }
  if (Array.isArray(at)) {
    return at.includes(active) ? children : null;
  }
  return null;
};
