import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { usePasswordChecklist } from 'src/hooks/usePasswordChecklist';

import { useChangePassword } from '../../api/auth/auth.hooks';
import { Button } from '../../components/Buttons/Button';
import { PasswordInput } from '../../components/Inputs/PasswordInput';
import {
  FieldType,
  useForm,
  validateDifferenceOfPasswords,
  validateForStringEquality,
  validateMaxLength,
  validatePassword,
  validateRequired,
} from '../../hooks/useForm';

export const Security = () => {
  const { t } = useTranslation();
  const { isLoading, mutateAsync } = useChangePassword();
  const {
    errors,
    fieldsProps: { newPasswordProps, oldPasswordProps, repeatPasswordProps },
    isFormValid,
    touched,
    values,
  } = useForm<{
    newPassword: FieldType.Text;
    oldPassword: FieldType.Text;
    repeatPassword: FieldType.Text;
  }>({
    newPassword: {
      fieldType: FieldType.Text,
      placeholder: `${t('accountProfileSecurity.newPassword')}`,
      validation: [validateRequired, validateMaxLength(50), validateDifferenceOfPasswords, validatePassword],
    },
    oldPassword: {
      fieldType: FieldType.Text,
      placeholder: `${t('accountProfileSecurity.oldPassword')}`,
      validation: [validateRequired, validatePassword, validateMaxLength(50)],
    },
    repeatPassword: {
      fieldType: FieldType.Text,
      placeholder: `${t('accountProfileSecurity.newPassword')}`,
      validation: [validateRequired, validateMaxLength(50), validateForStringEquality],
    },
  });

  const handleClick = () => {
    mutateAsync({
      new_password: values.newPassword.trim(),
      password: values.oldPassword.trim(),
    });
  };

  const passwordChecklist = usePasswordChecklist(values.newPassword);

  return (
    <div className="flex flex-col gap-7">
      <div>
        <PasswordInput
          label={t('accountProfileSecurity.oldPassword')}
          labelWithPadding
          {...oldPasswordProps}
        />
        {touched.oldPassword && errors.oldPassword && <span className="text-xs text-[red]">{errors.oldPassword}</span>}
      </div>
      <div>
        <PasswordInput
          id="password-input"
          label={t('accountProfileSecurity.newPassword')}
          {...newPasswordProps}
          labelWithPadding
        />
        <Tooltip
          anchorSelect="#password-input"
          className="rounded-4xl bg-background_6 text-text_2"
          openOnClick
          place="top"
        >
          <div>
            {passwordChecklist.map((error) => (
              <p
                className={classNames({ 'line-through': error.done })}
                key={error.text}
              >
                {error.text}
              </p>
            ))}
          </div>
        </Tooltip>
        <p className="mt-2.5 pl-[20px] text-[14px] font-medium text-text_2">
          {t('accountProfileSecurity.passwordRules')}
        </p>
        {touched.newPassword && errors.newPassword && <span className="text-xs text-[red]">{errors.newPassword}</span>}
      </div>
      <div>
        <PasswordInput
          label={t('accountProfileSecurity.passwordConfirm')}
          {...repeatPasswordProps}
          labelWithPadding
        />
        {touched.repeatPassword && errors.repeatPassword && (
          <span className="text-xs text-[red]">{errors.repeatPassword}</span>
        )}
      </div>
      <Button
        additionalClassName="mt-2.5 r_sm:m-auto"
        disabled={!isFormValid}
        label={`${t('general.save')}`}
        loading={isLoading}
        mode="secondary"
        onClick={handleClick}
        title={`${t('general.save')}`}
      />
    </div>
  );
};
