export const UploadFile = ({ classNames }: { classNames?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="41"
      viewBox="0 0 47 41"
      fill="none"
      className={classNames}>
      <path
        d="M24.6654 9.92373L24.6036 9.90548C24.2899 9.81272 24.1098 9.48495 24.2039 9.17201L24.6654 9.92373ZM24.6654 9.92373L24.668 9.9207M24.6654 9.92373L24.668 9.9207M24.668 9.9207C24.9623 9.97371 25.2551 9.7993 25.342 9.50852C26.1317 6.87803 28.62 5.03955 31.3932 5.03955C31.7203 5.03955 31.9871 4.77633 31.9871 4.44997C31.9871 4.1236 31.7203 3.86039 31.3932 3.86039C27.9868 3.86039 25.1235 6.1088 24.204 9.17195L24.668 9.9207Z"
        fill="#FFCB00"
        stroke="#FFCB00"
        strokeWidth="0.201091"
      />
      <path
        d="M38.2992 28.4121H35.3395C35.0671 28.4121 34.8462 28.1931 34.8462 27.9231C34.8462 27.6531 35.0671 27.434 35.3395 27.434H38.2992C42.3792 27.434 45.6988 24.1432 45.6988 20.0986C45.6988 16.0541 42.3792 12.7632 38.2992 12.7632H38.2281C38.085 12.7632 37.949 12.7017 37.8553 12.5945C37.7616 12.4873 37.7193 12.3451 37.7397 12.2047C37.7838 11.9001 37.806 11.5941 37.806 11.2962C37.806 7.79088 34.9289 4.93875 31.393 4.93875C30.0174 4.93875 28.7055 5.3649 27.5991 6.17144C27.356 6.34854 27.0107 6.26995 26.8701 6.00482C23.7366 0.0897027 15.5522 -0.704637 11.309 4.441C9.52152 6.60878 8.81919 9.42871 9.38198 12.1771C9.44399 12.4807 9.20967 12.7636 8.89854 12.7636H8.70087C4.62094 12.7636 1.30135 16.0544 1.30135 20.099C1.30135 24.1436 4.62094 27.4344 8.70087 27.4344H11.6606C11.933 27.4344 12.1539 27.6534 12.1539 27.9235C12.1539 28.1935 11.933 28.4125 11.6606 28.4125H8.70087C4.07683 28.4125 0.314697 24.6829 0.314697 20.099C0.314697 15.6436 3.86855 11.9954 8.31362 11.7942C7.89608 8.94281 8.69452 6.06663 10.5451 3.82203C15.088 -1.68755 23.7942 -1.07 27.4746 5.07359C28.6486 4.34389 29.9921 3.96109 31.3928 3.96109C35.6768 3.96109 39.0714 7.57572 38.7744 11.7988C43.1785 12.0436 46.6852 15.6726 46.6852 20.0986C46.6852 24.6829 42.9231 28.4121 38.299 28.4121L38.2992 28.4121Z"
        fill="#FFCB00"
      />
      <path
        d="M11.0005 27.6467C11.0005 34.4445 16.5788 39.9731 23.4338 39.9731C30.2888 39.9731 35.867 34.4444 35.867 27.6467C35.867 20.849 30.2888 15.3204 23.4338 15.3204C16.5788 15.3204 11.0005 20.8491 11.0005 27.6467ZM12.1885 27.6467C12.1885 21.5013 17.2324 16.4997 23.4338 16.4997C29.6351 16.4997 34.6791 21.5012 34.6791 27.6467C34.6791 33.7922 29.6351 38.7937 23.4338 38.7937C17.2325 38.7937 12.1885 33.7923 12.1885 27.6467Z"
        fill="#FFCB00"
        stroke="#FFCB00"
        strokeWidth="0.201091"
      />
      <path
        d="M23.6595 32.9271C23.4601 32.9271 23.2983 32.7668 23.2983 32.5691V23.2604C23.2983 23.0627 23.4601 22.9023 23.6595 22.9023C23.8589 22.9023 24.0206 23.0627 24.0206 23.2604V32.5691C24.0206 32.767 23.8589 32.9271 23.6595 32.9271Z"
        fill="#FFCB00"
        stroke="#FFCB00"
        strokeWidth="1.28571"
      />
      <path
        d="M26.5486 26.4836C26.4562 26.4836 26.3636 26.4486 26.2932 26.3787L23.6594 23.7677L21.0255 26.3787C20.8847 26.5186 20.6559 26.5186 20.5148 26.3787C20.3737 26.2389 20.3737 26.0123 20.5148 25.8724L23.404 23.0082C23.5448 22.8683 23.7736 22.8683 23.9147 23.0082L26.8039 25.8724C26.945 26.0123 26.945 26.2389 26.8039 26.3787C26.7335 26.4488 26.6409 26.4836 26.5485 26.4836H26.5486Z"
        fill="#FFCB00"
        stroke="#FFCB00"
        strokeWidth="1.28571"
      />
    </svg>
  );
};

export const UploadFilePreview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      viewBox="0 0 21 21"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 19.8369H20V0.836914H1V19.8369Z"
        stroke="#E83E44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.8369L5.5 9.33691L1 14.8369"
        stroke="#E83E44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14.8369L13.5 7.33691L7.5 14.8369"
        stroke="#E83E44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="7" cy="4.83691" r="1.5" stroke="#E83E44" strokeWidth="1.5" />
    </svg>
  );
};
