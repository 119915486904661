import { FC, useEffect } from 'react';
import { useDrawer } from '../../hooks/useDrawer';
import { PersonalHeader } from '../Header/PersonalHeader';
import { AccountHeader } from '../Sidebar/AccountHeader';
import classNames from 'classnames';
import { useLanguageService } from '../LanguageService/LanguageService';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Header/Header';
import { AccountAsideContainer } from '../AccountAsideContainer/AccountAsideContainer';
import { AsideInner } from '../AsideInner/AsideInner';
import { Responsive } from '../Responsive';
import { Sidebar } from '../Sidebar';
import { DesktopAside } from '../DesctopAside/DesctopAside';
import { AccountForProfile } from '../svg';
import { WithBackground } from '../WithBackground/WithBackground';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from '../Footer';
import { executeAdditionalGTMScript } from 'src/utils';
import { useBreakpoints } from 'src/hooks/responsiveBreakpoints';

export const AccountLayout: FC = () => {
  const location = useLocation();
  const {
    _mainAccountSidebarOpened,
    _closeMainAccountSidebar,
    _accountSidebarOpened,
    _openAccountSidebar,
    _closeMainSidebar,
    isMobileAppPopupOpened,
    isLeftDrawerOpen
  } = useDrawer();
  const { isLg, isXl } = useBreakpoints();
  useLanguageService();

  const isLargeWindow = isLg || isXl;

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    executeAdditionalGTMScript(userId ? parseInt(userId) : undefined);
  }, [location.pathname]);

  return (
    <FullScreenContainer>
      <Header
        accountHeader
        hideHeaderInAccountDesctopView
        additionalClassName="z-[600]"
        accountHeaderChildren={<AccountHeader />}>
        <PersonalHeader />
      </Header>
      <main
        className={classNames(
          'w-full h-[calc(100%_-_100px)] lg:mt-0 lg:h-full bg-background_15',
          isMobileAppPopupOpened ? 'mt-[130px]' : 'mt-[100px]'
        )}>
        <Responsive at={['gf', 'xs', 'sm', 'md']}>
          <Sidebar />
        </Responsive>
        <aside
          className={
            'hidden fixed bottom-0 h-full lg:flex justify-start items-center w-[340px] overflow-x-hidden'
          }>
          <AccountAsideContainer
            additionalClassName="aside-border"
            extended={_mainAccountSidebarOpened}>
            <AsideInner />
          </AccountAsideContainer>
          <AccountAsideContainer extended={_accountSidebarOpened}>
            {_accountSidebarOpened ? (
              <DesktopAside />
            ) : (
              <div
                className="py-6 cursor-pointer"
                onClick={() => {
                  _openAccountSidebar?.();
                  _closeMainAccountSidebar?.();
                  _closeMainSidebar?.();
                }}>
                <AccountForProfile fill="#FFF" />
              </div>
            )}
          </AccountAsideContainer>
        </aside>
        <WithBackground>
          <div className="w-full h-full pl:0 lg:pl-[340px] flex justify-center items-center">
            <div className="w-full overflow-y-auto ">
              {isLeftDrawerOpen && !isLargeWindow && (
                <div
                  className="fixed top-0 left-0 w-full h-full z-[600]"
                  style={{
                    background: 'rgba(5, 8, 27, 0.50)',
                    backdropFilter: 'blur(5px)'
                  }}></div>
              )}
              <Outlet />
              <Footer />
            </div>
          </div>
        </WithBackground>
      </main>
    </FullScreenContainer>
  );
};
