import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from 'react';
import { AllFreeSpins } from 'src/api/bonusesTransaction';

export const FreeSpinsContext = createContext<{
  oldFreeSpins?: AllFreeSpins;
  saweFreeSpins?: (freeSpins: AllFreeSpins) => void;
  freeSpinsChanged?: boolean;
  setFreeSpinsChanged?: Dispatch<SetStateAction<boolean>>;
}>({});

export const useIniFreeSpins = () => {
  const [oldFreeSpins, setOldFreeSpins] = useState<AllFreeSpins>();
  const [freeSpinsChanged, setFreeSpinsChanged] = useState<boolean>(false);

  const saweFreeSpins = useCallback(
    (freeSpins: AllFreeSpins) => {
      if (oldFreeSpins === undefined) {
        setOldFreeSpins(freeSpins);
        localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
      }
      if (oldFreeSpins && oldFreeSpins?.number < freeSpins.number) {
        setFreeSpinsChanged(true);
        setOldFreeSpins(freeSpins);
        localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
      }
      if (oldFreeSpins?.number === freeSpins.number) {
        setOldFreeSpins(freeSpins);
        localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
      } else {
        setOldFreeSpins(freeSpins);
        localStorage.setItem('oldFreeSpin', `${freeSpins?.number}`);
      }
    },
    [oldFreeSpins]
  );

  return { oldFreeSpins, saweFreeSpins, freeSpinsChanged, setFreeSpinsChanged };
};

export const useFreeSpins = () => {
  return useContext(FreeSpinsContext);
};
