export const GreyControlArrow = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8833 6.19999L9.28333 11.8167C9.21667 11.8833 9.14444 11.9307 9.06667 11.9587C8.98889 11.9867 8.90556 12.0004 8.81667 12C8.72778 12 8.64444 11.986 8.56667 11.958C8.48889 11.93 8.41667 11.8829 8.35 11.8167L2.73333 6.19999C2.57778 6.04443 2.5 5.84999 2.5 5.61666C2.5 5.38332 2.58333 5.18332 2.75 5.01666C2.91667 4.84999 3.11111 4.76666 3.33333 4.76666C3.55556 4.76666 3.75 4.84999 3.91667 5.01666L8.81667 9.91666L13.7167 5.01666C13.8722 4.8611 14.064 4.78332 14.292 4.78332C14.52 4.78332 14.7171 4.86666 14.8833 5.03332C15.05 5.19999 15.1333 5.39443 15.1333 5.61666C15.1333 5.83888 15.05 6.03332 14.8833 6.19999Z"
        fill="#6E7B74"
      />
    </svg>
  );
};
