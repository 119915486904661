import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isLanguageSupported } from 'src/utils/i18n';

export const useLanguageService = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      if (isLanguageSupported(lang)) {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
};
