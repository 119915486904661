import { MakeDepositResponse } from 'src/api/deposit';
import { useModal } from '../../hooks/useModal';
import { FormGenerator } from '../FormGenerator/FormGenerator';

type FormFieldsModalProps = MakeDepositResponse & {
  paymentMethodName?: string;
};

export const FormFieldsModal = () => {
  const { modalProps } = useModal<FormFieldsModalProps>();

  return (
    <div className="px-[15px] py-[20px] max-w-[300px] jxsm:w-full">
      <FormGenerator
        formFields={modalProps?.formFields}
        checkoutUrl={modalProps?.checkoutUrl}
        transaction={modalProps?.transaction}
        paymentMethodName={modalProps?.paymentMethodName}
      />
    </div>
  );
};
