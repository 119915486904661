import { createContext, useContext, useEffect } from 'react';
import { useBoolean } from './useBoolean';

export const DrawerContext = createContext<{
  isMobileAppPopupOpened?: boolean;
  openMobileAppPopup?: () => void;
  closeMobileAppPopup?: () => void;
  toggleMobileAppPopup?: () => void;
  isLeftDrawerOpen?: boolean;
  toggleLeftDrawer?: () => void;
  openLeftDrawer?: () => void;
  closeLeftDrawer?: () => void;
  isTopDrawerOpen?: boolean;
  toggleTopDrawer?: () => void;
  openTopDrawer?: () => void;
  closeTopDrawer?: () => void;
  isBottomDrawerOpen?: boolean;
  toggleBottomDrawer?: () => void;
  openBottomDrawer?: () => void;
  closeBottomDrawer?: () => void;
  isTopSearchDrawerOpen?: boolean;
  toggleIsOpenDrawer?: () => void;
  openIsTopSearchDrawerOpenDrawer?: () => void;
  closeIsTopSearchDrawerOpenDrawer?: () => void;
  isHeaderGameDrawerOpen?: boolean;
  toggleHeaderGameDrawerOpenDrawer?: () => void;
  openHeaderGameDrawerOpenDrawer?: () => void;
  closeHeaderGameDrawerOpenDrawer?: () => void;
  isDesktopSidebarOpen?: boolean;
  closeDesktopSidebar?: () => void;
  openDesktopSidebar?: () => void;
  toggleDesktopSidebar?: () => void;
  isAccountAsideOpen?: boolean;
  closeAccountAside?: () => void;
  openAccountAside?: () => void;
  toggleAccountAside?: () => void;
  isAccountSidebarOpen?: boolean;
  closeAccountSidebar?: () => void;
  openAccountSidebar?: () => void;
  toggleAccountSidebar?: () => void;
  _mainSidebarOpened?: boolean;
  _openMainSidebar?: () => void;
  _closeMainSidebar?: () => void;
  _toggleMainSidebar?: () => void;
  _mainAccountSidebarOpened?: boolean;
  _openMainAccountSidebar?: () => void;
  _closeMainAccountSidebar?: () => void;
  _toggleMainAccountSidebar?: () => void;
  _accountSidebarOpened?: boolean;
  _openAccountSidebar?: () => void;
  _closeAccountSidebar?: () => void;
  _toggleAccountSidebar?: () => void;
  goToAccount?: () => void;
}>({});

export const useInitDrawer = () => {
  const [isLeftDrawerOpen, openLeftDrawer, closeLeftDrawer, toggleLeftDrawer] = useBoolean();
  const [isTopDrawerOpen, openTopDrawer, closeTopDrawer, toggleTopDrawer] = useBoolean();
  const [isBottomDrawerOpen, openBottomDrawer, closeBottomDrawer, toggleBottomDrawer] =
    useBoolean();
  const [
    isTopSearchDrawerOpen,
    openIsTopSearchDrawerOpenDrawer,
    closeIsTopSearchDrawerOpenDrawer,
    toggleIsOpenDrawer
  ] = useBoolean();
  const [
    isHeaderGameDrawerOpen,
    toggleHeaderGameDrawerOpenDrawer,
    openHeaderGameDrawerOpenDrawer,
    closeHeaderGameDrawerOpenDrawer
  ] = useBoolean();
  const [isDesktopSidebarOpen, openDesktopSidebar, closeDesktopSidebar, toggleDesktopSidebar] =
    useBoolean(true);

  const [
    isMobileAppPopupOpened,
    openMobileAppPopup,
    closeMobileAppPopup,
    toggleMobileAppPopup
  ] = useBoolean(false);

  const [isAccountAsideOpen, openAccountAside, closeAccountAside, toggleAccountAside] =
    useBoolean(true);

  const [isAccountSidebarOpen, openAccountSidebar, closeAccountSidebar, toggleAccountSidebar] =
    useBoolean(false);

  const [
    _mainSidebarOpened,
    _openMainSidebar,
    _closeMainSidebar,
    _toggleMainSidebar
  ] = useBoolean(false);

  const [
    _mainAccountSidebarOpened,
    _openMainAccountSidebar,
    _closeMainAccountSidebar,
    _toggleMainAccountSidebar
  ] = useBoolean(false);

  const [
    _accountSidebarOpened,
    _openAccountSidebar,
    _closeAccountSidebar,
    _toggleAccountSidebar
  ] = useBoolean(false);

  const goToAccount = () => {
    _closeMainAccountSidebar?.();
    _closeMainSidebar?.();
    _openAccountSidebar?.();
  };

  useEffect(() => {
    goToAccount();
  }, []);

  return {
    isMobileAppPopupOpened,
    openMobileAppPopup,
    closeMobileAppPopup,
    toggleMobileAppPopup,
    isLeftDrawerOpen,
    toggleLeftDrawer,
    openLeftDrawer,
    closeLeftDrawer,
    isTopDrawerOpen,
    toggleTopDrawer,
    openTopDrawer,
    closeTopDrawer,
    isBottomDrawerOpen,
    toggleBottomDrawer,
    openBottomDrawer,
    closeBottomDrawer,
    isTopSearchDrawerOpen,
    toggleIsOpenDrawer,
    openIsTopSearchDrawerOpenDrawer,
    closeIsTopSearchDrawerOpenDrawer,
    isHeaderGameDrawerOpen,
    toggleHeaderGameDrawerOpenDrawer,
    openHeaderGameDrawerOpenDrawer,
    closeHeaderGameDrawerOpenDrawer,
    isDesktopSidebarOpen,
    closeDesktopSidebar,
    openDesktopSidebar,
    toggleDesktopSidebar,
    isAccountAsideOpen,
    closeAccountAside,
    openAccountAside,
    toggleAccountAside,
    isAccountSidebarOpen,
    closeAccountSidebar,
    openAccountSidebar,
    toggleAccountSidebar,
    _mainSidebarOpened,
    _openMainSidebar,
    _closeMainSidebar,
    _toggleMainSidebar,
    _mainAccountSidebarOpened,
    _openMainAccountSidebar,
    _closeMainAccountSidebar,
    _toggleMainAccountSidebar,
    _accountSidebarOpened,
    _openAccountSidebar,
    _closeAccountSidebar,
    _toggleAccountSidebar,
    goToAccount
  };
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};
