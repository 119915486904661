import { useTranslation } from 'react-i18next';
import { FieldType, useForm, validateMaxLength, validateRequired } from '../../hooks/useForm';
import { Button } from '../Buttons/Button';
import { Input } from '../Inputs/Input';

export const SupportModal = () => {
  const { t } = useTranslation();
  const {
    errors,
    fieldsProps: { emailProps, passwordProps },
    touched,
    isFormValid
  } = useForm<{ email: FieldType.Text; password: FieldType.Text }>({
    email: {
      placeholder: `${t('general.email')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(256)]
    },
    password: {
      placeholder: `${t('general.password')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(15)]
    }
  });

  return (
    <div className="flex flex-col items-center gap-[15px] p-[15px] w-[300px] r_xsm:w-full py-[20px]">
      <p className="text-[24px] text-text_1 font-bold tracking-[0.03em]">
        {t('modalSupport.title')}
      </p>
      <p className="font-medium text-[12px] text-text_1 text-center w-[80%]">
        {t('modalSupport.subtitle')}
      </p>
      <Input {...emailProps} />
      {touched.email && errors.email && <span className="text-[red] text-xs">{errors.email}</span>}
      <Input {...passwordProps} />
      {touched.password && errors.password && (
        <span className="text-[red] text-xs">{errors.password}</span>
      )}
      <Button disabled={!isFormValid} mode="primary" title={`${t('modalSupport.title')}`}>
        {t('modalSupport.title')}
      </Button>
    </div>
  );
};
