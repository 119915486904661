// @ts-nocheck -- Инициализируется глобально в index.html.
/* eslint-disable no-undef -- Инициализируется глобально в index.html. */

import { isDev } from '../api';

const sendGoogleAnalyticsEvent = (name: string, params?: any = '') => {
  if (isDev) {
    console.info(`Google Analytics Event: ${name}`, params);
  } else {
    if (!gtag) {
      return;
    }

    gtag('event', name, params);
  }
};

export { sendGoogleAnalyticsEvent };
