import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { TransactionDetailsInGatewayResponse } from 'src/api/transactions';
import { useGetTransactionDetailsInGateway } from '../../../api/transactions/transactions.hooks';
import { Button } from '../../../components/Buttons/Button';
import { MODAL_NAME, useModal } from '../../../hooks/useModal';
import { TRANSACTION_STATUS } from '../../../types';
import classNames from 'classnames';
import { useWithdrawCancel } from 'src/api/balance';

type Props = {
  item: string | number;
  className?: string;
  status: number;
  type: number;
  id: string;
  transactionId: number;
};

export const StatusCell = ({ item, className, status, type, id, transactionId }: Props) => {
  const { t } = useTranslation();
  const onSuccess = (response: AxiosResponse<TransactionDetailsInGatewayResponse, any>) => {
    if (response.data.formFields) {
      openModal?.(MODAL_NAME.FORM_FIELDS, response.data);
      return;
    }
    if (response.data.checkoutUrl) {
      setTimeout(() => {
        window.open(response.data.checkoutUrl, '_self');
      });
    }
  };
  const { refetch } = useGetTransactionDetailsInGateway(id, onSuccess);
  const { openModal } = useModal();
  const handleWithdrawClick = async () => {
    await refetch();
  };

  const { mutateAsync: withdrawCancel } = useWithdrawCancel();
  const handleWithdrawCancel = () => {
    withdrawCancel(transactionId);
  };

  return (
    <td className={classNames('p-6 text-text_2', className)}>
      <p>{item}</p>
      {TRANSACTION_STATUS[status] === 'Processing' && type === 1 ? (
        <Button
          mode="primary"
          additionalClassName="text-[14px]"
          title={`${t('general.withdraw')}`}
          onClick={handleWithdrawClick}>
          {t('general.withdraw')}
        </Button>
      ) : null}
      {status === 12 && type === 1 ? (
        <Button
          mode="secondary"
          className="text-[14px] text-[black] max-w-[200px] h-30"
          title={`${t('general.cancel')}`}
          onClick={handleWithdrawCancel}>
          {t('general.cancel')}
        </Button>
      ) : null}
    </td>
  );
};
