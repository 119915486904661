import { FC } from 'react';
import './styles.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum GameCardBannerType {
  TOP = 'TOP',
  BONUS = 'BONUS',
  NEW = 'NEW',
  LIVE = 'LIVE'
}

interface GameCardBannerProps {
  type: GameCardBannerType;
}

export const GameCardBanner: FC<GameCardBannerProps> = ({ type }) => {
  const { t } = useTranslation();
  const getBadgeStyle = () => {
    switch (type) {
      case GameCardBannerType.TOP:
        return 'pink-badge';
      case GameCardBannerType.BONUS:
        return 'green-badge';
      case GameCardBannerType.NEW:
        return 'blue-badge';
      case GameCardBannerType.LIVE:
        return 'red-badge';
      default:
        return '';
    }
  };

  const getBadgeContent = () => {
    switch (type) {
      case GameCardBannerType.TOP:
        return 'categoryBadgeGame.top';
      case GameCardBannerType.BONUS:
        return 'categoryBadgeGame.bonus';
      case GameCardBannerType.NEW:
        return 'categoryBadgeGame.new';
      case GameCardBannerType.LIVE:
        return 'categoryBadgeGame.live';
      default:
        return '';
    }
  };

  return (
    <div className={classNames('badge', getBadgeStyle())}>
      {type === GameCardBannerType.LIVE ? <div className="dot" /> : null}
      <p className={classNames(type === GameCardBannerType.LIVE ? 'uppercase ml-1' : '')}>
        {t(getBadgeContent())}
      </p>
    </div>
  );
};
