import classNames from 'classnames';

interface DividerProps {
  height?: string;
  color?: string;
  m?: string;
}

export const Divider = ({
  height = 'border-2',
  color = 'border-border_6',
  m = 'my-[20px]'
}: DividerProps) => {
  return <div className={classNames('w-[100%] border-solid rounded-[5px]', height, color, m)} />;
};
