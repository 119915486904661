import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';
import { Button } from '../Buttons/Button';

export const AttentionModal = () => {
  const { t } = useTranslation();
  const { modalProps, closeModal } = useModal();
  return (
    <div className=" flex flex-col gap-6 p-[24px] w-[300px] r_xsm:w-full">
      <div className="text-2xl font-bold uppercase text-center text-text_8 mt-5">
        {t('modalCancelBonus.attention')}!
      </div>
      <div className="text-lg font-bold text-center text-text_2">{t('modalCancelBonus.text')}</div>
      <div className="flex flex-col gap-7 items-center w-full">
        <div className="flex items-center justify-center w-full">
          <Button
            mode="primary"
            title={`${t('modalCancelBonus.sure')}`}
            label={`${t('modalCancelBonus.sure')}`}
            onClick={() => {
              modalProps?.rejectBonus();
              closeModal?.();
            }}
          />
        </div>
        <div className="flex items-center justify-center w-full mb-6">
          <Button
            title={`${t('modalCancelBonus.save')}`}
            mode="secondary"
            onClick={closeModal}
            label={`${t('modalCancelBonus.save')}`}
          />
        </div>
      </div>
    </div>
  );
};
