import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { calculateSeconds } from '../../utils/timer';

interface SmsTimerProps {
  sendAgainClick?: () => Promise<void>;
}

export const SmsTimer = ({ sendAgainClick }: SmsTimerProps) => {
  const [seconds, setSeconds] = useState(calculateSeconds());
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let interval: number;
    if (seconds > 0) {
      // @ts-ignore
      interval = setTimeout(() => {
        setSeconds(calculateSeconds());
      }, 1000);
    }
    return () => clearTimeout(interval);
  }, [seconds]);

  const isOver = seconds <= 0;

  const handleClick = useCallback(async () => {
    if (isOver && sendAgainClick && !isDisabled) {
      setIsDisabled(true);
      await sendAgainClick();
      setIsDisabled(false);
      setSeconds(calculateSeconds());
    }
  }, [sendAgainClick, isOver, setIsDisabled, isDisabled]);

  return (
    <div className="flex justify-between py-[4px]">
      <div
        className={classNames(
          'text-text_2 select-none',
          !isOver || isDisabled ? ' cursor-not-allowed opacity-40' : 'cursor-pointer',
        )}
        onClick={handleClick}
      >
        Send the code again
      </div>
      <span className="text-text_2">00:{String(seconds > 0 ? seconds : 0).padStart(2, '0')}</span>
    </div>
  );
};
