import { FC } from 'react';
import logo from 'src/assets/images/bigLogoWithDescription.png';

import './styles.css';

export const VideoPreloader: FC = () => (
  <div className="h-screen w-screen bg-background_2 flex flex-col justify-center items-center">
    <img
      alt="logo"
      className="max-w-[400px] w-[90%]"
      src={logo}
    />
    <div className="loader" />
  </div>
);
