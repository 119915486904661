import { CONTENT_URL } from 'src/api';
import { ShortBonus } from '../../api/bonuses/bonuses.types';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { Responsive } from '../Responsive';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { useApi } from 'src/hooks/useApi';
import { Link } from 'react-router-dom';

export const BonusCard = ({ bonus }: { bonus: ShortBonus }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApi();

  const { openModal } = useModal();
  return (
    <>
      <Responsive at={['lg', 'xl']}>
        <div
          className="relative flex items-center h-[269px] max-w-[1020px] w-full justify-start rounded-[20px] red-shadow px-[80px]"
          style={{
            backgroundImage: `url(${CONTENT_URL}${bonus.imageBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
          }}>
          <div className="flex gap-2.5 flex-col max-w-[550px] justify-center h-full z-10">
            <div
              className="font-black text-text_2 text-3xl break-words"
              dangerouslySetInnerHTML={{ __html: bonus.name }}></div>
            <div
              className="text-text_2 text-3xl short-text"
              dangerouslySetInnerHTML={{ __html: bonus.shortDescription }}></div>
            <div className="flex items-center justify-start gap-7 r_laptop:gap-3 mt-4 r_sm:flex-wrap r_sm:gap-2">
              <Link
                to={`/bonuses/${bonus.id}`}
                className="max-w-[190px] w-full"
                state={{ id: bonus.id }}
                aria-label={`${t('general.more')}`}>
                <Button
                  title={`${t('general.more')}`}
                  mode="secondary"
                  className="max-w-[190px] w-full font-bold"
                  label={`${t('general.more')}`}
                />
              </Link>
              <Button
                mode="primary"
                className="max-w-[190px] w-full"
                title={`${t('general.deposit')}`}
                label={`${t('general.deposit')}`}
                onClick={() => {
                  isAuthenticated
                    ? openModal?.(MODAL_NAME.POPUP_DEPOSIT)
                    : openModal?.(MODAL_NAME.SIGNUP);
                }}
              />
            </div>
          </div>
        </div>
      </Responsive>
      <Responsive at={['sm', 'xs', 'md']}>
        <div className="w-full flex flex-col justify-center items-center gap-5 mb-[80px]">
          <div className="rounded-[10px] min-h-[130px] red-shadow relative overflow-y-hidden w-full">
            <img
              src={`${CONTENT_URL}${bonus.imageBanner}`}
              alt="bonus-image"
              className="rounded-[10px] absolute top-0 right-0 min-h-[130px]"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full z-10">
            <div
              className="font-bold text-text_2 text-xl break-words text-left w-full"
              dangerouslySetInnerHTML={{ __html: bonus.name }}></div>
            <div
              className="font-normal text-text_2 text-xl break-words w-full text-left short-text"
              dangerouslySetInnerHTML={{ __html: bonus.shortDescription }}></div>
          </div>
          <div className='flex justify-center items-center w-full gap-[30px]'>
          <Link
              to={`/bonuses/${bonus.id}`}
              state={{ id: bonus.id }}
              className="w-auto min-w-[140px]">
              <Button
                mode="secondary"
                className="w-full font-bold px-3"
                label={`${t('general.more')}`}
                title={`${t('general.more')}`}
              />
            </Link>
            <Button
              mode="primary"
              className="w-auto px-3 min-w-[140px]"
              title={`${t('general.deposit')}`}
              label={`${t('general.deposit')}`}
              onClick={() => {
                isAuthenticated
                  ? openModal?.(MODAL_NAME.POPUP_DEPOSIT)
                  : openModal?.(MODAL_NAME.SIGNUP);
              }}
            />
          </div>
        </div>
      </Responsive>
    </>
  );
};
