import { useEffect } from 'react';
import { useGetFullInfo } from 'src/api/profile';
import { useApi } from './useApi';

export const useChangeEmailInLiveChat = () => {
  const { isAuthenticated } = useApi();
  const { fullInfo } = useGetFullInfo({});

  useEffect(() => {
    if (isAuthenticated) {
      // @ts-ignore
      window.LiveChatWidget?.call?.('set_customer_email', `${fullInfo?.email}`);
    } else {
      // @ts-ignore
      window.LiveChatWidget?.call?.('set_customer_email', ' ');
    }
  }, [fullInfo?.email, isAuthenticated]);
};
