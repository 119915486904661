import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Child } from 'src/api/content';
import { useGetProviderTournaments, useGetTournaments } from 'src/api/tournaments/tournaments.hooks';
import { PageTitle } from 'src/components';
import { Responsive } from 'src/components/Responsive';
import { Skeleton } from 'src/components/Skeleton';
import { TournamentPreview, TournamentPreviewCompleted } from 'src/components/TournamentPreview';
import { TournamentsCard, TournamentsCardCompleted } from 'src/components/TournamentsCard';

import { TournamentsActive } from '../../api/tournaments';

const matpTournamentToChild = (tournament: TournamentsActive): Child => ({
  amount: 0,
  code: tournament.id?.toString(),
  content: tournament.descriptionShort,
  createdAt: tournament.dateStart,
  dateEnd: new Date(tournament.dateEnd * 1000),
  dateStart: new Date(tournament.dateStart * 1000),
  description: tournament.description,
  image: tournament.image,
  imageMobile: tournament.image,
  members: tournament.members,
  places: tournament.places,
  title: tournament.name,
});

export const Tournaments = () => {
  const { t } = useTranslation();
  const { isLoading, tournaments: providerTournaments } = useGetProviderTournaments({});
  const localTournaments = useGetTournaments({});
  const activeTournaments: Child[] | undefined = useMemo(
    () => {
      return localTournaments?.data.active.map((tournament) => {
        return matpTournamentToChild(tournament);
      });
    },
    localTournaments?.data.active,
  );

  const completedTournaments: Child[] | undefined = useMemo(
    () => {
      return localTournaments?.data.completed.map((tournament) => {
        return matpTournamentToChild(tournament);
      });
    },
    localTournaments?.data.completed,
  );

  return (
    <div className="w-full">
      <PageTitle title={t('tournaments.title')} />
      <div className="p-2.5">
        {isLoading ? (
          <Skeleton rows={6} />
        ) : (
          <>
            <div className="flex flex-col gap-16 self-center items-center r_sm:gap-5">
              <Responsive at={['lg', 'md', 'xl']}>
                {activeTournaments?.map((tournament) => (
                  <TournamentPreview
                    content={tournament}
                    key={tournament.code}
                    type="local"
                  />
                ))}
                {providerTournaments.map((tournament) => (
                  <TournamentPreview
                    content={tournament as unknown as Child}
                    key={tournament.code}
                    type="provider"
                  />
                ))}
              </Responsive>
              <Responsive at={['xs', 'gf', 'sm']}>
                {activeTournaments?.map((tournament) => (
                  <TournamentsCard
                    content={tournament}
                    key={tournament.code}
                    type="local"
                  />
                ))}
                {providerTournaments.map((tournament) => (
                  <TournamentsCard
                    content={tournament as unknown as Child}
                    key={tournament.code}
                    type="provider"
                  />
                ))}
              </Responsive>
            </div>
            {(completedTournaments?.length ?? 0) > 0 && (
              <div className="text-white text-[24px] md:text-[48px] font-bold md:uppercase text-text_2 w-full text-center py-[80px]">
                Completed tournaments
              </div>
            )}
            <div className="flex flex-col gap-16 self-center items-center r_sm:gap-5">
              <Responsive at={['lg', 'md', 'xl']}>
                {completedTournaments &&
                  completedTournaments.map((tournament) => (
                    <TournamentPreviewCompleted
                      content={tournament as Child}
                      key={tournament.code}
                      type="local"
                    />
                  ))}
              </Responsive>
              <Responsive at={['xs', 'gf', 'sm']}>
                {completedTournaments &&
                  completedTournaments.map((tournament) => (
                    <TournamentsCardCompleted
                      content={tournament as Child}
                      key={tournament.code}
                      type="local"
                    />
                  ))}
              </Responsive>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
