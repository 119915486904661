import { FC } from 'react';
import { Spin } from '../svg';
import './styles.css';
import { ButtonProps } from './Button.types';
import classNames from 'classnames';

export const Button: FC<ButtonProps> = ({
  mode = 'primary',
  additionalClassName,
  disabled,
  label,
  children,
  onClick,
  loading,
  icon,
  leftIcon,
  classNameSvg,
  buttonRef,
  text = 'uppercase',
  ...restProps
}) => {
  return (
    <button
      {...restProps}
      ref={buttonRef}
      className={classNames('btn', text, mode, additionalClassName)}
      disabled={disabled}
      onClick={onClick}>
      {leftIcon}
      {loading && <Spin classNameSvg={classNameSvg} />}
      {children ? children : label}
      {icon}
    </button>
  );
};
