import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import { HellocleverResponse } from 'src/types';
import { Input } from '../Inputs/Input';
import { useEffect, useMemo, useState } from 'react';
import { getCurrencyIcon, openChat } from 'src/utils';
import { Tooltip } from 'react-tooltip';
import { Copy } from '../svg/Copy';

type HellocleverResultModalProps = HellocleverResponse & {
  paymentMethodName?: string;
};

export const HellocleverResultModal = () => {
  const { t } = useTranslation();
  const { modalProps } = useModal<HellocleverResultModalProps>();
  const [isCopiedText, setIsCopiedText] = useState(false);
  const [isCopiedCode, setIsCopiedCode] = useState(false);

  const currencySing = useMemo(() => {
    return modalProps?.currency ? getCurrencyIcon(modalProps?.currency) : '';
  }, [modalProps?.currency]);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(modalProps?.nonce ? `${modalProps?.nonce}` : '');
    setIsCopiedCode(true);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(modalProps?.account ? modalProps?.account : '');
    setIsCopiedText(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      isCopiedText && setIsCopiedText(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isCopiedText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      isCopiedCode && setIsCopiedCode(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isCopiedCode]);

  return (
    <div className="flex flex-col items-center gap-5 px-5 py-10 max-w-[400px] w-full jmid:w-full h-full">
      <p className="text-text_12 font-bold text-xl text-center whitespace-normal">
        {t('helloCleverResult.topUp')} {modalProps?.paymentMethodName}{' '}
        {t('helloCleverResult.playMore')}
      </p>
      <h1 className="font-bold text-3xl text-center text-text_8 whitespace-normal">
        {currencySing} {Number(modalProps?.amount).toFixed(2)}
      </h1>
      <p className="w-full font-bold text-base text-text_2">
        {t('helloCleverResult.send')} {currencySing}
        {Number(modalProps?.amount).toFixed(2)} {t('helloCleverResult.toThe')}{' '}
        {modalProps?.paymentMethodName} {t('helloCleverResult.shownBelowToComplete')}
      </p>
      <div className="flex flex-row w-full relative cursor-pointer" onClick={handleCopyText}>
        <Input
          id="account-input"
          type="text"
          value={modalProps?.account}
          readOnly
          rightIcon={<Copy />}
        />
        <Tooltip
          anchorSelect="#account-input"
          className="bg-background_6 text-text_2 rounded-4xl"
          place="top"
          isOpen={isCopiedText}>
          <div>{t('helloCleverResult.copied')}</div>
        </Tooltip>
      </div>
      <p className="font-medium text-[15px] leading-5 text-text_2">
        <p>{t('helloCleverResult.nonceText')}</p>
      </p>
      <p className="font-medium text-[15px] leading-5 text-text_2">
        <p>{t('helloCleverResult.nonceAlert')}</p>
      </p>
      <div className="w-full" onClick={handleCopyCode}>
        <Input
          id="nonce-input"
          type="text"
          value={modalProps?.nonce}
          readOnly
          rightIcon={<Copy />}
          className="!cursor-pointer max-w-[150px]"
        />
        <Tooltip
          anchorSelect="#nonce-input"
          className="bg-background_6 text-text_2 rounded-4xl"
          place="top"
          isOpen={isCopiedCode}>
          <div>{t('helloCleverResult.copied')}</div>
        </Tooltip>
      </div>
      <p className="font-medium text-[15px] leading-5 text-text_2">
        <Trans
          i18nKey="helloCleverResult.modalInfoGeneral"
          components={{
            '1': (
              <span className="text-text_8 underline cursor-pointer" onClick={() => openChat?.()} />
            )
          }}
        />
      </p>
      <ul className="text-text_6 font-medium text-[15px] leading-5  pb-10">
        <li className="list-disc ml-4">{t('helloCleverResult.modalInfoFirst')}</li>
        <li className="list-disc ml-4">{t('helloCleverResult.modalInfoSecond')}</li>
        <li className="list-disc ml-4">{t('helloCleverResult.modalInfoThird')}</li>
      </ul>
    </div>
  );
};
