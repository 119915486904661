import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { useGetShortBalance } from '../balance';
import {
  activeUserTournaments,
  applyTournament,
  providerTournaments,
  singleProviderTournament,
  singleTournament,
  tournaments,
  tournamentWithUsersProgress,
  userTournamentProgress,
} from './tournaments.requests';
import {
  ProviderTournamentsResponse,
  SingleProviderTournament,
  SingleProviderTournamentBody,
  SingleTournamentBody,
  TournamentsBody,
  TournamentsProviderBody,
  TournamentsResponse,
} from './tournaments.types';

export function useGetTournaments(params: TournamentsBody): TournamentsResponse | undefined {
  const { data: shortBalance } = useGetShortBalance();

  const { data: response } = useQuery<AxiosResponse<TournamentsResponse>, AxiosError>(
    ['tournaments', shortBalance?.currency],
    () => tournaments({ ...params, currency: shortBalance?.currency }),
  );

  return response?.data ?? undefined;
}

export function useGetProviderTournaments(params: TournamentsProviderBody) {
  const { data: shortBalance } = useGetShortBalance();
  const { i18n } = useTranslation();

  const { data: response, ...query } = useQuery<AxiosResponse<ProviderTournamentsResponse>, AxiosError>(
    ['providerTournaments', i18n.language, shortBalance?.currency],
    () => providerTournaments({ ...params, currency: shortBalance?.currency, language: i18n.language }),
  );
  return {
    tournaments: response?.data || [],
    ...query,
  };
}

export function useGetSingleProviderTournament(params: SingleProviderTournamentBody) {
  const { data: shortBalance } = useGetShortBalance();
  const { i18n } = useTranslation();

  const { data: response, ...query } = useQuery<AxiosResponse<SingleProviderTournament>, AxiosError>(
    ['singleProviderTournaments', shortBalance?.currency, i18n.language],
    () =>
      singleProviderTournament({
        ...params,
        currency: shortBalance?.currency,
        language: i18n.language,
      }),
  );

  return {
    tournament: response?.data,
    tournamentGamesCount: response?.data?.games?.length || 0,
    ...query,
  };
}

export function useGetSingleTournament(id: number, params: SingleTournamentBody) {
  return useQuery(['single-tournament'], () => singleTournament(id, params));
}

export function useGetTournamentWithUsersProgress() {
  return useQuery(['tournament-with-users-progress'], () => tournamentWithUsersProgress);
}

export function useGetActiveUserTournaments() {
  return useQuery(['active-user-tournaments'], () => activeUserTournaments);
}

export function useGetUserTournamentProgress() {
  return useQuery(['user-tournament-progress'], () => userTournamentProgress);
}

export function useApplyTournament() {
  const mutation = useMutation({
    mutationFn: applyTournament,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
  });

  return mutation;
}
