import {
  Apple,
  Discord,
  Facebook,
  Google,
  Linkedin,
  Telegram,
  Twitter,
  Youtube,
} from '../../components/svg/SocialMedia';
import img_jackpot_1 from './assets/images/jackpot/1.png';
import img_jackpot_10 from './assets/images/jackpot/10.png';
import img_jackpot_2 from './assets/images/jackpot/2.png';
import img_jackpot_3 from './assets/images/jackpot/3.png';
import img_jackpot_4 from './assets/images/jackpot/4.png';
import img_jackpot_5 from './assets/images/jackpot/5.png';
import img_jackpot_6 from './assets/images/jackpot/6.png';
import img_jackpot_7 from './assets/images/jackpot/7.png';
import img_jackpot_8 from './assets/images/jackpot/8.png';
import img_jackpot_9 from './assets/images/jackpot/9.png';
import interkassa from './assets/images/row-1/interkassa.webp';
import jetoncash from './assets/images/row-1/jetoncash.webp';
import logo_Neosurf from './assets/images/row-1/logo_Neosurf.png';
import logo_abn_amro from './assets/images/row-1/logo-abn-amro.svg';
import logo_asn from './assets/images/row-1/logo-asn.svg';
import logo_sns from './assets/images/row-1/logo-sns.svg';
import logo from './assets/images/row-1/logo.hd.png';
import mifinity from './assets/images/row-1/mifinity.webp';
import nodapay_NLD from './assets/images/row-1/nodapay_NLD.webp';
import payop from './assets/images/row-1/payop.webp';
import rabobank from './assets/images/row-1/rabobank.png';
import revolut from './assets/images/row-1/revolut.webp';
import volt from './assets/images/row-1/volt.webp';
import r2_63f4c9689497e0d7c32f4a31_BGaming_logo from './assets/images/row-2/63f4c9689497e0d7c32f4a31_BGaming_logo.svg';
import evolution_logo from './assets/images/row-2/evolution_logo.svg';
import js_elk_provider from './assets/images/row-2/js-elk-provider.webp';
import r2_logo from './assets/images/row-2/logo.svg';
import NETENT_LOGO2015_ALL_WHITE from './assets/images/row-2/NETENT_LOGO2015_ALL-WHITE.svg';
import playngo_logo_on_black from './assets/images/row-2/playngo_logo_on_black.webp';
import pragmatic from './assets/images/row-2/pragmatic.svg';
import pragmaticplay from './assets/images/row-2/pragmaticplay.svg';
import spinomenal_logo from './assets/images/row-2/spinomenal_logo.png';
import askgamblers from './assets/images/row-3/askgamblers-logo-vector.svg';
import bigwinboard from './assets/images/row-3/bigwinboard.png';
import bojoko from './assets/images/row-3/bojoko.svg';
import casinoguru_logo_1 from './assets/images/row-3/casinoguru_logo.svg';
import casinos_analyzer from './assets/images/row-3/casinos-analyzer.png';
import chipy_com_logo from './assets/images/row-3/chipy-com-logo.png';
import clash_of_slots from './assets/images/row-3/clash-of-slots.svg';
import correct_casino_logo from './assets/images/row-3/correct-casino-logo.svg';
import lcb from './assets/images/row-3/lcb.png';
import mr_gamble from './assets/images/row-3/mr-gamble.svg';
import play_casino_logo from './assets/images/row-3/play-casino-logo.svg';
import slot_catalog_logo from './assets/images/row-3/slot-catalog-logo.png';
import vegas_slots_online_logo from './assets/images/row-3/vegas-slots-online-logo.svg';

export const bottomMenu = [
  { href: 'https://www.megapartners.io/', name: 'Affiliates' },
  { href: '/faq', name: 'FAQ' },
  { href: '/info_pages/terms_and_conditions', name: 'Terms And Conditions' },
  { href: '/info_pages/bonus_terms_and_conditions', name: 'Bonus Terms And Conditions' },
  { href: '/info_pages/privacy_policy', name: 'Privacy Policy' },
  // { href: '#', name: 'Game Rules' },
  { href: '/info_pages/responsible_gaming', name: 'Responsible Gaming' },
  // { href: '#', name: 'Complaints' },
  // { href: '#', name: 'Support' },
  { href: '/info_pages/about_betsomnia', name: 'About Us' },
];

export const socialMedia = [
  { href: 'https://t.me/+2yXRUojKalRmNmJi', image: <Telegram />, label: 'Telegram' },
  { href: '#', image: <Facebook />, label: 'Facebook' },
  { href: '#', image: <Twitter />, label: 'Twitter' },
  { href: '#', image: <Youtube />, label: 'YouTube' },
  { href: '#', image: <Linkedin />, label: 'LinkedIn' },
  { href: '#', image: <Apple />, label: 'Apple' },
  { href: '#', image: <Discord />, label: 'Discord' },
  { href: '#', image: <Google />, label: 'Google' },
];

export const row1Images = [
  logo,
  logo_Neosurf,
  rabobank,
  logo_abn_amro,
  logo_asn,
  logo_sns,
  interkassa,
  jetoncash,
  mifinity,
  nodapay_NLD,
  payop,
  revolut,
  volt,
];

export const row2Images = [
  pragmaticplay,
  spinomenal_logo,
  r2_63f4c9689497e0d7c32f4a31_BGaming_logo,
  evolution_logo,
  r2_logo,
  NETENT_LOGO2015_ALL_WHITE,
  pragmatic,
  js_elk_provider,
  playngo_logo_on_black,
];

export const partners = [
  {
    image: askgamblers,
    link: 'https://www.askgamblers.com/',
  },
  {
    image: bigwinboard,
    link: 'https://www.bigwinboard.com/',
  },
  {
    image: bojoko,
    link: 'https://bojoko.com/',
  },
  {
    image: casinoguru_logo_1,
    link: 'https://casino.guru/',
  },
  {
    image: casinos_analyzer,
    link: 'https://casinosanalyzer.com/',
  },
  {
    image: chipy_com_logo,
    link: 'https://chipy.com/',
  },
  {
    image: clash_of_slots,
    link: 'https://clashofslots.com/',
  },
  {
    image: correct_casino_logo,
    link: 'https://www.correctcasinos.com/',
  },
  {
    image: lcb,
    link: 'https://lcb.org/',
  },
  {
    image: mr_gamble,
    link: 'https://mr-gamble.com/en/',
  },
  {
    image: play_casino_logo,
    link: 'https://www.playcasino.com/',
  },
  {
    image: slot_catalog_logo,
    link: 'https://slotcatalog.com/',
  },
  {
    image: vegas_slots_online_logo,
    link: 'https://www.vegasslotsonline.com/',
  },
];

export const jackpotImages = [
  img_jackpot_1,
  img_jackpot_2,
  img_jackpot_3,
  img_jackpot_4,
  img_jackpot_5,
  img_jackpot_6,
  img_jackpot_7,
  img_jackpot_8,
  img_jackpot_9,
  img_jackpot_10,
];
