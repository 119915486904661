import { Link } from 'react-router-dom';

import { CONTENT_URL } from '../../api';
import { Child } from '../../api/content';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { MainPageWinners } from '../MainPageBanners/MainPageWinners';

interface Props {
  card: Child;
}

export const MainPageSliderCard = ({ card }: Props) => {
  const backgroundImage = CONTENT_URL + card.image;

  const sendEvent = () => {
    sendGoogleAnalyticsEvent('home_offer_opened', {
      home_offer_id: card.code,
    });
  };

  return (
    <div className="swiper-card-shadow relative h-[192px] w-[330px] rounded-lg hover:grayscale-[50%] md:h-[217px] md:w-[370px]">
      {card.code === 'recent_winners' && (
        <div
          className="relative h-[192px] w-[330px] rounded-lg bg-[100%_100%] bg-no-repeat px-[20px] pt-[20px] md:h-[217px] md:w-[370px]"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <MainPageWinners />
          <div className="absolute top-0 left-0 h-full w-full" />
        </div>
      )}

      {card.code !== 'recent_winners' && (
        <Link
          className="flex h-[192px] w-[330px] cursor-pointer items-center justify-center rounded-lg md:h-[217px] md:w-[370px]"
          onClick={sendEvent}
          to={card.title}
        >
          <img
            alt="image"
            className="rounded-lg"
            src={backgroundImage}
          />
        </Link>
      )}
    </div>
  );
};
