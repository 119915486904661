import classNames from 'classnames';
import { getRoundNumbers } from 'src/utils/getRoundNumbers';

export function AmountPresets({
  amountPresets = [],
  onClick,
  value = '0'
}: {
  amountPresets?: number[];
  onClick?: (value: number) => void;
  value?: string;
}) {
  return (
    <>
      <div className="flex justify-start gap-2">
        {amountPresets.map((preset) => {
          const count = getRoundNumbers(+preset);
          const isSelected = value.toString() === preset.toString();

          return (
            <div
              className={classNames(
                isSelected ? 'bg-orange-gradient' : 'bg-background_15',
                'rounded-[20px] flex-1 lg:flex-none lg:w-[100px] p-[1px] h-full flex justify-center '
              )}
              key={preset}>
              <button
                className={classNames(
                  'rounded-[20px] py-[7px] w-full text-[16px] font-medium border-border_6 bg-background_2'
                )}
                onClick={() => onClick?.(preset)}
                title={`${count}`}>
                <span
                  className={classNames(
                    isSelected ? 'bg-orange-gradient' : 'bg-background_15',
                    'gradient-text'
                  )}>
                  {count}
                </span>
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
}
