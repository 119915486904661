import axios from 'axios';
import { FormEvent } from 'react';
import { DepositTransaction } from 'src/api/deposit';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { HellocleverResponse } from 'src/types';

const HELLOCLEVER_PAYMENT_METHOD = 'holypayment';

export function useProcessHelloclever() {
  const { openModal } = useModal();
  return async ({
    event,
    transaction,
    checkoutUrl,
    paymentMethodName
  }: {
    event: FormEvent<HTMLFormElement>;
    transaction?: DepositTransaction;
    checkoutUrl?: string;
    paymentMethodName?: string;
  }) => {
    if (transaction?.paymentMethod !== HELLOCLEVER_PAYMENT_METHOD || !checkoutUrl) return;
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    try {
      const response = await axios.post<HellocleverResponse>(checkoutUrl, data);
      openModal?.(MODAL_NAME.DEPOSIT_HELLOCLEVER_RESULT, { ...response.data, paymentMethodName });
      // redicrect ?
    } catch (error) {
      console.error(error);
      const failUrl = data.get('fail_url');
      if (failUrl) {
        window.open(failUrl as string);
      }
    }
  };
}
