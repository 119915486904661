import { useTranslation } from 'react-i18next';
import { Multiselect } from 'src/components';
import { InputDate } from 'src/components/Inputs/InputDate';
import { Button } from 'src/components/Buttons/Button';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { Input } from 'src/components/Inputs/Input';
import {
  DateFieldProps,
  DropdownFieldProps,
  MultiselectFieldProps,
  TextFieldProps
} from 'src/hooks/useForm/index';

type Props = {
  resetForm: () => void;
  dateFromProps: DateFieldProps;
  dateToProps: DateFieldProps;
  amountFromProps: TextFieldProps;
  amountToProps: TextFieldProps;
  sortByProps: DropdownFieldProps;
  typeProps: MultiselectFieldProps;
  refetch: () => void;
};

export const HistoryForm = ({
  dateFromProps,
  dateToProps,
  amountFromProps,
  amountToProps,
  sortByProps,
  typeProps,
  resetForm,
  refetch
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-7">
      <div className="grid grid-cols-2 gap-y-[27px] gap-x-[20px]">
        <div className="flex flex-col">
          <InputDate
            {...dateFromProps}
            label={`${t('transactionTable.dateFrom')}`}
            labelWithPadding
          />
        </div>
        <div className="flex flex-col">
          <InputDate {...dateToProps} label={`${t('transactionTable.dateTo')}`} labelWithPadding />
        </div>
        <div className="flex flex-col">
          <Input
            {...amountFromProps}
            label={`${t('transactionTable.amountFrom')}`}
            labelWithPadding
          />
        </div>
        <div className="flex flex-col">
          <Input {...amountToProps} label={`${t('transactionTable.amountTo')}`} labelWithPadding />
        </div>
        <div className="flex flex-col">
          <Dropdown {...sortByProps} label={`${t('transactionTable.sortBy')}`} labelWithPadding />
        </div>
        <div className="flex flex-col">
          <Multiselect {...typeProps} label={`${t('transactionTable.type')}`} labelWithPadding />
        </div>
        <Button mode="secondary" onClick={resetForm} title={`${t('transactionTable.clear')}`}>
          {t('transactionTable.clear')}
        </Button>
        <Button mode="primary" onClick={refetch} title={`${t('transactionTable.search')}`}>
          {t('transactionTable.search')}
        </Button>
      </div>
    </div>
  );
};
