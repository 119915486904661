import classNames from 'classnames';
import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetShortBalance } from 'src/api/balance';
import { useGetAllProviders } from 'src/api/content/content.hooks';
import noProviders from 'src/assets/images/noProviders.webp';
import { useClickOutside } from 'src/hooks/useClickOutside';

import { Provider } from '../../api/content';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { InputSearch } from '../Inputs/InputSearch';
import { InputWithButton } from '../InputWithButton/InputWithButton';
import { ProviderButton } from '../ProviderButton/ProviderButton';
import { Arrow, SearchIcon, SearchIconThin, Spin } from '../svg';

interface InputWithButtonProps {
  onChange?: (value: string) => void;
  value?: string;
}

export const GamesAndProvidersSearch = ({ onChange, value }: InputWithButtonProps) => {
  const [gamesSearchValue, setGamesSearchValue] = useState(value || '');
  const [providersSearchValue, setProvidersSearchValue] = useState<string>('');
  const [showProviders, setShowProviders] = useState(false);
  const providerSearchRef = useRef<HTMLDivElement | null>(null);
  const providersToggle = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (gamesSearchValue && params.categoryId) {
      navigate(`/games/${params.categoryId}/search`);
    }

    if (!gamesSearchValue && params.search) {
      navigate(`/games/${params.categoryId}`);
    }
  }, [gamesSearchValue, params.categoryId, params.search]);

  const { t } = useTranslation();
  const { data } = useGetShortBalance();
  const { allProviders, isFetched, isFetching } = useGetAllProviders(
    {
      currency: data?.currency || 'EUR',
      per_page: 9000,
    },
    showProviders,
  );

  useEffect(() => {
    if (value !== undefined) {
      setGamesSearchValue(value);
    }
  }, [value]);

  const handleGameSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setGamesSearchValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleChangeProviders = useCallback((value: string) => {
    setProvidersSearchValue(value);
  }, []);

  const handleProviderSwitchClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (!showProviders) {
      sendGoogleAnalyticsEvent('providers_list_opened');
    }

    setShowProviders((showProviders) => !showProviders);
    setProvidersSearchValue('');
  };

  const handleProviderSelect = (provider: Provider) => {
    sendGoogleAnalyticsEvent('provider_chosen', {
      provider_id: provider.name,
    });

    setGamesSearchValue('');
    onChange?.('');
  };

  const providers = useMemo(
    () => allProviders.filter((provider) => provider.name.toLowerCase().includes(providersSearchValue.toLowerCase())),
    [providersSearchValue, allProviders],
  );

  useClickOutside(
    providerSearchRef,
    () => {
      setShowProviders(false);
    },
    providersToggle,
  );

  return (
    <div className={classNames('relative flex flex-col')}>
      <div className="relative">
        <InputWithButton
          buttonProps={{
            buttonRef: providersToggle,
            children: (
              <>
                <p>{t('general.providerList')}</p>
                <Arrow
                  className={classNames(
                    'ml-5 w-[20px] stroke-[3px] transition-transform',
                    showProviders ? 'rotate-180' : '',
                  )}
                />
              </>
            ),
            onClick: handleProviderSwitchClick,
            title: `${t('general.providerList')}`,
          }}
          inputProps={{
            className: 'min-h-[30px]',
            leftIcon: <SearchIconThin classNameIcon="pr-[8px]" />,
            onChange: handleGameSearchChange,
            placeholder: t('general.placeholderSearchGames') as string,
            value: gamesSearchValue,
          }}
        />
      </div>
      {showProviders ? (
        <div
          className="red-shadow absolute -bottom-3 z-[21] flex h-[400px] w-full translate-y-[100%] flex-col items-center rounded-2xl bg-background_1 py-2.5 pb-[30px]"
          ref={providerSearchRef}
        >
          <div className="mt-[8px] w-[90%]">
            <InputSearch
              className="flex h-auto w-full cursor-pointer flex-row items-center justify-around border-b-2 border-b-border_6 bg-[transparent] py-[5px]"
              classNameInput="search-input text-text_2 font-medium text-[16px] bg-[transparent] w-full px-2.5 h-[35px]"
              leftIcon={<SearchIcon />}
              onChange={handleChangeProviders}
              placeholder={`${t('general.searchProvider')}`}
              value={providersSearchValue}
            />
          </div>
          <div
            className={`${
              isFetching && !isFetched
                ? 'flex h-full w-full items-center justify-center'
                : 'grid w-full grid-cols-4 gap-2.5 overflow-y-scroll px-3 pt-5'
            }`}
          >
            {providers?.map((provider) => (
              <div
                className="w-full"
                key={provider.id}
                onClick={() => {
                  setShowProviders((showProviders) => !showProviders);
                }}
              >
                <ProviderButton
                  onClick={() => handleProviderSelect(provider)}
                  provider={provider}
                />
              </div>
            ))}
            {isFetching && (
              <div className="flex h-full w-full items-center justify-center self-center text-center">
                <Spin classNameSvg="w-[30px] h-[30px]" />
              </div>
            )}
            <div className="h-[20px] bg-[transparent]"></div>
          </div>
          {!isFetching && providers?.length === 0 && (
            <div className="flex h-full w-full flex-col items-center justify-start">
              <p className="w-full text-center text-text_2">{t('general.noProvidersFound')}</p>
              <img
                alt="no providers"
                className="max-h-[80%] max-w-[300px]"
                src={noProviders}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
