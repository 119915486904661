import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { STORAGE_URL } from 'src/api';
import { useGetFullInfo } from 'src/api/profile';
import { categoryBadgeGame } from 'src/constants';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';

import { GAMESOURCE, SingleGame } from '../../api/games/games.types';
import BJLeagueLogo from '../../assets/images/bj-league.svg';
import DropsAndWindLogo from '../../assets/images/drops-and-wins.png';
import { useApi } from '../../hooks/useApi';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Button } from '../Buttons/Button';
import { GameCardBanner, GameCardBannerType } from '../GameCardBanner/GameCardBanner';

import './styles.css';

interface GameProps {
  className?: string;
  game: SingleGame;
  isWagering?: boolean;
  navigationState?: string;
  onClick?: () => void;
  showGameInfo?: boolean;
}

export const GameCard: FC<GameProps> = ({ className, game, isWagering, onClick, showGameInfo = true }) => {
  const { t } = useTranslation();
  const { fullInfo } = useGetFullInfo({});
  const navigate = useNavigate();
  const { openMessage } = useMessage();

  const badgeGame = useMemo(() => {
    if (game.categories) {
      for (const badge of game.categories) {
        return categoryBadgeGame[badge.id as keyof typeof categoryBadgeGame];
      }
    }
    return false;
  }, [game.categories]);

  const { openModal } = useModal();

  const location = useLocation();
  const { isAuthenticated } = useApi();

  const navigateToGame = ({ isDemo }: { isDemo: boolean }) => {
    const gameName = game.name.replaceAll(/[[\].,\s!*;&+#:@'()^"$=+\\?/]/g, '');
    const pathname = `/game/${game.id}${isDemo ? '/demo' : ''}?name=${gameName}`;

    sendGoogleAnalyticsEvent('game_opened', {
      page_id: pathname,
    });

    navigate(pathname, {
      state: { history: [location.pathname + location.search] },
    });
  };

  const handlePlayGameClick = () => {
    if (!isAuthenticated) {
      openModal?.(MODAL_NAME.SIGNUP);
      return;
    }
    if (game.providerId === 13) {
      fullInfo?.name && fullInfo.surname && fullInfo.birthday
        ? navigateToGame({ isDemo: false })
        : openMessage?.(t('general.nonFilledProfile'), MESSAGE_STATUS.ERROR);
      return;
    }
    if (game.providerId === 25 || game.providerId === 101) {
      fullInfo?.sex && fullInfo.birthday
        ? navigateToGame({ isDemo: false })
        : openMessage?.(t('general.nonFilledSexAndBirth'), MESSAGE_STATUS.ERROR);
      return;
    }
    if (game.providerId === 27) {
      fullInfo?.birthday
        ? navigateToGame({ isDemo: false })
        : openMessage?.(t('general.nonFilledBirth'), MESSAGE_STATUS.ERROR);
      return;
    }
    onClick?.();
    navigateToGame({ isDemo: false });
  };

  // ! Disable Demo game
  // const handlePlayDemoGameClick = () => {
  //   navigateToGame({ isDemo: true });
  //   onClick?.();
  // };

  if (!game.image) {
    return null;
  }

  const hasDropsAndWinsCategory = useMemo(() => {
    return Boolean(game.categories?.find((category) => category.id === 587));
  }, [game]);

  const hasBJLeagueCategory = useMemo(() => {
    return Boolean(game.categories?.find((category) => category.id === 613));
  }, [game]);

  return (
    <div
      className={classNames(
        'game-card border-game relative z-0 flex aspect-[29/40] h-auto cursor-pointer flex-col justify-end border-l-[1px] border-r-[1px] border-b-[1px] border-solid',
        className,
      )}
    >
      <div
        className={classNames(
          'gameCardImage relative h-full overflow-hidden',
          showGameInfo ? 'rounded-t-[10px]' : 'rounded-[10px]',
        )}
      >
        <img
          alt="game image"
          className={classNames('relative z-[2] h-full', showGameInfo ? 'rounded-t-[10px]' : 'rounded-[10px]')}
          src={`${STORAGE_URL}/storage/games/vertically${game.image}`}
        />
        {hasDropsAndWinsCategory && (
          <img
            alt=""
            className="absolute top-0 right-0 z-10 w-[65px] h-[72px]"
            src={DropsAndWindLogo}
          />
        )}
        {hasBJLeagueCategory && (
          <img
            alt=""
            className="absolute top-0 left-0 z-10 w-[55px] h-[52px]"
            src={BJLeagueLogo}
          />
        )}
      </div>
      <div className="absolute top-[10px] left-2 flex flex-wrap items-center justify-start gap-2">
        {badgeGame && <GameCardBanner type={badgeGame.type} />}
        {(game.isWagering || isWagering) && <GameCardBanner type={GameCardBannerType.BONUS} />}
      </div>
      {game.source === GAMESOURCE.CasinoLive ? (
        <div className="absolute bottom-[60px] left-2 flex items-center justify-center">
          <GameCardBanner type={GameCardBannerType.LIVE} />
        </div>
      ) : (
        ''
      )}
      {showGameInfo ? (
        <div className="flex flex-col rounded-b-[10px] bg-background_2 p-2 text-center">
          <p className="truncate text-sm font-bold text-text_2">{game.name}</p>
          <p className="truncate text-xs font-bold text-text_8">{game.providerName}</p>
        </div>
      ) : null}
      <div className="controls absolute top-0 left-0 z-[3] flex h-full w-full flex-col items-center justify-center gap-3 rounded-lg bg-background_14 px-2.5 shadow-game transition-[0.5s]">
        <Button
          className="h-auto w-full p-1.5"
          mode="primary"
          onClick={handlePlayGameClick}
          title={`${t('general.play')}`}
        >
          {t('general.play')}
        </Button>
        {/* ! Disable Demo game */}
        {/* {game.hasDemo && (
          <Button
            onClick={handlePlayDemoGameClick}
            title={`${t('general.playDemo')}`}
            mode="secondary"
            className="w-full h-auto flex items-center justify-center font-bold p-1.5">
            {t('general.playDemo')}
          </Button>
        )} */}
      </div>
    </div>
  );
};
