import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllWinners, WinnerResponse } from 'src/api/content';

import { TwitchContext } from '../../hooks/useTwitch';
import { CustomMarquee } from '../CustomMarquee/CustomMarquee';
import { RecentWinersCard } from '../MainPageBanner/RecentWinnersCard';

export const MainPageWinners = () => {
  const { allWinners } = useGetAllWinners();
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col justify-between items-center">
      <p className="text-[20px] font-bold text-text_2 w-full text-left uppercase">{t('general.recentWiners')}</p>
      <div className="overflow-y-auto flex flex-col flex-1 gap-2 h-full winners-list">
        <CustomMarquee>
          {allWinners.map((winner: WinnerResponse, index) => {
            return (
              <RecentWinersCard
                key={index}
                roundedImage
                winner={winner}
              />
            );
          })}
        </CustomMarquee>
      </div>
    </div>
  );
};

export const TwitchPlayer = () => {
  const { setIsOnline } = useContext(TwitchContext);
  const embedRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (embedRef.current) {
        // @ts-ignore
        playerRef.current = new window.Twitch.Embed(embedRef.current, {
          autoplay: true,
          channel: 'vegito050',
          height: '210',
          layout: 'video',
          muted: false,
          parent: ['localhost', 'betsomnia.com'],
          width: '100%',
        });
        // @ts-ignore
        playerRef.current.addEventListener(window.Twitch.Embed.ONLINE, () => {
          if (setIsOnline) {
            setIsOnline(true);
          }
        });
        // @ts-ignore
        playerRef.current.addEventListener(window.Twitch.Embed.OFFLINE, () => {
          if (setIsOnline) {
            setIsOnline(false);
          }
        });
        // @ts-ignore
        playerRef.current.addEventListener(window.Twitch.Embed.ENDED, () => {
          if (setIsOnline) {
            setIsOnline(false);
          }
        });
      }
    }, 2000);
  }, [embedRef.current]);

  return (
    <div
      className="rounded-lg overflow-hidden"
      id="twitch-embed"
      ref={embedRef}
    ></div>
  );
};
