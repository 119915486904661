import classNames from 'classnames';
import { NewCarouselProps } from './NewCarousel.types';
import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

export const NewCarousel: FC<NewCarouselProps> = ({
  className,
  classNameForSlide,
  slides,
  ...rest
}) => {
  return (
    <Swiper className={classNames(className)} {...rest}>
      {slides?.map((slide, index) => (
        <SwiperSlide className={classNames(classNameForSlide)} key={index}>
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
