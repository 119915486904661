import { ReactNode, useMemo } from 'react';
import { MESSAGE_STATUS, useMessage } from '../../hooks/useMessage';
import { MessageDefault, MessageError, MessageSuccess } from '../svg';

interface MessageProps {
  id: string;
  text: string | ReactNode;
  status: MESSAGE_STATUS;
  closed: boolean;
}

export const Message = ({ id, text, status }: MessageProps) => {
  const { closeMessage } = useMessage();

  const timeout = useMemo(() => (id ? setTimeout(closeMessage!, 2000, id) : null), [id]);

  const handleDeleteMessage = (messageId: string) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    closeMessage?.(messageId);
  };

  const messageToShow = {
    info: <MessageDefault />,
    error: <MessageError />,
    success: <MessageSuccess />
  };

  return (
    <div
      className="bg-background_15 flex items-center self-center z-[101] transition-all top-[20px] rounded-[10px] px-[20px] py-2.5 text-text_1 mt-4 cursor-pointer"
      onClick={() => handleDeleteMessage(id)}
      style={{ opacity: '1' }}>
      <div style={{ width: '20px' }}>{messageToShow[status]}</div>
      <div style={{ marginLeft: '14px', wordBreak: 'break-word', width: '100%' }}>{text}</div>
    </div>
  );
};
