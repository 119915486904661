import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  depositMethods,
  depositSuccess,
  makeDeposit,
  makeRedirectDeposit
} from './deposit.requests';
import { DepositMethodsResponse, MakeDepositBody, MakeDepositResponse } from './deposit.types';
import { useTranslation } from 'react-i18next';
import { paymentMethodsSort } from 'src/utils';
import { DescriptionQueryOptions } from '../types';
import { useShouldRefetch } from 'src/hooks/useShouldRefetch';

export function useGetDepositMethods(
  options: DescriptionQueryOptions<DepositMethodsResponse> = {}
) {
  const { refetchDependencies, refetchParams } = useShouldRefetch({
    lang: true
  });

  const { data: response, ...query } = useQuery<AxiosResponse<DepositMethodsResponse>, AxiosError>(
    ['deposit-methods', ...refetchDependencies],
    () => depositMethods({ ...refetchParams }),
    options
  );

  return {
    sortedPaymentMethods:
      response?.data && Array.isArray(response?.data?.paymentMethods)
        ? paymentMethodsSort(response?.data?.paymentMethods)
        : [],
    data:
      response?.data && Array.isArray(response?.data?.paymentMethods) ? response?.data : undefined,
    ...query
  };
}

export function useMakeDeposit() {
  const { i18n } = useTranslation();
  const mutation = useMutation<
    AxiosResponse<MakeDepositResponse>,
    AxiosError<any>,
    MakeDepositBody
  >({
    mutationFn: (variables) => makeDeposit({ ...variables, language: i18n.language }),
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    }
  });
  return mutation;
}

export function useMakeRedirectDeposit() {
  const mutation = useMutation({
    mutationFn: makeRedirectDeposit,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: (data) => {
      const urlToPay = data.data.split('url=')[1].split('"')[0];
      setTimeout(() => {
        window.open(urlToPay, '_blank');
      });
    }
  });

  return mutation;
}

export function useDepositSuccess() {
  const QueryClient = useQueryClient();
  return useMutation({
    mutationFn: depositSuccess,
    onSuccess: () => {
      QueryClient.invalidateQueries(['transactions']);
    }
  });
}
