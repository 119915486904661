import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDropdown } from 'src/hooks/useDropdown';

import { Arrow } from '../svg';
import { arrayLanguages, languageOptions } from './const';

import './styles.css';

export enum Position {
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
}

type CompactLanguageSelectorProps = {
  arrowPosition: Position;
  classNameArrow?: React.CSSProperties;
  classNameIcon?: string;
  classNameLanguage?: string;
  classNameLanguageList?: string;
};

export default React.memo(function CompactLanguageSelector({
  arrowPosition,
  classNameArrow,
  classNameIcon,
  classNameLanguage,
  classNameLanguageList,
}: CompactLanguageSelectorProps) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();

  const { close, showList, toggle } = useDropdown({});

  const languageName = useMemo(() => {
    const language = languageOptions[i18n.language as keyof typeof languageOptions];
    return language?.labelName;
  }, [i18n.language]);

  const LanguageIcon = useMemo(() => {
    const language = languageOptions[i18n.language as keyof typeof languageOptions];
    return language?.icon;
  }, [i18n.language]);

  useClickOutside(dropdownRef, close);

  return (
    <div
      className={classNames(
        'compact-language-selector cursor-pointer gap-2 language',
        arrowPosition === Position.RIGHT && 'right-arrow',
        arrowPosition === Position.BOTTOM && 'bottom-arrow',
        showList && classNameIcon ? classNameIcon : '',
      )}
      onClick={toggle}
    >
      <LanguageIcon
        className={classNames(
          'max-w-[24px] max-h-[24px] min-h-[24px] min-w-[24px] w-[24px] h-[24px] rounded-full',
          arrowPosition === Position.BOTTOM && 'w-full h-auto',
        )}
      />
      <Arrow
        className={classNames('w-[12px] h-[12px] stroke-[3px] transition-transform', {
          opened: showList,
        })}
        style={classNameArrow}
      />
      {showList && (
        <div
          className={classNames(
            'absolute top-[120%] right-[-22%] flex flex-col items-start bg-background_2 border border-border_5 rounded-[20px] py-2.5',
            classNameLanguageList,
          )}
        >
          {arrayLanguages.map((option) => (
            <div
              className="lang-dropdown-item hover:text-text_8 transition-all"
              key={option.labelName}
              onClick={() => {
                i18n.changeLanguage(option.labelCode);
              }}
            >
              <option.icon className="max-w-[24px] max-h-[24px] min-h-[24px] min-w-[24px] w-[24px] h-[24px] rounded-full" />
              <p
                className={classNames('ml-[5px] capitalize text-sm', classNameLanguage, {
                  'text-text_8': languageName === option.labelName,
                })}
              >
                {option.title}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
