import { breakpoints } from "./breakpoints";
import { isAt, isFrom } from "./types";
import { ResponsiveAtProps, ResponsiveFromProps } from "./types";

export const calculateResize = (from: keyof typeof breakpoints | number, windowWidth: number): boolean => {
    if (typeof from === "number") {
        return (from <= windowWidth);
    } 
    return breakpoints[from].min <= windowWidth ? true :  false;
}

export const calculateResizeWithoutFrom = (to: keyof typeof breakpoints | number, windowWidth: number): boolean => {
    if (typeof to === "number") {
        return (to >= windowWidth);
    } 
    const max = breakpoints[to].max;
    if(!max) {
        console.error(`Max resolution hasnt been provided for ${to} breakpoint`);
        return false;
    }
    return windowWidth <= max ? true :  false;
}

export const calculateResizeWithTo = (from: keyof typeof breakpoints | number, to: keyof typeof breakpoints | number, windowWidth: number): boolean => {
    if (typeof from === "number" && typeof to === "number") {
        return (from <= windowWidth && to >= windowWidth);
    } else if (typeof from === "number" && typeof to !== "number") {
        const max = breakpoints[to].max;
        if (!max) {
            console.error(`Max resolution hasnt been provided for ${to} breakpoint`);
            return false;
        }
        return (from <= windowWidth && max >= windowWidth)
    }
    else if (typeof from !== "number" && typeof to === "number") {
        return (breakpoints[from].min <= windowWidth && to >= windowWidth)
    } else if (typeof from !== "number" && typeof to !== "number") {
        const max = breakpoints[to].max;
        if (!max) {
            console.error(`Max resolution hasnt been provided for ${to} breakpoint`);
            return false;
        }
        return breakpoints[from].min <= windowWidth && windowWidth <= max ? true :  false;
    }
    return false
}

export const calculateResizeWithAt = (at: keyof typeof breakpoints | Array<keyof typeof breakpoints>, windowWidth: number): boolean => {
    if (Array.isArray(at)) {
        for (let i = 0; i < at.length; i++) {
            const max = breakpoints[at[i]].max;
            if (!max) {
                console.error(`Max resolution hasnt been provided for ${at[i]} breakpoint`);
                return false;
            }
            if(breakpoints[at[i]].min <= windowWidth && windowWidth <= max) {
                return true;
            }
        }
    } else {
        const max = breakpoints[at].max;
        if (!max) {
            console.error(`Max resolution hasnt been provided for ${at} breakpoint`);
            return false;
        }
        return breakpoints[at].min <= windowWidth && windowWidth <= max ? true : false;
    }
    return false
};

export const validateDiffOfFromAndTwo = (from: number | keyof typeof breakpoints, to:number | keyof typeof breakpoints) => {
    const max = breakpoints[to].max;
    if (!max) {
        console.error(`Max resolution hasnt been provided for ${to} breakpoint`);
        return false;
    }
    if ((typeof from !== 'number' ? breakpoints[from].min : from) - (typeof to !== 'number' ? max : to) > 0) {
        console.error('"to" should be less than "from"')
        return false
    }
    return true
};

export const selectScenarioForCalculate = (props: ResponsiveAtProps | ResponsiveFromProps, clientWidth: number) => {
    if (isFrom(props)) {
        if(props.from && props.to) {
            if(!validateDiffOfFromAndTwo(props.from, props.to)) return false;
            return calculateResizeWithTo(props.from, props.to, clientWidth)
        } 
        if (props.from && !props.to) {
            return calculateResize(props.from, clientWidth);
        }
        if(!props.from && props.to) {
            return calculateResizeWithoutFrom(props.to, clientWidth)
        }
    } else if(isAt(props)) {
        return calculateResizeWithAt(props.at, clientWidth);
    }
    return false;
};