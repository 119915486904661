import classNames from 'classnames';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useLogout } from 'src/api/auth/auth.hooks';
import { profileMenuItems, COMPONENT_TYPE } from 'src/constants/profileMenuItems';
import { useApi } from 'src/hooks/useApi';
import { useDrawer } from 'src/hooks/useDrawer';
import { Button } from '../Buttons/Button';
import { Separator } from '../Separator/Separator';
import Flash from 'src/assets/images/flash.webp';
import './styles.css';
import { useGetFullInfo } from 'src/api/profile';
import { PercentageScale } from '../PercentageScale/PercentageScale';
import { useGetAllUserLevels } from 'src/api/content';
import { useGetFullBalance, useGetWithdrawal } from 'src/api/balance';
import { getCurrencyIcon, getSummOfPossiblyUndefinedNums, makeFloat } from 'src/utils';
import { getLevelPointsTo, getLevelProgress } from '../VIP';

interface AccountMenuProps {
  iconMenuRef?: MutableRefObject<null>;
}

export const AccountMenu = ({ iconMenuRef }: AccountMenuProps) => {
  const {
    isTopDrawerOpen,
    closeTopDrawer,
    isBottomDrawerOpen,
    closeBottomDrawer,
    isLeftDrawerOpen
  } = useDrawer();
  const { clearToken } = useApi();
  const location = useLocation();
  const { mutateAsync: logout } = useLogout({});
  const { t, i18n } = useTranslation();
  const { fullInfo } = useGetFullInfo({});
  const { allLevels } = useGetAllUserLevels({ loyalty_page_code: 'loyalty' });
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();
  const navigate = useNavigate();
  const { withdrawDetails } = useGetWithdrawal({});
  const menuRef = useRef(null);

  const handleLogout = useCallback(async () => {
    await logout({ language: i18n.language });
    clearToken?.();
  }, [clearToken, i18n.language, logout]);

  const currency = useMemo(() => {
    return fullBalance?.currency ?? 'EUR';
  }, [fullBalance]);

  const userLevel = useMemo(() => {
    return fullInfo?.level;
  }, [fullInfo]);

  const levelNumber = userLevel;

  const balance = useMemo(() => {
    return makeFloat(
      getSummOfPossiblyUndefinedNums(
        fullBalance?.balance[currency as keyof typeof fullBalance.balance],
        bonusAccountCasino?.balance
      )
    ).toFixed(2);
  }, [fullBalance, currency, bonusAccountCasino?.balance]);

  const bonusBalance = useMemo(() => {
    return bonusAccountCasino?.balance ? makeFloat(bonusAccountCasino?.balance).toFixed(2) : '0.00';
  }, [bonusAccountCasino?.balance]);

  const nextLevelInfo = allLevels.find((lvl) => {
    if (userLevel === undefined) return undefined;
    return userLevel + 1 === lvl.levelInfo?.level;
  });

  const userPoints = useMemo(() => {
    return fullInfo?.points;
  }, [fullInfo]);

  const levelInfo = allLevels.find((lvl) => {
    return userLevel === lvl?.levelInfo?.level;
  });

  const nextLevelPoints = nextLevelInfo?.levelInfo?.fromPoints;

  const getLevelPercentage = () => {
    if (userPoints === undefined || nextLevelPoints === undefined) return undefined;
    return (userPoints / nextLevelPoints) * 100;
  };

  const icon = getCurrencyIcon(currency);

  const levelPointsTo = getLevelPointsTo({ levelNumber, allLevels });

  const pointsToNextLevel = getLevelProgress({ fullInfo, allLevels, levelNumber, levelPointsTo });

  const cashback = useMemo(() => {
    return levelInfo?.levelInfo?.cashback?.percent ?? 0;
  }, [levelInfo?.levelInfo?.cashback?.percent]);

  const withdrawValue = makeFloat(withdrawDetails?.available_for_withdraw).toFixed(2);

  useEffect(() => {
    isBottomDrawerOpen && isTopDrawerOpen && closeBottomDrawer?.();
  }, [isBottomDrawerOpen, closeBottomDrawer, isTopDrawerOpen]);

  useEffect(() => {
    isLeftDrawerOpen && closeTopDrawer?.();
  }, [closeTopDrawer, isLeftDrawerOpen]);

  const handleEnterPromoClick = () => {
    closeTopDrawer?.();
    if (location.pathname.includes('wallet/deposit')) {
      const promoCodeElement = document.getElementById('depositPromocode');
      if (promoCodeElement) {
        promoCodeElement.scrollIntoView({
          behavior: 'smooth'
        });
      }
    } else {
      navigate('/account/wallet/deposit', { state: { scrollToPromocode: true } });
    }
  };

  return (
    <div
      className="menu rounded-b-2xl"
      style={{
        transform: isTopDrawerOpen ? 'translateY(0px) translate3d(0,0,0)' : 'translateY(-300%)'
      }}
      ref={menuRef}>
      <div className="w-full px-[30px] flex flex-col justify-start items-center overflow-y-scroll max-h-[70vh]">
        {!userLevel || userLevel <= 0 ? (
          <></>
        ) : (
          <div className="w-full mt-[20px] flex justify-between items-center">
            <div className="w-1/3 flex justify-start items-center">
              <img src={Flash} className="w-[18px] mr-[15px]" alt="flash" />
              <span className="text-base font-bold text-text_8">{levelInfo?.title}</span>
            </div>
            <div className="flex flex-col items-center justify-center w-2/3 gap-1">
              <PercentageScale additionalClassName="w-full" value={getLevelPercentage() || 0} />
              <p className="text-xs font-medium text-text_6">{pointsToNextLevel}</p>
            </div>
          </div>
        )}

        <Separator width="w-full" white />
        <div className="mt-[20px] flex justify-between items-center w-full r_small:items-start">
          <div className="w-1/2 flex flex-col justify-start items-start h-full">
            <div className="mb-[20px]">
              <span className="text-sm font-medium text-text_2">
                {t('blockActiveBonus.balance')}
              </span>
              <p className="text-2xl font-bold text-text_12">
                <span className="mr-[4px]">{icon}</span>
                <span>{balance}</span>
              </p>
            </div>
            <div className="r_small:flex-1 r_small:flex r_small:flex-col r_small:justify-between">
              <span className="text-sm font-medium text-text_2 lowercase max-w-[75%]">
                {t('general.fundsWithdrawal')}
              </span>
              <p className="text-2xl font-bold text-text_2">
                <span className="mr-[4px]">{icon}</span>
                <span>{withdrawValue}</span>
              </p>
            </div>
          </div>
          <div className="w-1/2 flex flex-col justify-center items-start r_small:h-full">
            <div className="mb-[20px]">
              <span className="text-sm font-medium text-text_2">{t('general.cashback')}</span>
              <p className="text-2xl font-bold text-text_2">
                <span>{`${cashback} %`}</span>
              </p>
            </div>
            <div className="r_small:flex-1 r_small:flex r_small:flex-col r_small:justify-between">
              <span className="text-sm font-medium text-text_2">{t('general.bonusBalance')}</span>
              <p className="text-2xl font-bold text-text_2">
                <span>{`${icon} ${bonusBalance}`}</span>
              </p>
            </div>
          </div>
        </div>
        <Button
          mode="primary"
          additionalClassName="w-full mt-[20px]"
          onClick={() => {
            closeTopDrawer?.();
            navigate('/account/wallet/deposit');
          }}
          title={`${t('accountWalletBalance.deposite')}`}>
          {t('accountWalletBalance.deposite')}
        </Button>
        <Button
          mode="secondary"
          additionalClassName="w-full my-[20px]"
          onClick={() => {
            closeTopDrawer?.();
            navigate('/account/wallet/withdrawal');
          }}
          title={`${t('general.withdrawal')}`}>
          {t('general.withdrawal')}
        </Button>
        <p
          className="text-text_8 text-xs font-bold underline mb-[20px] lowercase"
          onClick={handleEnterPromoClick}>
          {t('promoCode.subtitle')}
        </p>
        <Separator width="w-full" noMarginTop white />
        <div className="w-full flex flex-col justify-center items-start mt-[30px] mb-[60px]">
          {profileMenuItems.map((item) => (
            <div key={item.key} className="flex justify-center items-center mb-2.5">
              <div className="mr-4 w-[24px] flex justify-center items-center">{<item.icon />}</div>
              <NavLink
                to={item.route}
                onClick={
                  item.type === COMPONENT_TYPE.BUTTON
                    ? () => {
                        handleLogout();
                        closeTopDrawer?.();
                      }
                    : () => closeTopDrawer?.()
                }
                className={classNames(
                  'font-bold text-base text-text_2',
                  location.pathname.includes(item.key) ? 'text-text_8' : ''
                )}
                aria-label={`${t(`${item.content}`)}`}>
                {t(`${item.content}`)}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
