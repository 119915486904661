import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

import { activateBonusViaLink, activatePromoCode, bonuses, checkPromoCode, singleBonus } from './bonuses.requests';
import {
  ActivateBonusViaLinkBody,
  ActivateBonusViaLinkResponse,
  ActivatePromoCodeResponse,
  BonusesResponse,
  ShortBonus,
  SingleBonus,
} from './bonuses.types';

export function useGetBonuses() {
  const { i18n } = useTranslation();

  const { data: response, ...query } = useQuery<AxiosResponse<BonusesResponse>, AxiosError>(
    ['bonuses', i18n.language],
    () => bonuses(i18n.language),
  );
  return {
    data: response?.data || [],
    ...query,
  };
}

export function useGetSingleBonus(bonusId?: string) {
  const { i18n } = useTranslation();

  const { data: response, ...query } = useQuery<AxiosResponse<SingleBonus>, AxiosError>(
    ['singele-bonus', i18n.language, bonusId],
    () => singleBonus(bonusId!, i18n.language),
    { enabled: Boolean(bonusId) },
  );

  return {
    data: response?.data || undefined,
    games: response?.data?.wageringGames || [],
    gamesCount: response?.data?.wageringGames?.length || 0,
    ...query,
  };
}

type ActivateBonusViaLinkMutationOptions = Omit<
  UseMutationOptions<AxiosResponse<ActivateBonusViaLinkResponse>, AxiosError, ActivateBonusViaLinkBody>,
  'mutationFn'
>;

export function useActivateBonusViaLink(options: ActivateBonusViaLinkMutationOptions = {}) {
  const { i18n } = useTranslation();
  const QueryClient = useQueryClient();
  return useMutation((data: ActivateBonusViaLinkBody) => activateBonusViaLink({ ...data, language: i18n.language }), {
    ...options,
    onSuccess: (...args) => {
      QueryClient.invalidateQueries(['bonuses-transactions']);
      QueryClient.invalidateQueries(['full-balance']);
      QueryClient.invalidateQueries(['short-balance']);
      QueryClient.invalidateQueries(['all-free-spins']);
      options?.onSuccess?.(...args);
    },
  });
}

export function useActivatePromoCode({ handleError }: { handleError?: () => void }) {
  const { openMessage } = useMessage();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: activatePromoCode,
    onError: () => {
      handleError?.();
    },
    onSuccess: ({ data }: { data: ActivatePromoCodeResponse }) => {
      openMessage?.(`${t('promoCode.successfulActivatedPromoCode')}`, MESSAGE_STATUS.SUCCESS);

      if (data.bonusTransaction.status === 0) {
        openModal?.(MODAL_NAME.POPUP_PROMOCODE, { bonusId: data.bonusTransaction.id }, false);
      }
    },
  });

  return mutation;
}

export function useCheckPromoCode() {
  const { openMessage } = useMessage();
  const { t } = useTranslation();

  const mutation = useMutation({
    meta: { preventCommonErrorHandler: true },
    mutationFn: checkPromoCode,
    onError: () => {
      openMessage?.(`${t('promoCode.promoCodeNotFound')}`, MESSAGE_STATUS.ERROR);
    },
    onSuccess: (response: { data: ShortBonus }) => {
      if (response.data.id) {
        openMessage?.(`${t('promoCode.successfulPromoCode')}`, MESSAGE_STATUS.SUCCESS);
      } else {
        openMessage?.(`${t('promoCode.promoCodeNotFound')}`, MESSAGE_STATUS.ERROR);
      }
    },
  });

  return mutation;
}
