import { useMemo } from 'react';
import { STORAGE_URL } from 'src/api';
import { useApi } from 'src/hooks/useApi';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';
import { WinnerResponse } from '../../api/content/content.types';
import { getCurrencyIcon, makeFloat } from '../../utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const RecentWinersCard = ({
  winner,
  roundedImage = false
}: {
  winner: WinnerResponse;
  roundedImage?: boolean;
}) => {
  const { isAuthenticated } = useApi();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const gameClick = () => {
    if (isAuthenticated) {
      navigate(`/game/${winner.game.id}`);
    } else {
      openModal?.(MODAL_NAME.SIGNUP);
    }
  };

  const winnerLogin = useMemo(() => {
    return winner.login.slice(0, 10);
  }, [winner.login]);

  return (
    <div className="flex flex-row items-center r_sm:w-full">
      <div
        style={{
          backgroundImage: `url(${STORAGE_URL}/storage/games/vertically${winner.game.image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '190%'
        }}
        onClick={gameClick}
        className={classNames(
          'no-repeat w-[30px] h-[40px] bg-cover bg-center cursor-pointer',
          roundedImage ? 'min-w-[30px] !h-[30px] rounded-full' : ''
        )}></div>
      <div className="flex flex-col pl-[6px]">
        <p className="text-text_2 r_sm:text-[14px] r_gf:text-[10px] font-medium">
          <>{winnerLogin}</> {t('general.wins')}{' '}
          <span className="font-bold text-text_8 r_sm:text-[14px] r_gf:text-[10px]">
            {makeFloat(winner.amount).toFixed(2)}
            {getCurrencyIcon(winner.currency)}
          </span>{' '}
          {t('general.in')}{' '}
          <span
            className="font-bold text-text_8 r_sm:text-[14px] r_gf:text-[10px] cursor-pointer"
            onClick={gameClick}>
            {winner.game.name}
          </span>
        </p>
      </div>
    </div>
  );
};
