export const Chrome = () => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9828_95963)">
        <path
          d="M14 28.2106C21.732 28.2106 28 21.9426 28 14.2106C28 6.47858 21.732 0.210571 14 0.210571C6.26801 0.210571 0 6.47858 0 14.2106C0 21.9426 6.26801 28.2106 14 28.2106Z"
          fill="#F1F1F1"
        />
        <path
          d="M14.0841 9.06885H25.019C25.7495 10.6304 26.1571 12.3727 26.1571 14.2107C26.1571 20.9244 20.7147 26.3668 14.001 26.3668C13.7323 26.3668 13.4653 26.3579 13.2009 26.3408L12.0508 19.4579"
          fill="#FCBD00"
        />
        <path
          d="M17.6923 16.2664C17.8565 16.0692 18.2661 17.0934 18.3973 16.8765C18.4157 16.8461 18.4341 16.8151 18.4525 16.784L13.1996 26.3405C6.85881 25.9285 1.84375 20.6554 1.84375 14.2106C1.84375 11.6136 2.65783 9.20695 4.04569 7.23196L9.49316 16.6884"
          fill="#16A663"
        />
        <path d="M25.0196 9.06869L14.8179 11.7861L14.207 7.60153L25.0196 9.06869Z" fill="#FD9F37" />
        <path
          d="M25.019 9.0687H14.0796C13.9979 9.06744 13.9154 9.06804 13.833 9.07056L9.1799 16.0004C9.268 16.2388 9.37327 16.4684 9.49435 16.6884L9.14123 16.0753L4.04688 7.23191C6.2457 4.1005 9.88427 2.05453 14.001 2.05453C18.8766 2.05453 23.0821 4.92536 25.019 9.0687Z"
          fill="#ED402D"
        />
        <path
          d="M13.9985 19.0292C16.6599 19.0292 18.8174 16.8717 18.8174 14.2104C18.8174 11.549 16.6599 9.39151 13.9985 9.39151C11.3372 9.39151 9.17969 11.549 9.17969 14.2104C9.17969 16.8717 11.3372 19.0292 13.9985 19.0292Z"
          fill="#4192CF"
        />
        <path
          d="M13.1992 26.3406L14.8388 18.3587L18.3969 16.8766L13.1992 26.3406Z"
          fill="#12945A"
        />
        <path
          d="M10.1487 12.6103L9.1799 16.0004L9.14123 16.0752L4.04688 7.2319L10.1487 12.6103Z"
          fill="#E1382A"
        />
        <path
          d="M14.0017 19.3843C11.149 19.3843 8.82812 17.0635 8.82812 14.2107C8.82812 11.358 11.149 9.03714 14.0017 9.03714C16.8543 9.03714 19.1753 11.358 19.1753 14.2107C19.1753 17.0635 16.8543 19.3843 14.0017 19.3843ZM14.0017 10.1378C11.7558 10.1378 9.92877 11.9649 9.92877 14.2107C9.92877 16.4566 11.7559 18.2836 14.0017 18.2836C16.2475 18.2836 18.0746 16.4565 18.0746 14.2107C18.0746 11.9649 16.2475 10.1378 14.0017 10.1378Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9828_95963">
          <rect width="28" height="28" fill="white" transform="translate(0 0.210571)" />
        </clipPath>
      </defs>
    </svg>
  );
};
