import classNames from 'classnames';

type PropsClose = {
  className?: string;
  fill?: string;
  onShow?: (() => void) | undefined;
};

type PropsCloseMenu = {
  className?: string;
  closeTopDrawer?: (() => void) | undefined;
};

export const Close = ({ onShow }: PropsClose) => {
  return (
    <svg
      className="self-end m-[14px] w-[26px] min-h-[26px] h-[26px] stroke-[5px] text-text_2 bg-background_3  hover:text-text_4 ease-in duration-300 border rounded-2xl cursor-pointer"
      fill="none"
      onClick={onShow}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#1F1F1F"
        rx="13"
      />
      <path
        d="M14.65 13.0003L17.7436 9.90668C18.0855 9.56487 18.0855 9.0107 17.7436 8.66942L17.3312 8.257C16.9893 7.91508 16.4351 7.91508 16.0939 8.257L13.0003 11.3505L9.90668 8.25636C9.56487 7.91455 9.0107 7.91455 8.66942 8.25636L8.25636 8.66878C7.91455 9.0107 7.91455 9.56487 8.25636 9.90615L11.3505 13.0003L8.257 16.0939C7.91508 16.4358 7.91508 16.9899 8.257 17.3312L8.66942 17.7436C9.01124 18.0855 9.5654 18.0855 9.90668 17.7436L13.0003 14.65L16.0939 17.7436C16.4358 18.0855 16.9899 18.0855 17.3312 17.7436L17.7436 17.3312C18.0855 16.9893 18.0855 16.4351 17.7436 16.0939L14.65 13.0003Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseModal = ({ onShow }: PropsClose) => {
  return (
    <svg
      className="self-end m-[14px] w-[26px] min-h-[26px] h-[26px] stroke-[5px] text-text_2 cursor-pointer hover:text-text_4 ease-in duration-300"
      fill="none"
      height="14"
      onClick={onShow}
      viewBox="0 0 26 26"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43348 7.00016L13.6762 2.75746C14.145 2.28869 14.145 1.52869 13.6762 1.06064L13.1106 0.495038C12.6417 0.026117 11.8817 0.026117 11.4136 0.495038L7.17106 4.73759L2.92836 0.494159C2.45958 0.0253845 1.69958 0.0253845 1.23154 0.494159L0.665057 1.05976C0.196283 1.52869 0.196283 2.28869 0.665057 2.75673L4.90849 7.00016L0.665936 11.2427C0.197015 11.7116 0.197015 12.4716 0.665936 12.9397L1.23154 13.5053C1.70032 13.9741 2.46032 13.9741 2.92836 13.5053L7.17106 9.26258L11.4136 13.5053C11.8825 13.9741 12.6425 13.9741 13.1106 13.5053L13.6762 12.9397C14.145 12.4708 14.145 11.7108 13.6762 11.2427L9.43348 7.00016Z"
        fill="white"
      />
    </svg>
  );
};

export const SimpleClose = ({ className, fill = 'white', onShow }: PropsClose) => {
  return (
    <svg
      className={classNames('cursor-pointer', className)}
      fill="none"
      height="20"
      onClick={onShow}
      viewBox="0 0 24 24"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.96 12.0008L23.3847 4.57604C24.2051 3.75569 24.2051 2.42569 23.3847 1.60661L22.3949 0.616805C21.5743 -0.203807 20.2443 -0.203807 19.4252 0.616805L12.0008 8.04127L4.57604 0.615266C3.75569 -0.205089 2.42569 -0.205089 1.60661 0.615266L0.615266 1.60508C-0.205089 2.42569 -0.205089 3.75569 0.615266 4.57476L8.04127 12.0008L0.616805 19.4252C-0.203807 20.2459 -0.203807 21.5759 0.616805 22.3949L1.60661 23.3847C2.42697 24.2051 3.75697 24.2051 4.57604 23.3847L12.0008 15.96L19.4252 23.3847C20.2459 24.2051 21.5759 24.2051 22.3949 23.3847L23.3847 22.3949C24.2051 21.5743 24.2051 20.2443 23.3847 19.4252L15.96 12.0008Z"
        fill={fill}
      />
    </svg>
  );
};

export const CloseMenuAccount = ({ className, closeTopDrawer }: PropsCloseMenu) => {
  return (
    <svg
      className={classNames(
        'mx-[14px] w-[26px] h-[26px] stroke-[5px] text-text_2 cursor-pointer bg-background_3  hover:text-text_4 ease-in duration-300 border rounded-2xl',
        className,
      )}
      fill="none"
      onClick={closeTopDrawer}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#1F1F1F"
        rx="13"
      />
      <path
        d="M14.65 13.0003L17.7436 9.90668C18.0855 9.56487 18.0855 9.0107 17.7436 8.66942L17.3312 8.257C16.9893 7.91508 16.4351 7.91508 16.0939 8.257L13.0003 11.3505L9.90668 8.25636C9.56487 7.91455 9.0107 7.91455 8.66942 8.25636L8.25636 8.66878C7.91455 9.0107 7.91455 9.56487 8.25636 9.90615L11.3505 13.0003L8.257 16.0939C7.91508 16.4358 7.91508 16.9899 8.257 17.3312L8.66942 17.7436C9.01124 18.0855 9.5654 18.0855 9.90668 17.7436L13.0003 14.65L16.0939 17.7436C16.4358 18.0855 16.9899 18.0855 17.3312 17.7436L17.7436 17.3312C18.0855 16.9893 18.0855 16.4351 17.7436 16.0939L14.65 13.0003Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseComponent = ({ onShow }: PropsClose) => {
  return (
    <svg
      className="cursor-pointer mr-[-30px]"
      fill="none"
      height="16"
      onClick={onShow}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1417 8.00048L14.7822 3.36003C15.2949 2.84731 15.2949 2.01605 14.7822 1.50413L14.1635 0.885503C13.6507 0.372621 12.8194 0.372621 12.3075 0.885503L7.6672 5.5258L3.02674 0.884541C2.51402 0.37182 1.68277 0.37182 1.17085 0.884541L0.551259 1.50317C0.038537 2.01605 0.038537 2.84731 0.551259 3.35923L5.19251 8.00048L0.55222 12.6408C0.0393382 13.1537 0.0393382 13.9849 0.55222 14.4968L1.17085 15.1155C1.68357 15.6282 2.51482 15.6282 3.02674 15.1155L7.6672 10.475L12.3075 15.1155C12.8204 15.6282 13.6516 15.6282 14.1635 15.1155L14.7822 14.4968C15.2949 13.9839 15.2949 13.1527 14.7822 12.6408L10.1417 8.00048Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseComponentWithoutStyles = ({ onShow }: PropsClose) => {
  return (
    <svg
      fill="none"
      height="16"
      onClick={onShow}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1417 8.00048L14.7822 3.36003C15.2949 2.84731 15.2949 2.01605 14.7822 1.50413L14.1635 0.885503C13.6507 0.372621 12.8194 0.372621 12.3075 0.885503L7.6672 5.5258L3.02674 0.884541C2.51402 0.37182 1.68277 0.37182 1.17085 0.884541L0.551259 1.50317C0.038537 2.01605 0.038537 2.84731 0.551259 3.35923L5.19251 8.00048L0.55222 12.6408C0.0393382 13.1537 0.0393382 13.9849 0.55222 14.4968L1.17085 15.1155C1.68357 15.6282 2.51482 15.6282 3.02674 15.1155L7.6672 10.475L12.3075 15.1155C12.8204 15.6282 13.6516 15.6282 14.1635 15.1155L14.7822 14.4968C15.2949 13.9839 15.2949 13.1527 14.7822 12.6408L10.1417 8.00048Z"
        fill="white"
      />
    </svg>
  );
};

export const CloseSearchTopbar = ({ closeTopDrawer }: PropsCloseMenu) => {
  return (
    <svg
      className="self-end min-h-[30px] max-h-[30px] min-w-[30px] max-w-[30px] m-[14px] stroke-[5px] cursor-pointer ease-in duration-300"
      fill="none"
      height="26"
      onClick={closeTopDrawer}
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.65 13.0003L17.7436 9.90668C18.0855 9.56487 18.0855 9.0107 17.7436 8.66942L17.3312 8.257C16.9893 7.91508 16.4351 7.91508 16.0939 8.257L13.0003 11.3505L9.90668 8.25636C9.56487 7.91455 9.0107 7.91455 8.66942 8.25636L8.25636 8.66878C7.91455 9.0107 7.91455 9.56487 8.25636 9.90615L11.3505 13.0003L8.257 16.0939C7.91508 16.4358 7.91508 16.9899 8.257 17.3312L8.66942 17.7436C9.01124 18.0855 9.5654 18.0855 9.90668 17.7436L13.0003 14.65L16.0939 17.7436C16.4358 18.0855 16.9899 18.0855 17.3312 17.7436L17.7436 17.3312C18.0855 16.9893 18.0855 16.4351 17.7436 16.0939L14.65 13.0003Z"
        fill="white"
      />
    </svg>
  );
};

type PropsClosePage = {
  className?: string;
  onClick?: () => void;
};

export const ClosePage = ({ className, onClick }: PropsClosePage) => {
  return (
    <svg
      className={className}
      fill="none"
      height="13"
      onClick={onClick}
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69436 6.28627L12.4067 2.57391C12.8169 2.16373 12.8169 1.49873 12.4067 1.0892L11.9118 0.594291C11.5015 0.183985 10.8365 0.183985 10.427 0.594291L6.71474 4.30653L3.00238 0.593522C2.5922 0.183344 1.9272 0.183344 1.51766 0.593522L1.02199 1.08843C0.611811 1.49873 0.611811 2.16373 1.02199 2.57327L4.73499 6.28627L1.02276 9.99851C0.612452 10.4088 0.612452 11.0738 1.02276 11.4834L1.51766 11.9783C1.92784 12.3884 2.59284 12.3884 3.00238 11.9783L6.71474 8.26589L10.427 11.9783C10.8373 12.3884 11.5023 12.3884 11.9118 11.9783L12.4067 11.4834C12.8169 11.073 12.8169 10.408 12.4067 9.99851L8.69436 6.28627Z"
        fill="white"
      />
    </svg>
  );
};

export const BigCloseGame = ({ className, onShow }: PropsClose) => {
  return (
    <svg
      className={classNames('cursor-pointer', className)}
      fill="none"
      height="35"
      onClick={onShow}
      viewBox="0 0 48 48"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8L40 40M8 8L40 40M8 8L40 40"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="3.75"
      />
      <path
        d="M40 8L8 40M40 8L8 40M40 8L8 40"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="3.75"
      />
    </svg>
  );
};

export const LittleClock = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
        stroke="#9D9D9D"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.09261"
      />
      <path
        d="M10 5V10L13.3333 11.6667"
        stroke="#9D9D9D"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.09261"
      />
    </svg>
  );
};
