import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, createPath, useLocation } from 'react-router-dom';
import { gamesCategoriesSwitcher, liveItems } from 'src/constants';

import './styles.css';

export const GamesCategoriesSwitcher = ({
  lightActiveLink = true,
  onCategoryClick
}: {
  lightActiveLink?: boolean;
  onCategoryClick?: () => void;
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  const categories = useMemo(() => {
    if (
      location.pathname.includes('roulette') ||
      location.pathname.includes('poker') ||
      location.pathname.includes('baccarat') ||
      location.pathname.includes('blackjack') ||
      location.pathname.includes('live')
    ) {
      return liveItems;
    }
    return gamesCategoriesSwitcher;
  }, [location]);

  const scrollableRef = useRef<any>(null);

  const scrollToCenter = (element: HTMLElement | null) => {
    const container = scrollableRef.current;
    const containerRect = container.getBoundingClientRect();
    if (element) {
      const elementRect = element.getBoundingClientRect();

      const containerCenterX = containerRect.left + containerRect.width / 2;
      const elementCenterX = elementRect.left + elementRect.width / 2;

      const scrollLeft = elementCenterX - containerCenterX + container.scrollLeft;
      container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    const currentElement = document.getElementById(location.pathname);

    if (scrollableElement) {
      scrollToCenter(currentElement);
    }
  }, [location]);

  return (
    <div className="switcher-wrapper overflow-x-scroll" ref={scrollableRef}>
      <div className="pb-2.5 md:mb-2 md:my-4 flex justify-start w-full bounce">
        {categories.map((item, i) => (
          <div key={i} className="flex gap-5 items-center r_sm:gap-2 px-2" id={item.path}>
            <NavLink
              aria-label={`${item.path}`}
              to={createPath({
                pathname: item.path
              })}
              key={item.name}
              className={({ isActive }) =>
                classNames(
                  'py-[8px] px-[20px] grid w-full min-w-[60px] uppercase grid-cols-[40px_1fr] justify-center items-center r_sm:flex r_sm:gap-1 r_sm:flex-col r_sm:justify-center r_sm:items-center rounded-full text-[15px] font-bold whitespace-nowrap h-full r_sm:py-1 r_sm:text-sm linkitem',
                  isActive && lightActiveLink
                    ? 'bg-button_primary_background text-background_1 linkItemActive'
                    : 'text-text_16'
                )
              }
              onClick={onCategoryClick}>
              {item.image && <div>{item.image}</div>}
              <span className="text-center">{t(item.name)}</span>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};
