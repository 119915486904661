import { throttle } from 'lodash';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { useCheckPromoCode } from '../../../api/bonuses';
import { InputWithButton } from '../../InputWithButton/InputWithButton';

export const DepositPromoCode: FC<{
  changePromoCode: (code: string) => void;
  depositAmount?: string;
  externalPromoCode?: string;
  label?: string;
  placeholder?: string;
}> = ({ changePromoCode, depositAmount, externalPromoCode }) => {
  const [promoCode, setPromoCode] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleChangePromoCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPromoCode(event.target.value);
      changePromoCode(event.target.value);
      setIsChecked(false);
    },
    [changePromoCode],
  );
  const { mutateAsync } = useCheckPromoCode();

  const handleCheckPromoCode = useCallback(
    throttle(async () => {
      if (promoCode && depositAmount) {
        await mutateAsync({
          depositAmount: parseInt(depositAmount, 10) * 100,
          promoCode,
        });
        setIsChecked(true);
      }
    }, 1000),
    [promoCode, depositAmount],
  );

  useEffect(() => {
    if (externalPromoCode) {
      setPromoCode(externalPromoCode);
      changePromoCode(externalPromoCode);
      setIsChecked(false);
    }
  }, [externalPromoCode]);

  return (
    <div className="flex flex-col justify-between gap-5">
      <div className="deposit-label-font w-full flex justify-center lg:justify-start">Promo code</div>
      <InputWithButton
        buttonProps={{
          disabled: !promoCode || isChecked || !depositAmount,
          label: 'Check',
          onClick: handleCheckPromoCode,
          title: 'Check',
        }}
        inputProps={{
          onChange: handleChangePromoCode,
          placeholder: 'promo code',
          value: externalPromoCode,
        }}
      />
    </div>
  );
};
