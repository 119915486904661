import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useGetSinglePage } from 'src/api/content';
import { useGetKefteme } from 'src/hooks/useGetKefteme';

import bigLogo from '../../assets/images/bigLogoWithDescription.png';
import { partners, paymentsCards } from '../../constants';
import Languages from '../Languages';
import { SeoBlock } from '../SeoBlock/SeoBlock';
import { AllRights } from '../svg';
import { Wrapper } from '../Wrapper/Wrapper';

export const Footer = () => {
  const { t } = useTranslation();
  const { childs } = useGetSinglePage('info_pages');
  const license = useGetKefteme();

  return (
    <footer className="flex flex-col justify-center items-center pt-[20px] pb-[100px] md:pb-[40px] xl:pt-[25px]">
      <Wrapper additionalClassName="flex flex-col gap-y-[20px] xl:gap-y-[30px]">
        <div className="flex flex-wrap flex-col items-center gap-y-[20px] xl:flex-row xl:gap-x-[20px] xl:justify-between">
          <Link to="/">
            <img
              alt=""
              className="w-[170px]"
              src={bigLogo}
            />
          </Link>

          <Languages additionalClassName="w-[180px] h-auto !mt-0 p-[5px] xl:order-1" />

          <div className="flex flex-col gap-y-[5px] py-[10px] xl:grid xl:grid-cols-3 xl:gap-x-[20px]">
            {childs.map((item, index) => (
              <NavLink
                aria-label={t<string>(item.title)}
                className={({ isActive }) => {
                  return classNames(
                    isActive ? 'text-text_8' : 'text-text_2 hover:text-text_8',
                    'text-[15px] text-center font-bold uppercase xl:text-left xl:text-[14px] xl:font-normal xl:capitalize',
                  );
                }}
                key={index}
                to={`/info_pages/${item.code}`}
              >
                {t(item.title)}
              </NavLink>
            ))}

            <NavLink
              aria-label={'Faq'}
              className={({ isActive }) => {
                return classNames(
                  isActive ? 'text-text_8' : 'text-text_2 hover:text-text_8',
                  'text-[15px] text-center font-bold uppercase xl:text-left xl:text-[14px] xl:font-normal xl:capitalize',
                );
              }}
              to="/faq"
            >
              FAQ
            </NavLink>

            <NavLink
              aria-label={t<string>('footer.affiliate')}
              className={({ isActive }) => {
                return classNames(
                  isActive ? 'text-text_8' : 'text-text_2 hover:text-text_8',
                  'text-[15px] text-center font-bold uppercase xl:text-left xl:text-[14px] xl:font-normal xl:capitalize',
                );
              }}
              target="_blank"
              to="https://megapartners.io/"
            >
              {t('footer.affiliate')}
            </NavLink>
          </div>
        </div>

        <div className="py-[10px]">
          <div className="flex flex-col border-y-2 border-border_8 divide-y-2 divide-border_8">
            <SeoBlock />

            <ul className="flex flex-wrap justify-center gap-[20px] py-[20px] xl:py-[30px]">
              {paymentsCards.map((item, index) => (
                <li
                  className={item.style}
                  key={index}
                >
                  {item.image}
                </li>
              ))}
            </ul>

            <ul className="flex flex-wrap justify-center items-center gap-[20px] py-[20px] xl:gap-[39px] xl:py-[30px]">
              {partners.map((item, index) => (
                <li
                  className={item.style}
                  key={index}
                >
                  <a
                    href={item.href}
                    rel="dofollow"
                    target="_blank"
                  >
                    {item.icon && item.icon}
                    {item.image && (
                      <img
                        alt=""
                        className={item.style}
                        src={item.image}
                      />
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col gap-y-[20px] xl:gap-y-[30px] xl:flex-row xl:justify-between">
          <p className="py-[20px] text-[10px] text-text_3 font-medium xl:py-0 xl:max-w-[790px] xl:text-[15px] xl:text-text_2">
            {license.text}
          </p>

          <div className="flex justify-center items-center gap-x-[26px]">
            <AllRights />

            <div>{license.logo}</div>
          </div>
        </div>
      </Wrapper>
    </footer>
  );
};
