import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShortBonus } from 'src/api/bonuses';
import { useGetGamesPaginated } from 'src/api/games';
import { useShortBalanceFormatted } from 'src/pages/AccountWallet/Deposit';
import { makeFloat } from 'src/utils';
import { Divider } from '../Divider/Divider';
import './BonusAccordion.css';
import { useNavigate } from 'react-router-dom';

export const BonusAccordion = ({ bonus }: { bonus: ShortBonus }) => {
  const { t } = useTranslation();
  const balance = useShortBalanceFormatted();
  const { data } = useGetGamesPaginated({ free_spins: 1 }, 'free-spins');
  const navigate = useNavigate();

  const gameNames = useMemo(() => data?.pages[0].data.data.slice(0, 3), [data]);

  const [accordion, setAccordion] = useState<boolean>(true);
  const handleClick = () => {
    setAccordion(!accordion);
  };

  const balanceCurrency = makeFloat(bonus.maxPayout[balance.currency]);

  return (
    <>
      <div className="flex justify-between items-center cursor-pointer" onClick={handleClick}>
        <div className="font-bold text-xl uppercase">
          <p className="text-text_8">{bonus.name}</p>
          <p className="text-text_2">
            {bonus.percent}% {t('accountWalletDeposite.upTo')}{' '}
            <span>
              {`${balance.currencySign} ${
                isFinite(balanceCurrency) ? balanceCurrency : balanceCurrency.toFixed(2)
              } + ${bonus.freeSpins} ${t('freeSpins.freeSpins')}`}
            </span>
          </p>
        </div>
        <div>
          <svg
            className={classNames('transition ease-in-out duration-500', {
              'rotate-180': !accordion
            })}
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="white"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.71209 6.52459C4.0782 6.15847 4.6718 6.15847 5.03791 6.52459L10 11.4867L14.9621 6.52459C15.3282 6.15847 15.9218 6.15847 16.2879 6.52459C16.654 6.8907 16.654 7.4843 16.2879 7.85041L10.6629 13.4754C10.2968 13.8415 9.7032 13.8415 9.33709 13.4754L3.71209 7.85041C3.34597 7.4843 3.34597 6.8907 3.71209 6.52459Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
      </div>
      <Divider height="border-1" />
      <div
        className={classNames('my-[15px] font-normal text-xs', {
          hidden: accordion
        })}>
        {bonus.maxPayout[balance.currency] ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('blockActiveBonus.maxBonus')}</p>
            <p className="font-bold text-sm">
              {balance.currencySign}
              {makeFloat(bonus.maxPayout[balance.currency]).toFixed(2)}
            </p>
          </div>
        ) : null}
        {bonus.minDepositAmount[balance.currency] ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('blockActiveBonus.minDeposite')}</p>
            <p className="font-bold text-sm">
              {balance.currencySign}
              {makeFloat(bonus.minDepositAmount[balance.currency]).toFixed(2)}
            </p>
          </div>
        ) : null}
        {bonus.maxBet[balance.currency] ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('blockActiveBonus.maxBet')}</p>
            <p className="font-bold text-sm">
              {balance.currencySign}
              {makeFloat(bonus.maxBet[balance.currency]).toFixed(2)}
            </p>
          </div>
        ) : null}
        {bonus.wager ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('blockActiveBonus.wagering')}</p>
            <p className="font-bold text-sm">{bonus.wager}x</p>
          </div>
        ) : null}
        {bonus.freeSpinsBetAmount[balance.currency] ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('blockActiveBonus.freeSpinAmount')}</p>
            <p className="font-bold text-sm">
              {balance.currencySign}
              {makeFloat(bonus.freeSpinsBetAmount[balance.currency]).toFixed(2)}
            </p>
          </div>
        ) : null}
        {gameNames?.length ? (
          <div className="bonus-accordion-item flex justify-between items-center p-2">
            <p className="font-bold text-lg">{t('freeSpins.gameFreeSpins')}</p>
            <div className="flex flex-col gap-2 text-end">
              {gameNames?.map((game, index) => (
                <p
                  key={index}
                  className="text-sm text-text_8 font-bold cursor-pointer"
                  onClick={() => {
                    navigate(`/game/${game.id}`);
                  }}>
                  {game.name}
                </p>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
