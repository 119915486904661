import classNames from 'classnames';

type Props = {
  item: string | number;
  className?: string;
};

export const TableCell = ({ item, className }: Props) => {
  return (
    <td className={classNames('p-6 text-text_2', className)}>
      <p className='text-center'>{item}</p>
    </td>
  );
};
