import { useGetSinglePage } from 'src/api/content';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import VIPLogo from 'src/assets/images/vip-logo.webp';

export const Guaranteed = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetSinglePage('loyalty_rules');

  const guaranteedInfo = useMemo(() => {
    if (isLoading) {
      return { content: '', title: '' };
    }

    return {
      content: data?.content || t('vip.text'),
      title: data?.title || t('vip.getQuaranteed')
    };
  }, [data?.content, data?.title, isLoading, t]);

  return (
    <div className="flex flex-col items-center mx-[40px] my-[40px] gap-y-[50px]">
      <div className="w-full flex justify-center max-w-[250px]">
        <img src={VIPLogo} alt="vip logo" />
      </div>
      <div className="flex flex-col items-center">
        <p className="font-bold text-[22px] md:text-[34px] text-center text-text_8 uppercase">
          {guaranteedInfo.title}
        </p>
      </div>
      <div
        className="flex flex-col items-center max-w-[700px] font-medium text-[14px] leading-[140%] text-text_2 gap-y-[30px] text-left"
        dangerouslySetInnerHTML={{ __html: guaranteedInfo.content || '' }}></div>
    </div>
  );
};
