import { AxiosError, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'src/hooks/useApi';
import { getCurrencyIcon, makeFloat } from 'src/utils';

import { useModal } from '../../hooks/useModal';
import {
  changeCurrency,
  fullBalance,
  shortBalance,
  withdrawCancel,
  withdrawDetails
} from './balance.requests';
import {
  FullBalanceResponse,
  ShortBalanceResponse,
  WithdrawDetailsResponse
} from './balance.types';

export function useGetFullBalance() {
  const { isAuthenticated } = useApi();
  const { data: response, ...query } = useQuery<AxiosResponse<FullBalanceResponse>, AxiosError>(
    ['full-balance'],
    fullBalance,
    { enabled: isAuthenticated }
  );
  return {
    bonusAccountCasino: response?.data?.bonusAccounts?.casino,
    bonusAccountSport: response?.data?.bonusAccounts?.sport,
    fullBalance: response?.data,
    ...query
  };
}

export function useGetWithdrawal({ enabled = true }: { enabled?: boolean }) {
  const { isAuthenticated } = useApi();
  const { data: response, ...query } = useQuery<AxiosResponse<WithdrawDetailsResponse>, AxiosError>(
    ['withdraw-details'],
    withdrawDetails,
    { enabled: isAuthenticated && enabled }
  );
  return {
    withdrawDetails: response?.data.withdrawDetails,
    ...query
  };
}

export function useGetShortBalance() {
  const { isAuthenticated } = useApi();

  const { data: response, ...query } = useQuery<AxiosResponse<ShortBalanceResponse>, AxiosError>(
    ['short-balance'],
    shortBalance,
    {
      enabled: isAuthenticated,
      refetchInterval: 5000
    }
  );

  const shortBalanceData = response?.data;

  const shortBalanceFormatted = useMemo(() => {
    return `${getCurrencyIcon(shortBalanceData?.currency ?? 'EUR')}${makeFloat(
      shortBalanceData?.balance
    ).toFixed(2)}`;
  }, [shortBalanceData?.balance, shortBalanceData?.currency]);

  return {
    data: shortBalanceData,
    shortBalanceFormatted,
    ...query
  };
}

export function useChangeCurrency() {
  const { closeModal } = useModal();
  const QueryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: changeCurrency,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      QueryClient.invalidateQueries(['full-balance']);
      QueryClient.invalidateQueries(['short-balance']);
      QueryClient.invalidateQueries(['withdraw-details']);
      closeModal?.();
    }
  });

  return mutation;
}

export function useWithdrawCancel() {
  const QueryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: withdrawCancel,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    },
    onSuccess: () => {
      QueryClient.invalidateQueries(['transactions']);
    }
  });

  return mutation;
}
