import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from '../../api/auth/auth.hooks';
import { FieldType, useForm, validateMaxLength, validateRequired } from '../../hooks/useForm';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { Button } from '../Buttons/Button';
import { Input } from '../Inputs/Input';
import { openChat } from 'src/utils';

export const ResetPasswordModal = () => {
  const [showSuccessInfo, setShowSuccessInfo] = useState(false);
  const { mutateAsync: resetPassword } = useResetPassword({});
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const {
    values,
    errors,
    fieldsProps: { emailProps },
    touched,
    isFormValid
  } = useForm<{ email: FieldType.Text }>({
    email: {
      placeholder: 'Email',
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(256)]
    }
  });

  const handleCreateAccountClick = () => {
    openModal?.(MODAL_NAME.SIGNUP);
  };

  const onResetPassword = async () => {
    const response = await resetPassword({ email: values.email });
    setShowSuccessInfo(response.data.success);
  };

  return (
    <div className="flex w-[340px] lg:w-[420px] lg:min-w-[420px]  flex-col items-center gap-[15px] p-[15px] py-[20px]">
      <p className="text-[26px] font-bold text-text_14 uppercase">
        {t('modalResetPassword.title')}
      </p>
      {!showSuccessInfo ? (
        <>
          <p className="font-medium text-[12px] text-center w-[80%] text-text_2">
            {t('modalResetPassword.subtitle')}
          </p>
          <Input {...emailProps} />
          {touched.email && errors.email && (
            <span className="text-[red] text-xs">{errors.email}</span>
          )}
          <div className="w-full flex justify-center items-center">
            <Button
              disabled={!isFormValid}
              mode="primary"
              onClick={onResetPassword}
              additionalClassName="w-full container-with-wide-content"
              title={`${t('modalResetPassword.title')}`}>
              {t('modalResetPassword.title')}
            </Button>
          </div>
          <div className="flex flex-row">
            <p className="m-auto mt-[5px] text-center mb-2.5 flex w-full flex-wrap flex-col justify-center gap-1 text-xs font-bold uppercase">
              <span className="text-[14px] text-text_2">{t('modalResetPassword.notRegist')}</span>
                <span
                  className="cursor-pointer text-[14px] text-text_14"
                onClick={handleCreateAccountClick}>
                {t('modalResetPassword.createNewAcc')}
              </span>
            </p>
          </div>
        </>
      ) : (
        <>
          <p className="font-medium text-[12px] text-text_2 text-center w-[80%]">
            {t('modalResetPassword.thankYouText')}
          </p>
          <p className="font-medium text-[12px] text-text_2 text-center w-[80%]">
            {t('modalResetPassword.spamText')}
          </p>
          <p className="font-medium text-[12px] text-text_2 text-center w-[80%]">
            {t('modalResetPassword.notReceivedLetter')}
            <span
              className="text-text_8 cursor-pointer underline"
              onClick={() => {
                closeModal?.();
                openChat();
              }}>
              {t('modalResetPassword.support')}
            </span>
          </p>
          <Button mode="primary" onClick={closeModal} title="okay">
            okay
          </Button>
        </>
      )}
    </div>
  );
};
