import { useTranslation } from 'react-i18next';

type Props = {
  header: string[];
};

export const TableHeader = ({ header }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {header?.map((param: string) => (
        <th
          key={param}
          className="text-center font-bold text-xs text-text_2 bg-background_2 p-2.5 first:rounded-tl-md last:rounded-tr-md">
          {t(param)}
        </th>
      ))}
    </>
  );
};
