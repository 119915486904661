import { useMemo } from 'react';
import {
  Clock,
  VerificarionAddress,
  VerificarionIdentity,
  VerificarionPayment
} from 'src/components/svg';
import { VerificationDragAndDropCard } from 'src/components/VerificationDragAndDropCard';
import { VerificationPreviewCard } from 'src/components/VerificationPreviewCard';
import { KycStatus, KYC_STATUS_NAME } from 'src/types';
import { useGetAllKycInfo, useUploadKycFiles } from 'src/api/kyc/kyc.hooks';
import { useTranslation } from 'react-i18next';
import { MESSAGE_STATUS, useMessage } from 'src/hooks/useMessage';

export type VerificationCardData = {
  id: string;
  title: string;
  description: string;
  content: string;
  image: React.ReactNode;
  important?: boolean;
};

const VERIFICATION_CARD_DATA: VerificationCardData[] = [
  {
    id: 'Identity',
    title: 'accountVerification.identityTitle',
    description: 'accountVerification.identityDescription',
    content: 'accountVerification.identityContent',
    image: <VerificarionIdentity />,
    important: true
  },
  {
    id: 'Address',
    title: 'accountVerification.addressTitle',
    description: 'accountVerification.addressDescription',
    content: 'accountVerification.addressContent',
    image: <VerificarionAddress />
  },
  {
    id: 'Payment',
    title: 'accountVerification.paymentTitle',
    description: 'accountVerification.paymentDescriprion',
    content: 'accountVerification.paymentContent',
    image: <VerificarionPayment />
  }
];

const MAX_IMAGES_COUNT = 20;

export const Verification = () => {
  const { t } = useTranslation();
  const { mutateAsync: uploadFiles } = useUploadKycFiles();
  const { kycStatus, uploadedFiles } = useGetAllKycInfo({});
  const { openMessage } = useMessage();

  const colorKycStatus = useMemo(() => {
    if (kycStatus === KycStatus.Verified) {
      return 'text-text_9';
    } else if (kycStatus === KycStatus.NotVerified) {
      return 'text-text_7';
    }
    return 'text-text_6';
  }, [kycStatus]);

  const statusName = useMemo(() => {
    if (kycStatus === undefined) return;
    return KYC_STATUS_NAME[kycStatus];
  }, [kycStatus]);

  const handleFileDrop = (file: File) => {
    if (uploadedFiles && uploadedFiles.length >= MAX_IMAGES_COUNT) {
      openMessage?.(t('apiErrors.maxImagesCount', { howMany: 20 }), MESSAGE_STATUS.ERROR);
      return;
    }
    const formData = new FormData();
    formData.append('kyc-files[]', file);
    uploadFiles(formData);
  };

  return (
    <div className="flex flex-col gap-11 items-start overflow-x-none max-w-[550px]">
      <div className="flex self-start text-left">
        <p className={`font-bold ${colorKycStatus} text-10px`}>{t(`${statusName}`)}</p>
      </div>
      <div className="flex w-full gap-9 overflow-x-scroll [&::-webkit-scrollbar]:block pb-[10px] [&::-webkit-scrollbar]:h-[8px] [&::-webkit-scrollbar-thumb]:rounded-[100px] [&::-webkit-scrollbar-thumb]:bg-background_5">
        {uploadedFiles?.map((file) => (
          <VerificationPreviewCard key={file.id} url={file.name} />
        ))}
      </div>

      {kycStatus !== 2 && kycStatus !== 3 && kycStatus !== 4 && (
        <>
          <div className="flex flex-col gap-12">
            {VERIFICATION_CARD_DATA.map((card) => (
              <VerificationDragAndDropCard
                key={card.id}
                card={card}
                handleFileDrop={handleFileDrop}
              />
            ))}
          </div>
          <div className="flex gap-3">
            <Clock classNames="min-h-[20px] min-w-[20px]" />
            <p className="text-sm text-text_2">{t('accountProfileKyc.verifiedText')}</p>
          </div>
        </>
      )}
    </div>
  );
};
