import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CONTENT_URL } from 'src/api';
import { MODAL_NAME, useModal } from 'src/hooks/useModal';

import { Child } from '../../api/content/content.types';
import { TournamentsActive } from '../../api/tournaments';
import { IconCurrency } from '../../constants/index';
import { useApi } from '../../hooks/useApi';
import { useGetCurrency } from '../../hooks/useCurrency';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent';
import { Button } from '../Buttons/Button';
import { LittleClock } from '../svg';

import './styles.css';

export const TournamentPreview = ({ content, hideButtons, type }: { content: Child; hideButtons?: boolean, type: 'local' | 'provider' }) => {
  const { openModal } = useModal();
  const { isAuthenticated } = useApi();
  const { t } = useTranslation();

  const handleButtonReadMoreClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_readmore', {
      tournament_id: content.title,
    });
  };

  const handleButtonParticipateClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_participate', {
      tournament_id: content.title,
    });
  };

  return (
    <div className="red-shadow mt-5 box-border grid max-h-[300px] w-full max-w-[1020px] grid-cols-[1fr_2fr] items-end rounded-2xl r_sm:h-auto r_sm:grid-cols-1 r_sm:grid-rows-[1fr_auto]">
      <div
        className="z-0 flex h-full w-full rounded-l-2xl"
        style={{
          backgroundImage: `url(${CONTENT_URL}${content.imageMobile})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="w-[49px] h-[23px] px-[5px] ml-[30px] mt-[20px] py-1 bg-orange-gradient rounded-[5px] justify-start items-start gap-2.5 inline-flex">
          <div className="text-center text-text_2 text-xs font-bold font-['Montserrat'] leading-[13.20px]">active</div>
        </div>
      </div>
      <div className="content-container">
        <p className="text-[20px] font-bold uppercase text-text_2 r_laptop:text-xl">{content.title}</p>
        <div
          className="content-box"
          dangerouslySetInnerHTML={{
            __html: content?.content,
          }}
        ></div>
        {content.dateEnd && content.dateStart && (
          <div className="content-box">
            <span className="gradient-text bg-orange-gradient font-bold uppercase">Tournament date:</span>{' '}
            {content.dateStart.toLocaleDateString()} - {content.dateEnd.toLocaleDateString()}
          </div>
        )}
        <div className="mt-auto flex w-full items-center justify-start gap-7 r_sm:mt-2 r_mid:flex-wrap r_mid:justify-center r_mid:gap-4">
          <Link
            onClick={() => handleButtonReadMoreClick(content)}
            state={{ id: content.code }}
            to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
          >
            <Button
              additionalClassName="w-[140px]"
              label={`${t('general.readMore')}`}
              mode="secondary"
              title={`${t('general.readMore')}`}
            />
          </Link>
          {isAuthenticated ? (
            <Link
              aria-label={`${t('tournaments.participate')}`}
              onClick={() => handleButtonParticipateClick(content)}
              state={{ id: content.code }}
              to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
            >
              <Button
                additionalClassName="w-[140px]"
                label={`${t('tournaments.participate')}`}
                mode="primary"
                title={`${t('tournaments.participate')}`}
              />
            </Link>
          ) : (
            <Button
              additionalClassName="min-w-[190px] max-w-[210px] r_laptop:min-w-[140px]"
              label={`${t('tournaments.participate')}`}
              mode="primary"
              onClick={() => openModal?.(MODAL_NAME.SIGNUP)}
              title={`${t('tournaments.participate')}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const mockdataMembers = [
  {
    login: 'html_ar1',
    place: 1,
    points: 12,
    progress: [
      {
        currency: 'EUR',
        progress: 565500,
        taskType: 2,
      },
    ],
  },
  {
    login: 'html_apu2',
    place: 2,
    points: 24,
    progress: [
      {
        currency: 'EUR',
        progress: 34200,
        taskType: 2,
      },
    ],
  },
  {
    login: 'html_apu3',
    place: 3,
    points: 24,
    progress: [
      {
        currency: 'EUR',
        progress: 34200,
        taskType: 2,
      },
    ],
  },
  {
    login: 'html_apu4',
    place: 3,
    points: 24,
    progress: [
      {
        currency: 'EUR',
        progress: 34200,
        taskType: 2,
      },
    ],
  },
  {
    login: 'html_apu5',
    place: 5,
    points: 24,
    progress: [
      {
        currency: 'EUR',
        progress: 34200,
        taskType: 2,
      },
    ],
  },
];
export const TournamentMembers = ({ members }: { members: TournamentsActive['members'] }) => {
  return (
    <div className="w-full flex flex-col gap-[5px]">
      {members.slice(0, 3).map((item, index) => (
        <div
          className="grid grid-cols-[min-content_2fr_1fr_1fr] px-[10px] py-[2px] md:py-[5px] text-text_2 gap-[10px] border-1 border-border_6 items-center text-[12px] md:text-[15px] rounded-[10px] font-medium"
          key={index}
        >
          <span className="w-[18px]">{index + 1}</span>
          <span>{item.login}</span>
          {item.progress?.[0].progress > 0 && (
            <span>
              {`${item.progress?.[0].progress}`} <span className="hidden md:inline">points</span>
            </span>
          )}
          {!item.progress?.[0].progress && <span />}
          {/* @ts-ignore*/}
          <span>{item.prize}</span>
        </div>
      ))}
    </div>
  );
};



export const TournamentPreviewCompleted = ({ content, type }: { content: Child, type: 'local' | 'provider' }) => {
  // const { openModal } = useModal();
  // const { isAuthenticated } = useApi();
  // const { t } = useTranslation();
  const currentLanguageObject = useGetCurrency();
  const currencySymbol = IconCurrency[currentLanguageObject.currency as keyof typeof IconCurrency];

  const allPlaces = useMemo(
    () =>
      content?.places.reduce((acc, place) => {
        let prize = '';

        if (place?.amount?.[currentLanguageObject.currency]) {
          prize = `${currencySymbol} ${(place.amount[currentLanguageObject.currency] / 100).toLocaleString()}`;
        }

        if (place?.freeSpins?.number) {
          prize = `${place.freeSpins.number} FS`;
        }

        acc.push(prize);
        return acc;
      }, [] as string[]) ?? [],
    [content?.places, currentLanguageObject.currency],
  );

  const fistThreePlaces = useMemo(() => allPlaces?.slice(0, 3), [allPlaces]);

  const members = useMemo(() => {
    if (fistThreePlaces?.length && content?.members) {
      return content.members.length >= fistThreePlaces?.length
        ? content.members
        : [
            ...content.members,
            ...new Array(fistThreePlaces.length - content.members.length).map((_, i) => ({
              login: '',
              place: content.members.length + i,
              points: 0,
              progress: [
                {
                  currency: currentLanguageObject.currency,
                  progress: 0,
                  taskType: 2,
                },
              ],
            })),
          ];
    } else {
      return content?.members;
    }
  }, [fistThreePlaces, content?.members]);

  const allMembers = useMemo(() => {
    if (!members || !allPlaces) {
      return [];
    }

    const member = [...members]
      .sort((a, b) => b.progress?.[0].progress - a.progress?.[0].progress)
      .map((member, index) => ({
        ...member,
        prize: allPlaces[index],
      }));

    return allPlaces.map((_, index) => {
      return (
        member[index] ?? {
          prize: allPlaces[index],
        }
      );
    });
  }, [members, allPlaces]);

  const handleButtonReadMoreClick = (content: Child) => {
    sendGoogleAnalyticsEvent('tournament_readmore', {
      tournament_id: content.title,
    });
  };

  // const handleButtonParticipateClick = (content: Child) => {
  //   sendGoogleAnalyticsEvent('tournament_participate', {
  //     tournament_id: content.title,
  //   });
  // };

  return (
    <div className="shadow-17 mt-5 box-border grid max-h-[300px] w-full max-w-[1020px] grid-cols-[1fr_2fr] items-end rounded-2xl r_sm:h-auto r_sm:grid-cols-1 r_sm:grid-rows-[1fr_auto]">
      <div
        className="z-0 flex h-full w-full rounded-l-2xl"
        style={{
          backgroundImage: `url(${CONTENT_URL}${content.imageMobile})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="h-[23px] px-[5px] ml-[30px] mt-[20px] py-1 bg-background_16 rounded-[5px] justify-start items-start gap-2.5 inline-flex">
          <div className="text-center text-xs font-bold font-['Montserrat'] leading-[13.20px]">inactive</div>
        </div>
      </div>
      <div className="content-container px-[40px] pt-[30px] pb-[24px] bg-[#171d29]">
        <p className="text-[20px] font-bold uppercase text-text_2 r_laptop:text-xl">{content.title}</p>
        {content.dateEnd && content.dateStart && (
          <div className="content-box flex gap-2 text-text_6">
            <LittleClock></LittleClock>
            {content.dateStart.toLocaleDateString()} - {content.dateEnd.toLocaleDateString()}
          </div>
        )}
        <TournamentMembers members={allMembers}></TournamentMembers>
        <div className="mt-auto flex w-full items-center justify-center gap-7 r_sm:mt-2 r_mid:flex-wrap r_mid:justify-center r_mid:gap-4">
          <Link
            onClick={() => handleButtonReadMoreClick(content)}
            state={{ id: content.code }}
            to={type === 'local' ? `/tournaments/local/${content.code}` : `/tournaments/${content.code}`}
          >
            <Button
              additionalClassName="w-[170px]"
              label={`SEE ALL RESULTS`}
              mode="transparent-button"
              title={`SEE ALL RESULTS`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
