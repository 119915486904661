import { apiProvider } from '..';
import {
  DemoGameBody,
  DemoGameData,
  GameData,
  GamesBody,
  GamesResponse,
  SingleGameBody
} from './games.types';
export const games = ({
  currency = 'EUR',
  platform_id = 1,
  page = 1,
  per_page = 10,
  name,
  ...params
}: GamesBody) => {
  return apiProvider.request<GamesResponse>({
    method: 'get',
    url: '/games',
    params: {
      currency,
      platform_id,
      page,
      per_page,
      name: name && name.length <= 2 ? undefined : name || undefined,
      ...params
    }
  });
};

// TODO X-Authorization, language
export const singleGame = (id: number | string, params: SingleGameBody) => {
  return apiProvider.request<GameData>({
    method: 'get',
    url: `/games/${id}`,
    params
  });
};

// TODO language
export const demoGame = (id: number | string, params: DemoGameBody) => {
  return apiProvider.request<DemoGameData>({
    method: 'get',
    url: `/games/demo/${id}`,
    params
  });
};
