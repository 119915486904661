import { useGetKefteme } from 'src/hooks/useGetKefteme';
import { useGetSinglePage } from '../../api/content/content.hooks';
import { PageTitle } from '../../components';
import { Faq } from './Faq';

export const Faqs = () => {
  const { getPageCodeFormat } = useGetKefteme();
  const { childs } = useGetSinglePage(getPageCodeFormat('faq'));

  return (
    <div className="w-full">
      <PageTitle title="Faq" className="uppercase" />
      <div className=" flex flex-col items-center px-2.5">
        <div className="mt-[30px] rounded-[20px] max-w-[900px] w-full red-shadow">
          {childs.map((child) => (
            <Faq key={child.code} faq={child} />
          ))}
        </div>
      </div>
    </div>
  );
};
