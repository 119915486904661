import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';
import TagManager from 'react-gtm-module';
import { executeAdditionalGTMScript } from './utils';

const userId = localStorage.getItem('userId');
const tagManagerArgs = {
  gtmId: 'GTM-T27C4B7',
  events: {
    user_id: userId || undefined
  }
};

executeAdditionalGTMScript(userId ? parseInt(userId) : undefined);
TagManager.initialize(tagManagerArgs);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
