import { MutationFunction } from 'react-query';
import { apiProvider } from '..';
import {
  ChangePasswordBody,
  FullRegistrationBody,
  GETJWTResponse,
  LoginBody,
  LoginResponse,
  LogoutBody,
  LogoutResponse,
  RegistrationBody,
  RegistrationResponse,
  ResetPasswordBody,
  ResetPasswordResponse
} from './auth.types';
import { AxiosResponse } from 'axios';
import { generateSeonSession } from 'src/utils';

export const login = async (data: LoginBody) => {
  const antifraud = await generateSeonSession(data.email);
  return apiProvider.request<LoginResponse>({
    method: 'post',
    url: '/auth/login',
    data: {
      ...data,
      antifraud
    }
  });
};

export const logout: MutationFunction<AxiosResponse<LogoutResponse, any>, LogoutBody> = (data) => {
  return apiProvider.request<LogoutResponse>({
    method: 'post',
    url: '/auth/logout',
    data
  });
};

export const registration = async (data: RegistrationBody) => {
  const antifraud = await generateSeonSession(data.email);
  return apiProvider.request<RegistrationResponse>({
    method: 'post',
    url: '/auth/fast-registration',
    data: {
      ...data,
      antifraud
    }
  });
};

// TODO language
export const fullRegistration = (data: FullRegistrationBody) => {
  return apiProvider.request({
    method: 'post',
    url: '/auth/full-registration',
    data
  });
};

// TODO language
export const resetPassword = (data: ResetPasswordBody) => {
  return apiProvider.request<ResetPasswordResponse>({
    method: 'post',
    url: '/auth/reset-password',
    data
  });
};
// TODO X-Authorization, language
// TODO ChangePasswordResponse
export const changePassword = (data: ChangePasswordBody) => {
  return apiProvider.request({
    method: 'put',
    url: '/auth/change-password',
    data
  });
};

export const getJWT = () => {
  return apiProvider.request<GETJWTResponse>({
    method: 'get',
    url: '/front/auth'
  });
};
