import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeCurrency } from '../../api/balance/balance.hooks';
import { useModal } from '../../hooks/useModal';
import { Button } from '../Buttons/Button';

export const ChangeCurrencyModal = () => {
  const { modalProps, closeModal } = useModal();
  const { mutate: changeCurrency, isLoading } = useChangeCurrency();
  const { t, i18n } = useTranslation();

  const handleChangeCurrencyButton = useCallback(() => {
    changeCurrency({
      currency: modalProps?.currency as string,
      language: i18n.language
    });
  }, [changeCurrency, i18n.language, modalProps?.currency]);

  return (
    <div className="p-[20px] flex flex-col gap-5 items-center w-[300px] r_xsm:w-full">
      <p className="font-bold text-[16px] text-center text-text_2">
        {t('accountWalletBalance.changeCurrencyTitle')}
      </p>
      <Button
        title={`${t('general.cancel')}`}
        mode="primary"
        onClick={() => {
          closeModal?.();
        }}>
        {t('general.cancel')}
      </Button>
      <Button
        title={`${t('accountWalletBalance.changeCurrencyButton')}`}
        loading={isLoading}
        onClick={handleChangeCurrencyButton}
        mode="secondary">
        {t('accountWalletBalance.changeCurrencyButton')}
      </Button>
    </div>
  );
};
