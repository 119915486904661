import classNames from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { NodapayMethod } from 'src/api/payment';
import instantMethodIcon from 'src/assets/images/instantNodapayMethod.png';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

export const NodapayMethodCard: FC<{
  method: NodapayMethod;
  onClick?: (method: NodapayMethod) => void;
  selected?: boolean;
  id: string;
}> = ({ method, onClick, selected, id }) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const title = useMemo(() => {
    return method.name ? method.name : '';
  }, [method.name]);

  const handleClick = useCallback(() => {
    onClick?.(method);
  }, [method, onClick]);

  return (
    <div
      className={classNames(
        'rounded-[10px] relative w-[120px] flex flex-col items-center justify-center p-[15px] gap-1',
        selected ? 'bg-background_5' : 'bg-background_2'
      )}
      id={method.name}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}>
      {method.isInstant ? (
        <Tooltip
          anchorSelect={`#${method.name}`}
          className="noda-modal-tooltip"
          place="right"
          isOpen={showTooltip}
          noArrow>
          <div>{t('NodapayModal.tooltipContent')}</div>
        </Tooltip>
      ) : null}
      {method.isInstant ? (
        <img src={instantMethodIcon} className="w-[17px] h-[17px] absolute top-[6px] right-[6px]" />
      ) : null}
      <input
        type="radio"
        className="absolute w-[100%] h-[100%] top-0 left-0 opacity-0 cursor-pointer"
        id={id}
        name={id}
        value={id}
        onClick={handleClick}
      />
      {method.logoUrl && (
        <img src={method.logoUrl} alt={title} className="max-w-[80px] object-contain h-9" />
      )}
      <p className={classNames('text-xs text-center ', selected ? 'text-black' : 'text-text_2')}>
        {title}
      </p>
    </div>
  );
};
