import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  withdrawalMakeWithdrawal,
  withdrawalMethods,
  withdrawalSubmitTransaction
} from './withdrawal.requests';
import { DescriptionQueryOptions } from '../types';
import { useShouldRefetch } from 'src/hooks/useShouldRefetch';
import { useApi } from 'src/hooks/useApi';
import { WithdrawalMethodsResponse } from './withdrawal.types';
import { paymentMethodsSort } from 'src/utils';

export function useGetWithdrawalMethods(
  options: DescriptionQueryOptions<WithdrawalMethodsResponse> = {}
) {
  const { isAuthenticated } = useApi();
  const { refetchDependencies, refetchParams } = useShouldRefetch({
    lang: true
  });
  const { data: response, ...query } = useQuery<
    AxiosResponse<WithdrawalMethodsResponse>,
    AxiosError
  >(
    ['withdrawal-methods', ...refetchDependencies],
    () =>
      withdrawalMethods({
        ...refetchParams
      }),
    { enabled: isAuthenticated, ...options }
  );
  return {
    sortedWithdrawalMethods:
      response?.data && Array.isArray(response?.data?.paymentMethods)
        ? paymentMethodsSort(response?.data?.paymentMethods)
        : [],
    methods:
      response?.data && Array.isArray(response?.data?.paymentMethods) ? response?.data : undefined,
    ...query
  };
}

export function useMakeWithdrawal() {
  const mutation = useMutation({
    mutationFn: withdrawalMakeWithdrawal,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    }
  });

  return mutation;
}

export function useSubmitTransaction() {
  const mutation = useMutation({
    mutationFn: withdrawalSubmitTransaction,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    }
  });

  return mutation;
}
