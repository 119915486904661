import { useTranslation } from 'react-i18next';
import { useGetSinglePage } from 'src/api/content';
import { PageTitle } from 'src/components/PageTitle';
import iosFirst from 'src/assets/images/iosFirst.webp';
import iosSecond from 'src/assets/images/iosSecond.webp';
import iosThird from 'src/assets/images/iosThird.webp';
import chromeFirst from 'src/assets/images/chromeFirst.webp';
import chromeSecond from 'src/assets/images/chromeSecond.webp';
import chromeThird from 'src/assets/images/chromeThird.webp';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames';
import { Chrome, Safari } from 'src/components/svg';
import { Wrapper } from 'src/components/Wrapper/Wrapper';

const imagesCollection = {
  ios_safari: {
    content: [
      { id: 0, src: iosFirst },
      { id: 1, src: iosSecond },
      { id: 2, src: iosThird }
    ]
  },
  android_chrome: {
    content: [
      { id: 0, src: chromeFirst },
      { id: 1, src: chromeSecond },
      { id: 2, src: chromeThird }
    ]
  }
};

const ContentComponent = ({
  title,
  content,
  code
}: {
  title: string;
  content: string;
  code: string;
}) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('android')) {
      setTimeout(() => {
        const element = document.getElementById('android');

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  return (
    <div
      className="w-full grid grid-cols-2 flex-row gap-[70px] r_laptop:flex r_laptop:flex-col r_laptop:gap-8 relative"
      id={`${code === 'ios_safari' ? 'ios' : 'android'}`}>
      <div className={classNames('absolute none', code === 'android_chrome' ? 'h-10' : '')}></div>
      <div
        className="flex flex-col gap-[30px] r_sm:w-full"
        id={`${code === 'ios_safari' ? 'ios' : 'android'}`}>
        <div className="flex flex-row gap-4">
          <div className=" w-[32px] h-[32px]">
            {code === 'ios_safari' ? <Safari /> : <Chrome />}
          </div>
          <p className="w-full font-bold text-base text-text_10 flex justify-start items-center uppercase">
            <span>{title}</span>
          </p>
        </div>
        <div className="text-base text-text_2" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className="grid grid-cols-3 max-w-[600px] gap-[30px] items-center justify-center r_sm:w-full r_sm:gap-2.5 max-h-[320px] r_sm:max-h-[180px]">
        {imagesCollection[code as keyof typeof imagesCollection].content.map((item) => (
          <img
            src={item.src}
            key={item.id}
            alt="collection"
            className="max-w-[180px] w-100% h-full max-h-[320px] object-contain r_sm:max-h-[180px]"
          />
        ))}
      </div>
    </div>
  );
};

export const PwaPage = () => {
  const { t } = useTranslation();
  const { childs } = useGetSinglePage('app');

  return (
    <div className="w-full">
      <PageTitle title={t('pwaPage.title')} />
      <div className="px-[50px] py-[80px] w-full flex flex-col justify-start items-center bg-no-repeat bg-cover bg-gradient_4 r_sm:px-[30px] r_sm:py-[60px]">
        <Wrapper>
          <div className="w-full flex flex-col items-center justify-center gap-[80px]">
            {childs.map((child) => (
              <ContentComponent
                key={child.title}
                title={child.title}
                content={child.content}
                code={child.code}
              />
            ))}
          </div>
        </Wrapper>
      </div>
    </div>
  );
};
