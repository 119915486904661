import classNames from 'classnames';

export const Spin = ({ classNameSvg }: { classNameSvg?: string }) => {
  return (
    <div className="flex justify-center mr-[12px]">
      <svg
        role="status"
        className={classNames(
          'inline w-6 h-6 text-text_1 animate-spin',
          classNameSvg ? `${classNameSvg}` : ''
        )}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#8188af"></path>
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"></path>
      </svg>
    </div>
  );
};

export const Lock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-text_6 text-center ml-[20px]">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
      />
    </svg>
  );
};

export const Wallet = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 0H4.5C2.01375 0 0 2.01375 0 4.5V13.5C0 15.9862 2.01375 18 4.5 18H18C20.4862 18 22.5 15.9862 22.5 13.5V4.5C22.5 2.01375 20.4862 0 18 0ZM15.9075 10.9912C15.6375 11.2162 15.2663 11.3062 14.9175 11.2162L2.41875 8.15625C2.75625 7.335 3.555 6.75 4.5 6.75H18C18.7537 6.75 19.4175 7.1325 19.8337 7.695L15.9075 10.9912ZM4.5 2.25H18C19.2375 2.25 20.25 3.2625 20.25 4.5V5.11875C19.5863 4.73625 18.8213 4.5 18 4.5H4.5C3.67875 4.5 2.91375 4.73625 2.25 5.11875V4.5C2.25 3.2625 3.2625 2.25 4.5 2.25Z"
        fill="white"
      />
    </svg>
  );
};

export const Promo = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.90529 0.542034C6.97234 0.531807 5.09052 2.39317 5.94961 4.70453H2.70756C2.16508 4.70453 1.64481 4.92004 1.26121 5.30363C0.877612 5.68723 0.662109 6.2075 0.662109 6.74999V8.79544C0.662109 9.06669 0.769861 9.32682 0.961659 9.51862C1.15346 9.71042 1.41359 9.81817 1.68484 9.81817H10.8894V6.74999H12.9348V9.81817H22.1394C22.4106 9.81817 22.6708 9.71042 22.8626 9.51862C23.0544 9.32682 23.1621 9.06669 23.1621 8.79544V6.74999C23.1621 6.2075 22.9466 5.68723 22.563 5.30363C22.1794 4.92004 21.6591 4.70453 21.1167 4.70453H17.8746C19.0712 1.36022 14.5712 -1.00228 12.4951 1.88181L11.9121 2.65908L11.3292 1.86135C10.6848 0.951125 9.79506 0.552261 8.90529 0.542034ZM8.84393 2.65908C9.75415 2.65908 10.2144 3.76363 9.57006 4.40794C8.92575 5.05226 7.8212 4.59203 7.8212 3.68181C7.8212 3.41056 7.92895 3.15043 8.12075 2.95863C8.31255 2.76683 8.57268 2.65908 8.84393 2.65908ZM14.9803 2.65908C15.8905 2.65908 16.3507 3.76363 15.7064 4.40794C15.0621 5.05226 13.9576 4.59203 13.9576 3.68181C13.9576 3.41056 14.0653 3.15043 14.2571 2.95863C14.4489 2.76683 14.709 2.65908 14.9803 2.65908ZM1.68484 10.8409V19.0227C1.68484 19.5652 1.90034 20.0855 2.28394 20.4691C2.66753 20.8527 3.1878 21.0682 3.73029 21.0682H20.0939C20.6364 21.0682 21.1567 20.8527 21.5403 20.4691C21.9239 20.0855 22.1394 19.5652 22.1394 19.0227V10.8409H12.9348V19.0227H10.8894V10.8409H1.68484Z"
        fill="white"
      />
    </svg>
  );
};

export const Vip = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9121 18.3732L6.63119 22.4052C6.36952 22.6193 6.08406 22.7202 5.77482 22.7078C5.46558 22.6964 5.19201 22.6074 4.95413 22.4409C4.71626 22.2744 4.53214 22.0484 4.40178 21.763C4.27047 21.4775 4.26428 21.1683 4.38322 20.8352L6.41709 14.2341L1.24321 10.5588C0.95776 10.3685 0.77935 10.1188 0.707986 9.80953C0.636622 9.50028 0.648516 9.21483 0.743668 8.95316C0.83882 8.69149 1.00534 8.45932 1.24321 8.25665C1.48109 8.05493 1.76655 7.95406 2.09958 7.95406H8.48665L10.5562 1.10313C10.6751 0.770104 10.8597 0.514146 11.11 0.335261C11.3593 0.157327 11.6267 0.0683594 11.9121 0.0683594C12.1976 0.0683594 12.4649 0.157327 12.7142 0.335261C12.9645 0.514146 13.1491 0.770104 13.268 1.10313L15.3376 7.95406H21.7246C22.0577 7.95406 22.3431 8.05493 22.581 8.25665C22.8189 8.45932 22.9854 8.69149 23.0806 8.95316C23.1757 9.21483 23.1876 9.50028 23.1162 9.80953C23.0449 10.1188 22.8665 10.3685 22.581 10.5588L17.4071 14.2341L19.441 20.8352C19.5599 21.1683 19.5542 21.4775 19.4239 21.763C19.2926 22.0484 19.108 22.2744 18.8701 22.4409C18.6322 22.6074 18.3586 22.6964 18.0494 22.7078C17.7402 22.7202 17.4547 22.6193 17.193 22.4052L11.9121 18.3732Z"
        fill="white"
      />
    </svg>
  );
};

export const GameHistory = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9121 0.708984C9.68707 0.708984 7.512 1.36879 5.66195 2.60495C3.8119 3.84112 2.36996 5.59813 1.51847 7.65379C0.666984 9.70946 0.444196 11.9715 0.87828 14.1537C1.31236 16.336 2.38382 18.3406 3.95716 19.9139C5.5305 21.4873 7.53506 22.5587 9.71735 22.9928C11.8996 23.4269 14.1616 23.2041 16.2173 22.3526C18.273 21.5011 20.03 20.0592 21.2661 18.2091C22.5023 16.3591 23.1621 14.184 23.1621 11.959C23.1564 8.97705 21.9693 6.11889 19.8607 4.01035C17.7522 1.9018 14.894 0.714702 11.9121 0.708984ZM17.9698 12.8244H11.9121C11.6826 12.8244 11.4625 12.7332 11.3002 12.5709C11.1379 12.4086 11.0467 12.1885 11.0467 11.959V5.90129C11.0467 5.67178 11.1379 5.45166 11.3002 5.28937C11.4625 5.12708 11.6826 5.03591 11.9121 5.03591C12.1416 5.03591 12.3617 5.12708 12.524 5.28937C12.6863 5.45166 12.7775 5.67178 12.7775 5.90129V11.0936H17.9698C18.1993 11.0936 18.4194 11.1848 18.5817 11.3471C18.744 11.5094 18.8352 11.7295 18.8352 11.959C18.8352 12.1885 18.744 12.4086 18.5817 12.5709C18.4194 12.7332 18.1993 12.8244 17.9698 12.8244Z"
        fill="white"
      />
    </svg>
  );
};

export const LogOut = () => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.91406 2.60938H12.0882V3.73419H5.91406V2.60938Z" fill="white" />
      <path d="M5.91406 0.375H12.0882V1.49981H5.91406V0.375Z" fill="white" />
      <path
        d="M12.0875 12.431V4.84277H5.91333V12.431H0.826172L9.00042 23.6251L17.1747 12.431H12.0875Z"
        fill="white"
      />
    </svg>
  );
};

export const Info = ({ fill = 'white' }: { fill?: string }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.34009C7.94365 4.34009 4.25 8.03374 4.25 12.5901C4.25 17.1464 7.94365 20.8401 12.5 20.8401C17.0563 20.8401 20.75 17.1464 20.75 12.5901C20.75 8.03374 17.0563 4.34009 12.5 4.34009ZM2.75 12.5901C2.75 7.20531 7.11522 2.84009 12.5 2.84009C17.8848 2.84009 22.25 7.20531 22.25 12.5901C22.25 17.9749 17.8848 22.3401 12.5 22.3401C7.11522 22.3401 2.75 17.9749 2.75 12.5901Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.8401C11 11.4259 11.3358 11.0901 11.75 11.0901H12.5C12.9142 11.0901 13.25 11.4259 13.25 11.8401V16.3401C13.6642 16.3401 14 16.6759 14 17.0901C14 17.5043 13.6642 17.8401 13.25 17.8401H12.5C12.0858 17.8401 11.75 17.5043 11.75 17.0901V12.5901C11.3358 12.5901 11 12.2543 11 11.8401Z"
        fill={fill}
      />
      <path
        d="M12.3125 9.59009C12.9338 9.59009 13.4375 9.08641 13.4375 8.46509C13.4375 7.84377 12.9338 7.34009 12.3125 7.34009C11.6912 7.34009 11.1875 7.84377 11.1875 8.46509C11.1875 9.08641 11.6912 9.59009 12.3125 9.59009Z"
        fill={fill}
      />
    </svg>
  );
};

export const AutocompleteIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M10.3634 0.331331L6.50323 4.23109L2.60347 0.370946C2.51042 0.278838 2.40013 0.205961 2.27891 0.156474C2.15769 0.106987 2.02792 0.0818597 1.89699 0.0825281C1.76606 0.0831966 1.63654 0.109647 1.51584 0.160369C1.39513 0.211091 1.2856 0.285091 1.19349 0.378144C1.10138 0.471197 1.0285 0.581482 0.979015 0.7027C0.929528 0.823918 0.904401 0.953697 0.90507 1.08463C0.905738 1.21555 0.932188 1.34507 0.98291 1.46578C1.03363 1.58648 1.10763 1.69602 1.20069 1.78813L5.81406 6.35463C5.90704 6.44686 6.01731 6.51985 6.13853 6.56941C6.25976 6.61898 6.38957 6.64414 6.52054 6.64347C6.65151 6.64281 6.78106 6.61631 6.90177 6.56552C7.02249 6.51472 7.132 6.44061 7.22404 6.34744L11.7905 1.73406C12.1786 1.34208 12.1753 0.712085 11.7833 0.324082C11.3914 -0.0539226 10.7514 -0.060655 10.3634 0.331331Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};
