import { ChangeEvent, MouseEvent } from 'react';

import { DropDownOption } from '../../types';
import { FieldConfig, FieldProps, FieldType } from './fields.types';
import { ValuesState } from './useForm.types';

export const DEFAULT_STATE: Record<FieldType, any> = {
  [FieldType.Autocomplete]: undefined,
  [FieldType.Checkbox]: false,
  [FieldType.Date]: '',
  [FieldType.Dropdown]: undefined,
  [FieldType.Multiselect]: [],
  [FieldType.Number]: '',
  [FieldType.Phone]: '',
  [FieldType.Radio]: undefined,
  [FieldType.Text]: '',
  [FieldType.WithoutWildcards]: '',
};

export type FieldPropsCreator<TFieldType extends FieldType> = <TValues extends ValuesState>(params: {
  checkFieldStatuses: (fieldName: string, value: any) => void;
  field: FieldConfig<TFieldType>;
  fieldName: string;
  setAsTouched: (fieldName: string) => void;
  setFocused: React.Dispatch<React.SetStateAction<string | undefined>>;
  setValues: React.Dispatch<React.SetStateAction<TValues>>;
  values: TValues;
}) => FieldProps<TFieldType>;

const makeTextFieldProps: FieldPropsCreator<FieldType.Text> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setFocused,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkFieldStatuses(fieldName, event.target.value);
    setValues((values) => ({ ...values, [fieldName]: event.target.value }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
    setFocused(undefined);
  };

  const onFocus = () => {
    setFocused(fieldName);
  };

  return {
    name: fieldName,
    onBlur,
    onChange,
    onFocus,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};
const makeNumberFieldProps: FieldPropsCreator<FieldType.Number> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkFieldStatuses(fieldName, event.target.value.replace(/[^\d]/g, ''));
    setValues((values) => ({ ...values, [fieldName]: event.target.value.replace(/[^\d]/g, '') }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };
  return {
    name: fieldName,
    onBlur,
    onChange,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makePhoneFieldProps: FieldPropsCreator<FieldType.Phone> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? '';
    if (newValue.startsWith(field.phoneCode ?? '')) {
      const newValueWithoutPlus = newValue.replace(/\D+/g, '');
      setValues((values) => ({ ...values, [fieldName]: `+${newValueWithoutPlus}` }));
      checkFieldStatuses(fieldName, newValue);
    }
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };
  return {
    name: fieldName,
    onBlur,
    onChange,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makeWithoutWildcardsFieldProps: FieldPropsCreator<FieldType.WithoutWildcards> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkFieldStatuses(fieldName, event.target.value.replace(/[^\w]/g, ''));
    setValues((values) => ({ ...values, [fieldName]: event.target.value.replace(/[^\w]/g, '') }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };
  return {
    name: fieldName,
    onBlur,
    onChange,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makeDateFieldProps: FieldPropsCreator<FieldType.Date> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkFieldStatuses(fieldName, event.target.value);
    setValues((values) => ({ ...values, [fieldName]: event.target.value }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };
  return {
    htmlType: 'date',
    name: fieldName,
    onBlur,
    onChange,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makeCheckboxFieldProps: FieldPropsCreator<FieldType.Checkbox> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setValues,
  values,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkFieldStatuses(fieldName, event.target.checked);
    setValues((values) => ({ ...values, [fieldName]: event.target.checked }));
  };
  return {
    checked: values[fieldName],
    label: field.label,
    name: fieldName,
    onChange,
  };
};

const makeRadioFieldProps: FieldPropsCreator<FieldType.Radio> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setValues,
  values,
}) => {
  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    // @ts-ignore value существует, т.к. клик обрабатывается на контейнер, содержащий радио инпут
    if (event.target.value) {
      // @ts-ignore
      checkFieldStatuses(fieldName, event.target.value);
      // @ts-ignore
      setValues((values) => ({ ...values, [fieldName]: event.target.value }));
    }
  };
  return {
    name: fieldName,
    onClick,
    options: field.options,
    value: values[fieldName],
  };
};

const makeDropdownFieldProps: FieldPropsCreator<FieldType.Dropdown> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (value?: string) => {
    checkFieldStatuses(fieldName, value);
    setValues((values) => ({ ...values, [fieldName]: value }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };

  return {
    label: field.label,
    name: fieldName,
    onBlur,
    onChange,
    options: field.options,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makeAutocompleteFieldProps: FieldPropsCreator<FieldType.Autocomplete> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setFocused,
  setValues,
  values,
}) => {
  const filter = (value: string | undefined, options: DropDownOption[]): DropDownOption[] => {
    if (!value) return [];
    return options.filter((o) => o.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  };

  const onChange = (value: string | undefined) => {
    checkFieldStatuses(fieldName, value);
    setValues((values) => ({ ...values, [fieldName]: value }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
    setFocused(undefined);
  };

  const onFocus = () => {
    setFocused(fieldName);
  };

  const onSelect = (value: string | undefined, option?: DropDownOption) => {
    if (option?.name) {
      setValues((values) => ({ ...values, [fieldName]: option.name }));
      checkFieldStatuses(fieldName, option.name);
    } else {
      setValues((values) => ({ ...values, [fieldName]: value }));
      checkFieldStatuses(fieldName, value);
    }
  };

  return {
    filter,
    name: fieldName,
    onBlur,
    onChange,
    onFocus,
    onSelect,
    options: field.options,
    placeholder: field.placeholder,
    value: values[fieldName],
  };
};

const makeMultiselectFieldProps: FieldPropsCreator<FieldType.Multiselect> = ({
  checkFieldStatuses,
  field,
  fieldName,
  setAsTouched,
  setValues,
  values,
}) => {
  const onChange = (selectedValues: string[]) => {
    checkFieldStatuses(fieldName, selectedValues);
    setValues((values) => ({ ...values, [fieldName]: selectedValues }));
  };
  const onBlur = () => {
    setAsTouched(fieldName);
  };
  return {
    name: fieldName,
    onBlur,
    onChange,
    options: field.options,
    placeholder: field.placeholder,
    values: values[fieldName],
  };
};

export const getFieldProps = {
  [FieldType.Autocomplete]: makeAutocompleteFieldProps,
  [FieldType.Checkbox]: makeCheckboxFieldProps,
  [FieldType.Date]: makeDateFieldProps,
  [FieldType.Dropdown]: makeDropdownFieldProps,
  [FieldType.Multiselect]: makeMultiselectFieldProps,
  [FieldType.Number]: makeNumberFieldProps,
  [FieldType.Phone]: makePhoneFieldProps,
  [FieldType.Radio]: makeRadioFieldProps,
  [FieldType.Text]: makeTextFieldProps,
  [FieldType.WithoutWildcards]: makeWithoutWildcardsFieldProps,
};

export type PropsMaker<T extends FieldType> = T extends FieldType.Text
  ? FieldPropsCreator<FieldType.Text>
  : T extends FieldType.Date
  ? FieldPropsCreator<FieldType.Date>
  : T extends FieldType.Autocomplete
  ? FieldPropsCreator<FieldType.Autocomplete>
  : T extends FieldType.Checkbox
  ? FieldPropsCreator<FieldType.Checkbox>
  : T extends FieldType.Dropdown
  ? FieldPropsCreator<FieldType.Dropdown>
  : T extends FieldType.Radio
  ? FieldPropsCreator<FieldType.Radio>
  : T extends FieldType.Multiselect
  ? FieldPropsCreator<FieldType.Multiselect>
  : T extends FieldType.Number
  ? FieldPropsCreator<FieldType.Number>
  : T extends FieldType.WithoutWildcards
  ? FieldPropsCreator<FieldType.WithoutWildcards>
  : T extends FieldType.Phone
  ? FieldPropsCreator<FieldType.Phone>
  : never;
