import { generateSeonSession } from 'src/utils';
import { apiProvider } from '..';
import i18n from '../../utils/i18n';
import {
  WithdrawalMakeWithdrawalBody,
  WithdrawalMakeWithdrawalResponse,
  WithdrawalMethodsParams,
  WithdrawalMethodsResponse,
  WithdrawalSubmitTransactionResponse
} from './withdrawal.types';

// TODO X-Authorization, language
export const withdrawalMethods = (params: WithdrawalMethodsParams) =>
  apiProvider.request<WithdrawalMethodsResponse>({
    method: 'get',
    url: '/withdrawal/all-methods',
    params
  });

// TODO X-Authorization, language
export const withdrawalMakeWithdrawal = async (data: WithdrawalMakeWithdrawalBody) => {
  const antifraud = await generateSeonSession(data.email);
  return apiProvider.request<WithdrawalMakeWithdrawalResponse>({
    method: 'post',
    url: '/withdrawal/make-withdrawal',
    data: { language: i18n.language, antifraud, ...data }
  });
};

// TODO language
export const withdrawalSubmitTransaction = (id: number) => {
  return apiProvider.request<WithdrawalSubmitTransactionResponse>({
    method: 'post',
    url: '/withdrawal/submit-transaction-to-aggregator',
    data: { transaction_id: id }
  });
};
