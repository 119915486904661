import React from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { VideoPreloader } from '../VideoPreloader/VideoPreloader';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

export const QueryBoundaries = ({ children }: { children: React.ReactNode }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorView}>
        <React.Suspense fallback={<VideoPreloader />}>{children}</React.Suspense>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

const ErrorView = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div>
      <div>{error.message}</div>
      <button title="Retry" onClick={resetErrorBoundary}>
        Retry
      </button>
    </div>
  );
};
