import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SquareSkeleton } from 'src/components/SquareSkeleton';

import { PaymentMethod } from '../../../types';
import { sendGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticsEvent';
import { BitcoinIcon, CardIcon } from '../../svg';
import { CardPaymentMethod } from './PaymentMethod';

enum TypeMethodsEnum {
  BANK = 'BANK',
  CRYPTO = 'CRYPTO',
}

export const PaymentMethods = ({
  balance,
  header,
  onSelectPaymentMethod,
  paymentMethods,
  selectedMethod,
}: {
  balance?: { currency: string; currencySign: string; data: string };
  header?: React.ReactNode;
  onSelectPaymentMethod?: (method?: PaymentMethod) => void;
  // methods?: AllMethodsResponse | undefined;
  paymentMethods?: PaymentMethod[];
  selectedMethod?: number;
}) => {
  const [typeMethod, setTypeMethod] = useState<TypeMethodsEnum>(TypeMethodsEnum.BANK);
  const { t } = useTranslation();
  const isSelectMethod = useCallback(
    (type: TypeMethodsEnum) => {
      return type === typeMethod;
    },
    [typeMethod],
  );

  const cryptoMethods = useMemo(() => paymentMethods?.filter((method) => method.type === 'coin'), [paymentMethods]);
  const bankMethods = useMemo(() => paymentMethods?.filter((method) => method.type !== 'coin'), [paymentMethods]);
  const methods = useMemo(
    () => (isSelectMethod(TypeMethodsEnum.BANK) ? bankMethods : cryptoMethods),
    [typeMethod, paymentMethods],
  );

  const setTypeMethodBank = useCallback(
    (type: TypeMethodsEnum) => {
      const selectedMethods = type === TypeMethodsEnum.BANK ? bankMethods : cryptoMethods;

      sendGoogleAnalyticsEvent('deposit_type_chosen', {
        deposit_type: type === TypeMethodsEnum.BANK ? 'fiat' : 'crypto',
      });

      setTypeMethod(type);
      onSelectPaymentMethod?.(selectedMethods?.length === 1 ? selectedMethods[0] : undefined);
    },
    [onSelectPaymentMethod, setTypeMethod, bankMethods, cryptoMethods],
  );

  return (
    <div className="paymentForm__balance">
      <div className="paymentForm-balance__wrapper">
        {!header ? (
          <div className="flex items-center justify-center space-x-4 text-[20px] font-bold text-text_2 lg:text-[26px]">
            <p>{t('general.balance')}</p>
            {/* <p>{balance?.currency}</p> */}
            <div className="gradient-text bg-orange-gradient">{balance?.data}</div>
          </div>
        ) : (
          header
        )}
        <div className="justify-stretch grid w-full grid-flow-col pt-[20px] text-text_2 select-none">
          <div
            className={classNames(
              isSelectMethod(TypeMethodsEnum.BANK) ? 'bg-orange-gradient-2' : 'border',
              'flex items-center justify-center gap-x-[10px] cursor-pointer rounded-bl-3xl rounded-tl-3xl py-[9px] text-center text-[15px] font-[500] leading-[15px] lg:py-[10px] lg:text-[20px] lg:leading-[20px]',
            )}
            onClick={() => setTypeMethodBank(TypeMethodsEnum.BANK)}
          >
            <CardIcon /> CARD
          </div>
          <div
            className={classNames(
              isSelectMethod(TypeMethodsEnum.CRYPTO) ? 'bg-orange-gradient-2' : 'border',
              'flex items-center justify-center gap-x-[10px] cursor-pointer rounded-br-3xl rounded-tr-3xl py-[9px] text-center text-[15px] font-[500] leading-[15px] lg:py-[10px] lg:text-[20px] lg:leading-[20px]',
            )}
            onClick={() => setTypeMethodBank(TypeMethodsEnum.CRYPTO)}
          >
            <BitcoinIcon /> CRYPTO
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div
            className={classNames(
              'flex flex-wrap justify-center gap-[8px] py-5 lg:justify-start lg:gap-[10px] r_sm:py-2.5',
              methods && methods.length > 3 ? 'flex-1' : '',
            )}
          >
            {methods?.length ? (
              methods.map((method) => (
                <CardPaymentMethod
                  id={method.title}
                  key={method.title}
                  method={method}
                  onClick={onSelectPaymentMethod}
                  selected={method._reactDepositMethodId === selectedMethod}
                />
              ))
            ) : (
              <SquareSkeleton
                classNameForSqueare="!pb-[15%] w-[120px] h-[100px] min-w-[120px]"
                count={5}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
