import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useLogin } from '../../api/auth/auth.hooks';
import { useGetUserCountry } from '../../api/profile';
import {
  FieldType,
  useForm,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validateRequired,
} from '../../hooks/useForm/index';
import { MODAL_NAME, useModal } from '../../hooks/useModal';
import { sendGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticsEvent.js';
import { Button } from '../Buttons/Button';
import { Input } from '../Inputs/Input';
import { PasswordInput } from '../Inputs/PasswordInput';
import { parseAffilliateReferralLink } from '../ReferralLinkHandler';

export const LoginModal = () => {
  const { isLoading, mutateAsync: login } = useLogin({});
  const [searchParams] = useSearchParams();
  const { userCountry } = useGetUserCountry({});
  const { openModal } = useModal();
  const { t } = useTranslation();
  const {
    errors,
    fieldsProps: { emailProps, passwordProps },
    isFormValid,
    setSubmitTried,
    submitTried,
    values,
  } = useForm<{
    email: FieldType.Text;
    password: FieldType.Text;
  }>({
    email: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.email')}`,
      validation: [validateRequired, validateMaxLength(256), validateEmail],
    },
    password: {
      fieldType: FieldType.Text,
      placeholder: `${t('general.password')}`,
      validation: [validateRequired, validatePassword],
    },
  });

  const handleForgotPasswordClick = () => {
    openModal?.(MODAL_NAME.RESET_PASSWORD);
  };

  const handleSignInButtonClick = () => {
    setSubmitTried(true);

    if (!isFormValid) {
      return;
    }

    const { affiliateId } = parseAffilliateReferralLink(searchParams.get('stag'));
    const unixTimestamp = Math.floor(new Date().getTime() / 1000);

    sendGoogleAnalyticsEvent('user_login', {
      affiliate_id: affiliateId ?? null,
      geo: userCountry?.country,
      unix_timestamp: unixTimestamp,
    });

    login({ email: values.email.trim(), password: values.password.trim() });
  };

  return (
    <div className="flex w-[340px] lg:w-[420px] lg:min-w-[420px] flex-col items-center gap-[20px] p-[15px]">
      <p className="text-[26px] font-bold text-text_14 uppercase">{t('modalLogin.title')}</p>
      {submitTried}
      <Input
        {...emailProps}
        id="email"
        inputBorderColor={classNames(submitTried && Boolean(errors.email) && 'border-border_4')}
      />
      <Tooltip
        anchorSelect="#email"
        className="rounded-4xl bg-background_6 text-text_2"
        isOpen={submitTried && Boolean(errors.email)}
        place="top"
      >
        <div>{errors.email}</div>
      </Tooltip>
      <PasswordInput
        {...passwordProps}
        id="password"
        inputBorderColor={classNames(submitTried && Boolean(errors.email) && 'border-border_4')}
      />
      <Tooltip
        anchorSelect="#password"
        className="rounded-4xl bg-background_6 text-text_2"
        isOpen={submitTried && Boolean(errors.password)}
        place="top"
      >
        <div>{errors.password}</div>
      </Tooltip>
      <p
        className="cursor-pointer text-[14px] font-bold text-text_14"
        onClick={handleForgotPasswordClick}
      >
        {t('modalLogin.forgot')}
      </p>
      <Button
        additionalClassName="max-w-[140px]"
        label={`${t('modalLogin.title')}`}
        loading={isLoading}
        mode="primary"
        onClick={handleSignInButtonClick}
      >
        {t('modalLogin.title')}
      </Button>
      <p className="m-auto mt-[5px] text-center mb-2.5 flex w-full flex-wrap flex-col justify-center gap-1 text-xs font-bold uppercase">
        <span className="text-[14px] text-text_2">{t('modalLogin.notRegist')}</span>
        <span
          className="cursor-pointer text-[14px] text-text_14"
          onClick={() => openModal?.(MODAL_NAME.SIGNUP)}
        >
          {t('modalLogin.createNewAcc')}
        </span>
      </p>
    </div>
  );
};
