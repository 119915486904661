import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { demoGame, games, singleGame } from './games.requests';
import { DemoGameData, GameData, GamesBody, GamesResponse, SingleGame } from './games.types';
import { useGamePlatform } from 'src/hooks/useGamePlatform';
import { useGetShortBalance } from '../balance';

export function useGetAllGames(params: GamesBody, target: string) {
  const { data: shortBalance } = useGetShortBalance();
  const platformId = useGamePlatform();

  const { data: response, ...query } = useQuery<AxiosResponse<GamesResponse>, AxiosError>(
    ['games', target, params.provider_id, shortBalance?.currency, platformId],
    () => games({ ...params, platform_id: platformId, currency: shortBalance?.currency })
  );
  return {
    games: response?.data?.data || [],
    gamesOptions: response?.data,
    ...query
  };
}

export function useNewGetAllGames(params: GamesBody, target: string) {
  const { data: shortBalance } = useGetShortBalance();
  const platformId = useGamePlatform();

  const response = useInfiniteQuery<AxiosResponse<GamesResponse>, AxiosError>({
    queryKey: ['games', target, params.per_page, shortBalance?.currency, platformId],
    queryFn: ({ pageParam = 1 }) =>
      games({
        ...params,
        currency: shortBalance?.currency,
        page: pageParam,
        platform_id: platformId
      }),
    getNextPageParam: (lastPage, allPages) => {
      const pagesCount = Math.ceil(lastPage.data.totalCount / lastPage.data.perPage);
      const pagesLoaded = allPages.length;
      return pagesLoaded < pagesCount ? pagesLoaded + 1 : undefined;
    }
  });

  const allGames =
    response.data?.pages.reduce<SingleGame[]>((games, page) => [...games, ...page.data.data], []) ||
    [];

  return { allGames, ...response };
}

export function useGetGamesPaginated(
  params: GamesBody,
  target: string | undefined,
  enabled?: boolean
) {
  const platformId = useGamePlatform();
  const { data: shortBalance } = useGetShortBalance();
  const response = useInfiniteQuery<AxiosResponse<GamesResponse>, AxiosError>({
    queryKey: [
      'games',
      target,
      params.name,
      params.provider_id,
      params.per_page,
      platformId,
      shortBalance?.currency
    ],
    queryFn: ({ pageParam = 1 }) =>
      games({
        ...params,
        page: pageParam,
        currency: shortBalance?.currency,
        platform_id: platformId
      }),
    getNextPageParam: (lastPage, allPages) => {
      const pagesCount = Math.ceil(lastPage.data.totalCount / lastPage.data.perPage);
      const pagesLoaded = allPages.length;
      return pagesLoaded < pagesCount ? pagesLoaded + 1 : undefined;
    },
    enabled: enabled
  });

  const allGames =
    response.data?.pages.reduce<SingleGame[]>((games, page) => [...games, ...page.data.data], []) ||
    [];

  return { allGames: allGames || [], ...response };
}

export function useGetSingleGame(
  id: number | string | undefined,
  onError?: (error: AxiosError) => void
) {
  const platformId = useGamePlatform();

  const { data: response, ...query } = useQuery<AxiosResponse<GameData>, AxiosError>(
    ['single-game', id, platformId],
    () => singleGame(id!, { platform_id: platformId }),
    {
      enabled: Boolean(id),
      onError: (error: AxiosError<unknown, any>) => {
        onError?.(error);
      }
    }
  );
  return {
    game: response?.data,
    gameOptions: response?.data,
    ...query
  };
}

export function useGetDemoGame(id?: number | string) {
  const platformId = useGamePlatform();
  const { data: response, ...query } = useQuery<AxiosResponse<DemoGameData>, AxiosError>(
    ['demo-game', id, platformId],
    () => demoGame(id!, { platform_id: platformId }),
    { enabled: Boolean(id) }
  );
  return {
    game: response?.data,
    gameOptions: response?.data,
    ...query
  };
}
