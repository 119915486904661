import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  addTransaction,
  checkTransactionStatus,
  singleTransaction,
  transactionDetailsInGateway,
  transactions
} from './transactions.requests';
import {
  TransactionDetailsInGatewayResponse,
  TransactionsBody,
  TransactionsResponse
} from './transactions.types';

export function useGetTransactions(
  params: TransactionsBody,
  pageName?: string,
  enabled: boolean = true
) {
  const [page, setPage] = useState(1);

  const { data: response, ...query } = useQuery<AxiosResponse<TransactionsResponse>, AxiosError>(
    ['transactions', `transactions page ${page}`, pageName, params.currency],
    () => transactions({ ...params, page }),
    { enabled }
  );

  return {
    page,
    setPage,
    numberPages: response?.data?.numberPages,
    transactions: response?.data,
    arrayTransactions: response?.data?.transactions || [],
    ...query
  };
}

export function useGetSingleTransaction() {
  return useQuery(['single-transaction'], () => singleTransaction);
}

export function useAddTransaction() {
  const mutation = useMutation({
    mutationFn: addTransaction,
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
    }
  });

  return mutation;
}

export function useGetCheckTransactionStatus() {
  return useQuery(['check-transaction-status'], () => checkTransactionStatus);
}

export function useGetTransactionDetailsInGateway(
  id: number | string,
  onSuccess?: (data: AxiosResponse<TransactionDetailsInGatewayResponse, any>) => void
) {
  return useQuery(['transaction-details-in-gateway'], () => transactionDetailsInGateway(id), {
    enabled: false,
    onSuccess: onSuccess
  });
}
