import { useTranslation } from 'react-i18next';
import { BonusAccountCasino, BonusAccountSport } from 'src/api/balance';
import { SingleCardBonusBalance } from './SingleCardBonusBalance';

export const PersonalAreaBonusBalance = ({
  bonusAccount,
  bonusType
}: {
  bonusAccount: BonusAccountCasino | BonusAccountSport;
  bonusType: number;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {bonusAccount?.balance && bonusAccount.balance > 0 ? (
        <SingleCardBonusBalance bonusAccount={bonusAccount} bonusType={bonusType} />
      ) : (
        <div className="flex flex-col gap-3">
          <p className="font-bold text-2xl text-text_8">{t('blockActiveBonus.blockTitle')}</p>
          <p className="font-medium text-sm text-text_2">{t('blockActiveBonus.noActiveBonus')}</p>
        </div>
      )}
    </div>
  );
};
