import { Button } from '../Buttons/Button';
import './styles.css';
import { Logo } from './Logo';
import { useTranslation } from 'react-i18next';
import { useDrawer } from 'src/hooks/useDrawer';
import { useScrollToTopOnMount } from 'src/hooks/useScrollToTop';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderBurgerIcon } from '../svg/HeaderBurgerIcon';

export const PersonalHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pageRef = useScrollToTopOnMount([location.pathname]);

  const { openLeftDrawer, closeBottomDrawer, _openMainSidebar } = useDrawer();

  const isAccountLayout = location.pathname.includes('account');

  return (
    <header className="header z-[700]" ref={pageRef}>
      <div className="flex justify-start items-center w-[60%] sm:w-full">
        <div
          className="p-2 flex justify-center items-center"
          onClick={() => {
            if (isAccountLayout) {
              openLeftDrawer?.();
            } else {
              _openMainSidebar?.();
            }
            closeBottomDrawer?.();
          }}>
          <HeaderBurgerIcon />
        </div>
        <Logo />
      </div>
      <Button
        title={`${t('general.backHome')}`}
        onClick={() => {
          navigate('/');
          closeBottomDrawer?.();
        }}
        mode="primary">
        {t('general.backHome')}
      </Button>
    </header>
  );
};
