import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import {
  BlacklistEmailDomainResponse,
  BlacklistEmailFragmentResponse,
  BlacklistEmailResponse,
  BlockedCountryResponse
} from './blacklist.types';
import {
  blacklistEmail,
  blacklistEmailDomain,
  blacklistEmailFragment,
  blockedCoutry
} from './blacklist.requests';

export function useGetblacklistEmail() {
  const { data: response, ...query } = useQuery<AxiosResponse<BlacklistEmailResponse>, AxiosError>(
    ['email-blacklist'],
    blacklistEmail
  );
  return {
    blackListEmail: response?.data || [],
    ...query
  };
}

export function useGetblacklistEmailDomain() {
  const { data: response, ...query } = useQuery<
    AxiosResponse<BlacklistEmailDomainResponse>,
    AxiosError
  >(['email-domain-blacklist'], blacklistEmailDomain);
  return {
    blackListEmailDomain: response?.data || [],
    ...query
  };
}

export function useGetblacklistEmailFragment() {
  const { data: response, ...query } = useQuery<
    AxiosResponse<BlacklistEmailFragmentResponse>,
    AxiosError
  >(['email-fragment-blacklist'], blacklistEmailFragment);
  return {
    blackListEmailFragment: response?.data || [],
    ...query
  };
}

export function useGetBlockedCountry() {
  const { data: response, ...query } = useQuery<AxiosResponse<BlockedCountryResponse>, AxiosError>(
    ['blocked-country'],
    blockedCoutry
  );
  return {
    blockedCoutry: response?.data.blocked,
    ...query
  };
}

export const useGetBlacklistEmails = () => {
  const { blackListEmail, ...queryEmail } = useGetblacklistEmail();
  const { blackListEmailDomain, ...queryEmailDomain } = useGetblacklistEmailDomain();
  const { blackListEmailFragment, ...queryEmailFragment } = useGetblacklistEmailFragment();

  const isEmailBlocked = (email: string) => {
    const isBlockedEmail =
      Array.isArray(blackListEmail) &&
      blackListEmail.some((blackListEmail) => blackListEmail === email);
    const isBlockedEmailDomain =
      Array.isArray(blackListEmailDomain) &&
      blackListEmailDomain.some((domain) => {
        const regexp = new RegExp('@' + domain, 'gm');
        return regexp.test(email);
      });
    const isBlockedblackListEmailFragment =
      Array.isArray(blackListEmailFragment) &&
      blackListEmailFragment.some((fragment) => {
        const regexp = new RegExp(fragment, 'gm');
        return regexp.test(email);
      });
    return isBlockedEmail || isBlockedEmailDomain || isBlockedblackListEmailFragment;
  };

  return {
    blackListEmail: blackListEmail || [],
    blackListEmailDomain: blackListEmailDomain || [],
    blackListEmailFragment: blackListEmailFragment || [],
    ...queryEmail,
    ...queryEmailDomain,
    ...queryEmailFragment,
    isEmailBlocked
  };
};
