import { apiProvider } from '..';
import {
  AddTransactionBody,
  AddTransactionResponse,
  CheckTransactionStatusResponse,
  Transaction,
  TransactionDetailsInGatewayResponse,
  TransactionsBody,
  TransactionsResponse
} from './transactions.types';

// X-Authorization, language
export const transactions = (params: TransactionsBody) => {
  return apiProvider.request<TransactionsResponse>({
    method: 'get',
    url: '/transactions',
    params
  });
};

// language
export const singleTransaction = (id: number) => {
  return apiProvider.request<Transaction>({
    method: 'get',
    url: `/transactions/${id}`
  });
};

export const addTransaction = (data: AddTransactionBody) => {
  return apiProvider.request<AddTransactionResponse>({
    method: 'post',
    url: '/transactions/add-transaction',
    data
  });
};

// language
export const checkTransactionStatus = (id: number) => {
  return apiProvider.request<CheckTransactionStatusResponse>({
    method: 'get',
    url: `/transactions/check-transaction-status/${id}`
  });
};

// language
export const transactionDetailsInGateway = (id: number | string) => {
  return apiProvider.request<TransactionDetailsInGatewayResponse>({
    method: 'get',
    url: `/transactions/transaction-details-in-gateway/${id}`
  });
};
