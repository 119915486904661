import classNames from 'classnames';
import { FC } from 'react';
import { paginationDots } from 'src/constants/usePaginationConstants';
import { usePagination } from 'src/hooks/usePagination';

type Props = {
  nextPage: () => void;
  prevPage: () => void;
  pages: number;
  currentPage: number;
  onClick: (page: number) => void;
};

export const TablePagination: FC<Props> = ({
  prevPage,
  nextPage,
  pages,
  currentPage = 1,
  onClick
}) => {
  const paginationRange = usePagination(pages, currentPage);

  return (
    <ul className="flex flex-row my-[15px] items-center justify-center gap-1">
      <li
        className={classNames(
          currentPage === 1
            ? ' opacity-10 cursor-not-allowed text-text_2'
            : 'cursor-pointer text-text_2'
        )}
        onClick={() => currentPage === 1 || prevPage?.()}>
        &#60;
      </li>

      {paginationRange?.map((pgNumber) => {
        if (pgNumber === paginationDots) {
          return (
            <li key={Math.random()}>
              <p className={classNames('cursor-pointer text-text_2 font-medium text-sm')}>
                {pgNumber}
              </p>
            </li>
          );
        }

        return (
          <li key={pgNumber} onClick={() => onClick?.(+pgNumber)}>
            <p
              className={classNames(
                'cursor-pointer p-2 font-medium text-sm',
                currentPage === pgNumber ? 'text-text_8' : 'text-text_2'
              )}>
              {pgNumber}
            </p>
          </li>
        );
      })}

      <li
        className={classNames(
          currentPage === pages
            ? ' opacity-10 cursor-not-allowed text-text_2'
            : 'cursor-pointer text-text_2'
        )}
        onClick={() => currentPage === pages || nextPage?.()}>
        &gt;
      </li>
    </ul>
  );
};
